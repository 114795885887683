<template lang="pug">
section.page-title-wrapper
  base-icon.page-title-icon(:name="pageTitleIcon" :nameString="pageTitleIconString")
  .title-section
    h1.title {{title}}
    .hr.hr.hr--thick
    ul.breadcrumbs(v-if="$slots.breadcrumbs")
      slot(name="breadcrumbs")
    p.boldText(v-if="$slots.boldText")
      slot(name="boldText")
  .action-section(v-if="$slots.actionSection")
    slot(name="actionSection")
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    pageTitleIcon: {
      type: String,
      required: false,
    },
    pageTitleIconString: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
  },
});
</script>

<style lang="scss">
.page-title-wrapper {
  width: 100%;
  max-width: 1120px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 77px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > * {
    flex-shrink: 0;
    flex-grow: 0;
  }
  .page-title-icon {
    top: 0;
    margin-right: 30px;
    font-size: 83px;
    color: $primaryLightBlue;
  }

  .title-section {
    flex-grow: 1;
    min-width: 745px;
    h1 {
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 24px;
      font-weight: 700;
      line-height: 1.2;
      letter-spacing: 0.12px;
      color: $primaryLightBlue;
      text-transform: uppercase;
    }
    .hr {
      margin-top: 14px;
      margin-bottom: 18px;
      display: block;
    }
    .boldText {
      font-size: 20px;
      font-weight: bold;
      margin-top: -4px;
      margin-bottom: 0px;
    }
    .breadcrumbs {
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        position: relative;
        font-size: 20px;
        font-weight: 300;
        line-height: 1.2;
        letter-spacing: 0.1px;
        color: #000;
        &.active {
          text-decoration: underline;
          text-decoration-thickness: 1px;
          text-underline-offset: 2px;
          text-transform: uppercase;
        }
        &:not(:last-child)::after {
          content: "•";
          display: inline-block;
          margin: 0 10px;
          font-size: 24px;
          font-weight: 700;
          letter-spacing: 0.12px;
          color: $primaryBgDarkGrey;
        }
        & > a {
          color: #000000;
          &.router-link-active {
            text-decoration: underline;
            text-decoration-thickness: 1px;
            text-underline-offset: 2px;
            text-transform: uppercase;
            pointer-events: none;
          }
        }
      }
    }
  }

  .action-section {
    position: relative;
    display: flex;
    align-items: center;
    margin-left: 33px;
    .annotation {
      position: absolute;
      right: 10px;
      bottom: calc(100% + 13px);
      font-size: 16px;
      letter-spacing: 0.08px;
      color: #9b9b9b;
    }
  }
}
</style>
