<template lang="pug">
.date-selector-wrapper
  .date-selector(
    @click.prevent="isCalendarExpanded = !isCalendarExpanded",
    :class="{ expanded: isCalendarExpanded }",
  )
    base-icon(name="calendar")
    span.txt {{selectionDisplay}}
    base-icon(name="chevron-md")
  .datepicker-container(v-show="isCalendarExpanded")
    .form-container
      .form-group
        label(for="date") Date
        select#date(v-model="selectedPresetDate")
          option(value="custom") Custom
          option(value="this-year") This Year
          option(value="last-year") Last Year
      .range-selector-container
        DatePicker(v-model='range' mode='date' :masks='masks' is-range='')
          template(v-slot='{ inputValue, inputEvents, isDragging }')
            .flex
              .col
                label From
                .flex.datepicker-outer-container
                  input(:class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value='inputValue.start' v-on='inputEvents.start')
                  .icon-container
                    svg(fill='none' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' viewbox='0 0 24 24' stroke='currentColor')
                      path(d='M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z')
              .col
                label To
                .flex.datepicker-outer-container
                  input(:class="isDragging ? 'text-gray-600' : 'text-gray-900'" :value='inputValue.end' v-on='inputEvents.end')
                  .icon-container
                    svg(fill='none' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' viewbox='0 0 24 24' stroke='currentColor')
                      path(d='M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z')
    .buttons-container
      BaseBtn.sm.grey-outline(@click.prevent="resetDate") RESET
      BaseBtn.sm.green(@click.prevent="applyDate") APPLY

</template>

<script>
import { DatePicker } from "v-calendar";
import { defineComponent } from "vue";
import DateSelector from "@/components/DateSelector.vue";
import {
  endOfYear,
  startOfYear,
  startOfLastYear,
  endOfLastYear,
} from "@/helpers";

export default defineComponent({
  components: { DateSelector, DatePicker },
  emits: ["filterByDate"],

  props: {
    selectedStart: {
      required: true,
    },
    selectedEnd: {
      required: true,
    },
  },

  data() {
    return {
      isCalendarExpanded: false,
      selectionDisplay: "All dates",
      selectedPresetDate: "this-year",
      selectedRange: null,
      range: {
        start: this.selectedStart,
        end: this.selectedEnd,
      },
      masks: {
        input: "MM-DD-YYYY",
      },
    };
  },

  methods: {
    resetDate() {
      this.range = {
        start: this.selectedStart,
        end: this.selectedEnd,
      };
    },
    applyDate() {
      this.$emit("filterByDate", this.range);
      this.isCalendarExpanded = false;
    },
  },

  watch: {
    selectedPresetDate(value) {
      switch (value) {
        case "this-year":
          this.range = {
            start: startOfYear,
            end: endOfYear,
          };
          break;
        case "last-year":
          this.range = {
            start: startOfLastYear,
            end: endOfLastYear,
          };
          break;
      }
    },
    range(value) {
      if (
        new Date(value.start).getTime() === new Date(startOfYear).getTime() &&
        new Date(value.end).getTime() === new Date(endOfYear).getTime()
      ) {
        this.selectedPresetDate = "this-year";
        return;
      }

      if (
        new Date(value.start).getTime() ===
          new Date(startOfLastYear).getTime() &&
        new Date(value.end).getTime() === new Date(endOfLastYear).getTime()
      ) {
        this.selectedPresetDate = "last-year";
        return;
      }

      this.selectedPresetDate = "custom";
      return;
    },
    isCalendarExpanded(value) {
      if (value) {
        this.range = {
          start: this.selectedStart,
          end: this.selectedEnd,
        };
      }
    },
  },
});
</script>

<style scoped lang="scss">
label {
  margin-bottom: 7px;
}
.date-selector-wrapper {
  position: relative;
}
.date-selector {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 191px;
  margin-right: 8px;
  border-radius: 30px;
  transition: background-color 0.3s linear;
  background: rgba(#f0eeee, 0) 0% 0% no-repeat;
  text-transform: uppercase;
  .svg-icon--calendar {
    font-size: 24px;
  }
  .txt {
    margin: 0 14px;
    text-transform: uppercase;
    font-size: 14px;
  }
  .svg-icon--chevron-md {
    font-size: 16px;
    transition: transform 0.3s linear;
    transform: rotate(0deg);
  }
  &:hover {
    background-color: rgba($primaryLightBlue, 0.1);
  }
  &.expanded {
    background-color: $primaryLightBlue;
    background-color: rgba(#f0eeee, 1);
    .svg-icon--chevron-md {
      transform: rotate(-180deg);
    }
  }
}
.picker {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: transparent !important;
  border: 0 !important;
  opacity: 0 !important;
}
.numInput cur-year {
  position: relative !important;
  top: -20px !important;
}

.form-container {
  display: flex;
  flex: 1;

  .form-group,
  .range-selector-container {
    margin: 0px;
    padding: 0px;
  }
  .form-group {
    margin: 0px 0.938rem;
  }

  .range-selector-container {
    .form-group {
      margin: 0 0.25rem;
    }
    .col {
      margin: 0px 0.938rem;
    }
    .datepicker-outer-container {
      display: flex;
      flex: 1;
      border-radius: 2px;
      border: 2px solid #019bdf;
      height: 50px;

      &:hover {
        border-color: #007dc5;
      }

      svg {
        height: 25px;
        width: 25px;
      }
      input {
        min-width: 9rem;
        border: none;
        box-shadow: none;
        height: 46px;
      }
      .icon-container {
        padding: 0 0.25rem;
        height: 46px;
        display: flex;
        align-items: center;
        border: none;
        box-shadow: none;
      }
    }
  }

  select {
    width: auto;
  }
}

.buttons-container {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-top: 1.75rem;
  button {
    margin: 0.25rem;
  }
}

// Date picker---------------------------------------
.datepicker-container {
  padding: 1.5rem 1rem;
  position: absolute;
  top: calc(100% + 14px);
  right: 0;
  background-color: #fff;
  border: 1px $primaryDarkGrey;
  box-shadow: 2px 5px 20px 0 rgba(0, 0, 0, 0.16);
  z-index: 100;
  display: flex;
  flex: 1;
  flex-direction: column;
}
</style>
