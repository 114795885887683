<template lang="pug">
section(v-if="!isSearch")
  TableTitle(title="Patient Search")
  .search-patient
    .cols
      .col
        .form-group
          label(for="search-by") Search By
          select#search-by(v-model="searchBy")
            option(
              v-for="type in PATIENT_SEARCH_OPTIONS",
              :key="type.id"
              :value="type.value"
              :selected="type.value === searchBy"
            ) {{ type.name }}
      .col(v-if="searchBy==='name'")
        .form-group
          label(for="first-name") First Name
          input#first-name(type="text", v-model="firstName")
      .col(v-if="searchBy==='name'")
        .form-group
          label(for="last-name") Last Name
          input#last-name(type="text", v-model="lastName")
      .col(v-if="searchBy==='mrn'")
        .form-group
          label(for="mrn") MRN
          input#mrn(type="text", v-model="mrn")
      .col(v-if="searchBy==='dob'")
        .form-group
          label(for="dob") Date of Birth
          input#dob(type="date", v-model="dob")
  .actions-below-table-container
    span
    BaseBtn.lg.green(
      href="#",
      :class="{disabled: !isValidEntry()}",
      @click.prevent="startSearch()",
    ) SEARCH
SelectPatient(
  v-if="isSearch", 
  :searchParams="searchParams",
  :idCommunity="idCommunity"
  @closeSelectPatient="closeSelectPatient",
)
</template>

<script>
import { defineComponent } from "vue";
import TableTitle from "@/components/TableTitle.vue";
import SelectPatient from "@/components/fit-kit-community-organization/new-fit-kit/SelectPatient.vue";
import { PATIENT_SEARCH_OPTIONS } from "@/helpers";

export default defineComponent({
  components: { TableTitle, SelectPatient },

  data() {
    return {
      idCommunity: null,
      mrn: null,
      dob: null,
      isSearch: false,
      searchBy: "name",
      firstName: null,
      lastName: null,
      searchParams: null,
      PATIENT_SEARCH_OPTIONS,
    };
  },

  methods: {
    isValidEntry() {
      const isValidSearchType = this.searchBy && this.searchBy !== "";
      const isValidName =
        this.searchBy === "name"
          ? (this.firstName && this.firstName !== "") ||
            (this.lastName && this.lastName !== "")
          : true;
      const isValidMrn =
        this.searchBy === "mrn" ? this.mrn && this.mrn !== "" : true;
      const isValidDob =
        this.searchBy === "dob" ? this.dob && this.dob !== "" : true;
      return isValidSearchType && isValidName && isValidMrn && isValidDob;
    },
    startSearch() {
      this.searchParams = {
        searchBy: this.searchBy,
        firstName: this.firstName,
        lastName: this.lastName,
        mrn: this.mrn,
        dob: this.dob,
      };
      this.isSearch = true;
    },
    closeSelectPatient() {
      this.searchParams = null;
      this.searchBy = "name";
      this.firstName = null;
      this.lastName = null;
      this.mrn = null;
      this.dob = null;
      this.isSearch = false;
    },
  },
  created() {
    if (this.$route.params.idCommunity === null) return;
    this.idCommunity = +this.$route.params.idCommunity;
    if (this.$route.params.firstName && this.$route.params.lastName) {
      this.searchBy = "name";
      this.firstName = this.$route.params.firstName;
      this.lastName = this.$route.params.lastName;
      this.startSearch();
    }
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/components/actions-below-table-container.scss";
.table-title {
  margin-bottom: 41px;
}
.search-patient {
  // margin-top: -14px;
  padding: 0 30px 9px;
  .cols {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .col {
      width: math.percentage(math.div(345, 1108));
      margin-right: 36px;
      flex-grow: 0;
      flex-shrink: 0;
      &:last-child {
        margin-right: auto;
      }
    }
  }
}
</style>
