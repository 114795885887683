<template lang="pug">
section.step-subtitle
  h2 SELECT PATIENT
  BaseBtn.x-lg.blue(@click.prevent="openAddNewClinicPatientOverlayOpen") ADD NEW PATIENT
section.select-patient
  TableTitle(:title="getTitle")
  .table
    .th
      .td Patient Name
      .td Clinic
      .td DOB
      .td
      .td
    .tr(v-for="patient in patients" :key="patient.id")
      .td.bold {{ getUserFormatted(patient) }}
      .td {{getClinicName(patient)}}
      .td {{getDobFormatted(patient.dob)}}
      .td
        BaseBtn.sm.blue-outline.disabled-outline( 
          v-if="patient.isDisabled") PATIENT RECORDS
        BaseBtn.sm.blue-outline(
          @click="openClinicPatientDetalisOverlay(patient)"
          v-else
        ) PATIENT RECORD

      .td
        BaseBtn.sm.blue.disabled( 
          v-if="patient.isDisabled") SELECT PATIENT
        BaseBtn.sm.blue(
          @click="selectPatient(patient)"
          v-else
        ) SELECT PATIENT

  Pagination(
    v-if="pagination",
    :pagination="pagination",
    :reloadFn="fetchPatients"
    @changePagination="changePagination"
  )
  .no-results(v-if="patients != null && !patients.length") 
    base-icon(name="exclamation") 
    span No matching records

  .actions-below-table-container
    BaseBtn.lg.green-outline(
      :to="{name: 'clinics.scheduling.search-patient'}",
      style="min-width: 270px",
    ) &lt; PREVIOUS STEP

AddNewPatientOverlay(
  :isOpen="isAddNewClinicPatientOverlayOpen",
  @closeAddNewClinicPatientOverlayOpen="closeAddNewClinicPatientOverlayOpen",
  @reloadPatients="reloadPatients"
)
ClinicPatientDetalisOverlay(
  :key="`${componentKey}-1`"
  :showPatient="showPatient",
  :isOpen="isClinicPatientDetalisOverlay",
  @closeClinicPatientDetalisOverlay="closeClinicPatientDetalisOverlay",
  :specialtyModule="specialtyModule"
)

</template>

<script>
import { defineComponent } from "vue";
import moment from "moment";
import TableTitle from "@/components/TableTitle.vue";
import Pagination from "@/components/Pagination.vue";
import listPagesMixin from "@/mixins/listPagesMixin";
import { getDobFormatted, getUserFormatted } from "@/helpers";
import { fetchCHSPatients } from "@/api/chsApi";
import AddNewPatientOverlay from "@/components/clinics/scheduling/overlays/AddNewPatientOverlay.vue";
import ClinicPatientDetalisOverlay from "@/components/clinics/patient-search/overlays/PatientDetalisOverlay.vue";

export default defineComponent({
  components: {
    TableTitle,
    Pagination,
    AddNewPatientOverlay,
    ClinicPatientDetalisOverlay,
  },
  mixins: [listPagesMixin],
  props: {
    specialtyModule: {
      required: false,
    },
  },
  data() {
    return {
      isClinicPatientDetalisOverlay: false,
      patients: null,
      isAddNewClinicPatientOverlayOpen: false,
      getTitle: "",
      showPatient: null,
      searchParams: null,
    };
  },

  methods: {
    async reloadPatients(patient) {
      this.searchParams = {
        searchBy: "name",
        firstName: patient.first,
        lastName: patient.last,
      };
      this.$router.push({
        name: "clinics.scheduling.select-patient",
        query: this.searchParams,
      });
      this.fetchPatients();
    },
    getDobFormatted,
    getUserFormatted,
    getClinicName(patient) {
      return patient && patient.clinic && patient.clinic.name;
    },
    async fetchPatients() {
      this.getTitle = "(0) Patients Found";
      try {
        let query_params = {
          searchBy: this.$route.query.searchBy,
          searchInput: this.getSearchInput(this.$route.query),
        };
        if (this.searchParams) {
          query_params = {
            searchBy: this.searchParams.searchBy,
            searchInput: this.getSearchInput(this.searchParams),
          };
        }
        const resPatient = await fetchCHSPatients({
          params: query_params,
          ...this.reqPagination,
        });
        this.patients = resPatient.data.patients.map((element) => {
          element.isDisabled = true;
          if (element.idClinic == this.$route.params.idClinic) {
            element.isDisabled = false;
          }

          return element;
        });

        if (this.patients != null && this.patients.length) {
          this.getTitle = "(" + this.patients.length + ") Patients Found";
        }
      } catch (err) {
        console.error(err);
      }
    },
    getSearchInput(searchParams) {
      let searchInput = {};
      const searchBy = searchParams.searchBy;
      switch (searchBy) {
        case "name":
          if (searchParams.firstName) {
            searchInput.first = searchParams.firstName;
          }
          if (searchParams.lastName) {
            searchInput.last = searchParams.lastName;
          }
          break;
        case "mrn":
          searchInput = searchParams.mrn;
          break;
        case "dob":
          searchInput = moment.utc(searchParams.dob).format("MMDDYYYY");
          break;
        default:
          break;
      }
      return searchInput;
    },
    selectPatient(patient) {
      this.$router.push({
        name: "clinics.scheduling.select-schedule",
        query: this.$route.query,
        params: {
          idPatient: patient.id,
        },
      });
    },
    openAddNewClinicPatientOverlayOpen() {
      this.isAddNewClinicPatientOverlayOpen = true;
    },
    closeAddNewClinicPatientOverlayOpen() {
      this.isAddNewClinicPatientOverlayOpen = false;
    },
    openClinicPatientDetalisOverlay(patient) {
      this.componentKey = this.componentKey + 1;
      this.showPatient = patient;
      this.isClinicPatientDetalisOverlay = true;
    },
    closeClinicPatientDetalisOverlay() {
      this.isClinicPatientDetalisOverlay = false;
      this.fetchPatients();
    },
  },
  created() {
    if (this.$route.query === null) return;
    this.fetchPatients();
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/components/actions-below-table-container.scss";
@import "@/scss/components/step-subtitle.scss";
.select-patient {
  .td {
    &:nth-child(1) {
      width: 23.5%;
    }
    &:nth-child(2) {
      width: 23.1%;
    }
    &:nth-child(3) {
      width: 15.4%;
    }
    &:nth-child(4) {
      width: 20.4%;
    }
    &:last-child {
      width: 17.6%;
      // width: 19.5%;
    }
  }
}
.actions-below-table-container {
  .buttons {
    margin-left: 0;
  }
}
</style>
