<template lang="pug">
.content-wrapper
  BasePageTitle(
    pageTitleIcon="icc-logo",
    title="SYSTEM SETTINGS"
  )
    template(#breadcrumbs)
      li
        router-link(:to="{name: 'system-settings.specialty-modules'}") Specialty Care
      li
        router-link(:to="{name: 'system-settings.primary-care-modules'}") Primary Care
      li
        router-link(:to="{name: 'system-settings.referrals'}") Referrals
      li
        router-link(:to="{name: 'system-settings.insurances'}") Insurances
      li
        router-link(:to="{name: 'system-settings.settings'}") Settings
  router-view()

</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  components: {},

  props: {
    // project: {
    //   type: Object,
    //   required: true
    // }
  },

  data() {
    return {};
  },

  computed: {},

  watch: {
    // keywords: {
    //   handler: "getRows",
    //   immediate: true
    // }
  },

  methods: {},
});
</script>

<style scoped lang="scss"></style>
