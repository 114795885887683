<template lang="pug">
SideOverlaySlot(
  :isOpen="isOpen",
  title="COMPLETE COLONOSCOPY",
  @closeOverlay="closeOverlay"
)
  template(#contentTop)
    PatientSection(:fitKit="outsideIccFitKit", :showDetails="false", :showProvider="false")
    form(
      v-if="!showSuccess"
      style="display:flex; flex-direction:column; flex:1;"
      @submit.prevent=""
    )
      .columns
        .column.is-half
          .form-group(style="margin-bottom:0;")
            label(for="results") Test Results
            select#results(v-model="selectedResult")
              option(
                v-for="result in COLONOSCOPY_OUTSIDE_RESULT_OPTIONS",
                :key="result.value"
                :value="result.value"
                :selected="result.value === selectedResult"
              ) {{result.name}}
        .column.is-half
          .form-group(style="margin-bottom:0;")
            label(for="date-of-processing") Date of Processing
            DatePicker(
              v-model="date_processing" 
              :masks="masks"
              :max-date="new Date()"
            )
              template(v-slot="{ inputValue, inputEvents }")
                input(
                  :value="inputValue"  
                  v-on="inputEvents"
                  placeholder="MM/DD/YYYY"
                )
      .columns
        .column.is-half
          .form-group(style="margin-bottom:0;")
            label(for="report") Report
            UploadAndPreviewFile(
              :buttonStyleClasses="['lg', 'blue']"
              :storageKey="reportStorageKey"
            )
        .column.is-half
          .form-group(style="margin-bottom:0;")
            label(for="follow-up-date") Follow-Up Date
            DatePicker(
              v-model="date_followup" 
              :masks="masks"
              :min-date="new Date()"
            )
              template(v-slot="{ inputValue, inputEvents }")
                input(
                  :value="inputValue"  
                  v-on="inputEvents"
                  placeholder="MM/DD/YYYY"
                )
      .form-group(
        style="margin-bottom:0; display:flex; flex-direction:column; flex:1;"
      )
        label(for="notes") Notes
        textarea#notes(style="flex:1;", v-model="newNotes")
    .overlay-show-success(v-if="showSuccess")
      p Colonoscopy successfully completed!
  template(#footer, v-if="!showSuccess")
    .flex.flex--space-between
      BaseBtn.x-lg.red-outline(
        href="#",
        @click.prevent="closeOverlay"
      ) CANCEL
      BaseBtn.x-lg.green(
        href="#",
        :class="{disabled: !isValidEntry()}"
        @click.prevent="completeColonoscopy"
      ) CONTINUE
</template>

<script>
import { defineComponent } from "vue";
import SideOverlaySlot from "@/components/overlays/SideOverlaySlot.vue";
import PatientSection from "@/components/fit-kit/overlays/PatientSection.vue";
import UploadAndPreviewFile from "@/components/UploadAndPreviewFile.vue";
import { updateColonoscopyOutsideICC } from "@/api/fitKitApi";
import { uploadFile } from "@/api/filesApi";
import {
  getInputFormatDate,
  COLONOSCOPY_OUTSIDE_RESULT_OPTIONS,
} from "@/helpers";
import { createToast } from "mosha-vue-toastify";
import { DatePicker } from "v-calendar";
import moment from "moment";

export default defineComponent({
  components: {
    SideOverlaySlot,
    PatientSection,
    UploadAndPreviewFile,
    DatePicker,
  },
  emits: ["closeCompleteColonoscopyOverlay"],

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    outsideIccFitKit: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      date_processing: null,
      date_followup: null,
      masks: {
        input: "MM/DD/YYYY",
      },
      showSuccess: false,
      selectedResult: null,
      processingDate: null,
      followUpDate: null,
      newNotes: null,
      report: null,
      todayDate: getInputFormatDate(new Date()),
      COLONOSCOPY_OUTSIDE_RESULT_OPTIONS,
      reportStorageKey: "complete_patient_colonoscopy",
    };
  },
  watch: {
    date_processing: {
      handler: "setDate_processing",
    },
    date_followup: {
      handler: "setDate_followup",
    },
  },
  methods: {
    setDate_followup(newVal) {
      if (newVal === null) {
        this.followUpDate = "";
      } else {
        let selectionDisplay = moment(this.date_followup).format("MM/DD/YYYY");
        this.followUpDate = selectionDisplay;
      }
    },
    setDate_processing(newVal) {
      if (newVal === null) {
        this.processingDate = "";
      } else {
        let selectionDisplay = moment(this.date_processing).format(
          "MM/DD/YYYY"
        );
        this.processingDate = selectionDisplay;
      }
    },
    isValidEntry() {
      const isValidResult = this.selectedResult && this.selectedResult !== "";
      return this.processingDate && this.followUpDate && isValidResult;
    },
    closeOverlay() {
      this.selectedResult = null;
      this.processingDate = null;
      this.followUpDate = null;
      this.newNotes = null;
      this.report = null;
      this.$emit("closeCompleteColonoscopyOverlay", {
        afterAction: this.showSuccess,
      });
      this.showSuccess = false;
      sessionStorage.removeItem(this.reportStorageKey);
    },
    async completeColonoscopy() {
      if (!this.selectedResult || this.selectedResult === "") {
        console.error("Test Results is required");
        return false;
      }

      const unixProcessing = new Date(this.processingDate).getTime();
      if (!unixProcessing) {
        console.error("Date of Processing is required");
        return false;
      }
      if (unixProcessing > Date.now()) {
        console.error("Date of Processing cannot be set in the future");
        return false;
      }
      const auxDate = new Date();
      const nowDate = new Date(
        auxDate.getFullYear(),
        auxDate.getMonth() - 1,
        auxDate.getDate()
      ).getTime();
      const unixFollowUp = new Date(this.followUpDate).getTime();
      if (!unixFollowUp) {
        console.error("Follow-Up Date");
        return false;
      }
      if (unixFollowUp < nowDate) {
        console.error("Follow-Up Date cannot be set in the past");
        return false;
      }
      const idFitKit = this.outsideIccFitKit.id;
      const data = {
        result: this.selectedResult,
        processedAt: unixProcessing / 1000,
        followUpAt: unixFollowUp / 1000,
        notes: this.newNotes !== "" ? this.newNotes : null,
        updatedAt: this.outsideIccFitKit.colonoscopyOutsideICC.updatedAt,
      };

      try {
        const { file } = await uploadFile(this.reportStorageKey);
        if (file) {
          data.idReport = file.id;
        }
        const resFitKit = await updateColonoscopyOutsideICC(idFitKit, data);
        if (resFitKit.data && resFitKit.data.success) {
          this.showSuccess = true;
        }
        this.closeOverlay();
      } catch (err) {
        createToast(err?.response?.data?.message || "There was a problem", {
          timeout: 4000,
          type: "danger",
          position: "bottom-right",
        });
        console.error(err);
      }
    },
  },
});
</script>

<style scoped lang="scss"></style>
