<template lang="pug">
.card(v-if="notifications", style="padding-bottom: 40px;")
  TableTitle(title="Notifications",
    searchPlaceholder="Search by patient name"
    v-model="searchKeyword",
    @doSearch="fetchNotification",
    @blur="fetchNotification"
  )
    template(#actions)
      select.table-action-selector.table-action-selector--bell(
        @change="setFilterValue($event.target.value)"
      )
        option(value="New") NEW ONLY
        option(value="Completed") COMPLETED
        option(value="Clinic") CLINIC
        option(value="Community") COMMUNITY
        option(value="All") VIEW ALL
      DateSelector(@filterByDate="filterByDate")

  .table.table--hoverable
    .th
      .td NOTE DATE
      .td Patient Name
      .td TYPE
      .td LAST ACTIVITY
      .td DONE
    .tr(v-for="notification in notifications", :key="notification.id", @click="openProviderReferralNotesOverlay(notification)")
      .td
        div {{unixToUTCDate(notification)}} 
        .second-row-md-grey {{unixToUTCTime(notification)}}
      .td.bold  {{ getUserFormatted(notification.patient)}}
      .td
        div(:class="{'red': notification.type === 'Cancelled'}") {{notification.type}}
      .td
        div(
          :class="isCompleted(notification) ? 'green':'red'"
        ) {{isCompleted(notification) ? `Completed by ${notification.completedBy.first} ${notification.completedBy.last}` : `Not Completed` }}
        .second-row-sm-grey {{isViewed(notification) ? `Last viewed by ${notification?.lastViewedBy?.first} ${notification?.lastViewedBy?.last}` : `Not viewed`}}
      .td
        base-icon(:name="isCompleted(notification) ? 'yes': 'no'")
  Pagination(
    v-if="pagination",
    @changePagination="changePagination",
    :pagination="pagination",
    :reloadFn="fetchNotification"
  )
  .no-results(v-if="notifications != null && !notifications.length") 
    base-icon(name="exclamation") 
    span No matching records
  

ProviderReferralNotesOverlay(
  :key="`${componentKeyForReferral}-1`",
  :isOpen="isProviderReferralNotesOverlayOpen",
  @closeOverlay="closeReferralNotesOverlay"
  @fetchNotifications="fetchNotification"
  :providerReferral="sendNotification"
  showAppointmentsFor="community"
  @openViewAppointment="fetchProviderPatientReferralById"
)

ProviderReferralRecordOverlay(
  :key="`${componentKey}-1`",
  :isOpen="isProviderReferralRecordOverlayOpen",
  :showReferral="showReferral"
  tabType="contacted"
  @closeProviderReferralRecordOverlay="closeReferralRecordOverlay",
)


</template>

<script>
import { defineComponent } from "vue";
import moment from "moment";
import { getUserFormatted } from "@/helpers";
import { mapGetters } from "vuex";
import TableTitle from "@/components/TableTitle.vue";
import DateSelector from "@/components/DateSelector.vue";
import Pagination from "@/components/Pagination.vue";
import ProviderReferralNotesOverlay from "@/components/overlays/side-overlays/ProviderReferralNotesOverlay.vue";
import ProviderReferralRecordOverlay from "@/components/cco-referrals/patient-referrals/overlays/ProviderReferralRecordOverlay.vue";

import listPagesMixin from "@/mixins/listPagesMixin";
import { fetchProviderPatientReferralById } from "@/api/providerPatientReferral";

import { fetchNotification } from "@/api/notifications.ts";
import { apiStatus, apiStatusComputedFactory } from "@/api";
const { IDLE, PENDING, SUCCESS, ERROR } = apiStatus;

export default defineComponent({
  components: {
    TableTitle,
    DateSelector,
    Pagination,
    ProviderReferralNotesOverlay,
    ProviderReferralRecordOverlay,
  },
  mixins: [listPagesMixin],
  data() {
    return {
      componentKeyForReferral: 0,
      isReferralNotesOverlayOpen: false,
      isProviderReferralNotesOverlayOpen: false,

      notifications: null,
      searchKeyword: "",
      pagination: null,
      status: "New",
      date: null,
      sendNotification: null,
      isProviderReferralRecordOverlayOpen: false,
      fetchPatientReferralByIdStatus: IDLE,

      showReferral: null,
      origin: null,
      showReferralDetails: null,
      isCommunityFilter: false,
    };
  },

  computed: {
    ...mapGetters(["destinationType", "getUserRoleContext"]),
  },

  methods: {
    getUserFormatted,
    async fetchProviderPatientReferralById(referralID) {
      try {
        const res = await fetchProviderPatientReferralById(referralID, {
          params: {
            include: ["patient", "cbo", "providerReport", "referralModule"],
          },
        });

        this.showReferral = res.data.providerPatientReferral;
        this.componentKey++;
        this.isProviderReferralRecordOverlayOpen = true;
        this.fetchPatientReferralByIdStatus = SUCCESS;
      } catch (err) {
        console.error(err);
      }
    },
    closeReferralRecordOverlay() {
      this.isProviderReferralRecordOverlayOpen = false;
    },
    openProviderReferralNotesOverlay(sendNotification) {
      this.componentKeyForReferral++;

      let sendPatientInfo = {
        patient: sendNotification.patient,
        id: sendNotification.idEntity,
      };
      this.sendNotification = sendPatientInfo;
      this.isProviderReferralNotesOverlayOpen = true;
    },

    closeReferralNotesOverlay() {
      this.isProviderReferralNotesOverlayOpen = false;
      this.fetchNotification();
    },

    setFilterValue(value) {
      this.origin = null;
      this.status = null;
      this.isCommunityFilter = false;
      if (value === "New" || value === "Completed") {
        this.status = value;
      } else {
        if (value === "Clinic") {
          this.origin = "Clinic";
        } else if (value === "Community") {
          this.isCommunityFilter = true;
        }
      }
      this.fetchNotification();
    },
    filterByDate(date) {
      this.date = date;
      this.fetchNotification();
    },

    async fetchNotification() {
      const order = this.listOrder
        ? {
            by: this.listOrder.by,
            direction: this.listOrder.direction,
          }
        : null;
      try {
        let res = await fetchNotification({
          params: {
            ...this.reqPagination,
            order,
            status: this.status ?? null,
            destinationId: this.$route.params.idProvider,
            destination: "Provider",
            origin: this.origin ?? null,
            searchKeyword: this.searchKeyword || null,
            createdAt: this.date ?? null,
            include: ["completedBy", "lastViewedBy", "patient"],
          },
        });
        this.notifications = res.data.notifications;
        this.pagination = res.data.pagination;
      } catch (error) {
        console.error(error);
      }
    },

    unixToUTCDate(notification) {
      return moment.unix(notification.createdAt).format("MM-DD-YYYY");
    },
    unixToUTCTime(notification) {
      return moment.unix(notification.createdAt).format("hh:mm A");
    },
    isCompleted(notification) {
      if (notification?.completedAt) return true;
      return false;
    },
    isViewed(notification) {
      if (notification?.lastViewedBy?.email) return true;
      return false;
    },
  },
  created() {
    this.fetchNotification();
  },
});
</script>

<style scoped lang="scss">
.tr {
  cursor: pointer;
}
.td {
  &:nth-child(1) {
    width: 15%;
  }
  &:nth-child(2) {
    width: 18%;
  }
  &:nth-child(3) {
    width: 20%;
  }
  &:nth-child(4) {
    width: 40%;
  }
  &:nth-child(5) {
    width: 7%;
  }
}
</style>
