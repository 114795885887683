<template lang="pug">
.dot-count
  .item(v-for="(stat, index) in stats", :key="index")
    .number {{stat.number}}
      .dot(:style="{backgroundColor: stat.backgroundColor}")
    .txt {{stat.label}}

    
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  components: {},

  props: {
    stats: {
      type: Array,
      required: true,
    },
  },
});
</script>

<style scoped lang="scss">
.dot-count {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.item {
  display: flex;
  align-items: center;
}
.number {
  position: relative;
  margin-right: 9px;
  padding-left: 30px;
  font-size: 24px;
  line-height: 1.21;
  letter-spacing: 0.13px;
  color: #000;
}
.dot {
  display: block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.txt {
  font-size: 14px;
  line-height: 1.29;
  letter-spacing: 0.07px;
  color: $primaryDarkGrey;
}
</style>
