<template lang="pug">

section.step-subtitle
  h2 SELECT PROVIDER 
TableTitle(title="All Providers")
  template(#actions)
    select.table-action-selector.table-action-selector--insurance(
      :title="providerTypeTitle"
      @change="setProvierType($event.target.value)"
    )
      option(value="") Please Select
      option(v-for="referral in referralModules" :key="referral.id" :value="referral.id") {{ referral.moduleTitle }}

.table
  .th    
    .td Provider Name
    .td Provider Group
    .td Provider Type
    .td
    .td
  .tr(v-for="providerGroupProvider in providerGroupProviders",
    :key="providerGroupProvider.id")
    .td {{ providerGroupProvider.first }} {{ providerGroupProvider.last }}
    .td {{ providerGroupProvider.provider_group_name }}
    .td {{ providerGroupProvider.referral_module_name }} 
    .td
      BaseBtn.sm.blue-outline(
        @click="openProviderDetailsOverlay(providerGroupProvider)"
      ) PROVIDER DETAILS
    .td
      BaseBtn.sm.blue(
        @click="selectProvider(providerGroupProvider)"
      ) SELECT PROVIDER
Pagination(
  v-if="pagination",
  :pagination="pagination",
  :reloadFn="fetchProviderGroupProviders"
  @changePagination="changePagination"
)
.no-results(v-if="providerGroupProviders != null && !providerGroupProviders.length") 
  base-icon(name="exclamation") 
  span No matching records


.actions-below-table-container 
  BaseBtn.lg.green-outline(
    :to="{name: 'cco-referrals.scheduling.select-patient', query: $route.query}",
    style="min-width: 270px",
  ) &lt; PREVIOUS STEP


ProviderDetailsOverlay(
  :isOpen="isProviderDetailsOverlayOpen",
  :selectedProvider="selectedProvider",
  @closeProviderDetailsOverlay="closeProviderDetailsOverlay"
)
</template>

<script>
import { defineComponent } from "vue";

import router from "@/router";
import TableTitle from "@/components/TableTitle.vue";
import Pagination from "@/components/Pagination.vue";
import listPagesMixin from "@/mixins/listPagesMixin";

import PopupCardSlot from "@/components/PopupCardSlot.vue";

import { getProviderGroupProviders } from "@/api/providerGroupApi";
import ProviderDetailsOverlay from "@/components/cco-referrals/scheduling/overlays/ProviderDetailsOverlay.vue";
import { fetchReferralModules } from "@/api/referralModulesApi";

export default defineComponent({
  components: { TableTitle, PopupCardSlot, Pagination, ProviderDetailsOverlay },

  data() {
    return {
      selectedProvider: null,
      isProviderDetailsOverlayOpen: false,
      selectedProviderGroupProvider: null,
      providerGroupProviderTitle: "PROVIDER NAME",
      providerGroupProviders: null,
      filterIdProviderGroupProvider: null,
      referralModules: null,
      referralModuleID: null,
      providerTypeTitle: "",
    };
  },
  mixins: [listPagesMixin],
  methods: {
    setProvierType(value) {
      if (value != "") {
        this.referralModuleID = value;
        this.referralModules.forEach((referral) => {
          if (referral.id == value) {
            this.providerTypeTitle = referral.moduleTitle;
          }
        });
      } else {
        this.referralModuleID = null;
        this.providerTypeTitle = "PROVIDER TYPE";
      }
      this.fetchProviderGroupProviders();
    },
    selectProvider(providerGroupProvider) {
      router.push({
        name: "cco-referrals.scheduling.referral-info",
        query: this.$route.query,
        params: {
          idCco: this.$route.params.idCco,
          idPatient: this.$route.params.idPatient,
          idProvider: providerGroupProvider.id,
        },
      });
    },
    openProviderDetailsOverlay(provider) {
      this.selectedProvider = provider;
      this.isProviderDetailsOverlayOpen = true;
    },
    closeProviderDetailsOverlay(afterAction = false) {
      this.isProviderDetailsOverlayOpen = false;
      if (afterAction) {
        this.selectProvider(this.selectedProvider);
      }
    },
    async fetchProviderGroupProviders() {
      try {
        const res = await getProviderGroupProviders({
          params: {
            ...this.reqPagination,
            idReferralModule: this.referralModuleID ?? null,
          },
        });
        this.providerGroupProviders = res.data.providerGroupProviders;
        this.pagination = res.data.pagination;
      } catch (error) {
        console.error(error);
      }
    },
    async fetchReferralModules() {
      try {
        const res = await fetchReferralModules({
          params: {
            size: 100,
          },
        });
        this.referralModules = res.data.referralModules;
        this.pagination = res.data.pagination;
      } catch (err) {
        console.error(err);
      }
    },
  },
  created() {
    if (this.$route.params.idCco === null) return;
    this.fetchProviderGroupProviders();
    this.fetchReferralModules();
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/components/step-subtitle.scss";
@import "@/scss/components/actions-below-table-container.scss";
select.table-action-selector--insurance {
  width: 200px;
}
.td {
  &:nth-child(1) {
    width: 20.5%;
  }

  &:nth-child(2) {
    width: 20.1%;
  }

  &:nth-child(3) {
    width: 21.4%;
  }

  &:nth-child(4) {
    width: 20.4%;
  }

  &:last-child {
    // width: 17.6%;
    width: 19.5%;
  }
}
</style>
