<template lang="pug">
TableTitle(
  title="Completed Kits",
  searchPlaceholder="Search..."
  v-model="searchKeyword",
  @doSearch="fetchCompletedFitKits",
  @blur="fetchCompletedFitKits"
)
  template(#actions)
    DateSelector(@filterByDate="filterByDate")

.table
  .th
    .td(@click.prevent="changeListOrder('patientName', fetchCompletedFitKits)" style="cursor: pointer;") Patient Name
      i(style="font-weight: bolder;") {{ patientNameColumnSortingIcon }}
    .td(@click.prevent="changeListOrder('processedAt', fetchCompletedFitKits)" style="cursor: pointer;") DATE OF PROCESSING
      i(style="font-weight: bolder;") {{ processedAtColumnSortingIcon }}
    .td(@click.prevent="changeListOrder('providerName', fetchCompletedFitKits)" style="cursor: pointer;") PROVIDER NAME
      i(style="font-weight: bolder;") {{ providerNameColumnSortingIcon }}
    .td
    .td
    .td
  .tr(v-for="fitKit in completedFitKits" :key="fitKit.id")
    .td.bold {{`${fitKit.patient.first} ${fitKit.patient.last}`}} 
    .td {{getDateFromUnixFormatted(fitKit.processedAt)}}
    .td {{getDoctorFormatted(fitKit.providerUser)}}
    .td
      BaseBtn.sm.blue-outline(
           href="#",
          @click.prevent="openClinicPatientDetailsOverlay(fitKit.patient)"
        ) PATIENT DETAILS
    .td
      BaseBtn.sm.blue-outline(
        href="#",
        @click.prevent="openCompletedFitOverlay(getFitKitCompletion(fitKit))"
      ) FIT DETAILS
    .td
      BaseBtn.sm.blue(:to="{name: 'new-fit-kit.search-patient', params: {firstName: fitKit.patient.first, lastName: fitKit.patient.last} }") ADD NEW FIT
Pagination(
  v-if="pagination && completedFitKits != null && completedFitKits.length",
  @changePagination="changePagination",
  :pagination="pagination",
  :reloadFn="fetchCompletedFitKits"
)
.no-results(v-if="completedFitKits != null && !completedFitKits.length") 
  base-icon(name="exclamation") 
  span No matching records

CompletedFitOverlay(
  :isOpen="isCompletedFitOverlayOpen",
  :selectedFitKit="selectedFitKit"
  @closeCompletedFitOverlay="closeCompletedFitOverlay",
  @openCompleteFitOverlay="openCompleteFitOverlay"
)
CompleteFitOverlay(
  :isOpen="isCompleteFitOverlayOpen",
  :isEdit="true",
  :selectedFitKit="selectedFitKit",
  @closeCompleteFitOverlay="closeCompleteFitOverlay",
)
ClinicPatientDetailsOverlay(
  :key="`${componentKey}-1`"
  :showPatient="showPatient",
  :isOpen="isClinicPatientDetailsOverlay",
  @closeClinicPatientDetalisOverlay="closeClinicPatientDetailsOverlay"
)
</template>

<script>
import { defineComponent } from "vue";
import TableTitle from "@/components/TableTitle.vue";
import DateSelector from "@/components/DateSelector.vue";
import Pagination from "@/components/Pagination.vue";
import CompletedFitOverlay from "@/components/fit-kit/overlays/CompletedFitOverlay.vue";
import CompleteFitOverlay from "@/components/fit-kit/overlays/CompleteFitOverlay.vue";
import ClinicPatientDetailsOverlay from "@/components/clinics/patient-search/overlays/PatientDetalisOverlay.vue";
import { fetchFitKits } from "@/api/fitKitApi";
import {
  reformatPagination,
  checkEditable,
  getDoctorFormatted,
  getDateFromUnixFormatted,
} from "@/helpers";
import listPagesMixin from "@/mixins/listPagesMixin";
import sortFitKits from "@/mixins/sortFitKits";

export default defineComponent({
  components: {
    TableTitle,
    DateSelector,
    Pagination,
    CompletedFitOverlay,
    CompleteFitOverlay,
    ClinicPatientDetailsOverlay,
  },

  mixins: [listPagesMixin, sortFitKits],

  data() {
    return {
      completedFitKits: null,
      selectedFitKit: null,
      isCompletedFitOverlayOpen: false,
      isCompleteFitOverlayOpen: false,
      date: null,
      searchKeyword: "",
      showPatient: null,
      isClinicPatientDetailsOverlay: false,
    };
  },

  methods: {
    openClinicPatientDetailsOverlay(patient) {
      this.componentKey = this.componentKey + 1;
      this.showPatient = patient;
      this.isClinicPatientDetailsOverlay = true;
    },
    closeClinicPatientDetailsOverlay() {
      this.isClinicPatientDetailsOverlay = false;
    },
    filterByDate(date) {
      this.date = date;
      this.fetchCompletedFitKits();
    },
    openCompletedFitOverlay(selectedFitKit) {
      this.selectedFitKit = selectedFitKit;
      this.isCompletedFitOverlayOpen = true;
    },
    closeCompletedFitOverlay() {
      this.isCompletedFitOverlayOpen = false;
    },
    openCompleteFitOverlay(fitKit) {
      this.selectedFitKit = fitKit;
      this.isCompleteFitOverlayOpen = true;
    },
    closeCompleteFitOverlay({ afterAction }) {
      this.isCompleteFitOverlayOpen = false;
      if (afterAction) {
        this.$router.go(0);
      }
    },
    checkEditable: checkEditable,
    getDoctorFormatted: getDoctorFormatted,
    getDateFromUnixFormatted: getDateFromUnixFormatted,
    getFitKitCompletion(fitKit) {
      const {
        id,
        idClinic,
        patient,
        kitNumber,
        providerUser,
        result,
        details,
        fluVaccine,
        covidVaccine,
        location,
        insurance,
        createdAt,
        updatedAt,
        processedAt,
        completionUser,
        completionNotes,
        referral,
        colonoscopyInsideICC,
        colonoscopyOutsideICC,
      } = fitKit;
      const isEditable = this.checkEditable(fitKit);
      const initialCompletion = {
        result,
        details,
        fluVaccine,
        covidVaccine,
        location,
        insurance,
        createdAt,
        processedAt,
        completionUser,
        completionNotes,
        isEditable,
      };
      return {
        id,
        idClinic,
        updatedAt,
        patient,
        kitNumber,
        providerUser,
        initialCompletion,
        referral,
        colonoscopyInsideICC,
        colonoscopyOutsideICC,
      };
    },
    async fetchCompletedFitKits() {
      const order = this.listOrder
        ? {
            by: this.listOrder.by,
            direction: this.listOrder.direction,
          }
        : null;
      const params = {
        order,
        ...this.reqPagination,
        idClinic: this.$route.params.idClinic,
        include: [
          "patient",
          "providerUser",
          "completionUser",
          "hasColonoscopyOrReferral",
        ],
        status: "completed",
        searchKeyword: this.searchKeyword,
        processedAt: this.date,
      };
      if (this.date === null) delete params.processedAt;
      if (!!this.searchKeyword !== true) delete params.searchKeyword;
      if (order === null) delete params.order;
      try {
        const resFitKit = await fetchFitKits({
          params: {
            ...params,
          },
        });
        this.completedFitKits = resFitKit.data.fitKits;
        this.pagination = reformatPagination(resFitKit.data.pagination);
      } catch (err) {
        console.error(err);
      }
    },
  },
  created() {
    if (this.$route.params.idClinic === null) return;
    this.fetchCompletedFitKits();
  },
});
</script>

<style scoped lang="scss">
.tr {
  cursor: pointer;
}
.td {
  &:nth-child(1) {
    width: math.percentage(math.div(258, $tabContentCardWidth));
  }
  &:nth-child(2) {
    width: math.percentage(math.div(200, $tabContentCardWidth));
  }
  &:nth-child(3) {
    width: math.percentage(math.div(205, $tabContentCardWidth));
  }
  &:nth-child(4) {
    width: math.percentage(math.div(175, $tabContentCardWidth));
  }
  &:nth-child(5) {
    width: math.percentage(math.div(175, $tabContentCardWidth));
  }
  &:nth-child(6) {
    width: math.percentage(math.div(175, $tabContentCardWidth));
  }
  .btn {
    display: block;
    min-width: 0;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}
</style>
