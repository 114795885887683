<template lang="pug">
SideOverlaySlot(
  :isOpen="isOpen",
  title="Ongoing Care"
  @closeOverlay="closeOverlay"
)
  template(#contentTop)
    section.patient-section
      p <strong>Patient: John Doe </strong>
      p <strong>03/20/2020</strong> &nbsp; 10:30 AM
  template(#scrollbox)
    .title.title--md Appointment History
    .table
      .th
        .td Appt. Time
        .td COMPLETED
        .td
      .tr
        .td
          div 07-30-2020
          .second-row-md-grey 6:30 PM
        .td
          base-icon(name="yes")
        .td
          BaseBtn.sm.blue(
            href="#",
            @click.prevent="openAppointmentRecordOverlay"
          ) VIEW APPT
      .tr
        .td
          div 07-30-2020
          .second-row-md-grey 6:30 PM
        .td
          base-icon(name="yes")
        .td
          BaseBtn.sm.blue(
            href="#",
            @click.prevent="openAppointmentRecordOverlay"
          ) VIEW APPT
    .title.title--md Next Appointment
    .next-apt
      div
        div 07-30-2020
        .second-row-md-grey 6:30 PM
      BaseBtn.sm.blue(
        href="#",
        @click.prevent="openAppointmentRecordOverlay"
      ) VIEW APPT
</template>

<script>
import { defineComponent } from "vue";
import SideOverlaySlot from "@/components/overlays/SideOverlaySlot.vue";

export default defineComponent({
  components: { SideOverlaySlot },
  emits: ["closeManageCareOverlay", "openAppointmentRecordOverlay"],
  data() {
    return {};
  },

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    closeOverlay() {
      this.$emit("closeManageCareOverlay");
    },
    openAppointmentRecordOverlay() {
      this.$emit("openAppointmentRecordOverlay");
    },
  },
});
</script>

<style scoped lang="scss">
.patient-section {
  margin-bottom: 31px;
  padding: 37px 24px 25px;
  background-color: $primaryBgBlue;
  p {
    margin: 0 0 12px;
    font-size: 21px;
    font-weight: 500;
    line-height: 1.1;
    color: $primaryLightBlue;
    overflow: hidden;
    strong {
      font-weight: 700;
    }
  }
}
.btn.sm.blue {
  min-width: 120px;
  width: 120px;
  padding-left: 2px;
  padding-right: 2px;
}
.title.title--md {
  margin-bottom: 25px;
}

.table {
  margin-bottom: 25px;
  .tr {
    &:hover {
      background-color: transparent;
      cursor: default;
    }
  }
  .td {
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 2px;
    }
    &:nth-child(1) {
      width: math.percentage(math.div(154, 438));
    }
    &:nth-child(2) {
      width: math.percentage(math.div(160, 438));
    }
    &:nth-child(3) {
      width: math.percentage(math.div(126, 438));
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
    }
  }
}
.next-apt {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -7px;
  margin-bottom: 14px;
}
</style>
