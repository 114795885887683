<template lang="pug">
.content-wrapper
    .card.router-view-card
        section.users
            TableTitle(title="Users")
                template(#actions)
                    BaseBtn.x-lg.blue(@click.prevent="openManageUsersProvidersOverlay('ADD NEW USER')") ADD NEW
            .table
                .th
                    .td(@click.prevent="changeListOrder('name', fetchUser)" style="cursor: pointer;") USER NAME
                        i(style="font-weight: bolder;") {{ nameColumnSortingIcon }}
                    .td(@click.prevent="changeListOrder('role', fetchUser)" style="cursor: pointer;") ROLE
                        i(style="font-weight: bolder;") {{ roleColumnSortingIcon }}
                    .td ACTIVE
                    .td
                .tr(v-for="user in Users", :key="user.id")
                    .td.bold {{ user.first}} {{ user.last }}
                    .td.bold {{ user.role }}
                    .td
                        BaseToggle(label="" :modelValue=" !user.disabled " :key="user.id" @change="toggleUserActive(user.id, $event.target.checked)" )
                    .td
                        BaseBtn.sm.blue-outline(
                          v-if="user.role == 'Provider'",
                          href="#", @click.prevent="goToProviderDashboard(user)") SELECT
                    .td
                        BaseBtn.sm.blue(
                            href="#", @click.prevent="openManageUsersProvidersOverlay('MANAGE USER',user)") MANAGE
        Pagination(
            v-if="pagination",
            @changePagination="changePagination",
            :pagination="pagination",
            :reloadFn="fetchUser"
        )
        .actions-below-table-container
        ManageUsersProvidersOverlay(
            :showProvider="showProvider",
            :key="`${componentKey}-1`",
            :isOpen="isManageUsersProvidersOverlayOpen",
            :overlayTitle="overlayTitle",
            @closeManageUsersProvidersOverlay="closeManageUsersProvidersOverlay"
        )
    </template>

<script>
import { defineComponent } from "vue";
import TableTitle from "@/components/TableTitle.vue";
import ManageUsersProvidersOverlay from "@/components/users/overlays/ManageUsersProvidersOverlay.vue";
import Pagination from "@/components/Pagination.vue";
import listPagesMixin from "@/mixins/listPagesMixin";
import sortUsers from "@/mixins/sortUsers";
import { userRoles } from "@/store/account";

import { fetchUser, deleteUser, patchUser } from "@/api/userApi";
import { apiStatus, apiStatusComputedFactory } from "@/api";
const { IDLE, PENDING, SUCCESS, ERROR } = apiStatus;

import { USER_ROLES } from "@/helpers/constants";
import { mapGetters } from "vuex";

export default defineComponent({
  components: {
    TableTitle,
    ManageUsersProvidersOverlay,
    Pagination,
  },
  mixins: [listPagesMixin, sortUsers],

  data() {
    return {
      isManageUsersProvidersOverlayOpen: false,
      showProvider: null,
      overlayTitle: "MANAGE USER",
      fetchUserStatus: IDLE,
      patchUserStatus: IDLE,
      deleteUserStatus: IDLE,
      Users: null,
      USER_ROLES,
    };
  },
  computed: {
    ...mapGetters(["getManagerOfProviderID"]),
    ...apiStatusComputedFactory(
      "fetchUserStatus",
      "patchUserStatus",
      "deleteUserStatus"
    ),
  },
  methods: {
    toggleUserActive(id, checked) {
      if (checked === true) {
        this.patchUser(id);
      } else {
        this.deleteUser(id);
      }
    },
    async patchUser(userId) {
      this.patchUserStatus = PENDING;
      try {
        const res = await patchUser(userId);
        this.patchUserStatus = SUCCESS;
      } catch (err) {
        this.patchUserStatus = ERROR;
        console.error(err);
      }
    },
    async deleteUser(userId) {
      this.deleteUserStatus = PENDING;
      try {
        const res = await deleteUser(userId);
        this.deleteUserStatus = SUCCESS;
      } catch (err) {
        this.deleteUserStatus = ERROR;
        console.error(err);
      }
    },
    async fetchUser() {
      this.fetchUserStatus = PENDING;
      const order = this.listOrder
        ? {
            by: this.listOrder.by,
            direction: this.listOrder.direction,
          }
        : undefined;
      try {
        const res = await fetchUser({
          params: {
            order,
            ...this.reqPagination,
            filters: {
              idProviderGroup: +this.$route.params.idProviderGroup,
            },
          },
        });

        this.Users = res.data.users;
        this.pagination = res.data.pagination;

        this.Users.forEach((element) => {
          this.USER_ROLES.forEach((userRole) => {
            if (element.idUserRole == userRole.id) {
              element.role = userRole.name;
            }
          });
          if (!element.role) {
            element.role = element.idUserRole;
          }
        });
        this.fetchUserStatus = SUCCESS;
      } catch (err) {
        this.fetchUserStatus = ERROR;
        console.error(err);
      }
    },
    goToProviderDashboard(user) {

      this.$store.dispatch("impersonate", {
        role: userRoles.provider.find((role) => role.name === "Provider"),
        providerGroup: this.getManagerOfProviderID,
        impersonateProviderID: user.id,
      });
      this.$router.push({
        name: "provider-groups",
        params: {
          idProvider: user.id,
        },
      });
    },
    openManageUsersProvidersOverlay(overlayTitle, user) {
      this.componentKey = this.componentKey + 1;
      if (user == null) {
        this.showProvider = {
          first: "",
          last: "",
          email: "",
          details: "",
        };
      } else {
        this.showProvider = user;
      }
      this.isManageUsersProvidersOverlayOpen = true;
      this.overlayTitle = overlayTitle;
    },
    closeManageUsersProvidersOverlay() {
      this.fetchUser();
      this.isManageUsersProvidersOverlayOpen = false;
    },
  },
  created() {
    this.fetchUser();
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/components/step-subtitle.scss";

.users {
  .td {
    &:nth-child(1) {
      width: math.percentage(math.div(302, $tabContentCardWidth));
    }

    &:nth-child(2) {
      width: math.percentage(math.div(300, $tabContentCardWidth));
    }

    &:nth-child(3) {
      width: math.percentage(math.div(150, $tabContentCardWidth));
    }

    &:nth-child(4) {
      width: math.percentage(math.div(200, $tabContentCardWidth));
    }

    &:nth-child(5) {
      width: math.percentage(math.div(200, $tabContentCardWidth));
    }
  }
}

.content-wrapper {
  position: relative;
  padding-bottom: 40px;
}

.router-view-card {
  padding-top: 0;
  position: relative;
  width: 100%;
  z-index: 1;
}

.table .tr > .td {
  display: flex;
  align-items: center;
}

.table .td .btn {
  /* width: 100%; */
}
label.label--margin-sm {
  margin-bottom: 0px;
}
</style>
