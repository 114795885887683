<template lang="pug">
BaseBtn.btn.lg.blue(
  href="#",
  @click.prevent=""
  :isDropdown="true",
  txt="ADD NEW FIELD"
)
  template(#dropdown-menu)
    li
      a(href="#", @click.prevent="emitAddFormField('NUMERIC')") Numeric Field
    li
      a(href="#", @click.prevent="emitAddFormField('TEXT')") Text Field
    li
      a(href="#", @click.prevent="emitAddFormField('DATE')") Date Field
    li
      a(href="#", @click.prevent="emitAddFormField('DROPDOWN')") Dropdown
    li
      a(href="#", @click.prevent="emitAddFormField('FILE')") File Upload
    li
      a(href="#", @click.prevent="emitAddFormField('RADIO')") Radio Yes/No
    li
      a(href="#", @click.prevent="emitAddFormField('SECTION_TITLE')") Section Title
    li
      a(href="#", @click.prevent="emitAddFormField('DESCRIPTION')") Description
    li
      a(href="#", @click.prevent="emitAddFormField('NOTES')") Notes
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["addFormField"],

  props: {
    formModel: {
      required: true,
    },
  },

  data() {
    return {};
  },

  methods: {
    emitAddFormField(fieldType) {
      this.$emit("addFormField", fieldType, this.formModel);
    },
  },
});
</script>
