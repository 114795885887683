<template lang="pug">
section.fit-referrals.card
  TableTitle(title="Insurances")
    template(#actions)
      BaseBtn.x-lg.blue(href="#", @click.prevent="openAddInsuranceOverlay") ADD NEW
  .table
    .th
      .td(@click.prevent="changeListOrder('InsuranceName', fetchInsurances)" style="cursor: pointer;") INSURANCE NAME
        i(style="font-weight: bolder;") {{ InsuranceNameColumnSortingIcon }}
      .td ACTIVE
      .td
    .tr(v-for="insurance in insurances", :key="insurance.id")
      .td.bold {{insurance.name}}
      .td
        BaseToggle(@update:modelValue="toggleInsuranceStatus(insurance.id)" :modelValue="!insurance.disabled")
      .td
        BaseBtn.sm.blue(href="#", @click.prevent="openManageInsuranceOverlay(insurance)") MANAGE
  Pagination(
    v-if="pagination",
    :pagination="pagination",
    :reloadFn="fetchInsurances"
    @changePagination="changePagination"
  )
  AddInsuranceOverlay(
    :isOpen="isAddInsuranceOverlayOpen",
    @closeAddInsuranceOverlay="closeAddInsuranceOverlay"
  )
  ManageInsuranceOverlay(
    :key="`${componentKey}-1`"
    :isOpen="isManageInsuranceOverlayOpen",
    :id="insuranceToManage?.id || null"
    @closeManageInsuranceOverlay="closeManageInsuranceOverlay"
  )
</template>

<script>
import { defineComponent } from "vue";
import TableTitle from "@/components/TableTitle.vue";
import Pagination from "@/components/Pagination.vue";
import AddInsuranceOverlay from "@/components/system-settings/overlays/AddInsuranceOverlay.vue";
import ManageInsuranceOverlay from "@/components/system-settings/overlays/ManageInsuranceOverlay.vue";
import listPagesMixin from "@/mixins/listPagesMixin";
import { apiStatus, apiStatusComputedFactory } from "@/api";
import {
  fetchInsurances,
  activateInsurance,
  deactivateInsurance,
} from "@/api/insuranceApi";
import sortInsurances from "@/mixins/sortInsurances";

const { IDLE, PENDING, ERROR, SUCCESS } = apiStatus;
export default defineComponent({
  components: {
    TableTitle,
    Pagination,
    AddInsuranceOverlay,
    ManageInsuranceOverlay,
  },
  mixins: [listPagesMixin, sortInsurances],
  data() {
    return {
      isAddInsuranceOverlayOpen: false,
      isManageInsuranceOverlayOpen: false,
      fetchInsuranceStatus: IDLE,
      activateInsuranceStatus: IDLE,
      deactivateInsuranceStatus: IDLE,
      insurances: null,
      insuranceToManage: null,
    };
  },
  computed: {
    ...apiStatusComputedFactory(["fetchInsuranceStatus"]),
  },
  methods: {
    openAddInsuranceOverlay() {
      this.isAddInsuranceOverlayOpen = true;
    },
    closeAddInsuranceOverlay() {
      this.isAddInsuranceOverlayOpen = false;
      this.fetchInsurances();
    },
    openManageInsuranceOverlay(insurance) {
      this.forceRerender();
      this.insuranceToManage = insurance;
      this.isManageInsuranceOverlayOpen = true;
    },
    closeManageInsuranceOverlay() {
      this.isManageInsuranceOverlayOpen = false;
      this.fetchInsurances();
    },
    async fetchInsurances() {
      this.fetchInsuranceStatus = PENDING;
      const order = this.listOrder
        ? {
            by: this.listOrder.by,
            direction: this.listOrder.direction,
          }
        : null;
      try {
        const { data } = await fetchInsurances({
          params: {
            ...this.reqPagination,
            order,
          },
        });
        this.insurances = data.insurances;
        this.pagination = data.pagination;
        this.fetchInsuranceStatus = SUCCESS;
      } catch (err) {
        this.fetchInsuranceStatus = ERROR;
        console.error(err);
      }
    },
    async activateInsurance(insurance) {
      this.activateInsuranceStatus = PENDING;
      try {
        await activateInsurance(insurance.id, { insurance });
        this.activateInsuranceStatus = SUCCESS;
        await this.fetchInsurances();
      } catch (error) {
        console.log(error);
        this.activateInsuranceStatus = ERROR;
      }
    },
    async deactivateInsurance(insurance) {
      this.deactivateInsuranceStatus = PENDING;
      try {
        await deactivateInsurance(insurance.id, { insurance });
        this.deactivateInsuranceStatus = SUCCESS;
        await this.fetchInsurances();
      } catch (error) {
        console.log(error);
        this.deactivateInsuranceStatus = ERROR;
      }
    },
    toggleInsuranceStatus(id) {
      const insuranceToToggle = this.insurances.find(
        (insurance) => insurance.id === id
      );
      if (insuranceToToggle) {
        if (insuranceToToggle.disabled) {
          return this.activateInsurance(insuranceToToggle);
        }

        return this.deactivateInsurance(insuranceToToggle);
      }
    },
  },
  created() {
    this.fetchInsurances();
  },
});
</script>

<style scoped lang="scss">
.td {
  &:nth-child(1) {
    width: math.percentage(math.div(744, $tabContentCardWidth));
  }
  &:nth-child(2) {
    width: math.percentage(math.div(205, $tabContentCardWidth));
  }
  &:last-child {
    width: math.percentage(math.div(229, $tabContentCardWidth));
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}
</style>
