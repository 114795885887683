<template lang="pug">
SideOverlaySlot(
  :isOpen="isOpen",
  title="ADD NEW FIT KIT DISTRIBUTION",
  @closeOverlay="closeOverlay"
)
  template(#contentTop)
    section.patient-section
      p <strong>Patient: {{`${selectedPatient.first} ${selectedPatient.last}`}} </strong>
      p <strong>DOB: {{getDobFormatted(selectedPatient.dob, '/')}}</strong>

    form#add-new-fit-kit-distribution(
      v-if="!showFitAdded",
      @submit.prevent="addNewFITKitDistribution"
      novalidate
    )
      .columns
        .column
          .form-group(:class="{ 'form-group__invalid': v$.kitNumber.$errors.length }")
            label(for="kit-nr") Kit#
            input#kit-nr(type="text", v-model="kitNumber" @blur="v$.kitNumber.$touch()")
            .form-group__errors(v-if="v$.kitNumber.$dirty && v$.kitNumber.$errors.length")
              .form-group__error(v-if="v$.kitNumber.required.$invalid") Kit# is required!
      .columns
        .column.is-half
          .form-group(:class="{ 'form-group__invalid': v$.distributedDate.$errors.length }")
            label(for="date-distributed") Date Distributed
            DatePicker(
              v-model="date" 
              :masks="masks"
              :maxDate="new Date()"
            )
              template(v-slot="{ inputValue, inputEvents }")
                input(
                  :value="inputValue"  
                  v-on="inputEvents"
                  placeholder="MM/DD/YYYY"
                  @blur="v$.distributedDate.$touch()"
                )
            .form-group__errors(v-if="v$.distributedDate.$dirty && v$.distributedDate.$errors.length")
              .form-group__error(v-if="v$.distributedDate.required.$invalid || v$.distributedDate.minLength.$invalid || v$.distributedDate.validDate.$invalid ") Date Distributed is required!
              .form-group__error(v-if="!v$.distributedDate.required.$invalid && v$.distributedDate.checkFuture.$invalid ") Date Distributed cannot be set in the future!

        .column.is-half
          .form-group(:class="{ 'form-group__invalid': v$.location.$errors.length }")
            label(for="received-location") Location
            select#received-location(v-model="location" @blur="v$.location.$touch()")
              option(:value="'Mail'") Mail
              option(:value="'Clinic'") Clinic
              option(:value="'Outreach Event'") Outreach Event
            .form-group__errors(v-if="v$.location.$dirty && v$.location.$errors.length")
              .form-group__error(v-if="v$.location.required.$invalid") Field is required!
      .columns
        .column
          .form-group(:class="{ 'form-group__invalid': v$.selectedProvider.$errors.length }")
            label(for="provider-name") Provider Name
            select#provider-name(v-model="selectedProvider" @blur="v$.selectedProvider.$touch()")
              option(value="" disabled) Please Select
              option(
                v-for="provider in providers",
                :key="provider.id"
                :value="provider.id"
                :selected="provider.id === selectedProvider"
              ) {{ `${provider.first} ${provider.last}` }}
            .form-group__errors(v-if="v$.selectedProvider.$dirty && v$.selectedProvider.$errors.length")
              .form-group__error(v-if="v$.selectedProvider.required.$invalid") Provider name is required!
      .columns
        .column.is-half
          .form-group(:class="{ 'form-group__invalid': v$.fluVaccine.$errors.length }")
            label(for="received-flu-vaccine") Flu vaccine
            select#received-flu-vaccine(v-model="fluVaccine" @blur="v$.fluVaccine.$touch()")
              option(:value="false") No
              option(:value="true") Yes
            .form-group__errors(v-if="v$.fluVaccine.$dirty && v$.fluVaccine.$errors.length")
              .form-group__error(v-if="v$.fluVaccine.required.$invalid") Field is required!
        .column.is-half
            .form-group(:class="{ 'form-group__invalid': v$.covidVaccine.$errors.length }")
              label(for="received-covid-vaccine") COVID vaccine
              select#received-covid-vaccine(v-model="covidVaccine" @blur="v$.covidVaccine.$touch()")
                option(:value="false") No
                option(:value="true") Yes
              .form-group__errors(v-if="v$.covidVaccine.$dirty && v$.covidVaccine.$errors.length")
                .form-group__error(v-if="v$.covidVaccine.required.$invalid") Field is required!
      .columns
        .column.is-half
          .form-group(:class="{ 'form-group__invalid': v$.insurance.$errors.length }")
            label(for="received-insurance") Insurance Options
            select#received-insurance(v-model="insurance" @blur="v$.insurance.$touch()")
              option(:value="'Insured'") Insured
              option(:value="'Uninsured'") Uninsured
            .form-group__errors(v-if="v$.insurance.$dirty && v$.insurance.$errors.length")
              .form-group__error(v-if="v$.insurance.required.$invalid") Field is required!
            .columns
        .column.is-half(v-if="insurance === 'Insured'")
            .form-group(:class="{ 'form-group__invalid': v$.insuredType.$errors.length }")
              label(for="received-insured-type" :style="{opacity: 0}") Insured Type
              select#received-insured-type(v-model="insuredType" @blur="v$.insuredType.$touch()")
                option(:value="'Private/Employer'") Private/Employer
                option(:value="'Medicare/Medicaid'") Medicare/Medicaid
              .form-group__errors(v-if="v$.insuredType.$dirty && v$.insuredType.$errors.length")
                .form-group__error(v-if="v$.insuredType.required.$invalid") Field is required!

    .overlay-show-success(v-else="")
      p FIT successfully added!
    .flex.flex--space-between(v-if="!showFitAdded")
      BaseBtn.x-lg.red-outline(
        href="#",
        @click.prevent="closeOverlay"
      ) CANCEL
      BaseBtn.x-lg.blue(
        type="submit",
        form="add-new-fit-kit-distribution"
        :disabled="v$.$invalid || isSubmitting"
        :class="{spinner: isSubmitting }"
      ) CONTINUE
    .flex.flex--space-between(v-else="")
      BaseBtn.x-lg.blue-outline(:to="{name: 'community-care-organization-fit-kit-status.all-outstanding', params: { idCommunity }}") FIT KIT STATUS
      BaseBtn.x-lg.blue(@click.prevent="goToAddNewFit()") ADD NEW FIT
</template>

<script>
import { defineComponent } from "vue";
import SideOverlaySlot from "@/components/overlays/SideOverlaySlot.vue";
import { createFitKit } from "@/api/fitKitApi";
import { fetchFitKitProviders } from "@/api/communityOrganization";
import { getDobFormatted, getInputFormatDate } from "@/helpers";
import useVuelidate from "@vuelidate/core";
import { required, minLength, requiredIf } from "@vuelidate/validators";
import { createToast } from "mosha-vue-toastify";
import { DatePicker } from "v-calendar";
import moment from "moment";

export default defineComponent({
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    let args = {
      kitNumber: { required },
      selectedProvider: { required },
      distributedDate: {
        required,
        minLength: minLength(10),
        validDate() {
          const unixDistributed = new Date(this.distributedDate).getTime();
          if (!unixDistributed) {
            return false;
          }
          return true;
        },
        checkFuture() {
          const unixDistributed = new Date(this.distributedDate).getTime();
          if (unixDistributed > Date.now()) {
            return false;
          }
          return true;
        },
      },
      fluVaccine: { required },
      covidVaccine: { required },
      location: { required },
      insurance: { required },
      insuredType: {
        required: requiredIf(function () {
          return this.insurance === "Insured";
        }),
      },
    };
    return args;
  },
  components: { SideOverlaySlot, DatePicker },
  emits: ["closeAddNewFITKitDistributionOverlay"],

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    idCommunity: {
      type: Number,
      required: true,
    },
    selectedPatient: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      masks: {
        input: "MM/DD/YYYY",
      },
      date: null,
      showFitAdded: false,
      providers: null,
      fluVaccine: false,
      covidVaccine: false,
      location: null,
      insurance: null,
      insuredType: null,
      kitNumber: null,
      todayDate: getInputFormatDate(new Date()),
      selectedProvider: "",
      distributedDate: null,
      isSubmitting: false,
    };
  },
  methods: {
    setDate(newVal) {
      if (newVal === null) {
        this.distributedDate = "";
      } else {
        let selectionDisplay = moment(this.date).format("MM/DD/YYYY");
        this.distributedDate = selectionDisplay;
      }
    },
    closeOverlay() {
      this.$emit("closeAddNewFITKitDistributionOverlay");
    },
    getDobFormatted,
    goToAddNewFit() {
      this.$router.go(0);
    },

    async fetchFitKitsProviders() {
      try {
        const resFitKit = await fetchFitKitProviders(this.idCommunity, {
          params: {
            filters: {
              confirmed: true,
            },
            order: {
              by: "user-full-name",
              direction: "asc",
            },
            page: 1,
            size: 1000,
          },
        });
        this.providers = resFitKit.data.users;
      } catch (err) {
        console.error(err);
      }
    },
    async addNewFITKitDistribution() {
      const unixDistributed = new Date(this.distributedDate).getTime();
      if (!unixDistributed) {
        console.error("Date Distributed is required");
        return false;
      }
      if (unixDistributed > Date.now()) {
        console.error("Date Distributed cannot be set in the future");
        return false;
      }
      if (this.insurance === "Insured" && !this.insuredType) {
        createToast("Insured type is required", {
          timeout: 4000,
          type: "danger",
          position: "bottom-right",
        });
        return false;
      }
      this.isSubmitting = true;

      const data = {
        distributedAt: unixDistributed / 1000,
        fluVaccine: this.fluVaccine,
        covidVaccine: this.covidVaccine,
        location: this.location,
        insurance: this.insuredType
          ? `${this.insurance} - ${this.insuredType}`
          : this.insurance,
        idCommunity: this.idCommunity,
        idPatient: this.selectedPatient.id,
        idProviderUser: this.selectedProvider,
        kitNumber: this.kitNumber,
      };
      try {
        const resFitKit = await createFitKit(data);
        if (resFitKit.data.success) {
          this.showFitAdded = true;
        }
      } catch (err) {
        createToast(err?.response?.data?.message || "There was a problem", {
          timeout: 4000,
          type: "danger",
          position: "bottom-right",
        });
        console.error(err);
      } finally {
        this.isSubmitting = false;
      }
    },
  },
  watch: {
    date: {
      handler: "setDate",
    },
  },
  created() {
    if (this.idCommunity === null) return;
    this.fetchFitKitsProviders();
  },
});
</script>

<style scoped lang="scss">
.patient-section {
  margin-bottom: 0;
  padding: 37px 24px 25px;
  background-color: $primaryBgBlue;
  p {
    margin: 0 0 12px;
    font-size: 21px;
    font-weight: 500;
    line-height: 1.1;
    color: $primaryLightBlue;
    overflow: hidden;
    strong {
      font-weight: 700;
    }
  }
}
form#add-new-fit-kit-distribution {
  margin: 47px 0 43px;
}
.overlay-show-success {
  p {
    margin: 41px 0 60px;
  }
}
</style>
