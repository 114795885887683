<template lang="pug">
SideOverlaySlot(
  :isOpen="isOpen",
  :title="overlayTitle",
  @closeOverlay="closeOverlay"
)
  template(#contentTop)
    form#manage-user(
      @submit.prevent="saveAndCloseOverlay",
      novalidate
    )
      .form-group(:class="{ 'form-group__invalid': v$.showAdmin.idUserRole.$errors.length }")
        label(for="user-type") User Type (Required)
        select#user-type(v-model="showAdmin.idUserRole" :disabled="overlayTitle !== 'ADD NEW USER'" @blur="v$.showAdmin.idUserRole.$touch()")
          option(value="") Select Role
          option(
            v-for="role in superAdminRoles" 
            :key="role.id" 
            :value="role.id"
          ) {{ role.name }}
        .form-group__errors(v-if="v$.showAdmin.idUserRole.$dirty && v$.showAdmin.idUserRole.$errors.length")
          .form-group__error(v-if="v$.showAdmin.idUserRole.required.$invalid") User role is required!
      .columns
        .column.is-half
          .form-group(:class="{ 'form-group__invalid': v$.showAdmin.first.$errors.length }")
            label(for="first-name") First Name (Required)
            input#first-name(type="text" v-model="showAdmin.first" @blur="v$.showAdmin.first.$touch()")
            .form-group__errors(v-if="v$.showAdmin.first.$dirty && v$.showAdmin.first.$errors.length")
              .form-group__error(v-if="v$.showAdmin.first.required.$invalid") User first name is required!
        .column.is-half
          .form-group(:class="{ 'form-group__invalid': v$.showAdmin.last.$errors.length }")
            label(for="last-name") Last Name (Required)
            input#last-name(type="text" v-model="showAdmin.last" @blur="v$.showAdmin.last.$touch()")
            .form-group__errors(v-if="v$.showAdmin.last.$dirty && v$.showAdmin.last.$errors.length")
              .form-group__error(v-if="v$.showAdmin.last.required.$invalid") User last name is required!
      .form-group( v-if=" overlayTitle=='ADD NEW USER' " :class="{ 'form-group__invalid': v$.showAdmin.password.$errors.length }")
        label(for="password") Password (Required)
        input#password(
          type="password" 
          autocomplete="current-password"
          v-model="showAdmin.password"
          @blur="v$.showAdmin.password.$touch()"
        )
        .form-group__errors(v-if="v$.showAdmin.password.$dirty && v$.showAdmin.password.$errors.length")
          .form-group__error(v-if="v$.showAdmin.password.required.$invalid") Password is required!
      .form-group(:class="{ 'form-group__invalid': v$.showAdmin.email.$errors.length }")
        label(for="email") Email (Required)
        input#email(type="email" v-model="showAdmin.email" @blur="v$.showAdmin.email.$touch()")
        .form-group__errors(v-if="v$.showAdmin.email.$dirty && v$.showAdmin.email.$errors.length")
          .form-group__error(v-if="v$.showAdmin.email.email.$invalid") Invalid email format
          .form-group__error(v-if="v$.showAdmin.email.required.$invalid") User email is required!
      .form-group
        label(for="details") Details
        textarea#details(style="height:250px;" v-model="showAdmin.details")
       
  template(#footer)
    .flex.flex--space-between
      BaseBtn.x-lg.red-outline(
        href="#",
        @click.prevent="closeOverlay"
      ) CANCEL
      BaseBtn.x-lg.green(
        type="submit",
        form="manage-user"
        :disabled="v$.$invalid || isSubmitting"
        :class="{spinner: isSubmitting }"
      ) CONTINUE
</template>

<script>
import { defineComponent } from "vue";
import SideOverlaySlot from "@/components/overlays/SideOverlaySlot.vue";
import { userRoles } from "@/store/account";

import { register } from "@/api/accountApi";
import { apiStatus, apiStatusComputedFactory } from "@/api";
import { updateUser } from "@/api/userApi";
const { IDLE, PENDING, SUCCESS, ERROR } = apiStatus;
import { createToast } from "mosha-vue-toastify";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";

export default defineComponent({
  setup() {
    return { v$: useVuelidate() };
  },
  components: { SideOverlaySlot },
  emits: ["closeManageUsersSuperAdminOverlay"],

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    overlayTitle: {
      type: String,
      required: true,
    },
    showAdmin: {
      type: Object,
      required: false,
    },
  },

  computed: {
    ...apiStatusComputedFactory("addSuperAdminStatus"),
  },

  data() {
    return {
      manageSuperAdminStatus: IDLE,
      addSuperAdminStatus: IDLE,
      superAdminRoles: userRoles.admin,
      isUpdateServerError: false,
      isSubmitting: false,
    };
  },
  validations() {
    let args = {
      showAdmin: {
        idUserRole: { required },
        first: { required },
        last: { required },
        password: { required },
        email: { required, email },
      },
    };
    if (this.overlayTitle != "ADD NEW USER") {
      args.showAdmin.password.required = false;
    }
    return args;
  },
  methods: {
    saveAndCloseOverlay() {
      if (this.overlayTitle == "ADD NEW USER") {
        this.register().then(() => {
          this.$emit("closeManageUsersSuperAdminOverlay");
        });
      } else {
        this.update().then(() => {
          if (!this.isUpdateServerError) {
            this.$emit("closeManageUsersSuperAdminOverlay");
          } else {
            this.isUpdateServerError = false;
          }
        });
      }
    },

    closeOverlay() {
      this.$emit("closeManageUsersSuperAdminOverlay");
    },

    async register() {
      this.isSubmitting = true;
      this.addSuperAdminStatus = PENDING;
      try {
        await register({
          user: {
            createdAt: null,
            details: this.showAdmin.details,
            email: this.showAdmin.email,
            first: this.showAdmin.first,
            idUserRole: parseInt(this.showAdmin.idUserRole),
            last: this.showAdmin.last,
            password: this.showAdmin.password,
            signInCount: 0,
            status: null,
            updatedAt: null,
          },
        });

        this.addSuperAdminStatus = SUCCESS;
      } catch (err) {
        this.addSuperAdminStatus = ERROR;
        createToast(err?.response?.data?.message || "There was a problem", {
          timeout: 4000,
          type: "danger",
          position: "bottom-right",
        });
        console.error(err);
      } finally {
        this.isSubmitting = false;
      }
    },
    async update() {
      this.isSubmitting = true;
      this.manageSuperAdminStatus = PENDING;
      try {
        await updateUser(this.showAdmin.id, {
          user: {
            details: this.showAdmin.details,
            email: this.showAdmin.email,
            first: this.showAdmin.first,
            last: this.showAdmin.last,
          },
        });

        this.manageSuperAdminStatus = SUCCESS;
      } catch (err) {
        this.manageSuperAdminStatus = ERROR;
        createToast(err?.response?.data?.message || "There was a problem", {
          timeout: 4000,
          type: "danger",
          position: "bottom-right",
        });
        console.error(err);
        this.isUpdateServerError = true;
      } finally {
        this.isSubmitting = false;
      }
    },
  },
});
</script>

<style scoped lang="scss">
.patient-section {
  margin-bottom: 0;
  padding: 37px 24px 25px;
  background-color: $primaryBgBlue;
  p {
    margin: 0 0 12px;
    font-size: 21px;
    font-weight: 500;
    line-height: 1.1;
    color: $primaryLightBlue;
    overflow: hidden;
    strong {
      font-weight: 700;
    }
  }
}
form#manage-user {
  margin: 12px 0 20px;
  .columns {
    margin-bottom: 0px;
    .column {
      padding-bottom: 0px;
    }
  }
}
.overlay-show-success {
  p {
    margin: 41px 0 60px;
  }
}
</style>
