// @ts-nocheck
export default {
  data() {
    return {
      sortings: {
        DateAndTime: [
          undefined,
          { by: "date-time", icon: "▲", direction: "asc" },
          { by: "date-time", icon: "▼", direction: "desc" },
        ],
        PatientFullName: [
          undefined,
          { by: "patient-full-name", icon: "▲", direction: "asc" },
          { by: "patient-full-name", icon: "▼", direction: "desc" },
        ],
        HospitalDoctorFullName: [
          undefined,
          { by: "hospital-doctor-full-name", icon: "▲", direction: "asc" },
          { by: "hospital-doctor-full-name", icon: "▼", direction: "desc" },
        ],
        ClinicName: [
          undefined,
          { by: "clinic-name", icon: "▲", direction: "asc" },
          { by: "clinic-name", icon: "▼", direction: "desc" },
        ],
        Status: [
          undefined,
          { by: "status", icon: "▲", direction: "asc" },
          { by: "status", icon: "▼", direction: "desc" },
        ],
        Report: [
          undefined,
          { by: "report", icon: "▲", direction: "asc" },
          { by: "report", icon: "▼", direction: "desc" },
        ],
      },
      currentSortKey: "DateAndTime",
      currentSortIndex: 0,
      listOrder: undefined,
    };
  },

  computed: {
    DateAndTimeColumnSortingIcon() {
      if (this.currentSortKey === "DateAndTime") {
        return this.sortings.DateAndTime[this.currentSortIndex]?.icon || "▼▲";
      }
      return "▼▲";
    },
    PatientFullNameColumnSortingIcon() {
      if (this.currentSortKey === "PatientFullName") {
        return this.sortings.PatientFullName[this.currentSortIndex]?.icon || "▼▲";
      }
      return "▼▲";
    },
    HospitalDoctorFullNameColumnSortingIcon() {
      if (this.currentSortKey === "HospitalDoctorFullName") {
        return this.sortings.HospitalDoctorFullName[this.currentSortIndex]?.icon || "▼▲";
      }
      return "▼▲";
    },
    ClinicNameColumnSortingIcon() {
      if (this.currentSortKey === "ClinicName") {
        return this.sortings.ClinicName[this.currentSortIndex]?.icon || "▼▲";
      }
      return "▼▲";
    },
    StatusColumnSortingIcon() {
      if (this.currentSortKey === "Status") {
        return this.sortings.Status[this.currentSortIndex]?.icon || "▼▲";
      }
      return "▼▲";
    },
    ReportColumnSortingIcon() {
      if (this.currentSortKey === "Report") {
        return this.sortings.Report[this.currentSortIndex]?.icon || "▼▲";
      }
      return "▼▲";
    },
  },

  methods: {
    changeListOrder(key, callback) {
      if (this.currentSortKey === key) {
        this.updateCurrentSortingIndex(callback);
        return;
      }

      this.currentSortKey = key;
      this.currentSortIndex = 0;
      this.updateCurrentSortingIndex(callback);
    },
    updateCurrentSortingIndex(callback) {
      if (
        this.currentSortIndex ===
        this.sortings[this.currentSortKey].length - 1
      ) {
        this.currentSortIndex = 0;
        this.listOrder = this.sortings[this.currentSortKey][0];
        callback();
        return;
      }

      this.currentSortIndex++;
      this.listOrder =
        this.sortings[this.currentSortKey][this.currentSortIndex];
      return callback();
    },
  },
};
