// @ts-nocheck
import api from "./api";
const usersUrl = "/user";

export const fetchUser = (data = {}) => {
  return api.get(usersUrl, data);
};

export const fetchLoggedActions = (data = {}) => {
  return api.get(usersUrl + "/logged-actions", data);
};

export const fetchUserLoggedActions = (data = {}, id) => {
  return api.get(usersUrl + "/" + id + "/logged-actions", data);
};

export const fetchHospitalLoggedActions = (data = {}, hospitalId) => {
  return api.get(usersUrl + "/logged-actions/hospital/" + hospitalId, data);
};

export const fetchUserHospitalLoggedActions = (data = {}, id, hospitalId) => {
  return api.get(
    usersUrl + "/" + id + "/logged-actions/hospital/" + hospitalId,
    data
  );
};

export const fetchClinicHealthSystemLoggedActions = (data = {}, chsId) => {
  return api.get(
    usersUrl + "/logged-actions/clinic-health-system/" + chsId,
    data
  );
};

export const fetchUserClinicHealthSystemLoggedActions = (
  data = {},
  id,
  chsId
) => {
  return api.get(
    usersUrl + "/" + id + "/logged-actions/clinic-health-system/" + chsId,
    data
  );
};

export const fetchCommunityCareOrganizationLoggedActions = (
  data = {},
  idCommunity
) => {
  return api.get(
    usersUrl + "/logged-actions/community-care-organization/" + idCommunity,
    data
  );
};

export const fetchUserCommunityCareOrganizationLoggedActions = (
  data = {},
  userId,
  idCommunity
) => {
  return api.get(
    usersUrl +
      "/" +
      userId +
      "/logged-actions/community-care-organization/" +
      idCommunity,
    data
  );
};

export const fetchProviderGroupLoggedActions = (data = {}, idProviderGroup) => {
  return api.get(
    usersUrl + "/logged-actions/provider-group/" + idProviderGroup,
    data
  );
};

export const fetchUserProviderGroupLoggedActions = (
  data = {},
  userId,
  idProviderGroup
) => {
  return api.get(
    usersUrl +
      "/" +
      userId +
      "/logged-actions/provider-group/" +
      idProviderGroup,
    data
  );
};

export const fetchUserTourStatus = (userId, data = {}) => {
  return api.get(`${usersUrl}/${userId}/tour-status`, data);
};
export const updateUserTourStatus = (userId, data = {}) => {
  return api.put(`${usersUrl}/${userId}/tour-status`, data);
};

export const updateUser = (id, data = {}) => {
  return api.put(usersUrl + "/" + id, data);
};

export const deleteUser = (id, data = {}) => {
  return api.delete(usersUrl + "/" + id + "/access", data);
};

export const patchUser = (id, data = {}) => {
  return api.patch(usersUrl + "/" + id + "/access", data);
};

export const getUserDefaultSpecialty = (idHospital, idUser) => {
  return api.get(usersUrl, { params: { filters: { idUser, idHospital } } });
};

export const getUserDoctorSpecialties = (idHospital, idUser) => {
  return api.get(usersUrl, {
    params: { filters: { idUser, idHospital, nameRole: "Hospital Doctor" } },
  });
};
