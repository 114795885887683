import { getHospitalSpecialty } from "@/api/hospitalApi";
import router from "@/router";
import { cloneDeep } from "lodash";

interface SpecialtyModule {
  createdAt: number;
  deletedAt: number;
  icon: string | null;
  id: number;
  isColonoscopy: boolean;
  isManageCare: boolean;
  isModuleEnabled: boolean;
  marketingTitle: string;
  moduleTitle: string;
  schemaRules: any;
  updatedAt: number;
}

interface SpecialtyModuleWrapper {
  active: boolean;
  idHospital: number;
  specialtyModule: SpecialtyModule;
}
interface CurrentHospitalSpecialtiesState {
  hospitalId: number;
  showHospitalSpecialtiesNavigation: boolean;
  specialtyModules: SpecialtyModuleWrapper[];
}

export default {
  state(): CurrentHospitalSpecialtiesState {
    return {
      hospitalId: -1,
      showHospitalSpecialtiesNavigation: false,
      specialtyModules: [],
    };
  },
  getters: {
    showHospitalSpecialties(state: CurrentHospitalSpecialtiesState) {
      return state.specialtyModules.length > 0;
    },
    getHospitalSpecialtyModules(state: CurrentHospitalSpecialtiesState) {
      return state.specialtyModules;
    },
    getHospitalSpecialtyById:
      (state: CurrentHospitalSpecialtiesState) => (id: number) => {
        return state.specialtyModules.find(
          (specialty) => specialty.specialtyModule.id === id
        );
      },
    getShowHospitalDashboardsMainNav(state: CurrentHospitalSpecialtiesState) {
      return state.showHospitalSpecialtiesNavigation;
    },
    getHospitalId(state: CurrentHospitalSpecialtiesState) {
      return state.hospitalId;
    },
    aHospitalIsSelected(state: CurrentHospitalSpecialtiesState) {
      return state.hospitalId > 0;
    },
    hospitalNameFromContext(
      state: CurrentHospitalSpecialtiesState,
      getters: any
    ) {
      return getters.getUserRoleContext.hospital.name;
    },
  },
  mutations: {
    COMMIT_CURRENT_HOSPITAL(
      state: CurrentHospitalSpecialtiesState,
      payload: number
    ) {
      state.hospitalId = payload;
    },
    COMMIT_HOSPITAL_SPECIALTY_MODULES(
      state: CurrentHospitalSpecialtiesState,
      payload: SpecialtyModuleWrapper[]
    ) {
      state.specialtyModules = cloneDeep(payload);
      state.showHospitalSpecialtiesNavigation = payload.length > 0;
    },
  },
  actions: {
    async fetchHospitalDashboard({ commit }: { commit: any }, id: number) {
      try {
        const response = await getHospitalSpecialty(id);
        const specialties = response.data.hospitalSpecialties;
        commit("COMMIT_CURRENT_HOSPITAL", id);
        commit("COMMIT_HOSPITAL_SPECIALTY_MODULES", specialties);
        localStorage.setItem("currentHospitalId", id.toString());
      } catch (error) {
        console.log(error);
      }
    },
    goToHospitalSearchPatient({ getters }: { getters: any }) {
      const { hospital } = getters.getUserRoleContext;
      const { params } = router.currentRoute.value;
      const specialtyModules = getters.getHospitalSpecialtyModules;

      if (params.idSpecialty) {
        router.push({
          name: "hospitals.patient-search",
          params: { idHospital: hospital.id, idSpecialty: params.idSpecialty },
        });
      } else if (specialtyModules.length) {
        router.push({
          name: "hospitals.patient-search",
          params: {
            idHospital: hospital.id,
            idSpecialty: specialtyModules[0].specialtyModule.id,
          },
        });
      }
    },
    goToHospitalUsers({ getters }: { getters: any }) {
      // {name: 'users-hospitals', params: { idHospital: 1, idSpecialty: 1}}
      const { hospital } = getters.getUserRoleContext;
      const { params } = router.currentRoute.value;
      const specialtyModules = getters.getHospitalSpecialtyModules;

      if (params.idSpecialty) {
        router.push({
          name: "users-hospitals",
          params: { idHospital: hospital.id, idSpecialty: params.idSpecialty },
        });
      } else if (specialtyModules.length) {
        router.push({
          name: "users-hospitals",
          params: {
            idHospital: hospital.id,
            idSpecialty: specialtyModules[0].specialtyModule.id,
          },
        });
      }
    },
    clearCurrentHospital({ commit }: { commit: any }) {
      commit("COMMIT_CURRENT_HOSPITAL", -1);
      commit("COMMIT_HOSPITAL_SPECIALTY_MODULES", []);
      localStorage.removeItem("currentHospitalId");
    },
  },
};
