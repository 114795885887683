<template lang="pug">
.table_wrapper
  table.table(id="data-table")
    tr.th
      td.td Month
      td.td Referred
      td.td Contacted
      td.td % Contacted
      td.td Not Contacted
      td.td Incomplete
      td.td(v-for="dynamicKey in dynamicReport" :key="dynamicKey.label") {{dynamicKey.label}} 
    tr.tr(v-for="month, monthIndex in labels", :key="month")
      td.td {{month}}
      td.td {{referred[monthIndex]}}
      td.td {{contacted[monthIndex]}}
      td.td.bold.red  {{contactedPercentage[monthIndex]}}%
      td.td {{notContacted[monthIndex]}}
      td.td {{incomplete[monthIndex]}}
      td.td(v-for="dynamicKey, indexKey in dynamicReport" :key="dynamicKey.label + indexKey") {{dynamicKey.data[monthIndex]}}
    tr.tr
      td.td Total
      td.td {{totalReferred}}
      td.td {{totalContacted}}
      td.td {{totalContactedPercentage}}%
      td.td {{totalNotContacted}}
      td.td {{totalIncomplete}}
      td.td(v-for="dynamicKey, indexKey in dynamicReport" :key="dynamicKey.label + indexKey") {{dynamicKey.total}}
</template>
<script>
import accumulator from "@/helpers/accumulator";
import moment from "moment";
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    reportData: {
      required: true,
      type: Array,
    },
  },
  computed: {
    referred() {
      return this.reportData.map((monthData) => monthData.totalReferred);
    },
    totalReferred() {
      return this.referred.reduce(accumulator, 0);
    },
    contacted() {
      return this.reportData.map((monthData) => monthData.totalContacted);
    },
    totalContacted() {
      return this.contacted.reduce(accumulator, 0);
    },
    contactedPercentage() {
      return this.reportData.map((monthData) => monthData.contactedPercentage);
    },
    totalContactedPercentage() {
      return (
        this.contactedPercentage.reduce(accumulator, 0) / this.labels.length
      ).toFixed(2);
    },
    notContacted() {
      return this.reportData.map((monthData) => monthData.totalNotContacted);
    },
    totalNotContacted() {
      return this.notContacted.reduce(accumulator, 0);
    },
    incomplete() {
      return this.reportData.map((monthData) => monthData.totalIncomplete);
    },
    totalIncomplete() {
      return this.incomplete.reduce(accumulator, 0);
    },
    dynamicReport() {
      const allfields = { ...this.reportData[0] };
      const fixedFields = [
        "referredAtMonth",
        "totalReferred",
        "totalContacted",
        "contactedPercentage",
        "totalNotContacted",
        "totalIncomplete",
      ];
      const dynamicKeys = Object.keys(allfields).filter(
        (key) => !fixedFields.includes(key)
      );
      if (dynamicKeys.length) {
        return dynamicKeys.map((dynamicKey) => {
          const data = this.reportData.map((month) => month[dynamicKey]);

          return {
            label: dynamicKey.replace("total", ""),
            data,
            total: [...data].reduce(accumulator, 0),
          };
        });
      }

      return [];
    },
    labels() {
      return this.reportData.map((monthData, index, arr) =>
        moment(
          monthData.distributedAtMonth ||
            monthData.scheduledAtMonth ||
            monthData.referredAtMonth
        ).format(arr.length > 12 ? "MMMM YY" : "MMMM")
      );
    },
  },
});
</script>
<style lang="scss" scoped>
.table_wrapper {
  width: 100%;
  margin-bottom: 80px;
}
.hr {
  margin-right: 30px;
}
.btn-margin-left {
  margin-left: 28px;
}
.table_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.table {
  width: 100%;
  color: #000;

  .th {
    padding-bottom: 14px;
    padding-top: 14px;
    height: auto;
    border-bottom: 0px;
    .td {
      font-weight: 700;
      text-transform: none;
    }
    background-color: $primaryBgDarkGrey;
  }

  .tr {
    padding-bottom: 14px;
    padding-top: 14px;
    height: auto;
    .td {
      height: auto;
      display: flex;
    }
    &:hover {
      background-color: transparent;
    }
    &:last-child {
      border-bottom: 0px;
    }
  }

  .td {
    font-size: 14px;
    display: flex;
    flex: 1;
  }
}
</style>
