<template lang="pug">
.upload-and-preview-container
  .preview-file(:style="removeMargin? 'margin-right: unset!important;':''")
    UploadedFilePreview(
      :src="getFileSrc"
      :key="forcedRerenderTimes"
    )
  .upload-button
    UploadFileButton(
      :styleClasses="buttonStyleClasses"
      :storageKey="storageKey"
      :src="getFileSrc"
      @fileLoaded="forceRerender"
      :disabled="disabled"
    )
  
</template>

<script>
import UploadFileButton from "@/components/UploadFileButton";
import UploadedFilePreview from "@/components/UploadedFilePreview";

export default {
  components: {
    UploadFileButton,
    UploadedFilePreview,
  },

  data() {
    return {
      forcedRerenderTimes: 0,
    };
  },

  props: {
    buttonStyleClasses: {
      type: Array,
      default: () => [],
    },
    storageKey: {
      type: String,
      default: "",
    },
    allowedFileTypes: {
      type: Array,
      default: () => ["image/*", ".pdf", ".csv"],
    },
    src: {
      type: String,
    },
    disabled: {
      required: false,
    },
    removeMargin: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  methods: {
    forceRerender(file) {
      this.forcedRerenderTimes = this.forcedRerenderTimes + 1;
      this.$emit("fileLoaded", file);
    },
  },

  computed: {
    getFileSrc() {
      this.forcedRerenderTimes;
      const stringifiedFile = sessionStorage.getItem(this.storageKey);
      const file = stringifiedFile ? JSON.parse(stringifiedFile).url : this.src;
      return file;
    },
  },
};
</script>

<style scoped lang="scss">
.upload-and-preview-container {
  display: flex;
  align-items: center;

  .preview-file {
    margin-right: 1rem;
  }
}
</style>
