<template lang="pug">
component.btn(
  :is="type",
  :to="to",
  :href="href",
  :disabled="disabled"
  :class="{'btn--is-dropdown': isDropdown, 'btn--is-open': isDropdown && isDropdownOpen}",
  @[dropdownClick]="toggleDropdown",
  v-click-outside="closeDropdown"
)
  slot
  .counter(v-if="count")
    span.count {{count}}

  //- Dropdown
  .btn--is-dropdown__inner(v-if="isDropdown && txt")
    .txt {{txt}}
    base-icon(name="chevron-md")
  .dropdown-menu(
    v-if="isDropdown",
    v-show="isDropdownOpen",
  )
    ul
      slot(name="dropdown-menu")
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    to: {
      required: false,
    },
    href: {
      required: false,
    },
    count: {
      required: false,
    },
    disabled: {
      required: false,
    },

    //Dropdown
    isDropdown: {
      type: Boolean,
      required: false,
    },
    txt: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      isDropdownOpen: false,
    };
  },
  computed: {
    type() {
      if (this.to) {
        return "router-link";
      } else if (this.href) {
        return "a";
      }
      return "button";
    },
    dropdownClick() {
      return this.isDropdown ? "click" : null;
    },
  },

  methods: {
    openDropdown() {
      if (!this.isDropdown) return;
      this.isDropdownOpen = true;
    },
    closeDropdown() {
      if (!this.isDropdown || !this.isDropdownOpen) return;
      this.isDropdownOpen = false;
    },
    toggleDropdown() {
      if (!this.isDropdown) return;
      this.isDropdownOpen = !this.isDropdownOpen;
    },
  },
});
</script>

<style scoped lang="scss">
// Base btn ==================================================================
.btn {
  position: relative;
  display: inline-block;
  min-width: 155px;
  transition: all 0.3s ease-in;
  cursor: pointer;
  font-weight: 500;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  outline: none;
  appearance: none;
  box-shadow: none;
  border-width: 2px;
  border-style: solid;
  border-radius: 100px;
  backface-visibility: hidden;
}

// Sizes =====================================================================
.x-lg {
  min-width: 228px;
  padding: 13px 20px;
  font-size: 18px;
  line-height: 20px;
  font-weight: 500;
}
.lg {
  padding: 13px 20px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
}
.md {
  font-size: 14px;
  line-height: 16px;
  padding: 9.5px 30px;
}
.sm {
  padding: 4px 20px;
  font-size: 14px;
  line-height: 18px;
}

// Colors=====================================================================
.blue {
  color: #fff;
  border-color: $primaryLightBlue;
  background-color: $primaryLightBlue;
  &:hover {
    background-color: $primaryDarkBlue;
    border-color: $primaryDarkBlue;
  }
  & > .dropdown-menu {
    background-color: $primaryLightBlue;
    color: #fff;
  }
}
.green {
  color: #fff;
  border-color: $secondaryGreen;
  background-color: $secondaryGreen;
  &:hover {
    background-color: $primaryGreen;
    border-color: $primaryGreen;
  }
}
.yellow {
  color: #000;
  border-color: $secondaryYellow;
  background-color: $secondaryYellow;
  &:hover {
    background-color: $secondaryDarkYellow;
    border-color: $secondaryDarkYellow;
  }
}
.grey {
  color: #fff;
  border-color: #9b9b9b;
  background-color: #9b9b9b;
  &:hover {
    color: #fff;
    background-color: darken(#9b9b9b, 10%);
  }
}

.blue-outline {
  color: $primaryLightBlue;
  border-color: $primaryLightBlue;
  background-color: rgba($primaryLightBlue, 0);
  &:hover {
    color: #fff;
    background-color: rgba($primaryLightBlue, 1);
  }
}
.green-outline {
  color: $secondaryGreen;
  border-color: $secondaryGreen;
  background-color: rgba($secondaryGreen, 0);
  &:hover {
    color: #fff;
    background-color: rgba($secondaryGreen, 1);
  }
}
.grey-outline {
  color: $primaryDarkGrey;
  border-color: $primaryDarkGrey;
  background-color: rgba($primaryDarkGrey, 0);
  &:hover {
    color: #fff;
    background-color: rgba($primaryDarkGrey, 1);
  }
}
.black-outline {
  color: #000;
  border-color: #000;
  background-color: rgba(#000, 0);
  &:hover {
    color: #fff;
    background-color: rgba(#000, 1);
  }
}
.redFill {
  color: #fff;
  border-color: $secondaryRed;
  background-color: $secondaryRed;
  &:hover {
    background-color: $secondaryDarkRed;
    border-color: $secondaryDarkRed;
    color: #fff;
  }
}
.red-outline {
  color: $secondaryRed;
  border-color: $secondaryRed;
  background-color: rgba($secondaryRed, 0);
  &:hover {
    color: #fff;
    background-color: rgba($secondaryRed, 1);
  }
}
.yellow--outline {
  color: #000;
  border-color: $secondaryYellow;
  background-color: #fff;
  &:hover {
    background-color: $secondaryYellow;
    border-color: $secondaryYellow;
  }
}
.disabled-outline {
  border-color: $primaryBgDarkGrey;
  color: $primaryDarkGrey;
  &:hover {
    background-color: transparent;
    border-color: $primaryBgDarkGrey;
    color: $primaryDarkGrey;
    cursor: not-allowed;
  }
}

// Disabled ==================================================================
@mixin btn-disabled() {
  background-color: $primaryBgDarkGrey;
  border-color: $primaryBgDarkGrey;
  color: $primaryDarkGrey;
  cursor: not-allowed;
}

button.btn {
  &:disabled,
  &:disabled:hover,
  &:disabled:focus,
  &.disabled,
  &.disabled:hover,
  &.disabled:focus {
    @include btn-disabled;
  }
}

a.btn {
  &.disabled,
  &.disabled:hover,
  &.disabled:focus {
    @include btn-disabled;
    pointer-events: none;
  }
}

// Counter =====================================================================
.counter {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 4px;
  top: 4px;
  transform: translate(-50%, -50%);
  transform-origin: center;
  min-width: 33px;
  min-height: 33px;
  border-radius: 33px;
  padding: 8px 8px;
  background-color: $secondaryRed;
  backface-visibility: hidden;
  .count {
    font-size: 16px;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: normal;
    color: #fff;
  }
}

.btn.spinner,
.btn.spinner-dark {
  padding-right: 40px;
  &::before {
    left: auto;
    right: 5px;
  }
}
// Dropdown
.btn--is-dropdown {
  padding: 13px 25px;
  transition: none;
  border-radius: 24px;
  &:hover {
    background-color: $primaryLightBlue;
    border-color: $primaryLightBlue;
  }
  &.btn--is-open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-color: $primaryLightBlue;
    border-color: $primaryLightBlue;
    .svg-icon {
      transform: rotate(-180deg);
    }
  }
  &__inner {
    display: flex;
    align-content: center;
    .svg-icon {
      margin-left: 19px;
      font-size: 1em;
      top: 1px;
      transition: transform 0.3s linear;
    }
  }
}

.btn--is-open {
  .dropdown-menu {
    opacity: 1;
    pointer-events: auto;
  }
}
</style>

<style lang="scss">
.dropdown-menu {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: calc(100% - 2px);
  left: -2px;
  right: -2px;
  transition: transform 0.3s linear;
  z-index: 5;
  border-radius: 0 0 20px 20px;
  padding: 19px 0 25px;
  ul {
    z-index: 6;
    list-style: none;
    margin: 0;
    padding: 0;
    color: #fff;
    text-transform: none;
  }
  li {
    width: 100%;
    a {
      display: flex;
      align-items: center;
      width: 100%;
      height: 35px;
      padding: 0 25px;
      color: #fff;
      font-size: 18px;
      font-weight: 500;
      line-height: 1.1;
      transition: background-color 0.2s linear;
      &:hover {
        background-color: $primaryDarkBlue;
      }
    }
  }
}
</style>
