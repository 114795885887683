<template lang="pug">
.content-wrapper
  StepsCard()
    template(#steps)
      router-link(:to="{name: 'clinics.scheduling.search-patient'}", v-slot="{ href, route, navigate, isActive, isExactActive }", custom)
        li(
          :class="[isActive && 'router-link-exact-active']"
        ) <span>Search Patient</span>
          base-icon(name="arrow-steps")

      router-link(:to="{name: 'clinics.scheduling.select-patient'}", v-slot="{ href, route, navigate, isActive, isExactActive }", custom)
        li(
          :class="[isActive && 'router-link-exact-active']"
        ) <span>Select Patient</span>
          base-icon(name="arrow-steps")

      router-link(:to="{name: 'clinics.scheduling.select-schedule' }", v-slot="{ href, route, navigate, isActive, isExactActive }", custom)
        li(
          :class="[isActive && 'router-link-exact-active']"
        ) <span>Select Schedule</span>
          base-icon(name="arrow-steps")

      router-link(:to="{name: 'clinics.scheduling.referral-info'}", v-slot="{ href, route, navigate, isActive, isExactActive }", custom)
        li(
          :class="[isActive && 'router-link-exact-active']"
        ) <span>Referral Info</span>
          base-icon(name="arrow-steps")

      router-link(:to="{name: 'clinics.scheduling.confirm'}", v-slot="{ href, route, navigate, isActive, isExactActive }", custom)
        li(
          :class="[isActive && 'router-link-exact-active']"
        ) <span>Confirm</span>

    template(#content)
      router-view(:specialtyModule="specialtyModule")  
</template>

<script>
import { defineComponent } from "vue";
import StepsCard from "@/components/StepsCard.vue";

import { fetchSpecialtyModule } from "@/api/specialtyModulesApi";

import { apiStatus, apiStatusComputedFactory } from "@/api";
const { IDLE, PENDING, SUCCESS, ERROR } = apiStatus;

export default defineComponent({
  components: { StepsCard },

  data() {
    return {
      fetchSpecialtyModuleStatus: IDLE,
      specialtyModule: null,
    };
  },

  methods: {
    async fetchSpecialtyModule() {
      this.fetchSpecialtyModuleStatus = PENDING;
      try {
        const res = await fetchSpecialtyModule(this.$route.params.idSpecialty);
        this.clinicIcon = res.data.specialtyModule?.icon;
        this.clinicTitle =
          res.data.specialtyModule?.marketingTitle +
          ": " +
          res.data.specialtyModule?.moduleTitle;
        this.specialtyModule = res.data.specialtyModule;
        this.fetchSpecialtyModuleStatus = SUCCESS;
      } catch (err) {
        this.fetchSpecialtyModuleStatus = ERROR;
        console.error(err);
      }
    },
  },
  created() {
    this.fetchSpecialtyModule();
  },
});
</script>

<style scoped lang="scss">
.content-wrapper {
  position: relative;
  padding-bottom: 40px;
}
</style>
