// @ts-nocheck
export default {
  data() {
    return {
      pagination: null,
      componentKey: 0,
    };
  },

  computed: {
    reqPagination() {
      if (this.pagination === null) return { size: 10 };
      const reqPagination = { ...this.pagination };
      delete reqPagination.currentPage;
      delete reqPagination.lastPage;
      delete reqPagination.quantity;
      return reqPagination;
    },
  },

  methods: {
    changePagination(newPagination, reloadFn) {
      this.pagination = newPagination;
      reloadFn();
    },
    forceRerender() {
      this.componentKey = this.componentKey + 1;
    },
  },
};
