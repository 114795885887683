<template lang="pug">
.content-wrapper
  .card.router-view-card
    section.select-patient
      h2.big_title PATIENT SEARCH RESULTS
      TableTitle(:title="getTitle")
        template(#actions)
          BaseBtn.lg.blue_outline.setMinWidth.setMarginRight(:to="{name: 'primary-care-community-organization.patient-search'}") SEARCH AGAIN
          BaseBtn.lg.blue.setMinWidth(href="#" @click.prevent="openAddNewClinicPatientOverlayOpen") ADD NEW PATIENT
      .table
        .th
          .td Patient Name
          .td DOB
          .td Clinic/CO
          .td
          .td
          .td
        .tr(v-if="patients" v-for="patient in patients" :key="patient.id")
          .td.bold {{ getUserFormatted(patient) }}
          .td {{ getDobFormatted(patient.dob) }}
          .td.bold {{patient?.clinic?.name}}
          .td
            BaseBtn.sm.blue-outline.disabled-outline( 
              v-if="shouldDisableRecords(patient)") PATIENT RECORDS
            BaseBtn.sm.blue-outline(
              @click="openPatientDetailsOverlay(patient)"
              v-else
            ) PATIENT RECORDS

            BaseBtn.sm.blue-outline.disabled-outline(
              v-if="!showCCOFitRegistry") ADD NEW FIT
            BaseBtn.sm.blue-outline(
              v-else, :to="{name: 'community-care-organization-new-fit-kit', params: {firstName: patient.first, lastName: patient.last} }") ADD NEW FIT

            BaseBtn.sm.blue-outline.disabled-outline(
              v-if="!showCCOPrimaryCare") SCHEDULE
            BaseBtn.btn.sm.blue(
              v-else
              @click="selectPatient(patient)"
            ) SCHEDULE
      Pagination(
        v-if="pagination",
        :pagination="pagination",
        :reloadFn="fetchPatients"
        @changePagination="changePagination"
      )
      .no-results(v-if="patients != null && !patients.length") 
        base-icon(name="exclamation") 
        span No matching records
      .actions-below-table-container
        
  router-view  

PatientDetailsOverlay(
  :key="`${componentKey}-1`"
  :showPatient="showPatient",
  :isOpen="isClinicPatientDetalisOverlay",
  @closePatientDetailsOverlay="closePatientDetailsOverlay",
)
AddNewPatientOverlay(
  :isOpen="isAddNewClinicPatientOverlayOpen",
  @closeAddNewClinicPatientOverlayOpen="closeAddNewClinicPatientOverlayOpen",
  @reloadPatients="reloadPatients"
)

</template>

<script>
import { defineComponent } from "vue";
import moment from "moment";
import TableTitle from "@/components/TableTitle.vue";
import Pagination from "@/components/Pagination.vue";
import listPagesMixin from "@/mixins/listPagesMixin";

import { getDobFormatted, getUserFormatted } from "@/helpers";
import {
  fetchCommunityCareOrganizationById,
  fetchCommunityCareOrganizationPatients,
} from "@/api/communityOrganization";

import AddNewPatientOverlay from "@/components/primary-care-community-organization/scheduling/overlays/AddNewPatientOverlay.vue";
import PatientDetailsOverlay from "@/components/primary-care-community-organization/patient-referrals/overlays/PatientDetailsOverlay.vue";
import { mapGetters } from "vuex";

export default defineComponent({
  components: {
    TableTitle,
    Pagination,
    AddNewPatientOverlay,
    PatientDetailsOverlay,
  },

  mixins: [listPagesMixin],
  computed: {
    ...mapGetters(["showCCOPrimaryCare", "showCCOFitRegistry", "getCCOId"]),
  },
  data() {
    return {
      isClinicPatientDetalisOverlay: false,
      isAddNewClinicPatientOverlayOpen: false,
      patients: null,
      getTitle: "",
      showPatient: null,
      specialties: null,
      searchParams: null,
      idCHS: null,
      hasFitKit: false,
    };
  },

  methods: {
    selectPatient(patient) {
      let sendIdClinic = {};
      if (patient.idClinic) {
        sendIdClinic.idClinic = patient.idClinic;
      } else if (patient.clinicReferral) {
        sendIdClinic.idClinic = patient.clinicReferral.id;
      }
      this.$router.push({
        name: "primary-care-community-organization.scheduling.select-schedule",
        query: this.$route.query,
        params: {
          idPatient: patient.id,
          ...sendIdClinic,
        },
      });
    },
    shouldDisableRecords(patient) {
      if (!patient.clinic) {
        return false;
      }
      return !this.accessToPatients;
    },
    openPatientDetailsOverlay(patient) {
      this.componentKey = this.componentKey + 1;
      this.showPatient = patient;
      this.isClinicPatientDetalisOverlay = true;
    },
    closePatientDetailsOverlay() {
      this.isClinicPatientDetalisOverlay = false;
      this.fetchPatients();
    },
    getDobFormatted,
    getUserFormatted,
    async reloadPatients(patient) {
      this.searchParams = {
        searchBy: "name",
        firstName: patient.first,
        lastName: patient.last,
      };
      await this.$router.push({
        name: "clinics.patient-search.results",
        query: this.searchParams,
      });
      this.fetchPatients();
    },

    async fetchPatients() {
      this.getTitle = "(0) Patients Found";
      try {
        let query_params = {
          searchBy: this.$route.query.searchBy,
          searchInput: this.getSearchInput(this.$route.query),
        };
        if (this.searchParams) {
          query_params = {
            searchBy: this.searchParams.searchBy,
            searchInput: this.getSearchInput(this.searchParams),
          };
        }
        const resPatient = await fetchCommunityCareOrganizationPatients(
          this.$route.params.idCommunity,
          {
            params: query_params,
            ...this.reqPagination,
          }
        );
        this.patients = resPatient.data.patients.map((element) => {
          element.isDisabled = true;
          if (element.idClinic == this.$route.params.idClinic) {
            element.isDisabled = false;
          }
          return element;
        });

        if (this.patients != null && this.patients.length) {
          this.getTitle = "(" + this.patients.length + ") Patients Found";
        }
      } catch (err) {
        console.error(err);
      }
    },
    getSearchInput(searchParams) {
      let searchInput = {};
      const searchBy = searchParams.searchBy;
      switch (searchBy) {
        case "name":
          if (searchParams.firstName) {
            searchInput.first = searchParams.firstName;
          }
          if (searchParams.lastName) {
            searchInput.last = searchParams.lastName;
          }
          break;
        case "mrn":
          searchInput = searchParams.mrn;
          break;
        case "dob":
          searchInput = moment.utc(searchParams.dob).format("MMDDYYYY");
          break;
        default:
          break;
      }
      return searchInput;
    },
    openClinicPatientDetalisOverlay(patient) {
      this.componentKey = this.componentKey + 1;
      this.showPatient = patient;
      this.isClinicPatientDetalisOverlay = true;
    },
    closeClinicPatientDetalisOverlay() {
      this.isClinicPatientDetalisOverlay = false;
      this.fetchPatients();
    },
    openAddNewClinicPatientOverlayOpen() {
      this.isAddNewClinicPatientOverlayOpen = true;
    },
    closeAddNewClinicPatientOverlayOpen() {
      this.isAddNewClinicPatientOverlayOpen = false;
    },
    async fetchCommunityCareOrganizationById() {
      try {
        const res = await fetchCommunityCareOrganizationById(
          this.$route.params.idCommunity
        );
        this.accessToPatients = res.data.hasPatientAccess;
      } catch (err) {
        console.error(err);
      }
    },
  },
  created() {
    if (this.$route.query === null) return;
    this.fetchPatients();
    this.fetchCommunityCareOrganizationById();
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/components/actions-below-table-container.scss";
.btn.setMarginRight {
  margin-right: 23px;
}
.btn.setMinWidth {
  min-width: 228px;
}
.table {
  overflow: visible;
}
.table-title {
  margin-bottom: 41px;
}
.btn.btn--is-dropdown {
  padding: 4px 20px;
  border-radius: 16px;
}
.dropdown-menu li a {
  padding-left: 20px;
  text-align: left;
  height: auto;
  padding-top: 5px;
  padding-bottom: 5px;
}
.select-patient {
  .td {
    overflow: visible;

    &:nth-child(1) {
      width: math.percentage(math.div(260, $tabContentCardWidth));
    }
    &:nth-child(2) {
      width: math.percentage(math.div(155, $tabContentCardWidth));
    }
    &:nth-child(3) {
      width: math.percentage(math.div(187, $tabContentCardWidth));
    }
    &:nth-child(4) {
      width: math.percentage(math.div(578, $tabContentCardWidth));
      text-align: right;
      .btn {
        margin-left: 15px;
      }
    }
  }
}
.table .tr > .td {
  overflow: visible;
}
.table > .tr:last-child {
  border-bottom: 0px;
}
</style>
