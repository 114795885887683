// @ts-nocheck
import api from "./api";

const URL = "/primary-care-module";

export const fetchPrimaryCareModule = (data = {}) => {
  return api.get(URL, data);
};

export const updatePrimaryCareModule = (id, data = {}) => {
  return api.put(URL + "/" + id, data);
};
