// @ts-nocheck
import api from "./api";

const notifications = "/notification";

export const fetchNotification = (data = {}) => {
  return api.get(notifications, data);
};

export const createPatientAppointmentNote = (data = {}) => {
  return api.post(notifications, data);
};

export const fetchNotificationNewCount = (data = {}) => {
  return api.get(notifications + "/new-count", data);
};

export const putNotification = (idNotification, data = {}) => {
  return api.put(notifications + "/" + idNotification, data);
};