<template lang="pug">
.fit-kit-completed
  header.overlay-show-success.fit-kit-completed__head
    p FIT successfully completed!

  section.fit-kit-completed__positive(
    v-if="showPositiveArea()"
  )
    h2.result Result is FIT Positive
    p You indicated that the test results are positive.
    p Would you like to immediately take action?

    p.schedule-colonoscopy(
      v-if="organizationType === 'Clinic'"
    ) SCHEDULE COLONOSCOPY:
    .actions.has-icc(v-if="showIcc()")
      //- TODO: link:
      BaseBtn.lg.green-outline(
        @click.prevent="isScheduleColonoscopyOutsideIccOverlayOpen = true"
      ) OUTSIDE ICC
      BaseBtn.lg.green(
        v-if="enabledColonoscopySpecialty && clinicStaffInTrainingNotAllowed",
        href="#",
        :to="{name: 'clinics.scheduling.select-schedule', params: { idFitKit: completedFit.id, idPatient: completedFit.idPatient, idSpecialty: idColonoscopySpecialty }}"
      ) INSIDE ICC

    .actions.has-referrals(v-if="showReferral()")
      BaseBtn.lg.green(
        @click.prevent="isFitReferralOverlayOpen = true"
      ) Create Clinic Referral
ScheduleColonoscopyOutsideIccOverlay(
  :isOpen="isScheduleColonoscopyOutsideIccOverlayOpen",
  :selectedFitKit="completedFit"
  @closeScheduleColonoscopyOutsideIccOverlay="closeScheduleColonoscopyOutsideIccOverlay"
)
FitReferralOverlay(
  :isOpen="isFitReferralOverlayOpen",
  :selectedFitKit="completedFit"
  @closeFitReferralOverlay="closeFitReferralOverlay"
)
</template>

<script>
import { defineComponent } from "vue";
import FitReferralOverlay from "@/components/fit-kit/overlays/FitReferralOverlay.vue";
import ScheduleColonoscopyOutsideIccOverlay from "@/components/fit-kit/overlays/ScheduleColonoscopyOutsideIccOverlay.vue";
import { fetchCHS, getSpecialtyModulesOfCHS } from "@/api/chsApi";
import { fetchClinic } from "@/api/clinicApi";
import { denyClinicStaffTraining } from "@/mixins/denyClinicStaffTraining";

export default defineComponent({
  components: {
    FitReferralOverlay,
    ScheduleColonoscopyOutsideIccOverlay,
  },

  mixins: [denyClinicStaffTraining],

  props: {
    idClinic: {
      type: Number,
      required: true,
    },
    completedFit: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      organizationType: null,
      idColonoscopySpecialty: null,
      enabledColonoscopySpecialty: false,
      isFitReferralOverlayOpen: false,
      isScheduleColonoscopyOutsideIccOverlayOpen: false,
    };
  },

  computed: {},

  watch: {},

  methods: {
    showPositiveArea() {
      return (
        this.completedFit &&
        this.completedFit.result === "positive" &&
        (this.showReferral() || this.showIcc())
      );
    },
    showReferral() {
      const hasReferral =
        this.completedFit &&
        this.completedFit.referral &&
        this.completedFit.referral.id;
      return this.organizationType === "CBO" && !hasReferral;
    },
    showIcc() {
      const hasOutside =
        this.completedFit &&
        this.completedFit.colonoscopyOutsideICC &&
        this.completedFit.colonoscopyOutsideICC.id;
      const hasInside =
        this.completedFit &&
        this.completedFit.colonoscopyInsideICC &&
        this.completedFit.colonoscopyInsideICC.id;
      return this.organizationType === "Clinic" && !hasOutside && !hasInside;
    },
    closeFitReferralOverlay({ afterAction }) {
      this.isFitReferralOverlayOpen = false;
      if (afterAction) {
        this.$router.go(0);
      }
    },
    closeScheduleColonoscopyOutsideIccOverlay({ afterAction }) {
      this.isFitReferralOverlayOpen = false;
      if (afterAction) {
        this.$router.go(0);
      }
    },
    async fetchClinicType(idClinic) {
      try {
        const clinicRes = await fetchClinic(idClinic);
        const chsRes = await fetchCHS(clinicRes.data.idClinicHealthSystem);
        this.organizationType = chsRes.data.organizationType;
        const specialtiesRes = await getSpecialtyModulesOfCHS(
          clinicRes.data.idClinicHealthSystem,
          {
            params: {
              page: 1,
              size: 1000,
            },
          }
        );
        if (specialtiesRes.data && specialtiesRes.data.success) {
          const chsSpecialties = specialtiesRes.data.chsSpecialties;
          if (chsSpecialties.length > 0) {
            const colonoscopy = chsSpecialties.find(
              (x) => x.specialtyModule.isColonoscopy
            );
            this.idColonoscopySpecialty = colonoscopy
              ? colonoscopy.specialtyModule.id
              : null;
            this.enabledColonoscopySpecialty = !!this.idColonoscopySpecialty;
          } else {
            this.enabledColonoscopySpecialty = false;
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
  },

  created() {
    if (this.idClinic === null) return;
    this.fetchClinicType(this.idClinic);
  },
});
</script>

<style scoped lang="scss">
.side-overlay__footer {
  display: none;
}
.fit-kit-completed {
  &__head {
    margin-bottom: 45px;
  }
  &__positive {
    padding: 10px 30px 40px;
    border: solid 1px $primaryBgDarkGrey;
    .result {
      margin-bottom: 33px;
      text-transform: uppercase;
      font-size: 28px;
      font-weight: 700;
      line-height: 1.14;
      color: $secondaryRed;
    }
  }
  p.schedule-colonoscopy {
    margin-top: 35px;
    text-transform: uppercase;
    font-weight: 700;
  }
  .actions {
    margin-top: 30px;
    display: flex;
    .btn {
      min-width: 206px;
    }
    & > .btn:first-child {
      margin-right: 17px;
    }
  }
}
</style>
