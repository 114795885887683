<template lang="pug">
FullScreenOverlaySlot(
  :isOpen="isOpen",
  headerIcon="primary-care",
  title="PATIENT DETAILS"
  @closeOverlay="closeOverlay"
)
  template(#breadcrumbs)
    h1.breadcrumbs-title ILLINOIS PRIMARY CARES: PRIMARY CARE
  template(#actionSection)
   
  template(#content)
    .card(style="padding-top: 52px;" v-if="isClinicPatient && dataLoaded")
      .columns.is-variable
        .info-pair.column.is-one-third
          .label First Name
          .txt.txt--blue {{ showPatientParsed.first }}
        .info-pair.column.is-one-third
          .label Last Name
          .txt.txt--blue {{ showPatientParsed.last }}
        .info-pair.column.is-one-third
          .label Date of Birth
          .txt.txt--blue {{ showPatientParsed.dobFormatted }}
      .hr.hr--thin
      .columns.is-variable.row-margin-bottom
        .column.is-one-third.flex.flex--flex-start.flex--align-start
          .info-pair
            .label Home Phone Number
            .txt {{ getPhoneFormatted(showPatientParsed.contact.homePhone) }}
          .info-pair
            .label Ext.
            .txt {{ showPatientParsed.contact.homePhoneExtension }}
        .column.is-one-third.flex.flex--flex-start.flex--align-start
          .info-pair
            .label Cell Phone Number
            .txt {{ getPhoneFormatted(showPatientParsed.contact.cellPhone) }}
          .info-pair
            .label Ext.
            .txt {{ showPatientParsed.contact.cellPhoneExtension }}
        .info-pair.column.is-one-third
          .label Clinic MRN
          .txt {{ showPatientParsed.mrn }}
      .hr.hr--thin
      .columns.is-varialbe 
        .column.is-one-third
          .info-pair
            .label Patient Address
            .txt {{ showPatientParsed.address.address }}<br />{{ showPatientParsed.address.place }}, {{showPatientParsed.address.state}} {{ showPatientParsed.address.zipCode }}
        .column.is-two-thirds
          .info-pair
            .label Patient Notes
            textarea(style="height:167px" v-model="showPatientParsed.notes" :disabled="true") 

    .card.card--blue-bg(style="padding-top: 52px;", v-if="!isClinicPatient && dataLoaded")
      .columns.is-variable
        .column.is-one-third
          .form-group(:class="{ 'form-group__invalid': v$.showPatientParsed.first.$errors.length }")
            label(for="first-name") First Name (Required)
            input#first-name(type="text" v-model="showPatientParsed.first" @blur="v$.showPatientParsed.first.$touch()")
            .form-group__errors(v-if="v$.showPatientParsed.first.$dirty && v$.showPatientParsed.first.$errors.length")
              .form-group__error(v-if="v$.showPatientParsed.first.required.$invalid") First Name is required!
        .column.is-one-third
          .form-group( :class="{ 'form-group__invalid': v$.showPatientParsed.last.$errors.length }")
            label(for="last-name") Last Name (Required)
            input#last-name(type="text" v-model="showPatientParsed.last" @blur="v$.showPatientParsed.last.$touch()")
            .form-group__errors(v-if="v$.showPatientParsed.last.$dirty && v$.showPatientParsed.last.$errors.length")
                .form-group__error(v-if="v$.showPatientParsed.last.required.$invalid") Last Name is required!
        .column.is-one-third
          .form-group(:class="{ 'form-group__invalid': v$.showPatientParsed.dobFormatted.$errors.length }")
            label(for="date-of-birth") Date of Birth (Required)
            input#date-of-birth(
              type="text"
              placeholder="MM/DD/YYYY" 
              v-model="showPatientParsed.dobFormatted" 
              @blur="v$.showPatientParsed.dobFormatted.$touch()" 
              v-maska="'##/##/####'" 
              @maska="dobRawValue"
            )
            .form-group__errors(v-if="v$.showPatientParsed.dobFormatted.$dirty && v$.showPatientParsed.dobFormatted.$errors.length")
                .form-group__error Date of birth is required!
               
      .columns.is-variable.align-start
        .column.is-one-third.flex.flex--flex-start.flex--align-start
          .form-group.setMarginRight
            label(for="home-phone") Home Phone Number
            input#home-phone(
                type="text" 
                v-model="showPatientParsed.contact.homePhone"
                v-maska="'###-###-####'" 
                placeholder="xxx-xxx-xxxx" 
                @maska="homePhoneRawValue"
              )
          .form-group
            label(for="ext") Ext.
            input#ext.ext(type="text" v-model="showPatientParsed.contact.homePhoneExtension")
        .column.is-one-third.flex.flex--flex-start.flex--align-start
          .form-group.setMarginRight(:class="{ 'form-group__invalid': v$.showPatientParsed.contact.cellPhone.$errors.length }")
            label(for="cell-phone") Cell Phone Number (Required)
            input#cell-phone(
              type="text" 
              v-model="showPatientParsed.contact.cellPhone" 
              @blur="v$.showPatientParsed.contact.cellPhone.$touch()" 
              v-maska="'###-###-####'" 
              placeholder="xxx-xxx-xxxx" 
              @maska="cellPhoneRawValue"
            )
            .form-group__errors(v-if="v$.showPatientParsed.contact.cellPhone.$dirty && v$.showPatientParsed.contact.cellPhone.$errors.length")
              .form-group__error(v-if="v$.showPatientParsed.contact.cellPhone.required.$invalid") Cell phone is required!
              .form-group__error(v-if="v$.showPatientParsed.contact.cellPhone.minLength.$invalid") Cell phone is required!
          .form-group
            label(for="cell-ext") Ext.
            input#cell-ext.ext(type="text" v-model="showPatientParsed.contact.cellPhoneExtension")

      .columns.is-variable
        .column
          .form-group(:class="{ 'form-group__invalid': v$.showPatientParsed.address.address.$errors.length }")
            label(for="home-address") Home Address (Required)
            input#home-address(type="text" v-model="showPatientParsed.address.address" @blur="v$.showPatientParsed.address.address.$touch()")
            .form-group__errors(v-if="v$.showPatientParsed.address.address.$dirty && v$.showPatientParsed.address.address.$errors.length")
              .form-group__error(v-if="v$.showPatientParsed.address.address.required.$invalid") Home address is required!
      Address(
        @addressResults="addressResults"
        :institution="showPatientParsed"
        textSize=""
      )
      .columns.is-varialbe 
        .column.is-two-thirds
          .info-pair
            .label Patient Notes
            textarea(style="height:167px;width: calc(100% - 6px);" v-model="showPatient.notes") 

    .card.appointment-history(v-if="isClinicPatient" style="padding-top: 52px;")
      h2 Appointment History
      .table.negative-margin.table--hoverable
        .th
          .td APPT TIME
          .td SPECIALTY
          .td REFFERING PHYSICIAN
          .td HOSPITAL
          .td LAST ACTIVITY
          .td REPORT
        .tr(v-if="appointmentsHistory" v-for="appointmentHistory in appointmentsHistory", :key="appointmentHistory.id" @click="openAppointmentRecordOverlay(appointmentHistory?.id)" )
          .td 
            div {{ getDateFromUnixFormatted(appointmentHistory.scheduledAt) }}
            .second-row-md-grey {{ getTimeFromUnixFormatted(appointmentHistory.scheduledAt) }}
          .td.bold {{ appointmentHistory.specialtyModule.moduleTitle }}
          .td {{ appointmentHistory.referringDoctor.first }} {{ appointmentHistory.referringDoctor.last }}
          .td {{ appointmentHistory.clinic.name }}
          .td
            .green(v-if = "appointmentHistory.status == 'Completed'") {{ appointmentHistory.status }}
            //- span(v-else-if = "index == 2") Rescheduled
            .red(v-else) {{ appointmentHistory.status }}
            //- span(v-else) Note Added
            .second-row-md-grey.font-14 Updated {{ getRelativeTimeFromUnixFormatted(appointmentHistory.updatedAt) }} 
          .td 
            base-icon(name="eye-green")
      Pagination(
        v-if="pagination",
        @changePagination="changePagination",
        :pagination="pagination",
        :reloadFn="fetchPatientAppointment"
      )
    
    .card.primary-care-referrals(style="padding-top: 52px;")
      h2 Primary Care Referrals
      .table.negative-margin.table--hoverable
        .th
          .td APPT TIME
          .td COMMUNITY ORGANIZATION
          .td LAST ACTIVITY
        .tr(v-if="referrals" v-for="referral in referrals" :key="referral.id" @click="openReferralRecordOverlay(referral)")
          .td 
            div {{ getDateFromUnixFormatted(referral.scheduledAt) }}
            .second-row-md-grey {{ getTimeFromUnixFormatted(referral.scheduledAt) }}
          .td {{ referral.clinic.name }}
          .td
            .green(v-if = "referral.status == 'New' ") {{ referral.status }}
            .green(v-if = "referral.status == 'In Process' ") {{ referral.status }}
            .green(v-if = "referral.status == 'Completed' ") {{ referral.status }}
            .green(v-if = "referral.status == 'Updated' ") {{ referral.status }}
            .red(v-if = "referral.status == 'Rescheduled' ") {{ referral.status }}
            .red(v-if = "referral.status == 'Cancelled' ") {{ referral.status }}
            
            .second-row-sm-grey Updated {{ getRelativeTimeFromUnixFormatted(referral.updatedAt) }}
      // TO DO: MAKE PAGINATION WORK WITH MULTIPLE INSTANCES   
      //- Pagination(
      //-   v-if="pagination",
      //-   @changePagination="changePagination",
      //-   :pagination="pagination",
      //-   :reloadFn="fetchPatientFitKitFitHistory"
      //- )

    .card.fit-history(style="padding-top: 52px;")
      h2 Fit History
      .table.negative-margin
        .th
          .td KIT #
          .td DATE SENT
          .td PROVIDER NAME
          .td STATUS
          .td DATE OF PROCESSING
          .td RESULTS
          .td 
        .tr(v-if="fitKitHistory" v-for="fitKitH in fitKitHistory", :key="fitKitH.id")
          .td {{ fitKitH.kitNumber }}
          .td
            div {{ getDateFromUnixFormatted(fitKitH.createdAt) }}
            .second-row-md-grey {{ getTimeFromUnixFormatted(fitKitH.createdAt) }}
          .td {{ fitKitH.providerUser.first }} {{ fitKitH.providerUser.last }}
          .td {{ fitKitH.status }}
          .td {{ showProcessed(fitKitH.processedAt) }}
          .td {{ showResult(fitKitH.result) }}
          .td
            BaseBtn.sm.blue-outline(
              href="#",
              @click.prevent="openCompletedFitOverlay(getFitKitCompletion(fitKitH))"
            ) FIT DETAILS

    .card.outside-icc(v-if="isClinicPatient" style="padding-top: 52px;")
      h2 Outside ICC Colonoscopy Appointments
      .table.negative-margin
        .th
          .td KIT #
          .td DATE SENT
          .td PROVIDER NAME
          .td
        .tr(v-if="fitKitOutsideICC" v-for="fitKitO in fitKitOutsideICC", :key="fitKitO.id")
          .td {{ fitKitO.kitNumber }}
          .td {{ getDateFromUnixFormatted(fitKitO.createdAt) }}
          .td {{ fitKitO.providerUser.first }} {{ fitKitO.providerUser.last }}
          .td
            BaseBtn.sm.blue-outline(
              href="#",
              :class="notOutsideIccResults(fitKitO)? 'disabled':''"
              @click.prevent="openCompletedColonoscopyOverlay(fitKitO)"
            ) VIEW RESULTS
  template(#footer)
    BaseBtn.btn.lg.red-outline(href="#", @click.prevent="closeOverlay") CANCEL CHANGES
    BaseBtn.btn.lg.green(
      @click.prevent="updatePatient"
      :disabled="v$.$invalid || isSubmitting"
      :class="{spinner: isSubmitting }"
    ) SAVE AND EXIT
    
CompletedFitOverlay(
  :isOpen="isCompletedFitOverlayOpen",
  :selectedFitKit="selectedFitKit",
  @closeCompletedFitOverlay="closeCompletedFitOverlay",
  @openCompleteFitOverlay="openCompleteFitOverlay"
)
ClinicAppointmentRecordOverlay(
  :key="`${componentKey}-1`",
  :isOpen="isClinicAppointmentRecordOverlayOpen",
  tabType="displayOnly"
  :showAppointment="showAppointment"
  @closeClinicAppointmentRecordOverlay="closeClinicAppointmentRecordOverlay",
  :specialtyModule="specialtyModule"
)
ReferralRecordOverlay(
  :key="`${componentKey}-2`",
  :showReferral="showReferral",
  :isOpen="isReferralRecordOverlayOpen",
  tabType="displayOnly"
  @closeReferralRecordOverlay="closeReferralRecordOverlay",
)
CompletedColonoscopyOverlay(
  :isOpen="isCompletedColonoscopyOverlayOpen",
  :outsideIccFitKit="outsideIccFitKit"
  @closeCompletedColonoscopyOverlay="closeCompletedColonoscopyOverlay"
)

</template>

<script>
import { defineComponent } from "vue";

import FullScreenOverlaySlot from "@/components/overlays/FullScreenOverlaySlot.vue";
import ClinicAppointmentRecordOverlay from "@/components/clinics/appointment-status/overlays/ClinicAppointmentRecordOverlay.vue";
import CompletedColonoscopyOverlay from "@/components/fit-kit/overlays/CompletedColonoscopyOverlay.vue";
import { fetchPatientReferrals } from "@/api/patientReferral";
import ReferralRecordOverlay from "@/components/primary-care-community-organization/patient-referrals/overlays/ReferralRecordOverlay.vue";
import CompletedFitOverlay from "@/components/fit-kit/overlays/CompletedFitOverlay.vue";
import Pagination from "@/components/Pagination.vue";
import listPagesMixin from "@/mixins/listPagesMixin";
import {
  getDateFromUnixFormatted,
  getTimeFromUnixFormatted,
  getDobFormatted,
  getRelativeTimeFromUnixFormatted,
  getPhoneFormatted,
} from "@/helpers";

import {
  fetchPatientAppointment,
  fetchPatientAppointmentById,
} from "@/api/patientAppointmentApi";

import {
  fetchCommunityCareOrganizationPatientByID,
  updateCCOPatient,
} from "@/api/communityOrganization";

import { fetchPatientFitKit } from "@/api/patientApi";
import { apiStatus, apiStatusComputedFactory } from "@/api";
const { IDLE, PENDING, SUCCESS, ERROR } = apiStatus;

import useVuelidate from "@vuelidate/core";
import { required, minLength, maxLength } from "@vuelidate/validators";
import { maska } from "maska";

import Address from "@/components/Address.vue";

export default defineComponent({
  directives: { maska },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    let args = {
      showPatientParsed: {
        first: { required },
        last: { required },
        dobFormatted: {
          required,
          minLength: minLength(10),
          maxLength: maxLength(10),
          function() {
            let unixDistributed = new Date(
              this.showPatientParsed.dobFormatted
            ).getTime();
            if (!unixDistributed) {
              return false;
            }
            return true;
          },
        },
        address: {
          address: { required },
        },
        contact: {
          cellPhone: {
            required,
            minLength: minLength(12),
          },
        },
      },
    };

    return args;
  },
  components: {
    CompletedFitOverlay,
    FullScreenOverlaySlot,
    Pagination,
    ClinicAppointmentRecordOverlay,
    CompletedColonoscopyOverlay,
    Address,
    ReferralRecordOverlay,
  },
  mixins: [listPagesMixin],
  emits: ["closePatientDetailsOverlay", "openAppointmentNotesOverlay"],

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    showPatient: {
      type: Object,
      required: false,
    },
    specialtyModule: {
      required: false,
    },
  },

  data() {
    return {
      isSubmitting: false,
      outsideIccFitKit: null,
      isCompletedColonoscopyOverlayOpen: false,
      selectedFitKit: null,
      isCompletedFitOverlayOpen: false,
      showReferral: null,
      isReferralRecordOverlayOpen: false,
      showPatientParsed: null,
      hidePolypsFoundSelectLabel: false,
      hidePolypsRemovedSelectLabel: false,
      fetchPatientAppointmentStatus: IDLE,
      fetchPatientFitKitStatus: IDLE,
      putPatientStatus: IDLE,
      fetchPatientAppointmentByIdStatus: IDLE,
      fitKitHistory: null,
      fitKitOutsideICC: null,
      appointmentsHistory: null,
      showAppointment: null,
      isClinicAppointmentRecordOverlayOpen: false,
      fetchPatientStatus: IDLE,
      patientExtraInfo: null,
      isClinicPatient: true,
      referrals: null,
      dataLoaded: false,
    };
  },
  computed: {
    ...apiStatusComputedFactory(
      "fetchPatientAppointmentStatus",
      "fetchPatientFitKitStatus",
      "putPatientStatus"
    ),
  },

  methods: {
    openCompletedColonoscopyOverlay(fitKit) {
      this.outsideIccFitKit = fitKit;
      this.isCompletedColonoscopyOverlayOpen = true;
    },
    closeCompletedColonoscopyOverlay() {
      this.isCompletedColonoscopyOverlayOpen = false;
      this.outsideIccFitKit = null;
    },
    notOutsideIccResults(fitKit) {
      return (
        !fitKit ||
        !fitKit.colonoscopyOutsideICC ||
        !fitKit.colonoscopyOutsideICC.result
      );
    },
    openReferralRecordOverlay(referral) {
      this.componentKey = this.componentKey + 1;
      this.showReferral = referral;
      this.isReferralRecordOverlayOpen = true;
    },
    closeReferralRecordOverlay() {
      this.$emit("refreshAppointmentsSummary");
      this.isReferralRecordOverlayOpen = false;
    },
    async fetchPatientReferrals() {
      this.fetchPatientReferralsStatus = PENDING;
      try {
        const res = await fetchPatientReferrals({
          params: {
            idPatient: this.showPatient.id,
            include: ["referringDoctor", "patient", "cancelled", "clinic"],
          },
        });

        this.referrals = res.data.patientReferrals;

        this.fetchPatientReferralsStatus = SUCCESS;
      } catch (err) {
        this.fetchPatientReferralsStatus = ERROR;
        console.error(err);
      }
    },
    async updatePatient() {
      this.isSubmitting = true;
      this.putPatientStatus = PENDING;

      try {
        let sendParams = JSON.parse(JSON.stringify(this.showPatientParsed));
        sendParams.dob = this.showPatientParsed.dobRaw;
        delete sendParams.dobRaw;
        delete sendParams.dobFormatted;
        delete sendParams.isDisabled;
        delete sendParams.clinic;
        delete sendParams.idClinic;
        delete sendParams.mrn;
        delete sendParams.communityCareOrganization;

        sendParams.contact.homePhone = sendParams.contact.homePhoneRaw;
        delete sendParams.contact.homePhoneRaw;
        sendParams.contact.cellPhone = sendParams.contact.cellPhoneRaw;
        delete sendParams.contact.cellPhoneRaw;
        delete sendParams.clinicReferral;

        sendParams.address = this.patientExtraInfo.address;
        sendParams.contact.id = this.patientExtraInfo.contact.id;
        sendParams.idAddress = this.patientExtraInfo.address.id;
        sendParams.idContact = this.patientExtraInfo.idContact;

        await updateCCOPatient(
          this.$route.params.idCco,
          this.showPatientParsed.id,
          sendParams
        );
        this.putPatientStatus = SUCCESS;
      } catch (err) {
        console.log(err);
        this.putPatientStatus = ERROR;
      } finally {
        this.isSubmitting = false;
        this.closeOverlay();
      }
    },
    homePhoneRawValue: function (event) {
      this.showPatientParsed.contact.homePhoneRaw =
        event.target.dataset.maskRawValue;
    },
    cellPhoneRawValue: function (event) {
      this.showPatientParsed.contact.cellPhoneRaw =
        event.target.dataset.maskRawValue;
    },
    dobRawValue: function (event) {
      this.showPatientParsed.dobRaw = event.target.dataset.maskRawValue;
    },
    openCompletedFitOverlay(selectedFitKit) {
      this.selectedFitKit = selectedFitKit;
      this.isCompletedFitOverlayOpen = true;
    },
    closeCompletedFitOverlay() {
      this.isCompletedFitOverlayOpen = false;
    },
    getFitKitCompletion(fitKit) {
      const {
        id,
        idClinic,
        patient,
        kitNumber,
        providerUser,
        result,
        details,
        fluVaccine,
        covidVaccine,
        location,
        insurance,
        createdAt,
        updatedAt,
        processedAt,
        completionUser,
        completionNotes,
        referral,
        colonoscopyInsideICC,
        colonoscopyOutsideICC,
      } = fitKit;
      const isEditable = false;
      const initialCompletion = {
        result,
        details,
        fluVaccine,
        covidVaccine,
        location,
        insurance,
        createdAt,
        processedAt,
        completionUser,
        completionNotes,
        isEditable,
      };
      return {
        id,
        idClinic,
        updatedAt,
        patient,
        kitNumber,
        providerUser,
        initialCompletion,
        referral,
        colonoscopyInsideICC,
        colonoscopyOutsideICC,
      };
    },
    getDateFromUnixFormatted,
    getTimeFromUnixFormatted,
    getDobFormatted,
    getRelativeTimeFromUnixFormatted,
    getPhoneFormatted,
    addressResults(city, state, zip, idPlace, idState, idZipCode) {
      this.showPatientParsed.address.place = city;
      this.showPatientParsed.address.state = state;
      this.showPatientParsed.address.zipCode = zip;
      this.showPatientParsed.address.idPlace = parseInt(idPlace, 10);
      this.showPatientParsed.address.idState = parseInt(idState, 10);
      this.showPatientParsed.address.idZipCode = parseInt(idZipCode, 10);
    },
    openAppointmentRecordOverlay(idSchedule) {
      this.fetchPatientAppointmentById(idSchedule);
    },
    closeClinicAppointmentRecordOverlay() {
      this.isClinicAppointmentRecordOverlayOpen = false;
    },
    async fetchPatientAppointmentById(idSchedule) {
      this.fetchPatientAppointmentByIdStatus = PENDING;
      try {
        const res = await fetchPatientAppointmentById(idSchedule, {
          params: {
            include: [
              "clinic",
              "hospitalDoctor",
              "patient",
              "referringDoctor",
              "hospital",
            ],
          },
        });
        this.showAppointment = res.data.patientAppointment;
        this.isClinicAppointmentRecordOverlayOpen = true;

        this.componentKey = this.componentKey + 1;
        this.fetchPatientAppointmentByIdStatus = SUCCESS;
      } catch (err) {
        this.fetchPatientAppointmentByIdStatus = ERROR;
        console.error(err);
      }
    },
    async fetchPatientAppointment() {
      this.fetchPatientAppointmentStatus = PENDING;
      try {
        const res = await fetchPatientAppointment({
          params: {
            idPatient: this.showPatient.id,
            include: ["hospital", "clinic", "referringDoctor", "patient"],
          },
        });
        this.appointmentsHistory = res.data.patientAppointments;

        this.fetchPatientAppointmentStatus = SUCCESS;
      } catch (err) {
        this.fetchPatientAppointmentStatus = ERROR;
        console.error(err);
      }
    },
    async fetchPatientFitKitFitHistory() {
      this.fetchPatientFitKitStatus = PENDING;
      try {
        const res = await fetchPatientFitKit(this.showPatient.id, {
          params: {
            ...this.reqPagination,
            include: [
              "patient",
              "providerUser",
              "hasColonoscopyOrReferral",
              "completionUser",
            ],
          },
        });
        this.fitKitHistory = res.data.fitKits;

        this.fetchPatientFitKitStatus = SUCCESS;
      } catch (err) {
        this.fetchPatientFitKitStatus = ERROR;
        console.error(err);
      }
    },
    async fetchPatientFitKitOutsideICC() {
      this.fetchPatientFitKitStatus = PENDING;
      try {
        const res = await fetchPatientFitKit(this.showPatient.id, {
          params: {
            ...this.reqPagination,
            include: ["patient", "providerUser", "colonoscopyOutsideICC"],
          },
        });
        this.fitKitOutsideICC = res.data.fitKits;
        this.fetchPatientFitKitStatus = SUCCESS;
      } catch (err) {
        this.fetchPatientFitKitStatus = ERROR;
        console.error(err);
      }
    },
    closeOverlay() {
      this.$emit("closePatientDetailsOverlay");
    },
    openAppointmentNotesOverlay() {
      this.$emit("openAppointmentNotesOverlay");
    },
    showProcessed(processed) {
      if (processed) {
        return this.getDateFromUnixFormatted(processed);
      } else {
        return "N/A";
      }
    },
    showResult(result) {
      if (result) {
        return result;
      } else {
        return "N/A";
      }
    },
    async fetchPatient() {
      this.fetchPatientStatus = PENDING;
      try {
        const res = await fetchCommunityCareOrganizationPatientByID(
          this.$route.params.idCco,
          this.showPatient.id
        );
        this.patientExtraInfo = res.data.patient;
        this.isClinicPatient =
          this.patientExtraInfo.idClinic === null &&
          this.patientExtraInfo.idCco !== null
            ? false
            : true;
        this.dataLoaded = true;

        this.fetchPatientStatus = SUCCESS;
      } catch (err) {
        this.fetchPatientStatus = ERROR;
        console.error(err);
      }
    },
  },
  created() {
    if (!this.showPatient) return;
    this.showPatientParsed = Object.assign({}, this.showPatient);
    this.showPatientParsed.dobRaw = null;
    this.showPatientParsed.contact.cellPhoneRaw = null;
    this.showPatientParsed.contact.homePhoneRaw = null;
    this.fetchPatientFitKitFitHistory();
    this.fetchPatientReferrals();
    if (this.isClinicPatient) {
      this.fetchPatientAppointment();
      this.fetchPatientFitKitOutsideICC();
    }
    this.fetchPatient();
  },
  watch: {
    showPatientParsed: function (val) {
      if (val != null) {
        val.dobFormatted = this.getDobFormatted(val.dob);
      }
    },
  },
});
</script>

<style scoped lang="scss">
.setMarginRight {
  margin-right: 36px;
}
.ext {
  max-width: 100px;
}
.font-14 {
  font-size: 14px;
}
h2 {
  margin-bottom: 62px;
  font-size: 28px;
}
.appointment-history {
  &.card {
    padding-bottom: 48px;
  }
  .td {
    &:nth-child(1) {
      width: math.percentage(math.div(180, $tabContentCardWidth));
    }
    &:nth-child(2) {
      width: math.percentage(math.div(212, $tabContentCardWidth));
    }
    &:nth-child(3) {
      width: math.percentage(math.div(230, $tabContentCardWidth));
    }
    &:nth-child(4) {
      width: math.percentage(math.div(200, $tabContentCardWidth));
    }
    &:nth-child(5) {
      width: math.percentage(math.div(180, $tabContentCardWidth));
    }
    &:nth-child(6) {
      width: math.percentage(math.div(178, $tabContentCardWidth));
    }
  }
}
.primary-care-referrals {
  &.card {
    padding-bottom: 48px;
  }
  .td {
    &:nth-child(1) {
      width: math.percentage(math.div(180, $tabContentCardWidth));
    }
    &:nth-child(2) {
      width: math.percentage(math.div(645, $tabContentCardWidth));
    }
    &:nth-child(3) {
      width: math.percentage(math.div(355, $tabContentCardWidth));
    }
  }
}
.fit-history {
  &.card {
    padding-bottom: 48px;
  }
  .td {
    &:nth-child(1) {
      width: math.percentage(math.div(190, $tabContentCardWidth));
    }
    &:nth-child(2) {
      width: math.percentage(math.div(147, $tabContentCardWidth));
    }
    &:nth-child(3) {
      width: math.percentage(math.div(220, $tabContentCardWidth));
    }
    &:nth-child(4) {
      width: math.percentage(math.div(127, $tabContentCardWidth));
    }
    &:nth-child(5) {
      width: math.percentage(math.div(198, $tabContentCardWidth));
    }
    &:nth-child(6) {
      width: math.percentage(math.div(106, $tabContentCardWidth));
    }
    &:nth-child(7) {
      width: math.percentage(math.div(187, $tabContentCardWidth));
    }
  }
}
.outside-icc {
  &.card {
    padding-bottom: 48px;
  }
  .td {
    &:nth-child(1) {
      width: math.percentage(math.div(187, $tabContentCardWidth));
    }
    &:nth-child(2) {
      width: math.percentage(math.div(147, $tabContentCardWidth));
    }
    &:nth-child(3) {
      width: math.percentage(math.div(660, $tabContentCardWidth));
    }
    &:nth-child(4) {
      width: math.percentage(math.div(185, $tabContentCardWidth));
    }
  }
}
.table .td:first-child {
  padding-left: 35px;
}
.card {
  padding: 35px $secondaryCardsSidePadding 68px;
}
.negative-margin {
  margin-left: -$secondaryCardsSidePadding;
  margin-right: -$secondaryCardsSidePadding;
}
.columns.is-variable {
  --columnGap: 1.575%;
}
.columns + .hr {
  background-color: $primaryBgDarkGrey;
  margin-top: -21px;
  margin-bottom: 24px;
}
.card > .columns:last-child.info-pair,
.card > .columns:last-child .info-pair {
  margin-bottom: 0;
}

// Page specific styles----------------------------------------
.insurance-status {
  margin-bottom: 21px;
  p {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.29;
    letter-spacing: 0.07px;
    color: #000;
  }
}
.rescheduled {
  .label {
    & > span {
      position: relative;
    }
    .svg-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: calc(100% + 20px);
    }
  }
}
.breadcrumbs-title {
  margin: 0;
  color: $primaryLightBlue;
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.12px;
}
.align-start .column {
  align-items: flex-start;
}
</style>
