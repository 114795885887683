<template lang="pug">
TableTitle(
  v-model="searchKeyword",
  @doSearch="fetchPositiveFitKits",
  @blur="fetchPositiveFitKits"
  title="Positive FIT",
  searchPlaceholder="Search...",
)
  template(#actions)
    DateSelector(@filterByDate="filterByDate")
.table(v-bind:class="{ primaryCareDisabled: !isPrimaryCareActive }")
  .th
    .td(@click.prevent="changeListOrder('patientName', fetchPositiveFitKits)" style="cursor: pointer;") Patient Name
      i(style="font-weight: bolder;") {{ patientNameColumnSortingIcon }}
    .td(@click.prevent="changeListOrder('fluVaccine', fetchPositiveFitKits)" style="cursor: pointer;") FLU VACCINE
      i(style="font-weight: bolder;") {{ fluVaccineColumnSortingIcon }}
    .td(@click.prevent="changeListOrder('kitNumber', fetchPositiveFitKits)" style="cursor: pointer;") KIT #
      i(style="font-weight: bolder;") {{ kitNumberColumnSortingIcon }}
    .td(@click.prevent="changeListOrder('distributedAt', fetchPositiveFitKits)" style="cursor: pointer;") DATE SENT
      i(style="font-weight: bolder;") {{ distributedAtColumnSortingIcon }}
    .td(@click.prevent="changeListOrder('providerName', fetchPositiveFitKits)" style="cursor: pointer;") PROVIDER NAME
    .td(v-if="isPrimaryCareActive") CLINIC REFERRAL
  .tr(v-for="fitKit in positiveFitKits" :key="fitKit.id")
    .td.bold {{`${fitKit.patient.first} ${fitKit.patient.last}`}} 
    .td
      div(v-if="fitKit.fluVaccine === null") {{'N/A'}}
      base-icon(v-else-if="fitKit.fluVaccine", name="yes")
      base-icon(v-else="", name="no")
    .td {{fitKit.kitNumber}}
    .td {{getDateFromUnixFormatted(fitKit.distributedAt)}}
    .td {{getDoctorFormatted(fitKit.providerUser)}}
    .td(v-if="isPrimaryCareActive")
      BaseBtn.sm.yellow--outline(
        href="#",
        :to="{name: 'primary-care-community-organization.scheduling.select-schedule', params: { idFitKit: fitKit.id, idPatient: fitKit.idPatient }}"
      ) REFERRAL
Pagination(
  v-if="pagination && positiveFitKits != null && positiveFitKits.length",
  @changePagination="changePagination",
  :pagination="pagination",
  :reloadFn="fetchPositiveFitKits"
)
.no-results(v-if="positiveFitKits != null && !positiveFitKits.length") 
  base-icon(name="exclamation") 
  span No matching records

</template>

<script>
import { defineComponent } from "vue";
import TableTitle from "@/components/TableTitle.vue";
import DateSelector from "@/components/DateSelector.vue";
import Pagination from "@/components/Pagination.vue";
import { fetchCommunityCareOrganizationById } from "@/api/communityOrganization";
import { fetchFitKits } from "@/api/fitKitApi";
import {
  reformatPagination,
  getDoctorFormatted,
  getDateFromUnixFormatted,
} from "@/helpers";
import listPagesMixin from "@/mixins/listPagesMixin";
import sortFitKits from "@/mixins/sortFitKits";

export default defineComponent({
  components: {
    TableTitle,
    DateSelector,
    Pagination,
  },

  mixins: [listPagesMixin, sortFitKits],

  data() {
    return {
      selectedFitKit: null,
      positiveFitKits: null,
      isPrimaryCareActive: false,
      date: null,
      searchKeyword: "",
    };
  },

  methods: {
    filterByDate(date) {
      this.date = date;
      this.fetchPositiveFitKits();
    },
    getDoctorFormatted: getDoctorFormatted,
    getDateFromUnixFormatted: getDateFromUnixFormatted,
    async fetchPositiveFitKits() {
      const order = this.listOrder
        ? {
            by: this.listOrder.by,
            direction: this.listOrder.direction,
          }
        : null;
      const params = {
        order,
        ...this.reqPagination,
        idCommunity: this.$route.params.idCommunity,
        include: ["patient", "providerUser"],
        pickOnly: "neededActionCommunity",
        result: "positive",
        status: "completed",
        searchKeyword: this.searchKeyword,
        distributedAt: this.date,
      };
      if (this.date === null) delete params.distributedAt;
      if (order === null) delete params.order;
      if (!!this.searchKeyword !== true) delete params.searchKeyword;
      try {
        const resFitKit = await fetchFitKits({
          params: {
            ...params,
          },
        });
        this.positiveFitKits = resFitKit.data.fitKits;
        this.pagination = reformatPagination(resFitKit.data.pagination);
      } catch (err) {
        console.error(err);
      }
    },
    async fetchCommunity(idCommunity) {
      try {
        const communityRes = await fetchCommunityCareOrganizationById(
          idCommunity
        );
        this.isPrimaryCareActive = communityRes.data.primaryCare;
      } catch (err) {
        console.error(err);
      }
    },
  },
  created() {
    if (this.$route.params.idCommunity === null) return;
    this.fetchPositiveFitKits();
    this.fetchCommunity(this.$route.params.idCommunity);
  },
});
</script>

<style scoped lang="scss">
.primaryCareDisabled {
  .td {
    &:nth-child(1) {
      width: math.percentage(math.div(300, $tabContentCardWidth));
    }
    &:nth-child(2) {
      width: math.percentage(math.div(130, $tabContentCardWidth));
    }
    &:nth-child(3) {
      width: math.percentage(math.div(150, $tabContentCardWidth));
    }
    &:nth-child(4) {
      width: math.percentage(math.div(200, $tabContentCardWidth));
    }
    &:nth-child(5) {
      width: math.percentage(math.div(200, $tabContentCardWidth));
    }
  }
}
.td {
  &:nth-child(1) {
    width: math.percentage(math.div(234, $tabContentCardWidth));
  }
  &:nth-child(2) {
    width: math.percentage(math.div(103, $tabContentCardWidth));
  }
  &:nth-child(3) {
    width: math.percentage(math.div(140, $tabContentCardWidth));
  }
  &:nth-child(4) {
    width: math.percentage(math.div(140, $tabContentCardWidth));
  }
  &:nth-child(5) {
    width: math.percentage(math.div(202, $tabContentCardWidth));
  }
  &:nth-child(6) {
    width: math.percentage(math.div(359, $tabContentCardWidth));
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .btn {
      display: block;
      width: 155px;
    }
  }
}
</style>
