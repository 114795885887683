<template lang="pug">
label.upload-label(v-if="labelFor", :for="labelFor")
base-icon(
  name="eye-green" 
  style="font-size: 42px;cursor: pointer" 
  v-if="showPreview" 
  @click="preview"
)
base-icon(
  name="eye-red" 
  style="font-size: 42px;" 
  v-if="showPreviewNotAvailable"
)

</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    src: {
      type: String,
    },
    labelFor: {
      type: String,
    },
    showIfNoFileUrl: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    preview() {
      const newTab = window.open("");
      newTab.document.write(
        "<iframe width='100%' height='100%' style='border-width: 0;' src='" +
          encodeURI(this.src) +
          "'></iframe>"
      );
    },
  },

  computed: {
    showPreview() {
      return !!this.src;
    },
    showPreviewNotAvailable() {
      return this.showIfNoFileUrl && !this.showPreview;
    },
  },
});
</script>
