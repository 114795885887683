<template lang="pug">
SideOverlaySlot(
  :isOpen="isOpen",
  title="MANAGE INSURANCE"
  @closeOverlay="closeOverlay"
)
  template(#contentTop)
    .form-group
      label(for="insurance-name") Insurance Name (Required)
      input#insurance-name(type="text" v-model="insurance.name")
  template(#footer)
    .flex.flex--space-between
      BaseBtn.x-lg.red-outline(
        href="#",
        @click.prevent="closeOverlay"
      ) CANCEL
      BaseBtn.x-lg.green(@click="updateInsurance" :disabled="!insurance.name") CONTINUE
</template>

<script>
import { defineComponent } from "vue";
import SideOverlaySlot from "@/components/overlays/SideOverlaySlot.vue";

import { fetchInsurance, updateInsurance } from "@/api/insuranceApi";

export default defineComponent({
  components: { SideOverlaySlot },
  emits: ["closeManageInsuranceOverlay"],

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
    },
  },

  data() {
    return {
      insurance: {
        name: "",
      },
    };
  },

  methods: {
    closeOverlay() {
      this.$emit("closeManageInsuranceOverlay");
    },
    async fetchInsurance() {
      this.insurance = {
        name: "",
      };
      try {
        const response = await fetchInsurance(this.id);
        this.insurance = { ...response.data };
      } catch (error) {
        console.log(error);
      }
    },
    async updateInsurance() {
      try {
        await updateInsurance(this.id, {
          insurance: {
            ...this.insurance,
          },
        });
        this.closeOverlay();
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    if (this.id === null) return;
    this.fetchInsurance();
  },
});
</script>

<style scoped lang="scss">
.patient-section {
  margin-bottom: 31px;
  padding: 37px 24px 25px;
  background-color: $primaryBgBlue;
  p {
    margin: 0 0 12px;
    font-size: 21px;
    font-weight: 500;
    line-height: 1.1;
    color: $primaryLightBlue;
    overflow: hidden;
    strong {
      font-weight: 700;
    }
  }
}
</style>
