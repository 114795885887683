<template lang="pug">
SideOverlaySlot(
  :isOpen="isOpen",
  title="CONFIRM RESCHEDULE",
  @closeOverlay="closeOverlay"
)
  template(#contentTop)
    section.patient-section
      .patient-section__top
        p.bold Patient: {{ getUserFormatted(oldSchedule?.patient) }}
        p
          span.bold FROM {{ getDateFromUnixFormatted(oldSchedule?.scheduledAt) }}&nbsp;
          span  {{ getTimeFromUnixFormatted(oldSchedule?.scheduledAt) }}  
        p {{ oldSchedule?.schedule?.clinic?.name }}
        p 
          span.bold TO: {{ getDateFromUnixFormatted(newSchedule?.availableAt) }}&nbsp;
          span {{ getTimeFromUnixFormatted(newSchedule?.availableAt) }}
        p {{ newSchedule?.clinic.name }}

    .info-pair( v-if="!showScheduleAdded" )
      .label Notes (Required)
      textarea(style="height: 383px;" v-model="noteText")

    .overlay-show-success(v-else="")
      p Successfully rescheduled!
  template(#footer)
    .flex.flex--space-between(v-if="!showScheduleAdded")
      BaseBtn.x-lg.red-outline(
        href="#",
        @click.prevent="closeOverlay"
      ) CANCEL
      BaseBtn.x-lg.green(
        :disabled = "!noteText"
        @click.prevent="addNewSchedule"
      ) YES, RESCHEDULE
    .flex.flex--flex-end(v-else="")
      BaseBtn.x-lg.green(:to="{name: 'primary-care-community-organization.patient-referrals.requested'}") RETURN TO DASHBOARD
</template>

<script>
import { defineComponent } from "vue";
import SideOverlaySlot from "@/components/overlays/SideOverlaySlot.vue";
import {
  getDateFromUnixFormatted,
  getTimeFromUnixFormatted,
  getUserFormatted,
} from "@/helpers";

import { mapGetters } from "vuex";

import { patchPatientReferralById } from "@/api/patientReferral";
import { createPatientAppointmentNote } from "@/api/notifications.ts";

import { apiStatus, apiStatusComputedFactory } from "@/api";
const { IDLE, PENDING, SUCCESS, ERROR } = apiStatus;

export default defineComponent({
  components: { SideOverlaySlot },
  emits: ["closeConfirmScheduleOverlay"],

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    newSchedule: {
      required: true,
    },
    oldSchedule: {
      required: true,
    },
  },
  data() {
    return {
      showScheduleAdded: false,
      noteText: null,
      createPatientReferralNoteStatus: IDLE,
      patchPatientReferralByIdStatus: IDLE,
    };
  },
  computed: {
    ...mapGetters(["getUserId", "getUserRoleContext"]),
    ...apiStatusComputedFactory(
      "patchPatientReferralByIdStatus",
      "createPatientReferralNoteStatus"
    ),
  },
  methods: {
    getUserFormatted,
    getDateFromUnixFormatted,
    getTimeFromUnixFormatted,
    async createPatientReferralNote() {
      this.createPatientReferralNoteStatus = PENDING;
      try {
        let origin = "Community";
        let destination = "Clinic";

        if (this.getUserRoleContext.clinicHealthSystem) {
          origin = "Clinic";
          destination = "Community";
        }

        const res = await createPatientAppointmentNote({
          idEntity: this.$route.params.idAppointment,
          entityType: "referral",
          createdBy: this.getUserId,
          note: {
            content: this.noteText,
            stage: this.oldSchedule.stage,
            type: "Rescheduled",
            origin: origin,
            destination: destination,
          },
        });
        this.noteText = "";
        this.createPatientReferralNoteStatus = SUCCESS;
      } catch (err) {
        this.createPatientReferralNoteStatus = ERROR;
        console.error(err);
      }
    },
    async patchPatientReferralById() {
      this.patchPatientReferralByIdStatus = PENDING;
      try {
        const res = await patchPatientReferralById(
          this.$route.params.idAppointment,
          {
            id: this.$route.params.idAppointment,
            idSchedule: this.newSchedule.id,
            scheduledAt: this.newSchedule.availableAt,
            stage: "REQUESTED",
            status: "Rescheduled",
          }
        );
        this.showScheduleAdded = true;
        this.patchPatientReferralByIdStatus = SUCCESS;
      } catch (err) {
        this.patchPatientReferralByIdStatus = ERROR;
        console.error(err);
      }
    },
    closeOverlay() {
      this.showScheduleAdded = false;
      this.$emit("closeConfirmScheduleOverlay");
    },
    resetAddNewSchedule() {
      this.showScheduleAdded = false;
    },
    addNewSchedule() {
      this.patchPatientReferralById().then(() => {
        this.createPatientReferralNote();
      });
    },
  },
  created() {
    if (this.oldSchedule === null) return;
  },
});
</script>

<style scoped lang="scss">
.patient-section {
  margin-bottom: 0;
  padding: 15px 24px 15px;
  background-color: $primaryBgBlue;
  margin-bottom: 53px;
  margin-top: 26px;
  p {
    margin: 0 0 12px;
    font-size: 21px;
    line-height: 1.1;
    color: $primaryLightBlue;
    overflow: hidden;
    strong {
      font-weight: 700;
    }
  }
}
form#add-new-schedule {
  margin: 12px 0 20px;
  .columns {
    margin-bottom: 0px;
    .column {
      padding-bottom: 0px;
    }
  }
}
.overlay-show-success > p {
  max-width: 400px;
}
</style>
