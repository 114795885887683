<template lang="pug">
FullScreenOverlaySlot(
  :isOpen="isOpen",
  headerIcon="icc-logo",
  :title="overlayTitle"
  @closeOverlay="closeOverlay"
)
  template(#breadcrumbs)
    h1.breadcrumbs-title IL CARE CONTINUUM
  template(#content)
  
    .card.hospital_details
      .columns.is-variable
        .column.is-two-thirds
          .form-group(:class="{ 'form-group__invalid': v$.Hospital.name.$errors.length }")
            label.label--margin-sm Hospital Name (Required)
            input.input.text-lg(type="text", v-model="Hospital.name" @blur="v$.Hospital.name.$touch()")
            .form-group__errors(v-if="v$.Hospital.name.$dirty && v$.Hospital.name.$errors.length")
              .form-group__error(v-if="v$.Hospital.name.required.$invalid") Hospital Name is required!
        .column.is-one-fifth
          .form-group(:class="{ 'form-group__invalid': v$.Hospital.contact.businessPhone.$errors.length }")
            label.label--margin-sm Phone Number (Required)
            input.input.text-lg(
              type="text", 
              v-model="Hospital.contact.businessPhone" 
              @blur="v$.Hospital.contact.businessPhone.$touch()"
              placeholder="xxx-xxx-xxxx" 
              v-maska="'###-###-####'"
              @maska="businessPhoneRawValue"
            )
            .form-group__errors(v-if="v$.Hospital.contact.businessPhone.$dirty && v$.Hospital.contact.businessPhone.$errors.length")
              .form-group__error(v-if="v$.Hospital.contact.businessPhone.required.$invalid") Phone Number is required!
              .form-group__error(v-if="v$.Hospital.contact.businessPhone.minLength.$invalid") Phone Number is required!
        .column.is-one-fifth.phone-ext
          .form-group
            label.label--margin-sm Ext.
            input.input.text-lg(type="text", v-model="Hospital.contact.businessPhoneExtension")
      .columns.is-variable
        .column.is-full
          .form-group(:class="{ 'form-group__invalid': v$.Hospital.address.address.$errors.length }")
            label.label--margin-sm Address (Required)
            input.input.text-lg(type="text", v-model="Hospital.address.address" @blur="v$.Hospital.address.address.$touch()")
            .form-group__errors(v-if="v$.Hospital.address.address.$dirty && v$.Hospital.address.address.$errors.length")
              .form-group__error(v-if="v$.Hospital.address.address.required.$invalid") Address is required!
      Address(
        @addressResults="addressResults"
        :institution="Hospital"
        textSize="text-lg"
      )
      .columns.is-variable(v-if="showLinkedArea")
        .column.is-full
          .table.table--no-side-padding
            .th.hr_only
          BaseBtn.btn.lg.blue(
            href="#",
            @click.prevent="openLinkedClinicHealthSystemsOverlay"
          ) LINKED CLINIC HEALTH SYSTEMS
    .card.modules
      .title-md-thin-with-action
        h2.title.title--md-thin(style="margin-bottom: 27px;") MODULES
      .table.table--no-side-padding(v-if="activespecialtyModules")
        .th.specialty_modules
          .td Specialty Modules
          .td Ongoing Care
          .td Active
        .tr.tr__row(v-for="element in activespecialtyModules", :key="element.id")
          .td
            h2 {{ element.moduleTitle }}
          .td
            div(v-if="element.isManageCare") 
              BaseToggle(
                :key="'ongoingCare'+element.id"
                :modelValue="element.toggleOnOngoingCare"
                :disabled="!element.toggleOn"
                @change="changeOngoingCare(element.id, $event.target.checked)"
              )
          .td
            BaseToggle(
              :key="element.id"
              :modelValue="element.toggleOn"
              @change="changeActiveSpecialties(element.id, $event.target.checked)"
            )
    .card.help_resources
      h2.title.title--md-thin OTHER INFORMATION
      .columns.is-variable
        .column.is-one-third
          .form-group
            label(for="user-support-text") Directions
            textarea#user-support-text(style="height:250px;", v-model="Hospital.directions")
        .column.is-one-third
          .form-group
            label(for="resources-text") General Information
            textarea#resources-text(style="height:250px;", v-model="Hospital.generalInfo")
        .column.is-one-third
          .form-group
            label Hospital Form
            .txt
              UploadAndPreviewFile(
                :buttonStyleClasses="['btn', 'lg', 'blue']"
                :storageKey="hospitalFormStorageKey"
                :src="hospitalFormFile"
              )

  template(#footer)
    BaseBtn.btn.lg.red-outline(href="#", @click.prevent="closeOverlay") CANCEL CHANGES
    BaseBtn.btn.lg.green(
      @click.prevent="saveHospital"
      :disabled="v$.$invalid || isSubmitting"
      :class="{spinner: isSubmitting }"
    ) SAVE AND EXIT

LinkedClinicHealthSystemsOverlay(
  :isOpen="isLinkedClinicHealthSystemsOverlayOpen",
  :hospId="hospId",
  @closeLinkedClinicHealthSystemsOverlay="closeLinkedClinicHealthSystemsOverlay"
)
</template>

<script>
import { defineComponent } from "vue";
import FullScreenOverlaySlot from "@/components/overlays/FullScreenOverlaySlot.vue";
import LinkedClinicHealthSystemsOverlay from "@/components/organizations/overlays/LinkedClinicHealthSystemsOverlay.vue";
import UploadAndPreviewFile from "@/components/UploadAndPreviewFile.vue";

import { fetchSpecialtyModules } from "@/api/specialtyModulesApi";
import {
  createHospital,
  fetchHospital,
  updateHospital,
  getHospitalSpecialty,
} from "@/api/hospitalApi";
import { apiStatus, apiStatusComputedFactory } from "@/api";
const { IDLE, PENDING, SUCCESS, ERROR } = apiStatus;
import remove from "lodash/remove";
import Address from "@/components/Address.vue";
import { fetchFileById, uploadFile } from "@/api/filesApi";
import { getFileSignedUrl } from "@/helpers";

import useVuelidate from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import { maska, mask } from "maska";

export default defineComponent({
  directives: { maska },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    let args = {
      Hospital: {
        name: { required },
        address: {
          address: {
            required,
          },
        },
        contact: {
          businessPhone: {
            required,
            minLength: minLength(12),
          },
        },
      },
    };

    return args;
  },
  components: {
    FullScreenOverlaySlot,
    LinkedClinicHealthSystemsOverlay,
    Address,
    UploadAndPreviewFile,
  },
  emits: ["closeHospitalsOverlay", "reloadHospitals"],

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    hospId: {
      required: false,
    },
  },

  data() {
    return {
      isSubmitting: false,

      fetchHospitalStatus: IDLE,
      updateHospitalStatus: IDLE,
      getHospitalSpecialtyStatus: IDLE,
      Hospital: {
        name: null,
        contact: {
          businessPhone: null,
          businessPhoneRaw: null,
          businessPhoneExtension: null,
        },
        address: {
          address: null,
          place: null,
          state: null,
          zipCode: null,
          idPlace: null,
          idState: null,
          idZipCode: null,
        },
        // keep adding here
      },
      specialtiesStatusChanged: [],
      specialtiesStatusAdd: [],
      ongoingStatusAdd: [],
      isModuleEnabled: true,
      overlayTitle: this.hospId ? "MANAGE HOSPITAL" : "ADD NEW HOSPITAL",
      showLinkedArea: this.hospId ? true : false,
      specialtyModules: null,
      isLinkedClinicHealthSystemsOverlayOpen: false,
      activeSpecialties: [],
      hospitalFormStorageKey: "hospital_form_upload_key",
      hospitalFormFile: null,
    };
  },

  computed: {
    ...apiStatusComputedFactory([
      "createHospitalStatus",
      "fetchHospitalStatus",
      "updateHospitalStatus",
    ]),
    activespecialtyModules: function () {
      let activeSpecialties;
      if (!this.activeSpecialties) {
        activeSpecialties = this.specialtyModules;
      } else {
        activeSpecialties = this.activeSpecialties;
      }
      if (this.specialtyModules) {
        return this.specialtyModules.filter(function (u) {
          if (activeSpecialties) {
            u.toggleOn = false;
            u.toggleOnOngoingCare = false;
            activeSpecialties.forEach((element) => {
              if (u.id == element.specialtyModule.id) {
                u.toggleOn = true;
                u.toggleOnOngoingCare = element.isOngoingActive;
              }
            });
          }
          return u.isModuleEnabled;
        });
      } else {
        return false;
      }
    },
  },

  methods: {
    businessPhoneRawValue: function (event) {
      this.Hospital.contact.businessPhoneRaw =
        event.target.dataset.maskRawValue;
    },
    addressResults(city, state, zip, idPlace, idState, idZipCode) {
      this.Hospital.address.place = city;
      this.Hospital.address.state = state;
      this.Hospital.address.zipCode = zip;
      this.Hospital.address.idPlace = parseInt(idPlace);
      this.Hospital.address.idState = parseInt(idState);
      this.Hospital.address.idZipCode = parseInt(idZipCode);
    },
    closeOverlay() {
      sessionStorage.removeItem(this.hospitalFormStorageKey);
      this.$emit("closeHospitalsOverlay");
    },
    closeLinkedClinicHealthSystemsOverlay() {
      this.isLinkedClinicHealthSystemsOverlayOpen = false;
    },
    openLinkedClinicHealthSystemsOverlay() {
      this.isLinkedClinicHealthSystemsOverlayOpen = true;
    },
    changeOngoingCare(id, checked) {
      if (this.hospId === null) {
        if (checked === true) {
          if (this.ongoingStatusAdd.includes(id)) {
            return;
          } else {
            this.ongoingStatusAdd.push(id);
          }
        } else {
          this.ongoingStatusAdd = remove(this.ongoingStatusAdd, function (n) {
            return n !== id;
          });
        }
      } else {
        let found = false;
        this.specialtiesStatusChanged.forEach((element) => {
          if (element.idSpecialtyModule == id) {
            found = true;
            element.active = true;
            element.isOngoingActive = checked;
          }
        });
        if (!found) {
          this.specialtiesStatusChanged.push({
            isOngoingActive: checked,
            active: true,
            idSpecialtyModule: id,
          });
        }
      }
    },
    changeActiveSpecialties(id, checked) {
      if (this.hospId === null) {
        if (checked === true) {
          if (this.specialtiesStatusAdd.includes(id)) {
            return;
          } else {
            this.specialtiesStatusAdd.push(id);
          }
        } else {
          this.specialtiesStatusAdd = remove(
            this.specialtiesStatusAdd,
            function (n) {
              return n !== id;
            }
          );
        }
      } else {
        let found = false;
        this.specialtiesStatusChanged.forEach((element) => {
          if (element.idSpecialtyModule == id) {
            found = true;
            element.active = checked;
            element.isOngoingActive = false;
          }
        });
        if (!found) {
          this.specialtiesStatusChanged.push({
            active: checked,
            idSpecialtyModule: id,
            isOngoingActive: checked,
          });
        }
      }
      // need to update main array, this is connected to the Ongoing Care toggle
      this.activespecialtyModules.forEach((element) => {
        if (element.id == id) {
          element.toggleOn = checked;
          if (!checked) {
            element.toggleOnOngoingCare = checked;
          }
        }
      });
    },

    async saveHospital() {
      if (this.hospId === null) {
        await this.createHospital();
      } else {
        await this.updateHospital();
      }
      this.$emit("reloadHospitals");
      this.closeOverlay();
    },
    async createHospital() {
      this.isSubmitting = true;

      this.createHospitalStatus = PENDING;
      const idHospitalForm = await this.checkAndUploadHospitalForm();

      try {
        this.Hospital.contact.businessPhone =
          this.Hospital.contact.businessPhoneRaw;
        delete this.Hospital.contact.businessPhoneRaw;

        await createHospital({
          hospital: {
            ...this.Hospital,
            idHospitalForm,
          },
          activeSpecialties: this.specialtiesStatusAdd,
          ongoingActiveSpecialties: this.ongoingStatusAdd,
        });
        this.createHospitalStatus = SUCCESS;
      } catch (err) {
        console.log(err);
        this.createHospitalStatus = ERROR;
      } finally {
        this.isSubmitting = false;
      }
    },
    async checkAndUploadHospitalForm() {
      if (sessionStorage.getItem(this.hospitalFormStorageKey)) {
        const {
          file: { id: idHospitalForm },
        } = await uploadFile(this.hospitalFormStorageKey);

        return idHospitalForm;
      }

      return undefined;
    },
    async updateHospital() {
      this.isSubmitting = true;
      const idHospitalForm = await this.checkAndUploadHospitalForm();
      this.updateHospitalStatus = PENDING;
      this.specialtiesStatusChanged.forEach((element) => {
        if (!element.active) {
          element.isOngoingActive = false;
        }
      });
      try {
        this.Hospital.contact.cellPhone =
          this.Hospital.contact.businessPhoneRaw;
        delete this.Hospital.contact.businessPhoneRaw;
        await updateHospital(`${this.hospId}`, {
          hospital: { ...this.Hospital, idHospitalForm },
          specialtiesStatusChanged: this.specialtiesStatusChanged,
        });
        this.updateHospitalStatus = SUCCESS;
      } catch (err) {
        console.log(err);
        this.updateHospitalStatus = ERROR;
      } finally {
        this.isSubmitting = false;
      }
    },

    async getHospitalSpecialty() {
      this.getHospitalSpecialtyStatus = PENDING;
      try {
        const res = await getHospitalSpecialty(this.hospId);
        this.activeSpecialties = res.data.hospitalSpecialties;
        this.getHospitalSpecialtyStatus = SUCCESS;
      } catch (err) {
        console.log(err);
        this.getHospitalSpecialtyStatus = ERROR;
      }
    },
    async fetchHospital() {
      this.fetchHospitalStatus = PENDING;
      try {
        const res = await fetchHospital(this.hospId);
        this.Hospital = res.data;
        this.Hospital.contact.businessPhone = mask(
          this.Hospital.contact.businessPhone,
          "###-###-####"
        );
        this.getHospitalSpecialty();

        if (res.data.idHospitalForm) {
          const { data } = await fetchFileById(res.data.idHospitalForm);
          const file = data.file;
          file.token = data.token;
          this.hospitalFormFile = getFileSignedUrl(file);
        }
        this.fetchHospitalStatus = SUCCESS;
      } catch (err) {
        console.log(err);
        this.fetchHospitalStatus = ERROR;
      }
    },
    async fetchSpecialtyModules() {
      try {
        const res = await fetchSpecialtyModules({
          params: {
            size: 100,
          },
        });
        this.specialtyModules = res.data.specialtyModules;
        this.pagination = res.data.pagination;
      } catch (err) {
        console.error(err);
      }
    },
  },
  created() {
    this.fetchSpecialtyModules(); //this needs to run for new hospitals as well
    if (this.hospId === null) return;
    this.fetchHospital();
  },
});
</script>

<style scoped lang="scss">
.phone-ext {
  max-width: 13.4%;
}
.help_resources h2.title--md-thin {
  margin-bottom: 38px;
}
.table .th.hr_only {
  padding-bottom: 11px;
  margin-bottom: 53px;
}
.table .tr.tr_child {
  padding-top: 0px;
  padding-bottom: 33px;
}
.tr.tr__row {
  padding-top: 33px;
  padding-bottom: 33px;
}
.no_bottom_border {
  border-bottom: 0px;
}
.card {
  padding: 35px $secondaryCardsSidePadding 20px $secondaryCardsSidePadding;
  &.hospital_details {
    padding-top: 55px;
    padding-bottom: 45px;
    .form-group {
      margin-bottom: 18px;
    }
  }
  &.help_resources {
    padding-top: 45px;
  }
  &.modules {
    padding-top: 45px;
    padding-bottom: 0px;
  }
}
.columns.is-variable {
  --columnGap: 1.575%;
}
.columns + .hr {
  background-color: $primaryBgDarkGrey;
  margin-top: -21px;
  margin-bottom: 24px;
}
.card > .columns:last-child.info-pair,
.card > .columns:last-child .info-pair {
  margin-bottom: 0;
}
.svg-icon--eye-red,
.svg-icon--eye-green,
.svg-icon--yes,
.svg-icon--no {
  width: 50px;
  height: 50px;
  top: 0;
}

// Page specific styles----------------------------------------
.breadcrumbs-title {
  margin: 0;
  color: $primaryLightBlue;
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.12px;
}
.procedure-dropdown-instructions {
  margin-top: -20px;
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 1.29;
}
.table {
  .td {
    &:nth-child(1) {
      width: math.percentage(math.div(810, $tabContentCardWidth));
    }
    &:nth-child(2) {
      width: math.percentage(math.div(296, $tabContentCardWidth));
    }
    &:nth-child(3) {
      width: math.percentage(math.div(74, $tabContentCardWidth));
    }
  }
  .row-main-options {
    padding: 35px 0;
    .base-checkbox:not(:last-child) {
      margin-right: 84px;
    }
  }
  .th {
    padding-bottom: 25px;
  }
}
</style>
