<template lang="pug">
TableTitle(title="Appointment Status",
  searchPlaceholder="Search by patient name or hospital"
)
  template(#actions)
    DateSelector()
.table
  .th
    .td Appt. Time
    .td Patient Name
    .td Referring Physician
    .td Hospital
    .td Last Activity
    .td Report
    .td Path.
  .tr(
    v-for="index in 10",
    :key="index",
    @click="openAppointmentRecordOverlay"
  )
    .td
      div 07-30-2020
      .second-row-md-grey 6:30 PM
    .td.bold John Doe
    .td(v-tooltip="'Dr. Romana Bepolinguan Timisoara Dracula Magyarország Mayday'") Dr. Romana Bepolinguan Timisoara Dracula Magyarország Mayday
    .td Michigan Hospital <br>Surgery Center
    .td
      .green Completed
      .second-row-sm-grey Updated 10 days ago
    .td
      base-icon(name="eye-green")
    .td
      base-icon(name="yes")
Pagination(v-if="pagination")
AppointmentRecordOverlay(
  :isOpen="isAppointmentRecordOverlayOpen",
  @closeAppointmentRecordOverlay="closeAppointmentRecordOverlay",
  @openAppointmentNotesOverlay="openAppointmentNotesOverlay"
)
AppointmentNotesOverlay(
  :isOpen="isAppointmentNotesOverlayOpen",
  @closeAppointmentNotesOverlay="closeAppointmentNotesOverlay"
)
</template>

<script>
import { defineComponent } from "vue";
import TableTitle from "@/components/TableTitle.vue";
import DateSelector from "@/components/DateSelector.vue";
import Pagination from "@/components/Pagination.vue";
import AppointmentRecordOverlay from "@/components/overlays/full-screen-overlays/AppointmentRecordOverlay.vue";
import AppointmentNotesOverlay from "@/components/overlays/side-overlays/AppointmentNotesOverlay.vue";

export default defineComponent({
  components: {
    TableTitle,
    DateSelector,
    Pagination,
    AppointmentRecordOverlay,
    AppointmentNotesOverlay,
  },

  data() {
    return {
      isAppointmentRecordOverlayOpen: false,
      isAppointmentNotesOverlayOpen: false,
    };
  },

  methods: {
    openAppointmentRecordOverlay() {
      this.isAppointmentRecordOverlayOpen = true;
    },
    closeAppointmentRecordOverlay() {
      this.isAppointmentRecordOverlayOpen = false;
    },

    openAppointmentNotesOverlay() {
      this.isAppointmentNotesOverlayOpen = true;
    },
    closeAppointmentNotesOverlay() {
      this.isAppointmentNotesOverlayOpen = false;
    },
  },
});
</script>

<style scoped lang="scss">
.tr {
  cursor: pointer;
}
.td {
  &:nth-child(1) {
    width: 15%;
  }
  &:nth-child(2) {
    width: 18%;
  }
  &:nth-child(3) {
    width: 19.9%;
  }
  &:nth-child(4) {
    width: 17%;
  }
  &:nth-child(5) {
    width: 14.7%;
  }
  &:nth-child(6) {
    width: 7.7%;
  }
  &:nth-child(7) {
    width: 7.7%;
  }
}
</style>
