<template lang="pug">
.card(v-if="notifications", style="padding-bottom: 40px;")
  TableTitle(title="Notifications",
    searchPlaceholder="Search by patient name"
    v-model="searchKeyword",
    @doSearch="fetchNotification",
    @blur="fetchNotification"
  )
    template(#actions)
      select.table-action-selector.table-action-selector--bell(
        @change="setFilterValue($event.target.value)"
      )
        option(value="New") NEW ONLY
        option(value="Completed") COMPLETED
        option(value="Clinic") CLINIC
        option(value="Hospital") HOSPITAL
        option(value="All") VIEW ALL
      DateSelector(@filterByDate="filterByDate")

  .table.table--hoverable
    .th
      .td(@click.prevent="changeListOrder('NoteDate', fetchNotification)" style="cursor: pointer;") NOTE DATE
        i(style="font-weight: bolder;") {{ NoteDateColumnSortingIcon }}
      .td Patient Name
      .td TYPE
      .td(@click.prevent="changeListOrder('Status', fetchNotification)" style="cursor: pointer;") LAST ACTIVITY
        i(style="font-weight: bolder;") {{ StatusColumnSortingIcon }}
      .td DONE
    .tr(v-for="notification in notifications", :key="notification.id", @click="openAppointmentNotesOverlay(notification)")
      .td
        div {{unixToUTCDate(notification)}} 
        .second-row-md-grey {{unixToUTCTime(notification)}}
      .td.bold  {{ getUserFormatted(notification.patient)}}
      .td
        div(:class="{'red': notification.type === 'Cancelled'}") {{notification.type}}
        .second-row-md-grey {{ notification.specialtyModule?.moduleTitle }}
      .td
        div(
          :class="isCompleted(notification) ? 'green':'red'"
        ) {{isCompleted(notification) ? `Completed by ${notification.completedBy.first} ${notification.completedBy.last}` : `Not Completed` }}
        .second-row-sm-grey {{isViewed(notification) ? `Last viewed by ${notification?.lastViewedBy?.first} ${notification?.lastViewedBy?.last}` : `Not viewed`}}
      .td
        base-icon(:name="isCompleted(notification) ? 'yes': 'no'")
  Pagination(
    v-if="pagination && notifications != null && notifications.length",
    @changePagination="changePagination",
    :pagination="pagination",
    :reloadFn="fetchNotification"
  )
  .no-results(v-if="notifications != null && !notifications.length") 
    base-icon(name="exclamation") 
    span No matching records
AppointmentNotesOverlay(
  :key="`${componentKeyForAppointment}-1`",
  :isOpen="isAppointmentNotesOverlayOpen",
  @closeAppointmentNotesOverlay="closeAppointmentNotesOverlay"
  @fetchPatientAppointmentNotifications="fetchNotification"
  @openViewAppointment="fetchPatientAppointmentById"
  :patientAppointment="sendNotification"
  showAppointmentsFor="hospitals"
)

HospitalAppointmentRecordOverlay(
  :key="`${componentKey}-1`",
  :isOpen="isHospitalAppointmentRecordOverlayOpen",
  tabType="displayOnly"
  :showAppointment="showAppointmentHospital"
  @closeHospitalAppointmentRecordOverlay="closeHospitalAppointmentRecordOverlay",
)
</template>

<script>
import { defineComponent } from "vue";
import moment from "moment";
import { getUserFormatted } from "@/helpers";
import { mapGetters } from "vuex";
import TableTitle from "@/components/TableTitle.vue";
import DateSelector from "@/components/DateSelector.vue";
import Pagination from "@/components/Pagination.vue";
import listPagesMixin from "@/mixins/listPagesMixin";
import AppointmentNotesOverlay from "@/components/overlays/side-overlays/AppointmentNotesOverlay.vue";
import { fetchPatientAppointmentById } from "@/api/patientAppointmentApi";
import { fetchNotification } from "@/api/notifications.ts";

import HospitalAppointmentRecordOverlay from "@/components/hospitals/appointment-status/overlays/HospitalAppointmentRecordOverlay.vue";
import { apiStatus, apiStatusComputedFactory } from "@/api";
const { IDLE, PENDING, SUCCESS, ERROR } = apiStatus;
import sortNotifications from "@/mixins/sortNotifications";

export default defineComponent({
  components: {
    TableTitle,
    DateSelector,
    Pagination,
    AppointmentNotesOverlay,
    HospitalAppointmentRecordOverlay,
  },
  mixins: [listPagesMixin, sortNotifications],
  data() {
    return {
      componentKeyForAppointment: 0,
      isAppointmentNotesOverlayOpen: false,
      notifications: null,
      searchKeyword: "",
      pagination: null,
      status: "New",
      date: null,
      sendNotification: null,
      isHospitalAppointmentRecordOverlayOpen: false,
      fetchPatientAppointmentByIdStatus: IDLE,
      showAppointmentHospital: null,
      origin: null,
      apiOrder: {
        by: "",
        direction: "",
      },
      orders: {
        "date-time": "desc",
        status: "desc",
      },
      isHospitalFilter: false,
    };
  },

  computed: {
    ...mapGetters(["destinationType", "getUserRoleContext"]),
  },

  methods: {
    getUserFormatted,
    async fetchPatientAppointmentById(appointmentId) {
      this.fetchPatientAppointmentByIdStatus = PENDING;
      try {
        const res = await fetchPatientAppointmentById(appointmentId, {
          params: {
            include: [
              "clinic",
              "clinicReport",
              "emergencyContact",
              "hospital",
              "hospitalDoctor",
              "hospitalReport",
              "insurance",
              "pathologyReport",
              "patient",
              "referringDoctor",
            ],
          },
        });
        let appointment = res.data.patientAppointment;
        this.openHospitalAppointmentRecordOverlay(appointment);

        this.fetchPatientAppointmentByIdStatus = SUCCESS;
      } catch (err) {
        this.fetchPatientAppointmentByIdStatus = ERROR;
        console.error(err);
      }
    },
    openHospitalAppointmentRecordOverlay(appointment) {
      this.componentKey = this.componentKey + 1;
      this.showAppointmentHospital = appointment;
      this.isHospitalAppointmentRecordOverlayOpen = true;
    },
    closeHospitalAppointmentRecordOverlay() {
      this.isHospitalAppointmentRecordOverlayOpen = false;
    },
    openAppointmentNotesOverlay(sendNotification) {
      this.componentKeyForAppointment++;

      let sendPatientInfo = {
        patient: sendNotification.patient,
        id: sendNotification.patientAppointment.id,
      };
      this.sendNotification = sendPatientInfo;
      this.isAppointmentNotesOverlayOpen = true;
    },
    closeAppointmentNotesOverlay() {
      this.isAppointmentNotesOverlayOpen = false;
      this.fetchNotification();
    },

    setFilterValue(value) {
      this.origin = null;
      this.status = null;
      this.isHospitalFilter = false;
      if (value === "New" || value === "Completed") {
        this.status = value;
      } else {
        if (value === "Clinic") {
          this.origin = "Clinic";
        } else if (value === "Hospital") {
          this.isHospitalFilter = true;
        }
      }
      this.fetchNotification();
    },
    filterByDate(date) {
      this.date = date;
      this.fetchNotification();
    },

    async fetchNotification() {
      const order = this.listOrder
        ? {
            by: this.listOrder.by,
            direction: this.listOrder.direction,
          }
        : null;
      try {
        if (!this.isHospitalFilter) {
          let res = await fetchNotification({
            params: {
              ...this.reqPagination,
              order,
              status: this.status ?? null,
              destinationId: this.getUserRoleContext.hospital.id,
              destination: "Hospital",
              origin: this.origin ?? null,
              searchKeyword: this.searchKeyword || null,
              createdAt: this.date ?? null,
              include: ["completedBy", "lastViewedBy", "patient"],
            },
          });
          this.notifications = res.data.notifications;
          this.pagination = res.data.pagination;
        } else {
          let res = await fetchNotification({
            params: {
              ...this.reqPagination,
              order,
              status: this.status ?? null,
              originId: this.getUserRoleContext.hospital.id,
              origin: "Hospital",
              searchKeyword: this.searchKeyword || null,
              createdAt: this.date ?? null,
              include: ["completedBy", "lastViewedBy", "patient"],
            },
          });
          this.notifications = res.data.notifications;
          this.pagination = res.data.pagination;
        }
      } catch (error) {
        console.error(error);
      }
    },

    unixToUTCDate(notification) {
      return moment.unix(notification.createdAt).format("MM-DD-YYYY");
    },
    unixToUTCTime(notification) {
      return moment.unix(notification.createdAt).format("hh:mm A");
    },
    isCompleted(notification) {
      if (notification?.completedAt) return true;
      return false;
    },
    isViewed(notification) {
      if (notification?.lastViewedBy?.email) return true;
      return false;
    },
  },
  created() {
    this.fetchNotification();
  },
});
</script>

<style scoped lang="scss">
.tr {
  cursor: pointer;
}
.td {
  &:nth-child(1) {
    width: 15%;
  }
  &:nth-child(2) {
    width: 18%;
  }
  &:nth-child(3) {
    width: 20%;
  }
  &:nth-child(4) {
    width: 40%;
  }
  &:nth-child(5) {
    width: 7%;
  }
}
</style>
