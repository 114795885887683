<template lang="pug">
.content-wrapper
  FITHeader(:showAddNewFitBtn="false", :idCommunity="+idCommunity")
  .card.router-view-card
    router-view()
</template>

<script>
import { defineComponent } from "vue";
import FITHeader from "@/components/fit-kit-community-organization/FITHeader.vue";

export default defineComponent({
  components: { FITHeader },

  data() {
    return {
      idCommunity: null,
    };
  },

  methods: {},

  created() {
    if (this.$route.params.idCommunity === null) return;
    this.idCommunity = this.$route.params.idCommunity;
  },
});
</script>

<style scoped lang="scss">
.content-wrapper {
  position: relative;
  padding-bottom: 40px;
}
.router-view-card {
  padding-top: 0;
  padding-bottom: 45px;
  position: relative;
  width: 100%;
  box-shadow: none;
  z-index: 1;
}
</style>
