// @ts-nocheck
export default {
  data() {
    return {
      sortings: {
        name: [
          undefined,
          {
            by: "user-full-name",
            icon: "▲",
            direction: "asc",
          },
          {
            by: "user-full-name",
            icon: "▼",
            direction: "desc",
          },
        ],
        role: [
          undefined,
          {
            by: "role-name",
            icon: "▲",
            direction: "asc",
          },
          {
            by: "role-name",
            icon: "▼",
            direction: "desc",
          },
        ],
        dateTime: [
          undefined,
          { by: "date-time", icon: "▲", direction: "asc" },
          {
            by: "date-time",
            icon: "▼",
            direction: "desc",
          },
        ],
        event: [
          undefined,
          { by: "event-name", icon: "▲", direction: "asc" },
          {
            by: "event-name",
            icon: "▼",
            direction: "desc",
          },
        ],
      },

      currentSortKey: "name",
      currentSortIndex: 0,
      listOrder: undefined,
    };
  },

  computed: {
    nameColumnSortingIcon() {
      if (this.currentSortKey === "name") {
        return this.sortings.name[this.currentSortIndex]?.icon || "▼▲";
      }

      return "▼▲";
    },
    roleColumnSortingIcon() {
      if (this.currentSortKey === "role") {
        return this.sortings.role[this.currentSortIndex]?.icon || "▼▲";
      }

      return "▼▲";
    },
    dateTimeColumnSortingIcon() {
      if (this.currentSortKey === "dateTime") {
        return this.sortings.role[this.currentSortIndex]?.icon || "▼▲";
      }

      return "▼▲";
    },
    eventColumnSortingIcon() {
      if (this.currentSortKey === "event") {
        return this.sortings.role[this.currentSortIndex]?.icon || "▼▲";
      }

      return "▼▲";
    },
  },

  methods: {
    changeListOrder(key, callback) {
      if (this.currentSortKey === key) {
        this.updateCurrentSortingIndex(callback);
        return;
      }

      this.currentSortKey = key;
      this.currentSortIndex = 0;
      this.updateCurrentSortingIndex(callback);
    },
    updateCurrentSortingIndex(callback) {
      if (
        this.currentSortIndex ===
        this.sortings[this.currentSortKey].length - 1
      ) {
        this.currentSortIndex = 0;
        this.listOrder = this.sortings[this.currentSortKey][0];
        callback();
        return;
      }

      this.currentSortIndex++;
      this.listOrder =
        this.sortings[this.currentSortKey][this.currentSortIndex];
      return callback();
    },
  },
};
