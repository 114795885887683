<template lang="pug">
FullScreenOverlaySlot(
  :isOpen="isOpen",
  headerIcon="icc-logo",
  :title="overlayTitle"
  @closeOverlay="closeOverlay"
)
  template(#breadcrumbs)
    h1.breadcrumbs-title IL CARE CONTINUUM
  template(#content)
    .card.clinic-title
      .columns.is-variable
        .column.is-two-thirds
          .form-group(:class="{ 'form-group__invalid': v$.clinicHealthSystem.name.$errors.length }")
            label Clinic Health System Name
            input.input.text-lg(type="text", v-model="clinicHealthSystem.name" @blur="v$.clinicHealthSystem.name.$touch()")
            .form-group__errors(v-if="v$.clinicHealthSystem.name.$dirty && v$.clinicHealthSystem.name.$errors.length")
                .form-group__error(v-if="v$.clinicHealthSystem.name.required.$invalid") Clinic Health System Name is required!
        .column.is-one-third
          label(v-if="chsId") Upload Patients
          .txt(v-if="chsId")
            BaseBtn.btn.lg.blue(href="#" @click.prevent="openUploadPatientsOverlay(chsId)" :class="{'disabled': false}") UPLOAD
    .card.modules
      .title-md-thin-with-action
        h2.title.title--md-thin(style="margin-bottom: 27px;") MODULES

      .table.table--no-side-padding
        .th
          .td General Modules
          .td Active
        .tr.tr__row.no_bottom_border
          .td
            h2 FIT Registry
          .td 
            BaseToggle(
              v-model="clinicHealthSystem.fitRegistry"
            )
        .tr.tr_child(v-if="clinicHealthSystem.fitRegistry === true")
          .columns.is-variable
            FollowUpField(
              key="followUp1"
              v-model="clinicHealthSystem.firstFollowUp",
              label="First Follow-up",
              :weekNumbersArray="[1,2,3,4]"
            )
            FollowUpField(
              key="followUp2"
              v-model="clinicHealthSystem.secondFollowUp",
              label="Second Follow-up",
              :weekNumbersArray="[2,3,4,6]",
              :firstWeekNumber="clinicHealthSystem.firstFollowUp"
            )
            .column.is-one-third
              label &nbsp;
              .txt(v-if="chsId")
                BaseBtn.btn.lg.blue(href="#" @click.prevent="openUploadFitKitsOverlay(chsId)" :class="{'disabled': false}") BULK UPLOAD
        .tr.tr__row
          .td
            h2 Primary Care
          .td 
            BaseToggle(v-model="clinicHealthSystem.primaryCare")
        .th.specialty_modules
          .td Specialty Modules
          .td Active
        .tr.tr__row(v-if="activespecialtyModules" v-for="item in activespecialtyModules", :key="item.id")
          .td
            h2 {{ item.moduleTitle }}
          .td 
            BaseToggle(
              :key="item.id"
              :modelValue="item.toggleOn"
              @change="changeActiveSpecialties(item.id, $event.target.checked)"
            )

    .card.help_resources
      h2.title.title--md-thin HELP &amp; RESOURCES
      .columns.is-variable
        .column.is-one-third
          .form-group
            label(for="user-support-text") User Support Text
            textarea#user-support-text(v-model="clinicHealthSystem.userSupportText", style="height:250px;")
        .column.is-one-third
          .form-group
            label(for="resources-text") Resources Text
            textarea#resources-text(v-model="clinicHealthSystem.resourcesText", style="height:250px;")
      .columns.is-variable
        .column.is-full
          .form-group(v-for="file in loadedFiles" :key="file.id")
            a(href="#" @click.prevent="preview(file.src)") {{file.title}} ( {{file.file.name}} ) 
            base-icon(@click.prevent="markToBeRemoved(file)" name="x")
      .columns.is-variable
        .column.is-full
          .form-group(v-for="confirmedFile in filesConfirmedToUpload" :key="confirmedFile.id")
            a(href="#" @click.prevent="preview(confirmedFile.url)") {{confirmedFile.title}} ( {{confirmedFile.name}} ) 
            base-icon(@click.prevent="removeConfirmed(confirmedFile.key)" name="x")
      .columns.is-variable
        .column.is-two-thirds
          .form-group
            label.label--margin-sm Additional Resource Title
            input.input.text-lg(type="text" v-model="extraResourceTitle")
        .info-pair.column.is-one-third
          .label--margin-sm Upload File
          .txt
            UploadAndPreviewFile(
              :buttonStyleClasses="['btn', 'lg', !extraResourceTitle ? 'grey disabled' : 'blue']"
              :storageKey="additionalResourceStorageKey"
              :key="forcedRerenderTimes"
              @fileLoaded="forceRerender"
              :disabled="!extraResourceTitle"
            )
      BaseBtn.btn.lg.blue(
        :disabled="!extraResourceTitle"
        v-if="anAdditionalResourceFileIsSelected"
        @click.prevent="confirmFileToUpload"
      ) Confirm Upload

  template(#footer)
    BaseBtn.btn.lg.red-outline(href="#", @click.prevent="closeOverlay") CANCEL CHANGES
    BaseBtn.btn.lg.green(
      @click.prevent="saveCHS"
      :disabled="v$.$invalid || isSubmitting"
      :class="{spinner: isSubmitting }"
    ) SAVE AND EXIT

BulkPatientUploadOverlay(
  :key="`${componentKey}-1`"
  :chsId="chsId"
  :chsName="chsName"
  :isOpen="isUploadPatientsOverlayOpen",
  @closeUploadPatientsOverlay="closeUploadPatientsOverlay"
)
BulkFitKitUploadOverlay(
  :key="`${componentFitKey}-1`"
  :chsId="chsId"
  :chsName="chsName"
  :isOpen="isUploadFitKitsOverlayOpen",
  @closeUploadFitKitsOverlay="closeUploadFitKitsOverlay"
)
</template>

<script>
import { defineComponent } from "vue";
import FullScreenOverlaySlot from "@/components/overlays/FullScreenOverlaySlot.vue";
import FollowUpField from "@/components/FollowUpField.vue";
import UploadAndPreviewFile from "@/components/UploadAndPreviewFile";
import BulkPatientUploadOverlay from "@/components/organizations/overlays/BulkPatientUploadOverlay.vue";
import BulkFitKitUploadOverlay from "@/components/organizations/overlays/BulkFitKitUploadOverlay.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

import {
  createCHS,
  fetchCHS,
  getSpecialtyModulesOfCHS,
  updateCHS,
  fetchCHSFiles,
  addCHSFiles,
} from "@/api/chsApi";
import { fetchFileById, uploadFile } from "@/api/filesApi";

import { fetchSpecialtyModules } from "@/api/specialtyModulesApi";
import { apiStatus, apiStatusComputedFactory } from "@/api";
const { IDLE, PENDING, SUCCESS, ERROR } = apiStatus;
import sanitizeHtml from "sanitize-html";
import remove from "lodash/remove";
import { preview } from "@/helpers/preview";
import { getFileSignedUrl } from "@/helpers";

export default defineComponent({
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    let args = {
      clinicHealthSystem: {
        name: {
          required,
        },
      },
    };

    return args;
  },
  components: {
    FullScreenOverlaySlot,
    FollowUpField,
    UploadAndPreviewFile,
    BulkPatientUploadOverlay,
    BulkFitKitUploadOverlay,
  },
  emits: ["closeClinicHealthSystemsOverlay", "reloadCHSs"],

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    chsId: {
      required: false,
    },
  },

  data() {
    return {
      componentKey: 0,
      componentFitKey: 0,
      saveDisabled: true,
      isSubmitting: false,
      getSpecialtyModulesOfCHSStatus: IDLE,
      clinicHealthSystem: {
        name: null,
        fitRegistry: false,
        primaryCare: false,
        colonoscopy: true,
        userSupportText: null,
        resourcesText: null,
        organizationType: "Clinic",
        firstFollowUp: null,
        secondFollowUp: null,
      },
      activeSpecialties: [], //For Create
      originalUpdateSpecialtieModules: null, //For Update
      specialtiesStatusChanged: [], //For Update
      specialtiesStatusAdd: [],
      isUploadPatientsOverlayOpen: false,
      isUploadFitKitsOverlayOpen: false,
      overlayTitle: this.chsId
        ? "MANAGE CLINIC HEALTH SYSTEM"
        : "ADD NEW CLINIC HEALTH SYSTEM",
      specialtyModules: null,
      additionalResourceStorageKey: "chs_resources_additional", //key for current selected but not confirmed file item to upload
      confirmedFilesToUploadStorageKey: "additional_resources_keys", //key for storage item that holds the list of confirmed file keys, as an string array.
      forcedRerenderTimes: 0,
      additionalResourceFiles: [],
      filesToBeRemoved: [],
      extraResourceTitle: "",
      createdChsId: null,
      timer: null,
    };
  },

  computed: {
    // For Create
    ...apiStatusComputedFactory(["getSpecialtyModulesOfCHSStatus"]),

    activespecialtyModules: function () {
      let activeSpecialties;
      if (!this.activeSpecialties) {
        activeSpecialties = this.specialtyModules;
      } else {
        activeSpecialties = this.activeSpecialties;
      }
      if (this.specialtyModules) {
        return this.specialtyModules.filter(function (u) {
          if (activeSpecialties) {
            u.toggleOn = false;
            activeSpecialties.forEach((element) => {
              if (u.id == element.specialtyModule.id) {
                u.toggleOn = true;
              }
            });
          }
          return u.isModuleEnabled;
        });
      } else {
        return false;
      }
    },
    anAdditionalResourceFileIsSelected() {
      //add force re-render times as dependency for this computed;
      this.forcedRerenderTimes;
      return !!sessionStorage.getItem(this.additionalResourceStorageKey);
    },
    filesConfirmedToUpload() {
      this.forcedRerenderTimes;

      const keys =
        JSON.parse(
          sessionStorage.getItem(this.confirmedFilesToUploadStorageKey)
        ) || [];
      return keys.map((key) => ({
        key,
        ...JSON.parse(sessionStorage.getItem(key)),
      }));
    },
    loadedFiles() {
      return this.additionalResourceFiles.filter(
        (file) =>
          !this.filesToBeRemoved.some(
            (fileMarkedToBeRemoved) => fileMarkedToBeRemoved.id === file.id
          )
      );
    },
    chsName() {
      return this.clinicHealthSystem?.name ?? "";
    },
  },

  methods: {
    openUploadPatientsOverlay() {
      this.componentKey = this.componentKey + 1;
      this.isUploadPatientsOverlayOpen = true;
    },
    closeUploadPatientsOverlay() {
      this.isUploadPatientsOverlayOpen = false;
    },
    openUploadFitKitsOverlay() {
      this.componentFitKey = this.componentFitKey + 1;
      this.isUploadFitKitsOverlayOpen = true;
    },
    closeUploadFitKitsOverlay() {
      this.isUploadFitKitsOverlayOpen = false;
    },
    closeOverlay() {
      this.$emit("closeClinicHealthSystemsOverlay");
    },
    async saveCHS() {
      if (this.clinicHealthSystem?.userSupportText) {
        this.clinicHealthSystem.userSupportText = this.getSecureHtml(
          this.clinicHealthSystem.userSupportText
        );
      }
      if (this.clinicHealthSystem?.resourcesText) {
        this.clinicHealthSystem.resourcesText = this.getSecureHtml(
          this.clinicHealthSystem.resourcesText
        );
      }
      if (this.chsId === null) {
        await this.createCHS();
      } else {
        await this.updateCHS();
      }
      await this.uploadExtraResources();
      this.$emit("reloadCHSs");
      this.closeOverlay();
    },
    getSecureHtml(unsafe) {
      return sanitizeHtml(unsafe);
    },
    async createCHS() {
      this.isSubmitting = true;

      if (!this?.clinicHealthSystem?.fitRegistry) {
        delete this?.clinicHealthSystem?.firstFollowUp;
        delete this?.clinicHealthSystem?.secondFollowUp;
      }
      try {
        const {
          data: { clinicHealthSystem },
        } = await createCHS({
          clinicHealthSystem: this.clinicHealthSystem,
          activeSpecialties: this.specialtiesStatusAdd,
        });

        this.createdChsId = clinicHealthSystem.id;
      } catch (err) {
        console.log(err);
      } finally {
        this.isSubmitting = false;
      }
    },
    async updateCHS() {
      this.isSubmitting = true;

      delete this.clinicHealthSystem.createdAt;
      delete this.clinicHealthSystem.fitRegistryConfiguration;
      try {
        // forced to be true in order for the update to work
        this.clinicHealthSystem.colonoscopy = true;
        await updateCHS(`${this.chsId}`, {
          clinicHealthSystem: this.clinicHealthSystem,
          specialtiesStatusChanged: this.specialtiesStatusChanged,
        });
      } catch (err) {
        console.log(err);
      } finally {
        this.isSubmitting = false;
      }
    },
    async fetchCHS() {
      try {
        const res = await fetchCHS(this.chsId);
        this.clinicHealthSystem = res.data;

        this.getSpecialtyModulesOfCHS();
        if (
          this?.clinicHealthSystem?.fitRegistry === true &&
          this?.clinicHealthSystem?.fitRegistryConfiguration?.firstFollowUp
        ) {
          this.clinicHealthSystem.firstFollowUp =
            this.clinicHealthSystem.fitRegistryConfiguration.firstFollowUp;
        }
        if (
          this?.clinicHealthSystem?.fitRegistry === true &&
          this?.clinicHealthSystem?.fitRegistryConfiguration?.secondFollowUp
        ) {
          this.clinicHealthSystem.secondFollowUp =
            this.clinicHealthSystem.fitRegistryConfiguration.secondFollowUp;
        }

        const {
          data: { files },
        } = await fetchCHSFiles(this.chsId);

        this.additionalResourceFiles = await Promise.all(
          files.map(async (resource) => {
            const {
              data: { file: fileFromServer, token },
            } = await fetchFileById(resource.file.id);

            resource.src = getFileSignedUrl({
              ...fileFromServer,
              token,
            });

            return resource;
          })
        );
      } catch (err) {
        console.error(err);
      }
    },
    async uploadExtraResources() {
      const keys = JSON.parse(
        sessionStorage.getItem(this.confirmedFilesToUploadStorageKey) || "[]"
      );

      const files = await Promise.all(
        keys.map(async (key) => {
          try {
            const { file } = await uploadFile(key);
            const fileInStorage = JSON.parse(sessionStorage.getItem(key));
            sessionStorage.removeItem(key);
            return {
              ...file,
              title: fileInStorage.title,
            };
          } catch (error) {
            console.log(error);
          }
        })
      );
      sessionStorage.removeItem(this.confirmedFilesToUploadStorageKey);

      await addCHSFiles(this.chsId || this.createdChsId, {
        filesAdded: [...files],
        filesRemoved: [...this.filesToBeRemoved],
      });
    },

    changeActiveSpecialties(id, checked) {
      if (this.chsId === null) {
        if (checked === true) {
          if (this.specialtiesStatusAdd.includes(id)) {
            return;
          } else {
            this.specialtiesStatusAdd.push(id);
          }
        } else {
          this.specialtiesStatusAdd = remove(
            this.specialtiesStatusAdd,
            function (n) {
              return n !== id;
            }
          );
        }
      } else {
        let found = false;
        this.specialtiesStatusChanged.forEach((element) => {
          if (element.idSpecialtyModule == id) {
            found = true;
            element.active = checked;
          }
        });
        if (!found) {
          this.specialtiesStatusChanged.push({
            active: checked,
            idSpecialtyModule: id,
          });
        }
      }
    },
    async getSpecialtyModulesOfCHS() {
      this.getSpecialtyModulesOfCHSStatus = PENDING;
      try {
        const res = await getSpecialtyModulesOfCHS(this.chsId);
        this.activeSpecialties = res.data.chsSpecialties;
        this.getSpecialtyModulesOfCHSStatus = SUCCESS;
      } catch (err) {
        console.log(err);
        this.getSpecialtyModulesOfCHSStatus = ERROR;
      }
    },
    async fetchSpecialtyModules() {
      try {
        const res = await fetchSpecialtyModules({
          params: {
            size: 100,
          },
        });
        this.specialtyModules = res.data.specialtyModules;
        this.pagination = res.data.pagination;
      } catch (err) {
        console.error(err);
      }
    },
    forceRerender() {
      this.forcedRerenderTimes++;
    },
    confirmFileToUpload() {
      const confirmedFilesToUploadKeys = JSON.parse(
        sessionStorage.getItem(this.confirmedFilesToUploadStorageKey) || "[]"
      );
      const confirmedFileKey =
        this.additionalResourceStorageKey + "_" + new Date().getTime();
      const currentFileToConfirm = {
        ...JSON.parse(
          sessionStorage.getItem(this.additionalResourceStorageKey)
        ),
        title: this.extraResourceTitle,
      };
      //pass current to an indexable key
      sessionStorage.setItem(
        confirmedFileKey,
        JSON.stringify(currentFileToConfirm)
      );
      //update list of confirmed files
      sessionStorage.setItem(
        this.confirmedFilesToUploadStorageKey,
        JSON.stringify([...confirmedFilesToUploadKeys, confirmedFileKey])
      );
      //remove current item because is already in a new key
      sessionStorage.removeItem(this.additionalResourceStorageKey);
      this.extraResourceTitle = "";
      //re render view
      this.forceRerender();
    },
    removeConfirmed(key) {
      sessionStorage.removeItem(key);
      const keyList = JSON.parse(
        sessionStorage.getItem(this.confirmedFilesToUploadStorageKey)
      ).filter((keyFromList) => keyFromList !== key);

      sessionStorage.setItem(
        this.confirmedFilesToUploadStorageKey,
        JSON.stringify(keyList)
      );
      this.forceRerender();
    },
    markToBeRemoved(file) {
      this.filesToBeRemoved = [...this.filesToBeRemoved, file];
    },
    preview(src) {
      preview(src);
    },
    async refetchAdditionalResources() {
      try {
        const {
          data: { files },
        } = await fetchCHSFiles(this.chsId);

        this.additionalResourceFiles = await Promise.all(
          files.map(async (resource) => {
            const {
              data: { file: fileFromServer, token },
            } = await fetchFileById(resource.file.id);

            resource.src = getFileSignedUrl({
              ...fileFromServer,
              token,
            });

            return resource;
          })
        );
      } catch (err) {
        console.error(err);
      }
    },
    cancelAutoRefresh() {
      clearInterval(this.timer);
    },
  },
  unmounted() {
    this.cancelAutoRefresh();
  },
  created() {
    this.fetchSpecialtyModules();
    if (this.chsId === null) return;
    this.fetchCHS();
    this.timer = setInterval(this.refetchAdditionalResources, 25000);
  },
});
</script>

<style scoped lang="scss">
.help_resources h2.title--md-thin {
  margin-bottom: 38px;
}
.table .tr.tr_child {
  padding-top: 0px;
  padding-bottom: 33px;
}
.tr.tr__row {
  padding-top: 33px;
  padding-bottom: 33px;
}
.specialty_modules {
  margin-top: 102px;
}
.no_bottom_border {
  border-bottom: 0px;
}
.card {
  padding: 35px $secondaryCardsSidePadding 20px $secondaryCardsSidePadding;
  &.clinic-title {
    padding-top: 55px;
  }
  &.help_resources,
  &.modules {
    padding-top: 45px;
  }
}
.columns.is-variable {
  --columnGap: 1.575%;
}
.columns + .hr {
  background-color: $primaryBgDarkGrey;
  margin-top: -21px;
  margin-bottom: 24px;
}
.card > .columns:last-child.info-pair,
.card > .columns:last-child .info-pair {
  margin-bottom: 0;
}
.svg-icon--eye-red,
.svg-icon--eye-green,
.svg-icon--yes,
.svg-icon--no {
  width: 50px;
  height: 50px;
  top: 0;
}

// Page specific styles----------------------------------------
.breadcrumbs-title {
  margin: 0;
  color: $primaryLightBlue;
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.12px;
}
.procedure-dropdown-instructions {
  margin-top: -20px;
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 1.29;
}
.table {
  .td {
    &:nth-child(1) {
      width: math.percentage(math.div(1108, $tabContentCardWidth));
    }
    &:nth-child(2) {
      width: math.percentage(math.div(72, $tabContentCardWidth));
    }
  }
  .row-main-options {
    padding: 35px 0;
    .base-checkbox:not(:last-child) {
      margin-right: 84px;
    }
  }
  .th {
    padding-bottom: 25px;
  }
}
</style>
