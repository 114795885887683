<template lang="pug">
header.header-main
  .header-left
    a.logo(href="#", title="Home", style="pointer-events: none")
      h1(@click.prevent="navigateAccordingToRole") IL CARE CONTINUUM
    h2.organization(v-if="isSubSystemClinic && !aClinicIsSelected") {{chsName}}
    h2.organization(v-if="isSubSystemClinic && aClinicIsSelected") {{getClinicName}}
    h2.organization(v-if="isSubSystemHospital && aHospitalIsSelected") {{hospitalName}}
    h2.organization(v-if="isSubSystemCommunityOrganization && aCCOIsSelected") {{getCCOName}}
    h2.organization(v-if="isSubSystemProvider && aProviderGroupIsSelected") {{ getProviderGroupName }} 
  .header-right
    .role-warning(href="#" v-if="showLeaveImpersonateHeader")
      p You are using the system as a {{getUserRoleName}}. <span class="exit" @click.prevent="leaveImpersonate">exit</span>
    .role-warning(href="#" v-if="!showLeaveImpersonateHeader && isSubSystemClinic && aClinicIsSelected")
      p <span class="exit" @click.prevent="navigateAccordingToRole">Switch Clinic</span>
    a.current-user
      .name {{getUserFullName}}
      base-icon(name="avatar")
</template>

<script>
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";

export default defineComponent({
  components: {},

  props: {},

  data() {
    return {};
  },

  computed: {
    ...mapGetters([
      "getUserFullName",
      "getUserRoleName",
      "getUserRoleContext",
      "getClinicName",
      "showLeaveImpersonateHeader",
      "isSubSystemClinic",
      "aClinicIsSelected",
      "isSubSystemHospital",
      "aHospitalIsSelected",
      "aCCOIsSelected",
      "getCCOName",
      "getCCOId",
      "isSubSystemCommunityOrganization",
      "isSubSystemProvider",
      "isSubSystemProvider",
      "getProviderGroupName",
      "aProviderGroupIsSelected",
    ]),
    chsName() {
      return this.getUserRoleContext?.clinicHealthSystem?.name;
    },
    hospitalName() {
      return this.getUserRoleContext?.hospital?.name;
    },
  },

  methods: {
    ...mapActions(["leaveImpersonate", "navigateAccordingToRole"]),
  },
});
</script>

<style scoped lang="scss">
.header-main {
  position: fixed;
  backface-visibility: hidden;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 27px 0 47px;
  height: $headerHeight;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  background-color: $primaryLightBlue;
  filter: drop-shadow(0 3px 30px rgba(0, 0, 0, 0.16));
  z-index: 100;
  &::after {
    content: "";
    display: block;
    height: 60px;
    width: 60px;
    position: absolute;
    background-image: url("../assets/images/header-corner.svg");
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: left top;
    background-size: contain;
    top: 100%;
    left: 0;
  }
  .logo {
    text-decoration: none;
    & > h1 {
      margin: 0 29px 0 0;
      font-size: 24px;
      font-weight: 600;
      line-height: 1;
      letter-spacing: 0.12px;
      color: #fff;
    }
  }
}
.header-left {
  display: flex;
  justify-content: left;
  align-items: center;
  & > .organization {
    margin: 0 27px 0 0;
    font-size: 24px;
    font-weight: 300;
    line-height: 1;
    letter-spacing: 0.12px;
    color: #ffffff;
  }
}
.header-right {
  display: flex;
  align-items: center;
  justify-content: right;
}
.role-warning {
  margin-right: 19px;
  padding: 8px 15px;
  border: solid 1px #ffffff;
  border-radius: 20px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.29;
  letter-spacing: 0.08px;
  text-decoration: none;
  background-color: rgba($primaryLightBlue, 0);
  color: #fff;
  transition: background-color 0.3s ease-in;
  &:hover {
    background-color: rgba($primaryLightBlue, 1);
  }
  p {
    margin: 0;
  }
  .exit {
    cursor: pointer;
    text-transform: uppercase;
    color: $secondaryYellow;
    text-decoration: underline;
  }
}
.current-user {
  display: flex;
  align-items: center;
  .name {
    width: 185px;
    text-align: right;
    // margin-right: ;
    margin: 0 10px 0 27px;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.22;
    letter-spacing: 0.1px;
    color: #ffffff;
  }
  .svg-icon {
    top: 0;
    flex-shrink: 0;
    flex-grow: 0;
    width: 44px;
    height: 44px;
  }
}
</style>
