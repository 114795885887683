// @ts-nocheck
export default {
  data() {
    return {
      sortings: {
        patientName: [
          undefined,
          { by: "patient-full-name", icon: "▲", direction: "asc" },
          { by: "patient-full-name", icon: "▼", direction: "desc" },
        ],
        fluVaccine: [
          undefined,
          { by: "flu-vaccine", icon: "▲", direction: "asc" },
          { by: "flu-vaccine", icon: "▼", direction: "desc" },
        ],
        kitNumber: [
          undefined,
          { by: "kit-number", icon: "▲", direction: "asc" },
          { by: "kit-number", icon: "▼", direction: "desc" },
        ],
        distributedAt: [
          undefined,
          { by: "distributed-at", icon: "▲", direction: "asc" },
          { by: "distributed-at", icon: "▼", direction: "desc" },
        ],
        providerName: [
          undefined,
          { by: "provider-user-full-name", icon: "▲", direction: "asc" },
          { by: "provider-user-full-name", icon: "▼", direction: "desc" },
        ],
        processedAt: [
          undefined,
          { by: "processed-at", icon: "▲", direction: "asc" },
          { by: "processed-at", icon: "▼", direction: "desc" },
        ],
        clinicReferredTo: [
          undefined,
          { by: "clinic-referred-to", icon: "▲", direction: "asc" },
          { by: "clinic-referred-to", icon: "▼", direction: "desc" },
        ],
      },
      currentSortKey: "patientName",
      currentSortIndex: 0,
      listOrder: undefined,
    };
  },

  computed: {
    patientNameColumnSortingIcon() {
      if (this.currentSortKey === "patientName") {
        return this.sortings.patientName[this.currentSortIndex]?.icon || "▼▲";
      }

      return "▼▲";
    },
    fluVaccineColumnSortingIcon() {
      if (this.currentSortKey === "fluVaccine") {
        return this.sortings.fluVaccine[this.currentSortIndex]?.icon || "▼▲";
      }

      return "▼▲";
    },
    kitNumberColumnSortingIcon() {
      if (this.currentSortKey === "kitNumber") {
        return this.sortings.kitNumber[this.currentSortIndex]?.icon || "▼▲";
      }

      return "▼▲";
    },
    distributedAtColumnSortingIcon() {
      if (this.currentSortKey === "distributedAt") {
        return this.sortings.distributedAt[this.currentSortIndex]?.icon || "▼▲";
      }

      return "▼▲";
    },
    providerNameColumnSortingIcon() {
      if (this.currentSortKey === "providerName") {
        return this.sortings.providerName[this.currentSortIndex]?.icon || "▼▲";
      }

      return "▼▲";
    },
    processedAtColumnSortingIcon() {
      if (this.currentSortKey === "processedAt") {
        return this.sortings.processedAt[this.currentSortIndex]?.icon || "▼▲";
      }

      return "▼▲";
    },
    clinicReferredToColumnSortingIcon() {
      if (this.currentSortKey === "clinicReferredTo") {
        return (
          this.sortings.clinicReferredTo[this.currentSortIndex]?.icon || "▼▲"
        );
      }

      return "▼▲";
    },
  },

  methods: {
    changeListOrder(key, callback) {
      if (this.currentSortKey === key) {
        this.updateCurrentSortingIndex(callback);
        return;
      }

      this.currentSortKey = key;
      this.currentSortIndex = 0;
      this.updateCurrentSortingIndex(callback);
    },
    updateCurrentSortingIndex(callback) {
      if (
        this.currentSortIndex ===
        this.sortings[this.currentSortKey].length - 1
      ) {
        this.currentSortIndex = 0;
        this.listOrder = this.sortings[this.currentSortKey][0];
        callback();
        return;
      }

      this.currentSortIndex++;
      this.listOrder =
        this.sortings[this.currentSortKey][this.currentSortIndex];
      return callback();
    },
  },
};
