<template lang="pug">
SideOverlaySlot(
  :isOpen="isOpen",
  :title="title"
  @closeOverlay="closeOverlay"
)
  template(#contentTop)
    PatientSection(:fitKit="selectedFitKit")
    CompleteFitOverlayConfirm(
      v-if="step === 'confirm'",
      :completedFit="completedFit"
    )
    CompleteFitOverlayCompleted(
      v-if="step === 'completed'",
      :idClinic="idClinic",
      :completedFit="completedFit",
    )
    form(
      v-if="step === 'complete'"
      style="display:flex; flex-direction:column; flex:1;"
      @submit.prevent="goToConfirmStep()"
      novalidate
    )
      .columns
        .column.is-half
          .form-group(style="margin-bottom:0;" :class="{ 'form-group__invalid': v$.fitResult.$errors.length }")
            label(for="results") FIT Test Results
            select#results(v-model="fitResult" @blur="v$.fitResult.$touch()")
              option(value="") Please Select
              option(
                  v-for="result in COMPLETED_FIT_OPTIONS",
                  :key="result.value"
                  :value="result.value"
                  :selected="result.value === fitResult"
                ) {{result.name}}
            .form-group__errors(v-if="v$.fitResult.$dirty && v$.fitResult.$errors.length")
              .form-group__error(v-if="v$.fitResult.required.$invalid") FIT test results is required!
        .column.is-half
          .form-group(style="margin-bottom:0;" :class="{ 'form-group__invalid': v$.processingDate.$errors.length }")
            label(for="date-of-processing") Date of Processing
            DatePicker(
              v-model="date" 
              :masks="masks"
              :max-date="new Date()"
            )
              template(v-slot="{ inputValue, inputEvents }")
                input(
                  :value="inputValue"  
                  v-on="inputEvents"
                  placeholder="MM/DD/YYYY"
                  @blur="v$.processingDate.$touch()"
                )
            .form-group__errors(v-if="v$.processingDate.$dirty && v$.processingDate.$errors.length")
              .form-group__error(v-if="v$.processingDate.required.$invalid") Date of processing is required!
              .form-group__error(v-if="v$.processingDate.minLength.$invalid") Date of processing is required!
              .form-group__error(v-if="v$.processingDate.$dirty && !v$.processingDate.required.$invalid && !v$.processingDate.minLength.$invalid") Date of processing cannot be set in the future!
      .form-group( :class="{ 'form-group__invalid': v$.details.$errors.length }" v-if="isNoResultsSet()")
        label(for="details") Details
        select#details(v-model="details" @blur="v$.details.$touch()")
          option(value="") Please Select
          option(
              v-for="result in FIT_DETAIL_OPTIONS",
              :key="result.value"
              :value="result.value"
              :selected="result.value === details"
            ) {{result.name}}
        .form-group__errors(v-if="v$.details.$dirty && v$.details.$errors.length")
          .form-group__error(v-if="v$.details.required.$invalid") Details are required!
      .form-group(style="margin-bottom:0; display:flex; flex-direction:column; flex:1;" :class="{ 'form-group__invalid': v$.newNotes.$errors.length }")
        label(for="notes") Notes
        textarea#notes(style="flex:1;", v-model="newNotes" @blur="v$.newNotes.$touch()")
        .form-group__errors(v-if="v$.newNotes.$dirty && v$.newNotes.$errors.length")
          .form-group__error(v-if="v$.newNotes.required.$invalid") Notes are required!
  template(#footer, v-if="step !== 'completed'")
    .flex.flex--space-between(v-if="step === 'complete'")
      BaseBtn.x-lg.red-outline(
        href="#",
        @click.prevent="closeOverlay"
      ) CANCEL
      BaseBtn.x-lg.green(
        :class="{spinner: isSubmitting }"
        :disabled="v$.$invalid || isSubmitting"
        @click.prevent="goToConfirmStep();"
      ) CONTINUE
    .flex.flex--space-between(v-if="step === 'confirm'")
      BaseBtn.x-lg.blue-outline(
        href="#",
        @click.prevent="step = 'complete';"
      ) EDIT
      BaseBtn.x-lg.green(
        href="#",
        @click.prevent="completeFit"
      ) COMPLETE FIT
</template>

<script>
import { defineComponent } from "vue";
import SideOverlaySlot from "@/components/overlays/SideOverlaySlot.vue";
import PatientSection from "@/components/fit-kit/overlays/PatientSection.vue";
import CompleteFitOverlayConfirm from "@/components/fit-kit/overlays/CompleteFitOverlayConfirm.vue";
import CompleteFitOverlayCompleted from "@/components/fit-kit/overlays/CompleteFitOverlayCompleted.vue";
import { updateFitKit } from "@/api/fitKitApi";
import {
  getInputFormatDate,
  FIT_DETAIL_OPTIONS,
  COMPLETED_FIT_OPTIONS,
} from "@/helpers";

import { DatePicker } from "v-calendar";
import moment from "moment";

import useVuelidate from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import { createToast } from "mosha-vue-toastify";
import { maska } from "maska";

export default defineComponent({
  directives: { maska },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    let args;
    if (this.isNoResultsSet()) {
      args = {
        fitResult: { required },
        processingDate: {
          required,
          minLength: minLength(10),
          function() {
            const unixDistributed = new Date(this.processingDate).getTime();
            if (!unixDistributed) {
              return false;
            }
            if (unixDistributed > Date.now()) {
              return false;
            }
            return true;
          },
        },
        details: { required },
        newNotes: { required },
      };
    } else {
      args = {
        fitResult: { required },
        processingDate: {
          required,
          minLength: minLength(10),
          function() {
            const unixDistributed = new Date(this.processingDate).getTime();
            if (!unixDistributed) {
              return false;
            }
            if (unixDistributed > Date.now()) {
              return false;
            }
            return true;
          },
        },
        newNotes: { required },
      };
    }
    return args;
  },
  components: {
    SideOverlaySlot,
    PatientSection,
    CompleteFitOverlayConfirm,
    CompleteFitOverlayCompleted,
    DatePicker,
  },
  emits: ["closeCompleteFitOverlay"],

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    selectedFitKit: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      date: null,
      masks: {
        input: "MM/DD/YYYY",
      },
      fitResult: false,
      step: "complete", //complete, confirm, completed
      processingDate: null,
      processingDateRaw: null,
      details: false,
      newNotes: null,
      completedFit: null,
      todayDate: getInputFormatDate(new Date()),
      idClinic: null,
      idFitKit: null,
      updatedAt: null,
      referral: null,
      colonoscopyInsideICC: null,
      colonoscopyOutsideICC: null,
      FIT_DETAIL_OPTIONS,
      COMPLETED_FIT_OPTIONS,
      isSubmitting: false,
    };
  },

  watch: {
    date: {
      handler: "setDate",
    },
    selectedFitKit: function (newVal) {
      if (newVal && newVal.id) {
        this.updatedAt = newVal.updatedAt;
        this.idFitKit = newVal.id;
        this.idClinic = newVal.idClinic;
        this.referral = newVal.referral;
        this.colonoscopyInsideICC = newVal.colonoscopyInsideICC;
        this.colonoscopyOutsideICC = newVal.colonoscopyOutsideICC;
        if (this.isEdit && newVal.initialCompletion) {
          this.processingDate = newVal.initialCompletion.processedAt
            ? new Date(newVal.initialCompletion.processedAt * 1000)
                .toISOString()
                .substring(0, 10)
            : null;
          this.details = newVal.initialCompletion.details;
          this.fitResult = newVal.initialCompletion.result;
          this.newNotes = newVal.initialCompletion.completionNotes;
        }
      }
    },
  },

  computed: {
    title() {
      switch (this.step) {
        case "confirm":
          return "COMPLETE FIT / CONFIRM";
        case "completed":
          return "FIT KIT COMPLETED";
        default:
          if (this.isEdit) return "EDIT COMPLETED FIT";
          return "COMPLETE FIT";
      }
    },
  },
  methods: {
    setDate(newVal) {
      if (newVal === null) {
        this.processingDate = "";
      } else {
        let selectionDisplay = moment(this.date).format("MM/DD/YYYY");
        this.processingDate = selectionDisplay;
      }
    },
    processingDateRawValue: function (event) {
      this.processingDateRaw = event.target.dataset.maskRawValue;
    },
    clearForm() {
      this.fitResult = null;
      this.processingDate = null;
      this.details = null;
      this.newNotes = null;

      this.$nextTick(() => {
        this.v$.$reset();
      });
    },
    closeOverlay() {
      this.clearForm();
      this.$emit("closeCompleteFitOverlay", {
        afterAction: this.step === "completed",
      });
      this.step = "complete";
    },
    isNoResultsSet() {
      return this.fitResult && this.fitResult === "no_result";
    },
    isValidEntry() {
      const isValidResult = this.fitResult && this.fitResult !== "";
      const isValidDetails =
        this.fitResult === "no_result"
          ? this.details && this.details !== ""
          : true;
      return this.processingDate && isValidResult && isValidDetails;
    },
    goToConfirmStep() {
      const unixProcessing = new Date(this.processingDate).getTime();
      this.completedFit = {
        id: this.idFitKit,
        result: this.fitResult,
        processedAt: unixProcessing / 1000,
        details: this.details,
        notes: this.newNotes,
        referral: this.referral,
        kitNumber: this.selectedFitKit.kitNumber,
        idClinic: this.selectedFitKit.idClinic,
        patient: this.selectedFitKit.patient,
        colonoscopyInsideICC: this.colonoscopyInsideICC,
        colonoscopyOutsideICC: this.colonoscopyOutsideICC,
      };
      this.step = "confirm";
    },
    async completeFit() {
      this.isSubmitting = true;

      const data = {
        isCompletionEdit: this.isEdit,
        updatedAt: this.updatedAt,
        result: this.completedFit.result,
        processedAt: this.completedFit.processedAt,
        completionNotes: this.completedFit.notes,
      };
      if (this.completedFit.result === "no_result") {
        data.details = this.completedFit.details;
      }
      try {
        const resFitKit = await updateFitKit(this.idFitKit, data);
        if (resFitKit.data && resFitKit.data.success) {
          this.step = "completed";
          this.clearForm();
        }
      } catch (err) {
        console.error(err);
        createToast(err?.response?.data?.message || "There was a problem", {
          timeout: 4000,
          type: "danger",
          position: "bottom-right",
        });
      } finally {
        this.isSubmitting = false;
      }
    },
  },
});
</script>

<style scoped lang="scss"></style>
