import moment from "moment";

export const COLONOSCOPY_OUTSIDE_RESULT_OPTIONS = [
  { value: "positive", name: "Positive" },
  { value: "negative", name: "Negative" },
];

export const FOLLOWUP_TYPE_OPTIONS = [
  { id: 1, value: "email", name: "Email" },
  { id: 2, value: "text", name: "Text Message" },
  { id: 3, value: "phone_call", name: "Phone Call" },
  { id: 4, value: "letter", name: "Letter" },
];

export const FOLLOWUP_TYPE_SIMPLE_OPTIONS = {
  email: "Email",
  text: "Text Message",
  phone_call: "Phone Call",
  letter: "Letter",
};

export const COMPLETED_FIT_OPTIONS = [
  { id: 1, value: "positive", name: "Positive" },
  { id: 2, value: "negative", name: "Negative" },
  { id: 3, value: "no_result", name: "No Results" },
];

export const COMPLETED_FIT_SIMPLE_OPTIONS = {
  positive: "Positive",
  negative: "Negative",
  no_result: "No Results",
};

export const FIT_DETAIL_OPTIONS = [
  { value: "not_returned", name: "Not Returned" },
  { value: "lost", name: "Lost" },
  { value: "opt_for_colonoscopy", name: "Opt for Colonoscopy" },
  { value: "throw_out", name: "Throw Out" },
];

export const PATIENT_SEARCH_OPTIONS = [
  { id: 1, value: "name", name: "Patient Name" },
  { id: 2, value: "mrn", name: "MRN" },
  { id: 3, value: "dob", name: "Date Of Birth" },
];

export const USER_ROLES = [
  {
    id: 1,
    subSystem: "admin",
    name: "CARES TEAM",
  },
  {
    id: 2,
    subSystem: "clinichealthsystem",
    name: "Clinic Health System Manager",
  },
  {
    id: 3,
    subSystem: "clinichealthsystem",
    name: "Clinic Staff in Training",
  },
  {
    id: 4,
    subSystem: "clinichealthsystem",
    name: "Clinic Staff",
  },
  {
    id: 5,
    subSystem: "clinichealthsystem",
    name: "Clinic Doctor",
  },
  {
    id: 6,
    subSystem: "hospital",
    name: "Hospital Manager",
  },
  {
    id: 7,
    subSystem: "hospital",
    name: "Hospital Staff",
  },
  {
    id: 8,
    subSystem: "hospital",
    name: "Hospital Doctor",
  },
  {
    id: 11,
    subSystem: "provider",
    name: "Provider Group Manager",
  },
  {
    id: 12,
    subSystem: "provider",
    name: "Provider",
  },
];

export const ACTION_TYPES = [
  {
    label: "Write",
    value: "write",
  },
  {
    label: "Read",
    value: "read",
  },
];

export const ACTION_NAMES = [
  {
    label: "Address successfully created",
    value: "Address successfully created",
  },
  { label: "Application started", value: "Application started" },
  {
    label: "Clinic Health System collection retrieved",
    value: "Clinic Health System collection retrieved",
  },
  {
    label: "Clinic Health System successfully created",
    value: "Clinic Health System successfully created",
  },
  {
    label: "Clinic Health System successfully retrieved",
    value: "Clinic Health System successfully retrieved",
  },
  {
    label: "Clinic Health System successfully updated",
    value: "Clinic Health System successfully updated",
  },
  {
    label: "Clinic collection retrieved",
    value: "Clinic collection retrieved",
  },
  {
    label: "Clinic health system files collection retrieved",
    value: "Clinic health system files collection retrieved",
  },
  {
    label: "Clinic health system hospitals collection retrieved",
    value: "Clinic health system hospitals collection retrieved",
  },
  {
    label: "Clinic successfully created",
    value: "Clinic successfully created",
  },
  {
    label: "Clinic successfully retrieved",
    value: "Clinic successfully retrieved",
  },
  {
    label: "Clinic successfully updated",
    value: "Clinic successfully updated",
  },
  {
    label: "Colonoscopy outside ICC created",
    value: "Colonoscopy outside ICC created",
  },
  {
    label: "Colonoscopy outside ICC updated",
    value: "Colonoscopy outside ICC updated",
  },
  {
    label: "Contact successfully created",
    value: "Contact successfully created",
  },
  {
    label: "Dashboard summary successfully retrieved",
    value: "Dashboard summary successfully retrieved",
  },
  {
    label: "FIT Kit follow up note created",
    value: "FIT Kit follow up note created",
  },
  {
    label: "FIT kit collection retrieved",
    value: "FIT kit collection retrieved",
  },
  { label: "FIT kit created", value: "FIT kit created" },
  {
    label: "FIT kit follow up note updated",
    value: "FIT kit follow up note updated",
  },
  {
    label: "FIT kit patient Appointment relation created",
    value: "FIT kit patient Appointment relation created",
  },
  { label: "FIT kit updated", value: "FIT kit updated" },
  {
    label: "FIT registry configuration fields successfully retrieved",
    value: "FIT registry configuration fields successfully retrieved",
  },
  { label: "File requested", value: "File requested" },
  { label: "File uploaded", value: "File uploaded" },
  {
    label: "Fit Kit editions collection retrieved",
    value: "Fit Kit editions collection retrieved",
  },
  {
    label: "Fit Kit follow up editions collection retrieved",
    value: "Fit Kit follow up editions collection retrieved",
  },
  {
    label: "Full PatientAppointment Report collection retrieved",
    value: "Full PatientAppointment Report collection retrieved",
  },
  {
    label: "Hospital collection retrieved",
    value: "Hospital collection retrieved",
  },
  {
    label: "Hospital linked to clinic health system",
    value: "Hospital linked to clinic health system",
  },
  {
    label: "Hospital successfully created",
    value: "Hospital successfully created",
  },
  {
    label: "Hospital successfully retrieved",
    value: "Hospital successfully retrieved",
  },
  {
    label: "Hospital successfully updated",
    value: "Hospital successfully updated",
  },
  {
    label: "Hospital unlinked to clinic health system",
    value: "Hospital unlinked to clinic health system",
  },
  {
    label: "Insurance collection retrieved",
    value: "Insurance collection retrieved",
  },
  {
    label: "Insurance successfully created",
    value: "Insurance successfully created",
  },
  {
    label: "Insurance successfully disabled",
    value: "Insurance successfully disabled",
  },
  {
    label: "Insurance successfully enabled",
    value: "Insurance successfully enabled",
  },
  {
    label: "Insurance successfully retrieved",
    value: "Insurance successfully retrieved",
  },
  {
    label: "Insurance successfully updated",
    value: "Insurance successfully updated",
  },
  {
    label: "Last Patient Appointment note retrieved",
    value: "Last Patient Appointment note retrieved",
  },
  { label: "Log history retrieved", value: "Log history retrieved" },
  { label: "Logo created", value: "Logo created" },
  {
    label: "New Notifications Count retrieved",
    value: "New Notifications Count retrieved",
  },
  {
    label: "Overall FitKit Report collection retrieved",
    value: "Overall FitKit Report collection retrieved",
  },
  {
    label: "Overall PatientAppointment Report collection retrieved",
    value: "Overall PatientAppointment Report collection retrieved",
  },
  {
    label: "Patient Appointment Notifications collection retrieved",
    value: "Patient Appointment Notifications collection retrieved",
  },
  {
    label: "Patient Appointment collection retrieved",
    value: "Patient Appointment collection retrieved",
  },
  {
    label: "Patient Appointment created",
    value: "Patient Appointment created",
  },
  {
    label: "Patient Appointment notification created",
    value: "Patient Appointment notification created",
  },
  {
    label: "Patient Appointment patched",
    value: "Patient Appointment patched",
  },
  {
    label: "Patient Appointment retrieved",
    value: "Patient Appointment retrieved",
  },
  { label: "Patient created", value: "Patient created" },
  {
    label: "Patient successfully retrieved",
    value: "Patient successfully retrieved",
  },
  {
    label: "Patient successfully updated",
    value: "Patient successfully updated",
  },
  {
    label: "PatientAppointmentNotification updated",
    value: "PatientAppointmentNotification updated",
  },

  {
    label: "Provider group successfully retrieved",
    value: "Provider group successfully retrieved",
  },

  {
    label: "Provider group successfully created",
    value: "Provider group successfully created",
  },

  {
    label: "Provider group retrieved",
    value: "Provider group retrieved",
  },

  {
    label: "Provider group providers collection retrieved",
    value: "Provider group providers collection retrieved",
  },

  {
    label: "Provider group referrals collection retrieved",
    value: "Provider group referrals collection retrieved",
  },

  {
    label: "Provider group collection retrieved",
    value: "Provider group collection retrieved",
  },

  {
    label: "Provider group successfully updated",
    value: "Provider group successfully updated",
  },

  {
    label: "Provider Patient Referral created",
    value: "Provider Patient Referral created",
  },
  {
    label: "Provider Patient Referral collection retrieved",
    value: "Provider Patient Referral collection retrieved",
  },
  {
    label: "Provider Patient Referral past due",
    value: "Provider Patient Referral past due",
  },
  {
    label: "Provider Patient Referral patched and note created",
    value: "Provider Patient Referral patched and note created",
  },
  {
    label: "Provider Patient Referral updated",
    value: "Provider Patient Referral updated",
  },
  {
    label: "Provider Patient Referral collection retrieved",
    value: "Provider Patient Referral collection retrieved",
  },
  {
    label: "Referral module retrieved",
    value: "Referral module retrieved",
  },

  {
    label: "Referral module successfully created",
    value: "Referral module successfully created",
  },

  {
    label: "Referral module collection retrieved",
    value: "Referral module collection retrieved",
  },

  {
    label: "Referral module successfully updated",
    value: "Referral module successfully updated",
  },

  {
    label: "Schedule collection retrieved",
    value: "Schedule collection retrieved",
  },
  {
    label: "Schedule for doctor in hospital created",
    value: "Schedule for doctor in hospital created",
  },
  {
    label: "Schedule successfully deleted",
    value: "Schedule successfully deleted",
  },
  {
    label: "Schedule successfully retrieved",
    value: "Schedule successfully retrieved",
  },
  {
    label: "Search patient executed by dob",
    value: "Search patient executed by dob",
  },
  {
    label: "Search patient executed by mrn",
    value: "Search patient executed by mrn",
  },
  {
    label: "Search patient executed by name",
    value: "Search patient executed by name",
  },
  {
    label: "Settings files collection retrieved",
    value: "Settings files collection retrieved",
  },
  { label: "Settings not found", value: "Settings not found" },
  {
    label: "Settings successfully created",
    value: "Settings successfully created",
  },
  {
    label: "Settings successfully retrieved",
    value: "Settings successfully retrieved",
  },
  { label: "Successfully Registered", value: "Successfully Registered" },
  {
    label: "Successfully issue a forget password",
    value: "Successfully issue a forget password",
  },
  { label: "Successfully logged in", value: "Successfully logged in" },
  {
    label: "Successfully retrieved the reset password code",
    value: "Successfully retrieved the reset password code",
  },
  {
    label: "Successfully set or changed password through forget password",
    value: "Successfully set or changed password through forget password",
  },
  { label: "User access re-enabled", value: "User access re-enabled" },
  { label: "User access revoked", value: "User access revoked" },
  {
    label: "User collection (FIT kit providers) retrieved",
    value: "User collection (FIT kit providers) retrieved",
  },
  { label: "User collection retrieved", value: "User collection retrieved" },
  { label: "User successfully updated", value: "User successfully updated" },
];

export const startOfYear = new Date(moment().startOf("year").format());
export const startOfLastYear = new Date(
  moment().subtract("1", "year").startOf("year").format()
);
export const endOfYear = new Date(moment().endOf("year").format());
export const endOfLastYear = new Date(
  moment().subtract("1", "year").endOf("year").format()
);
