// @ts-nocheck
import moment from "moment";

export const getTimestampFromDate = (strDate: string) => {
  console.log(strDate);
  const d = Date.parse(strDate);
  return d / 1000;
};
export const getDateFromUnixFormatted = (
  unix: number,
  separator: string
): string => {
  if (!unix) {
    return null;
  }
  return convertTimestamp(unix, separator).date;
};
export const getTimeFromUnixFormatted = (unix: number): string => {
  if (!unix) {
    return null;
  }
  return convertTimestamp(unix).time;
};

function convertTimestamp(timestamp, separator = "-") {
  const d = new Date(timestamp * 1000),
    year = d.getFullYear(),
    month = ("0" + (d.getMonth() + 1)).slice(-2),
    day = ("0" + d.getDate()).slice(-2),
    hours = d.getHours(),
    minutes = ("0" + d.getMinutes()).slice(-2);
  let system = "AM";
  let calculatedHours = hours;

  if (hours > 12) {
    calculatedHours = hours - 12;
    system = "PM";
  } else if (hours === 12) {
    calculatedHours = 12;
    system = "PM";
  } else if (hours == 0) {
    calculatedHours = 12;
  }
  return {
    date: `${month}${separator}${day}${separator}${year}`,
    time: `${calculatedHours}:${minutes} ${system}`,
  };
}

export const getUserFormatted = (user: any): string => {
  return `${user?.first} ${user?.last}`;
};

export const getDoctorFormatted = (doctor: any): string => {
  return `Dr. ${getUserFormatted(doctor)}`;
};

export const getInputFormatDate = (initialDate: any) => {
  let month = initialDate.getMonth() + 1;
  let day = initialDate.getDate();
  const year = initialDate.getFullYear();
  if (month < 10) month = "0" + month.toString();
  if (day < 10) day = "0" + day.toString();
  return `${year}-${month}-${day}`;
};

export const getFileSignedUrl = (file: any): string => {
  const downloadUrl = file.downloadUrl;
  const fileName = file.name;
  const token = file.token;
  return `${downloadUrl}?name=${encodeURIComponent(
    fileName
  )}&token=${encodeURIComponent(token)}`;
};

export const getNoteTitle = (note: any): string => {
  let title = "";
  const date = moment.unix(note.createdAt).format("MMMM Do, YYYY");
  if (note.createdByFirst && note.createdByLast) {
    title = ` by ${note.createdByFirst} ${note.createdByLast}`;
  } else if (note.createdBy?.first && note.createdBy?.last) {
    title = ` by ${note.createdBy.first} ${note.createdBy.last}`;
  }
  return date + title;
};
export const getPhoneFormatted = (phone: string): string => {
  const cleaned = ("" + phone).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return match[1] + "-" + match[2] + "-" + match[3];
  }
  return phone;
};
export const getDobFormatted = (dob: string, separator = "-"): string => {
  const month = dob.substring(0, 2);
  const day = dob.substring(2, 4);
  const year = dob.substring(4, 8);
  return `${month}${separator}${day}${separator}${year}`;
};

export const getDobFormattedforDateField = (
  dob: string,
  separator = "-"
): string => {
  const month = dob.substring(0, 2);
  const day = dob.substring(2, 4);
  const year = dob.substring(4, 8);
  return `${month}${separator}${day}${separator}${year}`;
};
export const getRelativeTimeFromUnixFormatted = (unix: number): string => {
  const msPerMinute = 60;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;
  const msPerMonth = msPerDay * 30;
  const msPerYear = msPerDay * 365;

  const current = Math.floor(Date.now() / 1000);
  const elapsed = current - unix;

  if (elapsed < msPerMinute) {
    return (
      Math.round(elapsed / 1000) +
      " second" +
      (Math.round(elapsed / 1000) === 1 ? "" : "s") +
      " ago"
    );
  } else if (elapsed < msPerHour) {
    return (
      Math.round(elapsed / msPerMinute) +
      " min" +
      (Math.round(elapsed / msPerMinute) === 1 ? "" : "s") +
      " ago"
    );
  } else if (elapsed < msPerDay) {
    return (
      Math.round(elapsed / msPerHour) +
      " hour" +
      (Math.round(elapsed / msPerHour) === 1 ? "" : "s") +
      " ago"
    );
  } else if (elapsed < msPerMonth) {
    return (
      Math.round(elapsed / msPerDay) +
      " day" +
      (Math.round(elapsed / msPerDay) === 1 ? "" : "s") +
      " ago"
    );
  } else if (elapsed < msPerYear) {
    return (
      Math.round(elapsed / msPerMonth) +
      " month" +
      (Math.round(elapsed / msPerMonth) === 1 ? "" : "s") +
      " ago"
    );
  } else {
    return (
      Math.round(elapsed / msPerYear) +
      " year" +
      (Math.round(elapsed / msPerYear) === 1 ? "" : "s") +
      " ago"
    );
  }
};

export const checkEditable = (fitKit) => {
  return (
    (fitKit.status === "completed" && fitKit.result === "no_result") ||
    !hasColonoscopyOrReferral(fitKit)
  );
};

const hasColonoscopyOrReferral = (fitKit) => {
  return (
    (fitKit.colonoscopyInsideICC && fitKit.colonoscopyInsideICC.id) ||
    (fitKit.colonoscopyOutsideICC && fitKit.colonoscopyOutsideICC.id) ||
    (fitKit.referral && fitKit.referral.id) ||
    (fitKit.communityPatientReferral && fitKit.communityPatientReferral.id)
  );
};

export const getMonthsAgo = (unix: number) => {
  const msPerMinute = 60;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;
  const msPerMonth = msPerDay * 30;
  const current = Math.floor(Date.now() / 1000);
  const elapsed = current - unix;

  return (
    Math.round(elapsed / msPerMonth) +
    " month" +
    (Math.round(elapsed / msPerMonth) === 1 ? "" : "s") +
    " ago"
  );
};
