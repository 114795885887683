// @ts-nocheck
import api from "./api";
const URLS = {
  ADDRESS: `/address`,
};
export const fetchCities = (data = {}) => {
  return api.get(URLS.ADDRESS + "/places", data);
};
export const fetchCitiesZipCode = (id, data = {}) => {
  return api.get(URLS.ADDRESS + "/places/" + id + "/zip-codes", data);
};
export const fetchStates = (data = {}) => {
  return api.get(URLS.ADDRESS + "/states", data);
};
export const fetchStatesZipCode = (id,data = {}) => {
  return api.get(URLS.ADDRESS + "/states/" + id + "/places" , data);
};
export const fetchZip = (data = {}) => {
  return api.get(URLS.ADDRESS + "/zip-codes", data);
};