<template lang="pug">
section.steps-card.card
  ol.steps
    slot(name="steps")
  .steps-content
    slot(name="content")
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  components: {},

  data() {
    return {};
  },

  computed: {},

  methods: {},
});
</script>

<style lang="scss">
.steps-card {
  padding-top: 0;
  padding-bottom: 45px;
  position: relative;
  width: 100%;
  box-shadow: none;
  z-index: 1;
}
.steps {
  display: flex;
  align-items: center;
  height: 83px;
  counter-reset: list;
  margin: 0;
  padding: 0 30px;
  background-color: $primaryBgBlue;
  border-radius: 30px 30px 0 0;
  text-transform: uppercase;
  & > li {
    list-style: none;
    color: $primaryDarkGrey;
    &:before {
      content: counter(list) ") ";
      counter-increment: list;
    }
    & > span {
      text-decoration: underline;
    }
    &.router-link-exact-active {
      color: #000;
      font-weight: 700;
      & ~ li > span {
        text-decoration: none;
      }
    }
    .svg-icon--arrow-steps {
      width: 19.8px;
      height: 8.9px;
      margin: 0 12px;
    }
  }
}
</style>
