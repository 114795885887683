<template lang="pug">

TabsCard(variant="lg", v-if= " $route.name != 'clinics.appointment-status.reschedule' ")
  template(#tabs)
    router-link.tab(:to="{name: 'clinics.appointment-status.requested'}")
      .tab__inner
        .tab__first-child {{ summary.requested }}
        .tab__second-child Requested
    router-link.tab(:to="{name: 'clinics.appointment-status.confirmed'}")
      .tab__inner
        .tab__first-child {{ summary.confirmed }}
        .tab__second-child Confirmed
    router-link.tab(:to="{name: 'clinics.appointment-status.pending-clinic-review'}")
      .tab__inner
        .tab__first-child {{ summary.completedColonoscopyPendingClinicReview }}
        .tab__second-child Pending Clinic Review
    router-link.tab(:to="{name: 'clinics.appointment-status.archived'}")
      .tab__inner
        .tab__first-child {{ summary.archives }}
        .tab__second-child Archived
  template(#content)
    router-view(
      @refreshAppointmentsSummary="refreshAppointmentsSummary"
      :specialtyModule="specialtyModule"
    )
router-view(v-else :specialtyModule="specialtyModule")

</template>

<script>
import { defineComponent } from "vue";
import TabsCard from "@/components/TabsCard.vue";

import { fetchClinicSummary } from "@/api/clinicApi";

import { apiStatus, apiStatusComputedFactory } from "@/api";
const { IDLE, PENDING, SUCCESS, ERROR } = apiStatus;

export default defineComponent({
  components: {
    TabsCard,
  },
  props: {
    specialtyModule: {
      required: false,
    },
  },

  data() {
    return {
      fetchClinicSummaryStatus: IDLE,
      fetchPatientAppointmentAvailableScheduleStatus: IDLE,
      summary: {
        requested: 0,
        confirmed: 0,
        completedColonoscopyPendingClinicReview: 0,
        archives: 0,
      },
    };
  },
  computed: {
    ...apiStatusComputedFactory(
      "fetchPatientAppointmentAvailableScheduleStatus",
      "fetchClinicSummaryStatus"
    ),
  },
  methods: {
    refreshAppointmentsSummary() {
      this.fetchClinicSummary();
    },
    async fetchClinicSummary() {
      this.fetchClinicSummaryStatus = PENDING;
      try {
        const res = await fetchClinicSummary(this.$route.params.idClinic, {
          params: {
            idSpecialtyModule: this.$route.params.idSpecialty,
          },
        });

        this.summary = res.data.appointmentStatus;
        this.fetchClinicSummaryStatus = SUCCESS;
      } catch (err) {
        this.fetchClinicSummaryStatus = ERROR;
        console.error(err);
      }
    },
  },
  created() {
    if (this.$route.params.idClinic === null) return;
    this.fetchClinicSummary();
  },
});
</script>

<style scoped lang="scss"></style>
