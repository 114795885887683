<template lang="pug">
.base-checkbox
  label.checkbox-custom
    input.toggle-checkbox(
      type="checkbox",
      :checked="modelValue",
      @change="$emit('update:modelValue', $event.target.checked)",
      :id="labelUnique"
    )
    base-icon.checkmark(name="checkmark")
  label.checkbox-custom-lbl(v-if="label", :for="labelUnique") {{label}}
</template>

<script>
import { defineComponent } from "vue";
import kebabCase from "lodash/kebabCase";
import { v1 as uuidv1 } from "uuid";

export default defineComponent({
  props: {
    label: {
      type: String,
      default: "",
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    labelUnique() {
      if (!this.label) return "";
      return `${kebabCase(this.label)}-${uuidv1()}`;
    },
  },
});
</script>

<style scoped lang="scss">
$checkboxHeight: 25px;
.base-checkbox {
  display: flex;
  align-items: center;
}
.checkbox-custom {
  display: inline-block;
  position: relative;
  height: $checkboxHeight;
  width: $checkboxHeight;
  margin: 0;
  border: solid 2px #019bdf;
  border-radius: 2px;
  background-color: #fff;
  cursor: pointer;
  input {
    appearance: none;
    box-shadow: none;
    outline: none;
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  .checkmark {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    transform-origin: center;
    color: $primaryLightBlue;
    opacity: 0;
    transition: all 0.3s ease-out;
  }
  input:checked ~ .checkmark {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
}
.checkbox-custom-lbl {
  display: block;
  margin: 0;
  font-size: 14px;
  line-height: 1.29;
  padding-left: 11px;
  white-space: nowrap;
}
</style>
