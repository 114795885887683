<template lang="pug">

TableTitle(title="Ongoing Care",
  searchPlaceholder="Search by patient name"
  v-model="searchKeyword",
  @doSearch="fetchOnGoingCarePatient",
  @blur="fetchOnGoingCarePatient"
)
.table
  .th
    .td(@click.prevent="changeListOrder('PatientName', fetchOnGoingCarePatient)" style="cursor: pointer;")  Patient Name
      i(style="font-weight: bolder;") {{ PatientNameColumnSortingIcon }}
    .td Clinic
    .td Doctor
    .td Last Appt
    .td Next Appt
    .td On Track
  .tr(
    v-for="patient in patients",
    :key="patient.id_patient",
    @click.prevent="openAppointmentHistoryOverlay(patient.id_patient)"
  )
    .td.bold  {{ getUserFormatted(patient.patient) }}
    .td {{ patient.clinic?.clinic_name }}
    .td(v-if='patient.doctor') {{ getDoctorFormatted(patient.doctor) }}
    .td(v-else) 
    .td(v-if="patient.last_appointment_date")
      div {{ getDateFromUnixFormatted(patient.last_appointment_date) }}
      .second-row-md-grey {{ getTimeFromUnixFormatted(patient.last_appointment_date) }}
    .td(v-else)
    .td 
      div(v-if="patient.next_appointment_date")
        div {{ getDateFromUnixFormatted(patient.next_appointment_date) }}
        .second-row-md-grey {{ getTimeFromUnixFormatted(patient.  next_appointment_date) }}
    .td
      base-icon(name="yes", v-if="patient.is_on_track")
      base-icon(name="no", v-else)
Pagination(
  v-if="pagination",
  @changePagination="changePagination",
  :pagination="pagination",
  :reloadFn="fetchOnGoingCarePatient"
)

AppointmentHistory(
  :key="`${componentKey}-1`",
  :isOpen="isAppointmentHistoryOpen",
  :patientId="patientId"
  :specialtyModule="specialtyModule"
  @closeAppointmentHistoryOverlay="closeAppointmentHistoryOverlay"
  @refreshAppointments="fetchOnGoingCarePatient"
)
</template>

<script>
import { defineComponent } from "vue";
import TableTitle from "@/components/TableTitle.vue";
import DateSelector from "@/components/DateSelector.vue";
import Pagination from "@/components/Pagination.vue";
import listPagesMixin from "@/mixins/listPagesMixin";

import AppointmentHistory from "@/components/hospitals/ongoing-care/overlays/AppointmentHistory.vue";

import {
  getDateFromUnixFormatted,
  getTimeFromUnixFormatted,
  getUserFormatted,
  getDoctorFormatted,
} from "@/helpers";

import { fetchOnGoingCarePatient } from "@/api/onGoingCareApi";
import { apiStatus, apiStatusComputedFactory } from "@/api";
const { IDLE, PENDING, SUCCESS, ERROR } = apiStatus;
import sortOnGoingCare from "@/mixins/sortOnGoingCare";

export default defineComponent({
  components: {
    TableTitle,
    DateSelector,
    Pagination,
    AppointmentHistory,
  },
  mixins: [listPagesMixin, sortOnGoingCare],
  emits: ["refreshAppointmentsSummary"],

  data() {
    return {
      searchKeyword: "",
      isAppointmentHistoryOpen: false,
      fetchOnGoingCarePatientStatus: IDLE,
      patients: null,
      patientId: 0,
      summary: {
        active: 0,
        archived: 0,
      },
    };
  },
  computed: {
    ...apiStatusComputedFactory("fetchOnGoingCarePatientStatus"),
  },
  props: {
    specialtyModule: {
      required: false,
    },
  },
  methods: {
    getDateFromUnixFormatted,
    getTimeFromUnixFormatted,
    getUserFormatted,
    getDoctorFormatted,

    async fetchOnGoingCarePatient() {
      this.fetchOnGoingCarePatientStatus = PENDING;
      const order = this.listOrder
        ? {
            by: this.listOrder.by,
            direction: this.listOrder.direction,
          }
        : null;
      try {
        const res = await fetchOnGoingCarePatient({
          params: {
            ...this.reqPagination,
            order,
            idSpecialtyModule: this.$route.params.idSpecialty,
            filters: {
              idHospital: this.$route.params.idHospital,
              status: "archived",
              ...(this.searchKeyword
                ? { searchKeyword: this.searchKeyword }
                : {}),
            },
          },
        });
        this.pagination = res.data.pagination;
        this.patients = res.data.patients;
        this.summary = {
          active: res.data.activeCount || 0,
          archived: res.data.archivedCount || 0,
        };
        this.$emit("refreshAppointmentsSummary", this.summary);

        this.fetchOnGoingCarePatientStatus = SUCCESS;
      } catch (err) {
        this.fetchOnGoingCarePatientStatus = ERROR;
        console.error(err);
      }
    },
    openAppointmentHistoryOverlay(patientId) {
      this.componentKey = this.componentKey + 1;
      this.patientId = patientId;
      this.isAppointmentHistoryOpen = true;
    },
    closeAppointmentHistoryOverlay() {
      this.isAppointmentHistoryOpen = false;
    },
  },
  created() {
    this.fetchOnGoingCarePatient();
  },
});
</script>

<style scoped lang="scss">
.tr {
  cursor: pointer;
}
.td {
  &:nth-child(1) {
    width: math.percentage(math.div(236, $tabContentCardWidth));
  }
  &:nth-child(2) {
    width: math.percentage(math.div(223, $tabContentCardWidth));
  }
  &:nth-child(3) {
    width: math.percentage(math.div(197, $tabContentCardWidth));
  }
  &:nth-child(4) {
    width: math.percentage(math.div(218, $tabContentCardWidth));
  }
  &:nth-child(5) {
    width: math.percentage(math.div(190, $tabContentCardWidth));
  }
  &:nth-child(6) {
    width: math.percentage(math.div(116, $tabContentCardWidth));
  }
}
</style>
<style>
.tabs-card .tab-content {
  padding-bottom: 0px;
}
</style>
