<template lang="pug">

TabsCard(variant="lg", v-if= " $route.name != 'hospitals.appointment-status.reschedule' " )
  template(#tabs)
    router-link.tab(:to="{name: 'hospitals.appointment-status.requested'}")
      .tab__inner
        .tab__first-child {{ summary.requested }}
        .tab__second-child Requested
    router-link.tab(:to="{name: 'hospitals.appointment-status.confirmed'}")
      .tab__inner
        .tab__first-child {{ summary.confirmed }}
        .tab__second-child Confirmed
    router-link.tab(:to="{name: 'hospitals.appointment-status.pending-clinic-review'}")
      .tab__inner
        .tab__first-child {{ summary.completedColonoscopyPendingClinicReview }}
        .tab__second-child Pending Clinic Review
    router-link.tab(:to="{name: 'hospitals.appointment-status.archived'}")
      .tab__inner
        .tab__first-child {{ summary.archives }}
        .tab__second-child Archived
  template(#content)
    router-view(
      @refreshAppointmentsSummary="refreshAppointmentsSummary"
      :specialtyModule="specialtyModule"
    )
router-view(v-else :specialtyModule="specialtyModule")

</template>

<script>
import { defineComponent } from "vue";
import TabsCard from "@/components/TabsCard.vue";

import { fetchPatientAppointment } from "@/api/patientAppointmentApi";

export default defineComponent({
  components: {
    TabsCard,
  },
  props: {
    specialtyModule: {
      required: false,
    },
  },
  data() {
    return {
      summary: {
        requested: 0,
        confirmed: 0,
        completedColonoscopyPendingClinicReview: 0,
        archives: 0,
      },
    };
  },
  methods: {
    refreshAppointmentsSummary() {
      this.fetchSummaryInfo();
    },
    fetchSummaryInfo() {
      this.fetchPatientAppointment("REQUESTED");
      this.fetchPatientAppointment("CONFIRMED");
      this.fetchPatientAppointment(
        "COMPLETED_COLONOSCOPY_PENDING_CLINIC_REVIEW"
      );
      this.fetchPatientAppointment("ARCHIVES");
    },
    async fetchPatientAppointment(stageParam) {
      try {
        const res = await fetchPatientAppointment({
          params: {
            ...this.reqPagination,
            idHospital: this.$route.params.idHospital,
            include: [
              "clinic",
              "hospitalDoctor",
              "patient",
              "referringDoctor",
              "hospital",
              "cancelled",
            ],
            stage: stageParam,
            idSpecialtyModule: this.$route.params.idSpecialty,
          },
        });

        switch (stageParam) {
          case "REQUESTED":
            this.summary.requested = res.data.pagination.quantity;
            break;
          case "CONFIRMED":
            this.summary.confirmed = res.data.pagination.quantity;
            break;
          case "COMPLETED_COLONOSCOPY_PENDING_CLINIC_REVIEW":
            this.summary.completedColonoscopyPendingClinicReview =
              res.data.pagination.quantity;
            break;
          case "ARCHIVES":
            this.summary.archives = res.data.pagination.quantity;
            break;
        }

        this.appointments = res.data.patientAppointments;
        this.pagination = res.data.pagination;
      } catch (err) {
        console.error(err);
      }
    },
  },
  created() {
    this.fetchSummaryInfo();
  },
});
</script>

<style scoped lang="scss"></style>
