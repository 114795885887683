<template lang="pug">
FullScreenOverlaySlot(
  :isOpen="isOpen",
  headerIcon="colon",
  :headerIconString="clinicIcon",
  title="Appointment Record"
  @closeOverlay="closeOverlay(false)"
)
  template(#breadcrumbs)
    h1.breadcrumbs-title {{ clinicTitle }}
  template(#actionSection)
    BaseBtn.x-lg.blue-outline(
      href="#",
      @click.prevent="openAppointmentNotesOverlay",
      :count="noteSummary.new"
    ) NOTES ({{ noteSummary.total }})
  template(#content)
    .card(style="padding-top: 52px;")
      .patient-info.columns.is-variable
        .column.is-one-third
          .columns.is-variable
            .info-pair.column.is-one-half
              .label Scheduled Date
              .txt.txt--blue {{ getDateFromUnixFormatted(showAppointment.scheduledAt) }} 
            .info-pair.column.is-one-half
              .label Scheduled Time
              .txt.txt--blue {{ getTimeFromUnixFormatted(showAppointment.scheduledAt) }} 
        .column.is-one-third.rescheduled
          .columns.is-variable
            .column.is-one-half
            .column.is-one-half
              .info-pair
                .label
                  span Rescheduled
                    base-icon(name="yes", v-if="showAppointment.status == 'Rescheduled' ")
                    base-icon(name="no", v-else)
        .column.is-one-third.adjust-margin-top(v-if=" tabTypeProcessed == 'requested' ")
          .flex.flex--space-between
            BaseBtn.sm.black-outline(:to="{name: 'clinics.appointment-status.reschedule',params: { idAppointment: showAppointment?.id, idSpecialty: specialtyId } }") RESCHEDULE
            BaseBtn.sm.red-outline(href="#",
            @click.prevent="openCancelAppointmentOverlay") CANCEL
      .hr.hr--thin
      .columns.is-variable
        .info-pair.column.is-one-third
          .label First Name
          .txt.txt--blue {{ showAppointment.patient.first }}
        .info-pair.column.is-one-third
          .label Last Name
          .txt.txt--blue {{ showAppointment.patient.last }}
        .info-pair.column.is-one-third
          .label Date of Birth
          .txt.txt--blue {{ getDobFormattedforDateField(showAppointment.patient.dob) }}
      .hr.hr--thin
      .info-pair.columns.is-variable
        .column.is-two-thirds.info-pair
          .label Patient Address
          .txt {{ showAppointment.patient.address.address}}<br>{{ showAppointment.patient.address.place }}, {{ showAppointment.patient.address.state }} {{ showAppointment.patient.address.zipCode }}
        .column.is-one-third.flex.flex--flex-start.flex--align-start
          .info-pair
            .label Patient Phone Number
            .txt {{ getPhoneFormatted(showAppointment.patient.contact.cellPhone) }}
          .info-pair
            .label Ext.
            .txt {{ showAppointment.patient.contact.cellPhoneExtension }}
      .hr.hr.hr--thin
      .columns.is-variable(v-if="showAppointment?.schedule?.hospital.name")
        .info-pair.column.is-one-third
          .label Hospital Name
          .txt {{ showAppointment.schedule.hospital.name }}
        .info-pair.column.is-one-third(v-if="showAppointment?.schedule.doctor")
          .label Doctor Name
          .txt {{ getDoctorFormatted(showAppointment?.schedule.doctor) }}
        .column.is-one-third.flex.flex--flex-start.flex--align-start
          .info-pair
            .label Hospital Phone Number
            .txt {{ getPhoneFormatted(showAppointment.schedule.hospital.contact.businessPhone) }}
          .info-pair
            .label Ext.
            .txt {{ showAppointment.schedule.hospital.contact.businessPhoneExtension }}
    .card.card--blue-bg
      h2.title.title--md-thin PATIENT RECORD INFORMATION
      .patient-record-info.columns.is-variable
        .info-pair.column.is-one-third
          .label Referring Physician
          .txt {{ getDoctorFormatted(showAppointment?.referringDoctor) }}
        .info-pair.column.is-one-third
          .label Clinic MRN
          .txt {{ showAppointment.patient.mrn }}
        .column.is-one-third
          .form-group
            label.label--margin-sm Hospital MRN
            input.text-lg(type="text" :value="showAppointment.hospitalMrn " :disabled="disableFields" )
    .card
      h2.title.title--md-thin Emergency Contact Information
      .emergency-contact-info.columns
        .info-pair.column.is-two-thirds
          .label Name
          .txt {{ showAppointmentDetails?.emergencyContact?.firstName }} {{ showAppointmentDetails?.emergencyContact?.lastName }}
        .column.is-one-third.flex.flex--flex-start.flex--align-start
          .info-pair
            .label Phone Number
            .txt {{ getPhoneFormatted(showAppointmentDetails?.emergencyContact.phoneNumber) }}
          .info-pair(v-if="showAppointmentDetails?.emergencyContact.phoneExtension")
            .label Ext.
            .txt {{ showAppointmentDetails?.emergencyContact.phoneExtension }}
    .card
      .columns.is-variable
        .column.insurance-status.is-one-third.flex.flex--space-between
          p Does patient have insurance?
          base-icon(name="yes", v-if="showAppointmentDetails?.insurance.name")
          base-icon(name="no", v-else)
      .hr.hr.hr--thin(v-if="showAppointmentDetails?.insurance.name")
      h2.title.title--md-thin(v-if="showAppointmentDetails?.insurance.name") Insurance Information
      .insurance-info.columns.is-variable(v-if="showAppointmentDetails?.insurance.name")
        .info-pair.column.is-one-third
          .label Insurance Name
          .txt {{ showAppointmentDetails?.insurance.name }}
        .info-pair.column.is-one-third
          .label Policy Number
          .txt {{ showAppointmentDetails?.insurance.policyNumber }}
      .columns.is-variable(v-if="showAppointmentDetails?.insurance.name")
        .info-pair.column.is-one-third
          .label Expiration Date
          .txt(v-if="showAppointmentDetails?.insurance.expirationDate") {{ getDateFromUnixFormatted(showAppointmentDetails?.insurance.expirationDate) }}
        .info-pair.column.is-one-third
          .label Group Number
          .txt {{ showAppointmentDetails?.insurance.groupNumber }}
        .info-pair.column.is-one-third(v-if=" tabTypeProcessed != 'requested' ")
          .label Upload Insurance Card
          .flex.flex--flex-start
            FileViewer(:fileId="showAppointmentDetails?.insuranceCard.id")
        .info-pair.column.is-one-third(v-else)
          .label Upload Insurance Card
          .flex.flex--flex-start
            UploadAndPreviewFile(
              :buttonStyleClasses="['btn', 'lg', 'blue']"
              :storageKey="insuranceCardStorageKey"
              :src="insuranceCardSrc"
              v-if=" tabTypeProcessed == 'pending' "
            )
            FileViewer(
              v-else
              :fileId="showAppointmentDetails?.insuranceCard.id"
            )
    .card
      h2.title.title--md-thin CLINIC APPOINTMENT DETAILS
      .columns
        .info-pair.column.is-one-third
          .label {{ specialtyModule?.schemaRules?.patientInfoConfiguration?.referralInfoTitle || "GI Referral Info" }} 
          .txt.cursorPointer 
            base-icon(name="eye-green", @click.prevent="openReferralInfoOverlay")
        .info-pair.column.is-one-third
          .label Clinic Report
          .txt
            FileViewer(:fileId="showAppointmentDetails?.clinicReport.id")

    .card.card--blue-bg(v-if=" tabTypeProcessed != 'requested' ")
      h2.title.title--md-thin PROCEDURE COMPLETION
      .columns
        .info-pair.column.is-one-third
          .label Procedure Completed
          select(:disabled="true" v-model="showAppointment.procedureStatus")
              option Select
              option(value="COMPLETED") YES - COMPLETED
              option(value="CANCELED") NO - CANCELLED
              option(value="NO_SHOW") NO - NO SHOW
              option(
                v-for="additionalDropdownOption in additionalDropdownOptions" 
                :key="additionalDropdownOption.id" 
                :value="additionalDropdownOption.value"
              ) NO - {{ additionalDropdownOption.name.toUpperCase() }}
        .info-pair.column.is-one-third(v-if="specialtyModule?.schemaRules?.specialtyConfiguration?.procedureReportTitle")
          .label {{ specialtyModule?.schemaRules?.specialtyConfiguration?.procedureReportTitle }} 
          FileViewer(:fileId="showAppointmentDetails?.idHospitalReport", v-if="disableFields")
          BaseBtn.lg.blue(href="#", v-if="!disableFields") UPLOAD
    
    .card.card--blue-bg(v-if=" tabTypeProcessed != 'requested' && tabTypeProcessed != 'confirmed' ")
      h2.title.title--md-thin PROCEDURE RESULTS
      .columns.flex-wrap(v-if="activeSpecialtyFields")
        template(v-for="(specialtyField, index) in activeSpecialtyFields" :key="specialtyField.id" :class="specialtyField.class" )
          .info-pair.column.is-full(v-if="specialtyField.inNewRow !== undefined && specialtyField.inNewRow")
          .info-pair.column(:class="specialtyField.class" )
            div(v-if="specialtyField.type == 'NUMERIC' ")
              .label {{ specialtyField.name }}
              input(type="number" :disabled="true" v-model="procedureResults[specialtyField.id]")
            div(v-else-if="specialtyField.type == 'TEXT' ")
              .label {{ specialtyField.name }}
              input(type="text" :disabled="true" v-model="procedureResults[specialtyField.id]")
            div(v-else-if="specialtyField.type == 'RADIO' && specialtyField.value !== null " )
              .label {{ specialtyField.name }}
              label.radio-custom
                input(type="radio" :checked="true" :disabled="true" v-model="procedureResults[specialtyField.id]")
                span.radio
                span.lbl(v-if="specialtyField.value === true ") Yes
                span.lbl(v-else-if="specialtyField.value === false ") No
                span.lbl(v-else) Null
            div(v-else-if="specialtyField.type == 'FILE' ")
              .label {{ specialtyField.name }}
              FileViewer(:fileId="specialtyField.fileId")
            div(v-else-if="specialtyField.type == 'DROPDOWN' ")
              .label {{ specialtyField.name }}
              select(:disabled="true" v-model="procedureResults[specialtyField.id]")
                option {{ specialtyField.value }}
                //- option(v-for="option in specialtyField.options" :key="option.id" :value="option.name" ) {{ option.name }}
            
            div(v-else-if="specialtyField.type == 'SECTION_TITLE' ")
              h2.title.title--md-thin.margin-top {{ specialtyField.name }}

            div(v-else) {{ specialtyField.name }} || {{ specialtyField.type }} 

    .card.card--blue-bg
      h2.title.title--md-thin FINAL NOTES
      .columns
        .info-pair.column.is-one-third
          .label Hospital Notes (Required)
          textarea(style="height: 250px;" :disabled="disableFields" v-model="showAppointment.hospitalNotes")
        .info-pair.column.is-one-third(v-if="showAppointment.followUpDate")
          .label Recommended Follow-Up
          input(type="text" :value="getDateFromUnixFormatted(showAppointment.followUpDate)" disabled)

    .card(v-if="showClinicNotesForClinics()")
      h2.title.title--md-thin FINAL NOTES
      .columns
        .info-pair.column
          .label Clinic Final Notes (Required)
          textarea(style="height: 250px;" :disabled="disableClinicNotes" v-model="showAppointment.clinicNotes")
        
  template(#footer v-if=" tabTypeProcessed == 'pending' ")
    BaseBtn.btn.lg.green-outline(@click.prevent="patchPatientAppointmentById()", v-if=" tabTypeProcessed == 'pending' ") Save &amp; Return to Dashboard
    BaseBtn.btn.lg.green(
      @click.prevent="openCompleteAppointmentPopup()", 
      v-if=" tabTypeProcessed == 'pending' "
      :disabled="!showAppointment.clinicNotes"
      ) Complete this Appointment

PopupCardSlot(
    :isOpen="isCompleteAppointmentPopupOpen",
    maxWidth="675px"
  )
    .complete-appointment-popup
      h1 Are you sure you want to complete this appointment?
      p Please confirm to continue
      .popup-btns
        BaseBtn.x-lg.red-outline(@click.prevent="closeCompleteAppointmentPopup") CANCEL
        BaseBtn.x-lg.green(
          @click.prevent="prepareCompleteAppointmentPopup"
          :disabled="isSubmitting"
          :class="{spinner: isSubmitting }"
        ) YES, COMPLETE

ReferralInfoOverlay(
  :isOpen="isReferralInfoOverlayOpen",
  :overlayTitle="specialtyModule?.schemaRules?.patientInfoConfiguration?.referralInfoTitle || 'GI Referral Info' ",
  :showAppointment="showAppointment"
  :showAppointmentDetails="showAppointmentDetails"
  :patientInfoQuestions="patientInfoQuestions"
  @closeReferralInfoOverlay="closeReferralInfoOverlay"
)

CancelAppointmentOverlay(
  :isOpen="isCancelAppointmentOverlayOpen",
  :patientAppointment="showAppointment"
  overlayTitle="ARE YOU SURE YOU WANT TO CANCEL THIS APPOINTMENT?",
  @closeCancelAppointmentOverlay="closeCancelAppointmentOverlay"
  @closeAllOverlays="closeOverlay()"
)

AppointmentNotesOverlay(
  :hasNoNotes="hasNoNotes",
  :key="`${componentKey}-2`",
  :isOpen="isAppointmentNotesOverlayOpen",
  @closeAppointmentNotesOverlay="closeAppointmentNotesOverlay"
  @fetchPatientAppointmentNotifications="fetchPatientAppointmentNotifications"
  :patientAppointment="showAppointment"
)
</template>

<script>
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

import FullScreenOverlaySlot from "@/components/overlays/FullScreenOverlaySlot.vue";
import PopupCardSlot from "@/components/PopupCardSlot.vue";
import ReferralInfoOverlay from "@/components/hospitals/appointment-status/overlays/ReferralInfoOverlay.vue";
import CancelAppointmentOverlay from "@/components/clinics/appointment-status/overlays/CancelAppointmentOverlay.vue";
import AppointmentNotesOverlay from "@/components/overlays/side-overlays/AppointmentNotesOverlay.vue";
import FileViewer from "@/components/files/FileViewer.vue";
import UploadAndPreviewFile from "@/components/UploadAndPreviewFile";

import {
  fetchPatientAppointmentById,
  patchPatientAppointmentById,
} from "@/api/patientAppointmentApi";
import { fetchNotification } from "@/api/notifications.ts";

import { apiStatus } from "@/api";
const { IDLE, PENDING, SUCCESS, ERROR } = apiStatus;

import listPagesMixin from "@/mixins/listPagesMixin";
import {
  getDateFromUnixFormatted,
  getTimeFromUnixFormatted,
  getDobFormattedforDateField,
  getPhoneFormatted,
  getRelativeTimeFromUnixFormatted,
  getDoctorFormatted,
  getFileSignedUrl,
} from "@/helpers";
import { fetchFileById } from "@/api/filesApi";

export default defineComponent({
  components: {
    FullScreenOverlaySlot,
    PopupCardSlot,
    ReferralInfoOverlay,
    CancelAppointmentOverlay,
    AppointmentNotesOverlay,
    FileViewer,
    UploadAndPreviewFile,
  },
  emits: ["closeClinicAppointmentRecordOverlay"],
  mixins: [listPagesMixin],

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    tabType: {
      type: String,
      default: "",
    },
    showAppointment: {
      type: Object,
    },
    specialtyModule: {
      required: false,
    },
  },
  data() {
    return {
      patientInfoQuestions: {},
      hasNoNotes: true,
      isSubmitting: false,
      getDateFromUnixFormatted: getDateFromUnixFormatted,
      getTimeFromUnixFormatted: getTimeFromUnixFormatted,
      getDobFormattedforDateField: getDobFormattedforDateField,
      getPhoneFormatted,
      getRelativeTimeFromUnixFormatted: getRelativeTimeFromUnixFormatted,
      getDoctorFormatted,

      tabTypeProcessed: "",
      specialtyModuleProcessed: null,
      clinicId: null,
      specialtyId: null,

      hidePolypsFoundSelectLabel: false,
      hidePolypsRemovedSelectLabel: false,
      isCompleteAppointmentPopupOpen: false,
      isReferralInfoOverlayOpen: false,
      isCancelAppointmentOverlayOpen: false,
      fetchPatientAppointmentByIdStatus: IDLE,
      patchPatientAppointmentByIdStatus: IDLE,
      fetchPatientAppointmentNotificationsStatus: IDLE,

      additionalDropdownOptions: [],
      specialtyFields: [],
      procedureResults: [],

      showAppointmentDetails: null,
      isAppointmentNotesOverlayOpen: false,
      noteSummary: {
        total: 0,
        new: 0,
      },
      insuranceCardStorageKey: "edit_appt_insurance_card",
      insuranceCardSrc: null,
    };
  },
  computed: {
    ...mapGetters(["getClinicId"]),
    clinicIcon() {
      return this.specialtyModuleProcessed?.icon;
    },
    clinicTitle() {
      return (
        this.specialtyModuleProcessed?.marketingTitle +
        " > " +
        this.specialtyModuleProcessed?.moduleTitle
      );
    },
    disableFields() {
      return (
        this.tabTypeProcessed == "requested" ||
        this.tabTypeProcessed == "archived" ||
        this.tabTypeProcessed == "confirmed" ||
        this.tabTypeProcessed == "pending" ||
        this.tabTypeProcessed == "displayOnly"
      );
    },
    disableClinicNotes() {
      return (
        this.tabTypeProcessed == "requested" ||
        this.tabTypeProcessed == "archived" ||
        this.tabTypeProcessed == "confirmed" ||
        this.tabTypeProcessed == "displayOnly"
      );
    },
    activeSpecialtyFields() {
      return this.specialtyFields
        .filter(
          (specialty) =>
            specialty.value !== null || specialty.type == "SECTION_TITLE"
        )
        .map((specialty) => {
          switch (specialty.type) {
            case "NUMERIC":
              // specialty.class = "is-2";
              specialty.class = "is-one-third";
              break;
            case "DROPDOWN":
              specialty.class = "is-one-third";
              break;
            case "SECTION_TITLE":
              specialty.class = "is-full";
              break;
            default:
              // specialty.class = "is-3";
              specialty.class = "is-one-third";
          }
          return specialty;
        });
    },
  },
  methods: {
    showClinicNotesForClinics() {
      //  !showAppointment.onGoingGeneratedBy
      if (this.tabTypeProcessed == "pending") {
        return true;
      }
      if (
        this.tabTypeProcessed == "archived" &&
        this.showAppointment.clinicNotes
      ) {
        return true;
      }

      return false;
    },
    prepareCompleteAppointmentPopup() {
      this.isSubmitting = true;
      this.completeAppointmentPopup().then(() => {
        this.closeOverlay();
        this.isSubmitting = false;
      });
    },
    async completeAppointmentPopup() {
      this.patchPatientAppointmentByIdStatus = PENDING;
      try {
        await patchPatientAppointmentById(
          this.showAppointment.id,
          this.specialtyId,
          {
            followUpDate: null,
            hospitalMrn: this.showAppointment.hospitalMrn,
            hospitalNotes: this.showAppointment.hospitalNotes,
            clinicNotes: this.showAppointment.clinicNotes,
            id: this.showAppointment.id,
            idHospitalReport: null,
            idPathologyReport: null,
            pathologyNeeded: false,
            polypsFound: null,
            polypsRemoved: null,
            procedureStatus: null,
            scheduledAt: this.showAppointment.scheduledAt,
            stage: "ARCHIVES",
          }
        );
        this.isCompleteAppointmentPopupOpen = false;

        this.patchPatientAppointmentByIdStatus = SUCCESS;
      } catch (err) {
        this.patchPatientAppointmentByIdStatus = ERROR;
        console.error(err);
      }
    },
    async patchPatientAppointmentById() {
      this.patchPatientAppointmentByIdStatus = PENDING;

      try {
        await patchPatientAppointmentById(
          this.showAppointment.id,
          this.specialtyId,
          {
            clinicNotes: this.showAppointment.clinicNotes,
          }
        );

        this.closeOverlay();

        this.patchPatientAppointmentByIdStatus = SUCCESS;
      } catch (err) {
        this.patchPatientAppointmentByIdStatus = ERROR;
        console.error(err);
      }
    },
    async fetchPatientAppointmentNotifications() {
      this.fetchPatientAppointmentNotificationsStatus = PENDING;
      try {
        const res = await fetchNotification({
          params: {
            idEntity: this.showAppointment.id,
            entityType: "appointment",
          },
        });

        let newNotifications = res.data.notifications?.filter(
          (notification) => {
            return notification.status != "Completed";
          }
        );
        this.noteSummary.new = newNotifications?.length || 0;
        this.noteSummary.total = res.data.pagination?.quantity || 0;
        if (this.noteSummary.total > 0) {
          this.hasNoNotes = false;
        }
        this.fetchPatientAppointmentNotificationsStatus = SUCCESS;
      } catch (err) {
        this.fetchPatientAppointmentNotificationsStatus = ERROR;
        console.error(err);
      }
    },
    async fetchPatientAppointmentById() {
      this.fetchPatientAppointmentByIdStatus = PENDING;
      try {
        const res = await fetchPatientAppointmentById(this.showAppointment.id, {
          params: {
            include: [
              "clinic",
              "clinicReport",
              "emergencyContact",
              "hospital",
              "hospitalDoctor",
              "hospitalReport",
              "insurance",
              "pathologyReport",
              "patient",
              "referringDoctor",
            ],
          },
        });
        this.showAppointmentDetails = res.data.patientAppointment;
        if (res.data.patientAppointment?.insuranceCard?.id) {
          this.fetchInsuranceCardSrc(
            res.data.patientAppointment.insuranceCard.id
          );
        }
        this.checkFetchFiles();

        this.fetchPatientAppointmentByIdStatus = SUCCESS;
      } catch (err) {
        this.fetchPatientAppointmentByIdStatus = ERROR;
        console.error(err);
      }
    },

    closeOverlay(shouldRefresh = true) {
      this.$emit("closeClinicAppointmentRecordOverlay", shouldRefresh);
    },
    openAppointmentNotesOverlay() {
      this.componentKey++;
      this.isAppointmentNotesOverlayOpen = true;
    },
    closeAppointmentNotesOverlay() {
      this.fetchPatientAppointmentNotifications();
      this.isAppointmentNotesOverlayOpen = false;
    },
    openCompleteAppointmentPopup() {
      this.isCompleteAppointmentPopupOpen = true;
    },
    closeCompleteAppointmentPopup() {
      this.isCompleteAppointmentPopupOpen = false;
    },
    openReferralInfoOverlay() {
      this.isReferralInfoOverlayOpen = true;
    },
    closeReferralInfoOverlay() {
      this.isReferralInfoOverlayOpen = false;
    },
    openCancelAppointmentOverlay() {
      this.isCancelAppointmentOverlayOpen = true;
    },
    closeCancelAppointmentOverlay() {
      this.fetchPatientAppointmentNotifications();
      this.isCancelAppointmentOverlayOpen = false;
    },
    async fetchInsuranceCardSrc(id) {
      try {
        const resFile = await fetchFileById(id);
        const file = resFile.data.file;
        file.token = resFile.data.token;
        this.insuranceCardSrc = getFileSignedUrl(file);
      } catch (err) {
        console.error(err);
      }
    },
    async checkFetchFiles() {
      if (!this.showAppointmentDetails.appointmentRecordSpecialtyFields) return;
      await Promise.all(
        this.showAppointmentDetails.appointmentRecordSpecialtyFields.map(
          async (apptSpecialtyField) => {
            if (apptSpecialtyField.type !== "FILE") {
              return apptSpecialtyField;
            }

            const specialtyFieldIndex = this.specialtyFields.findIndex(
              (specialtyField) => specialtyField.id === apptSpecialtyField.id
            );

            if (this.specialtyFields[specialtyFieldIndex]) {
              this.specialtyFields[specialtyFieldIndex].fileId =
                apptSpecialtyField.value;
            }

            return apptSpecialtyField;
          }
        )
      );
    },
  },
  created() {
    if (this.showAppointment === null) return;

    this.specialtyModuleProcessed = this.specialtyModule;
    if (!this.specialtyModuleProcessed) {
      this.specialtyModuleProcessed = this.showAppointment.specialtyModule;
    }

    this.patientInfoQuestions =
      this.specialtyModuleProcessed?.schemaRules?.patientInfoQuestions;

    this.clinicId = this.$route.params.idClinic;
    if (!this.clinicId) {
      this.clinicId = this.getClinicId;
    }

    this.specialtyId = this.$route.params.idSpecialty;
    if (!this.specialtyId) {
      this.specialtyId = this.showAppointment?.specialtyModule?.id;
    }

    this.tabTypeProcessed = this.tabType;
    if (this.tabType == "displayOnly") {
      let convertType = "";
      switch (this.showAppointment.stage) {
        case "REQUESTED":
          convertType = "requested";
          break;
        case "CONFIRMED":
          convertType = "confirmed";
          break;
        case "COMPLETED_COLONOSCOPY_PENDING_CLINIC_REVIEW":
          convertType = "pending";
          break;
        case "ARCHIVES":
          convertType = "archived";
          break;
      }
      this.tabTypeProcessed = convertType;
    }

    this.fetchPatientAppointmentById();
    this.fetchPatientAppointmentNotifications();

    this.showAppointment?.appointmentRecordSpecialtyFields?.forEach(
      (specialtyField) => {
        this.specialtyModuleProcessed.schemaRules?.appointmentRecordSpecialtyFields?.fields?.forEach(
          (element) => {
            if (element.id === specialtyField.id && element.inNewRow) {
              specialtyField.inNewRow = true;
            }
          }
        );

        this.specialtyFields.push(specialtyField);
        this.procedureResults[specialtyField.id] = specialtyField.value;
      }
    );
  },
});
</script>

<style scoped lang="scss">
.ext {
  max-width: 100px;
}
.flex-wrap {
  flex-wrap: wrap;
}
.margin-top {
  margin-top: 30px;
}
.cursorPointer svg {
  cursor: pointer;
}
.adjust-margin-top {
  margin-top: -8px;
}
.select-m-bot {
  margin-bottom: 25px;
}
.request_tab_confirm {
  margin-right: 0px;
  margin-left: auto;
}
.card {
  padding: 35px $secondaryCardsSidePadding 68px;
}
.columns.is-variable {
  --columnGap: 1.575%;
}
.columns + .hr {
  background-color: $primaryBgDarkGrey;
  margin-top: -21px;
  margin-bottom: 24px;
}
.card > .columns:last-child.info-pair,
.card > .columns:last-child .info-pair {
  margin-bottom: 0;
}
.svg-icon--eye-red,
.svg-icon--eye-green,
.svg-icon--yes,
.svg-icon--no {
  width: 50px;
  height: 50px;
  top: 0;
}

// Page specific styles----------------------------------------
.insurance-status {
  margin-bottom: 21px;
  p {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.29;
    letter-spacing: 0.07px;
    color: #000;
  }
}
.rescheduled {
  .label {
    & > span {
      position: relative;
    }
    .svg-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: calc(100% + 20px);
    }
  }
}
.breadcrumbs-title {
  margin: 0;
  color: $primaryLightBlue;
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.12px;
}

.complete-appointment-popup {
  max-width: 555px;
  margin: 0 auto;
  h1 {
    width: 100%;
    max-width: 549px;
    margin: 39px auto 30px;
    font-size: 28px;
    font-weight: 700;
    line-height: 1.21;
    letter-spacing: 0.14px;
    text-align: center;
  }
  p {
    margin-top: 0;
    margin-bottom: 32px;
    font-size: 16px;
    line-height: 1.19;
    letter-spacing: 0.23px;
    text-align: center;
  }
  .popup-btns {
    margin-bottom: 39px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .btn {
      width: 260px;
    }
  }
}
</style>
