<template lang="pug">
SideOverlaySlot(
  :isOpen="isOpen",
  :title="overlayTitle",
  isLarge=true,
  @closeOverlay="closeOverlay"
)
  template(#contentTop)
    section.patient-section
        .patient-section__top
          p.bold Patient: {{ showAppointment.patient.first }} {{ showAppointment.patient.last }}
          p
            span.bold {{ getDateFromUnixFormatted(showAppointment.scheduledAt) }} &nbsp;
            span {{ getTimeFromUnixFormatted(showAppointment.scheduledAt) }} 
          p.bold Clinic: {{ showAppointmentDetails?.clinic.name }}
    
    .qa-radios(v-if="showAppointment.patientInfoQuestions")
      .qa-radios__row(v-for="question in activeQuestions" :key="question.id")
        .qa-radios__qa(v-if="question.value !== null" :class="checkNotes(question)")
          .q {{ question.name }}
          .a(v-if="question.type == 'RADIO' ")
            label.radio-custom.radio-custom--yes
              input(type="radio", :name="question.id" :checked=" (question.value === true ) ? true : false"  disabled)
              span.radio
              span.lbl Yes
            label.radio-custom.radio-custom--no
              input(type="radio", :name="question.id" :checked=" (question.value === true ) ? false : true" disabled)
              span.radio
              span.lbl No
          .a(v-if="question.type == 'DATE' ")
            input(type="text" :value="getDateFromUnixFormatted(question.value)" disabled)
          .a(v-if="question.type == 'TEXT' ")
            input(type="text" :value="question.value" disabled)
          .a(v-if="question.type == 'NUMERIC' ")
            input(type="text" :value="question.value" disabled)
          .a(v-if="question.type == 'DROPDOWN' ")
            input(type="text" :value="question.value" disabled)
          .a(v-if="question.type == 'NOTES' ")
            textarea(:value="question.value" disabled style="height: 250px; width: 100%;")
          .a(v-if="question.type == 'FILE' ")
            FileViewer(:fileId="question.value")
      .qa-radios__row
        .qa-radios__qa.isNotesRow
          .q Community Notes
          .a 
            textarea(:value="showAppointment.communityCareNotes" disabled style="height: 250px; width: 100%;")

</template>

<script>
import { defineComponent } from "vue";
import SideOverlaySlot from "@/components/overlays/SideOverlaySlot.vue";
import FileViewer from "@/components/files/FileViewer.vue";
import {
  getDateFromUnixFormatted,
  getTimeFromUnixFormatted,
  getDoctorFormatted,
} from "@/helpers";

export default defineComponent({
  components: { SideOverlaySlot, FileViewer },
  emits: ["closeReferralInfoOverlay"],

  props: {
    patientInfoQuestions: {
      type: Object,
      required: false,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
    overlayTitle: {
      type: String,
      required: true,
    },
    showAppointment: {
      type: Object,
    },
    showAppointmentDetails: {
      type: Object,
    },
  },
  computed: {
    activeQuestions() {
      this.showAppointment?.patientInfoQuestions.forEach(
        (patientInfoQuestion) => {
          if (patientInfoQuestion.type === "DROPDOWN") {
            this.patientInfoQuestions?.fields?.forEach((element) => {
              if (element.type === "DROPDOWN") {
                element.options.forEach((option) => {
                  if (option.id === patientInfoQuestion.value) {
                    patientInfoQuestion.value = option.name;
                  }
                });
              }
            });
          }
        }
      );
      return this.showAppointment?.patientInfoQuestions.filter(
        (question) => question.value !== null
      );
    },
  },
  data() {
    return {
      getDateFromUnixFormatted: getDateFromUnixFormatted,
      getTimeFromUnixFormatted: getTimeFromUnixFormatted,
      getDoctorFormatted,
    };
  },
  methods: {
    checkNotes: function (answer) {
      if (answer.type == "NOTES") {
        return "isNotesRow";
      }
    },
    closeOverlay() {
      this.$emit("closeReferralInfoOverlay");
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/components/qa-radios.scss";
.qa-radios .qa-radios__qa.isNotesRow {
  flex-direction: column;
  .q {
    width: 100%;
    max-width: 100%;
  }
  .a {
    width: 100%;
    margin-top: 15px;
  }
}
body .side-overlay {
  width: 1000px;
}
.qa-radios {
  margin-bottom: 24px;
  margin-top: 5px;
  .q {
    max-width: 634px;
  }
}
.small {
  font-size: 14px;
}
.patient-section {
  position: relative;
  z-index: 1;
  margin-bottom: 31px;
  padding: 30px 24px 35px;
  background-color: $primaryBgBlue;
  color: $primaryLightBlue;
  &__top {
    margin-bottom: 8px;
    p {
      margin: 0;
      font-size: 21px;
      line-height: 1.67;
      color: #019bdf;
    }
  }
}
form#refferal-info {
  margin: 12px 0 20px;
  .columns {
    margin-bottom: 0px;
    .column {
      padding-bottom: 0px;
    }
  }
}
.overlay-show-success {
  p {
    margin: 41px 0 60px;
  }
}
</style>
