<template lang="pug">
input.upload-input(
  ref="uploadInput",
  v-bind="$attrs",
  type="file",
  @change="handleImage",
  accept="image/*"
)
BaseBtn.x-lg.blue(href="#", @click.prevent="openUploadDialog")
  slot
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    modelValue: {
      type: String,
    },
  },

  data() {
    return {
      image: "",
    };
  },
  methods: {
    handleImage(e) {
      const selectedImage = e.target.files[0]; // get first file
      this.createBase64Image(selectedImage);
    },
    createBase64Image(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.image = e.target.result;
        this.$emit("update:modelValue", this.image);
      };
      reader.readAsDataURL(fileObject);
    },
    openUploadDialog() {
      this.$refs.uploadInput.click();
    },
  },
});
</script>

<style scoped lang="scss">
.upload-input {
  box-shadow: none;
  outline: none;
  display: block;
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
</style>
