<template lang="pug">
.content-wrapper
  .card.router-view-card
    section.users
      TableTitle(title="Users")
        template(#actions)
          BaseBtn.x-lg.blue(@click.prevent="openManageUsersClinicalHealthSystemsOverlay('ADD NEW USER')") ADD NEW
      .table
        .th
          .td(@click.prevent="changeListOrder('name', fetchUser)" style="cursor: pointer;") USER NAME
            i(style="font-weight: bolder;") {{ nameColumnSortingIcon }}
          .td(@click.prevent="changeListOrder('role', fetchUser)" style="cursor: pointer;") ROLE
            i(style="font-weight: bolder;") {{ roleColumnSortingIcon }}
          .td ACTIVE
          .td
        .tr(v-for="user in Users", :key="user.id")
          .td.bold {{ user.first}} {{ user.last }}
          .td.bold {{ user.role }}
          .td
            BaseToggle(label="" :modelValue=" !user.disabled " :key="user.id" @change="toggleUserActive(user.id, $event.target.checked)" )
          .td
            BaseBtn.sm.blue(
               href="#",
              @click.prevent="openManageUsersClinicalHealthSystemsOverlay('MANAGE USER',user)"
            ) MANAGE
      Pagination(
        v-if="pagination",
        @changePagination="changePagination",
        :pagination="pagination",
        :reloadFn="fetchUser"
      )
      .actions-below-table-container
      ManageUsersClinicalHealthSystemsOverlay(
        :key="`${componentKey}-1`",
        :isOpen="isManageUsersClinicalHealthSystemsOverlayOpen",
        :overlayTitle="overlayTitle",
        :showDoctor="showDoctor",
        @closeManageUsersClinicalHealthSystemsOverlay="closeManageUsersClinicalHealthSystemsOverlay"
      )
</template>

<script>
import { defineComponent } from "vue";
import TableTitle from "@/components/TableTitle.vue";
import ManageUsersClinicalHealthSystemsOverlay from "@/components/users/overlays/ManageUsersClinicalHealthSystemsOverlay.vue";
import Pagination from "@/components/Pagination.vue";
import listPagesMixin from "@/mixins/listPagesMixin";
import sortUsers from "@/mixins/sortUsers";

import { fetchUser, deleteUser, patchUser } from "@/api/userApi";
import { apiStatus, apiStatusComputedFactory } from "@/api";
const { IDLE, PENDING, SUCCESS, ERROR } = apiStatus;

import { USER_ROLES } from "@/helpers/constants";

export default defineComponent({
  components: {
    TableTitle,
    ManageUsersClinicalHealthSystemsOverlay,
    Pagination,
  },
  mixins: [listPagesMixin, sortUsers],

  data() {
    return {
      isManageUsersClinicalHealthSystemsOverlayOpen: false,
      showDoctor: null,
      overlayTitle: "MANAGE USER",
      fetchUserStatus: IDLE,
      patchUserStatus: IDLE,
      deleteUserStatus: IDLE,
      Users: null,
      USER_ROLES,
    };
  },
  computed: {
    ...apiStatusComputedFactory("fetchUserStatus","patchUserStatus","deleteUserStatus"),
  },
  methods: {
    toggleUserActive(id, checked) {
      if (checked === true) {
        this.patchUser(id);
      } else {
        this.deleteUser(id);
      }
    },
    async patchUser(userId) {
      this.patchUserStatus = PENDING;
      try {
        const res = await patchUser(userId);
        this.patchUserStatus = SUCCESS;
      } catch (err) {
        this.patchUserStatus = ERROR;
        console.error(err);
      }
    },
    async deleteUser(userId) {
      this.deleteUserStatus = PENDING;
      try {
        const res = await deleteUser(userId);
        this.deleteUserStatus = SUCCESS;
      } catch (err) {
        this.deleteUserStatus = ERROR;
        console.error(err);
      }
    },
    async fetchUser() {
      this.fetchUserStatus = PENDING;
      const order = this.listOrder
        ? {
            by: this.listOrder.by,
            direction: this.listOrder.direction,
          }
        : undefined;
      try {
        const res = await fetchUser({
          params: {
            order,
            ...this.reqPagination,
            filters: {
              idClinicHealthSystem: +this.$route.params.idClinicHealthSystem,
            },
            include: ["clinic"],
          },
        });

        this.Users = res.data.users;
        this.pagination = res.data.pagination;

        this.Users.forEach((element) => {
          this.USER_ROLES.forEach((userRole) => {
            if (element.idUserRole == userRole.id) {
              element.role = userRole.name;
            }
          });
          if (!element.role) {
            element.role = element.idUserRole;
          }
        });
        this.fetchUserStatus = SUCCESS;
      } catch (err) {
        this.fetchUserStatus = ERROR;
        console.error(err);
      }
    },
    openManageUsersClinicalHealthSystemsOverlay(overlayTitle, user) {
      this.componentKey = this.componentKey + 1;
      if (user == null) {
        this.showDoctor = {
          first: "",
          last: "",
          email: "",
          details: "",
        };
      } else {
        this.showDoctor = user;
        this.showDoctor.idClinic = user.clinic.id;
      }
      this.isManageUsersClinicalHealthSystemsOverlayOpen = true;
      this.overlayTitle = overlayTitle;
    },
    closeManageUsersClinicalHealthSystemsOverlay() {
      this.fetchUser();
      this.isManageUsersClinicalHealthSystemsOverlayOpen = false;
    },
  },
  created() {
    this.fetchUser();
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/components/step-subtitle.scss";
.users {
  .td {
    &:nth-child(1) {
      width: math.percentage(math.div(402, $tabContentCardWidth));
    }
    &:nth-child(2) {
      width: math.percentage(math.div(344, $tabContentCardWidth));
    }
    &:nth-child(3) {
      width: math.percentage(math.div(200, $tabContentCardWidth));
    }
    &:nth-child(4) {
      width: math.percentage(math.div(234, $tabContentCardWidth));
    }
  }
}
.content-wrapper {
  position: relative;
  padding-bottom: 40px;
}
.router-view-card {
  padding-top: 0;
  position: relative;
  width: 100%;
  z-index: 1;
}
.table .tr > .td {
  display: flex;
  align-items: center;
}
.table .td .btn {
  width: 100%;
}
</style>
