// @ts-nocheck
import axios from "axios";
import { createToast } from "mosha-vue-toastify";

// let numberOfAjaxCallPending = 0;

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  // headers: {},
});
// axiosInstance.defaults.headers.common['SOMETHING'] = 'something';

// axiosInstance.interceptors.request.use((response) => {
//   numberOfAjaxCallPending++;
//   console.log("API calls: " + numberOfAjaxCallPending);
//   return response;
// });
axiosInstance.interceptors.response.use(
  (response) => {
    // numberOfAjaxCallPending--;
    // console.log("API calls: " + numberOfAjaxCallPending);

    if (response?.data?.message) {
      // temporarely disable toasts until we decide how to display them
      // createToast(response.data.message, {
      //   timeout: 4000,
      //   type: "info",
      //   position: "bottom-right",
      // });
    }
    return response;
  },
  (error) => Promise.reject(error)
);

export default axiosInstance;
