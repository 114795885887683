<template lang="pug">
PopupCardSlot(
  :isOpen="isDeleteSchedulePopupOpen",
  maxWidth="675px"
)
  .delete-schedule-popup
    h1 Are you sure you want to delete this slot?
    p Please confirm to continue
    .popup-btns
      BaseBtn.x-lg.red-outline(@click="closeDeleteSchedulePopup") CANCEL
      BaseBtn.x-lg.green(@click.prevent="deleteSchedule") YES, CONTINUE


.content-wrapper
  .card.router-view-card
    section.all-clinics
    TableTitle(title="All Schedules")
      template(#actions)
        select.table-action-selector.table-action-selector--doctor(
          :title="doctorTitle",
          style="margin-right: 24px;"
          @change="setFilterValueDoctor($event.target.value)"
        )
          option(value="") DOCTOR'S NAME
          option(
            v-for="doctor in activeDoctors", :key="doctor.id",
            :value="doctor.id"
          ) {{ getDoctorFormatted(doctor) }}
        DateSelector(@filterByDate="filterByDate" :minDate="new Date()")
        BaseBtn.lg.blue(@click.prevent="openAddScheduleOverlay") ADD NEW SCHEDULE
    .table
      .th
        .td(@click.prevent="changeListOrder('DoctorName', fetchClinicSchedules)" style="cursor: pointer;") Doctor Name
          i(style="font-weight: bolder;") {{ DoctorNameColumnSortingIcon }}
        .td Insurance
        .td(@click.prevent="changeListOrder('DateAndTime', fetchClinicSchedules)" style="cursor: pointer;") Appt. Time
          i(style="font-weight: bolder;") {{ DateAndTimeColumnSortingIcon }}      
        .td(@click.prevent="changeListOrder('CommunityCareName', fetchClinicSchedules)" style="cursor: pointer;") Referred by
          i(style="font-weight: bolder;") {{ CommunityCareNameColumnSortingIcon }}
        .td Appt. Scheduled
      .tr(
        v-for="schedule in schedules",
        :key="schedule.id"
      )
        //-  @click="openAppointmentRecordOverlay"
        .td.bold {{schedule.doctor.first}} {{schedule.doctor.last}}
        .td
          base-icon(name="no", v-if="!schedule.insuranceRequired")
          base-icon(name="yes", v-else)
        .td 
          div {{getDateFromUnixFormatted(schedule.availableAt)}}
          .second-row-md-grey {{getTimeFromUnixFormatted(schedule.availableAt)}}
        .td {{schedule.communityCareOrganization.name || ""}}
        .td.alignButtonRight
          base-icon(name="no", v-if="!schedule.communityCareOrganization.name")
          base-icon(name="yes", v-else)

          BaseBtn.sm.redFill(
            @click.prevent="openDeleteSchedulePopup(schedule.id)",
            v-if="!schedule.communityCareOrganization.name") DELETE THIS SLOT

          BaseBtn.sm.blue(
            @click.prevent="openReferralRecordOverlay",
            v-else) VIEW REFERRAL
      Pagination(
        v-if="pagination  && schedules != null && schedules.length",
        @changePagination="changePagination",
        :pagination="pagination",
        :reloadFn="fetchClinicSchedules"
      )
      .no-results(v-if="schedules != null && !schedules.length") 
        base-icon(name="exclamation") 
        span No matching records
    .actions-below-table-container


ReferralRecordOverlay(
  :isOpen="isReferralRecordOverlayOpen",
  tabType="confirmed"
  @closeReferralRecordOverlay="closeReferralRecordOverlay",
)
//- AppointmentNotesOverlay(
//-   :isOpen="isAppointmentNotesOverlayOpen",
//-   @closeAppointmentNotesOverlay="closeAppointmentNotesOverlay"
//- )

AddScheduleOverlay(
  :isOpen="isAddScheduleOverlayOpen",
  @closeAddScheduleOverlay="closeAddScheduleOverlay"
)
</template>

<script>
import { defineComponent } from "vue";
import TableTitle from "@/components/TableTitle.vue";
import DateSelector from "@/components/DateSelector.vue";
import Pagination from "@/components/Pagination.vue";
import ReferralRecordOverlay from "@/components/primary-care-clinic/patient-referrals/overlays/ReferralRecordOverlay";
import AddScheduleOverlay from "@/components/primary-care-clinic/schedules/overlays/AddScheduleOverlay";
import PopupCardSlot from "@/components/PopupCardSlot.vue";
import {
  deleteClinicSchedule,
  getClinicSchedules,
} from "@/api/patientReferral";
import { mapGetters } from "vuex";
import listPagesMixin from "@/mixins/listPagesMixin";
import {
  getDateFromUnixFormatted,
  getTimeFromUnixFormatted,
} from "@/helpers/formatters";
import { getClinicDoctors } from "@/api/clinicApi";
import { getDoctorFormatted } from "@/helpers";
import sortPrimaryCareClinicSchedules from "@/mixins/sortPrimaryCareClinicSchedules";

export default defineComponent({
  components: {
    TableTitle,
    DateSelector,
    Pagination,
    AddScheduleOverlay,
    PopupCardSlot,
    ReferralRecordOverlay,
  },

  mixins: [listPagesMixin, sortPrimaryCareClinicSchedules],

  computed: {
    ...mapGetters(["getClinicId"]),
    activeDoctors() {
      return this.doctors?.filter((doctor) => !doctor.disabled);
    },
  },

  data() {
    return {
      isReferralRecordOverlayOpen: false,
      isAppointmentNotesOverlayOpen: false,
      isAddScheduleOverlayOpen: false,
      isDeleteSchedulePopupOpen: false,
      schedules: [],
      searchKeyword: null,
      doctors: [],
      idDoctor: null,
      date: null,
      doctorTitle: "",
      scheduleToDelete: 0,
    };
  },

  methods: {
    getDoctorFormatted,
    getDateFromUnixFormatted,
    getTimeFromUnixFormatted,
    openReferralRecordOverlay() {
      this.isReferralRecordOverlayOpen = true;
    },
    closeReferralRecordOverlay() {
      this.isReferralRecordOverlayOpen = false;
    },
    openAddScheduleOverlay() {
      this.isAddScheduleOverlayOpen = true;
    },
    closeAddScheduleOverlay() {
      this.isAddScheduleOverlayOpen = false;
      this.fetchClinicSchedules();
    },
    openDeleteSchedulePopup(id) {
      this.scheduleToDelete = id;
      this.isDeleteSchedulePopupOpen = true;
    },
    closeDeleteSchedulePopup() {
      this.isDeleteSchedulePopupOpen = false;
    },
    async deleteSchedule() {
      try {
        await deleteClinicSchedule(this.scheduleToDelete);
        this.closeDeleteSchedulePopup();
        this.fetchClinicSchedules();
      } catch (error) {
        console.error(error);
      }
    },
    async fetchClinicSchedules() {
      try {
        if (this.getClinicId > 0) {
          const order = this.listOrder
            ? {
                by: this.listOrder.by,
                direction: this.listOrder.direction,
              }
            : null;
          const { data } = await getClinicSchedules({
            params: {
              ...this.reqPagination,
              order,
              page: this.reqPagination.page || 1,
              idClinic: +this.getClinicId,
              include: ["patientReferral", "clinic", "doctor", "communityCare"],
              isAvailable: "All",
              availableAt: this.date,
              searchKeyword: this.searchKeyword,
              idDoctor: this.idDoctor ?? null,
            },
          });

          this.pagination = data.pagination;
          this.schedules = data.schedules;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getDoctors() {
      try {
        if (this.getClinicId > 0) {
          const {
            data: { doctors },
          } = await getClinicDoctors(this.getClinicId);
          this.doctors = doctors;
        }
      } catch (error) {
        console.error(error);
      }
    },
    setFilterValueDoctor(value) {
      if (value) {
        this.idDoctor = value;
        this.doctors.forEach((doctor) => {
          if (doctor.id == value) {
            this.doctorTitle = this.getDoctorFormatted(doctor);
          }
        });
      } else {
        this.idDoctor = null;
      }
      this.fetchClinicSchedules();
    },
    filterByDate(date) {
      this.date = date;
      this.fetchClinicSchedules();
    },
  },

  created() {
    this.fetchClinicSchedules();
    this.getDoctors();
  },

  watch: {
    getClinicId: function () {
      this.fetchClinicSchedules();
      this.getDoctors();
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/components/actions-below-table-container.scss";
.tr {
  cursor: pointer;
}
.card {
  position: relative;
}
.actions-below-table-container {
  top: calc(100% + 44px);
}
.td {
  &:nth-child(1) {
    width: math.percentage(math.div(286, $tabContentCardWidth));
  }
  &:nth-child(2) {
    width: math.percentage(math.div(148, $tabContentCardWidth));
  }
  &:nth-child(3) {
    width: math.percentage(math.div(170, $tabContentCardWidth));
  }
  &:nth-child(4) {
    width: math.percentage(math.div(218, $tabContentCardWidth));
  }
  &:nth-child(5) {
    width: math.percentage(math.div(358, $tabContentCardWidth));
  }
  &.alignButtonRight {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
  .btn {
    min-width: 196px;
  }
}
.delete-schedule-popup {
  max-width: 555px;
  margin: 0 auto;
  h1 {
    width: 100%;
    max-width: 549px;
    margin: 39px auto 30px;
    font-size: 28px;
    font-weight: 700;
    line-height: 1.21;
    letter-spacing: 0.14px;
    text-align: center;
  }
  p {
    margin-top: 0;
    margin-bottom: 32px;
    font-size: 16px;
    line-height: 1.19;
    letter-spacing: 0.23px;
    text-align: center;
  }
  .popup-btns {
    margin-bottom: 39px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .btn {
      width: 260px;
    }
  }
}
</style>
<style>
.main-container {
  padding-bottom: 100px;
}
</style>
