<template lang="pug">
span.box-link-sm
  span.left
    span.text {{text}}
  .span.right
    span.number {{number}}
    base-icon(name="chevron-md")
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  components: {},

  props: {
    text: {
      type: String,
      required: true,
    },
    number: {
      type: [Number, String],
      required: true,
    },
  },

  data() {
    return {};
  },

  computed: {},

  watch: {
    // keywords: {
    //   handler: "getRows",
    //   immediate: true
    // }
  },

  methods: {},
});
</script>

<style scoped lang="scss">
.box-link-sm {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 97px;
  padding: 29px 15px;
  color: #000;
  background-color: rgba($primaryBgLightGrey, 0);
  border-radius: 2px;
  transition: background-color 0.3s linear;
  &:hover {
    background-color: rgba($primaryBgLightGrey, 1);
  }
  &::after {
    content: "";
    display: block;
    position: absolute;
    bottom: 10px;
    left: 15px;
    right: 15px;
    height: 5px;
    border-radius: 5px;
    background-color: $primaryLightBlue;
  }
  .left {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-right: 10px;
    overflow: hidden;
  }
  .text {
    display: block;
    font-size: 14px;
    line-height: 1.29;
    letter-spacing: 0.07px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    top: -4px;
    .svg-icon--chevron-md {
      top: 0;
      margin-left: 13px;
      font-size: 15px;
      transform: rotate(-90deg);
    }
  }
  .number {
    display: block;
    font-size: 24px;
    line-height: 1.21;
    letter-spacing: 0.12px;
    color: $primaryLightBlue;
  }
}
</style>
