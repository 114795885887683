
import { defineComponent } from "vue";
import HeaderMain from "@/components/HeaderMain.vue";
import SidebarMain from "@/components/SidebarMain.vue";
import SessionCountDownModal from "@/components/SessionCountDownModal.vue";
import SessionExpiredModal from "@/components/SessionExpiredModal.vue";
import { mapGetters } from "vuex";

export default defineComponent({
  components: {
    HeaderMain,
    SidebarMain,
    SessionCountDownModal,
    SessionExpiredModal,
  },
  computed: {
    ...mapGetters(["showSessionCountdownModal", "showSessionExpiredModal"]),
  },
  data() {
    return {
      noWrapperRoutes: [
        "log-in-page",
        "forgot-password-page",
        "reset-password",
      ],
    };
  },
});
