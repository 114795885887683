<template lang="pug">
div
  .form-group(:class="{ 'form-group__invalid': v$.inputValue.$errors.length }")
    input(
      type="text" 
      placeholder="MM/DD/YYYY"
      @change="sendAnswer(value, $event.target.value)" 
      v-model="inputValue"
      v-maska="'##/##/####'"
      @blur="v$.inputValue.$touch()"

    )
    .form-group__errors(v-if="v$.inputValue.$dirty && v$.inputValue.$errors.length")
      .form-group__error Field is required!

</template>
<script>
import { defineComponent } from "vue";
import useVuelidate from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import { maska } from "maska";

export default defineComponent({
  directives: { maska },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    let args = {
      inputValue: {},
    };
    if (this.value.required) {
      args = {
        inputValue: {
          required,
          minLength: minLength(10),
          function() {
            let unixDistributed = new Date(this.inputValue).getTime();
            if (!unixDistributed) {
              return false;
            }
            return true;
          },
        },
      };
    }
    return args;
  },
  data() {
    return {
      inputValue: "",
    };
  },
  methods: {
    sendAnswer(value, eventTarget) {
      this.$emit("emitAnswer", value, eventTarget);
    },
  },
  emits: ["emitAnswer"],

  props: {
    value: {
      type: Object,
      required: true,
    },
    default: {
      type: String,
      required: false,
    },
  },
  watch: {
    default: function (newVal) {
      this.inputValue = newVal;
    },
  },
  created() {
    if (this.value === null) return;
    this.inputValue = this.default;
  },
});
</script>
<style scoped>
.form-group input {
  width: 192px;
}
</style>
