<template lang="pug">
SideOverlaySlot(
  :isOpen="isOpen",
  title="LINKED COMMUNITY SYSTEMS"
  @closeOverlay="closeOverlay"
)
  template(#contentTop)
    .side-overlay-blue-box
      p.strong-blue Changes are saved automatically.
    .links-container
      .not-linked
        .label Not Linked
        BaseBtn.sm.blue-outline(v-for="unlinkedCommunity in unlinkedCommunities", :key="unlinkedCommunity.id" @click.prevent="createWorkWith(unlinkedCommunity.id)" ) {{ unlinkedCommunity.name }}
        
      .linked
        .label Linked
        BaseBtn.sm.blue(v-for="linkedCommunity in linkedCommunities", :key="linkedCommunity.id" @click.prevent="deleteWorkWith(linkedCommunity.id)" ) {{ linkedCommunity.name }}
</template>

<script>
import { defineComponent } from "vue";
import SideOverlaySlot from "@/components/overlays/SideOverlaySlot.vue";
import { fetchCommunityCareOrganizations } from "@/api/communityOrganization";
import {
  createWorkWithCommunity,
  deleteWorkWithCommunity,
} from "@/api/clinicApi";

export default defineComponent({
  components: { SideOverlaySlot },
  emits: ["closeManageLinkedCommunitySystemsOverlay"],

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    clinicId: {
      required: false,
    },
  },

  data() {
    return {
      linkedCommunities: [],
      unlinkedCommunities: [],
    };
  },

  methods: {
    closeOverlay() {
      this.$emit("closeManageLinkedCommunitySystemsOverlay");
    },
    async createWorkWith(communityId) {
      try {
        await createWorkWithCommunity(this.clinicId, communityId);
        this.getLinkedCommunities();
      } catch (err) {
        console.log(err);
      }
    },
    async deleteWorkWith(communityId) {
      try {
        await deleteWorkWithCommunity(this.clinicId, communityId);
        this.getLinkedCommunities();
      } catch (err) {
        console.log(err);
      }
    },
    async getLinkedCommunities() {
      try {
        const {
          data: { communityCareOrganizations },
        } = await fetchCommunityCareOrganizations({
          params: {
            include: "idClinics",
          },
        });

        this.linkedCommunities = communityCareOrganizations.filter((cco) =>
          cco.idClinics.includes(this.clinicId)
        );
        this.unlinkedCommunities = communityCareOrganizations.filter(
          (cco) => !cco.idClinics.includes(this.clinicId)
        );
      } catch (error) {
        console.error(error);
      }
    },
  },

  mounted() {
    this.getLinkedCommunities();
  },
});
</script>

<style scoped lang="scss">
.links-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.not-linked,
.linked {
  flex-grow: 0;
  flex-shrink: 0;
  width: 48.1%;
  .label {
    display: block;
    margin-bottom: 31px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
  }
  .btn {
    display: block;
    margin-bottom: 25px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
