<template lang="pug">
FullScreenOverlaySlot(
  :isOpen="isOpen",
  headerIcon="icc-logo",
  title="SETTINGS"
  @closeOverlay="closeOverlay"
)
  template(#breadcrumbs)
    h1.breadcrumbs-title IL CARE CONTINUUM

  template(#content)
    .card.upload-logo
      h2.title.title--md-th in UPLOAD LOGO
      .upload-logo__container
        .upload-area
          img(v-if="logo" :src="logo")
          h2(v-else) NO LOGO UPLOADED YET
        UploadFileButton(
          :allowedFileTypes="['image/*']"
          :styleClasses="['btn', 'lg', 'blue']"
          storageKey="settings_logo"
          @fileLoaded="setLogoUrl"
        ) UPLOAD
    .card.help-and-resources
      h2.title.title--md-thin General Help &amp; Resources
      .help-and-resources__container
        .form-group
          label(for="user-support-text") User support text
          textarea#user-support-text(v-model="userSupportText")
        .form-group
          label(for="resource-text") Resources Text
          textarea#resource-text(v-model="resourcesText")
          
    .card.upload-additional-resources
      h2.title.title--md-thin UPLOAD ADDITIONAL RESOURCES
      .already-up-resources-container
        .already-up-resource(v-for="file in loadedFiles" :key="file.id")
          a(href="#", @click.prevent="openFile(file.downloadUrl)") {{file.title}} ( {{file.file.name}} )
          base-icon(@click.prevent="markToBeRemoved(file)" name="x")
      .confirmed-to-upload-resources-container
        .confirmed-to-upload-resource(v-for="confirmedFile in filesConfirmedToUpload" :key="confirmedFile.key")
          a(href="#" @click.prevent="preview(confirmedFile.url)") {{confirmedFile.title}} ( {{confirmedFile.name}} )
          base-icon(@click.prevent="removeConfirmed(confirmedFile.key)" name="x")
      .upload-additional-resources__container
        .form-group
          label(for="resource-title") Resource Title
          input#resource-title(type="text" v-model="extraResourceTitle")
        UploadAndPreviewFile(
          :buttonStyleClasses="['btn', 'lg', !extraResourceTitle ? 'grey disabled' : 'blue']"
          :storageKey="additionalResourceStorageKey"
          :key="forcedRerenderTimes"
          @fileLoaded="forceRerender"
          :disabled="!extraResourceTitle"
        )
      BaseBtn.btn.lg.blue(
        :disabled="!extraResourceTitle"
        v-if="anAdditionalResourceFileIsSelected"
        @click.prevent="confirmFileToUpload"
      ) Confirm Upload
  template(#footer)
    BaseBtn.btn.lg.red-outline(
      href="#",
      @click.prevent="closeOverlay"
    ) CANCEL CHANGES
    BaseBtn.btn.lg.green(href="#" @click.prevent="updateSettings") SAVE AND EXIT

</template>

<script>
import { defineComponent } from "vue";
import FullScreenOverlaySlot from "@/components/overlays/FullScreenOverlaySlot.vue";
import UploadAndPreviewFile from "@/components/UploadAndPreviewFile";
import UploadFileButton from "@/components/UploadFileButton";
import {
  fetchSettings,
  fetchLogo,
  uploadLogo,
  uploadFile as uploadResourceFile,
  updateSettings,
  fetchSettingsFiles,
} from "@/api/settingsApi";
import { fetchFileById, uploadFile } from "@/api/filesApi";
import { preview } from "@/helpers/preview";
import { getFileSignedUrl } from "@/helpers";
import sanitizeHtml from "sanitize-html";

export default defineComponent({
  components: {
    FullScreenOverlaySlot,
    UploadAndPreviewFile,
    UploadFileButton,
  },

  emits: ["closeSettingsOverlayOverlay"],

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      userSupportText: "",
      resourcesText: "",
      logo: "",
      extraResourceTitle: "",
      id: null,
      additionalResourceStorageKey: "settings_resources_additional", //key for current selected but not confirmed file item to upload
      confirmedFilesToUploadStorageKey: "additional_resources_keys", //key for storage item that holds the list of confirmed file keys, as an string array.
      forcedRerenderTimes: 0,
      additionalResourceFiles: [],
      filesToBeRemoved: [],
    };
  },

  computed: {
    anAdditionalResourceFileIsSelected() {
      //add force re-render times as dependency for this computed;
      this.forcedRerenderTimes;
      return !!sessionStorage.getItem(this.additionalResourceStorageKey);
    },
    filesConfirmedToUpload() {
      this.forcedRerenderTimes;

      const keys =
        JSON.parse(
          sessionStorage.getItem(this.confirmedFilesToUploadStorageKey)
        ) || [];
      return keys.map((key) => ({
        key,
        ...JSON.parse(sessionStorage.getItem(key)),
      }));
    },
    loadedFiles() {
      return this.additionalResourceFiles.filter(
        (file) =>
          !this.filesToBeRemoved.some(
            (fileMarkedToBeRemoved) => fileMarkedToBeRemoved.id === file.id
          )
      );
    },
  },

  methods: {
    async fetchSettings() {
      const response = await fetchSettings();
      if (response.data && response.data.success) {
        const { userSupportText, resourcesText, id } = response.data.settings;
        this.userSupportText = userSupportText;
        this.resourcesText = resourcesText;
        this.id = id;
      }

      const filesResponse = await fetchSettingsFiles();
      if (filesResponse.data && filesResponse.data.success) {
        this.additionalResourceFiles = filesResponse.data.files;
        const completeFiles = await Promise.all(
          this.additionalResourceFiles.map((resource) =>
            fetchFileById(resource.idFile)
          )
        );
        this.additionalResourceFiles = this.additionalResourceFiles.map(
          (resource) => {
            const fileComplete = completeFiles.find(
              (response) => response?.data?.file?.id === resource.idFile
            );
            const file = fileComplete?.data?.file;
            file.token = fileComplete?.data?.token;
            const downloadUrl = getFileSignedUrl(file);
            if (downloadUrl) {
              return { ...resource, downloadUrl };
            } else {
              return resource;
            }
          }
        );
      }
    },
    openFile(src) {
      preview(src);
    },
    async fetchLogo() {
      try {
        const response = await fetchLogo();
        const {
          data: { token, file },
        } = await fetchFileById(response.data.logo.file.id);
        this.logo = getFileSignedUrl({
          token,
          ...file,
        });
      } catch (error) {
        console.log(error);
      }
    },
    async uploadExtraResources() {
      const keys = JSON.parse(
        sessionStorage.getItem(this.confirmedFilesToUploadStorageKey) || "[]"
      );

      const files = await Promise.all(
        keys.map(async (key) => {
          try {
            const { file } = await uploadFile(key);
            const fileInStorage = JSON.parse(sessionStorage.getItem(key));
            sessionStorage.removeItem(key);
            return {
              ...file,
              title: fileInStorage.title,
            };
          } catch (error) {
            console.log(error);
          }
        })
      );
      sessionStorage.removeItem(this.confirmedFilesToUploadStorageKey);

      await uploadResourceFile({
        filesAdded: [...files],
        filesRemoved: [...this.filesToBeRemoved],
      });
    },
    async updateSettings() {
      try {
        await uploadLogo("settings_logo");
        sessionStorage.removeItem("settings_logo");

        await this.uploadExtraResources();

        await updateSettings({
          settings: {
            id: this.id,
            userSupportText: this.getSecureHtml(this.userSupportText),
            resourcesText: this.getSecureHtml(this.resourcesText),
          },
        });
      } catch (error) {
        console.log(error);
      }

      this.closeOverlay();
    },
    getSecureHtml(unsafe) {
      return sanitizeHtml(unsafe);
    },
    closeOverlay() {
      this.$emit("closeSettingsOverlayOverlay");
      //clear storage
      sessionStorage.removeItem("settings_logo");
      sessionStorage.removeItem(this.additionalResourceStorageKey);
      JSON.parse(
        sessionStorage.getItem(this.confirmedFilesToUploadStorageKey) || "[]"
      ).map((key) => sessionStorage.removeItem(key));
    },
    setLogoUrl(url) {
      this.logo = url;
    },
    forceRerender() {
      this.forcedRerenderTimes++;
    },
    confirmFileToUpload() {
      const confirmedFilesToUploadKeys = JSON.parse(
        sessionStorage.getItem(this.confirmedFilesToUploadStorageKey) || "[]"
      );
      const confirmedFileKey =
        this.additionalResourceStorageKey + "_" + new Date().getTime();
      const currentFileToConfirm = {
        ...JSON.parse(
          sessionStorage.getItem(this.additionalResourceStorageKey)
        ),
        title: this.extraResourceTitle,
      };
      //pass current to an indexable key
      sessionStorage.setItem(
        confirmedFileKey,
        JSON.stringify(currentFileToConfirm)
      );
      //update list of confirmed files
      sessionStorage.setItem(
        this.confirmedFilesToUploadStorageKey,
        JSON.stringify([...confirmedFilesToUploadKeys, confirmedFileKey])
      );
      //remove current item because is already in a new key
      sessionStorage.removeItem(this.additionalResourceStorageKey);
      this.extraResourceTitle = "";
      //re render view
      this.forceRerender();
    },
    preview(src) {
      preview(src);
    },
    removeConfirmed(key) {
      sessionStorage.removeItem(key);
      const keyList = JSON.parse(
        sessionStorage.getItem(this.confirmedFilesToUploadStorageKey)
      ).filter((keyFromList) => keyFromList !== key);

      sessionStorage.setItem(
        this.confirmedFilesToUploadStorageKey,
        JSON.stringify(keyList)
      );
      this.forceRerender();
    },
    markToBeRemoved(file) {
      this.filesToBeRemoved = [...this.filesToBeRemoved, file];
    },
  },

  created() {
    this.fetchSettings();
    this.fetchLogo();
  },
});
</script>

<style scoped lang="scss">
.card {
  padding: 35px $secondaryCardsSidePadding 20px $secondaryCardsSidePadding;
}
.help-and-resources {
  // https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/
  width: 100%;
  &__container {
    display: flex;
    justify-content: space-between;
    & > * {
      width: 48.42%;
    }
  }
  textarea {
    min-height: 250px;
  }
}
.upload-additional-resources {
  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .form-group {
    width: 539px;
  }
}

// Page specific styles----------------------------------------
.breadcrumbs-title {
  margin: 0;
  color: $primaryLightBlue;
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.12px;
}
</style>
