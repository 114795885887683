<template lang="pug">
SideOverlaySlot(
  :isOpen="isOpen",
  title="ADD NEW SCHEDULE",
  @closeOverlay="closeOverlay"
)
  template(#contentTop)
    form#add-new-schedule(
      v-if="!showScheduleAdded",
      @submit.prevent="addNewSchedule"
    ) 
      .form-group
        label(for="user-type") Doctor's Name (Required)
        select#user-type()
          option Select
          option option 2
          option option 3
      .columns
        .column.is-half
          .form-group
            label(for="date") Date
            input#date(type="text" placeholder="MM/DD/YYYY")
        .column.is-half
          .form-group
            label(for="time") Time
            select#time()
              option Select
              option option 2
              option option 3
      .columns
        .column.is-half
          .form-group
            label(for="recurring") Recurring
            select#recurring()
              option Select
              option option 2
              option option 3
        .column.is-half
          .form-group
            label(for="insurance") Insurance
            select#insurance()
              option Select
              option option 2
              option option 3
      

    .overlay-show-success(v-else="")
      section.patient-section
        p <strong>Doctor’s Name: DR. Mary Jane</strong>
        p <strong>Date: Mar 17 2021</strong>
        p <strong>Time: 06:45PM</strong>
        p <strong>Recurring: WEEKLY</strong>
        p <strong>Insurance: INSURED</strong>
      p Schedule successfully added
  template(#footer)
    .flex.flex--space-between(v-if="!showScheduleAdded")
      BaseBtn.x-lg.red-outline(
        href="#",
        @click.prevent="closeOverlay"
      ) CANCEL
      BaseBtn.x-lg.green(
        type="submit",
        form="add-new-schedule"
        ref="#",
        @click.prevent="addNewSchedule"
      ) CONTINUE
    .flex.flex--flex-end(v-else="")
      BaseBtn.x-lg.blue(@click.prevent="resetAddNewSchedule") CREATE NEW SCHEDULE
</template>

<script>
import { defineComponent } from "vue";
import SideOverlaySlot from "@/components/overlays/SideOverlaySlot.vue";

export default defineComponent({
  components: { SideOverlaySlot },
  emits: ["closeAddScheduleOverlay"],

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      showScheduleAdded: false,
    };
  },
  methods: {
    closeOverlay() {
      this.showScheduleAdded = false;
      this.$emit("closeAddScheduleOverlay");
    },
    resetAddNewSchedule(){
        this.showScheduleAdded = false;
    },
    addNewSchedule() {
      //TODO: API CALL
      this.showScheduleAdded = true;
    },
  },
});
</script>

<style scoped lang="scss">
.patient-section {
  margin-bottom: 0;
  padding: 37px 24px 25px;
  background-color: $primaryBgBlue;
  margin-bottom:53px;
  margin-top:26px;
  p {
    margin: 0 0 12px;
    font-size: 21px;
    font-weight: 500;
    line-height: 1.1;
    color: $primaryLightBlue;
    overflow: hidden;
    strong {
      font-weight: 700;
    }
  }
}
form#add-new-schedule {
  margin: 12px 0 20px;
  .columns{
    margin-bottom:0px;
    .column{
      padding-bottom:0px;
    }
  }
}
.overlay-show-success > p{
  max-width:400px;
}
</style>
