<template lang="pug">

.nav-main(
  :class="{'nav-main--expanded' : isNavMainExpanded, 'is-submenu-visible': isSubmenuVisible}",
  @mouseover="isNavMainExpanded = true",
  @mouseleave="closeSubmenu",
)
  .nav-main__primary
    ul(v-if="isSuperAdmin")
      li
        router-link(:to="{name: 'organizations'}", title="Home")
          base-icon(name="home")
          .txt Home

    ul(v-if="isSuperAdmin")
      li 
        router-link(:to="{name: 'organizations'}", title="Organizations")
          base-icon(name="organizations")
          span.txt Organizations
      li 
        router-link(:to="{name: 'system-settings'}", title="Settings")
          base-icon(name="settings")
          span.txt Settings
      li 
        router-link(:to="{name: 'users-super-admin'}", title="Users")
          base-icon(name="users")
          span.txt Users
      li 
        router-link(:to="{name: 'reports'}")
          base-icon(name="reports")
          span.txt Reports
      li 
        router-link(:to="{name: 'all-actions'}", title="Logs")
          base-icon(name="logs")
          span.txt Logs

    ul(v-if="isProvider && !isProviderManager")
      li
        a(@click.prevent="goToProviderDashboard")
          base-icon(name="home")
          .txt Dashboard
    ul(v-if="isProviderManager")
      li 
        a(@click.prevent="goToProviderGroupUsers")
          base-icon(name="users")
          span.txt Users
      li 
        router-link(:to="{name: 'reports'}")
          base-icon(name="reports")
          span.txt Reports
      li 
        router-link(:to="{name: 'all-actions'}", title="Logs")
          base-icon(name="logs")
          span.txt Logs
          
    ul(v-if="isClinicHealthSystemManager")
      li
        a(@click.prevent="goToAllClinicsView")
          base-icon(name="home")
          .txt Home
    ul
      li(v-if="isClinicHealthSystemManager") 
        a(@click.prevent="goToAllClinicsView")
          base-icon(name="clinics")
          span.txt Clinics
      li(v-if="isClinicHealthSystemManager") 
        a(@click.prevent="goToUsersClinicHealthSystem", title="Users")
          base-icon(name="users")
          span.txt Users
      li(v-if="isClinicHealthSystemManager") 
        router-link(:to="{name: 'reports'}")
          base-icon(name="reports")
          span.txt Reports
      li(v-if="isClinicHealthSystemManager") 
        router-link(:to="{name: 'all-actions'}", title="Logs")
          base-icon(name="logs")
          span.txt Logs
    
    ul(v-if="isHospitalManager || isHospitalStaff || isHospitalDoctor")
      li
        a(@click.prevent="navigateAccordingToRole", title="Home")
          base-icon(name="home")
          .txt Home
    ul(v-if="isHospitalManager || isHospitalStaff || isHospitalDoctor")
      ul(v-if="clinicOrHospitalHasSpecialtiesToShow")
      li
        a(title="Dashboards", @click.prevent="showSubmenu")
          base-icon(name="dashboards")
          span.txt Dashboards
      li(v-if="isHospitalManager")
        a(@click.prevent="goToHospitalSearchPatient", title="Search patient")
          base-icon(name="search")
          span.txt Search patient
      li(v-if="isHospitalManager")
        a(@click.prevent="goToHospitalUsers", title="Users")
          base-icon(name="users")
          span.txt Users
      li(v-if="isHospitalManager")
        router-link(:to="{name: 'reports'}")
          base-icon(name="reports")
          span.txt Reports
      li(v-if="isHospitalManager")
        router-link(:to="{name: 'all-actions'}", title="Logs")
          base-icon(name="logs")
          span.txt Logs

    ul(v-if="isClinicStaff || isClinicStaffInTraining || isClinicDoctor")
      li
        a(@click.prevent="goToLastClinicVisited")
          base-icon(name="home")
          .txt Home 
    ul(v-if="isClinicStaff || isClinicStaffInTraining || isClinicDoctor")
      li(v-if="showClinicSpecialties")
        a(title="Dashboards", @click.prevent="showSubmenu")
          base-icon(name="dashboards")
          span.txt Dashboards
      li(v-if="aClinicIsSelected")
        router-link(:to="{name: 'clinics.patient-search', params: { idClinic: getClinicId }}", title="Search patient")
          base-icon(name="search")
          span.txt Search patient
      li(v-if="isClinicStaff && aClinicIsSelected")
        router-link(:to="{name: 'reports'}")
          base-icon(name="reports")
          span.txt Reports


    ul(v-if="isSubSystemCommunityOrganization")
      li
        router-link(:to="{name: 'home'}", title="Home")
          base-icon(name="home")
          .txt Home 
    ul(v-if="isSubSystemCommunityOrganization")
      li
        a(title="Dashboards" @click.prevent="showSubmenu")
          base-icon(name="dashboards")
          span.txt Dashboards
      li 
        router-link(:to="{name: 'primary-care-community-organization.patient-search', params: { idCommunity: getCCOId }}", title="Search patient")
          base-icon(name="search")
          span.txt Search patient
      li(v-if="isCommunityCareOrganizationManager")
        router-link(:to="{name: 'reports'}")
          base-icon(name="reports")
          span.txt Reports
      li(v-if="isCommunityCareOrganizationManager")
        a(@click.prevent="goToCommunityUsers", title="Users")
          base-icon(name="users")
          span.txt Users
      li(v-if="isCommunityCareOrganizationManager")
        router-link(:to="{name: 'all-actions'}", title="Logs")
          base-icon(name="logs")
          span.txt Logs
    ul
      li(v-if="isProvider && !isProviderManager")
        router-link(:to="{name: 'provider.notifications' , params: { idProvider: $route.params.idProvider }}", title="Notifications")
          base-icon(name="bell")
          div.counter 
            div.count {{getNotificationCount}}
          span.txt Notifications
      li(v-if="isHospitalManager || isHospitalDoctor")
        router-link(:to="{name: 'hospitals.notifications' , params: { idHospital: $route.params.idHospital }}", title="Notifications")
          base-icon(name="bell")
          div.counter 
            div.count {{getNotificationCount}}
          span.txt Notifications 
      li(v-if="(isClinicStaff || isClinicStaffInTraining) && aClinicIsSelected")
        router-link(:to="{name: 'clinics.notifications', params: { idClinic: $route.params.idClinic }}", title="Notifications")
          base-icon(name="bell")
          div.counter 
            div.count {{getNotificationCount}}
          span.txt Notifications 
      li(v-if="isCommunityCareOrganizationManager")
        router-link(:to="{name: 'primary-care-community-organization.notifications'}", title="Notifications")
          base-icon(name="bell")
          div.counter 
            div.count {{getNotificationCount}}
          span.txt Notifications 
      li
        router-link(  
          title="Help",
          :to="{name: 'resources-and-help'}"
        )
          base-icon(name="help")
          span.txt Help
      li
        a(href="#", title="Log out", @click.prevent="logOut")
          base-icon(name="logout")
          span.txt Log out

  .nav-main__secondary.upper(v-if="isSubSystemHospital")
    ul
      li
        a(href="#", title="back", @click.prevent="isSubmenuVisible = false")
          base-icon(name="chevron-md")
          .txt Back
    ul
      li
        .section-title
          base-icon(name="dashboards")
          span.txt Dashboards
    HospitalSpecialties(
      :hospital="hospital"
    )

  .nav-main__secondary(v-if="isSubSystemClinic")
    ul
      li
        a(href="#", title="back", @click.prevent="isSubmenuVisible = false")
          base-icon(name="chevron-md")
          .txt Back
    ul
      li
        .section-title
          base-icon(name="dashboards")
          span.txt Dashboards
    ClinicSpecialties(
      :clinic="clinic"
    )
  .nav-main__secondary(v-if="isSubSystemCommunityOrganization")
    ul
      li
        a(href="#", title="back", @click.prevent="isSubmenuVisible = false")
          base-icon(name="chevron-md")
          .txt Back
    ul
      li
        .section-title
          base-icon(name="dashboards")
          span.txt Dashboards
    CommunitySpecialties

</template>

<script>
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import ClinicSpecialties from "@/components/clinics/dynamic-sidebar-menu/Specialties";
import HospitalSpecialties from "@/components/hospitals/dynamic-sidebar-menu/Specialties";
import CommunitySpecialties from "@/components/community-care-organization/dynamic-sidebar-menu/Specialties";

export default defineComponent({
  components: {
    ClinicSpecialties,
    HospitalSpecialties,
    CommunitySpecialties,
  },

  data() {
    return {
      isNavMainExpanded: false,
      isSubmenuVisible: false,
    };
  },

  computed: {
    ...mapGetters([
      "getUserRoleContext",
      "getShowClinicDashboardsMainNav",
      "getShowHospitalDashboardsMainNav",
      "isSuperAdmin",
      "isProvider",
      "isProviderManager",
      "isHospitalManager",
      "isHospitalStaff",
      "isHospitalDoctor",
      "isClinicHealthSystemManager",
      "isClinicStaff",
      "isClinicStaffInTraining",
      "isClinicDoctor",
      "isCommunityCareOrganizationManager",
      "isSubSystemCommunityOrganization",
      "getHospitalSpecialtyModules",
      "aClinicIsSelected",
      "aCCOIsSelected",
      "getClinicId",
      "getCCOId",
      "showClinicSpecialties",
      "isSubSystemClinic",
      "isSubSystemHospital",
      "getNotificationCount",
    ]),
    clinicOrHospitalHasSpecialtiesToShow() {
      return (
        this.getShowClinicDashboardsMainNav ||
        this.getShowHospitalDashboardsMainNav
      );
    },
    clinic() {
      this.$store.dispatch(
        "getFullClinicDashboard",
        +this.$route.params.idClinic || -1
      );
      return +this.$route.params.idClinic;
    },
    hospital() {
      if (+this.$route.params.idHospital) {
        this.$store.dispatch(
          "fetchHospitalDashboard",
          +this.$route.params.idHospital
        );
      }
      return +this.$route.params.idHospital;
    },
  },

  methods: {
    ...mapActions([
      "navigateAccordingToRole",
      "goToHospitalSearchPatient",
      "goToHospitalUsers",
      "goToAllClinicsView",
      "goToUsersClinicHealthSystem",
      "goToLastClinicVisited",
      "goToCommunityUsers",
      "goToProviderGroupUsers",
      "goToProviderDashboard",
    ]),
    showSubmenu() {
      this.isSubmenuVisible = true;
    },
    closeSubmenu() {
      this.isNavMainExpanded = false;
      this.isSubmenuVisible = false;
    },
    logOut() {
      this.$store.dispatch("logOut");
    },
  },
});
</script>

<style scoped lang="scss">
.counter {
  position: absolute;
  top: 8px;
  left: 20px;
  transform-origin: center;
  background-color: $secondaryRed;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 23px;
  min-height: 23px;
  border-radius: 23px;
  padding: 2px 1px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border: 3px solid white;
  font-size: 12px;
  width: auto;
  font-weight: 700;
  .count {
    padding-left: 4px;
    padding-right: 4px;
  }
}
.svg-icon--chevron-md {
  color: #fff;
  transform: rotate(90deg);
  left: -2px;
}
.nav-main__primary {
  transform: translate(0, 0);
  transition: transform 0.3s ease-out;
  height: calc(100vh - #{$headerHeight});
}
.nav-main__secondary {
  width: 100%;
  transform: translateX(337px + $headerHeight);
  transition: transform 0.3s ease-out;
  opacity: 0;
  pointer-events: none;
}

.is-submenu-visible {
  position: relative;
  .nav-main__primary {
    transform: translate(-337px - $headerHeight);
    pointer-events: none;
  }
  .nav-main__secondary {
    position: absolute;
    opacity: 1;
    transform: translateX(0);
    top: 20px;
    pointer-events: auto;
  }
}

.nav-main {
  width: 64px + 2 * 30px;
  padding-left: 30px;
  padding-right: 30px;
  overflow: hidden;
  transition: width 0.4s ease-in;
  &--expanded {
    width: 357px;
    a:hover {
      background-color: $primaryLightBlue;
      &::before,
      &::after {
        opacity: 1;
      }
    }
  }
  ul {
    margin: 0;
    padding: 0;
    position: relative;
    list-style: none;
    & > li {
      padding: 0 13px;
      white-space: nowrap;
      & > a,
      & > .section-title {
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;
        height: 68px;
        width: 100%;
        font-size: 20px;
        line-height: 1.1;
        font-weight: 400;
        letter-spacing: 0px;
        color: #fff;
        text-decoration: none;
        text-transform: uppercase;
        white-space: nowrap;
        transition: all 0.2s linear;
        .svg-icon,
        .icn {
          flex: 0 0 auto;
          width: 34px;
          height: 34px;
          margin-right: 53px;
        }
        .txt {
          flex: 0 0 auto;
          white-space: nowrap;
        }
        &::before,
        &::after {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          bottom: 0;
          width: 43px;
          background-color: $primaryLightBlue;
          opacity: 0;
          transition: opacity 0.2s linear;
        }
        &::before {
          right: 100%;
        }
        &::after {
          left: 100%;
        }
      }
    }
  }
}

ul:not(:first-of-type) {
  li:first-child {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: -1px;
      right: 0;
      display: block;
      height: 1px;
      background-color: #fff;
      transition: all 0.2s linear;
    }
    &:hover {
      &::before {
        left: -30px;
        right: -30px;
      }
    }
  }
}
ul:not(:last-of-type) {
  li:last-child {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      display: block;
      height: 1px;
      background-color: #fff;
      transition: all 0.2s linear;
    }
    &:hover {
      &::after {
        left: -30px;
        right: -30px;
      }
    }
  }
}

ul.small > li > a {
  text-transform: none;
}
</style>
