import { createApp } from "vue";
import App from "./App.vue";
import "@/scss/global.scss";
import router from "./router";
import store from "./store/index";

import vClickOutside from "./directives/click-outside";
import VTooltipPlugin from "v-tooltip";
import "v-tooltip/dist/v-tooltip.css";
import Vue3Tour from "vue3-tour";

import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";

import mitt from "mitt";
const emitter = mitt();

const app = createApp(App);

app.use(store);
app.use(router);
app.use(VTooltipPlugin);
app.use(Vue3Tour);
app.directive("clickOutside", vClickOutside);
app.config.globalProperties.emitter = emitter;

store.dispatch("restoreSessionFromStorage");

// Register base components globally
const requireComponent = require.context(
  "@/components",
  false,
  /Base[A-Z]\w+\.(vue|js)$/
);
requireComponent.keys().forEach((fileName) => {
  const componentConfig = requireComponent(fileName);
  const componentName = upperFirst(
    camelCase(
      fileName
        ?.split("/")
        ?.pop()
        ?.replace(/\.\w+$/, "")
    )
  );
  app.component(componentName, componentConfig.default || componentConfig);
});

//Mount the app
app.mount("#app");
