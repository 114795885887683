<template lang="pug">
.view-appointment-status.content-wrapper
  BasePageTitle(
    pageTitleIcon="hep-b",
    title="Illinois Colon Cares: Colonoscopy"
  )
    template(#breadcrumbs)
      li Appointment Status
      li.active Ongoing Care
      li Schedule Patient
    template(#actionSection)
      BaseBtn.lg.blue(:to="{name: 'home'}") Schedule patient
      .annotation 24 Slots Available
  TabsCard(variant="lg")
    template(#tabs)
      router-link.tab(:to="{name: 'manage-care.active-patients'}")
        .tab__inner
          .tab__first-child 5
          .tab__second-child Active Patients
      router-link.tab(:to="{name: 'manage-care.archived'}")
        .tab__inner
          .tab__first-child 213
          .tab__second-child Archived
    template(#content)
      router-view
</template>

<script>
import { defineComponent } from "vue";
import TabsCard from "@/components/TabsCard.vue";

export default defineComponent({
  components: { TabsCard },

  data() {
    return {};
  },

  methods: {},
});
</script>

<style scoped lang="scss"></style>
