<template lang="pug">
SideOverlaySlot(
  :isOpen="isOpen",
  title="ADD REFERRAL NOTE"
  @closeOverlay="closeOverlay"
)
  template(#contentTop)
    section.patient-section
      p <strong>Patient: {{ getUserFormatted(patientAppointment.patient) }} </strong>

    form#add-note
      .form-group
        label(for="add-a-file") Add a File (Optional)
        UploadAndPreviewFile(
          :buttonStyleClasses="['btn', 'lg', 'blue']"
          :storageKey="appointmentNoteFileStorageKey"
        )

      .form-group
        label(for="notes") Notes
        textarea#notes(style="height:300px;" v-model="noteText")

  template(#footer)
    .flex.flex--space-between
      BaseBtn.x-lg.red-outline(
        href="#",
        @click.prevent="closeOverlay"
      ) CANCEL
      BaseBtn.x-lg.green(
        :disabled = "!noteText"
        @click.prevent="addNote",
      ) YES, CONTINUE
</template>

<script>
import { defineComponent } from "vue";
import SideOverlaySlot from "@/components/overlays/SideOverlaySlot.vue";
import UploadAndPreviewFile from "@/components/UploadAndPreviewFile.vue";
import { getUserFormatted } from "@/helpers";
import { mapGetters } from "vuex";

import { createPatientAppointmentNote } from "@/api/notifications.ts";

import { apiStatus, apiStatusComputedFactory } from "@/api";
import { uploadFile } from "@/api/filesApi";
const { IDLE, PENDING, SUCCESS, ERROR } = apiStatus;

export default defineComponent({
  components: { SideOverlaySlot, UploadAndPreviewFile },
  emits: ["closeAddNewReferralNoteOverlay"],

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    patientAppointment: {
      required: false,
      type: Object,
    },
  },

  data() {
    return {
      createPatientAppointmentNoteStatus: IDLE,
      noteText: null,
      appointmentNoteFileStorageKey: "appointment_note_file",
    };
  },
  computed: {
    ...mapGetters(["getUserId", "getUserRoleContext"]),
  },
  methods: {
    getUserFormatted,
    addNote() {
      this.createPatientAppointmentNote().then(() => {
        this.$emit("closeAddNewReferralNoteOverlay");
      });
    },
    async createPatientAppointmentNote() {
      this.createPatientAppointmentNoteStatus = PENDING;
      try {
        let responseFile;
        if (sessionStorage.getItem(this.appointmentNoteFileStorageKey)) {
          responseFile = await uploadFile(this.appointmentNoteFileStorageKey);
        }

        let origin = "";
        let destination = "";
        let noteType = "";

        if (this.getUserRoleContext.communityCareOrganization) {
          origin = "Community";
          destination = "Clinic";
          noteType = "Community Note";
        } else {
          origin = "Clinic";
          destination = "Community";
          noteType = "Clinic Note";
        }

        await createPatientAppointmentNote({
          idEntity: this.patientAppointment.id,
          entityType: "referral",
          createdBy: this.getUserId,
          idFile: responseFile?.file?.id,
          note: {
            content: this.noteText,
            stage: this.patientAppointment.stage,
            type: noteType,
            origin: origin,
            destination: destination,
          },
        });
        this.noteText = "";
        this.$emit("closeAddNewReferralNoteOverlay");
        this.createPatientAppointmentNoteStatus = SUCCESS;
      } catch (err) {
        this.createPatientAppointmentNoteStatus = ERROR;
        console.error(err);
      }
    },
    closeOverlay() {
      sessionStorage.removeItem(this.appointmentNoteFileStorageKey);
      this.$emit("closeAddNewReferralNoteOverlay");
    },
  },
});
</script>

<style scoped lang="scss">
.patient-section {
  margin-bottom: 44px;
  // margin-top:26px;
  padding: 37px 24px 25px;
  background-color: $primaryBgBlue;
  p {
    margin: 0 0 12px;
    font-size: 21px;
    font-weight: 500;
    line-height: 1.1;
    color: $primaryLightBlue;
    overflow: hidden;
    strong {
      font-weight: 700;
    }
  }
}
</style>
