// @ts-nocheck
import api from "./api";
const URLS = {
  ACCOUNT: `/account`,
};
export const register = (data: {
  user: any;
  idClinic?: number;
  idHospital?: number;
  idClinicHealthSystem?: number;
  idProviderGroup?: number;
  idReferralModule?: number;
}): Promise<any> => {
  return api.post(`${URLS.ACCOUNT}/register`, data);
};
export const login = (email: string, password: string): Promise<any> => {
  const credentials = "Basic " + window.btoa(`${email}:${password}`);
  const data = { email, password };
  return api.post(`${URLS.ACCOUNT}/login`, data, {
    headers: { Authorization: credentials },
  });
};
export const verifyEmail = (code: string): Promise<any> => {
  return api.get(`${URLS.ACCOUNT}/verify-email/${code}`);
};
export const requestForgetPassword = (
  email: string,
  isCreation: boolean
): Promise<any> => {
  return api.post(`${URLS.ACCOUNT}/forget`, { email, isCreation });
};
export const updateForgetPassword = (
  code: string,
  newPassword: string,
  confirmPassword: string
): Promise<any> => {
  return api.post(`${URLS.ACCOUNT}/forget/${code}`, {
    password: newPassword,
    confirm: confirmPassword,
  });
};
export const validateForgetPassword = (code: string): Promise<any> => {
  return api.get(`${URLS.ACCOUNT}/forget/${code}`);
};
export const renewSession = (): Promise<any> => {
  return api.patch(`${URLS.ACCOUNT}/session`, null);
};
