<template lang="pug">
.fit-kit-status.content-wrapper
  FITHeader(:idCommunity="+idCommunity")
  TabsCard(variant="lg")
    template(#tabs)
      router-link.tab(
        :to="{ name: 'community-care-organization-fit-kit-status.all-outstanding' }"
      )
        .tab__inner
          .tab__first-child {{fitKitDashboard.openKits}}
          .tab__second-child All Outstanding
      router-link.tab(
        :to="{ name: 'community-care-organization-fit-kit-status.follow-up-due' }"
      )
        .tab__inner
          .tab__first-child {{fitKitDashboard.dueFollowUpKits}}
          .tab__second-child Kit Follow-Up Due
      router-link.tab(
        :to="{ name: 'community-care-organization-fit-kit-status.completed' }"
      )
        .tab__inner
          .tab__first-child {{fitKitDashboard.completedArchivedKits}}
          .tab__second-child Completed
      router-link.tab(
        :to="{ name: 'community-care-organization-fit-kit-status.positive-fit' }"
      )
        .tab__inner
          .tab__first-child.positive-fit {{fitKitDashboard.positiveCompletedNeededActionKits}}
          .tab__second-child.positive-fit Positive FIT
      router-link.tab(
        :to="{ name: 'community-care-organization-fit-kit-status.patient-follow-up-due' }"
      )
        .tab__inner
          .tab__first-child {{fitKitDashboard.oneYearFollowUpKits}}
          .tab__second-child Patient Follow-Up Due
    template(#content)
      router-view

.content-wrapper.patient-referral-stats
  .card.router-view-card
    section.section_padding
      h3 Fit Registry Stats 
      span.h3-small-font Last 30 Days
      .columns.is-variable.circles-center
        .column.is-one-fifth
          .circle
            <vue3-chart-js :id="doughnutChart.id" :type="doughnutChart.type" :data="doughnutChart.data" :options="doughnutChart.options" ref="chartRef" ></vue3-chart-js>
        .column.is-2.circle-wrapper
          .circle-status.green
          .circle-numbers
            .circle-big-font {{ fitKitStats.negativeCompletedKits }}
            .circle-small-font Negative
        .column.is-2.circle-wrapper
          .circle-status.orange
          .circle-numbers
            .circle-big-font {{ fitKitStats.positiveCompletedKits }}
            .circle-small-font Positive
        .column.is-3.circle-wrapper
          .circle-status.grey
          .circle-numbers
            .circle-big-font {{ fitKitStats.noResultCompletedKits }}
            .circle-small-font No Result
</template>

<script>
import { defineComponent } from "vue";
import FITHeader from "@/components/fit-kit-community-organization/FITHeader.vue";
import TabsCard from "@/components/TabsCard.vue";
import { fetchFitKitDashboard } from "@/api/communityOrganization";
import BoxLinkSm from "@/components/BoxLinkSm.vue";
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";
import { ref } from "vue";

export default defineComponent({
  components: { FITHeader, TabsCard, BoxLinkSm, Vue3ChartJs },

  props: {},

  data() {
    return {
      idCommunity: null,
      fitKitDashboard: {
        positiveCompletedNeededActionKits: 0,
        dueFollowUpKits: 0,
        openKits: 0,
        oneYearFollowUpKits: 0,
        completedArchivedKits: 0,
      },
      fitKitStats: {
        dueFollowUpKits: 0,
        negativeCompletedKits: 0,
        newKits: 0,
        noResultCompletedKits: 0,
        positiveCompletedKits: 0,
      },
    };
  },

  watch: {
    // keywords: {
    //   handler: "getRows",
    //   immediate: true
    // }
  },

  methods: {
    async getFitKitDashboard() {
      try {
        const res = await fetchFitKitDashboard(this.$route.params.idCommunity, {
          params: {
            searchBy: "name",
          },
        });
        if (res.data.fitRegistryStatus) {
          this.fitKitDashboard = res.data.fitRegistryStatus;
        }
        if (res.data.fitRegistryStats) {
          this.fitKitStats = res.data.fitRegistryStats;
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
  setup() {
    const counter = ref(0);
    const chartRef = ref(null);
    const doughnutChart = {
      id: "doughnut",
      type: "doughnut",
      data: {
        datasets: [
          {
            backgroundColor: ["#96bf3c", "#f5a623", "#d8d8d8"],
            data: [0, 0, 0],
          },
        ],
      },
      options: {
        offset: 0,
        cutout: "70%",
        scales: {
          x: {
            display: false,
          },
          y: {
            display: false,
          },
        },
      },
    };
    const beforeUpdate = () => {
      counter.value++;
    };
    const doughnutChartUpdate = (values) => {
      doughnutChart.data.datasets[0].data = [
        values.negative,
        values.positive,
        values.noResult,
      ];
      chartRef.value.update(750);
    };
    return {
      doughnutChart,
      beforeUpdate,
      doughnutChartUpdate,
      chartRef,
    };
  },
  created() {
    if (this.$route.params.idCommunity === null) return;
    this.idCommunity = this.$route.params.idCommunity;
    this.getFitKitDashboard();
  },
});
</script>

<style scoped lang="scss">
.patient-referral-stats {
  margin-right: 0px;
  margin-left: auto;

  .section_padding {
    padding-left: 30px;
    padding-right: 30px;
    h3 {
      font-size: 21px;
      font-weight: normal;
      margin-bottom: 42px;
      margin-top: 30px;
      color: #7d7c7d;
      .h3-small-font {
        font-size: 16px;
        margin-left: 17px;
        color: #9b9b9b;
      }
    }
    .circles-center {
      align-items: center;
    }
    .columns.is-variable {
      padding-bottom: 57px;
      margin-left: 0px;
      margin-right: 0px;
    }
    .box-link-sm {
      margin-left: -24px;
    }
    .circle {
      width: 130px;
      height: 130px;
    }
    .circle-numbers > * {
      display: block;
    }
    .circle-status {
      width: 18px;
      height: 18px;
      border-radius: 100%;
      display: inline-block;
      margin-top: 10px;
      margin-right: 10px;
      &.green {
        background-color: $secondaryGreen;
      }
      &.orange {
        background-color: $secondaryOrange;
      }
      &.grey {
        background-color: $primaryBgDarkGrey;
      }
    }
    .circle-big-font {
      color: #343434;
      font-size: 26px;
    }
    .circle-small-font {
      color: $primaryDarkGrey;
      font-size: 14px;
      margin-top: -3px;
    }
    .circle-wrapper {
      display: flex;
    }
  }
}
.positive-fit {
  color: #d5351d !important;
}
</style>
