import { fetchCommunityCareOrganizationById } from "@/api/communityOrganization";
import router from "@/router";

interface CurrentCCOSpecialtiesState {
  currentCommunityOrganizationId: number;
  fitRegistry: any | null;
  primaryCare: any;
  referralModule: any;
  name: string;
  type: string;
}

export default {
  state(): CurrentCCOSpecialtiesState {
    return {
      currentCommunityOrganizationId: -1,
      fitRegistry: null,
      primaryCare: null,
      referralModule: null,
      name: "",
      type: "",
    };
  },
  getters: {
    showCCOFitRegistry(state: CurrentCCOSpecialtiesState) {
      return state.fitRegistry !== null && state.fitRegistry;
    },
    showCCOReferralModule(state: CurrentCCOSpecialtiesState) {
      return state.referralModule !== null && state.referralModule;
    },
    showCCOPrimaryCare(state: CurrentCCOSpecialtiesState) {
      return state.primaryCare !== null && state.primaryCare;
    },
    aCCOIsSelected(state: CurrentCCOSpecialtiesState) {
      return state.currentCommunityOrganizationId > 0;
    },
    getCCOId(state: CurrentCCOSpecialtiesState) {
      return state.currentCommunityOrganizationId;
    },
    getCCOName(state: CurrentCCOSpecialtiesState) {
      return state.name;
    },
    getCCOFitRegistry(state: CurrentCCOSpecialtiesState) {
      return state.fitRegistry;
    },
    getCCOPrimaryCare(state: CurrentCCOSpecialtiesState) {
      return state.primaryCare;
    },
  },
  mutations: {
    COMMIT_CURRENT_CCO(state: CurrentCCOSpecialtiesState, payload: number) {
      localStorage.setItem(
        "currentCommunityOrganizationId",
        payload.toString()
      );
      state.currentCommunityOrganizationId = payload;
    },
    COMMIT_PRIMARY_CARE(state: CurrentCCOSpecialtiesState, payload: any) {
      state.primaryCare = payload;
    },
    COMMIT_REFERRAL_MODULE(state: CurrentCCOSpecialtiesState, payload: any) {
      state.referralModule = payload;
    },
    COMMIT_FIT_REGISTRY(state: CurrentCCOSpecialtiesState, payload: any) {
      state.fitRegistry = payload;
    },
    COMMIT_CCO_NAME(state: CurrentCCOSpecialtiesState, payload: string) {
      state.name = payload;
    },
    COMMIT_CCO_TYPE(state: CurrentCCOSpecialtiesState, payload: string) {
      state.type = payload;
    },
  },
  actions: {
    async getCCOinfo({ commit }: { commit: any }, cco: any) {
      const {
        data: { id, name, type, fitRegistry, primaryCare, referralModule },
      } = await fetchCommunityCareOrganizationById(cco.id);

      commit("COMMIT_CURRENT_CCO", id);
      commit("COMMIT_PRIMARY_CARE", primaryCare);
      commit("COMMIT_FIT_REGISTRY", fitRegistry);
      commit("COMMIT_REFERRAL_MODULE", referralModule);
      commit("COMMIT_CCO_NAME", name);
      commit("COMMIT_CCO_TYPE", type);
    },
    goToCommunityUsers({ getters }: { getters: any }) {
      router.push({
        name: "users-community-organizations",
        params: {
          idCommunity: getters.getCCOId,
        },
      });
    },
    clearCurrentCCO({ commit }: { commit: any }) {
      commit("COMMIT_CURRENT_CCO", -1);
      commit("COMMIT_PRIMARY_CARE", null);
      commit("COMMIT_FIT_REGISTRY", null);
      commit("COMMIT_REFERRAL_MODULE", null);
      commit("COMMIT_CCO_NAME", "");
      commit("COMMIT_CCO_TYPE", "");
      localStorage.removeItem("currentCommunityOrganizationId");
    },
  },
};
