<template lang="pug">
</template>

<script>
import { defineComponent } from "vue";
import { mapActions } from "vuex";

export default defineComponent({
  methods: {
    ...mapActions(["navigateAccordingToRole"]),
  },
  created() {
    this.navigateAccordingToRole();
  },
});
</script>

<style scoped lang="scss"></style>
