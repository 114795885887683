<template lang="pug">
.pagination
  .per-page Items per page:
    div.no-per-page
      select(v-model="size", @change="changePaginationSize")
        option(
          v-for="option in sizeOptions"
          :key="option",
          :value="option",
        ) {{option}}
  .paginator
    .arrow_left
      a(
        v-if="pagination.currentPage !== 1 && displayCurrentPage > 0"
        href="#",
        @click.prevent="previousPage"
      )
        base-icon.mirror(name="chevron-next")
    .paginator__count {{displayCurrentPage}} of {{displayLastPage}}
    .arrow_right
      a(
        v-if="pagination.currentPage !== pagination.lastPage"
        href="#",
        @click.prevent="nextPage"
      )
        base-icon(name="chevron-next")
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["changePagination"],

  props: {
    pagination: {
      type: Object,
      required: true,
    },
    reloadFn: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      sizeOptions: [10, 20, 30, 40, 50],
      size: this.pagination.size,
      page: this.pagination.currentPage,
      displayCurrentPage: this.pagination.currentPage,
      displayLastPage: this.pagination.lastPage,
    };
  },
  watch: {
    pagination(val) {
      if (val.currentPage) {
        this.displayCurrentPage = val.currentPage;
        this.page = val.currentPage;
      }
      if (val.lastPage) {
        this.displayLastPage = val.lastPage;
      }
    },
  },
  methods: {
    changePagination() {
      const newPagination = {
        size: this.size,
        page: this.page,
      };
      this.$emit("changePagination", newPagination, this.reloadFn);
    },
    changePaginationSize() {
      this.page = 1;
      this.changePagination();
    },
    nextPage() {
      this.page = this.page + 1;
      this.displayCurrentPage = this.page;
      this.changePagination();
    },
    previousPage() {
      this.page = this.page == 1 ? 1 : this.page - 1;
      this.displayCurrentPage = this.page;
      this.changePagination();
    },
  },
});
</script>
<style scoped lang="scss">
.pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 40px 30px;
  .per-page {
    select {
      border: none;
      padding: 0;
      padding-right: 15px;
      font-size: 14px;
      line-height: 1.29;
      background-image: url("../assets/images/arrow-per-page.svg");
      background-position: 100% calc(50% + 1px);
      background-size: 8px 6.4px;
      border-radius: 0;
      &:focus,
      &:active {
        box-shadow: none;
      }
    }
    .no-per-page {
      display: inline-block;
      font-weight: 700;
      margin-left: 10px;
      margin-right: 7px;
    }
    .svg-icon--arrow-per-page {
      font-size: 8px;
    }
  }
  .paginator {
    margin-left: 23px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.29;
    .arrow_left,
    .arrow_right {
      width: 31px;
    }
    &__count {
      margin: 0 20px;
    }
    .svg-icon--chevron-next {
      top: 0;
      font-size: 31px;
      &.mirror {
        transform: rotate(180deg);
      }
    }
  }
}
</style>
