<template lang="pug">
.table_wrapper
  table.table(id="data-table")
    tr.th
      td.td Month
      td.td Scheduled
      td.td Completed
      td.td % Completed
      td.td Cancelled
      td.td No Show
      td.td(v-for="dynamicKey in dynamicReport" :key="dynamicKey.label") {{dynamicKey.label}} 
    tr.tr(v-for="month, monthIndex in labels", :key="month")
      td.td {{month}}
      td.td {{scheduled[monthIndex]}}
      td.td {{completed[monthIndex]}}
      td.td.bold.red  {{completedPercentage[monthIndex]}}%
      td.td {{cancelled[monthIndex]}}
      td.td {{noShow[monthIndex]}}
      td.td(v-for="dynamicKey, indexKey in dynamicReport" :key="dynamicKey.label + indexKey") {{dynamicKey.data[monthIndex]}}
    tr.tr
      td.td Total
      td.td {{totalScheduled}}
      td.td {{totalCompleted}}
      td.td {{totalCompletedPercentage}}%
      td.td {{totalCancelled}}
      td.td {{totalNoShow}}
      td.td(v-for="dynamicKey, indexKey in dynamicReport" :key="dynamicKey.label + indexKey") {{dynamicKey.total}}
</template>
<script>
import accumulator from "@/helpers/accumulator";
import moment from "moment";
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    reportData: {
      required: true,
      type: Array,
    },
  },
  computed: {
    scheduled() {
      return this.reportData.map((monthData) => monthData.totalScheduled);
    },
    totalScheduled() {
      return this.scheduled.reduce(accumulator, 0);
    },
    completed() {
      return this.reportData.map((monthData) => monthData.totalCompleted);
    },
    totalCompleted() {
      return this.completed.reduce(accumulator, 0);
    },
    completedPercentage() {
      return this.reportData.map((monthData) => monthData.completedPercentage);
    },
    totalCompletedPercentage() {
      return (
        this.completedPercentage.reduce(accumulator, 0) / this.labels.length
      ).toFixed(2);
    },
    cancelled() {
      return this.reportData.map((monthData) => monthData.totalCancelled);
    },
    totalCancelled() {
      return this.cancelled.reduce(accumulator, 0);
    },
    noShow() {
      return this.reportData.map((monthData) => monthData.totalNoShow);
    },
    totalNoShow() {
      return this.noShow.reduce(accumulator, 0);
    },
    dynamicReport() {
      const allfields = { ...this.reportData[0] };
      const fixedFields = [
        "scheduledAtMonth",
        "totalScheduled",
        "totalCompleted",
        "completedPercentage",
        "totalCancelled",
        "totalNoShow",
      ];
      const dynamicKeys = Object.keys(allfields).filter(
        (key) => !fixedFields.includes(key)
      );
      if (dynamicKeys.length) {
        return dynamicKeys.map((dynamicKey) => {
          const data = this.reportData.map((month) => month[dynamicKey]);

          return {
            label: dynamicKey.replace("total", ""),
            data,
            total: [...data].reduce(accumulator, 0),
          };
        });
      }

      return [];
    },
    labels() {
      return this.reportData.map((monthData, index, arr) =>
        moment(
          monthData.distributedAtMonth || monthData.scheduledAtMonth
        ).format(arr.length > 12 ? "MMMM YY" : "MMMM")
      );
    },
  },
});
</script>
<style lang="scss" scoped>
.table_wrapper {
  width: 100%;
  margin-bottom: 80px;
}
.hr {
  margin-right: 30px;
}
.btn-margin-left {
  margin-left: 28px;
}
.table_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.table {
  width: 100%;
  color: #000;

  .th {
    padding-bottom: 14px;
    padding-top: 14px;
    height: auto;
    border-bottom: 0px;
    .td {
      font-weight: 700;
      text-transform: none;
    }
    background-color: $primaryBgDarkGrey;
  }

  .tr {
    padding-bottom: 14px;
    padding-top: 14px;
    height: auto;
    .td {
      height: auto;
      display: flex;
    }
    &:hover {
      background-color: transparent;
    }
    &:last-child {
      border-bottom: 0px;
    }
  }

  .td {
    font-size: 14px;
    display: flex;
    flex: 1;
  }
}
</style>
