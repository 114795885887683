<template lang="pug">
.content-wrapper
  .card.router-view-card
    section.all-actions
      TableTitle(title="All Actions")
        template(#actions)
          select.table-action-selector(
            v-model="selectedUser"
            @change="fetchActions"
          )
            option(value="") ALL USERS
            option(
              v-for="user in users" 
              :key="user.id" 
              :value="user.id"
            ) {{user.first}} {{user.last}}
          select.table-action-selector(
            v-model="selectedByType" 
            @change="fetchActions"
          )
            option(value="") ALL ACTIONS
            option(value="type") By action type
            option(value="name") By action name
          select.table-action-selector(
            v-if="selectedByType" 
            v-model="selectedType" 
            style="max-width: 12rem;"
            @change="fetchActions"
          )
            option(value="") ALL ACTIONS
            option(
              v-if="selectedByType === 'type'" 
              v-for="actionType in actionTypes" 
              :value="actionType.value" 
              :key="actionType.value"
            ) {{actionType.label}}
            option(
              v-if="selectedByType === 'name'" 
              v-for="actionName in actionNames" 
              :value="actionName.value" 
              :key="actionName.value"
            ) {{actionName.label}}
      .table
        .th
          .td(@click.prevent="changeListOrder('dateTime', fetchActions)" style="cursor: pointer;") DATE AND TIME
            i(style="font-weight: bolder;") {{ dateTimeColumnSortingIcon }}
          .td(@click.prevent="changeListOrder('name', fetchActions)" style="cursor: pointer;") USER
            i(style="font-weight: bolder;") {{ nameColumnSortingIcon }}
          .td(@click.prevent="changeListOrder('role', fetchActions)" style="cursor: pointer;") ROLE
            i(style="font-weight: bolder;") {{ roleColumnSortingIcon }}
          .td(@click.prevent="changeListOrder('event', fetchActions)" style="cursor: pointer;") ACTION
            i(style="font-weight: bolder;") {{ eventColumnSortingIcon }}
        .tr(v-for="action in actions", :key="action.id")
          .td.date-time {{momentDate(action.createdAt)}} <span>{{momentTime(action.createdAt)}}</span>
          .td.bold {{action.first}} {{action.last}}
          .td {{action.roleName}}
          .td {{action.entry}}
      Pagination(
        v-if="pagination",
        :pagination="pagination",
        :reloadFn="fetchActions"
        @changePagination="changePagination"
      )
</template>

<script>
import moment from "moment";
import { defineComponent } from "vue";
import TableTitle from "@/components/TableTitle.vue";
import Pagination from "@/components/Pagination.vue";
import listPagesMixin from "@/mixins/listPagesMixin";
import sortLogs from "@/mixins/sortLogs";
import { ACTION_TYPES, ACTION_NAMES } from "@/helpers/constants";

import {
  fetchUser,
  fetchLoggedActions,
  fetchUserLoggedActions,
  fetchUserHospitalLoggedActions,
  fetchUserClinicHealthSystemLoggedActions,
  fetchHospitalLoggedActions,
  fetchClinicHealthSystemLoggedActions,
  fetchCommunityCareOrganizationLoggedActions,
  fetchUserCommunityCareOrganizationLoggedActions,
  fetchProviderGroupLoggedActions,
  fetchUserProviderGroupLoggedActions,
} from "@/api/userApi";
import { apiStatus, apiStatusComputedFactory } from "@/api";
import { mapGetters } from "vuex";
const { IDLE, PENDING, SUCCESS, ERROR } = apiStatus;

export default defineComponent({
  components: {
    TableTitle,
    Pagination,
  },
  mixins: [listPagesMixin, sortLogs],

  data() {
    return {
      fetchUsersStatus: IDLE,
      fetchActionsStatus: IDLE,
      actionTypes: ACTION_TYPES,
      actionNames: ACTION_NAMES,
      users: null,
      actions: null,
      selectedUser: "",
      selectedByType: "",
      selectedType: "",
    };
  },

  computed: {
    ...apiStatusComputedFactory("fetchActionsStatus"),
    ...mapGetters(["getUserRoleContext", "getRole", "isSuperAdmin", "getManagerOfProviderID"]),
  },

  methods: {
    async fetchUsers() {
      this.fetchUsersStatus = PENDING;
      const params = {
        filters: {
          list: this.isSuperAdmin ? true : undefined,
        },
      };

      if (this.getUserRoleContext.hospital) {
        params.filters.idHospital = this.getUserRoleContext.hospital.id;
      } else if (this.getUserRoleContext.clinicHealthSystem) {
        params.filters.idClinicHealthSystem =
          this.getUserRoleContext.clinicHealthSystem.id;
      } else if (this.getUserRoleContext.communityCareOrganization) {
        params.filters.idCommunity =
          this.getUserRoleContext.communityCareOrganization.id;
      } else if (this.getUserRoleContext.providerGroup) {
        params.filters.idProviderGroup =
          this.getUserRoleContext.providerGroup.id;
      }

      try {
        const response = await fetchUser({
          params,
        });
        this.users = response.data.users;
      } catch (error) {
        console.log(error);
        this.fetchUsersStatus = ERROR;
      }
    },
    async fetchActions() {
      this.fetchActionsStatus = PENDING;

      const order = this.listOrder
        ? {
            by: this.listOrder.by,
            direction: this.listOrder.direction,
          }
        : undefined;

      const params = {
        write:
          (this.selectedByType === "type" && this.selectedType === "write") ||
          null,
        read:
          (this.selectedByType === "type" && this.selectedType === "read") ||
          null,
        actionName:
          (this.selectedByType === "name" && this.selectedType) || null,
        order,
        ...this.reqPagination,
      };

      const roleSubSystem = this.getRole.subSystem;
      try {
        let response;
        if (this.selectedUser) {
          switch (roleSubSystem) {
            case "admin":
              response = await fetchUserLoggedActions(
                { params },
                this.selectedUser
              );
              break;
            case "hospital":
              response = await fetchUserHospitalLoggedActions(
                { params },
                this.selectedUser,
                this.getUserRoleContext.hospital.id
              );
              break;
            case "clinichealthsystem":
              response = await fetchUserClinicHealthSystemLoggedActions(
                { params },
                this.selectedUser,
                this.getUserRoleContext.clinicHealthSystem.id
              );
              break;
            case "communitycareorganization":
              response = await fetchUserCommunityCareOrganizationLoggedActions(
                { params },
                this.selectedUser,
                this.getUserRoleContext.communityCareOrganization.id
              );
              break;
            case "provider":
              response = await fetchUserProviderGroupLoggedActions(
                { params },
                this.selectedUser,
                this.getUserRoleContext.providerGroup.id
              );
              break;
            default:
              break;
          }
        } else {
          switch (roleSubSystem) {
            case "admin":
              response = await fetchLoggedActions({ params });
              break;
            case "hospital":
              response = await fetchHospitalLoggedActions(
                { params },
                this.getUserRoleContext.hospital.id
              );
              break;
            case "clinichealthsystem":
              response = await fetchClinicHealthSystemLoggedActions(
                { params },
                this.getUserRoleContext.clinicHealthSystem.id
              );
              break;
            case "communitycareorganization":
              response = await fetchCommunityCareOrganizationLoggedActions(
                { params },
                this.getUserRoleContext.communityCareOrganization.id
              );
              break;
            case "provider": {
              let providerGroupID = (this.getManagerOfProviderID) ? this.getManagerOfProviderID : this.getUserRoleContext.providerGroup.id;
              response = await fetchProviderGroupLoggedActions(
                { params },
                providerGroupID
              );
              break;
            }
            default:
              break;
          }
        }
        this.actions = response.data.logs;
        this.pagination = response.data.pagination;
        this.fetchActionsStatus = SUCCESS;
      } catch (error) {
        console.log(error);
        this.fetchActionsStatus = ERROR;
      }
    },
    momentDate(date) {
      return moment(date * 1000).format("MM-DD-YYYY");
    },
    momentTime(date) {
      return moment(date * 1000).format("hh:mm A");
    },
  },

  created() {
    this.fetchUsers();
    this.fetchActions();
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/components/step-subtitle.scss";
.all-actions {
  .td {
    &:nth-child(1) {
      width: math.percentage(math.div(242, $tabContentCardWidth));
    }
    &:nth-child(2) {
      width: math.percentage(math.div(225, $tabContentCardWidth));
    }
    &:nth-child(3) {
      width: math.percentage(math.div(238, $tabContentCardWidth));
    }
    &:nth-child(4) {
      width: math.percentage(math.div(474, $tabContentCardWidth));
    }
  }
}
.content-wrapper {
  position: relative;
  padding-bottom: 40px;
}
.router-view-card {
  padding-top: 0;
  position: relative;
  width: 100%;
  z-index: 1;
}
.table .tr > .td {
  display: flex;
  &.date-time {
    flex-direction: column;
    align-items: flex-start;
    span {
      color: #9b9b9b;
    }
  }
}

.table-action-selector {
  width: auto;
  background-image: url(/img/chevron-md-grey.6a68b145.svg);
  background-size: 19px;
  background-position: 100% center;
  padding-right: 30px;
  margin-left: 40px;
}
</style>
