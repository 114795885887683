import api from "./api";

const reportsUrl = "/report/patient-appointment";

const fitKitReportsUrl = "/fit-kit";

export const fetchReportsData = (data = {}) => {
  return api.get(reportsUrl + "/overall", data);
};

export const fetchFitReportData = (data = {}) => {
  return api.get(fitKitReportsUrl + "/overall-report", data);
};

export const fetchFullReportsData = (data = {}) => {
  return api.get(reportsUrl + "/full", data);
};

export const fetchFullFitReportData = (data = {}) => {
  return api.get(fitKitReportsUrl + "/full-report", data);
};
