<template lang="pug">

TabsCard
  template(#tabs)
    router-link.tab(:to="{name: 'clinics.ongoing-care.active-patients'}")
      .tab__inner
        .tab__first-child {{ summary.active }}
        .tab__second-child Active Patients
    router-link.tab(:to="{name: 'clinics.ongoing-care.archived'}")
      .tab__inner
        .tab__first-child {{ summary.archived }}
        .tab__second-child Archived
  template(#content)
    router-view(
      :specialtyModule="specialtyModule"
      @refreshAppointmentsSummary="refreshAppointmentsSummary"
    ) 
</template>

<script>
import { defineComponent } from "vue";
import TabsCard from "@/components/TabsCard.vue";

import { fetchOnGoingCarePatient } from "@/api/onGoingCareApi";

export default defineComponent({
  components: {
    TabsCard,
  },
  props: {
    specialtyModule: {
      required: false,
    },
  },
  data() {
    return {
      summary: {
        active: 0,
        archived: 0,
      },
    };
  },
  methods: {
    refreshAppointmentsSummary(summary) {
      this.summary = summary;
    },
  },
});
</script>

<style scoped lang="scss"></style>
