<template lang="pug">
PopupCardSlot(
  :isOpen="isDeleteSchedulePopupOpen",
  maxWidth="675px"
)
  .delete-schedule-popup
    h1 Are you sure you want to delete this slot?
    p Please confirm to continue
    .popup-btns
      BaseBtn.x-lg.red-outline(@click.prevent="closeDeleteSchedulePopup") CANCEL
      BaseBtn.x-lg.green(@click.prevent="confirmDeleteSchedulePopup") YES, CONTINUE


.content-wrapper
  .card.router-view-card
    section.all-clinics
    TableTitle(title="All Schedules")
      template(#actions)
        select.table-action-selector.table-action-selector--doctor(
          :title="doctorTitle",
          style="margin-right: 24px;"
          @change="setFilterValueDoctor($event.target.value)"
        )
          option(value="") DOCTOR'S NAME
          option(
            v-for="doctor in activeDoctors", :key="doctor.id",
            :value="doctor.id"
          ) {{ getDoctorFormatted(doctor) }} 
        DateSelector(@filterByDate="filterByDate")
        BaseBtn.lg.blue(@click.prevent="openAddScheduleOverlay()" v-if="isHospitalManager") ADD NEW SCHEDULE
    .table
      .th
        .td(@click.prevent="changeListOrder('DoctorName', fetchPatientAppointmentAvailableSchedule)" style="cursor: pointer;") Doctor Name
          i(style="font-weight: bolder;") {{ DoctorNameColumnSortingIcon }}
        .td(@click.prevent="changeListOrder('Insurance', fetchPatientAppointmentAvailableSchedule)" style="cursor: pointer;") Insurance
          i(style="font-weight: bolder;") {{ InsuranceColumnSortingIcon }}

        .td(@click.prevent="changeListOrder('DateAndTime', fetchPatientAppointmentAvailableSchedule)" style="cursor: pointer;") Appt. Time
          i(style="font-weight: bolder;") {{ DateAndTimeColumnSortingIcon }}
        .td(@click.prevent="changeListOrder('ClinicName', fetchPatientAppointmentAvailableSchedule)" style="cursor: pointer;") Clinic
          i(style="font-weight: bolder;") {{ ClinicNameColumnSortingIcon }}
        .td(@click.prevent="changeListOrder('ApptScheduled', fetchPatientAppointmentAvailableSchedule)" style="cursor: pointer;") Appt. Scheduled
          i(style="font-weight: bolder;") {{ ApptScheduledColumnSortingIcon }}
      .tr(
        v-for="schedule in schedules",
        :key="schedule.id",
      )
        .td.bold {{ getDoctorFormatted(schedule.doctor) }}
        .td
          base-icon(name="yes", v-if="schedule.insuranceRequired")
          base-icon(name="no", v-else)
        .td 
          div {{ getDateFromUnixFormatted(schedule.availableAt) }}
          .second-row-md-grey {{ getTimeFromUnixFormatted(schedule.availableAt) }}
        .td {{ schedule.clinic.name }}
        .td.alignButtonRight
          base-icon.marginRight(name="no", v-if="!schedule.patientAppointment")
          base-icon.marginRight(name="yes", v-else)
      
          BaseBtn.sm.redFill(
            @click.prevent="openDeleteSchedulePopup(schedule.id)"
            v-if="!schedule.patientAppointment") DELETE THIS SLOT
          BaseBtn.sm.blue(
            v-else,
            @click.prevent="openHospitalAppointmentRecordOverlay(schedule.patientAppointment)"
            ) VIEW APPOINTMENT
      Pagination(
        v-if="pagination  && schedules != null && schedules.length",
        :pagination="pagination",
        :reloadFn="fetchPatientAppointmentAvailableSchedule"
        @changePagination="changePagination"
      )
      .no-results(v-if="schedules != null && !schedules.length") 
        base-icon(name="exclamation") 
        span No matching records

AddScheduleOverlay(
  :isOpen="isAddScheduleOverlayOpen",
  @closeAddScheduleOverlay="closeAddScheduleOverlay"
  @fetchPatientAppointmentAvailableSchedule="fetchPatientAppointmentAvailableSchedule"
)
HospitalAppointmentRecordOverlay(
  :key="`${componentKey}-1`",
  :isOpen="isHospitalAppointmentRecordOverlayOpen",
  tabType="displayOnly"
  :showAppointment="showAppointment"
  @closeHospitalAppointmentRecordOverlay="closeHospitalAppointmentRecordOverlay",
  :specialtyModule="specialtyModule"
)
</template>

<script>
import { defineComponent } from "vue";
import TableTitle from "@/components/TableTitle.vue";
import DateSelector from "@/components/DateSelector.vue";
import Pagination from "@/components/Pagination.vue";
import listPagesMixin from "@/mixins/listPagesMixin";

import HospitalAppointmentRecordOverlay from "@/components/hospitals/appointment-status/overlays/HospitalAppointmentRecordOverlay.vue";
import AddScheduleOverlay from "@/components/hospitals/schedules/overlays/AddScheduleOverlay";
import PopupCardSlot from "@/components/PopupCardSlot.vue";

import { fetchDoctorsSpecialty } from "@/api/hospitalApi";

import {
  fetchPatientAppointmentById,
  deletePatientAppointmentById,
  fetchPatientAppointmentAvailableSchedule,
} from "@/api/patientAppointmentApi";
import { apiStatus, apiStatusComputedFactory } from "@/api";
const { IDLE, PENDING, SUCCESS, ERROR } = apiStatus;

import {
  getDateFromUnixFormatted,
  getTimeFromUnixFormatted,
  getDoctorFormatted,
} from "@/helpers";
import { mapGetters } from "vuex";
import sortHospitalSchedules from "@/mixins/sortHospitalSchedules";


export default defineComponent({
  components: {
    TableTitle,
    DateSelector,
    Pagination,
    HospitalAppointmentRecordOverlay,
    AddScheduleOverlay,
    PopupCardSlot,
  },
  props: {
    specialtyModule: {
      required: false,
    },
  },
  data() {
    return {
      isHospitalAppointmentRecordOverlayOpen: false,
      isAppointmentNotesOverlayOpen: false,
      isAddScheduleOverlayOpen: false,
      isDeleteSchedulePopupOpen: false,
      fetchPatientAppointmentAvailableScheduleStatus: IDLE,
      fetchPatientAppointmentByIdStatus: IDLE,
      deletePatientAppointmentByIdStatus: IDLE,
      schedules: null,
      showAppointment: null,
      deleteScheduleID: null,
      date: null,
      doctors: null,
      idDoctor: null,
      doctorTitle: "",
    };
  },
  mixins: [listPagesMixin, sortHospitalSchedules],

  computed: {
    ...apiStatusComputedFactory(
      "fetchPatientAppointmentAvailableScheduleStatus"
    ),
    ...mapGetters(["isHospitalManager"]),
    activeDoctors() {
      return this.doctors?.filter((doctor) => !doctor.disabled);
    },
  },
  watch: {
    scheduleDoctor(val) {
      console.log(val);
    },
  },
  methods: {
    getDateFromUnixFormatted,
    getTimeFromUnixFormatted,
    getDoctorFormatted,
    filterByDate(date) {
      this.date = date;
      this.fetchPatientAppointmentAvailableSchedule();
    },
    setFilterValueDoctor(value) {
      if (value) {
        this.idDoctor = value;
        this.doctors.forEach((doctor) => {
          if (doctor.id == value) {
            this.doctorTitle = this.getDoctorFormatted(doctor);
          }
        });
      } else {
        this.idDoctor = null;
      }
      this.fetchPatientAppointmentAvailableSchedule();
    },
    confirmDeleteSchedulePopup() {
      this.deletePatientAppointmentById(this.deleteScheduleID);
    },
    async fetchDoctorsSpecialty() {
      this.fetchDoctorsSpecialtyStatus = PENDING;
      try {
        const res = await fetchDoctorsSpecialty(
          this.$route.params.idHospital,
          this.$route.params.idSpecialty
        );
        this.doctors = res.data.doctors;
        this.fetchDoctorsSpecialtyStatus = SUCCESS;
      } catch (err) {
        this.fetchDoctorsSpecialtyStatus = ERROR;
        console.error(err);
      }
    },
    async deletePatientAppointmentById(idSchedule) {
      this.deletePatientAppointmentByIdStatus = PENDING;
      try {
        await deletePatientAppointmentById(idSchedule);

        this.isDeleteSchedulePopupOpen = false;
        this.fetchPatientAppointmentAvailableSchedule();
        this.deletePatientAppointmentByIdStatus = SUCCESS;
      } catch (err) {
        this.deletePatientAppointmentByIdStatus = ERROR;
        console.error(err);
      }
    },
    async fetchPatientAppointmentById(idSchedule) {
      this.fetchPatientAppointmentByIdStatus = PENDING;
      try {
        const res = await fetchPatientAppointmentById(idSchedule, {
          params: {
            include: [
              "clinic",
              "hospitalDoctor",
              "patient",
              "referringDoctor",
              "hospital",
            ],
          },
        });
        this.showAppointment = res.data.patientAppointment;
        this.isHospitalAppointmentRecordOverlayOpen = true;
        this.componentKey = this.componentKey + 1;
        this.fetchPatientAppointmentByIdStatus = SUCCESS;
      } catch (err) {
        this.fetchPatientAppointmentByIdStatus = ERROR;
        console.error(err);
      }
    },
    openHospitalAppointmentRecordOverlay(appointment) {
      this.fetchPatientAppointmentById(appointment.id);
    },
    closeHospitalAppointmentRecordOverlay() {
      this.isHospitalAppointmentRecordOverlayOpen = false;
    },
    async fetchPatientAppointmentAvailableSchedule() {
      this.fetchPatientAppointmentAvailableScheduleStatus = PENDING;
      let navigatePage = 1;
      if(this.reqPagination.page){
        navigatePage = this.reqPagination.page;
      }
     const order = this.listOrder
        ? {
            by: this.listOrder.by,
            direction: this.listOrder.direction,
          }
        : null;
      try {
        delete this.reqPagination.quantity;
        const res = await fetchPatientAppointmentAvailableSchedule({
          params: {
            ...this.reqPagination,
            order,
            idHospital: this.$route.params.idHospital,
            include: ["doctor", "patientAppointment", "clinic"],
            idSpecialtyModule: this.$route.params.idSpecialty,
            availableAt: this.date ?? null,
            idDoctor: this.idDoctor,
            page: navigatePage,
          },
        });

        this.schedules = res.data.schedules;
        this.pagination = res.data.pagination;
        this.fetchPatientAppointmentAvailableScheduleStatus = SUCCESS;
      } catch (err) {
        this.fetchPatientAppointmentAvailableScheduleStatus = ERROR;
        console.error(err);
      }
    },
    openAddScheduleOverlay() {
      this.isAddScheduleOverlayOpen = true;
    },
    closeAddScheduleOverlay() {
      this.fetchPatientAppointmentAvailableSchedule();
      this.isAddScheduleOverlayOpen = false;
    },
    openDeleteSchedulePopup(idSchedule) {
      this.deleteScheduleID = idSchedule;
      this.isDeleteSchedulePopupOpen = true;
    },
    closeDeleteSchedulePopup() {
      this.isDeleteSchedulePopupOpen = false;
    },
  },
  created() {
    if (this.$route.params.idHospital === null) return;
    this.fetchPatientAppointmentAvailableSchedule();
    this.fetchDoctorsSpecialty();
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/components/actions-below-table-container.scss";
.card {
  position: relative;
}
.actions-below-table-container {
  top: calc(100% + 44px);
}
.td {
  &:nth-child(1) {
    width: math.percentage(math.div(286, $tabContentCardWidth));
  }
  &:nth-child(2) {
    width: math.percentage(math.div(148, $tabContentCardWidth));
  }
  &:nth-child(3) {
    width: math.percentage(math.div(170, $tabContentCardWidth));
  }
  &:nth-child(4) {
    width: math.percentage(math.div(218, $tabContentCardWidth));
  }
  &:nth-child(5) {
    width: math.percentage(math.div(358, $tabContentCardWidth));
  }
  &.alignButtonRight {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
  .btn {
    min-width: 196px;
  }
}
.marginRight {
  margin-right: 25px;
}
.delete-schedule-popup {
  max-width: 555px;
  margin: 0 auto;
  h1 {
    width: 100%;
    max-width: 549px;
    margin: 39px auto 30px;
    font-size: 28px;
    font-weight: 700;
    line-height: 1.21;
    letter-spacing: 0.14px;
    text-align: center;
  }
  p {
    margin-top: 0;
    margin-bottom: 32px;
    font-size: 16px;
    line-height: 1.19;
    letter-spacing: 0.23px;
    text-align: center;
  }
  .popup-btns {
    margin-bottom: 39px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .btn {
      width: 260px;
    }
  }
}
</style>
<style>
.main-container {
  padding-bottom: 100px;
}
</style>
