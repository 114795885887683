<template lang="pug">

TableTitle(title="Patient Referrals",
  searchPlaceholder="Search by patient name or hospital"
  v-model="searchKeyword",
  @doSearch="fetchPatientReferrals",
  @blur="fetchPatientReferrals"
)
  template(#actions)
    DateSelector(@filterByDate="filterByDate")
.table
  .th
    .td(@click.prevent="changeListOrder('DateTime', fetchPatientReferrals)" style="cursor: pointer;") APPT. TIME
      i(style="font-weight: bolder;") {{ DateTimeColumnSortingIcon }}
    .td(@click.prevent="changeListOrder('PatientName', fetchPatientReferrals)" style="cursor: pointer;") PATIENT NAME
      i(style="font-weight: bolder;") {{ PatientNameColumnSortingIcon }}
    .td(@click.prevent="changeListOrder('Clinic', fetchPatientReferrals)" style="cursor: pointer;") Clinic
      i(style="font-weight: bolder;") {{ ClinicColumnSortingIcon }}
    .td(@click.prevent="changeListOrder('LastActivity', fetchPatientReferrals)" style="cursor: pointer;") Last Activity
      i(style="font-weight: bolder;") {{ LastActivityColumnSortingIcon }}
    .td Show
  .tr(
    v-if='referrals'
    v-for="referral in referrals",
    :key="referral.id",
    @click="openReferralRecordOverlay(referral)"
  )
    .td
      div {{ getDateFromUnixFormatted(referral.scheduledAt) }}
      .second-row-md-grey {{ getTimeFromUnixFormatted(referral.scheduledAt)}}
    .td.bold {{ getUserFormatted(referral.patient) }}
    .td {{ referral.clinic.name }}
    .td
      .red(v-if = "referral.visitStatus == 'CANCELED' ") Cancelled
      .red(v-if = "referral.visitStatus == 'NO_SHOW' ") No Show
      .green(v-if = "referral.visitStatus == 'COMPLETED' ") Completed
      
      .second-row-sm-grey Updated {{ getRelativeTimeFromUnixFormatted(referral.updatedAt) }}
    .td
      base-icon(v-if="referral.visitStatus == 'COMPLETED' " name="yes")
      base-icon(v-else name="no")
    .td(@click.stop)
      ThreeDots
        template(#entries)
          router-link.dot_popup_entry(:to="{name: 'primary-care-community-organization.scheduling.select-schedule', params: {idPatient: referral.idPatient, idClinic: referral.idClinic, idReferral: referral.id  } }") Schedule Again
          .dot_popup_entry(@click="openPatientDetailsOverlay(referral.patient)" v-if="accessToPatients") View Patient record
          
Pagination(
  v-if="pagination && referrals != null && referrals.length",
  :pagination="pagination",
  :reloadFn="fetchPatientReferrals"
  @changePagination="changePagination"
)
.no-results(v-if="referrals != null && !referrals.length") 
  base-icon(name="exclamation") 
  span No matching records

ReferralRecordOverlay(
  :key="`${componentKey}-1`",
  :showReferral="showReferral",
  :isOpen="isReferralRecordOverlayOpen",
  tabType="archived"
  @closeReferralRecordOverlay="closeReferralRecordOverlay",
  @refreshPatientReferrals="refreshPatientReferrals"
)
AppointmentNotesOverlay(
  :isOpen="isAppointmentNotesOverlayOpen",
  @closeAppointmentNotesOverlay="closeAppointmentNotesOverlay"
)
PatientDetailsOverlay(
  :key="`${componentKey}-2`",
  :isOpen="isPatientDetailsOverlayOpen",
  :showPatient="showPatient"
  @closePatientDetailsOverlay="closePatientDetailsOverlay",
)

</template>

<script>
import { defineComponent } from "vue";
import TableTitle from "@/components/TableTitle";
import DateSelector from "@/components/DateSelector";
import Pagination from "@/components/Pagination";
import listPagesMixin from "@/mixins/listPagesMixin";

import ReferralRecordOverlay from "@/components/primary-care-community-organization/patient-referrals/overlays/ReferralRecordOverlay";
import AppointmentNotesOverlay from "@/components/overlays/side-overlays/AppointmentNotesOverlay";
import ThreeDots from "@/components/primary-care-community-organization/patient-referrals/ThreeDots";
import PatientDetailsOverlay from "@/components/primary-care-community-organization/patient-referrals/overlays/PatientDetailsOverlay";
import { fetchCommunityCareOrganizationById } from "@/api/communityOrganization";
import { fetchPatientReferrals } from "@/api/patientReferral";
import { apiStatus, apiStatusComputedFactory } from "@/api";
const { IDLE, PENDING, SUCCESS, ERROR } = apiStatus;
import {
  getDateFromUnixFormatted,
  getTimeFromUnixFormatted,
  getRelativeTimeFromUnixFormatted,
  getUserFormatted,
  getDoctorFormatted,
} from "@/helpers";
import sortCCOReferrals from "@/mixins/sortCCOReferrals";

export default defineComponent({
  components: {
    TableTitle,
    DateSelector,
    Pagination,
    ReferralRecordOverlay,
    AppointmentNotesOverlay,
    PatientDetailsOverlay,
    ThreeDots,
  },
  mixins: [listPagesMixin, sortCCOReferrals],
  computed: {
    ...apiStatusComputedFactory("fetchPatientReferralsStatus"),
  },

  data() {
    return {
      accessToPatients: false,
      searchKeyword: "",
      isReferralRecordOverlayOpen: false,
      isAppointmentNotesOverlayOpen: false,
      isPatientDetailsOverlayOpen: false,
      fetchPatientReferralsStatus: IDLE,
      referrals: null,
      date: null,
      showReferral: null,
      showPatient: null,
    };
  },
  emits: ["refreshAppointmentsSummary"],

  methods: {
    refreshPatientReferrals() {
      this.fetchPatientReferrals();
      this.$emit("refreshAppointmentsSummary");
    },
    getDateFromUnixFormatted,
    getTimeFromUnixFormatted,
    getRelativeTimeFromUnixFormatted,
    getUserFormatted,
    getDoctorFormatted,
    filterByDate(date) {
      this.date = date;
      this.fetchPatientReferrals();
    },
    async fetchPatientReferrals() {
      this.fetchPatientReferralsStatus = PENDING;
      const order = this.listOrder
        ? {
            by: this.listOrder.by,
            direction: this.listOrder.direction,
          }
        : null;

      try {
        const res = await fetchPatientReferrals({
          params: {
            ...this.reqPagination,
            order,
            idCommunity: this.$route.params.idCommunity,
            include: ["referringDoctor", "patient", "clinic", "cancelled"],
            stage: "ARCHIVES",
            scheduledAt: this.date ?? null,
            ...(this.searchKeyword
              ? { searchKeyword: this.searchKeyword }
              : {}),
          },
        });

        this.referrals = res.data.patientReferrals;
        this.pagination = res.data.pagination;
        this.fetchPatientReferralsStatus = SUCCESS;
      } catch (err) {
        this.fetchPatientReferralsStatus = ERROR;
        console.error(err);
      }
    },
    openPatientDetailsOverlay(patient) {
      this.componentKey = this.componentKey + 1;
      this.showPatient = patient;
      this.isPatientDetailsOverlayOpen = true;
    },
    closePatientDetailsOverlay() {
      this.isPatientDetailsOverlayOpen = false;
    },
    openReferralRecordOverlay(referral) {
      this.componentKey = this.componentKey + 1;
      this.showReferral = referral;
      this.isReferralRecordOverlayOpen = true;
    },
    closeReferralRecordOverlay() {
      this.isReferralRecordOverlayOpen = false;
    },

    openAppointmentNotesOverlay() {
      this.isAppointmentNotesOverlayOpen = true;
    },
    closeAppointmentNotesOverlay() {
      this.isAppointmentNotesOverlayOpen = false;
    },
    async fetchCommunityCareOrganizationById() {
      try {
        const res = await fetchCommunityCareOrganizationById(
          this.$route.params.idCommunity
        );
        this.accessToPatients = res.data.hasPatientAccess;
      } catch (err) {
        console.error(err);
      }
    },
  },
  created() {
    if (this.$route.params.idCommunity === null) return;
    this.fetchPatientReferrals();
    this.fetchCommunityCareOrganizationById();
  },
});
</script>

<style scoped lang="scss">
.table {
  overflow: visible;
}

.active .three_dots_wrap .dot_popup {
  display: inline;
}
.tr {
  cursor: pointer;
}
.td {
  &:nth-child(1) {
    width: math.percentage(math.div(180, $tabContentCardWidth));
  }
  &:nth-child(2) {
    width: math.percentage(math.div(213, $tabContentCardWidth));
  }
  &:nth-child(3) {
    width: math.percentage(math.div(230, $tabContentCardWidth));
  }
  &:nth-child(4) {
    width: math.percentage(math.div(450, $tabContentCardWidth));
  }
  &:nth-child(5) {
    width: math.percentage(math.div(70, $tabContentCardWidth));
  }
  &:nth-child(6) {
    overflow: visible;
  }
}
</style>
