<template lang="pug">
FullScreenOverlaySlot(
  :isOpen="isOpen",
  headerIcon="icc-logo",
  title="ADD NEW PATIENT"
  @closeOverlay="closeOverlay"
)
  template(#breadcrumbs)
    h1.breadcrumbs-title ILLINOIS PRIMA CARES > PROVIDER REFERRAL
  template(#actionSection)

  template(#content)
    .force_autocomplete_safari
      input(tabindex="-1" name="e-mail" type="email")
      input(tabindex="-1" name="password" type="password")

  
    .card.card--blue-bg(style="padding-top: 52px;")
      form#add-user(
        @submit.prevent="savePatient",
        novalidate
        autocomplete="off"
      )
        input(autocomplete="false" name="hidden" type="text" style="display:none;")
        .columns.is-variable
          .column.is-one-third
            .form-group(:class="{ 'form-group__invalid': v$.patient.first.$errors.length }")
              label(for="first-name") First Name (Required)
              input#first-name(type="text" v-model="patient.first" @blur="v$.patient.first.$touch()")
              .form-group__errors(v-if="v$.patient.first.$dirty && v$.patient.first.$errors.length")
                .form-group__error(v-if="v$.patient.first.required.$invalid") First Name is required!
          .column.is-one-third
            .form-group(:class="{ 'form-group__invalid': v$.patient.last.$errors.length }")
              label(for="last-name") Last Name (Required)
              input#last-name(type="text" v-model="patient.last" @blur="v$.patient.last.$touch()")
              .form-group__errors(v-if="v$.patient.last.$dirty && v$.patient.last.$errors.length")
                .form-group__error(v-if="v$.patient.last.required.$invalid") Last Name is required!
          .column.is-one-third
            .form-group(:class="{ 'form-group__invalid': v$.patient.dob.$errors.length }")
              label(for="date-of-birth") Date of Birth (Required)
              input#date-of-birth(
                type="text"
                placeholder="MM/DD/YYYY" 
                v-model="patient.dob" 
                @blur="v$.patient.dob.$touch()" 
                v-maska="'##/##/####'" 
                @maska="dobRawValue"
                )
              .form-group__errors(v-if="v$.patient.dob.$dirty && v$.patient.dob.$errors.length")
                .form-group__error Date of birth is required!
        .columns.is-variable
          .column.is-one-third.flex.flex--flex-start
            .form-group.setMarginRight
              label(for="home-phone") Home Phone Number
              input#home-phone(
                type="text" 
                placeholder="xxx-xxx-xxxx" 
                v-model="patient.contact.homePhone"
                v-maska="'###-###-####'" 
                @maska="homePhoneRawValue"
              )
            .form-group
              label(for="ext") Ext.
              input#ext.ext(type="text" v-model="patient.contact.homePhoneExtension")
          .column.is-one-third.flex.flex--flex-start
            .form-group.setMarginRight(:class="{ 'form-group__invalid': v$.patient.contact.cellPhone.$errors.length }")
              label(for="cell-phone") Cell Phone Number (Required)
              input#cell-phone(
                type="text" 
                placeholder="xxx-xxx-xxxx" 
                v-model="patient.contact.cellPhone" 
                @blur="v$.patient.contact.cellPhone.$touch()" 
                v-maska="'###-###-####'" 
                @maska="cellPhoneRawValue"
              )
              .form-group__errors(v-if="v$.patient.contact.cellPhone.$dirty && v$.patient.contact.cellPhone.$errors.length")
                .form-group__error(v-if="v$.patient.contact.cellPhone.required.$invalid") Cell phone is required!
                .form-group__error(v-if="v$.patient.contact.cellPhone.minLength.$invalid") Cell phone is required!
            .form-group
              label(for="cell-ext") Ext.
              input#cell-ext.ext(type="text" v-model="patient.contact.cellPhoneExtension")

        .columns.is-variable
          .column
            .form-group(:class="{ 'form-group__invalid': v$.patient.address.address.$errors.length }")
              label(for="home-address") Home Address (Required)
              input#home-address(type="text" v-model="patient.address.address" @blur="v$.patient.address.address.$touch()")
              .form-group__errors(v-if="v$.patient.address.address.$dirty && v$.patient.address.address.$errors.length")
                .form-group__error(v-if="v$.patient.address.address.required.$invalid") Home address is required!
        Address(
          @addressResults="addressResults"
          :institution="patient"
          textSize=""
        )

        .columns.is-varialbe 
          .column.is-two-thirds
            .info-pair
              .label Patient Notes
              textarea(style="height:167px;width: calc(100% - 6px);" v-model="patient.notes") 


  template(#footer)
    BaseBtn.btn.lg.red-outline(href="#", @click.prevent="closeOverlay") CANCEL CHANGES
    BaseBtn.btn.lg.green(
      type="submit",
      form="add-user"
      :disabled="v$.$invalid || isSubmitting"
      :class="{spinner: isSubmitting }"
    ) SAVE AND EXIT
    
</template>

<script>
import { defineComponent } from "vue";
import moment from "moment";
import FullScreenOverlaySlot from "@/components/overlays/FullScreenOverlaySlot.vue";
import Address from "@/components/Address.vue";

import { createPatientOnCommunityCareOrganization } from "@/api/communityOrganization";
import { apiStatus, apiStatusComputedFactory } from "@/api";
const { IDLE, PENDING, SUCCESS, ERROR } = apiStatus;

import useVuelidate from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import { maska } from "maska";

export default defineComponent({
  directives: { maska },
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    FullScreenOverlaySlot,
    Address,
  },
  emits: ["closeAddNewClinicPatientOverlayOpen", "reloadPatients"],

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  validations() {
    let args = {
      patient: {
        first: { required },
        last: { required },
        dob: {
          required,
          minLength: minLength(10),
          function() {
            let unixDistributed = new Date(this.patient.dob).getTime();
            if (!unixDistributed) {
              return false;
            }
            return true;
          },
        },

        address: {
          address: { required },
        },
        contact: {
          cellPhone: {
            required,
            minLength: minLength(12),
          },
        },
      },
    };

    return args;
  },
  data() {
    return {
      hidePolypsFoundSelectLabel: false,
      hidePolypsRemovedSelectLabel: false,
      createPatientOnCommunityCareOrganizationStatus: IDLE,
      idClinic: this.$route.params.idClinic,
      isSubmitting: false,

      patient: {
        address: {
          address: null,
          place: null,
          state: null,
          zipCode: null,
          idPlace: null,
          idState: null,
          idZipCode: null,
        },
        contact: {
          businessPhone: null,
          businessPhoneExtension: null,
          cellPhone: null,
          cellPhoneRaw: null,
          cellPhoneExtension: null,
          homePhone: null,
          homePhoneRaw: null,
          homePhoneExtension: null,
        },
        dob: null,
        dobRaw: null,
        first: null,
        last: null,
        idClinic: this.$route.params.idClinic,

        notes: null,
      },
    };
  },

  computed: {
    ...apiStatusComputedFactory([
      "createPatientOnCommunityCareOrganizationStatus",
    ]),
  },
  methods: {
    homePhoneRawValue: function (event) {
      this.patient.contact.homePhoneRaw = event.target.dataset.maskRawValue;
    },
    cellPhoneRawValue: function (event) {
      this.patient.contact.cellPhoneRaw = event.target.dataset.maskRawValue;
    },
    dobRawValue: function (event) {
      this.patient.dobRaw = event.target.dataset.maskRawValue;
    },
    addressResults(city, state, zip, idPlace, idState, idZipCode) {
      this.patient.address.place = city;
      this.patient.address.state = state;
      this.patient.address.zipCode = zip;
      this.patient.address.idPlace = parseInt(idPlace);
      this.patient.address.idState = parseInt(idState);
      this.patient.address.idZipCode = parseInt(idZipCode);
    },
    async createPatientOnCommunityCareOrganization() {
      this.isSubmitting = true;
      this.createPatientOnCommunityCareOrganizationStatus = PENDING;
      let sendParams = JSON.parse(JSON.stringify(this.patient));
      try {
        sendParams.dob = sendParams.dobRaw;
        delete sendParams.dobRaw;
        sendParams.contact.homePhone = sendParams.contact.homePhoneRaw;
        delete sendParams.contact.homePhoneRaw;
        sendParams.contact.cellPhone = sendParams.contact.cellPhoneRaw;
        delete sendParams.contact.cellPhoneRaw;
        sendParams.idCommunity = this.$route.params.idCco;
        await createPatientOnCommunityCareOrganization(
          this.$route.params.idCco,
          sendParams
        );
        this.createPatientOnCommunityCareOrganizationStatus = SUCCESS;
      } catch (err) {
        console.log(err);
        this.createPatientOnCommunityCareOrganizationStatus = ERROR;
      } finally {
        this.isSubmitting = false;
      }
    },
    savePatient() {
      this.createPatientOnCommunityCareOrganization().then((response) => {
        this.$emit("reloadPatients", this.patient);
        this.closeOverlay();
      });
    },
    closeOverlay() {
      this.$emit("closeAddNewClinicPatientOverlayOpen");
    },
  },
});
</script>

<style scoped lang="scss">
.setMarginRight {
  margin-right: 36px;
}

.ext {
  max-width: 100px;
}

.font-14 {
  font-size: 14px;
}

h2 {
  margin-bottom: 62px;
  font-size: 28px;
}

.appointment-history {
  &.card {
    padding-bottom: 0px;
  }

  .td {
    &:nth-child(1) {
      width: math.percentage(math.div(180, $tabContentCardWidth));
    }

    &:nth-child(2) {
      width: math.percentage(math.div(212, $tabContentCardWidth));
    }

    &:nth-child(3) {
      width: math.percentage(math.div(230, $tabContentCardWidth));
    }

    &:nth-child(4) {
      width: math.percentage(math.div(200, $tabContentCardWidth));
    }

    &:nth-child(5) {
      width: math.percentage(math.div(180, $tabContentCardWidth));
    }

    &:nth-child(6) {
      width: math.percentage(math.div(178, $tabContentCardWidth));
    }
  }
}

.primary-care-referrals {
  &.card {
    padding-bottom: 0px;
  }

  .td {
    &:nth-child(1) {
      width: math.percentage(math.div(180, $tabContentCardWidth));
    }

    &:nth-child(2) {
      width: math.percentage(math.div(645, $tabContentCardWidth));
    }

    &:nth-child(3) {
      width: math.percentage(math.div(355, $tabContentCardWidth));
    }
  }
}

.fit-history {
  &.card {
    padding-bottom: 48px;
  }

  .td {
    &:nth-child(1) {
      width: math.percentage(math.div(190, $tabContentCardWidth));
    }

    &:nth-child(2) {
      width: math.percentage(math.div(147, $tabContentCardWidth));
    }

    &:nth-child(3) {
      width: math.percentage(math.div(220, $tabContentCardWidth));
    }

    &:nth-child(4) {
      width: math.percentage(math.div(127, $tabContentCardWidth));
    }

    &:nth-child(5) {
      width: math.percentage(math.div(198, $tabContentCardWidth));
    }

    &:nth-child(6) {
      width: math.percentage(math.div(106, $tabContentCardWidth));
    }

    &:nth-child(7) {
      width: math.percentage(math.div(187, $tabContentCardWidth));
    }
  }
}

.outside-icc {
  &.card {
    padding-bottom: 48px;
  }

  .td {
    &:nth-child(1) {
      width: math.percentage(math.div(187, $tabContentCardWidth));
    }

    &:nth-child(2) {
      width: math.percentage(math.div(147, $tabContentCardWidth));
    }

    &:nth-child(3) {
      width: math.percentage(math.div(660, $tabContentCardWidth));
    }

    &:nth-child(4) {
      width: math.percentage(math.div(185, $tabContentCardWidth));
    }
  }
}

.table .td:first-child {
  padding-left: 35px;
}

.card {
  padding: 35px $secondaryCardsSidePadding 68px;
}

.negative-margin {
  margin-left: -$secondaryCardsSidePadding;
  margin-right: -$secondaryCardsSidePadding;
}

.columns.is-variable {
  --columnGap: 1.575%;
}

.columns + .hr {
  background-color: $primaryBgDarkGrey;
  margin-top: -21px;
  margin-bottom: 24px;
}

.card > .columns:last-child.info-pair,
.card > .columns:last-child .info-pair {
  margin-bottom: 0;
}

// Page specific styles----------------------------------------
.insurance-status {
  margin-bottom: 21px;

  p {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.29;
    letter-spacing: 0.07px;
    color: #000;
  }
}

.rescheduled {
  .label {
    & > span {
      position: relative;
    }

    .svg-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: calc(100% + 20px);
    }
  }
}

.breadcrumbs-title {
  margin: 0;
  color: $primaryLightBlue;
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.12px;
}

#add-user .flex {
  align-items: normal;
}
.force_autocomplete_safari {
  position: fixed;
  width: 1px;
  left: -50px;
}
</style>
