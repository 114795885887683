<template lang="pug">
BasePageTitle(
  pageTitleIcon="colon",
  title="Illinois Colon Cares: FIT Registry"
)
  template(#breadcrumbs)
    li
      router-link(:to="{name: 'community-care-organization-fit-kit-status', params: { idCommunity }}") FIT Kit Status
    li
       router-link(:to="{name: 'community-care-organization-new-fit-kit', params: { idCommunity }}") Add New FIT
  template(#actionSection, v-if="showAddNewFitBtn")
     BaseBtn.lg.blue(:to="{name: 'community-care-organization-new-fit-kit', params: { idCommunity }}") ADD NEW FIT
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    showAddNewFitBtn: {
      type: Boolean,
      default: true,
    },
    idCommunity: {
      type: Number,
      required: true,
    },
  },
});
</script>
