// @ts-nocheck
export default {
  data() {
    return {
      sortings: {
        ModuleName: [
          undefined,
          { by: "module-name", icon: "▲", direction: "asc" },
          { by: "module-name", icon: "▼", direction: "desc" },
        ],
      },
      currentSortKey: "ModuleName",
      currentSortIndex: 0,
      listOrder: undefined,
    };
  },

  computed: {
    ModuleNameColumnSortingIcon() {
      if (this.currentSortKey === "ModuleName") {
        return this.sortings.ModuleName[this.currentSortIndex]?.icon || "▼▲";
      }
      return "▼▲";
    },
  },

  methods: {
    changeListOrder(key, callback) {
      if (this.currentSortKey === key) {
        this.updateCurrentSortingIndex(callback);
        return;
      }

      this.currentSortKey = key;
      this.currentSortIndex = 0;
      this.updateCurrentSortingIndex(callback);
    },
    updateCurrentSortingIndex(callback) {
      if (
        this.currentSortIndex ===
        this.sortings[this.currentSortKey].length - 1
      ) {
        this.currentSortIndex = 0;
        this.listOrder = this.sortings[this.currentSortKey][0];
        callback();
        return;
      }

      this.currentSortIndex++;
      this.listOrder =
        this.sortings[this.currentSortKey][this.currentSortIndex];
      return callback();
    },
  },
};
