<template lang="pug">
teleport(to="body")
  transition(name="side-overlay-backdrop" appear)
    .side-overlay-backdrop(v-if="isOpen")

  transition(name="side-overlay" appear)
    section.side-overlay(
      v-if="isOpen",
      :class="{'side-overlay--has-scrollbox': $slots.scrollbox,'side-overlay--has-footer': $slots.footer,'large' : isLarge}"
    )
      header.side-overlay__header(:style="{'background': isTour ? 'rgba(90, 149, 184, 0.8)' : 'inherit' }")
        h1.title(v-html="title") 
        a.btn-close-side-overlay(
          href="#",
          @click.prevent="$emit('closeOverlay')"
        )
          base-icon(:name="'x'")
      .side-overlay__body.scroll-shadows(:style="{'background': isTour ? 'rgba(90, 149, 184, 0.8)' : 'inherit' }")
        slot(name="contentTop", v-if="$slots.contentTop")
        .scroll-box(v-if="$slots.scrollbox")
          .scroll-box__inner
            slot(name="scrollbox")
        slot(name="contentBottom", v-if="$slots.contentBottom")
      footer.side-overlay__footer(v-if="$slots.footer")(:style="{'background': isTour ? 'rgba(90, 149, 184, 0.8)' : 'inherit' }")
        slot(name="footer")
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  components: {},
  emits: ["closeOverlay"],

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    isLarge: {
      type: Boolean,
      default: false,
    },
    isTour: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {};
  },

  computed: {},

  watch: {
    isOpen: {
      handler(val) {
        if (document.getElementById("fullscreen-overlay") !== null) return;
        if (val === true) {
          document.documentElement.style.overflow = "hidden";
        } else {
          document.documentElement.style.overflow = "auto";
        }
      },
    },
  },

  beforeUnmount() {
    if (document.getElementById("fullscreen-overlay") !== null) return;
    document.documentElement.style.overflow = "auto";
  },
});
</script>

<style scoped lang="scss">
$sideOverlayHorizontalPadding: 41px;
$sideOverlayHeaderHeight: 75px;
$sideOverlayFooterHeight: 110px;
.side-overlay {
  &.large {
    width: 1000px;
  }
  position: fixed;
  backface-visibility: hidden;
  width: 570px;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  padding: $sideOverlayHeaderHeight 0 0;
  background-color: #fff;
  z-index: 101;
  overflow: hidden;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: $sideOverlayHeaderHeight;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 0 $sideOverlayHorizontalPadding;
    padding-top: 26px;
    .title {
      margin: 0;
    }
    .btn-close-side-overlay {
      display: block;
      .svg-icon--x {
        width: 20px;
        height: 20px;
        padding: 2px;
        color: $primaryMidGrey;
      }
    }
  }
  &__body {
    padding: $sideOverlayVerticalPadding $sideOverlayHorizontalPadding
      $sideOverlayVerticalPadding $sideOverlayHorizontalPadding;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    overflow-y: auto;
    &.scroll-shadows {
      overflow: auto;
      background: linear-gradient(white 30%, rgba(255, 255, 255, 0)),
        linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
        radial-gradient(
          farthest-side at 50% 0,
          rgba(0, 0, 0, 0.14),
          rgba(0, 0, 0, 0)
        ),
        radial-gradient(
            farthest-side at 50% 100%,
            rgba(0, 0, 0, 0.14),
            rgba(0, 0, 0, 0)
          )
          0 100%;
      background-repeat: no-repeat;
      background-color: white;
      background-size: 100% 40px, 100% 40px, 100% 6px, 100% 6px;

      // Opera doesn’t support this in the shorthand
      background-attachment: local, local, scroll, scroll;
    }
  }
  &__footer {
    display: flex;
    align-items: center;
    height: $sideOverlayFooterHeight;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    // background-color: white;
    padding: 0 $sideOverlayHorizontalPadding;
  }
  &.side-overlay-enter-active {
    animation: side-overlay 0.4s ease-out;
    pointer-events: auto;
  }
  &.side-overlay-leave-active {
    animation: side-overlay 0.4s ease-in reverse;
    pointer-events: none;
  }

  &--has-scrollbox {
    display: flex;
    overflow-y: auto;
    .scroll-box {
      flex-grow: 1;
      min-height: 130px;
      overflow-y: auto;
      margin-bottom: 38px;
      padding: 32px 23px 21px;
      border: solid 1px $primaryBgDarkGrey;
    }
  }
  &--has-footer {
    padding-bottom: 0;
    padding-bottom: $sideOverlayFooterHeight;
    .scroll-box:last-child {
      margin-bottom: 0;
    }
  }
}

.side-overlay-backdrop {
  position: fixed;
  backface-visibility: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 93, 147, 0.65);
  z-index: 101;
  &.side-overlay-backdrop-enter-active {
    animation: side-overlay-backdrop 0.4s linear;
    pointer-events: auto;
  }
  &.side-overlay-backdrop-leave-active {
    animation: side-overlay-backdrop 0.3s ease-out reverse;
    pointer-events: none;
  }
}
</style>

<style lang="scss">
@keyframes side-overlay-backdrop {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes side-overlay {
  from {
    transform: translateX(calc(100% + 10px));
  }
  to {
    transform: translateX(0);
  }
}
</style>
