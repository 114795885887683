<template lang="pug">
form(@submit.prevent="logIn", novalidate)
  .form-group(:class="{ 'form-group__invalid': v$.email.$errors.length }")
    label(for="email") Email
    input#email(v-model="email", type="email", @blur="v$.email.$touch()")
    .form-group__errors(v-if="v$.email.$dirty && v$.email.$errors.length")
      .form-group__error(v-if="v$.email.email.$invalid") Invalid email format
      .form-group__error(v-if="v$.email.required.$invalid") Email is required
  .form-group(:class="{ 'form-group__invalid': v$.password.$errors.length }")
    label(for="password") Password
    input#password(v-model="password", type="password", @blur="v$.password.$touch()")
    .form-group__errors(v-if="v$.password.$errors.length")
      .form-group__error(v-if="v$.password.required.$invalid") Password is required
  .form-actions
    router-link.forgot-pass-link(:to="{name: 'forgot-password-page'}") Forgot Your Password?
    BaseBtn.x-lg.blue(
      type="submit",
      :disabled="v$.$invalid || isLoggingIn"
      :class="{spinner: isLoggingIn}"
    ) Login
  .form-error-main(v-show="formErr") {{formErr}}
</template>

<script>
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import { login, requestForgetPassword } from "@/api/accountApi";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { getProviderGroupIDforManager } from "@/api/providerGroupApi";

export default defineComponent({
  components: {},

  setup() {
    return { v$: useVuelidate() };
  },

  data() {
    return {
      email: "",
      password: "",
      isLoggingIn: false,
      formErr: null,
    };
  },

  validations() {
    return {
      email: { required, email },
      password: { required },
    };
  },

  computed: {
    ...mapGetters([
      "isSuperAdmin",
      "isHospitalManager",
      "isClinicHealthSystemManager",
      "isClinicStaff",
      "isHospitalStaff",
      "isCommunityCareOrganizationManager",
      "isCommunityCareOrganizationStaff",
      "isProvider",
      "isProviderManager",
    ]),
  },

  methods: {
    async logIn() {
      this.isLoggingIn = true;
      try {
        const res = await login(this.email, this.password);
        const user = res.data.user;

        this.$store.commit("COMMIT_JWT", res.data.token);
        this.$store.commit("COMMIT_USER_ID", user.id);
        this.$store.commit("COMMIT_USER_EMAIL", user.email);
        this.$store.commit("COMMIT_USER_FIRST_NAME", user.first);
        this.$store.commit("COMMIT_USER_LAST_NAME", user.last);
        this.$store.commit("COMMIT_USER_ROLE", user.idUserRole);
        this.$store.commit("COMMIT_USER_ROLE_CONTEXT", res.data.roleContext);
        localStorage.setItem("userSigninCount", user.signInCount);

        if (this.isProviderManager) {
          try {
            const response = await getProviderGroupIDforManager();
            const managerOfProviderID = response.data.providerGroupID;
            const managerOfProviderGroupName = response.data.providerGroupName;
            this.$store.commit(
              "COMMIT_MANAGER_OF_PROVIDER_ID",
              managerOfProviderID
            );
            this.$store.commit(
              "COMMIT_MANAGER_OF_PROVIDER_GROUP_NAME",
              managerOfProviderGroupName
            );
          } catch (error) {
            console.log(error);
          }
        }
        if (user.signInCount === 0) {
          const {
            data: { code },
          } = await requestForgetPassword(user.email, true);
          this.$router.push({ name: "reset-password", params: { code } });
        } else {
          this.formErr = null;
          if (this.isHospitalManager) {
            this.$store.dispatch("impersonateHospitalManager");
          } else if (this.isClinicHealthSystemManager) {
            this.$store.dispatch("impersonateClinicHealthSystemManager", [
              res.data.roleContext.clinicHealthSystem,
              true,
            ]);
          } else if (
            this.isCommunityCareOrganizationManager ||
            this.isCommunityCareOrganizationStaff
          ) {
            await this.$store.dispatch(
              "getCCOinfo",
              res.data.roleContext.communityCareOrganization
            );
            this.$store.dispatch("navigateAccordingToRole");
          } else {
            this.$store.dispatch("navigateAccordingToRole");
          }
        }
        this.$store.dispatch("startIdleTimeout");
      } catch (err) {
        this.formErr =
          err?.response?.data?.message || "Could not log in, please try again.";
      } finally {
        this.isLoggingIn = false;
        this.clearForm();
      }
    },

    clearForm() {
      this.email = "";
      this.password = "";
      this.$nextTick(() => {
        this.v$.$reset();
      });
    },
  },
});
</script>

<style scoped lang="scss">
.form-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.forgot-pass-link {
  font-size: 14px;
  line-height: 1.29;
  color: #000;
  text-decoration: none;
  transition: color 3s linear;
  &:hover {
    color: $primaryLightBlue !important;
  }
}
.form-error-main {
  margin-top: 35px;
}
</style>
