<template lang="pug">
teleport(to="body")
  transition(name="full-screen-overlay" appear)
    section#fullscreen-overlay.fullscreen-overlay(v-if="isOpen")
      header.overlay-header
        .title-section
          base-icon.page-title-icon(:name="headerIcon" :nameString="headerIconString")
          .title-section-right
            ul.breadcrumbs
              slot(name="breadcrumbs")
            .hr
            h1.title {{title}}
        .action-section
          slot(name="actionSection")
          a.btn-close-full-screen-overlay(
            href="#",
            @click.prevent="$emit('closeOverlay')"
          )
            base-icon(:name="'close-circle'")
      .overlay-body
        .overlay-body__container(:style="body_container_style")
          slot(name="content")
      .full-screen-overlay-footer(v-if="isFooterSlotVisible")
        slot(name="footer")
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  components: {},
  emits: ["closeOverlay"],

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    headerIcon: {
      type: String,
      required: true,
    },
    headerIconString: {
      required: false,
      default: "",
    },
    title: {
      type: String,
      required: true,
    },
  },

  data() {
    return {};
  },

  computed: {
    isFooterSlotVisible() {
      return this.$slots["footer"];
    },
    body_container_style() {
      let paddingBottom = 138;
      if (!this.$slots["footer"]) {
        paddingBottom = 15;
      }
      return {
        "padding-bottom": paddingBottom + "px",
      };
      // if(this.$slots["footer"]){
      //   console.log("case 1");
      //   return {
      //     "padding-bottom:": "15px"
      //   }
      // } else {
      //   console.log("case 2");
      //   return "";
      // }
    },
  },
  watch: {
    isOpen: {
      handler(val) {
        if (val === true) {
          document.documentElement.style.overflow = "hidden";
        } else {
          document.documentElement.style.overflow = "auto";
        }
      },
    },
  },

  beforeUnmount() {
    document.documentElement.style.overflow = "auto";
  },
});
</script>

<style scoped lang="scss">
$overlayHeaderHeight: 136px;
$overlayFooterHeight: 98px;

.fullscreen-overlay {
  position: fixed;
  backface-visibility: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $overlayBackgroundDarkBlue;;
  z-index: 101;
  &.full-screen-overlay-enter-active {
    animation: fullscreen-overlay 0.4s linear;
    pointer-events: auto;
  }
  &.full-screen-overlay-leave-active {
    animation: fullscreen-overlay 0.4s linear reverse;
    pointer-events: none;
  }

  .overlay-header {
    height: $overlayHeaderHeight;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    backface-visibility: hidden;
    top: 0;
    left: 0;
    right: 0;
    padding-left: 42px;
    padding-right: 30px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.16);
    background-color: #fff;
    z-index: 101;
    .title-section {
      display: flex;
      align-items: center;
      flex: 1;
      margin-right: 40px;
    }
    .title-section-right {
      flex: 1;
    }
    .page-title-icon {
      top: 0;
      font-size: 86px;
      margin-right: 28px;
      color: $primaryLightBlue;
    }
    .breadcrumbs {
      display: flex;
      align-items: center;
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        position: relative;
        font-size: 24px;
        font-weight: 700;
        line-height: 1.1;
        letter-spacing: 0.12px;
        color: $primaryLightBlue;
        text-transform: uppercase;
        &:not(:last-child)::after {
          content: ">";
          display: inline-block;
          margin: 0 15px;
          font-weight: 400;
        }
      }
    }
    .title {
      margin: 0;
      font-size: 24px;
      font-weight: 500;
      line-height: 1.1;
      letter-spacing: 0.12px;
      color: #000000;
      text-transform: uppercase;
    }
    .hr {
      margin-top: 15px;
      margin-bottom: 12px;
      display: block;
      height: 2px;
      background-color: $primaryBgDarkGrey;
    }
    .action-section {
      display: flex;
      align-items: center;
    }
    .btn-close-full-screen-overlay {
      display: block;
      width: 44px;
      height: 44px;
      margin-left: 27px;
      text-decoration: none;
      color: #000;
      transition: opacity 0.3s linear;
      &:hover {
        opacity: 0.5;
      }
      .svg-icon--close-circle {
        top: 0;
        width: 100%;
        height: inherit;
      }
    }
  }

  .overlay-body {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    &__container {
      position: relative;
      width: 100%;
      max-width: $contentWidth;
      margin-left: auto;
      margin-right: auto;
      padding-top: $overlayHeaderHeight + 70px;
      padding-bottom: $overlayFooterHeight + 40px;
    }
  }

  .full-screen-overlay-footer {
    height: $overlayFooterHeight;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    backface-visibility: hidden;
    bottom: 0;
    left: 0;
    right: 0;
    padding-left: 38px;
    padding-right: 38px;
    background-color: #fff;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.16);
    z-index: 101;
    .btn {
      min-width: 345px !important;
    }
  }
}
</style>

<style lang="scss">
.full-screen-overlay-footer {
  .btn {
    min-width: 345px !important;
  }
}
@keyframes fullscreen-overlay {
  from {
    transform: translateY(calc(100% + 10px));
  }
  to {
    transform: translateY(0);
  }
}
</style>
