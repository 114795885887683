<template lang="pug">
base-icon(
  v-if="fileId",
  name="eye-green",
  style="font-size: 42px;cursor: pointer",
  @click.prevent="getAndOpenFile"
)
base-icon(v-else="", name="eye-red", style="font-size: 42px;")
</template>

<script>
import { defineComponent } from "vue";
import { fetchFileById } from "@/api/filesApi";
import { getFileSignedUrl } from "@/helpers";

export default defineComponent({
  props: {
    fileId: {
      type: Number,
    },
  },

  methods: {
    async getAndOpenFile() {
      try {
        const resFile = await fetchFileById(this.fileId);
        const file = resFile.data.file;
        file.token = resFile.data.token;
        window.open(getFileSignedUrl(file), "_blank");
      } catch (err) {
        console.error(err);
      }
    },
  },
});
</script>
