<template lang="pug">
span.box-link-md
  span.top
    span.number {{number}}
    base-icon(name="chevron-md")
  span.text(v-html="text")
    
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    text: {
      type: String,
      required: true,
    },
    number: {
      type: [String, Number],
      required: true,
    },
  },
});
</script>

<style scoped lang="scss">
.box-link-md {
  display: block;
  position: relative;
  height: 120px;
  border-radius: 2px;
  padding: 8px 16px 11px;
  transition: all 0.3s linear;
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding-bottom: 6px;
    border-bottom: 1px solid currentColor;
    font-size: 36px;
    line-height: 1.22;
    letter-spacing: 0.2px;
    .svg-icon--chevron-md {
      top: 0;
      margin-left: 13px;
      font-size: 24px;
      transform: rotate(-90deg);
    }
  }
  .text {
    display: block;
    overflow: hidden;
    font-size: 16px;
    line-height: 1.19;
    letter-spacing: 0.09px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &.red {
    color: #fff;
    background-color: $secondaryRed;
    &:hover {
      background-color: $secondaryDarkRed;
    }
  }
  &.yellow {
    color: #000;
    background-color: $secondaryYellow;
    &:hover {
      background-color: $secondaryDarkYellow;
    }
  }
  &.yellow-outline {
    color: #000;
    border: solid 3px $secondaryYellow;
    &:hover {
      border-color: $secondaryDarkYellow;
    }
  }
  &.grey-outline {
    color: $primaryMidGrey;
    border: solid 3px currentColor;
    &:hover {
      color: $primaryDarkGrey;
    }
  }
}
</style>
