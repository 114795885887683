<template lang="pug">
.content-wrapper
  .card.router-view-card
    section.select-patient
      h2.big_title PATIENT SEARCH RESULTS
      TableTitle(:title="getTitle")
        template(#actions)
          BaseBtn.lg.blue_outline.setMinWidth.setMarginRight(:to="{name: 'clinics.patient-search'}") SEARCH AGAIN
          BaseBtn.lg.blue.setMinWidth(
            href="#" @click.prevent="openAddNewClinicPatientOverlayOpen" 
            v-if="isClinicHealthSystemManager || isClinicStaff || isClinicDoctor"
          ) ADD NEW PATIENT
      .table
        .th
          .td Patient Name
          .td DOB
          .td Clinic
          .td
          .td
          .td
        .tr(v-if="patients" v-for="patient in patients" :key="patient.id")
          .td.bold {{ getUserFormatted(patient) }}
          .td {{ getDobFormatted(patient.dob) }}
          .td.bold {{patient?.clinic?.name}}
          .td
            BaseBtn.sm.blue-outline(
              :class="( (!clinicStaffInTrainingNotAllowed && !isClinicStaffInTraining) || patient.isDisabled ) && `disabled-outline`"
              @click.prevent="( (clinicStaffInTrainingNotAllowed || isClinicStaffInTraining) && !patient.isDisabled )  ? openClinicPatientDetalisOverlay(patient) : null"
            ) PATIENT DETAILS

            BaseBtn.sm.blue-outline.disabled-outline(
              v-if="patient.isDisabled || !hasFitKit") ADD NEW FIT
            BaseBtn.sm.blue-outline(
              v-else @click.prevent="openAddNewFITKitDistributionOverlay(patient)"
            ) ADD NEW FIT

            BaseBtn.sm.blue-outline.disabled-outline(
              v-if="patient.isDisabled || !specialties || !clinicStaffInTrainingNotAllowed") SCHEDULE
            BaseBtn.btn.sm.blue(
              :isDropdown="true",
              txt="SCHEDULE"
              v-else
            )
              template(#dropdown-menu v-if="specialties")
                li(v-for="specialty in specialties" :key="specialty.specialtyModule.id")
                  router-link(:to="{name: 'clinics.scheduling.select-schedule', query: $route.query, params: { idClinic: $route.params.idClinic, idSpecialty: specialty.specialtyModule.id, idPatient: patient.id} }") {{ specialty.specialtyModule.moduleTitle }}
               
      Pagination(
        v-if="pagination",
        :pagination="pagination",
        :reloadFn="fetchPatients"
        @changePagination="changePagination"
      )
      .no-results(v-if="patients != null && !patients.length") 
        base-icon(name="exclamation") 
        span No matching records

      .actions-below-table-container
        
  router-view  

ClinicPatientDetalisOverlay(
  :key="`${componentKey}-1`"
  :showPatient="showPatient",
  :isOpen="isClinicPatientDetalisOverlay",
  @closeClinicPatientDetalisOverlay="closeClinicPatientDetalisOverlay",
)
AddNewClinicPatientOverlay(
  :isOpen="isAddNewClinicPatientOverlayOpen",
  @reloadPatients="reloadPatients",
  @closeAddNewClinicPatientOverlayOpen="closeAddNewClinicPatientOverlayOpen",
)
AddNewFITKitDistributionOverlay(
    :isOpen="isAddNewFITKitDistributionOverlayOpen",
    :selectedPatient="selectedPatient",
    :idClinic="selectedClientID",
    @closeAddNewFITKitDistributionOverlay="closeAddNewFITKitDistributionOverlay"
  )
</template>

<script>
import { defineComponent } from "vue";
import moment from "moment";
import TableTitle from "@/components/TableTitle.vue";
import Pagination from "@/components/Pagination.vue";
import listPagesMixin from "@/mixins/listPagesMixin";
import AddNewFITKitDistributionOverlay from "@/components/fit-kit/overlays/AddNewFITKitDistributionOverlay.vue";
import { mapGetters } from "vuex";

import { getDobFormatted, getUserFormatted } from "@/helpers";
import {
  fetchCHS,
  fetchCHSPatients,
  getSpecialtyModulesOfCHS,
} from "@/api/chsApi";
import { fetchClinic } from "@/api/clinicApi";

import ClinicPatientDetalisOverlay from "@/components/clinics/patient-search/overlays/PatientDetalisOverlay.vue";
import AddNewClinicPatientOverlay from "@/components/clinics/scheduling/overlays/AddNewPatientOverlay.vue";
import { denyClinicStaffTraining } from "@/mixins/denyClinicStaffTraining";

export default defineComponent({
  components: {
    TableTitle,
    Pagination,
    ClinicPatientDetalisOverlay,
    AddNewClinicPatientOverlay,
    AddNewFITKitDistributionOverlay,
  },

  mixins: [listPagesMixin, denyClinicStaffTraining],

  data() {
    return {
      isClinicPatientDetalisOverlay: false,
      isAddNewClinicPatientOverlayOpen: false,
      patients: null,
      getTitle: "",
      showPatient: null,
      specialties: null,
      isAddNewFITKitDistributionOverlayOpen: false,
      selectedPatient: null,
      selectedClientID: parseInt(this.$route.params.idClinic),
      searchParams: null,
      idCHS: null,
      hasFitKit: false,
    };
  },
  computed: {
    ...mapGetters(["isClinicStaffInTraining"]),
  },
  methods: {
    getDobFormatted,
    getUserFormatted,
    async fetchClinic() {
      try {
        const res = await fetchClinic(this.$route.params.idClinic);
        this.idCHS = res.data.idClinicHealthSystem;
        // this.specialties = res.data.chsSpecialties;
      } catch (err) {
        console.error(err);
      }
    },
    async reloadPatients(patient) {
      this.searchParams = {
        searchBy: "name",
        firstName: patient.first,
        lastName: patient.last,
      };
      await this.$router.push({
        name: "clinics.patient-search.results",
        query: this.searchParams,
      });
      this.fetchPatients();
    },
    openAddNewFITKitDistributionOverlay(patient) {
      this.selectedPatient = patient;
      this.isAddNewFITKitDistributionOverlayOpen = true;
    },
    closeAddNewFITKitDistributionOverlay() {
      this.selectedPatient = null;
      this.isAddNewFITKitDistributionOverlayOpen = false;
    },
    getClinicName(patient) {
      return patient && patient.clinic && patient.clinic.name;
    },

    async getSpecialtyModulesOfCHS() {
      try {
        const res = await getSpecialtyModulesOfCHS(this.idCHS);
        this.specialties = res.data.chsSpecialties;
      } catch (err) {
        console.error(err);
      }
    },
    async fetchCHS() {
      try {
        const res = await fetchCHS(this.idCHS);
        this.hasFitKit = res.data.fitRegistry;
      } catch (err) {
        console.error(err);
      }
    },
    async fetchPatients() {
      this.getTitle = "(0) Patients Found";
      try {
        const query_params = {
          searchBy: this.$route.query.searchBy,
          searchInput: this.getSearchInput(this.$route.query),
        };
        const resPatient = await fetchCHSPatients({
          params: query_params,
          ...this.reqPagination,
        });
        this.patients = resPatient.data.patients;
        this.pagination = resPatient.data.pagination;

        this.patients.forEach((element) => {
          element.isDisabled = true;
          if (element.idClinic == this.$route.params.idClinic) {
            element.isDisabled = false;
          }
        });

        if (this.patients != null && this.patients.length) {
          this.getTitle = "(" + this.patients.length + ") Patients Found";
        }
      } catch (err) {
        console.error(err);
      }
    },
    getSearchInput(searchParams) {
      let searchInput = {};
      const searchBy = searchParams.searchBy;
      switch (searchBy) {
        case "name":
          if (searchParams.firstName) {
            searchInput.first = searchParams.firstName;
          }
          if (searchParams.lastName) {
            searchInput.last = searchParams.lastName;
          }
          break;
        case "mrn":
          searchInput = searchParams.mrn;
          break;
        case "dob":
          searchInput = moment.utc(searchParams.dob).format("MMDDYYYY");
          break;
        default:
          break;
      }
      return searchInput;
    },
    openClinicPatientDetalisOverlay(patient) {
      this.componentKey = this.componentKey + 1;
      this.showPatient = patient;
      this.isClinicPatientDetalisOverlay = true;
    },
    closeClinicPatientDetalisOverlay() {
      this.isClinicPatientDetalisOverlay = false;
      this.fetchPatients();
    },
    openAddNewClinicPatientOverlayOpen() {
      this.isAddNewClinicPatientOverlayOpen = true;
    },
    closeAddNewClinicPatientOverlayOpen() {
      this.isAddNewClinicPatientOverlayOpen = false;
    },
  },
  created() {
    if (this.$route.query === null) return;
    this.fetchPatients();
    this.fetchClinic().then(() => {
      this.getSpecialtyModulesOfCHS();
      this.fetchCHS();
    });
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/components/actions-below-table-container.scss";
.btn.setMarginRight {
  margin-right: 23px;
}
.btn.setMinWidth {
  min-width: 228px;
}
.table {
  overflow: visible;
}
.table-title {
  margin-bottom: 41px;
}
.btn.btn--is-dropdown {
  padding: 4px 20px;
  border-radius: 16px;
}
.dropdown-menu li a {
  padding-left: 20px;
  text-align: left;
  height: auto;
  padding-top: 5px;
  padding-bottom: 5px;
}
.select-patient {
  .td {
    overflow: visible;

    &:nth-child(1) {
      width: math.percentage(math.div(260, $tabContentCardWidth));
    }
    &:nth-child(2) {
      width: math.percentage(math.div(155, $tabContentCardWidth));
    }
    &:nth-child(3) {
      width: math.percentage(math.div(187, $tabContentCardWidth));
    }
    &:nth-child(4) {
      width: math.percentage(math.div(578, $tabContentCardWidth));
      text-align: right;
      .btn {
        margin-left: 19px;
      }
    }
  }
}
.table .tr > .td {
  overflow: visible;
}
.table > .tr:last-child {
  border-bottom: 0px;
}
</style>
