<template lang="pug">
.content-wrapper
  .card.router-view-card
    section.all-clinics
      h2.big_title RESCHEDULE APPOINTMENT 
        span for 
          span.bold(v-if="oldSchedule?.patient") {{ getUserFormatted(oldSchedule.patient) }}
    TableTitle(title="Available Dates"
    )
      template(#actions)
        select.table-action-selector.table-action-selector--doctor(
          :title="doctorTitle",
          style="margin-right: 24px;"
          @change="setFilterValueDoctor($event.target.value)"
        )
          option(value="") DOCTOR'S NAME
          option(
            v-for="doctor in activeDoctors", :key="doctor.id",
            :value="doctor.id"
          ) {{ getDoctorFormatted(doctor) }} 
        DateSelector(@filterByDate="filterByDate")
    .table
      .th
        .td Doctor Name
        .td Insurance
        .td Hospital
        .td Date and Time
        .td
      .tr(v-for="schedule in schedules", :key="schedule.id")
        .td {{ getDoctorFormatted(schedule.doctor) }}
        .td
          base-icon(name="yes", v-if="schedule.insuranceRequired")
          base-icon(name="no", v-else)
        .td {{ schedule.hospital.name }}
        .td
          div {{ getDateFromUnixFormatted(schedule.availableAt) }}
          .second-row-md-grey {{ getTimeFromUnixFormatted(schedule.availableAt) }}
        .td.alignButtonRight
          BaseBtn.sm.blue(
            @click.prevent="openConfirmScheduleOverlay(schedule)"
          ) SELECT SCHEDULE

    Pagination(
      v-if="pagination",
      :pagination="pagination",
      :reloadFn="fetchPatientAppointmentAvailableSchedule"
      @changePagination="changePagination"
    )
    .actions-below-table-container

ConfirmScheduleOverlay(
  :isOpen="isConfirmScheduleOverlayOpen",
  :newSchedule="newSchedule"
  :oldSchedule="oldSchedule"
  @closeConfirmScheduleOverlay="closeConfirmScheduleOverlay"
)
</template>

<script>
import { defineComponent } from "vue";
import TableTitle from "@/components/TableTitle.vue";
import DateSelector from "@/components/DateSelector.vue";
import Pagination from "@/components/Pagination.vue";
import listPagesMixin from "@/mixins/listPagesMixin";

import { fetchPatientAppointmentAvailableSchedule } from "@/api/patientAppointmentApi";
import { fetchPatientAppointmentById } from "@/api/patientAppointmentApi";
import { apiStatus, apiStatusComputedFactory } from "@/api";
const { IDLE, PENDING, SUCCESS, ERROR } = apiStatus;

import ConfirmScheduleOverlay from "@/components/hospitals/appointment-status/reschedule/overlays/ConfirmScheduleOverlay";
import { fetchDoctorsSpecialty } from "@/api/hospitalApi";

import {
  getDateFromUnixFormatted,
  getTimeFromUnixFormatted,
  getUserFormatted,
  getDoctorFormatted,
} from "@/helpers";

export default defineComponent({
  components: {
    TableTitle,
    DateSelector,
    Pagination,
    ConfirmScheduleOverlay,
  },
  mixins: [listPagesMixin],
  props: {
    specialtyModule: {
      required: false,
    },
  },
  data() {
    return {
      isAppointmentRecordOverlayOpen: false,
      isAppointmentNotesOverlayOpen: false,
      isConfirmScheduleOverlayOpen: false,
      fetchPatientAppointmentAvailableScheduleStatus: IDLE,
      fetchPatientAppointmentByIdStatus: IDLE,
      schedules: null,
      idCHS: null,
      patient: null,
      newSchedule: null,
      oldSchedule: null,
      date: null,
      doctors: null,
      idDoctor: null,
      doctorTitle: "",
    };
  },
  computed: {
    ...apiStatusComputedFactory(
      "fetchPatientAppointmentAvailableScheduleStatus",
      "fetchPatientStatus"
    ),
    activeDoctors() {
      return this.doctors?.filter((doctor) => !doctor.disabled);
    },
  },
  methods: {
    getUserFormatted,
    getDateFromUnixFormatted,
    getTimeFromUnixFormatted,
    getDoctorFormatted,
    setFilterValueDoctor(value) {
      if (value) {
        this.idDoctor = value;
        this.doctors.forEach((doctor) => {
          if (doctor.id == value) {
            this.doctorTitle = this.getDoctorFormatted(doctor);
          }
        });
      } else {
        this.idDoctor = null;
      }
      this.fetchPatientAppointmentAvailableSchedule();
    },
    filterByDate(date) {
      this.date = date;
      this.fetchPatientAppointmentAvailableSchedule();
    },
    async fetchPatientAppointmentById(idSchedule) {
      this.fetchPatientAppointmentByIdStatus = PENDING;
      try {
        const res = await fetchPatientAppointmentById(idSchedule, {
          params: {
            include: [
              "clinic",
              "clinicReport",
              "emergencyContact",
              "hospital",
              "hospitalDoctor",
              "hospitalReport",
              "insurance",
              "pathologyReport",
              "patient",
              "referringDoctor",
            ],
          },
        });

        // this.patient = res.data.patientAppointment.patient;
        this.oldSchedule = res.data.patientAppointment;
        this.fetchPatientAppointmentByIdStatus = SUCCESS;
      } catch (err) {
        this.fetchPatientAppointmentByIdStatus = ERROR;
        console.error(err);
      }
    },

    openConfirmScheduleOverlay(newSchedule) {
      this.newSchedule = newSchedule;
      this.isConfirmScheduleOverlayOpen = true;
    },
    closeConfirmScheduleOverlay() {
      this.isConfirmScheduleOverlayOpen = false;
    },
    async fetchPatientAppointmentAvailableSchedule() {
      this.fetchPatientAppointmentAvailableScheduleStatus = PENDING;
      let navigatePage = 1;
      if (this.reqPagination.page) {
        navigatePage = this.reqPagination.page;
      }
      try {
        const res = await fetchPatientAppointmentAvailableSchedule({
          params: {
            ...this.reqPagination,
            idHospital: this.$route.params.idHospital,
            include: ["hospital", "patientAppointment", "doctor"],
            isAvailable: "Available",
            availableAt: this.date ?? null,
            idSpecialtyModule: this.$route.params.idSpecialty,
            idDoctor: this.idDoctor,
            page: navigatePage,
            isInsuranceRequired: this.oldSchedule.insurance.name ? true : false,
          },
        });

        this.schedules = res.data.schedules;
        this.pagination = res.data.pagination;
        this.fetchPatientAppointmentAvailableScheduleStatus = SUCCESS;
      } catch (err) {
        this.fetchPatientAppointmentAvailableScheduleStatus = ERROR;
        console.error(err);
      }
    },
    async fetchDoctorsSpecialty() {
      this.fetchDoctorsSpecialtyStatus = PENDING;
      try {
        const res = await fetchDoctorsSpecialty(
          this.$route.params.idHospital,
          this.$route.params.idSpecialty
        );
        this.doctors = res.data.doctors;
        this.fetchDoctorsSpecialtyStatus = SUCCESS;
      } catch (err) {
        this.fetchDoctorsSpecialtyStatus = ERROR;
        console.error(err);
      }
    },
  },
  created() {
    if (this.$route.params.idHospital === null) return;
    this.fetchPatientAppointmentById(this.$route.params.idAppointment).then(
      () => {
        this.fetchPatientAppointmentAvailableSchedule();
      }
    );
    this.fetchDoctorsSpecialty();
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/components/actions-below-table-container.scss";
select.table-action-selector--insurance {
  width: 175px;
}
.big_title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  span {
    font-weight: normal;
    color: $primaryDarkBlue;
    span {
      font-weight: 700;
    }
  }
}
.card {
  position: relative;
}
.actions-below-table-container {
  top: calc(100% + 44px);
}
.td {
  &:nth-child(1) {
    width: math.percentage(math.div(290, $tabContentCardWidth));
  }
  &:nth-child(2) {
    width: math.percentage(math.div(175, $tabContentCardWidth));
  }
  &:nth-child(3) {
    width: math.percentage(math.div(289, $tabContentCardWidth));
  }
  &:nth-child(4) {
    width: math.percentage(math.div(190, $tabContentCardWidth));
  }
  &:nth-child(5) {
    width: math.percentage(math.div(236, $tabContentCardWidth));
  }
  &.alignButtonRight {
    text-align: right;
  }
  .btn {
    min-width: 196px;
  }
}
</style>
<style>
.main-container {
  padding-bottom: 100px;
}
</style>
