<template lang="pug">
FullScreenOverlaySlot(
  :isOpen="isOpen",
  headerIcon="icc-logo",
  title="FIT KIT BULK UPLOAD"
  @closeOverlay="closeOverlay"
)
  template(#breadcrumbs)
    h1.breadcrumbs-title IL CARE CONTINUUM
  template(#content)
    .card.clinic-title
     .columns.is-variable.bottom_border
        .column.is-two-thirds
            label.subtitle Clinic Health System
            label.chs-name {{chsName}}
        .column.is-one-third
            label.subtitle Select a Clinic
            select(
              :title="clinicTitle"
              @change="setFilterValueClinic($event.target.value)"  
            )
              option(value="") Please select
              option(v-for="clinic in clinics" :key="clinic.id" :value="clinic.id") {{ clinic.name }}
     .columns.is-variable.bottom_border.topPadding
        .column.is-variable
            label.subtitle Upload CSV File
            .upload-csv-bulk 
              UploadAndPreviewFile(
                :removeMargin="!isFilePresent()"
                :buttonStyleClasses="['btn', 'lg', 'blue']"
                :key="forcedRerenderTimes"
                :storageKey="bulkFitKitsStorageKey"
                @fileLoaded="fileLoaded"
              )
     .columns.is-variable.topPadding
        .column.is-two-thirds
            label.subtitle Uploaded File
            label.file_uploaded {{fileName}}
        .column.is-one-third
            label.subtitle File Check Status
            label.file_check_status(:class="validationClass") {{validationStatus}}
    .card.errors(v-if="showErrorArea")
      .title-md-thin-with-action
        h2.title.title--md-thin(style="margin-bottom: 27px;") {{errorTitle}}
      .table.general-table.table--no-side-padding
        .th
          .td Row
          .td Message
        .tr.tr__row(v-for="(item, index) in errorMessages", :key="index")
          .td {{item.row}}
          .td {{item.message}}
      PaginationErrors(
        v-if="errorMessages",
        :pagination="paginationBulk.messages",
        @changePagination="changePaginationMessages"
      )
    .card.preview(v-if="showPreviewArea")
      .title-md-thin-with-action
        h2.title.title--md-thin(style="margin-bottom: 27px;") {{previewTitle}}
      .table.preview-table.table--no-side-padding
        .th
          .td #
          .td(v-for="header in tableHeaders", :key="header.jsonField") {{header}}
        .tr.tr__row(v-for="(row, index) in previewRows", :key="index", :class="{'invalid-field': row.isInvalid}")
          .td {{getIndexColumn(index)}}
          .td(v-for="header in tableHeaders", :class="{'invalid-field': row[header].isInvalid}") {{row[header]?.value}}
      Pagination(
        v-if="previewRows",
        :pagination="paginationBulk.preview",
        @changePagination="changePaginationPreview"
      )
  template(#footer)
    BaseBtn.btn.lg.red-outline(href="#", @click.prevent="closeOverlay") CANCEL CHANGES
    BaseBtn.btn.lg.green(
      @click.prevent="submit"
      :disabled="isSubmitting || !canUploadFile()"
      :class="{spinner: isSubmitting }"
    ) UPLOAD FIT KIT DATA
</template>

<script>
import _ from "lodash";
import Papa from "papaparse";
import moment from "moment";
import { defineComponent } from "vue";
import FullScreenOverlaySlot from "@/components/overlays/FullScreenOverlaySlot.vue";
import UploadAndPreviewFile from "@/components/UploadAndPreviewFile.vue";
import Pagination from "@/components/PaginationWithoutItemsPerPage.vue";
import PaginationErrors from "@/components/PaginationWithoutItemsPerPage.vue";
import listPagesMixin from "@/mixins/listPagesMixin";
import {
  ERROR_MESSAGE,
  AUTH_ERROR_STATUS,
  AUTH_ERROR_MESSAGE,
  BULK_FIT_KIT_UPLOAD_FIELDS,
} from "@/components/organizations/overlays/BulkFitKitUploadConstants";
import { fetchCHSClinics } from "@/api/chsApi";
import { bulkFitKits } from "@/api/fitKitApi";

import { createToast } from "mosha-vue-toastify";

export default defineComponent({
  components: {
    Pagination,
    PaginationErrors,
    FullScreenOverlaySlot,
    UploadAndPreviewFile,
  },
  emits: ["closeUploadFitKitsOverlay"],
  mixins: [listPagesMixin],
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    chsId: {
      required: true,
    },
    chsName: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      forcedRerenderTimes: 0,
      bulkFitKitsStorageKey: "bulk_fit_kits",
      isSubmitting: false,
      csvTable: {
        requiredHeaders: _.chain(BULK_FIT_KIT_UPLOAD_FIELDS)
          .omitBy(function (field) {
            return field.required === false;
          })
          .keys()
          .value(),
        headers: Object.keys(BULK_FIT_KIT_UPLOAD_FIELDS),
        rows: [],
      },
      validationComplete: false,
      isStatusReady: false,
      messages: [],
      fitKitsFile: null,
      paginationBulk: {
        messages: {
          page: 1,
          size: 3,
          currentPage: 1,
          lastPage: 1,
        },
        preview: {
          page: 1,
          size: 10,
          currentPage: 1,
          lastPage: 1,
        },
      },
      currentTargetJson: null,
      clinicTitle: "CLINIC NAME",
      filterIdClinic: null,
      clinics: null,
    };
  },
  watch: {
    fitKitsFile: {
      handler: "setFitKitsFile",
    },
  },
  computed: {
    showErrorArea: function () {
      return this.messages?.length > 0;
    },
    showPreviewArea: function () {
      return this.csvTable?.rows?.length > 0;
    },
    fileName: function () {
      return this.getFileName();
    },
    validationStatus: function () {
      return this.getValidationStatus();
    },
    validationClass: function () {
      return this.getValidationClass();
    },
    errorTitle: function () {
      return `ERROR MESSAGES (${this.messages?.length})`;
    },
    previewTitle: function () {
      return `PREVIEW (${this.csvTable?.rows?.length})`;
    },
    errorMessages: function () {
      const { errorMessages, start, end } = this.getPaginationMessages();
      return _.flatten(_.slice(errorMessages, start, end));
    },
    tableHeaders: function () {
      return this.csvTable?.headers;
    },
    previewRows: function () {
      const { previewRows, start, end } = this.getPaginationPreviewRows();
      return _.flatten(_.slice(previewRows, start, end));
    },
  },
  methods: {
    setFilterValueClinic(value) {
      if (value != "") {
        this.filterIdClinic = value;
        this.clinics.forEach((clinic) => {
          if (clinic.id == value) {
            this.clinicTitle = clinic.name;
          }
        });
      } else {
        this.filterIdClinic = null;
        this.clinicTitle = "CLINIC NAME";
      }
    },
    changePaginationPreview(newPagination) {
      this.paginationBulk.preview.page = newPagination.page;
    },
    changePaginationMessages(newPagination) {
      this.paginationBulk.messages.page = newPagination.page;
    },
    getIndexColumn(index) {
      const paginationPreview = this.paginationBulk?.preview;
      return (
        paginationPreview?.size * (paginationPreview?.page - 1) + index + 1
      );
    },
    getPaginationMessages() {
      const size = this.paginationBulk.messages.size;
      const page = this.paginationBulk.messages.page - 1;
      const errorMessages = _.compact(this.messages);
      const start = page * size;
      const end = _.min([start + size, errorMessages.length]);
      return { errorMessages, start, end };
    },
    getPaginationPreviewRows() {
      const size = this.paginationBulk.preview.size;
      const page = this.paginationBulk.preview.page - 1;
      const previewRows = _.compact(this.csvTable?.rows);
      const start = page * size;
      const end = _.min([start + size, previewRows.length]);
      return { previewRows, start, end };
    },
    fileLoaded(value) {
      this.forcedRerenderTimes++;
      this.setFitKitsFile(this.convertBase64ToFile(value));
    },
    convertBase64ToFile(image) {
      const byteString = atob(image.split(",")[1]);
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i += 1) {
        ia[i] = byteString.charCodeAt(i);
      }
      const newBlob = new Blob([ab], {
        type: "text/csv",
      });
      return newBlob;
    },
    setFitKitsFile(newVal) {
      if (newVal !== this.fitKitsFile) {
        this.resetView();
        this.fitKitsFile = newVal;
        this.parseCsvFromFile(
          this.fitKitsFile,
          this.processAndValidate,
          this.completeProcess,
          this.csvTable,
          this.addMessage
        );
      }
    },
    closeOverlay() {
      sessionStorage.removeItem(this.bulkFitKitsStorageKey);
      this.fitKitsFile = null;
      this.resetView();
      this.$emit("closeUploadFitKitsOverlay");
    },
    getValidationClass() {
      const status = this.getValidationStatus();
      if (status) {
        return "status-" + status?.toLowerCase();
      }
    },
    getValidationStatus() {
      if (this.validationComplete) {
        return this.isStatusReady ? "READY" : "FAILED";
      }
      return "N/A";
    },
    isFilePresent() {
      return !!this.fitKitsFile;
    },
    canUploadFile() {
      return (
        this.getValidationStatus()?.toLowerCase() === "ready" &&
        !!this.filterIdClinic
      );
    },
    getFileName() {
      if (this.isFilePresent()) {
        try {
          const file = JSON.parse(
            sessionStorage.getItem(this.bulkFitKitsStorageKey)
          );
          return file.name;
        } catch (err) {
          console.log(err);
          return "N/A";
        }
      }
      return "N/A";
    },
    parseCsvFromFile(
      csvFile,
      callback,
      completedProcess,
      csvTable,
      addMessage
    ) {
      Papa.parse(csvFile, {
        header: true,
        trimHeaders: true,
        skipEmptyLines: true,
        complete: function (results) {
          if (validateHeaders(results, csvTable, addMessage)) {
            callback(results.data);
          } else {
            completedProcess(true);
          }

          function validateHeaders(results, csvTable, addMessage) {
            const MESSAGE = "One or more columns are missing in the header row";
            const missingHeaders = _.difference(
              csvTable.requiredHeaders,
              results.meta.fields
            );
            if (missingHeaders.length > 0) {
              const errorMessage = MESSAGE + ": " + missingHeaders.join(", ");
              createToast(errorMessage, {
                timeout: 4000,
                type: "danger",
                position: "bottom-right",
              });
              console.log(errorMessage);
              addMessage(0, errorMessage);
              return false;
            }
            return true;
          }
        },
      });
    },
    addMessage(row, message) {
      this.messages[row] = this.messages[row] ?? [];
      this.messages[row].push({ row: row, message: message });
    },
    processAndValidate(data) {
      let targetJson = [],
        targetJsonItem,
        hasErrors = false,
        csvTable = [],
        csvTableItem,
        currentColumn,
        config = BULK_FIT_KIT_UPLOAD_FIELDS;
      for (let i = 0; i < data.length; i++) {
        targetJsonItem = {};
        csvTableItem = {};
        for (const key in config) {
          currentColumn = this.getCsvColumn(data[i][key], config[key]);
          if (!hasErrors && !currentColumn.isInvalid) {
            _.set(
              targetJsonItem,
              config[key].jsonField,
              this.getValueForJson(currentColumn, config[key])
            );
          } else {
            hasErrors = true;
            if (currentColumn.isInvalid) {
              this.addMessage(i + 1, currentColumn.message + ": " + key);
            }
          }
          csvTableItem[key] = currentColumn;
        }
        csvTable.push(csvTableItem);
        if (!hasErrors) {
          targetJson.push(targetJsonItem);
        }
      }
      this.currentTargetJson = targetJson;
      this.csvTable.rows = csvTable;
      this.completeProcess(hasErrors);
    },
    getCsvColumn(value, config) {
      let csvColumn = {
        value: value != null ? value.trim() : "",
      };
      if (csvColumn.value && csvColumn.value.length > 0) {
        switch (config.type) {
          case "string":
            csvColumn.isInvalid = false;
            break;
          case "enum":
            csvColumn.isInvalid = false;
            csvColumn.value = _.chain(csvColumn.value)
              .toLower()
              .snakeCase()
              .value();
            break;
          case "date":
            csvColumn.isInvalid = !this.isValidDate(csvColumn.value);
            if (csvColumn.isInvalid) {
              csvColumn.message = "Invalid date format";
            }
            break;
          case "number":
            csvColumn.isInvalid = !this.isValidNumber(csvColumn.value, config);
            if (csvColumn.isInvalid) {
              csvColumn.message = "Invalid format or length";
            }
            break;
          default:
            csvColumn.isInvalid = true;
            csvColumn.message = "Unknown type";
            break;
        }
      }
      return csvColumn;
    },
    isValidDate(value) {
      return this.getMomentDateFromValue(value).isValid();
    },
    getDateValue(column, config) {
      const dateValue = this.getMomentDateFromValue(column.value);
      if (config.isUTC) {
        return dateValue.unix();
      } else if (config.format) {
        return dateValue.format(config.format);
      } else {
        return column.value;
      }
    },
    getMomentDateFromValue(value) {
      return moment(value, "M/D/YYYY", true);
    },
    isValidNumber(value, config) {
      const isValid = !/\D/.test(value);
      return config.length
        ? isValid && value.length === config.length
        : isValid;
    },
    getValueForJson(column, config) {
      if (column.value != null && column.value.length > 0) {
        if (config.type === "date") {
          return this.getDateValue(column, config);
        } else {
          return column.value;
        }
      } else {
        return null;
      }
    },
    completeProcess(failed) {
      this.validationComplete = true;
      this.isStatusReady = !failed;
      if (this.messages.length > 0) {
        this.setPagination("messages", _.compact(this.messages).length);
      }
      if (this.csvTable.rows.length > 0) {
        this.setPagination("preview", this.csvTable.rows.length);
      }
    },
    setPagination(tableName, length) {
      const pagination = this.paginationBulk[tableName];
      pagination.quantity = length;
      pagination.lastPage = Math.ceil(length / pagination.size);
    },
    resetView() {
      this.validationComplete = false;
      this.isStatusReady = false;
      this.messages = [];
      this.csvTable.rows = [];
      this.currentTargetJson = null;
    },
    submit() {
      this.isSubmitting = true;
      this.currentTargetJson = this.currentTargetJson.map((item) => {
        return {
          ...item,
          idClinic: this.filterIdClinic,
        };
      });
      bulkFitKits({ fitKits: this.currentTargetJson })
        .then(this.handleSuccess, this.handleFailure)
        .finally(() => (this.isSubmitting = false));
    },
    handleSuccess(response) {
      const data = response?.data ?? {};
      if (data?.success) {
        createToast(data?.message, {
          timeout: 4000,
          type: "success",
          position: "bottom-right",
        });
        this.closeOverlay();
      } else {
        this.handleFailure(response);
      }
    },
    handleFailure({ response }) {
      if (response.status === AUTH_ERROR_STATUS) {
        createToast(AUTH_ERROR_MESSAGE, {
          timeout: 4000,
          type: "danger",
          position: "bottom-right",
        });
        console.log(AUTH_ERROR_MESSAGE);
      } else {
        this.processServerErrors(this.getErrorMessage(response.data));
      }
    },
    getErrorMessage(data) {
      if (data && data.success != null) {
        return {
          message: data.message ?? ERROR_MESSAGE,
          rows: data.rows ?? [],
        };
      } else {
        return this.getErrorFromRawResponse(data);
      }
    },
    getErrorFromRawResponse(data) {
      const keys = _.get(data, "validation.keys");
      if (keys && keys.length > 0) {
        const keyParts = keys[0].split(".");
        if (keyParts.length >= 2 && keyParts[0] === "fitKits") {
          const row = [parseInt(keyParts[1]) + 1];
          const fullField = keyParts.slice(2).join(".");
          for (const key in BULK_FIT_KIT_UPLOAD_FIELDS) {
            if (BULK_FIT_KIT_UPLOAD_FIELDS[key].jsonField === fullField) {
              return { message: "Invalid value for: " + key, rows: row };
            }
          }
        }
      }
      return { message: ERROR_MESSAGE };
    },
    processServerErrors(error) {
      const errorMessage = _.isArray(error?.message)
        ? "Bulk Upload failed."
        : error?.message;
      createToast(errorMessage, {
        timeout: 4000,
        type: "danger",
        position: "bottom-right",
      });
      console.log(error.message);
      if (error.rows && error.rows.length > 0) {
        this.messages = [];
        error.rows.forEach(function (row, index) {
          if (_.isArray(error.message)) {
            this.addMessage(row, error.message[index]);
          } else {
            this.addMessage(row, error.message);
          }
          this.markInvalidRow(row);
        }, this);
        this.completeProcess(true);
      }
    },
    markInvalidRow(row) {
      this.csvTable.rows[row - 1].isInvalid = true;
    },
    async getClinics() {
      try {
        const { data } = await fetchCHSClinics(this.chsId);
        this.clinics = data.clinics;
      } catch (error) {
        console.error(error);
      }
    },
  },
  created() {
    if (!this.chsId) return;
    this.getClinics();
  },
  unmounted() {
    sessionStorage.removeItem(this.bulkFitKitsStorageKey);
  },
});
</script>

<style scoped lang="scss">
.invalid-field .td {
  color: #d5351d !important;
}
.topPadding {
  padding-top: 20px;
}
.subtitle {
  font-size: 14px;
}
.table .tr.tr_child {
  padding-top: 0px;
  padding-bottom: 33px;
}
.tr.tr__row {
  padding-top: 5px;
  padding-bottom: 5px;
}
.no_bottom_border {
  border-bottom: 0px;
}
.bottom_border {
  border-bottom: 1px solid #d8d8d8;
}
.download_template_link {
  font-size: 21px;
  font-weight: bold;
  color: inherit;
  text-decoration: underline;
}
.file_uploaded {
  font-size: 21px;
  font-weight: bold;
}
.file_check_status {
  font-size: 21px;
  font-weight: bold;
}
.status-ready {
  color: #6e980b;
}
.status-failed {
  color: #d5351d;
}
.card {
  padding: 35px $secondaryCardsSidePadding 20px $secondaryCardsSidePadding;
  &.clinic-title {
    padding-top: 55px;
  }
  &.preview,
  &.errors {
    padding-top: 45px;
  }
}
.columns.is-variable {
  --columnGap: 1.575%;
}
.columns + .hr {
  background-color: $primaryBgDarkGrey;
  margin-top: -21px;
  margin-bottom: 24px;
}
.card > .columns:last-child.info-pair,
.card > .columns:last-child .info-pair {
  margin-bottom: 0;
}
.svg-icon--eye-red,
.svg-icon--eye-green,
.svg-icon--yes,
.svg-icon--no {
  width: 50px;
  height: 50px;
  top: 0;
}

// Page specific styles----------------------------------------
.breadcrumbs-title {
  margin: 0;
  color: $primaryLightBlue;
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.12px;
}
.chs-name {
  margin: 0;
  color: $primaryLightBlue;
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.12px;
}
.procedure-dropdown-instructions {
  margin-top: -20px;
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 1.29;
}
.general-table {
  display: block;
  overflow-x: auto;
  .td {
    border-bottom: solid 1px #d8d8d8;
    padding-bottom: 5px;
    padding-top: 5px;
    &:nth-child(1) {
      padding-left: 10px;
      width: 10%;
    }
    &:nth-child(2) {
      width: 200%;
    }
  }
  .row-main-options {
    padding: 35px 0;
    .base-checkbox:not(:last-child) {
      margin-right: 84px;
    }
  }
  .th {
    padding-bottom: 25px;
  }
}
.table.table--no-side-padding .th {
  border-bottom: none;
}
.table .tr {
  border-bottom: none;
}
.preview-table {
  display: block;
  overflow-x: auto;
  .td {
    border-bottom: solid 1px #d8d8d8;
    padding-bottom: 5px;
    padding-top: 5px;
    &:nth-child(1) {
      padding-left: 10px;
      width: 5%;
    }
    &:nth-child(2) {
      width: 5%;
    }
    &:nth-child(3) {
      width: 16%;
    }
    &:nth-child(4) {
      width: 16%;
    }
    &:nth-child(5) {
      width: 13%;
    }
    &:nth-child(6) {
      width: 18%;
    }
    &:nth-child(7) {
      width: 21%;
    }
    &:nth-child(8) {
      width: 18%;
    }
    &:nth-child(9) {
      width: 20%;
    }
    &:nth-child(10) {
      width: 20%;
    }
    &:nth-child(11) {
      width: 13%;
    }
    &:nth-child(12) {
      width: 30%;
    }
    &:nth-child(13) {
      width: 15%;
    }
    &:nth-child(14) {
      width: 13%;
    }
    &:nth-child(15) {
      width: 13%;
    }
    &:nth-child(16) {
      width: 13%;
    }
    &:nth-child(17) {
      width: 30%;
    }
    &:nth-child(18) {
      width: 20%;
    }
    &:nth-child(19) {
      width: 100%;
    }
  }
}
</style>
