<template lang="pug">
SideOverlaySlot(
  :isOpen="isOpen",
  title="EDIT FOLLOW-UP NOTES"
  @closeOverlay="closeOverlay"
)
  template(#contentTop)
    PatientSection(:fitKit="selectedFitKit")
    FollowUpNotesAdded(v-if="notesAdded")
    form#follow-up-notes-update(
      v-if="!notesAdded"
      style="display:flex; flex-direction:column; flex:1;"
      @submit.prevent="updateFollowUpNotes"
    )
      .columns
        .column.is-half
          .form-group
            label(for="type") Type of Follow-Up
            select(v-model="selectedType")
              option(
                v-for="type in FOLLOWUP_TYPE_OPTIONS",
                :key="type.id"
                :value="type.value"
                :selected="type.value === selectedType"
              ) {{ type.name }}
        .column.is-half
          .form-group
            label(for="name-of-staff") Name of Staff
            input#name-of-staff(type="text", v-model="staffName")
      .form-group(
        style="margin-bottom:0; display:flex; flex-direction:column; flex:1;"
      )
        label(for="notes") Notes
        textarea#notes(style="flex:1;", v-model="newNotes")
  template(#footer, v-if="!notesAdded")
    .flex.flex--space-between
      BaseBtn.x-lg.red-outline(
        href="#",
        @click.prevent="closeOverlay"
      ) CANCEL
      BaseBtn.x-lg.green(
        href="#",
        :class="{disabled: !isValidEntry()}"
        @click.prevent="createNewEditionNote"
      ) CONTINUE
</template>

<script>
import { defineComponent } from "vue";
import SideOverlaySlot from "@/components/overlays/SideOverlaySlot.vue";
import PatientSection from "@/components/fit-kit/overlays/PatientSection.vue";
import FollowUpNotesAdded from "@/components/fit-kit/fit-kit-status/FollowUpNotesAdded.vue";
import { updateFitKitFollowUpNote } from "@/api/fitKitApi";
import { FOLLOWUP_TYPE_OPTIONS } from "@/helpers";
import { createToast } from "mosha-vue-toastify";

export default defineComponent({
  components: {
    SideOverlaySlot,
    PatientSection,
    FollowUpNotesAdded,
  },
  emits: ["closeOverlay", "nextOverlayIs"],

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    selectedFitKit: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      notesAdded: false,
      selectedType: null,
      staffName: null,
      newNotes: null,
      FOLLOWUP_TYPE_OPTIONS,
    };
  },
  methods: {
    isValidEntry() {
      const isValidStaff = this.staffName && this.staffName !== "";
      const isValidNotes = this.newNotes && this.newNotes !== "";
      return this.selectedType && isValidStaff && isValidNotes;
    },
    closeOverlay() {
      this.selectedType = null;
      this.staffName = null;
      this.newNotes = null;
      this.$emit("closeOverlay", {
        afterAction: this.notesAdded,
      });
      this.notesAdded = false;
    },
    async createNewEditionNote() {
      const idFitKit = this.selectedFitKit.id;
      const data = {
        id: this.selectedFitKit.initialFollowUp.id,
        followUpType: this.selectedType,
        staffName: this.staffName,
        notes: this.newNotes,
        followUp: this.selectedFitKit.initialFollowUp.followUp,
      };
      try {
        const resFitKit = await updateFitKitFollowUpNote(idFitKit, data);
        if (resFitKit.data.success) {
          this.notesAdded = true;
        }
      } catch (err) {
        createToast(err?.response?.data?.message || "There was a problem", {
          timeout: 4000,
          type: "danger",
          position: "bottom-right",
        });
        console.error(err);
      }
    },
  },
});
</script>

<style scoped lang="scss">
.form-group {
  margin-bottom: 10px;
}
</style>
