<template lang="pug">
.table_wrapper
  table.table(id="data-table")
    tr.th
      td.td Month
      td.td Cumulative Rate Of Return
      td.td Currently Outstanding
      td.td Kits Distributed
      td.td Kits Completed
      td.td Kits Processed
      td.td Positive
    tr.tr(v-for="month, index in labels", :key="month")
      td.td {{month}}
      td.td.bold.red {{cumulativeRateOfReturn[index]}}
      td.td {{currentlyOutstanding[index]}}
      td.td {{kitsDistributed[index]}}
      td.td {{kitsCompleted[index]}}
      td.td {{kitsProcessed[index]}}
      td.td {{positive[index]}}
    tr.tr
      td.td Total
      td.td
      td.td
      td.td {{totalKitsDistributed}}
      td.td {{totalKitsCompleted}}
      td.td {{totalKitsProcessed}}
      td.td {{totalPositive}}
</template>

<script>
import { defineComponent } from "vue";
import accumulator from "@/helpers/accumulator";
import moment from "moment";

export default defineComponent({
  props: {
    reportData: {
      required: true,
      type: Array,
    },
  },
  computed: {
    cumulativeRateOfReturn() {
      return this.reportData.map(
        (monthData) => monthData.cumulativeRateOfReturnPercentage
      );
    },
    currentlyOutstanding() {
      return this.reportData.map((monthData) => monthData.currentlyOutstanding);
    },
    kitsDistributed() {
      return this.reportData.map((monthData) => monthData.totalDistributed);
    },
    totalKitsDistributed() {
      return this.kitsDistributed.reduce(accumulator, 0);
    },
    kitsCompleted() {
      return this.reportData.map((monthData) => monthData.totalCompletedKits);
    },
    totalKitsCompleted() {
      return this.kitsCompleted.reduce(accumulator, 0);
    },
    kitsProcessed() {
      return this.reportData.map((monthData) => monthData.totalProcessed);
    },
    totalKitsProcessed() {
      return this.kitsProcessed.reduce(accumulator, 0);
    },
    positive() {
      return this.reportData.map((monthData) => monthData.positive);
    },
    totalPositive() {
      return this.positive.reduce(accumulator, 0);
    },
    labels() {
      return this.reportData.map((monthData, index, arr) =>
        moment(
          monthData.distributedAtMonth || monthData.scheduledAtMonth
        ).format(arr.length > 12 ? "MMMM YY" : "MMMM")
      );
    },
  },
});
</script>

<style lang="scss" scoped>
.table_wrapper {
  width: 100%;
  margin-bottom: 80px;
}
.hr {
  margin-right: 30px;
}
.btn-margin-left {
  margin-left: 28px;
}
.table_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.table {
  width: 100%;
  color: #000;

  .th {
    padding-bottom: 14px;
    padding-top: 14px;
    height: auto;
    border-bottom: 0px;
    .td {
      font-weight: 700;
      text-transform: none;
    }
    background-color: $primaryBgDarkGrey;
  }

  .tr {
    padding-bottom: 14px;
    padding-top: 14px;
    height: auto;
    .td {
      height: auto;
      display: flex;
    }
    &:hover {
      background-color: transparent;
    }
    &:last-child {
      border-bottom: 0px;
    }
  }

  .td {
    font-size: 14px;
    display: flex;
    flex: 1;
  }
}
</style>
