<template lang="pug">
PopupCardSlot(
  :isOpen="isOpen",
  maxWidth="675px"
)
  h1.title.title--md Your Session Has Expired.
  .content-popup
    BaseBtn.x-lg.blue(href="#", @click.prevent="closeOverlay") LOG BACK IN
</template>

<script>
import { defineComponent } from "vue";
import PopupCardSlot from "@/components/PopupCardSlot.vue";
import { mapActions } from "vuex";

export default defineComponent({
  components: { PopupCardSlot },

  computed: {
    minutesRemainingString() {
      if (this.minutesRemaining < 10) {
        return "0" + this.minutesRemaining;
      }

      return this.minutesRemaining.toString();
    },
    secondsRemainingString() {
      if (this.secondsRemaining < 10) {
        return "0" + this.secondsRemaining;
      }

      return this.secondsRemaining.toString();
    },
  },

  data() {
    return {
      isOpen: true,
    };
  },

  methods: {
    ...mapActions(["showSessionExpiredModal"]),
    closeOverlay() {
      this.showSessionExpiredModal(false);
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/components/actions-below-table-container.scss";
@import "@/scss/components/step-subtitle.scss";
.schedule-confirm {
  padding-left: 30px;
  padding-right: 30px;
}

.title--md {
  text-align: center;
  margin: 3rem 0;
}

.content-popup {
  text-align: center;
  position: relative;

  header {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 36px;
    padding-bottom: 28px;
    &::after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      left: -35px;
      right: -35px;
      height: 1px;
      background-color: $primaryBgDarkGrey;
    }
    h1 {
      margin: 0;
      font-size: 36px;
      font-weight: 700;
      line-height: 1.22;
      letter-spacing: 0.2px;
      color: $secondaryGreen;
      .svg-icon--checkmark {
        margin-left: 20px;
        font-size: 44px;
      }
    }
  }
  .popup-content {
    & > h2 {
      margin-top: 0;
      margin-bottom: 47px;
      font-size: 28px;
      font-weight: 700;
      line-height: 1.21;
      letter-spacing: 0.14px;
    }
    .confirmation-info {
      margin-bottom: 27px;
    }
  }
  footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      margin: 0;
      font-size: 21px;
      font-weight: 700;
      line-height: 1.19;
      letter-spacing: 0.11px;
      color: $secondaryGreen;
      strong {
        color: #000000;
        text-decoration: underline;
      }
      .svg-icon {
        top: 0;
        margin-left: 22px;
        font-size: 33px;
      }
    }
  }
}
</style>
