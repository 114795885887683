<template lang="pug">
teleport(to="body")
  transition(name="popup-card-backdrop")
    .popup-card-backdrop(v-if="isOpen")
      section.popup-card.card(:style="{maxWidth: maxWidth}")
        slot(name="default")
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  components: {},

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    maxWidth: {
      type: String,
      default: "1178px",
    },
  },

  watch: {
    isOpen: {
      handler(val) {
        if (document.getElementById("fullscreen-overlay") !== null) return;
        if (val === true) {
          document.documentElement.style.overflow = "hidden";
        } else {
          document.documentElement.style.overflow = "auto";
        }
      },
    },
  },

  beforeUnmount() {
    if (
      document.getElementById("fullscreen-overlay") !== null ||
      document.getElementsByClassName("side-overlay-backdrop") !== null
    ) {
      return;
    }
    document.documentElement.style.overflow = "auto";
  },
});
</script>

<style scoped lang="scss">
.popup-card-backdrop {
  position: fixed;
  backface-visibility: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 93, 147, 0.65);
  z-index: 101;
  &.popup-card-backdrop-enter-active {
    animation: popup-card-backdrop 0.3s ease-in;
    pointer-events: auto;
  }
  &.popup-card-backdrop-leave-active {
    animation: popup-card-backdrop 0.3s ease-in reverse;
    pointer-events: none;
  }
}
.popup-card {
  margin: 20px;
  width: 100%;
  z-index: 101;
  max-height: calc(100vh - 40px);
  overflow-y: auto;
  padding: 35px;
}
</style>

<style lang="scss">
@keyframes popup-card-backdrop {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
