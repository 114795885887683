<template lang="pug">
section.select-patient
  TableTitle(:title="getTitle")
    template(#actions)
      BaseBtn.x-lg.blue(
        href="#" @click.prevent="openAddNewPatientOverlayOpen"
      ) ADD NEW PATIENT
  .table
    .th
      .td Patient Name
      .td Clinic
      .td DOB
      .td
      .td
    .tr(v-for="patient in patients" :key="patient.id")
      .td.bold {{ getUserFormatted(patient) }}
      .td {{getClinicName(patient)}}
      .td {{getDobFormatted(patient.dob)}}
      .td(:title="getActionTitle(patient)")
        BaseBtn.sm.blue-outline(
           href="#", :class="{disabled: shouldDisableRecords(patient)}"
          @click.prevent="openPatientDetailsOverlay(patient)"
        ) PATIENT DETAILS
      .td(:title="getActionTitle(patient)")
        BaseBtn.sm.blue(href="#", @click.prevent="openAddNewFITKitDistributionOverlay(patient)") ADD NEW FIT
  .no-results(v-if="patients != null && !patients.length") 
    base-icon(name="exclamation") 
    span No matching records
  .actions-below-table-container
    BaseBtn.lg.green-outline(
      @click.prevent="searchAgain()",
      style="min-width: 270px",
    ) SEARCH AGAIN

AddNewFITKitDistributionOverlay(
  :isOpen="isAddNewFITKitDistributionOverlayOpen",
  :idCommunity="idCommunity",
  :selectedPatient="selectedPatient",
  @closeAddNewFITKitDistributionOverlay="closeAddNewFITKitDistributionOverlay"
)

AddNewPatientOverlay(
  :isOpen="isAddNewPatientOverlayOpen",
  @reloadPatients="fetchPatients",
  @closeAddNewClinicPatientOverlayOpen="closeAddNewClinicPatientOverlayOpen",
)

PatientDetailsOverlay(
  :key="`${componentKey}-1`"
  :showPatient="showPatient",
  :isOpen="isPatientDetailsOverlay",
  @closePatientDetailsOverlay="closePatientDetailsOverlay",
)

</template>

<script>
import { defineComponent } from "vue";
import moment from "moment";
import TableTitle from "@/components/TableTitle.vue";
import AddNewFITKitDistributionOverlay from "@/components/fit-kit-community-organization/overlays/AddNewFITKitDistributionOverlay.vue";
import {
  fetchCommunityCareOrganizationById,
  fetchCommunityCareOrganizationPatients,
} from "@/api/communityOrganization";
import { getDobFormatted, getUserFormatted } from "@/helpers";
import listPagesMixin from "@/mixins/listPagesMixin";
import AddNewPatientOverlay from "@/components/primary-care-community-organization/scheduling/overlays/AddNewPatientOverlay.vue";
import PatientDetailsOverlay from "@/components/primary-care-community-organization/patient-referrals/overlays/PatientDetailsOverlay.vue";

export default defineComponent({
  components: {
    TableTitle,
    AddNewFITKitDistributionOverlay,
    AddNewPatientOverlay,
    PatientDetailsOverlay,
  },
  mixins: [listPagesMixin],
  emits: ["closeSelectPatient"],

  props: {
    idCommunity: {
      type: Number,
      required: true,
    },
    searchParams: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      patients: null,
      selectedPatient: null,
      isAddNewFITKitDistributionOverlayOpen: false,
      isAddNewPatientOverlayOpen: false,
      isPatientDetailsOverlay: false,
      getTitle: "",
      showPatient: null,
      accessToPatients: false,
    };
  },

  methods: {
    shouldDisableRecords(patient) {
      if (!patient.idClinic) {
        return false;
      }
      return !this.accessToPatients;
    },
    async fetchCommunityCareOrganizationById() {
      try {
        const res = await fetchCommunityCareOrganizationById(
          this.$route.params.idCommunity
        );
        this.accessToPatients = res.data.hasPatientAccess;
      } catch (err) {
        console.error(err);
      }
    },
    openAddNewPatientOverlayOpen() {
      this.isAddNewPatientOverlayOpen = true;
    },
    closeAddNewClinicPatientOverlayOpen() {
      this.isAddNewPatientOverlayOpen = false;
    },
    openPatientDetailsOverlay(patient) {
      this.componentKey = this.componentKey + 1;
      this.showPatient = patient;
      this.isPatientDetailsOverlay = true;
    },
    closePatientDetailsOverlay() {
      this.isPatientDetailsOverlay = false;
      this.fetchPatients();
    },
    openAddNewFITKitDistributionOverlay(patient) {
      this.selectedPatient = patient;
      this.isAddNewFITKitDistributionOverlayOpen = true;
    },
    closeAddNewFITKitDistributionOverlay() {
      this.selectedPatient = null;
      this.isAddNewFITKitDistributionOverlayOpen = false;
    },
    searchAgain() {
      this.$emit("closeSelectPatient");
    },
    getDobFormatted,
    getUserFormatted,
    getActionTitle(patient) {
      const title = this.shouldDisableRecords(patient)
        ? "Don't have access to patient record"
        : "";
      return title;
    },
    getClinicName(patient) {
      return patient?.clinic?.name;
    },
    getSearchInput(searchParams) {
      let searchInput = {};
      const searchBy = searchParams.searchBy;
      switch (searchBy) {
        case "name":
          if (searchParams.firstName) {
            searchInput.first = searchParams.firstName;
          }
          if (searchParams.lastName) {
            searchInput.last = searchParams.lastName;
          }
          break;
        case "mrn":
          searchInput = searchParams.mrn;
          break;
        case "dob":
          searchInput = moment.utc(searchParams.dob).format("MMDDYYYY");
          break;
        default:
          break;
      }
      return searchInput;
    },
    async fetchPatients() {
      this.getTitle = "(0) Patients Found";
      try {
        const queryParams = {
          searchBy: this.searchParams.searchBy,
          searchInput: this.getSearchInput(this.searchParams),
        };

        const resPatient = await fetchCommunityCareOrganizationPatients(
          this.$route.params.idCommunity,
          {
            params: queryParams,
            ...this.reqPagination,
          }
        );
        this.patients = resPatient.data.patients;
        if (this.patients != null && this.patients.length) {
          this.getTitle = "(" + this.patients.length + ") Patients Found";
        }
      } catch (err) {
        console.error(err);
      }
    },
  },

  created() {
    if (this.searchParams === null) return;
    this.fetchCommunityCareOrganizationById();
    this.fetchPatients();
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/components/actions-below-table-container.scss";
@import "@/scss/components/step-subtitle.scss";
.select-patient {
  .td {
    &:nth-child(1) {
      width: 23.5%;
    }
    &:nth-child(2) {
      width: 23.1%;
    }
    &:nth-child(3) {
      width: 15.4%;
    }
    &:nth-child(4) {
      width: 20.4%;
    }
    &:last-child {
      width: 17.6%;
      // width: 19.5%;
    }
  }
}
.actions-below-table-container {
  .buttons {
    margin-left: 0;
  }
}
</style>
