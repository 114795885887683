<template lang="pug">
section.dashboard-notifications(v-if="notifications")
  h3.title.title--md Notifications
  .table.table--hoverable
    .tr(v-for="notification in notifications", :key="notification.id", @click="openAppointmentNotesOverlay(notification)")
      .td.datetime-name
        .date-time {{unixToUTCDate(notification)}} <span class="time">{{unixToUTCTime(notification)}}</span>
        .name {{notification.patient.first}} {{notification.patient.last}}
      .td.status-exam
        div
          .status(:class="{'red': notification.type === 'Cancelled'}") {{notification.type}}
          .exam Colonoscopy
  BaseBtn.x-lg.blue-outline(:to="{name: 'clinics.notifications' , params: { idClinic: $route.params.idClinic, isViewAll: true }}", style="display:block;") VIEW ALL

AppointmentNotesOverlay(
  :key="`${componentKeyForAppointment}-1`",
  :isOpen="isAppointmentNotesOverlayOpen",
  @closeAppointmentNotesOverlay="closeAppointmentNotesOverlay"
  @fetchPatientAppointmentNotifications="fetchNotification"
  @openViewAppointment="fetchPatientAppointmentById"
  :patientAppointment="sendNotification"
  showAppointmentsFor="clinics"
)
ReferralNotesOverlay(
  :key="`${componentKeyForReferral}-1`",
  :isOpen="isReferralNotesOverlayOpen",
  @closeReferralNotesOverlay="closeReferralNotesOverlay"
  @fetchNotifications="fetchNotification"
  @openViewAppointment="fetchPatientReferralById"
  :patientAppointment="sendNotification"
  showAppointmentsFor="clinics"
)

ClinicAppointmentRecordOverlay(
  :key="`${componentKey}-1`",
  :isOpen="isClinicAppointmentRecordOverlayOpen",
  tabType="displayOnly"
  :showAppointment="showAppointmentClinic"
  @closeClinicAppointmentRecordOverlay="closeClinicAppointmentRecordOverlay",
)
ReferralRecordOverlay(
  :key="`${componentKey}-1`",
  :isOpen="isReferralRecordOverlayOpen",
  tabType="displayOnly"
  :showReferral="showReferral",
  @closeReferralRecordOverlay="closeReferralRecordOverlay",
)
</template>

<script>
import { defineComponent } from "vue";
import moment from "moment";

import listPagesMixin from "@/mixins/listPagesMixin";
import AppointmentNotesOverlay from "@/components/overlays/side-overlays/AppointmentNotesOverlay.vue";
import ReferralNotesOverlay from "@/components/overlays/side-overlays/ReferralNotesOverlay.vue";
import ReferralRecordOverlay from "@/components/primary-care-clinic/patient-referrals/overlays/ReferralRecordOverlay";
import { fetchPatientAppointmentById } from "@/api/patientAppointmentApi";
import { fetchPatientReferralById } from "@/api/patientReferral";

import { fetchNotification } from "@/api/notifications.ts";

import { apiStatus, apiStatusComputedFactory } from "@/api";
const { IDLE, PENDING, SUCCESS, ERROR } = apiStatus;

import ClinicAppointmentRecordOverlay from "@/components/clinics/appointment-status/overlays/ClinicAppointmentRecordOverlay.vue";

export default defineComponent({
  components: {
    AppointmentNotesOverlay,
    ClinicAppointmentRecordOverlay,
    ReferralNotesOverlay,
    ReferralRecordOverlay,
  },
  mixins: [listPagesMixin],

  data() {
    return {
      componentKeyForAppointment: 0,
      componentKeyForReferral: 0,
      isReferralNotesOverlayOpen: false,
      isReferralRecordOverlayOpen: false,
      isAppointmentNotesOverlayOpen: false,
      notifications: null,
      sendNotification: null,
      sideId: null,
      side: null,
      isClinicAppointmentRecordOverlayOpen: false,
      fetchPatientAppointmentByIdStatus: IDLE,
      fetchPatientReferralByIdStatus: IDLE,
      showAppointmentClinic: null,
      showReferral: null,
    };
  },

  methods: {
    async fetchPatientReferralById(referralId) {
      this.fetchPatientReferralByIdStatus = PENDING;
      try {
        const res = await fetchPatientReferralById(referralId, {
          params: {
            include: [
              "communityCare",
              "clinicDoctor",
              "communityReport",
              "cancelled",
              "clinic",
              "clinicReport",
              "emergencyContact",
              "insurance",
              "patient",
              "referringDoctor",
            ],
          },
        });
        let referral = res.data.patientReferral;
        this.openClinicReferralRecordOverlay(referral);
        this.fetchPatientReferralByIdStatus = SUCCESS;
      } catch (err) {
        this.fetchPatientReferralByIdStatus = ERROR;
        console.error(err);
      }
    },
    openClinicReferralRecordOverlay(referral) {
      this.componentKey = this.componentKey + 1;
      this.showReferral = referral;
      this.isReferralRecordOverlayOpen = true;
    },
    async fetchPatientAppointmentById(appointmentId) {
      this.fetchPatientAppointmentByIdStatus = PENDING;
      try {
        const res = await fetchPatientAppointmentById(appointmentId, {
          params: {
            include: [
              "clinic",
              "clinicReport",
              "emergencyContact",
              "hospital",
              "hospitalDoctor",
              "hospitalReport",
              "insurance",
              "pathologyReport",
              "patient",
              "referringDoctor",
            ],
          },
        });
        let appointment = res.data.patientAppointment;
        this.openClinicAppointmentRecordOverlay(appointment);

        this.fetchPatientAppointmentByIdStatus = SUCCESS;
      } catch (err) {
        this.fetchPatientAppointmentByIdStatus = ERROR;
        console.error(err);
      }
    },
    openClinicAppointmentRecordOverlay(appointment) {
      this.componentKey = this.componentKey + 1;
      this.showAppointmentClinic = appointment;
      this.isClinicAppointmentRecordOverlayOpen = true;
    },
    closeClinicAppointmentRecordOverlay() {
      this.isClinicAppointmentRecordOverlayOpen = false;
    },
    openAppointmentNotesOverlay(sendNotification) {
      let sendPatientInfo = {
        patient: sendNotification.patient,
        id: sendNotification.idEntity,
      };
      this.sendNotification = sendPatientInfo;

      if (sendNotification.patientReferral) {
        this.componentKeyForReferral++;
        this.isReferralNotesOverlayOpen = true;
      } else {
        this.componentKeyForAppointment++;
        this.isAppointmentNotesOverlayOpen = true;
      }
    },
    closeAppointmentNotesOverlay() {
      this.isAppointmentNotesOverlayOpen = false;
    },
    closeReferralNotesOverlay() {
      this.isReferralNotesOverlayOpen = false;
    },
    closeReferralRecordOverlay() {
      this.isReferralRecordOverlayOpen = false;
    },
    async fetchNotification() {
      try {
        let res = await fetchNotification({
          params: {
            size: 5,
            destination: "Clinic",
            destinationId: this.$route.params.idClinic ?? null,
            include: ["completedBy", "lastViewedBy", "patient"],
          },
        });
        this.notifications = res.data.notifications;
      } catch (error) {
        console.error(error);
      }
    },

    unixToUTCDate(notification) {
      return moment.unix(notification.createdAt).format("MM-DD-YYYY");
    },
    unixToUTCTime(notification) {
      return moment.unix(notification.createdAt).format("hh:mm A");
    },
    isCompleted(notification) {
      if (notification?.completedAt) return true;
      return false;
    },
    isViewed(notification) {
      if (notification?.lastViewedBy?.email) return true;
      return false;
    },
  },
  created() {
    this.fetchNotification();
  },
});
</script>

<style scoped lang="scss">
.table {
  margin: 28px (-$secondaryCardsSidePadding);
  border-top: solid 2px $primaryLightBlue;
  .tr {
    lost-flex-container: row;
    align-items: center;
    height: 78px;
    padding-left: $secondaryCardsSidePadding;
    padding-right: $secondaryCardsSidePadding;
  }
  .td {
    font-size: 14px;
    line-height: 1.71;
    padding: 0 !important;
  }
  .datetime-name {
    lost-column: 3/5 0 10px;
    color: #000;
    .time {
      display: inline-block;
      margin-left: 7px;
      color: #9b9b9b;
    }
    .name {
      font-weight: 700;
      color: #000000;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .status-exam {
    lost-column: 2/5 0 10px;
    display: flex;
    justify-content: flex-start;
    .exam {
      color: #9b9b9b;
    }
  }
}
</style>
