<template lang="pug">
FullScreenOverlaySlot(
  :isOpen="isOpen",
  headerIcon="icc-logo",
  title="PATIENT DETAILS"
  @closeOverlay="closeOverlay"
)
  template(#breadcrumbs)
    h1.breadcrumbs-title IL CARE CONTINUUM
  template(#actionSection)

  template(#content)
    .card(style="padding-top: 52px;", v-if="showPatient")
      .columns.is-variable
        .info-pair.column.is-one-third
          .label First Name
          .txt.txt--blue {{ showPatient.first }}
        .info-pair.column.is-one-third
          .label Last Name
          .txt.txt--blue {{ showPatient.last }}
        .info-pair.column.is-one-third
          .label Date of Birth
          .txt.txt--blue {{ getDobFormattedforDateField(showPatient.dob) }}
      .hr.hr--thin
      .columns.is-variable.row-margin-bottom
        .column.is-one-third.flex.flex--flex-start.flex--align-start
          .info-pair
            .label Home Phone Number
            .txt {{ getPhoneFormatted(showPatient.contact.homePhone) }}
          .info-pair
            .label Ext.
            .txt {{ showPatient.contact.homePhoneExtension }}
        .column.is-one-third.flex.flex--flex-start.flex--align-start
          .info-pair
            .label Cell Phone Number
            .txt {{ getPhoneFormatted(showPatient.contact.cellPhone) }}
          .info-pair
            .label Ext.
            .txt {{ showPatient.contact.cellPhoneExtension }}
        .info-pair.column.is-one-third
          .label Clinic MRN
          .txt {{ showPatient.mrn }}
      .hr.hr--thin
      .columns.is-varialbe 
        .column.is-one-third
          .info-pair
            .label Patient Address
            .txt {{ showPatient.address.address }}<br />{{ showPatient.address.place }}, {{showPatient.address.state}} {{ showPatient.address.zipCode }}
        .column.is-two-thirds
          .info-pair
            .label Patient Notes
            textarea(style="height:167px" v-model="showPatient.notes" :disabled="true") 

    .card.appointment-history(style="padding-top: 52px;")
      h2 Appointment History
      .table.negative-margin.table--hoverable
        .th
          .td APPT TIME
          .td SPECIALTY
          .td REFFERING PHYSICIAN
          .td CLINIC
          .td LAST ACTIVITY
          .td REPORT
        .tr(v-if="appointmentsHistory" v-for="appointmentHistory in appointmentsHistory", :key="appointmentHistory.id" @click="openAppointmentRecordOverlay(appointmentHistory?.id)" )
          .td 
            div {{ getDateFromUnixFormatted(appointmentHistory.scheduledAt) }}
            .second-row-md-grey {{ getTimeFromUnixFormatted(appointmentHistory.scheduledAt) }}
          .td.bold {{ appointmentHistory.specialtyModule.moduleTitle }}
          .td {{ appointmentHistory.referringDoctor.first }} {{ appointmentHistory.referringDoctor.last }}
          .td {{ appointmentHistory.clinic.name }}
          .td
            .green(v-if = "appointmentHistory.status == 'Completed'") {{ appointmentHistory.status }}
            //- span(v-else-if = "index == 2") Rescheduled
            .red(v-else) {{ appointmentHistory.status }}
            //- span(v-else) Note Added
            .second-row-md-grey.font-14 Updated {{ getRelativeTimeFromUnixFormatted(appointmentHistory.updatedAt) }} 
          .td 
            base-icon(v-if="appointmentHistory.idHospitalReport" name="yes")
            base-icon(v-else name="no")
      
HospitalAppointmentRecordOverlay(
  :key="`${componentKey}-1`",
  :isOpen="isHospitalAppointmentRecordOverlayOpen",
  tabType="displayOnly"
  :showAppointment="showAppointment"
  @closeHospitalAppointmentRecordOverlay="closeHospitalAppointmentRecordOverlay",
  :specialtyModule="specialtyModule"
)
ReferralRecordOverlay(
  :key="`${componentKey}-2`",
  :showReferral="showReferral",
  :isOpen="isReferralRecordOverlayOpen",
  tabType="displayOnly"
  @closeReferralRecordOverlay="closeReferralRecordOverlay",
)
</template>

<script>
import { defineComponent } from "vue";
import FullScreenOverlaySlot from "@/components/overlays/FullScreenOverlaySlot.vue";
import Pagination from "@/components/Pagination.vue";
import listPagesMixin from "@/mixins/listPagesMixin";

import {
  getDateFromUnixFormatted,
  getTimeFromUnixFormatted,
  getDobFormattedforDateField,
  getRelativeTimeFromUnixFormatted,
  getPhoneFormatted,
} from "@/helpers";
import HospitalAppointmentRecordOverlay from "@/components/hospitals/appointment-status/overlays/HospitalAppointmentRecordOverlay.vue";

import ReferralRecordOverlay from "@/components/primary-care-community-organization/patient-referrals/overlays/ReferralRecordOverlay.vue";

import {
  fetchPatientAppointment,
  fetchPatientAppointmentById,
} from "@/api/patientAppointmentApi";

import { apiStatus, apiStatusComputedFactory } from "@/api";
const { IDLE, PENDING, SUCCESS, ERROR } = apiStatus;

export default defineComponent({
  components: {
    FullScreenOverlaySlot,
    Pagination,
    HospitalAppointmentRecordOverlay,
    ReferralRecordOverlay,
  },
  emits: ["closeHospitalPatientDetalisOverlay", "openAppointmentNotesOverlay"],
  mixins: [listPagesMixin],

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    showPatient: {
      type: Object,
      required: false,
    },
    specialtyModule: {
      required: false,
    },
  },
  data() {
    return {
      hidePolypsFoundSelectLabel: false,
      hidePolypsRemovedSelectLabel: false,
      appointmentsHistory: null,
      fitKitHistory: null,
      fitKitOutsideICC: null,
      isHospitalAppointmentRecordOverlayOpen: false,
      fetchPatientAppointmentByIdStatus: IDLE,
      fetchPatientReferralsStatus: IDLE,
      fetchPatientAppointmentStatus: IDLE,
      showAppointment: null,
      referrals: null,
      isReferralRecordOverlayOpen: false,
      showReferral: null,
    };
  },

  methods: {
    closeReferralRecordOverlay() {
      this.$emit("refreshAppointmentsSummary");
      this.isReferralRecordOverlayOpen = false;
    },
    openReferralRecordOverlay(referral) {
      this.componentKey = this.componentKey + 1;
      this.showReferral = referral;
      this.isReferralRecordOverlayOpen = true;
    },
    getDateFromUnixFormatted,
    getTimeFromUnixFormatted,
    getDobFormattedforDateField,
    getRelativeTimeFromUnixFormatted,
    getPhoneFormatted,
    openAppointmentRecordOverlay(idSchedule) {
      this.fetchPatientAppointmentById(idSchedule);
    },
    showProcessed(processed) {
      if (processed) {
        return this.getDateFromUnixFormatted(processed);
      } else {
        return "N/A";
      }
    },
    showResult(result) {
      if (result) {
        return result;
      } else {
        return "N/A";
      }
    },
    async fetchPatientAppointmentById(idSchedule) {
      this.fetchPatientAppointmentByIdStatus = PENDING;
      try {
        const res = await fetchPatientAppointmentById(idSchedule, {
          params: {
            include: [
              "clinic",
              "hospitalDoctor",
              "patient",
              "referringDoctor",
              "hospital",
            ],
          },
        });
        this.showAppointment = res.data.patientAppointment;
        this.isHospitalAppointmentRecordOverlayOpen = true;

        this.componentKey = this.componentKey + 1;
        this.fetchPatientAppointmentByIdStatus = SUCCESS;
      } catch (err) {
        this.fetchPatientAppointmentByIdStatus = ERROR;
        console.error(err);
      }
    },
    async fetchPatientAppointment() {
      this.fetchPatientAppointmentStatus = PENDING;
      try {
        const res = await fetchPatientAppointment({
          params: {
            idPatient: this.showPatient.id,
            include: ["hospital", "clinic", "referringDoctor", "patient"],
          },
        });
        this.appointmentsHistory = res.data.patientAppointments;

        this.fetchPatientAppointmentStatus = SUCCESS;
      } catch (err) {
        this.fetchPatientAppointmentStatus = ERROR;
        console.error(err);
      }
    },
    closeHospitalAppointmentRecordOverlay() {
      this.isHospitalAppointmentRecordOverlayOpen = false;
    },
    closeOverlay() {
      this.$emit("closeHospitalPatientDetalisOverlay");
    },
    openAppointmentNotesOverlay() {
      this.$emit("openAppointmentNotesOverlay");
    },
  },
  created() {
    if (!this.showPatient) return;
    this.fetchPatientAppointment();
  },
});
</script>

<style scoped lang="scss">
.font-14 {
  font-size: 14px;
}
h2 {
  margin-bottom: 62px;
  font-size: 28px;
}
.row-margin-bottom {
  margin-bottom: 45px;
}
.appointment-history {
  &.card {
    padding-bottom: 48px;
  }
  .td {
    &:nth-child(1) {
      width: math.percentage(math.div(180, $tabContentCardWidth));
    }
    &:nth-child(2) {
      width: math.percentage(math.div(212, $tabContentCardWidth));
    }
    &:nth-child(3) {
      width: math.percentage(math.div(230, $tabContentCardWidth));
    }
    &:nth-child(4) {
      width: math.percentage(math.div(200, $tabContentCardWidth));
    }
    &:nth-child(5) {
      width: math.percentage(math.div(180, $tabContentCardWidth));
    }
    &:nth-child(6) {
      width: math.percentage(math.div(178, $tabContentCardWidth));
    }
  }
}
.primary-care-referrals {
  &.card {
    padding-bottom: 48px;
  }
  .td {
    &:nth-child(1) {
      width: math.percentage(math.div(180, $tabContentCardWidth));
    }
    &:nth-child(2) {
      width: math.percentage(math.div(645, $tabContentCardWidth));
    }
    &:nth-child(3) {
      width: math.percentage(math.div(355, $tabContentCardWidth));
    }
  }
}
.fit-history {
  &.card {
    padding-bottom: 48px;
  }
  .td {
    &:nth-child(1) {
      width: math.percentage(math.div(190, $tabContentCardWidth));
    }
    &:nth-child(2) {
      width: math.percentage(math.div(147, $tabContentCardWidth));
    }
    &:nth-child(3) {
      width: math.percentage(math.div(220, $tabContentCardWidth));
    }
    &:nth-child(4) {
      width: math.percentage(math.div(127, $tabContentCardWidth));
    }
    &:nth-child(5) {
      width: math.percentage(math.div(198, $tabContentCardWidth));
    }
    &:nth-child(6) {
      width: math.percentage(math.div(106, $tabContentCardWidth));
    }
    &:nth-child(7) {
      width: math.percentage(math.div(187, $tabContentCardWidth));
    }
  }
}
.outside-icc {
  &.card {
    padding-bottom: 48px;
  }
  .td {
    &:nth-child(1) {
      width: math.percentage(math.div(187, $tabContentCardWidth));
    }
    &:nth-child(2) {
      width: math.percentage(math.div(147, $tabContentCardWidth));
    }
    &:nth-child(3) {
      width: math.percentage(math.div(660, $tabContentCardWidth));
    }
    &:nth-child(4) {
      width: math.percentage(math.div(185, $tabContentCardWidth));
    }
  }
}
.table .td:first-child {
  padding-left: 35px;
}
.card {
  padding: 35px $secondaryCardsSidePadding 68px;
}
.negative-margin {
  margin-left: -$secondaryCardsSidePadding;
  margin-right: -$secondaryCardsSidePadding;
}
.columns.is-variable {
  --columnGap: 1.575%;
}
.columns + .hr {
  background-color: $primaryBgDarkGrey;
  margin-top: -21px;
  margin-bottom: 24px;
}
.card > .columns:last-child.info-pair,
.card > .columns:last-child .info-pair {
  margin-bottom: 0;
}

// Page specific styles----------------------------------------
.insurance-status {
  margin-bottom: 21px;
  p {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.29;
    letter-spacing: 0.07px;
    color: #000;
  }
}
.rescheduled {
  .label {
    & > span {
      position: relative;
    }
    .svg-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: calc(100% + 20px);
    }
  }
}
.breadcrumbs-title {
  margin: 0;
  color: $primaryLightBlue;
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.12px;
}
</style>
<style>
/* #fullscreen-overlay.fullscreen-overlay .full-screen-overlay-footer{
  display:none;
}
#fullscreen-overlay.fullscreen-overlay .overlay-body__container{
  padding-bottom:15px;
} */
</style>
