// @ts-nocheck
import api from "./api";
const URLS = {
  HOSPITAL: `/hospital`,
  CHS: `/clinic-health-system`,
};
export const fetchHospitals = (data = {}) => {
  return api.get(URLS.HOSPITAL, data);
};
export const fetchHospital = (id, data = {}) => {
  return api.get(URLS.HOSPITAL + "/" + id, data);
};
export const createHospital = (data = {}) => {
  return api.post(URLS.HOSPITAL, data);
};
export const updateHospital = (idHospital, data = {}) => {
  return api.put(URLS.HOSPITAL + "/" + idHospital, data);
};
export const fetchDoctors = (idHospital, data = {}) => {
  return api.get(URLS.HOSPITAL + "/" + idHospital + "/doctor", data);
};
export const fetchDoctorsSpecialty = (idHospital, idSpecialty, data = {}) => {
  return api.get(
    URLS.HOSPITAL + "/" + idHospital + "/doctor/" + idSpecialty,
    data
  );
};
export const createWorkWith = (idHospital, chsID, data = {}) => {
  return api.post(
    URLS.HOSPITAL + "/" + idHospital + "/work-with/" + chsID,
    data
  );
};
export const deleteWorkWith = (idHospital, chsID, data = {}) => {
  return api.delete(
    URLS.HOSPITAL + "/" + idHospital + "/work-with/" + chsID,
    data
  );
};
export const getLinkedCHS = (data = {}) => {
  return api.get(URLS.CHS, data);
};
export const getHospitalSpecialty = (idHospital, data = {}) => {
  return api.get(URLS.HOSPITAL + "/" + idHospital + "/specialty-module", data);
};

export const scheduleHospitalDoctor = (
  idHospital,
  idDoctor,
  idSpecialtyModule,
  data = {}
) => {
  return api.post(
    URLS.HOSPITAL +
      "/" +
      idHospital +
      "/doctor/" +
      idDoctor +
      "/schedule/" +
      idSpecialtyModule,
    data
  );
};
export const fetchPatients = (hospId, data = {}) => {
  return api.get(URLS.HOSPITAL + "/" + hospId + "/patient", data);
};
