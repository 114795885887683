// @ts-nocheck
import api from "./api";
const URLS = {
  specialtyModule: `/specialty-module`,
};
export const fetchSpecialtyModules = (options = {}) => {
  return api.get(URLS.specialtyModule, options);
};
export const fetchSpecialtyModule = (id, data = {}) => {
  return api.get(URLS.specialtyModule + "/" + id, data);
};
export const createSpecialtyModule = (data = {}) => {
  return api.post(URLS.specialtyModule, data);
};
export const updateSpecialtyModule = (id, data = {}) => {
  return api.put(URLS.specialtyModule + "/" + id, data);
};
