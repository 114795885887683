<template lang="pug">
section.patient-section
  .patient-section__top
    h2 {{getProviderName()}}
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  components: {},

  props: {
    showDetails: {
      type: Boolean,
      default: true,
    },
    showProvider: {
      type: Boolean,
      default: true,
    },
    provider: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {};
  },

  computed: {},

  methods: {
    getProviderName() {
      return this.provider
        ? `${this.provider.first} ${this.provider.last}`
        : "";
    },
    getProviderPhone() {
      return this.provider && this.provider.contact
        ? this.provider.contact.business_phone
        : "";
    },
    getProviderStreet() {
      return this.provider && this.provider.address
        ? this.provider.address.address
        : "";
    },
    getProviderStateAndArea() {
      return this.provider && this.provider.address
        ? `${this.provider.address.state_name}, ${this.provider.address.zip_code}`
        : "";
    },
  },
});
</script>

<style scoped lang="scss">
.patient-section {
  position: relative;
  z-index: 1;
  margin-bottom: 31px;
  padding: 30px 24px 35px;
  background-color: $primaryBgBlue;
  color: $primaryLightBlue;
  &__top {
    margin-bottom: 0px;
    line-height: 100%;
    h2 {
      margin: 0;
      font-size: 21px;
      font-weight: bold;
      line-height: 1.67;
      color: #019bdf;
    }
  }
  &__data {
    td {
      padding-right: 14px;
      vertical-align: top;
    }
  }
  &__provider {
    margin-top: 31px;
    p {
      margin: 0;
      font-size: 16px;
      font-weight: 700;
      line-height: 1.31;
    }
  }
}
</style>
