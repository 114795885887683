// @ts-nocheck
import api from "./api";
import qs from "qs";
const URLS = {
  FIT_KIT: "/fit-kit",
  BULK_FIT_KIT: "/bulk-fit-kit",
};
export const createFitKit = (data: {
  idPatient: number;
  idClinic: number;
  idCommunity: number;
  idProviderUser: number;
  kitNumber: number;
  fluVaccine: boolean;
  distributedAt: number;
}): Promise<any> => {
  return api.post(URLS.FIT_KIT, data);
};
export const fetchFitKits = (data = {}): Promise<any> => {
  return api.get(URLS.FIT_KIT, {
    params: data.params,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  });
};
export const createFitKitReferral = (
  idFitKit: number,
  data: {
    clinicReferredTo: string;
    notes: string;
    referralAt: number;
  }
): Promise<any> => {
  return api.post(`${URLS.FIT_KIT}/${idFitKit}/referral`, data);
};
export const createColonoscopyOutsideICC = (idFitKit: number): Promise<any> => {
  return api.post(`${URLS.FIT_KIT}/${idFitKit}/colonoscopy-outside-icc`);
};
export const updateColonoscopyOutsideICC = (
  idFitKit: number,
  data: {
    result: string;
    processedAt: number;
    followUpAt: number;
    notes?: string;
    idReport?: number;
    updatedAt: number;
  }
): Promise<any> => {
  return api.put(`${URLS.FIT_KIT}/${idFitKit}/colonoscopy-outside-icc`, data);
};
export const updateFitKit = (
  idFitKit: number,
  data: {
    completionNotes?: string;
    isCompletionEdit?: boolean;
    processedAt: number;
    result: string;
    details?: string;
    updatedAt: number;
  }
): Promise<any> => {
  return api.put(`${URLS.FIT_KIT}/${idFitKit}`, data);
};
export const createFitKitFollowUpNote = (
  idFitKit: number,
  data: {
    followUpType: string;
    staffName: string;
    notes?: string;
    followUp: number;
    createdBy: number;
  }
): Promise<any> => {
  return api.post(`${URLS.FIT_KIT}/${idFitKit}/follow-up-note`, data);
};
export const updateFitKitFollowUpNote = (
  idFitKit: number,
  data: {
    followUpType: string;
    staffName: string;
    notes?: string;
    followUp: number;
    id: number;
  }
): Promise<any> => {
  return api.put(`${URLS.FIT_KIT}/${idFitKit}/follow-up-note`, data);
};
export const getOverallFitKitReport = (params: {
  timezone: string;
  idClinic?: number;
  idClinicHealthSystem?: number;
  startDate?: number;
  endDate?: number;
}): Promise<any> => {
  Object.keys(params).forEach(
    (key) => params[key] === undefined && delete params[key]
  );
  return api.get(`${URLS.FIT_KIT}/overall-report`, { params });
};
export const getFullFitKitReport = (params: {
  idClinic?: number;
  idClinicHealthSystem?: number;
  startDate?: number;
  endDate?: number;
}): Promise<any> => {
  Object.keys(params).forEach(
    (key) => params[key] === undefined && delete params[key]
  );
  return api.get(`${URLS.FIT_KIT}/full-report`, { params });
};
export const getFitKitEditions = (idFitKit: number): Promise<any> => {
  return api.get(`${URLS.FIT_KIT}/editions`, { params: { idFitKit } });
};
export const getFitKitFollowUpEditions = (
  idFitKit: number,
  idFollowUp: number
): Promise<any> => {
  return api.get(`${URLS.FIT_KIT}/follow-up-editions`, {
    params: { idFitKit, idFollowUp },
  });
};
export const bulkFitKits = (data = {}) => {
  return api.post(URLS.BULK_FIT_KIT, data);
};
