<template lang="pug">
div
  .columns.is-variable
    .column.is-one-third
      .form-group(:class="{ 'form-group__invalid': v$.citySearchInput.$errors.length }")
        label.label--margin-sm City (Required)
        input.input(:class="textSize" type="text", @keyup='cityKeyUp', v-model='citySearchInput', @blur="v$.citySearchInput.$touch()")
        .form-group__errors(v-if="v$.citySearchInput.$dirty && v$.citySearchInput.$errors.length")
          .form-group__error(v-if="v$.citySearchInput.required.$invalid") City is required!
        div.dropdown_input_wrapper(v-if="citySearchResults && citySearchResults.length")
          ul
            li(v-for="(place, index) in citySearchResults", :key="place.id", @click="citySelectResult(place)" :class="{ 'active': index ===0}")
              span(v-html="place.nameHighlight")

    .column.is-one-third
      .form-group(:class="{ 'form-group__invalid': v$.stateSearchInput.$errors.length }")
        label.label--margin-sm State (Required)
        input.input(:class="textSize" type="text", @keyup='stateKeyUp', v-model='stateSearchInput', @blur="v$.stateSearchInput.$touch()")
        .form-group__errors(v-if="v$.stateSearchInput.$dirty && v$.stateSearchInput.$errors.length")
          .form-group__error(v-if="v$.stateSearchInput.required.$invalid") State is required!
        div.dropdown_input_wrapper(v-if="stateSearchResults && stateSearchResults.length")
          ul
            li(v-for="(state, index) in stateSearchResults", :key="state.id", @click="stateSelectResult(state)" :class="{ 'active': index ===0}") 
              span(v-html="state.nameHighlight") 

    .column.is-one-third
      .form-group(:class="{ 'form-group__invalid': v$.zipSearchInput.$errors.length }")
        label.label--margin-sm Zip Code (Required)
        input.input(:class="textSize" type="text", @keyup='zipKeyUp', v-model='zipSearchInput', @blur="v$.zipSearchInput.$touch()")
        .form-group__errors(v-if="v$.zipSearchInput.$dirty && v$.zipSearchInput.$errors.length")
          .form-group__error(v-if="v$.zipSearchInput.required.$invalid") ZIP is required!
          .form-group__error(v-if="v$.zipSearchInput.minLength.$invalid || v$.zipSearchInput.minLength.$invalid ") Must be a number with 5 digits.
        div.dropdown_input_wrapper(v-if="zipSearchResults && zipSearchResults.length")
          ul
            li(v-for="(zip, index) in zipSearchResults", :key="zip.id", @click="zipSelectResult(zip)" :class="{ 'active': index ===0}") 
              span(v-html="zip.zipCodeHighlight")
</template>

<script>
import { defineComponent } from "vue";

import useVuelidate from "@vuelidate/core";
import { required, minLength, maxLength } from "@vuelidate/validators";

import {
  fetchCities,
  fetchStates,
  fetchStatesZipCode,
  fetchZip,
  fetchCitiesZipCode,
} from "@/api/addressApi";
import { apiStatus, apiStatusComputedFactory } from "@/api";
const { IDLE, PENDING, SUCCESS, ERROR } = apiStatus;

export default defineComponent({
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      citySearchInput: { required },
      stateSearchInput: { required },
      zipSearchInput: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(5),
      },
    };
  },
  emits: ["addressResults"],
  props: {
    institution: {
      type: Object,
      required: true,
    },
    textSize: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      fetchCitiesStatus: IDLE,
      fetchStatesStatus: IDLE,
      fetchStatesZipCodeStatus: IDLE,
      fetchZipStatus: IDLE,
      fetchCitiesZipCodeStatus: IDLE,

      citySearchInput: "",
      citySearchID: null,
      stateSearchInput: "",
      stateSearchID: null,
      zipSearchInput: "",
      zipSearchID: null,

      citySearchResults: null,
      stateSearchResults: null,
      zipSearchResults: null,

      localStates: null,
      forceZip: false,
      forceZipPlaceId: null,
      forceZipStateId: null,
    };
  },
  computed: {
    ...apiStatusComputedFactory([
      "fetchCitiesStatus",
      "fetchStatesStatus",
      "fetchStatesZipCodeStatus",
      "fetchZipStatus",
      "fetchCitiesZipCodeStatus",
    ]),
  },
  created() {
    this.localStates = this.fetchStates();
    this.citySearchInput = this.institution.address.place;
    this.citySearchID = this.institution.address.idPlace;
    this.stateSearchInput = this.institution.address.state;
    this.stateSearchID = this.institution.address.idState;
    this.zipSearchInput = this.institution.address.zipCode;
    this.zipSearchID = this.institution.address.idZipCode;
  },
  watch: {
    institution: function () {
      this.citySearchInput = this.institution.address.place;
      this.citySearchID = this.institution.address.idPlace;
      this.stateSearchInput = this.institution.address.state;
      this.stateSearchID = this.institution.address.idState;
      this.zipSearchInput = this.institution.address.zipCode;
      this.zipSearchID = this.institution.address.idZipCode;
    },
  },
  methods: {
    cityKeyUp() {
      if (!this.citySearchInput && !this.stateSearchInput) {
        this.forceZip = null;
      }
      if (!this.citySearchInput) {
        this.citySearchID = null;
      }
      this.fetchCities();
    },
    stateKeyUp() {
      if (!this.citySearchInput && !this.stateSearchInput) {
        this.forceZip = null;
      }
      if (!this.stateSearchInput) {
        this.stateSearchID = null;
      }
      this.fetchStateResults();
    },
    zipKeyUp() {
      if (!this.zipSearchInput) {
        this.zipSearchID = null;
      }
      if (this.forceZip) {
        if (this.citySearchInput) {
          this.fetchCitiesZipCode(this.forceZipPlaceId);
        } else {
          this.fetchStatesZipCode(this.forceZipStateId);
        }
      } else {
        this.fetchZip();
      }
    },

    async fetchCities() {
      if (!this.citySearchInput) {
        this.citySearchResults = null;
        return;
      }

      this.fetchCitiesStatus = PENDING;
      try {
        const res = await fetchCities({
          params: {
            keyWord: this.citySearchInput,
          },
        });
        if (res.data.places.length) {
          this.zipSearchResults = null;
          this.fetchCitiesStatus = SUCCESS;
          let showPlacesStates = [];
          res.data.places.forEach((element) => {
            let showPlace = {};
            showPlace.id = element.id;
            showPlace.name = element.name;
            showPlace.idState = element.idState;
            showPlace = this.returnStateFromId(showPlace);

            showPlace.nameHighlight = this.boldString(
              showPlace.name,
              this.citySearchInput
            );
            showPlacesStates.push(showPlace);
          });
          this.citySearchResults = showPlacesStates;
        }
      } catch (err) {
        console.log(err);
        this.fetchCitiesStatus = ERROR;
      }
    },
    async fetchStates() {
      this.fetchStatesStatus = PENDING;
      try {
        const res = await fetchStates({});
        this.localStates = res.data.states;
        this.fetchStatesStatus = SUCCESS;
      } catch (err) {
        console.log(err);
        this.fetchStatesStatus = ERROR;
      }
    },
    fetchStateResults() {
      if (this.stateSearchInput) {
        this.stateSearchResults = [];
        this.localStates.forEach((element) => {
          if (
            element.name
              .toLowerCase()
              .includes(this.stateSearchInput.toLowerCase())
          ) {
            element.nameHighlight = this.boldString(
              element.name,
              this.stateSearchInput
            );
            this.stateSearchResults.push(element);
          }
        });
      } else {
        this.stateSearchResults = null;
      }
    },

    async fetchZip() {
      if (!this.zipSearchInput) {
        this.zipSearchResults = null;
        return;
      }

      this.fetchZipStatus = PENDING;
      try {
        const res = await fetchZip({
          params: {
            keyWord: this.zipSearchInput,
          },
        });
        if (res.data.zipCodes.length) {
          this.citySearchResults = null;
          let zipResults = [];
          res.data.zipCodes.forEach((element) => {
            let zipResult = {};
            zipResult = element;
            zipResult.zipCodeHighlight = this.boldString(
              zipResult.zipCode,
              this.zipSearchInput
            );
            zipResults.push(zipResult);
          });
          this.zipSearchResults = zipResults;
          this.fetchZipStatus = SUCCESS;
        }
      } catch (err) {
        console.log(err);
        this.fetchZipStatus = ERROR;
      }
    },
    async fetchCitiesZipCode(idPlace) {
      if (!idPlace) {
        this.zipSearchResults = null;
        return;
      }
      if (!this.zipSearchInput) {
        this.zipSearchResults = null;
      }

      this.fetchCitiesZipCodeStatus = PENDING;
      try {
        const res = await fetchCitiesZipCode(idPlace);
        let foundZipByPlaceId = res.data.zipCodes;
        if (foundZipByPlaceId.length == 1) {
          this.zipSearchInput = foundZipByPlaceId[0].zipCode;
          this.zipSearchID = foundZipByPlaceId[0].id;
        } else {
          this.forceZip = true;
          this.forceZipPlaceId = idPlace;

          if (this.zipSearchInput) {
            let forcedZips = [];
            foundZipByPlaceId.forEach((element) => {
              if (element.zipCode.includes(this.zipSearchInput)) {
                let forcedZip = {};
                forcedZip = element;
                forcedZip.zipCodeHighlight = this.boldString(
                  forcedZip.zipCode,
                  this.zipSearchInput
                );
                forcedZips.push(forcedZip);
              }
            });
            if (forcedZips.length) {
              this.zipSearchResults = forcedZips;
            }
          }
        }
        this.fetchCitiesZipCodeStatus = SUCCESS;
      } catch (err) {
        console.log(err);
        this.fetchCitiesZipCodeStatus = ERROR;
      }
    },
    async fetchStatesZipCode(idState) {
      if (!idState) {
        this.zipSearchResults = null;
        return;
      }
      if (!this.zipSearchInput) {
        this.zipSearchResults = null;
      }

      this.fetchStatesZipCodeStatus = PENDING;
      try {
        const res = await fetchStatesZipCode(idState);
        let foundZipByStateId = res.data.places;

        this.forceZip = true;
        this.forceZipStateId = idState;

        if (this.zipSearchInput) {
          let forcedZips = [];
          foundZipByStateId.forEach((element) => {
            if (element.zipCodes[0].zipCode.includes(this.zipSearchInput)) {
              let forcedZip = {};
              forcedZip = element.zipCodes[0];
              forcedZip.zipCodeHighlight = this.boldString(
                forcedZip.zipCode,
                this.zipSearchInput
              );
              forcedZips.push(forcedZip);
            }
          });
          if (forcedZips.length) {
            this.zipSearchResults = forcedZips;
          }
        }

        this.fetchStatesZipCodeStatus = SUCCESS;
      } catch (err) {
        console.log(err);
        this.fetchStatesZipCodeStatus = ERROR;
      }
    },

    citySelectResult(place) {
      this.citySearchInput = place.name;
      this.stateSearchInput = place.stateName;
      this.zipSearchInput = null;

      this.citySearchID = place.id;
      this.stateSearchID = place.idState;

      this.fetchCitiesZipCode(place.id).then((resolve, reject) => {
        this.$emit(
          "addressResults",
          this.citySearchInput,
          this.stateSearchInput,
          this.zipSearchInput,
          this.citySearchID,
          this.stateSearchID,
          this.zipSearchID
        );
      });

      this.zipSearchResults = null;
      this.stateSearchResults = null;
      this.citySearchResults = null;
    },
    stateSelectResult(state) {
      this.stateSearchInput = state.name;
      this.stateSearchID = state.id;

      this.fetchStatesZipCode(state.id);

      this.$emit(
        "addressResults",
        this.citySearchInput,
        this.stateSearchInput,
        this.zipSearchInput,
        this.citySearchID,
        this.stateSearchID,
        this.zipSearchID
      );

      this.zipSearchResults = null;
      this.stateSearchResults = null;
      this.citySearchResults = null;
    },
    zipSelectResult(zip) {
      this.zipSearchInput = zip.zipCode;

      if (!this.forceZip) {
        let getStateandCity = this.returnStateFromId(zip.place);
        this.citySearchInput = getStateandCity.name;
        this.stateSearchInput = getStateandCity.stateName;

        this.citySearchID = zip.idPlace;
        this.stateSearchID = zip.place.idState;
        this.zipSearchID = zip.id;

        this.$emit(
          "addressResults",
          this.citySearchInput,
          this.stateSearchInput,
          this.zipSearchInput,
          this.citySearchID,
          this.stateSearchID,
          this.zipSearchID
        );
      } else {
        // need to rerun the API call to get placeID and stateId
        this.fetchZip().then((resolve, reject) => {
          let getStateandCity = this.returnStateFromId(
            this.zipSearchResults[0].place
          );
          this.citySearchInput = getStateandCity.name;
          this.stateSearchInput = getStateandCity.stateName;

          this.citySearchID = getStateandCity.id;
          this.stateSearchID = getStateandCity.idState;
          this.zipSearchID = this.zipSearchResults[0].id;

          this.$emit(
            "addressResults",
            this.citySearchInput,
            this.stateSearchInput,
            this.zipSearchInput,
            this.citySearchID,
            this.stateSearchID,
            this.zipSearchID
          );

          this.zipSearchResults = null;
        });
      }

      this.zipSearchResults = null;
      this.stateSearchResults = null;
      this.citySearchResults = null;
    },

    boldString(str, find) {
      let reg = new RegExp("(" + find + ")", "gi");
      return str.replace(reg, "<b>$1</b>");
    },
    returnStateFromId(element) {
      this.localStates.forEach((foundState) => {
        if (foundState.id == element.idState) {
          element.name = element.name + ", " + foundState.abbreviation;
          element.stateId = foundState.id;
          element.stateName = foundState.name;
        }
      });
      return element;
    },
  },
});
</script>

<style scoped lang="scss">
.dropdown_input_wrapper {
  position: relative;
  width: 100%;
  height: 0px;
  margin: 0px;
  ul {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: block;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
    box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
    max-height: 300px;
    overflow: scroll;
    li {
      display: block;
      padding: 3px 20px;
      line-height: 1.42857143;
      white-space: nowrap;
      cursor: pointer;
    }
    li.active,
    li:hover {
      color: #fff;
      text-decoration: none;
      background-color: $primaryLightBlue;
      outline: 0;
    }
  }
}
</style>
