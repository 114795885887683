<template lang="pug">
div
  label(v-for="option in value.options" :key="option.id" class="radio-custom no-label--margin" :class="classRadio(option.name)" )
    input(type="radio", :name="value.id" :value="option.id" @change="sendAnswer(value,option.id)" v-model="inputValue")
    span.radio
    span.lbl {{option.name }}

</template>
<script>
import { defineComponent } from "vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default defineComponent({
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    let args = {
      inputValue: {},
    };
    if (this.value.required) {
      args = {
        inputValue: { required },
      };
    }
    return args;
  },
  data() {
    return {
      inputValue: "",
    };
  },
  methods: {
    sendAnswer(value, eventTarget) {
      this.$emit("emitAnswer", value, eventTarget);
    },
    classRadio: function (option) {
      return "radio-custom--" + option.toLowerCase();
    },
  },
  emits: ["emitAnswer"],

  props: {
    value: {
      type: Object,
      required: true,
    },
    default: {
      type: Boolean,
      required: false,
    },
  },
  watch: {
    default: function (newVal) {
      this.inputValue = newVal;
    },
  },
  created() {
    if (this.value === null) return;
    this.inputValue = this.default;
  },
});
</script>
<style scoped lang="scss">
.radio-custom {
  // margin-right: 15px;
  margin-right: 26px;
}
.no-label--margin{
  margin-bottom:0px;
}
</style>
