// @ts-nocheck
export default {
  data() {
    return {
      sortings: {
        DateTime: [
          undefined,
          { by: "date-time", icon: "▲", direction: "asc" },
          { by: "date-time", icon: "▼", direction: "desc" },
        ],
        PatientName: [
          undefined,
          { by: "patient-full-name", icon: "▲", direction: "asc" },
          { by: "patient-full-name", icon: "▼", direction: "desc" },
        ],
        CommunityCare: [
          undefined,
          { by: "community-name", icon: "▲", direction: "asc" },
          { by: "community-name", icon: "▼", direction: "desc" },
        ],
        LastActivity: [
          undefined,
          { by: "status", icon: "▲", direction: "asc" },
          { by: "status", icon: "▼", direction: "desc" },
        ],
      },
      currentSortKey: "DateTime",
      currentSortIndex: 0,
      listOrder: undefined,
    };
  },

  computed: {
    DateTimeColumnSortingIcon() {
      if (this.currentSortKey === "DateTime") {
        return this.sortings.DateTime[this.currentSortIndex]?.icon || "▼▲";
      }
      return "▼▲";
    },
    PatientNameColumnSortingIcon() {
      if (this.currentSortKey === "PatientName") {
        return this.sortings.PatientName[this.currentSortIndex]?.icon || "▼▲";
      }
      return "▼▲";
    },
    CommunityCareColumnSortingIcon() {
      if (this.currentSortKey === "CommunityCare") {
        return this.sortings.CommunityCare[this.currentSortIndex]?.icon || "▼▲";
      }
      return "▼▲";
    },
    LastActivityColumnSortingIcon() {
      if (this.currentSortKey === "LastActivity") {
        return this.sortings.LastActivity[this.currentSortIndex]?.icon || "▼▲";
      }
      return "▼▲";
    },
  },

  methods: {
    changeListOrder(key, callback) {
      if (this.currentSortKey === key) {
        this.updateCurrentSortingIndex(callback);
        return;
      }

      this.currentSortKey = key;
      this.currentSortIndex = 0;
      this.updateCurrentSortingIndex(callback);
    },
    updateCurrentSortingIndex(callback) {
      if (
        this.currentSortIndex ===
        this.sortings[this.currentSortKey].length - 1
      ) {
        this.currentSortIndex = 0;
        this.listOrder = this.sortings[this.currentSortKey][0];
        callback();
        return;
      }

      this.currentSortIndex++;
      this.listOrder =
        this.sortings[this.currentSortKey][this.currentSortIndex];
      return callback();
    },
  },
};
