// @ts-nocheck
import api from "./api";
const URLS = {
  FILES: `/files`,
};
const getFileFromSessionStorage = (key: string): string | null =>
  sessionStorage.getItem(key);

const urltoFile = (url, filename, mimeType) => {
  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    });
};

export const fetchFileById = (fileId: number): Promise<any> => {
  return api.get(`${URLS.FILES}/${fileId}`);
};

export const uploadFile = async (storageKey: string): Promise<any> => {
  const { name, url, type } = JSON.parse(
    getFileFromSessionStorage(storageKey) || "{}"
  );
  if (!name || !url || !type) {
    return {};
  }
  const blobFileToUpload = await urltoFile(url, name, type);
  const data = new FormData();
  data.append("upload", blobFileToUpload);
  const config = {
    header: {
      "Content-Type": "multipart/form-data",
    },
  };

  const response = await api.post(`${URLS.FILES}/upload`, data, config);

  if (!response.data.success) {
    throw new Error(
      respons.data.message,
      "something went wrong uploading the selected file"
    );
  }

  return response.data;
};

export const deleteFileById = (fileId: number): Promise<any> => {
  return api.delete(`${URLS.FILES}/${fileId}`);
};
