<template lang="pug">
PopupCardSlot(
  :isOpen="isDeleteSchedulePopupOpen",
  maxWidth="675px"
)
  .delete-schedule-popup
    h1 Are you sure you want to delete this slot?
    p Please confirm to continue
    .popup-btns
      BaseBtn.x-lg.red-outline(@click="closeDeleteSchedulePopup") CANCEL
      BaseBtn.x-lg.green(@click.prevent="closeDeleteSchedulePopup") YES, CONTINUE


.content-wrapper
  .card.router-view-card
    section.all-clinics
    h2.big_title AVAILABLE APPOINTMENT SLOTS
    TableTitle(title="All Schedules")
      template(#actions)
        select.table-action-selector.table-action-selector--hospital(
          :title="hospitalTitle"
          @change="setFilterValueHospital($event.target.value)"
          style="margin-right: 24px;"
        )
          option(value="") HOSPITAL NAME
          option(v-for="hospital in hospitals" :key="hospital.id" :value="hospital.id") {{ hospital.name }}
        select.table-action-selector.table-action-selector--insurance(
          :title="insuranceTitle",
          @change="setFilterValueInsurance($event.target.value)"
          style="margin-right: 24px;"
        )
          option INSURANCE
          option(value="true") Insured
          option(value="false") Uninsured
        DateSelector(@filterByDate="filterByDate"
          :min-date="startDateOffset"
        )
    .table
      .th
        .td(@click.prevent="changeListOrder('HospitalName', fetchPatientAppointmentAvailableSchedule)" style="cursor: pointer;") HOSPITAL
          i(style="font-weight: bolder;") {{ HospitalNameColumnSortingIcon }}
        .td(@click.prevent="changeListOrder('DateAndTime', fetchPatientAppointmentAvailableSchedule)" style="cursor: pointer;") DATE AND TIME
          i(style="font-weight: bolder;") {{ DateAndTimeColumnSortingIcon }}
        .td INSURANCE
      .tr( v-if="schedules"
        v-for="schedule in schedules",
        :key="schedule.id"
      )
        //-  @click="openAppointmentRecordOverlay"
        .td {{ schedule.hospital.name }}
        .td
          div {{ getDateFromUnixFormatted(schedule.availableAt) }}
          .second-row-md-grey {{ getTimeFromUnixFormatted(schedule.availableAt)}}
        .td 
          base-icon(name="yes", v-if="schedule.insuranceRequired")
          base-icon(name="no", v-else)
    Pagination(
      v-if="pagination && schedules != null && schedules.length",
      :pagination="pagination",
      :reloadFn="fetchPatientAppointmentAvailableSchedule"
      @changePagination="changePagination"
    )
    .no-results(v-if="schedules != null && !schedules.length") 
      base-icon(name="exclamation") 
      span No matching records
    .actions-below-table-container

AddScheduleOverlay(
  :isOpen="isAddScheduleOverlayOpen",
  @closeAddScheduleOverlay="closeAddScheduleOverlay"
)
</template>

<script>
import { defineComponent } from "vue";
import moment from "moment";

import TableTitle from "@/components/TableTitle.vue";
import DateSelector from "@/components/DateSelector.vue";
import Pagination from "@/components/Pagination.vue";
import listPagesMixin from "@/mixins/listPagesMixin";

import AddScheduleOverlay from "@/components/clinics/schedules/overlays/AddScheduleOverlay";
import PopupCardSlot from "@/components/PopupCardSlot.vue";

import { fetchCHSHospitals } from "@/api/chsApi";
import { fetchPatientAppointmentAvailableSchedule } from "@/api/patientAppointmentApi";
import { apiStatus, apiStatusComputedFactory } from "@/api";
const { IDLE, PENDING, SUCCESS, ERROR } = apiStatus;

import { getDateFromUnixFormatted, getTimeFromUnixFormatted } from "@/helpers";
import { mapGetters } from "vuex";
import sortClinicSchedules from "@/mixins/sortClinicSchedules";

export default defineComponent({
  components: {
    TableTitle,
    DateSelector,
    Pagination,
    AddScheduleOverlay,
    PopupCardSlot,
  },
  mixins: [listPagesMixin, sortClinicSchedules],

  data() {
    return {
      isAppointmentRecordOverlayOpen: false,
      isAppointmentNotesOverlayOpen: false,
      isAddScheduleOverlayOpen: false,
      isDeleteSchedulePopupOpen: false,
      fetchPatientAppointmentAvailableScheduleStatus: IDLE,
      schedules: null,

      fetchCHSHospitalsStatus: IDLE,
      insuranceTitle: "INSURANCE",
      hospitalTitle: "HOSPITAL NAME",
      hospitals: null,
      filterIdHospital: null,
      isInsuranceRequired: null,
      startDateOffset: new Date(Date.now() + 15 * 24 * 60 * 60 * 1000),
    };
  },
  computed: {
    ...mapGetters(["getUserRoleContext"]),
    ...apiStatusComputedFactory(
      "fetchPatientAppointmentAvailableScheduleStatus"
    ),
  },
  props: {
    specialtyModule: {
      required: false,
    },
  },
  methods: {
    getDateFromUnixFormatted,
    getTimeFromUnixFormatted,
    async fetchPatientAppointmentAvailableSchedule() {
      this.fetchPatientAppointmentAvailableScheduleStatus = PENDING;
      const startDateOffsetNoMiliseconds = Math.floor(
        this.startDateOffset / 1000
      );

      let navigatePage = 1;
      if (this.reqPagination.page) {
        navigatePage = this.reqPagination.page;
      }
      const order = this.listOrder
        ? {
            by: this.listOrder.by,
            direction: this.listOrder.direction,
          }
        : null;
      try {
        const res = await fetchPatientAppointmentAvailableSchedule({
          params: {
            ...this.reqPagination,
            order,
            collapsedDoctor: false,
            idClinicHealthSystem: this.getUserRoleContext.clinicHealthSystem.id,
            idSpecialtyModule: this.$route.params.idSpecialty,
            include: ["hospital", "patientAppointment"],
            isAvailable: "Available",
            page: navigatePage,
            startDate: startDateOffsetNoMiliseconds,
            availableAt: this.date ?? null,
            isInsuranceRequired: this.isInsuranceRequired ?? null,
            idHospital: this.filterIdHospital ?? null,
          },
        });
        this.schedules = res.data.schedules;
        this.pagination = res.data.pagination;
        this.fetchPatientAppointmentAvailableScheduleStatus = SUCCESS;
      } catch (err) {
        this.fetchPatientAppointmentAvailableScheduleStatus = ERROR;
        console.error(err);
      }
    },
    filterByDate(date) {
      this.date = date;
      this.fetchPatientAppointmentAvailableSchedule();
    },
    setFilterValueHospital(value) {
      if (value != "") {
        this.filterIdHospital = value;
        this.hospitals.forEach((hospital) => {
          if (hospital.id == value) {
            this.hospitalTitle = hospital.name;
          }
        });
      } else {
        this.filterIdHospital = null;
        this.hospitalTitle = "HOSPITAL NAME";
      }
      this.fetchPatientAppointmentAvailableSchedule();
    },
    setFilterValueInsurance(value) {
      if (value === "true" || value === "false") {
        if (value === "true") {
          this.insuranceTitle = "INSURED";
        } else {
          this.insuranceTitle = "UNINSURED";
        }
        this.isInsuranceRequired = value;
      } else {
        this.insuranceTitle = "INSURANCE";
        this.isInsuranceRequired = null;
      }
      this.fetchPatientAppointmentAvailableSchedule();
    },
    async fetchCHSHospitals() {
      this.fetchCHSHospitalsStatus = PENDING;
      try {
        const res = await fetchCHSHospitals(
          this.getUserRoleContext.clinicHealthSystem.id
        );
        this.hospitals = res.data.hospitals;
        this.fetchCHSHospitalsStatus = SUCCESS;
      } catch (err) {
        this.fetchCHSHospitalsStatus = ERROR;
        console.error(err);
      }
    },

    openAddScheduleOverlay() {
      this.isAddScheduleOverlayOpen = true;
    },
    closeAddScheduleOverlay() {
      this.isAddScheduleOverlayOpen = false;
    },
    openDeleteSchedulePopup() {
      this.isDeleteSchedulePopupOpen = true;
    },
    closeDeleteSchedulePopup() {
      this.isDeleteSchedulePopupOpen = false;
    },
  },
  created() {
    if (this.$route.params.idClinic === null) return;
    this.fetchPatientAppointmentAvailableSchedule();
    this.fetchCHSHospitals();
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/components/actions-below-table-container.scss";

.td {
  &:nth-child(1) {
    width: math.percentage(math.div(443, $tabContentCardWidth));
  }
  &:nth-child(2) {
    width: math.percentage(math.div(352, $tabContentCardWidth));
  }
  &:nth-child(3) {
    width: math.percentage(math.div(385, $tabContentCardWidth));
  }
}
.delete-schedule-popup {
  max-width: 555px;
  margin: 0 auto;
  h1 {
    width: 100%;
    max-width: 549px;
    margin: 39px auto 30px;
    font-size: 28px;
    font-weight: 700;
    line-height: 1.21;
    letter-spacing: 0.14px;
    text-align: center;
  }
  p {
    margin-top: 0;
    margin-bottom: 32px;
    font-size: 16px;
    line-height: 1.19;
    letter-spacing: 0.23px;
    text-align: center;
  }
  .popup-btns {
    margin-bottom: 39px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .btn {
      width: 260px;
    }
  }
}
</style>
<style>
.main-container {
  padding-bottom: 100px;
}
</style>
