// @ts-nocheck
import { createStore } from "vuex";
import account from "@/store/account";
import currentClinicSpecialties from "@/store/current-clinic";
import currentHospitalSpecialties from "@/store/current-hospital-specialties";
import currentCommunityOrganization from "./current-community-organization";
import notifications from "@/store/notifications";

const store = createStore({
  modules: {
    account,
    currentClinicSpecialties,
    currentHospitalSpecialties,
    currentCommunityOrganization,
    notifications,
  },
  state() {
    return {};
  },
  mutations: {},
  actions: {},
});

export default store;
