<template lang="pug">
.full-screen-page.account-pages(:class="$route.name")
  .card
    .card-inner
      base-icon(name="icc-logo-color")
      h1 IL Care Continuum
      router-view()
  .text-btm
    p Illinois Care CONTINUUM is a proprietary collaborative software portal for hospital systems to connect with their partnering clinics for follow up specialty care. <br>Copyright {{new Date().getFullYear()}}
    .hipaa-logo

</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  components: {},

  props: {
    // project: {
    //   type: Object,
    //   required: true
    // }
  },

  data() {
    return {};
  },

  computed: {},

  watch: {
    // keywords: {
    //   handler: "getRows",
    //   immediate: true
    // }
  },

  methods: {},
});
</script>

<style scoped lang="scss">
@import "@/scss/components/full-screen-page.scss";
.account-pages {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $primaryLightBlue;
  background-image: url("../assets/images/icc-logo-white.svg");
  background-repeat: no-repeat;
  background-size: 405.8px 661.8px;
  background-position: -85.8px calc(100% + 14.5px);
  .card {
    width: 100%;
    max-width: 674px;
    min-height: 612px;
    margin-top: auto;
    margin-bottom: auto;
    padding: 43.2px 90px 67px;
    border: solid 1px $primaryBgDarkGrey;
    border-radius: 30px;
    box-shadow: 5px 5px 30px 0 rgba(0, 0, 0, 0.3);
  }
  .svg-icon--icc-logo-color {
    display: block;
    width: 88px;
    height: 143.5px;
    top: 0;
    margin: 0 auto;
  }
  h1 {
    font-size: 36px;
    font-weight: 700;
    line-height: 1.2;
    text-align: center;
    color: $tertiaryBrown;
    margin-top: 11px;
    margin-bottom: 57px;
  }
}
.text-btm {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin: 28px 0 8px;
  p {
    margin: 0;
    padding-top: 25px;
    width: auto;
    max-width: 489px;
    font-size: 12px;
    line-height: 1.75;
    text-align: center;
    color: #fff;
  }
  .hipaa-logo {
    position: absolute;
    right: 60px;
    width: 65px;
    height: 88px;
    background-image: url("../assets/images/hipaa-logo.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
}
</style>
