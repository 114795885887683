<template lang="pug">
.columns(v-if="!hasCustomText && isDisplayable")
  .column.is-two-thirds 
    .card
      h2 User Support
      p If you're experiencing an error or you need assistance using {{APP_NAME}}, please email: <a :href="`mailto:${SUPPORT_EMAIL}`">{{SUPPORT_EMAIL}}</a>
      p To facilitate a rapid and accurate response, please copy and paste these below questions with corresponding answers into the email:

      section.blue-section
        .blue-section__top
          p.bold Your Name:
          p.bold Name of the Hospital or Clinic:
          p.bold Your {{APP_NAME}} Role (Staff or Manager):
          p.bold Date/Time & Frequency of Occurrence:
          p.bold Complete Description of the issue, including the steps leading up to the problem:
          p.bold Screenshot of the error (please attach photos)
          p.bold Your computer type (PC or Mac):
          p.bold Browser used (Internet Explorer, Chrome, Safari, etc.):

      p You will receive a same day response on weekdays from 9 AM to 6 PM U.S. Central Time.
  .column.is-one-third
    .card.card--blue-bg
      h2 Resources
      p.extra-margin-bot Below you will find training resources as well as research materials to download.
.columns(v-if="hasCustomText && isDisplayable")
  .column.is-two-thirds 
    .card
      h2 User Support
      <div v-html="userSupportText"></div>
      <div v-html="userSupportTextClinic"></div>
  .column.is-one-third
    .card.card--blue-bg
      h2 Resources
      <div v-html="resourcesText"></div>
      <div v-html="resourcesTextClinic"></div>
      p.bold(v-for="item in resourceFiles", :key="item.file.name")
        a(href="#", @click.prevent="openFile(item.downloadUrl)") {{item.title}}
    
</template>

<script>
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import { fetchCHS, fetchCHSFiles } from "@/api/chsApi";
import {
  fetchCommunityCareOrganizationById,
  fetchCommunityCareOrganizationFilesById,
} from "@/api/communityOrganization";
import { fetchSettings, fetchSettingsFiles } from "@/api/settingsApi";
import { fetchFileById } from "@/api/filesApi";
import { preview } from "@/helpers/preview";
import { getFileSignedUrl } from "@/helpers";
import sanitizeHtml from "sanitize-html";

export default defineComponent({
  components: {},
  data() {
    return {
      hasCustomText: false,
      isDisplayable: false,
      files: [],
      resourceFiles: [],
      userSupportText: null,
      resourcesText: null,
      userSupportTextClinic: null,
      resourcesTextClinic: null,
      idClinicHealthSystem: null,
      APP_NAME: process.env.APP_NAME || "Illinois Care Continuum",
      SUPPORT_EMAIL: process.env.SUPPORT_EMAIL || "support@ilcoloncares.org",
      timer: null,
    };
  },
  computed: {
    ...mapGetters([
      "isSubSystemClinic",
      "aChsIsSelected",
      "getChsId",
      "isSubSystemCommunityOrganization",
      "getCCOId",
    ]),
  },
  methods: {
    async getGeneralSettings() {
      try {
        const filesResponse = await fetchSettingsFiles();
        const dataFiles = filesResponse.data;
        if (dataFiles && dataFiles.success) {
          this.files = dataFiles.files;
          const completeFiles = await Promise.all(
            this.files.map((resource) => fetchFileById(resource.idFile))
          );
          this.files = this.files.map((resource) => {
            const fileComplete = completeFiles.find(
              (response) => response?.data?.file?.id === resource.idFile
            );
            const file = fileComplete?.data?.file;
            file.token = fileComplete?.data?.token;

            const downloadUrl = getFileSignedUrl(file);
            if (downloadUrl) {
              return { ...resource, downloadUrl };
            } else {
              return resource;
            }
          });
        }
        const { data } = await fetchSettings();
        if (data.success && data.settings) {
          const generalSettings = data.settings;
          this.userSupportText = this.stripDangerousHTML(
            generalSettings.userSupportText
          );
          this.resourcesText = this.stripDangerousHTML(
            generalSettings.resourcesText
          );
        }
        this.hasCustomText =
          this.userSupportText !== "" || this.resourcesText !== "";
        if (this.isSubSystemClinic) {
          const idClinicHealthSystem = await this.getCHSid();
          if (idClinicHealthSystem > 0) {
            const clinicHealthSystem = await this.getClinicHealthSystem(
              idClinicHealthSystem
            );
            const settingsFiles = await this.getRelatedFiles(
              idClinicHealthSystem
            );
            const completeFilesClinic = await Promise.all(
              settingsFiles.map((resource) => fetchFileById(resource.idFile))
            );
            const clinicFiles = settingsFiles.map((resource) => {
              const fileComplete = completeFilesClinic.find(
                (response) => response?.data?.file?.id === resource.idFile
              );
              const file = fileComplete?.data?.file;
              file.token = fileComplete?.data?.token;

              const downloadUrl = getFileSignedUrl(file);
              if (downloadUrl) {
                return { ...resource, downloadUrl };
              } else {
                return resource;
              }
            });
            if (clinicFiles && clinicFiles.length > 0) {
              this.files = this.files.concat(clinicFiles);
            }
            this.userSupportTextClinic = this.stripDangerousHTML(
              clinicHealthSystem.userSupportText
            );
            this.resourcesTextClinic = this.stripDangerousHTML(
              clinicHealthSystem.resourcesText
            );
            if (!this.hasCustomText) {
              this.hasCustomText =
                this.userSupportTextClinic !== "" ||
                this.resourcesTextClinic !== "";
            }
          }
        }

        if (this.isSubSystemCommunityOrganization) {
          if (this.getCCOId > 0) {
            const cco = await this.fetchCommunityCareOrganizationById(
              this.getCCOId
            );

            const settingsFiles = await this.getRelatedFilesCCO(this.getCCOId);

            const completeFilesCommunity = await Promise.all(
              settingsFiles.map((resource) => fetchFileById(resource.idFile))
            );
            const communityFiles = settingsFiles.map((resource) => {
              const fileComplete = completeFilesCommunity.find(
                (response) => response?.data?.file?.id === resource.idFile
              );
              const file = fileComplete?.data?.file;
              file.token = fileComplete?.data?.token;

              const downloadUrl = getFileSignedUrl(file);
              if (downloadUrl) {
                return { ...resource, downloadUrl };
              } else {
                return resource;
              }
            });

            if (communityFiles && communityFiles.length > 0) {
              this.files = this.files.concat(communityFiles);
            }

            this.userSupportTextClinic = this.stripDangerousHTML(
              cco.userSupportText
            );

            this.resourcesTextClinic = this.stripDangerousHTML(
              cco.resourcesText
            );

            if (!this.hasCustomText) {
              this.hasCustomText =
                this.userSupportTextClinic !== "" ||
                this.resourcesTextClinic !== "";
            }
          }
        }
        this.resourceFiles = [...this.files];
      } catch (e) {
        console.error(e);
      }
    },
    async fetchCommunityCareOrganizationById(idCCO) {
      const ccoRes = await fetchCommunityCareOrganizationById(idCCO);
      return ccoRes.data;
    },
    async getCHSid() {
      if (this.aChsIsSelected) {
        return this.getChsId;
      }
      return 0;
    },
    async getClinicHealthSystem(idChs) {
      const chsRes = await fetchCHS(idChs);
      return chsRes.data;
    },
    async getRelatedFilesCCO(idCCO) {
      const {
        data: { files },
      } = await fetchCommunityCareOrganizationFilesById(idCCO);
      return files;
    },
    async getRelatedFiles(idChs) {
      const {
        data: { files },
      } = await fetchCHSFiles(idChs);
      return files;
    },
    stripDangerousHTML(value) {
      if (value) {
        return sanitizeHtml(value);
      }
    },
    openFile(src) {
      preview(src);
    },
    cancelAutoRefresh() {
      clearInterval(this.timer);
    },
  },
  unmounted() {
    this.cancelAutoRefresh();
  },
  async created() {
    await this.getGeneralSettings();
    this.isDisplayable = true;
    this.timer = setInterval(this.getGeneralSettings, 25000);
  },
});
</script>

<style scoped lang="scss">
.extra-margin-bot {
  margin-bottom: 39px;
}
.column {
  padding-left: 15px;
  padding-right: 15px;
}
.card {
  padding: 42px 35px 112px 35px;
  h2 {
    margin-bottom: 50px;
  }
  p {
    max-width: 615px;
  }
}
.blue-section {
  position: relative;
  z-index: 1;
  margin-bottom: 31px;
  padding: 30px 24px 15px 34px;
  background-color: $primaryBgBlue;
  color: $primaryLightBlue;
  &__top {
    margin-bottom: 8px;
    p {
      margin: 0;
      margin-bottom: 12px;
      font-size: 16x;
      line-height: 1.67;
      color: #019bdf;
      max-width: 516px;
    }
  }
}
</style>
