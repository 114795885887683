<template lang="pug">
input.upload-input(
  ref="uploadInput",
  v-bind="$attrs",
  type="file",
  @change="handleFile",
  :accept="allowedFileTypes.join(', ')"
)
BaseBtn(
  href="#", 
  @click.prevent="!disabled ? openUploadDialog() : null", 
  :class="computedClasses"
  :disabled="disabled"
) {{buttonTitle}}
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    allowedFileTypes: {
      type: Array,
      default: () => ["image/*", ".pdf", ".csv"],
    },
    styleClasses: {
      type: Array,
      default: () => [],
    },
    storageKey: {
      type: String,
      required: true,
    },
    src: {
      type: String,
    },
    disabled: {
      required: false,
    },
  },

  computed: {
    computedClasses() {
      return this.styleClasses.join(" ");
    },
    file() {
      return this.src || "";
    },
    buttonTitle() {
      return this.file ? "REUPLOAD" : "UPLOAD";
    },
  },

  methods: {
    handleFile(e) {
      const file = e.target.files[0]; // get first file
      this.createBase64Image(file);
    },
    createBase64Image(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        //save needed props for later upload
        const file = {
          name: fileObject.name,
          type: fileObject.type,
          url: e.target.result,
        };

        sessionStorage.setItem(this.storageKey, JSON.stringify(file));
        this.$emit("fileLoaded", e.target.result);
      };
      reader.readAsDataURL(fileObject);
    },
    openUploadDialog() {
      this.$refs.uploadInput.click();
    },
  },
});
</script>

<style scoped lang="scss">
.upload-input {
  box-shadow: none;
  outline: none;
  display: block;
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
</style>
