<template lang="pug">
.line-graph(v-if="percentages")
  .line(v-for="(stat, index) in stats", :key="index")
    .bar-wrapper
      .bar(
        :style="{ width: percentages[index] + '%', backgroundColor: stat.backgroundColor }"
      )
    .number-lable
      .number
        span {{stat.number}}
      .label {{stat.label}}
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    stats: {
      type: Array,
      required: true,
    },
  },

  computed: {
    percentages() {
      const numbers = this.stats.map((item) => {
        return item.number;
      });

      // const isAllZero = numbers.every((item) => item === "0");
      // if (isAllZero) return Array(numbers.length).fill("0");

      const maxValue = Math.max(...numbers);
      const percentages = numbers.map((number) => {
        return (number / maxValue) * 100;
      });
      return percentages;
    },
  },
});
</script>

<style scoped lang="scss">
.line-graph {
  width: 100%;
}
.line {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bar-wrapper {
  width: 62%;
}
.bar {
  height: 17px;
  border-radius: 9px;
}
.number-lable {
  width: 32%;
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  position: relative;
  top: -2px;
  left: -29px;
  margin-left: 20px;
}
.number {
  display: flex;
  justify-content: flex-end;
  width: 20%;
  font-size: 24px;
  line-height: 1.21;
  letter-spacing: 0.13px;
  color: #000;
}
.label {
  width: 80px;
  margin-left: 16px;
  font-size: 14px;
  line-height: 1.29;
  letter-spacing: 0.07px;
  color: $primaryDarkGrey;
  white-space: nowrap;
}
</style>
