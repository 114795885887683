<template lang="pug">
FullScreenOverlaySlot(
  :isOpen="isOpen",
  headerIcon="primary-care",
  :headerIconString="careIcon",
  title="Referral Record"
  @closeOverlay="closeOverlay(false)"
)
  template(#breadcrumbs)
    h1.breadcrumbs-title {{ careTitle }}
  template(#actionSection)
    BaseBtn.x-lg.blue-outline(
      href="#",
      @click.prevent="openReferralNotesOverlay",
      :count="noteSummary.new"
    ) NOTES ({{ noteSummary.total }})
  template(#content)
    .card(style="padding-top: 52px;")
      .patient-info.columns.is-variable
        .column.is-one-third
          .columns.is-variable
            .info-pair.column.is-one-half
              .label Scheduled Date
              .txt.txt--blue {{ getDateFromUnixFormatted(showReferral.scheduledAt) }}
            .info-pair.column.is-one-half
              .label Scheduled Time
              .txt.txt--blue {{ getTimeFromUnixFormatted(showReferral.scheduledAt) }}
        .column.is-one-third.rescheduled
          .columns.is-variable 
            .column.is-one-half
            .column.is-one-half
              .info-pair
                .label
                  span Rescheduled
                    base-icon(name="yes" v-if="showReferral.status == 'Rescheduled'")
                    base-icon(name="no" v-else)
        .column.is-one-third.adjust-margin-top(v-if=" tabType == 'confirmed' ")
          .flex.flex--space-between
            BaseBtn.sm.black-outline(:to="{name: 'primary-care-clinic.reschedule', params: {idCommunity: showReferral.idCommunity, idAppointment: showReferral.id } }") RESCHEDULE
            BaseBtn.sm.red-outline(@click.prevent="openCancelAppointmentOverlay") CANCEL

      .hr.hr--thin
      .columns.is-variable
        .info-pair.column.is-one-third
          .label First Name
          .txt.txt--blue {{ showReferral.patient.first }}
        .info-pair.column.is-one-third
          .label Last Name
          .txt.txt--blue {{ showReferral.patient.last }}
        .info-pair.column.is-one-third
          .label Date of Birth
          .txt.txt--blue {{ getDobFormattedforDateField(showReferral.patient.dob) }}
      .hr.hr--thin
      .info-pair.columns.is-variable
        .column.is-two-thirds.info-pair
          .label Patient Address
          .txt {{ showReferral.patient.address.address}}<br>{{ showReferral.patient.address.place }}, {{ showReferral.patient.address.state }} {{ showReferral.patient.address.zipCode }}
        .column.is-one-third.flex.flex--flex-start.flex--align-start
          .info-pair
            .label Patient Phone Number
            .txt {{ getPhoneFormatted(showReferral.patient.contact.cellPhone) }}
          .info-pair
            .label Ext.
            .txt {{ showReferral.patient.contact.cellPhoneExtension }}
      .hr.hr.hr--thin
      .columns.is-variable
        .info-pair.column.is-one-third
          .label Community Care Name
          .txt {{ showReferral.communityCare.name }} 
        .info-pair.column.is-one-third(v-if="showReferralDetails")
          .label Doctor Name
          .txt {{ getDoctorFormatted(showReferralDetails.schedule.doctor) }}
        
    .card.card--blue-bg
      h2.title.title--md-thin PATIENT RECORD INFORMATION
      .patient-record-info.columns.is-variable
        .info-pair.column.is-one-third(v-if=" tabType != 'requested' || showReferral.patient.mrn")
          .label Clinic MRN
          .txt {{ showReferral.patient.mrn }}
        .column.is-one-third(v-else)
          .form-group(:class="{ 'form-group__invalid': v$.clinicMRN.$errors.length }")
            label.label--margin-sm Clinic MRN
            input.text-lg(type="text" v-model="clinicMRN" @blur="v$.clinicMRN.$touch()")
            .form-group__errors(v-if="v$.clinicMRN.$dirty && v$.clinicMRN.$errors.length")
              .form-group__error Field is required!
    .card
      h2.title.title--md-thin Emergency Contact Information
      .emergency-contact-info.columns
        .info-pair.column.is-two-thirds
          .label Name
          .txt {{ showReferralDetails?.emergencyContact.firstName }} {{ showReferralDetails?.emergencyContact.lastName }}
        .column.is-one-third.flex.flex--flex-start.flex--align-start
          .info-pair
            .label Phone Number
            .txt {{ getPhoneFormatted(showReferralDetails?.emergencyContact.phoneNumber) }}
          .info-pair
            .label Ext.
            .txt {{ showReferralDetails?.emergencyContact.phoneExtension }}
    .card
      .columns.is-variable
        .column.insurance-status.is-one-third.flex.flex--space-between
          p Does patient have insurance?
          base-icon(name="yes", v-if="showReferralDetails?.schedule?.insuranceRequired")
          base-icon(name="no", v-else)
      .hr.hr.hr--thin(v-if="showReferralDetails?.schedule?.insuranceRequired")
      h2.title.title--md-thin(v-if="showReferralDetails?.schedule?.insuranceRequired") Insurance Information
      .insurance-info.columns.is-variable(v-if="showReferralDetails?.schedule?.insuranceRequired")
        .info-pair.column.is-one-third
          .label Insurance Name
          .txt {{ showReferralDetails?.insurance.name || "N/A"}}
        .info-pair.column.is-one-third
          .label Policy Number
          .txt {{ showReferralDetails?.insurance.policyNumber || "N/A"}}
      .columns.is-variable(v-if="showReferralDetails?.schedule?.insuranceRequired")
        .info-pair.column.is-one-third
          .label Expiration Date
          .txt {{ getDateFromUnixFormatted(showReferralDetails?.insurance.expirationDate) || "N/A"}}
        .info-pair.column.is-one-third
          .label Group Number
          .txt {{ showReferralDetails?.insurance.groupNumber || "N/A"}}
        .info-pair.column.is-one-third
          .label Upload Insurance Card
          .flex.flex--flex-start
            FileViewer(
              :fileId="showReferralDetails?.insuranceCard.id"
            )
    .card
      .columns
        .info-pair.column.is-one-third
          .label Referral Info
          .txt.cursorPointer 
            base-icon(name="eye-green", @click="openReferralInfoOverlay")
        .info-pair.column.is-one-third
          .label Referral Report
          .txt
            FileViewer(:fileId="showReferral?.idCommunityReport")

    .card.card--blue-bg(v-if=" tabType != 'requested' ")
      .columns
        .form-group.info-pair.column.is-one-third(:class="{ 'form-group__invalid': v$.visitCompleted.$errors.length }")
          .label Visit Completed (Required)
          select(:disabled="isNoShowOrArchived" v-model="visitCompleted" @blur="v$.visitCompleted.$touch()")
              option(value="") Select
              option(value="COMPLETED") Yes
              option(value="NO_SHOW") No Show
              option(value="CANCELED") Cancelled
          .form-group__errors(v-if="v$.visitCompleted.$dirty && v$.visitCompleted.$errors.length")
            .form-group__error Field is required!
        .info-pair.column.is-one-third
          .label Clinic Report
          .txt
            FileViewer(:fileId="showReferral?.idClinicReport" v-if="isNoShowOrArchived")
            UploadAndPreviewFile(
              :buttonStyleClasses="['btn', 'lg', 'blue']"
              :storageKey="clinicReportStorageKey"
              :src="clinicReportSrc"
              v-if="!isNoShowOrArchived"
              @fileLoaded="forceReRender"
            )
      .columns
        .info-pair.column.is-full
          .label Clinic Notes (Optional)
          textarea(style="height: 250px;", :disabled="isNoShowOrArchived" v-model="clinicNotes")

        
  template(#footer v-if=" tabTypeProcessed == 'confirmed' || tabTypeProcessed == 'requested' ")
    BaseBtn.btn.lg.green-outline(
      v-if=" tabTypeProcessed == 'confirmed' "
      @click.prevent="prepareCompleteAppointmentPopup('CONFIRMED')"
      :class="{spinner: isSubmitting }"
    ) Save and exit
    BaseBtn.btn.lg.green(
      v-if=" tabTypeProcessed == 'confirmed' "
      @click.prevent="openCompleteAppointmentPopup()" 
      :disabled="v$.$invalid || isSubmitting"
      :class="{spinner: isSubmitting }"
    ) Complete this Referral
    BaseBtn.btn.lg.green.request_tab_confirm(
      v-if=" tabTypeProcessed == 'requested' "
      @click.prevent="confirmReferral()" 
      :disabled="v$.$invalid || isSubmitting"
      :class="{spinner: isSubmitting }"
    )  CONFIRM THIS REFERRAL


PopupCardSlot(
    :isOpen="isCompleteAppointmentPopupOpen",
    maxWidth="675px"
  )
    .complete-appointment-popup
      h1 Are you sure you want to complete this appointment?
      p Please confirm to continue
      .popup-btns
        BaseBtn.x-lg.red-outline(@click.prevent="closeCompleteAppointmentPopup") CANCEL
        BaseBtn.x-lg.green(@click.prevent="prepareCompleteAppointmentPopup('ARCHIVES')") YES, COMPLETE

ReferralInfoOverlay(
  :isOpen="isReferralInfoOverlayOpen",
  overlayTitle="REFERRAL INFO",
  :showAppointment="showReferral"
  :showAppointmentDetails="showReferralDetails"
  :patientInfoQuestions="patientInfoQuestions"
  @closeReferralInfoOverlay="closeReferralInfoOverlay"
)
CancelAppointmentOverlay(
  :isOpen="isCancelAppointmentOverlayOpen",
  :patientAppointment="showReferralDetails"
  overlayTitle="ARE YOU SURE YOU WANT TO CANCEL THIS APPOINTMENT?",
  @closeCancelAppointmentOverlay="closeCancelAppointmentOverlay"
  @closeCancelWithAppointmentOverlay="closeCancelWithAppointmentOverlay"
)
ReferralNotesOverlay(
  :hasNoNotes="hasNoNotes",
  :key="`${componentKey}-1`",
  :isOpen="isReferralNotesOverlayOpen",
  @closeReferralNotesOverlay="closeReferralNotesOverlay"
  @fetchNotifications="fetchNotifications"
  :patientAppointment="showReferral"
)
</template>

<script>
import { defineComponent } from "vue";
import FullScreenOverlaySlot from "@/components/overlays/FullScreenOverlaySlot.vue";
import PopupCardSlot from "@/components/PopupCardSlot.vue";
import ReferralInfoOverlay from "@/components/primary-care-community-organization/patient-referrals/overlays/ReferralInfoOverlay.vue";
import CancelAppointmentOverlay from "@/components/primary-care-community-organization/patient-referrals/overlays/CancelReferralOverlay.vue";
import FileViewer from "@/components/files/FileViewer.vue";
import UploadAndPreviewFile from "@/components/UploadAndPreviewFile";
import ReferralNotesOverlay from "@/components/overlays/side-overlays/ReferralNotesOverlay.vue";
import listPagesMixin from "@/mixins/listPagesMixin";

import {
  getDateFromUnixFormatted,
  getTimeFromUnixFormatted,
  getDobFormattedforDateField,
  getRelativeTimeFromUnixFormatted,
  getDoctorFormatted,
  getPhoneFormatted,
  getFileSignedUrl,
} from "@/helpers";

import { fetchNotification } from "@/api/notifications.ts";
import { fetchPatientReferralById } from "@/api/patientReferral";

import { patchPatientReferralById } from "@/api/patientReferral";

import { apiStatus } from "@/api";
import { fetchFileById, uploadFile } from "@/api/filesApi";
const { IDLE, PENDING, SUCCESS, ERROR } = apiStatus;
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { fetchPrimaryCareModule } from "@/api/primaryCareModule";

export default defineComponent({
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    let args = {
      clinicMRN: {},
      visitCompleted: {},
    };
    if (this.tabType == "requested") {
      args.clinicMRN = { required };
    }
    if (this.tabType == "confirmed") {
      args.visitCompleted = { required };
    }
    return args;
  },
  mixins: [listPagesMixin],
  components: {
    FullScreenOverlaySlot,
    PopupCardSlot,
    ReferralInfoOverlay,
    CancelAppointmentOverlay,
    FileViewer,
    UploadAndPreviewFile,
    ReferralNotesOverlay,
  },
  emits: ["closeReferralRecordOverlay", "openAppointmentNotesOverlay"],

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    tabType: {
      type: String,
      default: "",
    },
    showReferral: {
      type: Object,
    },
  },

  data() {
    return {
      careTitle: "",
      careIcon: "",
      patientInfoQuestions: {},

      hasNoNotes: true,
      isSubmitting: false,
      isReferralNotesOverlayOpen: false,

      getDateFromUnixFormatted: getDateFromUnixFormatted,
      getTimeFromUnixFormatted: getTimeFromUnixFormatted,
      getDobFormattedforDateField: getDobFormattedforDateField,
      getRelativeTimeFromUnixFormatted: getRelativeTimeFromUnixFormatted,
      getDoctorFormatted,
      getPhoneFormatted,
      tabTypeProcessed: "",
      clinicMRN: this.showReferral?.patient?.mrn || "",
      visitCompleted: "",
      clinicNotes: "",

      fetchPatientReferralByIdStatus: IDLE,
      patchPatientReferralByIdStatus: IDLE,
      fetchNotificationStatus: IDLE,
      showReferralDetails: null,
      clinicReportStorageKey: "referral_record_clinic_report",
      clinicReportSrc: null,
      isCompleteAppointmentPopupOpen: false,
      isReferralInfoOverlayOpen: false,
      isCancelAppointmentOverlayOpen: false,

      noteSummary: {
        total: 0,
        new: 0,
      },
      forcedReRenderTimes: 0,
    };
  },
  computed: {
    isNoShowOrArchived() {
      return this.tabType == "noshowcancelled" || this.tabType == "archived";
    },
  },
  methods: {
    forceReRender() {
      this.forcedReRenderTimes++;
    },
    async fetchPrimaryCareModule() {
      try {
        const res = await fetchPrimaryCareModule();
        this.patientInfoQuestions = res.data.primaryCareModule?.schemaRules?.patientInfoQuestions;
        this.careIcon = res.data.primaryCareModule?.icon;
        this.careTitle = res.data.primaryCareModule?.marketingTitle + ": "+res.data.primaryCareModule?.moduleTitle;
      } catch (err) {
        console.error(err);
      }
    },
    closeReferralNotesOverlay() {
      this.fetchNotifications();
      this.isReferralNotesOverlayOpen = false;
    },
    openReferralNotesOverlay() {
      this.componentKey++;
      this.isReferralNotesOverlayOpen = true;
    },
    async fetchNotifications() {
      this.fetchNotificationStatus = PENDING;
      try {
        const res = await fetchNotification({
          params: {
            idEntity: this.showReferral.id,
            entityType: "referral",
          },
        });
        let newNotifications = res.data.notifications?.filter(
          (notification) => {
            return notification.status != "Completed";
          }
        );
        this.noteSummary.new = newNotifications?.length || 0;
        this.noteSummary.total = res.data.pagination?.quantity || 0;
        if (this.noteSummary.total > 0) {
          this.hasNoNotes = false;
        }
        this.fetchNotificationStatus = SUCCESS;
      } catch (err) {
        this.fetchNotificationStatus = ERROR;
        console.error(err);
      }
    },
    async prepareCompleteAppointmentPopup(setStage) {
      this.patchPatientReferralByIdStatus = PENDING;
      await this.uploadClinicReport();

      let args = {
        visitStatus: this.visitCompleted,
      };
      if (setStage == "CONFIRMED" && this.visitCompleted == "") {
        args = {};
      }
      if (setStage == "CONFIRMED" && this.visitCompleted != "") {
        args.status = "Completed";
      }
      try {
        this.isSubmitting = true;

        await patchPatientReferralById(this.showReferral.id, {
          id: this.showReferral.id,
          idPatient: this.showReferral.idPatient,
          idClinic: this.showReferral.idClinic,
          idClinicReport: this.idClinicReport,
          stage: setStage,
          clinicNotes: this.clinicNotes,
          ...args,
        });
        this.patchPatientReferralByIdStatus = SUCCESS;
        this.closeOverlay();
        this.closeCompleteAppointmentPopup();
      } catch (err) {
        this.patchPatientReferralByIdStatus = ERROR;
        console.error(err);
      } finally {
        this.isSubmitting = false;
      }
    },
    async confirmReferral() {
      await this.uploadClinicReport();
      this.patchPatientReferralByIdStatus = PENDING;
      try {
        this.isSubmitting = true;
        await patchPatientReferralById(this.showReferral.id, {
          id: this.showReferral.id,
          clinicMrn: this.clinicMRN,
          idPatient: this.showReferral.idPatient,
          idClinic: this.showReferral.idClinic,
          idClinicReport: this.idClinicReport,
          stage: "CONFIRMED",
        });
        this.patchPatientReferralByIdStatus = SUCCESS;
        this.closeOverlay();
      } catch (err) {
        this.patchPatientReferralByIdStatus = ERROR;
        console.error(err);
      } finally {
        this.isSubmitting = false;
      }
    },
    closeCancelWithAppointmentOverlay() {
      this.fetchNotifications();
      this.isCancelAppointmentOverlayOpen = false;
      this.closeOverlay();
    },
    closeOverlay(shouldRefresh = true) {
      this.$emit("closeReferralRecordOverlay", shouldRefresh);
    },
    openAppointmentNotesOverlay() {
      this.isAppointmentNotesOverlayOpen = true;
    },
    openCompleteAppointmentPopup() {
      this.isCompleteAppointmentPopupOpen = true;
    },
    closeCompleteAppointmentPopup() {
      this.isCompleteAppointmentPopupOpen = false;
    },
    openReferralInfoOverlay() {
      this.isReferralInfoOverlayOpen = true;
    },
    closeReferralInfoOverlay() {
      this.isReferralInfoOverlayOpen = false;
    },
    openCancelAppointmentOverlay() {
      this.isCancelAppointmentOverlayOpen = true;
    },
    closeCancelAppointmentOverlay() {
      this.isCancelAppointmentOverlayOpen = false;
    },
    async fetchPatientReferralById() {
      this.fetchPatientReferralByIdStatus = PENDING;
      try {
        const res = await fetchPatientReferralById(this.showReferral.id, {
          params: {
            include: [
              "communityCare",
              "clinicDoctor",
              "communityReport",
              "cancelled",
              "clinic",
              "clinicReport",
              "emergencyContact",
              "insurance",
              "patient",
              "referringDoctor",
            ],
          },
        });
        this.showReferralDetails = res.data.patientReferral;
        this.checkFetchFiles();
        this.fetchPatientReferralByIdStatus = SUCCESS;
      } catch (err) {
        this.fetchPatientReferralByIdStatus = ERROR;
        console.error(err);
      }
    },
    async fetchFileSrc(id) {
      if (!id) {
        return null;
      }

      try {
        const resFile = await fetchFileById(id);
        const file = resFile.data.file;
        file.token = resFile.data.token;
        return getFileSignedUrl(file);
      } catch (err) {
        console.error(err);
      }
    },
    async checkFetchFiles() {
      this.clinicReportSrc = await this.fetchFileSrc(
        this.showReferral?.idClinicReport
      );
    },
    async uploadClinicReport() {
      const clinicReport = sessionStorage.getItem(this.clinicReportStorageKey);
      if (clinicReport) {
        try {
          const { file } = await uploadFile(this.clinicReportStorageKey);
          this.idClinicReport = file.id;
        } catch (error) {
          console.error(error);
        }
      }
      sessionStorage.removeItem(this.clinicReportStorageKey);
    },
  },
  created() {
    if (this.showReferral === null || this.showReferral === undefined) return;
    this.fetchPatientReferralById();
    this.fetchPrimaryCareModule();
    this.fetchNotifications();
    this.visitCompleted = this.showReferral.visitStatus || "";
    this.clinicNotes = this.showReferral.clinicNotes || "";
    this.tabTypeProcessed = this.tabType;
    if (this.tabType == "displayOnly") {
      let convertType = "";
      switch (this.showReferral.stage) {
        case "REQUESTED":
          convertType = "requested";
          break;
        case "CONFIRMED":
          convertType = "confirmed";
          break;
        case "ARCHIVES":
          convertType = "archived";
          break;
      }
      this.tabTypeProcessed = convertType;
    }
  },
});
</script>

<style scoped lang="scss">
.cursorPointer svg {
  cursor: pointer;
}
.adjust-margin-top {
  margin-top: -8px;
}
.select-m-bot {
  margin-bottom: 25px;
}
.request_tab_confirm {
  margin-right: 0px;
  margin-left: auto;
}
.card {
  padding: 35px $secondaryCardsSidePadding 68px;
}
.columns.is-variable {
  --columnGap: 1.575%;
}
.columns + .hr {
  background-color: $primaryBgDarkGrey;
  margin-top: -21px;
  margin-bottom: 24px;
}
.card > .columns:last-child.info-pair,
.card > .columns:last-child .info-pair {
  margin-bottom: 0;
}
.svg-icon--eye-red,
.svg-icon--eye-green,
.svg-icon--yes,
.svg-icon--no {
  width: 50px;
  height: 50px;
  top: 0;
}

// Page specific styles----------------------------------------
.insurance-status {
  margin-bottom: 21px;
  p {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.29;
    letter-spacing: 0.07px;
    color: #000;
  }
}
.rescheduled {
  .label {
    & > span {
      position: relative;
    }
    .svg-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: calc(100% + 20px);
    }
  }
}
.breadcrumbs-title {
  margin: 0;
  color: $primaryLightBlue;
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.12px;
}

.complete-appointment-popup {
  max-width: 555px;
  margin: 0 auto;
  h1 {
    width: 100%;
    max-width: 549px;
    margin: 39px auto 30px;
    font-size: 28px;
    font-weight: 700;
    line-height: 1.21;
    letter-spacing: 0.14px;
    text-align: center;
  }
  p {
    margin-top: 0;
    margin-bottom: 32px;
    font-size: 16px;
    line-height: 1.19;
    letter-spacing: 0.23px;
    text-align: center;
  }
  .popup-btns {
    margin-bottom: 39px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .btn {
      width: 260px;
    }
  }
}
</style>
