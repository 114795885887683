<template lang="pug">
SideOverlaySlot(
  :isOpen="isOpen",
  :title="overlayTitle",
  @closeOverlay="closeOverlay"
)
  template(#contentTop)
    form#manage-user(
      v-if="!showUserManaged",
      @submit.prevent="saveAndcloseOverlay"
      novalidate
    )  
      //- pre {{ v$.$silentErrors }}
      .form-group(:class="{ 'form-group__invalid': v$.providerInfo.idUserRole.$errors.length }")
        label(for="user-type") User Type (Required)
        select#user-type(v-model="providerInfo.idUserRole" @blur="v$.providerInfo.idUserRole.$touch()" :disabled='overlayTitle == "ADD NEW USER" ? false : true')
          option(value=11) Provider Group Manager
          option(value=12) Provider
        .form-group__errors(v-if="v$.providerInfo.idUserRole.$dirty && v$.providerInfo.idUserRole.$errors.length")
          .form-group__error(v-if="v$.providerInfo.idUserRole.required.$invalid") User role is required!
      .form-group(v-if=" providerInfo.idUserRole == 12 " :class="{ 'form-group__invalid': v$.providerInfo.referral_module_id.$errors.length }")
        label(for="user-type") Referral Type (Required)
        select#user-type(
          v-model="providerInfo.referral_module_id" 
          @blur="v$.providerInfo.referral_module_id.$touch()" 
        )
          option(value="") Select Referral Type
          option(
              v-for="(item, index) in referralModules",
              :key= "index",
              :value="item.id",
              :selected="item.id === providerInfo.referral_module_id"
            ) {{ item.moduleTitle }}
        .form-group__errors(v-if="v$.providerInfo.referral_module_id.$dirty && v$.providerInfo.referral_module_id.$errors.length")
          .form-group__error(v-if="v$.providerInfo.referral_module_id.required.$invalid") Referral type is required!
    
      .columns
        .column.is-half
          .form-group(:class="{ 'form-group__invalid': v$.providerInfo.first.$errors.length }")
            label(for="first-name") First Name (Required)
            input#first-name(type="text" v-model="providerInfo.first"  @blur="v$.providerInfo.first.$touch()")
            .form-group__errors(v-if="v$.providerInfo.first.$dirty && v$.providerInfo.first.$errors.length")
              .form-group__error(v-if="v$.providerInfo.first.required.$invalid") First name is required!
        .column.is-half
          .form-group(:class="{ 'form-group__invalid': v$.providerInfo.last.$errors.length }")
            label(for="last-name") Last Name (Required)
            input#last-name(type="text" v-model="providerInfo.last" @blur="v$.providerInfo.last.$touch()")
            .form-group__errors(v-if="v$.providerInfo.last.$dirty && v$.providerInfo.last.$errors.length")
              .form-group__error(v-if="v$.providerInfo.last.required.$invalid") Last name is required!
      .form-group(:class="{ 'form-group__invalid': v$.providerInfo.password.$errors.length }" v-if=" overlayTitle=='ADD NEW USER' ")
        label(for="password") Password (Required)
        input#password(type="password"  v-model="providerInfo.password" autocomplete="current-password"  @blur="v$.providerInfo.password.$touch()")
        .form-group__errors(v-if="v$.providerInfo.password.$dirty && v$.providerInfo.password.$errors.length")
          .form-group__error(v-if="v$.providerInfo.password.required.$invalid") Password is required!
      .form-group(:class="{ 'form-group__invalid': v$.providerInfo.email.$errors.length }")
        label(for="email") Email (Required)
        input#email(type="email" v-model="providerInfo.email"  @blur="v$.providerInfo.email.$touch()")
        .form-group__errors(v-if="v$.providerInfo.email.$dirty && v$.providerInfo.email.$errors.length")
          .form-group__error(v-if="v$.providerInfo.email.email.$invalid") Invalid email format
          .form-group__error(v-if="v$.providerInfo.email.required.$invalid") User email is required!
      .form-group(v-if="providerInfo.idUserRole == 12" v-for="(question, index) in providerDynamicQuestions" :key="index" :class="question.halfWidth ? 'is-half' : ''")
        label(:for="question.question") {{ question.question }}
          span(v-if="question.required") (Required)
        .a(v-if="question.type == 'TEXT' ")
          input(
            type="text"
            v-model="question.value"
          )
          .form-group__errors(v-if="question.value == '' && question.required")
            .form-group__error Field is required!

        .a.flex-end(v-if="question.type == 'DROPDOWN' ")
          select(
            v-model="question.value"
          )
            option(
              v-for="option in question.options" 
              :key="option.name" 
              :value="option.name") {{ option.name }}
          .form-group__errors(v-if="question.value == '' && question.required ")
            .form-group__error Field is required!

        .a.flex-end(v-if="question.type == 'TEXTAREA' ")
            textarea#details(style="height:250px;" v-model="question.value")
            .form-group__errors(v-if="question.value == '' && question.required")
              .form-group__error Field is required!
          

      .form-group
        label(for="details") Details
        textarea#details(style="height:250px;" v-model="providerInfo.details")

    .overlay-show-success(v-else="")
      p User successfully added!
  template(#footer)
    .flex.flex--space-between(v-if="!showUserManaged")
      BaseBtn.x-lg.red-outline(
        href="#",
        @click.prevent="closeOverlay"
      ) CANCEL
      BaseBtn.x-lg.green(
        type="submit",
        form="manage-user"
        :disabled="v$.$invalid || isSubmitting || dynamicRequiredFields"
        :class="{spinner: isSubmitting }"
      ) CONTINUE
    .flex.flex--space-between(v-else="")
      BaseBtn.x-lg.blue-outline(
        :to={name: 'fit-kit-status.all-outstanding'}
      ) FIT KIT STATUS
      BaseBtn.x-lg.blue(
        :to={name: 'new-fit-kit'}
      ) ADD NEW FIT
</template>

<script>
import { defineComponent } from "vue";
import SideOverlaySlot from "@/components/overlays/SideOverlaySlot.vue";

import { register } from "@/api/accountApi";
import { updateUser } from "@/api/userApi";
import { apiStatus } from "@/api";
const { IDLE, PENDING, SUCCESS, ERROR } = apiStatus;
import { createToast } from "mosha-vue-toastify";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import {
  getProviderGroupProvider,
  getProviderGroupReferral,
} from "@/api/providerGroupApi";

import inputValidateText from "@/components/clinics/scheduling/inputValidate/inputValidateText.vue";
import inputValidateDropdown from "@/components/clinics/scheduling/inputValidate/inputValidateDropdown.vue";

export default defineComponent({
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    let args = {
      providerInfo: {
        idUserRole: { required },
        referral_module_id: { required },
        first: { required },
        last: { required },
        password: { required },
        email: { required, email },
      },
      providerDynamicQuestions: [],
    };

    if (this.overlayTitle != "ADD NEW USER") {
      args.providerInfo.password.required = false;
    }

    return args;
  },
  components: {
    SideOverlaySlot,
    inputValidateDropdown,
    inputValidateText,
  },
  emits: ["closeManageUsersProvidersOverlay"],

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    overlayTitle: {
      type: String,
      required: true,
    },
    showProvider: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      showUserManaged: false,
      addProviderStatus: IDLE,
      updateUserStatus: IDLE,
      isUpdateServerError: false,
      isSubmitting: false,
      getProviderGroupReferralStatus: IDLE,
      referralModules: null,
      showDynamicErrors: false,
      questionsAnsweredLoad: [],
      providerInfo: {
        ...this.showProvider,
        referral_module_id: 0,
        referral_module_name: "",
      },
      providerDynamicQuestions: [
        {
          id: 14,
          question: "Location(s) Address and Phone",
          required: false,
          type: "TEXTAREA",
          value: "",
          halfWidth: false,
        },
        {
          id: 1,
          question: "Provider title",
          required: false,
          type: "TEXT",
          value: "",
          halfWidth: false,
        },
        {
          id: 8,
          question: "Provides Tele-health Appointments",
          required: false,
          type: "DROPDOWN",
          value: "",
          halfWidth: false,
          options: [
            { id: 0, name: "Yes" },
            { id: 1, name: "No" },
          ],
        },
        {
          id: 12,
          question: "Provides In-person Appointments",
          required: false,
          type: "DROPDOWN",
          value: "",
          halfWidth: false,
          options: [
            { id: 0, name: "Yes" },
            { id: 1, name: "No" },
          ],
        },
        {
          id: 2,
          question: "Specialties",
          required: false,
          type: "TEXT",
          value: "",
          halfWidth: false,
        },
        {
          id: 9,
          question: "Business Hours",
          required: false,
          type: "TEXTAREA",
          value: "",
          halfWidth: false,
        },
        {
          id: 3,
          question: "Languages Spoken",
          required: false,
          type: "TEXT",
          value: "",
          halfWidth: false,
        },
        {
          id: 7,
          question: "Average Wait Times",
          required: false,
          type: "TEXT",
          value: "",
          halfWidth: false,
        },
        {
          id: 10,
          question: "Gender",
          required: false,
          type: "TEXT",
          value: "",
          halfWidth: false,
        },
        {
          id: 5,
          question: "Sliding Scale",
          required: false,
          type: "DROPDOWN",
          value: "",
          halfWidth: true,
          options: [
            { id: 1, name: "Yes" },
            { id: 2, name: "No" },
          ],
        },
        {
          id: 6,
          question: "Accept Medicaid",
          required: false,
          type: "DROPDOWN",
          value: "",
          halfWidth: false,
          options: [
            { id: 1, name: "Yes" },
            { id: 2, name: "No" },
          ],
        },
        {
          id: 15,
          question: "Accept Insurance",
          required: false,
          type: "DROPDOWN",
          value: "",
          halfWidth: false,
          options: [
            { id: 1, name: "Yes" },
            { id: 2, name: "No" },
          ],
        },
        {
          id: 11,
          question: "Insurance List",
          required: false,
          type: "TEXTAREA",
          value: "",
          halfWidth: false,
        },
        {
          id: 13,
          question: "Geographical Coverage Area",
          required: false,
          type: "TEXTAREA",
          value: "",
          halfWidth: false,
        },
      ],
    };
  },
  computed: {
    dynamicRequiredFields() {
      if (this.providerInfo.idUserRole == 11) {
        return false;
      }
      return this.providerDynamicQuestions.some((question) => {
        return question.required && question.value === "";
      });
    },
  },

  methods: {
    async fetchProviderGroupProvider() {
      try {
        const res = await getProviderGroupProvider(this.providerInfo.id);
        this.providerGroupProvider = res.data.provider[0];
        this.providerInfo.referral_module_id =
          this.providerGroupProvider.referral_module_id;
        this.providerInfo.referral_module_name =
          this.providerGroupProvider.referral_module_name;

        if (this.providerGroupProvider.user_meta) {
          this.providerDynamicQuestions.forEach((item) => {
            // match the user_meta id with the question id
            const matchedQuestion = this.providerGroupProvider.user_meta.find(
              (question) => question.id == item.id
            );
            if (matchedQuestion) {
              item.value = matchedQuestion.value;
            }
          });
        }
      } catch (error) {
        console.error(error);
      }
    },

    async getProviderGroupReferral() {
      this.getProviderGroupReferralStatus = PENDING;
      try {
        const res = await getProviderGroupReferral(
          this.$route.params.idProviderGroup
        );
        const referralModulesRaw = res.data.providerGroupReferrals;
        this.referralModules = referralModulesRaw.map((item) => {
          return item.referralModule;
        });

        this.getProviderGroupReferralStatus = SUCCESS;
      } catch (err) {
        this.getProviderGroupReferralStatus = ERROR;
      }
    },
    saveAndcloseOverlay() {
      if (this.overlayTitle == "ADD NEW USER") {
        this.register().then(() => {
          this.$emit("closeManageUsersProvidersOverlay");
        });
      } else {
        this.updateUser().then(() => {
          if (!this.isUpdateServerError) {
            this.$emit("closeManageUsersProvidersOverlay");
          } else {
            this.isUpdateServerError = false;
          }
        });
      }
    },
    async updateUser() {
      this.isSubmitting = true;
      this.updateUserStatus = PENDING;
      let userID = this.providerInfo.id;
      let params = {
        idProviderGroup: this.$route.params.idProviderGroup,
      };

      const answersRaw = this.providerDynamicQuestions.map((question) => {
        return {
          id: question.id,
          value: question.value,
          question: question.question,
        };
      });
      const questionsAnswered = answersRaw.filter(
        (answer) => answer.value !== ""
      );

      if (this.providerInfo.idUserRole == 12) {
        params.providerQuestions = questionsAnswered;
        params.idReferralModule = this.providerInfo.referral_module_id;
      }

      try {
        await updateUser(userID, {
          ...params,
          user: {
            details: this.providerInfo.details,
            email: this.providerInfo.email,
            first: this.providerInfo.first,
            idUserRole: parseInt(this.providerInfo.idUserRole),
            last: this.providerInfo.last,
          },
        });
        this.updateUserStatus = SUCCESS;
      } catch (err) {
        this.updateUserStatus = ERROR;
        createToast(err?.response?.data?.message || "There was a problem", {
          timeout: 4000,
          type: "danger",
          position: "bottom-right",
        });
        this.isUpdateServerError = true;
        console.error(err);
      } finally {
        this.isSubmitting = false;
      }
    },
    async register() {
      this.isSubmitting = true;
      this.addProviderStatus = PENDING;
      let params = {
        idProviderGroup: this.$route.params.idProviderGroup,
      };

      const answersRaw = this.providerDynamicQuestions.map((question) => {
        return {
          id: question.id,
          value: question.value,
          question: question.question,
        };
      });
      const questionsAnswered = answersRaw.filter(
        (answer) => answer.value !== ""
      );

      if (this.providerInfo.idUserRole == 12) {
        params.providerQuestions = questionsAnswered;
        params.idReferralModule = this.providerInfo.referral_module_id;
      }

      try {
        await register({
          ...params,
          user: {
            createdAt: null,
            details: this.providerInfo.details,
            email: this.providerInfo.email,
            first: this.providerInfo.first,
            idUserRole: parseInt(this.providerInfo.idUserRole),
            last: this.providerInfo.last,
            password: this.providerInfo.password,
            signInCount: 0,
            status: null,
            updatedAt: null,
          },
        });

        this.addProviderStatus = SUCCESS;
      } catch (err) {
        this.addProviderStatus = ERROR;
        createToast(err?.response?.data?.message || "There was a problem", {
          timeout: 4000,
          type: "danger",
          position: "bottom-right",
        });
        console.error(err);
      } finally {
        this.isSubmitting = false;
      }
    },
    closeOverlay() {
      this.$emit("closeManageUsersProvidersOverlay");
    },
  },
  created() {
    this.getProviderGroupReferral().then(() => {
      if (this.providerInfo.id) {
        this.fetchProviderGroupProvider();
      }
    });
  },
});
</script>

<style scoped lang="scss">
.patient-section {
  margin-bottom: 0;
  padding: 37px 24px 25px;
  background-color: $primaryBgBlue;
  p {
    margin: 0 0 12px;
    font-size: 21px;
    font-weight: 500;
    line-height: 1.1;
    color: $primaryLightBlue;
    overflow: hidden;
    strong {
      font-weight: 700;
    }
  }
}
form#manage-user {
  margin: 12px 0 20px;
  .columns {
    margin-bottom: 0px;
    .column {
      padding-bottom: 0px;
    }
  }
}
.overlay-show-success {
  p {
    margin: 41px 0 60px;
  }
}
.questions_wrap {
  display: flex;
  flex-direction: column;
}
</style>
