// @ts-nocheck
import api from "./api";
const URLS = {
  PATIENT_REFERRAL: "/patient-referral",
  REPORT_PATIENT_REFERRAL: "/report/patient-referral",
};

export const getClinicSchedules = (data) => {
  return api.get(URLS.PATIENT_REFERRAL + "/available-schedule", data);
};

export const fetchPatientReferrals = (data) => {
  return api.get(URLS.PATIENT_REFERRAL, data);
};

export const deleteClinicSchedule = (idSchedule, data = {}) => {
  return api.delete(
    URLS.PATIENT_REFERRAL + "/available-schedule/" + idSchedule,
    data
  );
};

export const createPatientReferral = (data = {}) => {
  return api.post(URLS.PATIENT_REFERRAL, data);
};

export const fetchPatientReferralById = (id, data) => {
  return api.get(URLS.PATIENT_REFERRAL + "/" + id, data);
};
export const patchPatientReferralById = (id, data) => {
  return api.patch(URLS.PATIENT_REFERRAL + "/" + id, data);
};

export const createPatientReferralNote = (data = {}) => {
  return api.post(URLS.PATIENT_REFERRAL + "/notes", data);
};

export const getOverallPrimaryCareReport = (params: {
  timezone: string;
  idClinic?: number;
  idClinicHealthSystem?: number;
  idCommunity?: number;
  startDate?: number;
  endDate?: number;
}): Promise<any> => {
  Object.keys(params).forEach(
    (key) => params[key] === undefined && delete params[key]
  );
  return api.get(`${URLS.REPORT_PATIENT_REFERRAL}/overall`, { params });
};

export const getFullPrimaryCareReport = (params: {
  idClinic?: number;
  idClinicHealthSystem?: number;
  idCommunity?: number;
  startDate?: number;
  endDate?: number;
}): Promise<any> => {
  Object.keys(params).forEach(
    (key) => params[key] === undefined && delete params[key]
  );
  return api.get(`${URLS.REPORT_PATIENT_REFERRAL}/full`, { params });
};
