<template lang="pug">
.content-wrapper
  FITHeader(:idClinic="+idClinic")
  section.fit-referrals.card
    TableTitle(
      title="Referrals",
      searchPlaceholder="Search..."
      v-model="searchKeyword",
      @doSearch="fetchReferralFitKits",
      @blur="fetchReferralFitKits"
    )
      template(#actions)
        DateSelector(@filterByDate="filterByDate")
    .table
      .th
        .td(@click.prevent="changeListOrder('patientName', fetchReferralFitKits)" style="cursor: pointer;") Patient Name
          i(style="font-weight: bolder;") {{ patientNameColumnSortingIcon }}
        .td(@click.prevent="changeListOrder('kitNumber', fetchReferralFitKits)" style="cursor: pointer;") KIT #
          i(style="font-weight: bolder;") {{ kitNumberColumnSortingIcon }}
        .td(@click.prevent="changeListOrder('referralDate', fetchReferralFitKits)" style="cursor: pointer;") Date of referral
          i(style="font-weight: bolder;") {{ referralDateColumnSortingIcon }}
        .td(@click.prevent="changeListOrder('providerName', fetchReferralFitKits)" style="cursor: pointer;") PROVIDER NAME
          i(style="font-weight: bolder;") {{ providerNameColumnSortingIcon }}
        .td(@click.prevent="changeListOrder('clinicReferredTo', fetchReferralFitKits)" style="cursor: pointer;") CLINIC REFERRED TO
          i(style="font-weight: bolder;") {{ clinicReferredToColumnSortingIcon }}
        .td 
      .tr(v-for="fitKit in referralFitKits" :key="fitKit.id")
        .td.bold {{`${fitKit.patient.first} ${fitKit.patient.last}`}} 
        .td {{fitKit.kitNumber}}
        .td
          div {{getDateFromUnixFormatted(fitKit.referral.referralAt)}}
          .second-row-md-grey {{getTimeFromUnixFormatted(fitKit.referral.referralAt)}}
        .td {{getDoctorFormatted(fitKit.referral.referringUser)}}
        .td {{fitKit.referral.clinicReferredTo}}
        .td
          BaseBtn.sm.blue-outlined(
            href="#",
            @click.prevent="openClinicReferralOverlay(fitKit)"
          ) DETAILS
    Pagination(
      v-if="pagination",
      @changePagination="changePagination",
      :pagination="pagination",
      :reloadFn="fetchReferralFitKits"
    )
    ClinicReferralOverlay(
      v-if="selectedFitKit"
      :isOpen="isClinicReferralOverlayOpen"
      :selectedFitKit="selectedFitKit"
      @closeClinicReferralOverlay="closeClinicReferralOverlay",
    )

</template>

<script>
import { defineComponent } from "vue";
import FITHeader from "@/components/fit-kit/FITHeader.vue";
import TableTitle from "@/components/TableTitle.vue";
import DateSelector from "@/components/DateSelector.vue";
import Pagination from "@/components/Pagination.vue";
import ClinicReferralOverlay from "@/components/fit-kit/overlays/ClinicReferralOverlay.vue";
import { fetchFitKits } from "@/api/fitKitApi";
import {
  reformatPagination,
  getDoctorFormatted,
  getDateFromUnixFormatted,
  getTimeFromUnixFormatted,
} from "@/helpers";
import listPagesMixin from "@/mixins/listPagesMixin";
import sortFitKits from "@/mixins/sortFitKits";

export default defineComponent({
  components: {
    FITHeader,
    TableTitle,
    DateSelector,
    Pagination,
    ClinicReferralOverlay,
  },

  props: {},

  mixins: [listPagesMixin, sortFitKits],

  data() {
    return {
      date: null,
      idClinic: null,
      searchKeyword: "",
      selectedFitKit: null,
      referralFitKits: null,
      isClinicReferralOverlayOpen: false,
    };
  },

  methods: {
    filterByDate(date) {
      this.date = date;
      this.fetchReferralFitKits();
    },
    openClinicReferralOverlay(referralFitKit) {
      this.selectedFitKit = referralFitKit;
      this.isClinicReferralOverlayOpen = true;
    },
    closeClinicReferralOverlay() {
      this.isClinicReferralOverlayOpen = false;
      this.selectedFitKit = null;
    },
    getDoctorFormatted: getDoctorFormatted,
    getDateFromUnixFormatted: getDateFromUnixFormatted,
    getTimeFromUnixFormatted: getTimeFromUnixFormatted,
    async fetchReferralFitKits() {
      const order = this.listOrder
        ? {
            by: this.listOrder.by,
            direction: this.listOrder.direction,
          }
        : null;
      try {
        let params = {
          order,
          ...this.reqPagination,
          idClinic: this.$route.params.idClinic,
          include: ["patient", "providerUser", "referral"],
          status: "completed",
        };
        if (this.date) {
          params.referredAt = this.date;
        }
        if (!!this.searchKeyword === true) {
          params.searchKeyword = this.searchKeyword;
        }
        if (order === null) delete params.order;
        const resFitKit = await fetchFitKits({
          params,
        });
        this.referralFitKits = resFitKit.data.fitKits;
        this.pagination = reformatPagination(resFitKit.data.pagination);
      } catch (err) {
        console.error(err);
      }
    },
  },
  created() {
    if (this.$route.params.idClinic === null) return;
    this.idClinic = this.$route.params.idClinic;
    this.fetchReferralFitKits();
  },
});
</script>

<style scoped lang="scss">
.fit-referrals.card {
  padding-bottom: 44px;
}
.td {
  &:nth-child(1) {
    width: math.percentage(math.div(244, $tabContentCardWidth));
  }
  &:nth-child(2) {
    width: math.percentage(math.div(145, $tabContentCardWidth));
  }
  &:nth-child(3) {
    width: math.percentage(math.div(180, $tabContentCardWidth));
  }
  &:nth-child(4) {
    width: math.percentage(math.div(206, $tabContentCardWidth));
  }
  &:nth-child(5) {
    width: math.percentage(math.div(206, $tabContentCardWidth));
  }
  &:nth-child(6) {
    width: math.percentage(math.div(197, $tabContentCardWidth));
  }
}
</style>
