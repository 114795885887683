<template lang="pug">
.confirmation-info(v-if="PatientReferralID")
  .columns
    .column.is-one-third
      .info-pair
        .label Patient Name
        .txt(v-if="patient") {{ getUserFormatted(patient) }}
    .column.is-one-third
      .info-pair
        .label Referral Date
        .txt(v-if="providerGroupProvider")  {{ getDateFromUnixFormatted(showReferral.createdAt) }} 
    .column.is-one-third
      .info-pair
        .label Referral Time
        .txt(v-if="providerGroupProvider")  {{ getTimeFromUnixFormatted(showReferral.createdAt) }} 
  .columns
    .column
      .info-pair
        .label Provider
        .txt(v-if="providerGroupProvider")  {{ getUserFormatted(providerGroupProvider) }}
.confirmation-info(v-else)
  .columns
    .column.is-one-second
      .info-pair
        .label Patient Name
        .txt(v-if="patient") {{ getUserFormatted(patient) }}
    .column.is-one-half
      .info-pair
        .label Provider
        .txt(v-if="providerGroupProvider")  {{ getUserFormatted(providerGroupProvider) }}
</template>

<script>
import { defineComponent } from "vue";
import {
  getDateFromUnixFormatted,
  getTimeFromUnixFormatted,
  getUserFormatted,
  getPhoneFormatted,
} from "@/helpers";
import { fetchPatient } from "@/api/patientApi";
import { fetchProviderPatientReferralById } from "@/api/providerPatientReferral";

import { getProviderGroupProvider } from "@/api/providerGroupApi";

import { apiStatus, apiStatusComputedFactory } from "@/api";
const { IDLE, PENDING, SUCCESS, ERROR } = apiStatus;

export default defineComponent({
  components: {},
  props: {
    PatientReferralID: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      fetchPatientStatus: IDLE,
      fetchPatientAppointmentAvailableScheduleByIdStatus: IDLE,
      fetchCliniclStatus: IDLE,
      patient: null,
      providerGroupProvider: null,
      showReferral: null,
    };
  },

  computed: {
    ...apiStatusComputedFactory(
      "fetchPatientStatus",
      "fetchPatientAppointmentAvailableScheduleByIdStatus",
      "fetchClinicStatus"
    ),
  },

  methods: {
    getDateFromUnixFormatted,
    getTimeFromUnixFormatted,
    getUserFormatted,
    getPhoneFormatted,
    async fetchPatient() {
      this.fetchPatientStatus = PENDING;
      try {
        const res = await fetchPatient(this.$route.params.idPatient);
        this.patient = res.data.patient;
        this.fetchPatientStatus = SUCCESS;
      } catch (err) {
        this.fetchPatientStatus = ERROR;
        console.error(err);
      }
    },
    async fetchProviderPatientReferralById(referralID) {
      this.fetchPatientAppointmentAvailableScheduleByIdStatus = PENDING;
      try {
        const res = await fetchProviderPatientReferralById(referralID);
        this.showReferral = res.data.providerPatientReferral;
        this.fetchPatientAppointmentAvailableScheduleByIdStatus = SUCCESS;
      } catch (err) {
        this.fetchPatientAppointmentAvailableScheduleByIdStatus = ERROR;
        console.error(err);
      }
    },
    async fetchProviderGroupProvider() {
      try {
        const res = await getProviderGroupProvider(
          this.$route.params.idProvider
        );
        this.providerGroupProvider = res.data.provider[0];
      } catch (error) {
        console.error(error);
      }
    },
    goToPreviousStep() {
      //navigate
      this.$router.push({
        name: "cco-referrals.scheduling.referral-info",
        query: this.$route.query,
        params: {},
      });
    },
  },
  created() {
    if (this.$route.params.idPatient === null) return;

    if (this.PatientReferralID) {
      this.fetchProviderPatientReferralById(this.PatientReferralID);
    }
    this.fetchPatient();
    this.fetchProviderGroupProvider();
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/components/step-subtitle.scss";
@import "@/scss/components/qa-radios.scss";
@import "@/scss/components/actions-below-table-container.scss";

.confirmation-info-title {
  width: 100%;
  padding: 0 $secondaryCardsSidePadding;
}

.confirmation-info {
  background-color: $primaryBgBlue;
  padding: 37px 37px 17px;
}
</style>
