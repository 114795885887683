<template lang="pug">
.fit-kit-outside-icc.content-wrapper
  FITHeader(:idClinic="+idClinic")
  TabsCard(variant="lg")
    template(#tabs)
      router-link.tab(:to="{name: 'fit-outside-icc.pending'}")
        .tab__inner
          .tab__first-child {{outsideIccStatus.pending}}
          .tab__second-child Pending
      router-link.tab(:to="{name: 'fit-outside-icc.archived'}")
        .tab__inner
          .tab__first-child {{outsideIccStatus.completed}}
          .tab__second-child Archived
    template(#content)
      router-view(@refreshAppointments="getOutsideIccFitKit")
</template>

<script>
import { defineComponent } from "vue";
import FITHeader from "@/components/fit-kit/FITHeader.vue";
import TabsCard from "@/components/TabsCard.vue";
import { fetchFitKitDashboard } from "@/api/clinicApi";

export default defineComponent({
  components: { FITHeader, TabsCard },

  props: {},

  data() {
    return {
      idClinic: null,
      outsideIccStatus: {
        completed: 0,
        pending: 0,
      },
    };
  },

  computed: {},

  watch: {
    // keywords: {
    //   handler: "getRows",
    //   immediate: true
    // }
  },

  methods: {
    async getOutsideIccFitKit() {
      try {
        const res = await fetchFitKitDashboard(this.$route.params.idClinic);
        this.outsideIccStatus = res.data.outsideICCAppointments;
      } catch (err) {
        console.log(err);
      }
    },
  },

  created() {
    if (this.$route.params.idClinic === null) return;
    this.idClinic = this.$route.params.idClinic;
    this.getOutsideIccFitKit();
  },
});
</script>

<style scoped lang="scss"></style>
