<template lang="pug">

TableTitle(title="Patient Referrals",
  searchPlaceholder="Search by patient name"
  v-model="searchKeyword",
  @doSearch="fetchProviderPatientReferral",
  @blur="fetchProviderPatientReferral"
)
  template(#actions)
.table.table--hoverable
  .th
    .td(@click.prevent="changeListOrder('DateAndTime', fetchProviderPatientReferral)" style="cursor: pointer;") Date/Time
      i(style="font-weight: bolder;") {{ DateAndTimeColumnSortingIcon }}
    .td(@click.prevent="changeListOrder('PatientFullName', fetchProviderPatientReferral)" style="cursor: pointer;") Patient Name
      i(style="font-weight: bolder;") {{ PatientFullNameColumnSortingIcon }}
    .td(@click.prevent="changeListOrder('CBO', fetchProviderPatientReferral)" style="cursor: pointer;") Referred By
      i(style="font-weight: bolder;") {{ CBOColumnSortingIcon }}
    .td(@click.prevent="changeListOrder('ReferralModuleName', fetchProviderPatientReferral)" style="cursor: pointer;") Referral Type
      i(style="font-weight: bolder;") {{ ReferralModuleNameColumnSortingIcon }}
    .td(@click.prevent="changeListOrder('Status', fetchProviderPatientReferral)" style="cursor: pointer;") Last Activity
      i(style="font-weight: bolder;") {{ StatusColumnSortingIcon }}

  .tr(
    v-for="providerPatientReferral in providerPatientReferrals",
    :key="providerPatientReferral.id",
    @click.prevent="openReferralRecordOverlay(providerPatientReferral)" 
  )
    .td
      div {{ getDateFromUnixFormatted(providerPatientReferral.createdAt) }}
      .second-row-md-grey {{ getTimeFromUnixFormatted(providerPatientReferral.createdAt)}}
    .td.bold {{ getUserFormatted(providerPatientReferral.patient) }}
    .td {{ providerPatientReferral.communityCareOrganization?.name }}
    .td {{ providerPatientReferral.referralModule?.moduleTitle  }}
    .td
      .red(v-if = "providerPatientReferral.status == 'New Referral' ") {{ providerPatientReferral.status }}
      .red(v-if = "providerPatientReferral.status == 'Past Due' ") {{ providerPatientReferral.status }}
      .green(v-if = "providerPatientReferral.status == 'Received' ") {{ providerPatientReferral.status }}
      .black(v-if = "providerPatientReferral.status == 'Note Added' ") {{ providerPatientReferral.status }}
      .green(v-if = "providerPatientReferral.status == 'Contacted' ") {{ providerPatientReferral.status }}
      .red(v-if = "providerPatientReferral.status == 'Unable to Contact' ") {{ providerPatientReferral.status }}
      
      .second-row-sm-grey Updated {{ getRelativeTimeFromUnixFormatted(providerPatientReferral.updatedAt) }}


Pagination(
  v-if="pagination && providerPatientReferrals != null && providerPatientReferrals.length",
  :pagination="pagination",
  :reloadFn="fetchProviderPatientReferral"
  @changePagination="changePagination"
)
.no-results(v-if="providerPatientReferrals != null && !providerPatientReferrals.length") 
  base-icon(name="exclamation") 
  span No matching records
ProviderReferralRecordOverlay(
  :key="`${componentKey}-1`",
  :isOpen="isProviderReferralRecordOverlayOpen",
  tabType="unable_to_contact"
  :showReferral="showReferral"
  @closeProviderReferralRecordOverlay="closeProviderReferralRecordOverlay",
)

</template>

<script>
import { defineComponent } from "vue";
import TableTitle from "@/components/TableTitle.vue";
import DateSelector from "@/components/DateSelector.vue";
import Pagination from "@/components/Pagination.vue";
import listPagesMixin from "@/mixins/listPagesMixin";

import ProviderReferralRecordOverlay from "@/components/provider-groups/referral-status/overlays/ProviderReferralRecordOverlay.vue";

import { fetchProviderPatientReferral } from "@/api/providerPatientReferral";

import { apiStatus, apiStatusComputedFactory } from "@/api";
const { IDLE, PENDING, SUCCESS, ERROR } = apiStatus;

import {
  getDateFromUnixFormatted,
  getTimeFromUnixFormatted,
  getRelativeTimeFromUnixFormatted,
  getUserFormatted,
  getDoctorFormatted,
} from "@/helpers";
import sortProviderGroupReferrals from "@/mixins/sortProviderGroupReferrals";

export default defineComponent({
  components: {
    TableTitle,
    DateSelector,
    ProviderReferralRecordOverlay,
    Pagination,
  },
  emits: ["refreshAppointmentsSummary"],
  mixins: [listPagesMixin, sortProviderGroupReferrals],

  data() {
    return {
      searchKeyword: "",
      isProviderReferralRecordOverlayOpen: false,
      fetchProviderPatientReferralStatus: IDLE,
      fetchPatientStatus: IDLE,
      providerPatientReferrals: null,
      showReferral: null,
      date: null,
    };
  },
  computed: {
    ...apiStatusComputedFactory("fetchProviderPatientReferralStatus"),
  },
  methods: {
    getDateFromUnixFormatted,
    getTimeFromUnixFormatted,
    getRelativeTimeFromUnixFormatted,
    getUserFormatted,
    getDoctorFormatted,
    filterByDate(date) {
      this.date = date;
      this.fetchProviderPatientReferral();
    },
    async fetchProviderPatientReferral() {
      this.fetchProviderPatientReferralStatus = PENDING;
      try {
        delete this.reqPagination.quantity;
        const order = this.listOrder
          ? {
              by: this.listOrder.by,
              direction: this.listOrder.direction,
            }
          : null;

        const res = await fetchProviderPatientReferral({
          params: {
            ...this.reqPagination,
            order,
            include: ["patient", "cbo", "referralModule", "provider"],
            idProvider: this.$route.params.idProvider,
            stage: "UNABLE_TO_CONTACT",
            ...(this.searchKeyword
              ? { searchKeyword: this.searchKeyword }
              : {}),
          },
        });

        this.providerPatientReferrals = res.data.providerPatientReferrals;
        this.pagination = res.data.pagination;
        this.fetchProviderPatientReferralStatus = SUCCESS;
      } catch (err) {
        this.fetchProviderPatientReferralStatus = ERROR;
        console.error(err);
      }
    },
    openReferralRecordOverlay(providerPatientReferral) {
      this.componentKey = this.componentKey + 1;
      this.showReferral = providerPatientReferral;
      this.isProviderReferralRecordOverlayOpen = true;
    },
    closeProviderReferralRecordOverlay(shouldRefresh = true) {
      if (shouldRefresh) {
        this.$emit("refreshAppointmentsSummary");
        this.fetchProviderPatientReferral();
      }
      this.isProviderReferralRecordOverlayOpen = false;
    },
  },
  created() {
    if (this.$route.params.idProvider == null) return;
    this.fetchProviderPatientReferral();
  },
});
</script>

<style scoped lang="scss">
.tr {
  cursor: pointer;
}

.td {
  &:nth-child(1) {
    width: 15%;
  }

  &:nth-child(2) {
    width: 18%;
  }

  &:nth-child(3) {
    width: 19.9%;
  }

  &:nth-child(4) {
    width: 20%;
  }

  &:nth-child(5) {
    width: 19.4%;
  }
}
</style>
