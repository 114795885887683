<template lang="pug">
.overlay-pairs
  .pairs.columns
    .pair.column.is-half
      .pair-label FIT Test Results
      .pair-txt <strong>{{getFitResult()}}</strong>
    .pair.column.is-half
      .pair-label Date of Processing
      .pair-txt <strong>{{getDateOfProcessing()}}</strong>
  .pairs.columns(v-if="areFitNoResults()")
    .pair.column
      .pair-label Details
      .pair-txt <strong>{{getFitDetails()}}</strong>
  .pairs.columns
    .pair.column
      .pair-label Notes
      .pair-txt <strong>{{completedFit && completedFit.notes}}</strong>
</template>

<script>
import { defineComponent } from "vue";
import {
  getDateFromUnixFormatted,
  FIT_DETAIL_OPTIONS,
  COMPLETED_FIT_SIMPLE_OPTIONS,
} from "@/helpers";

export default defineComponent({
  components: {},

  props: {
    completedFit: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {};
  },

  computed: {},

  watch: {},

  methods: {
    getFitResult() {
      return this.completedFit && this.completedFit.result
        ? COMPLETED_FIT_SIMPLE_OPTIONS[this.completedFit.result]
        : "";
    },
    areFitNoResults() {
      return (
        this.completedFit &&
        this.completedFit.result &&
        this.completedFit.result === "no_result" &&
        this.completedFit.details
      );
    },
    getDateOfProcessing() {
      return (
        this.completedFit &&
        this.completedFit.processedAt &&
        getDateFromUnixFormatted(this.completedFit.processedAt)
      );
    },
    getFitDetails() {
      return this.completedFit && this.completedFit.details
        ? FIT_DETAIL_OPTIONS.find((x) => x.value === this.completedFit.details)
            .name
        : "";
    },
  },
});
</script>

<style scoped lang="scss"></style>
