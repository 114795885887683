<template lang="pug">
section.provider-groups
  TableTitle(title="Provider Groups")
    template(#actions)
      BaseBtn.x-lg.blue(
        href="#",
        @click.prevent="openProviderGroupOverlay()"
      ) ADD NEW
  .table(v-if="ProviderGroups?.length")
    .th
      .td(@click.prevent="changeListOrder('ProviderGroupName', fetchProviderGroups)" style="cursor: pointer;")  PROVIDER GROUP NAME
        i(style="font-weight: bolder;") {{ ProviderGroupNameColumnSortingIcon }}
      .td(@click.prevent="changeListOrder('City', fetchProviderGroups)" style="cursor: pointer;")  CITY
        i(style="font-weight: bolder;") {{ CityColumnSortingIcon }}
      .td
      .td
    .tr(v-for="providerGroup in ProviderGroups", :key="providerGroup.id")
      .td.bold {{ providerGroup.name }}
      .td.bold {{ providerGroup.address.place }}
      .td
        BaseBtn.sm.blue-outline(href="#", @click.prevent="goToProviderDashboard(providerGroup)") SELECT
      .td
        BaseBtn.sm.blue(
           href="#",
          @click.prevent="openProviderGroupOverlay(providerGroup.id)"
        ) MANAGE
  
  Pagination(
    v-if="pagination",
    :pagination="pagination",
    :reloadFn="fetchProviderGroups"
    @changePagination="changePagination"
  )

  ProviderGroupsOverlay(
    :key="`${componentKey}-1`"
    :providerGroupId="providerGroupId"
    :isOpen="isProviderGroupOverlayOpen",
    @closeProviderGroupOverlay="closeProviderGroupOverlay",
    @reloadProviderGroups="fetchProviderGroups"
  )
</template>

<script>
import { defineComponent } from "vue";
import TableTitle from "@/components/TableTitle.vue";
import Pagination from "@/components/Pagination.vue";
import ProviderGroupsOverlay from "@/components/organizations/overlays/ProviderGroupsOverlay.vue";
import { mapGetters } from "vuex";

import listPagesMixin from "@/mixins/listPagesMixin";
import { fetchProviderGroups } from "@/api/providerGroupApi";
import { apiStatus, apiStatusComputedFactory } from "@/api";
import { userRoles } from "@/store/account";
const { IDLE, PENDING, SUCCESS, ERROR } = apiStatus;
import sortProviderGroups from "@/mixins/sortProviderGroups";

export default defineComponent({
  components: {
    TableTitle,
    Pagination,
    ProviderGroupsOverlay,
  },
  mixins: [listPagesMixin, sortProviderGroups],
  data() {
    return {
      fetchProviderGroupsStatus: IDLE,
      ProviderGroups: null,
      providerGroupId: null,
      isProviderGroupOverlayOpen: false,
    };
  },
  computed: {
    ...apiStatusComputedFactory("fetchProviderGroupsStatus"),
    ...mapGetters(["isProviderManager", "getUserId"]),
  },
  methods: {
    async fetchProviderGroups() {
      this.fetchProviderGroupsStatus = PENDING;

      const order = this.listOrder
        ? {
            by: this.listOrder.by,
            direction: this.listOrder.direction,
          }
        : null;
      try {
        const res = await fetchProviderGroups({
          params: {
            ...this.reqPagination,
            order,
          },
        });

        this.ProviderGroups = res.data.providerGroups;
        this.pagination = res.data.pagination;
        this.fetchProviderGroupsStatus = SUCCESS;
      } catch (err) {
        this.fetchProviderGroupsStatus = ERROR;
        console.error(err);
      }
    },

    async goToProviderDashboard(providerGroup) {

      this.$store.dispatch("impersonate", {
        role: userRoles.provider.find(
          (role) => role.name === "Provider Group Manager"
        ),
        providerGroup,
      }).then(() => {
        let providerGroupID = providerGroup.id;
        this.$store.dispatch("fetchProvidersAndNavigate", providerGroupID);
      });
 
    },

    openProviderGroupOverlay(providerGroupId) {
      this.componentKey = this.componentKey + 1;
      this.forceRerender();
      this.providerGroupId = providerGroupId ?? null;
      this.isProviderGroupOverlayOpen = true;
    },
    closeProviderGroupOverlay() {
      this.isProviderGroupOverlayOpen = false;
    },
  },

  created() {
    this.fetchProviderGroups();
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/components/step-subtitle.scss";
.provider-groups {
  .td {
    &:nth-child(1) {
      width: math.percentage(math.div(397, $tabContentCardWidth));
    }
    &:nth-child(2) {
      width: math.percentage(math.div(347, $tabContentCardWidth));
    }
    &:nth-child(3) {
      width: math.percentage(math.div(212, $tabContentCardWidth));
    }
    &:nth-child(4) {
      width: math.percentage(math.div(222, $tabContentCardWidth));
    }
  }
}
.table .tr .td:not(:last-child):not(.bold) {
  padding-right: 10px;
  padding-left: 10px;
}
.table .td .btn {
  width: 100%;
}
.table .tr > .td {
  display: flex;
  align-items: center;
}
</style>
