<template lang="pug">

TableTitle(title="Appointment Status",
  searchPlaceholder="Search by patient name or hospital"
  v-model="searchKeyword",
  @doSearch="fetchPatientAppointment",
  @blur="fetchPatientAppointment"
)
  template(#actions)
    DateSelector(@filterByDate="filterByDate")
.table.table--hoverable
  .th
    .td(@click.prevent="changeListOrder('DateTime', fetchPatientAppointment)" style="cursor: pointer;") Appt. Time
      i(style="font-weight: bolder;") {{ DateTimeColumnSortingIcon }}
    .td(@click.prevent="changeListOrder('PatientName', fetchPatientAppointment)" style="cursor: pointer;")  Patient Name
      i(style="font-weight: bolder;") {{ PatientNameColumnSortingIcon }}
    .td(@click.prevent="changeListOrder('ReferringPhysician', fetchPatientAppointment)" style="cursor: pointer;") Referring Physician
      i(style="font-weight: bolder;") {{ ReferringPhysicianColumnSortingIcon }}
    .td(@click.prevent="changeListOrder('HospitalName', fetchPatientAppointment)" style="cursor: pointer;") Hospital
      i(style="font-weight: bolder;") {{ HospitalNameColumnSortingIcon }}
    .td(@click.prevent="changeListOrder('LastActivity', fetchPatientAppointment)" style="cursor: pointer;") Last Activity
      i(style="font-weight: bolder;") {{ LastActivityColumnSortingIcon }}
    .td(@click.prevent="changeListOrder('Report', fetchPatientAppointment)" style="cursor: pointer;") Report
      i(style="font-weight: bolder;") {{ ReportColumnSortingIcon }}
  .tr(
    v-for="appointment in appointments",
    :key="appointment.id",
    @click.prevent="openClinicAppointmentRecordOverlay(appointment)"
  )
    .td
      div {{ getDateFromUnixFormatted(appointment.scheduledAt) }}
      .second-row-md-grey {{ getTimeFromUnixFormatted(appointment.scheduledAt)}}
    .td.bold {{ getUserFormatted(appointment.patient) }}
    .td {{ getDoctorFormatted(appointment.referringDoctor) }}
    .td {{ appointment.schedule.hospital.name }}
    .td
      //- .red(v-if = "appointment.status == 'CANCELLED' ") Cancelled
      //- span(v-else-if = "appointment.status == 'RESCHEDULED' ") Rescheduled
      //- .green(v-else-if = "appointment.status == 'COMPLETED' ") Completed
      //- span(v-else) Note Added
      .green(v-if = "appointment.status == 'New' ") {{ appointment.status }}
      .green(v-if = "appointment.status == 'In Process' ") {{ appointment.status }}
      .green(v-if = "appointment.status == 'Completed' ") {{ appointment.status }}
      .green(v-if="appointment.status == 'Updated'") {{ appointment.status }}
      .red(v-if = "appointment.status == 'Rescheduled' ") {{ appointment.status }}
      .red(v-if = "appointment.status == 'Cancelled' ") {{ appointment.status }}
      
      .second-row-sm-grey Updated {{ getRelativeTimeFromUnixFormatted(appointment.updatedAt) }}
    .td
      base-icon(name="yes" v-if="appointment.idHospitalReport")
      base-icon(name="no" v-else)
Pagination(
  v-if="pagination && appointments != null && appointments.length",
  :pagination="pagination",
  :reloadFn="fetchPatientAppointment"
  @changePagination="changePagination"
)
.no-results(v-if="appointments != null && !appointments.length") 
  base-icon(name="exclamation") 
  span No matching records


ClinicAppointmentRecordOverlay(
  :key="`${componentKey}-1`",
  :isOpen="isClinicAppointmentRecordOverlayOpen",
  tabType="pending"
  :showAppointment="showAppointment"
  @closeClinicAppointmentRecordOverlay="closeClinicAppointmentRecordOverlay",
  :specialtyModule="specialtyModule"
)
AppointmentNotesOverlay(
  :isOpen="isAppointmentNotesOverlayOpen",
  @closeAppointmentNotesOverlay="closeAppointmentNotesOverlay"
)
</template>

<script>
import { defineComponent } from "vue";
import TableTitle from "@/components/TableTitle.vue";
import DateSelector from "@/components/DateSelector.vue";
import Pagination from "@/components/Pagination.vue";
import listPagesMixin from "@/mixins/listPagesMixin";

import ClinicAppointmentRecordOverlay from "@/components/clinics/appointment-status/overlays/ClinicAppointmentRecordOverlay.vue";
import AppointmentNotesOverlay from "@/components/overlays/side-overlays/AppointmentNotesOverlay.vue";

import { fetchPatientAppointment } from "@/api/patientAppointmentApi";

import { apiStatus, apiStatusComputedFactory } from "@/api";
const { IDLE, PENDING, SUCCESS, ERROR } = apiStatus;

import {
  getDateFromUnixFormatted,
  getTimeFromUnixFormatted,
  getRelativeTimeFromUnixFormatted,
  getUserFormatted,
  getDoctorFormatted,
} from "@/helpers";
import { mapGetters } from "vuex";
import { denyClinicStaffTraining } from "@/mixins/denyClinicStaffTraining";
import sortClinicAppointments from "@/mixins/sortClinicAppointments";

export default defineComponent({
  components: {
    TableTitle,
    DateSelector,
    Pagination,
    ClinicAppointmentRecordOverlay,
    AppointmentNotesOverlay,
  },
  emits: ["refreshAppointmentsSummary"],
  mixins: [listPagesMixin, denyClinicStaffTraining, sortClinicAppointments],

  data() {
    return {
      searchKeyword: "",
      isClinicAppointmentRecordOverlayOpen: false,
      isAppointmentNotesOverlayOpen: false,
      fetchPatientAppointmentStatus: IDLE,
      appointments: null,
      showAppointment: null,
      date: null,
    };
  },
  computed: {
    ...apiStatusComputedFactory("fetchPatientAppointmentStatus"),
  },
  props: {
    specialtyModule: {
      required: false,
    },
  },

  methods: {
    getDateFromUnixFormatted,
    getTimeFromUnixFormatted,
    getRelativeTimeFromUnixFormatted,
    getUserFormatted,
    getDoctorFormatted,
    filterByDate(date) {
      this.date = date;
      this.fetchPatientAppointment();
    },
    async fetchPatientAppointment() {
      this.fetchPatientAppointmentStatus = PENDING;
      delete this.reqPagination.quantity;
      const order = this.listOrder
        ? {
            by: this.listOrder.by,
            direction: this.listOrder.direction,
          }
        : null;

      try {
        const res = await fetchPatientAppointment({
          params: {
            ...this.reqPagination,
            order,
            idClinic: this.$route.params.idClinic,
            include: [
              "hospital",
              "referringDoctor",
              "patient",
              "hospitalDoctor",
              "cancelled",
            ],
            stage: "COMPLETED_COLONOSCOPY_PENDING_CLINIC_REVIEW",
            scheduledAt: this.date ?? null,
            idSpecialtyModule: this.$route.params.idSpecialty,
            ...(this.searchKeyword
              ? { searchKeyword: this.searchKeyword }
              : {}),
          },
        });
        this.appointments = res.data.patientAppointments;
        this.pagination = res.data.pagination;
        this.fetchPatientAppointmentStatus = SUCCESS;
      } catch (err) {
        this.fetchPatientAppointmentStatus = ERROR;
        console.error(err);
      }
    },
    openClinicAppointmentRecordOverlay(appointment) {
      if (!this.clinicStaffInTrainingNotAllowed) {
        return;
      }

      this.componentKey = this.componentKey + 1;
      this.showAppointment = appointment;
      this.isClinicAppointmentRecordOverlayOpen = true;
    },
    closeClinicAppointmentRecordOverlay(shouldRefresh = true) {
      if (shouldRefresh) {
        this.fetchPatientAppointment();
        this.$emit("refreshAppointmentsSummary");
      }
      this.isClinicAppointmentRecordOverlayOpen = false;
    },

    openAppointmentNotesOverlay() {
      this.isAppointmentNotesOverlayOpen = true;
    },
    closeAppointmentNotesOverlay() {
      this.isAppointmentNotesOverlayOpen = false;
    },
  },
  created() {
    if (this.$route.params.idClinic === null) return;
    this.fetchPatientAppointment();
  },
});
</script>

<style scoped lang="scss">
.tr {
  cursor: pointer;
}
.td {
  &:nth-child(1) {
    width: 15%;
  }
  &:nth-child(2) {
    width: 18%;
  }
  &:nth-child(3) {
    width: 19.9%;
  }
  &:nth-child(4) {
    width: 17%;
  }
  &:nth-child(5) {
    width: 20.4%;
  }
  &:nth-child(6) {
    width: 8.7%;
  }
}
</style>
<style>
.tabs-card .tab-content {
  padding-bottom: 0px;
}
</style>
