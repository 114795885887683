<template lang="pug">
section.select-patient
  TableTitle(:title="title")
    template(#actions)
      BaseBtn.x-lg.blue(
        href="#" @click.prevent="openAddNewClinicPatientOverlayOpen" 
        v-if="isClinicHealthSystemManager || isClinicStaff || isClinicDoctor"
      ) ADD NEW PATIENT
  .table
    .th
      .td Patient Name
      .td Clinic
      .td DOB
      .td
      .td
    .tr(v-for="patient in patients" :key="patient.id")
      .td.bold {{`${patient.first} ${patient.last}`}}
      .td {{getClinicName(patient)}}
      .td {{getDobFormatted(patient.dob)}}
      .td(:title="getActionTitle(patient)")
        BaseBtn.sm.blue-outline(
           href="#", :class="{disabled: !showAvailableToEdit(patient)}"
          @click.prevent="openClinicPatientDetalisOverlay(patient)"
        ) PATIENT DETAILS
      .td(:title="getActionTitle(patient)")
        BaseBtn.sm.blue(href="#", :class="{disabled: !showAvailableToEdit(patient)}", @click.prevent="openAddNewFITKitDistributionOverlay(patient)") ADD NEW FIT
  .no-results(v-if="patients != null && !patients.length") 
    base-icon(name="exclamation") 
    span No matching records
  .actions-below-table-container
    BaseBtn.lg.green-outline(
      @click.prevent="searchAgain()",
      style="min-width: 270px",
    ) SEARCH AGAIN

  AddNewFITKitDistributionOverlay(
    v-if="context === 'chs'"
    :isOpen="isAddNewFITKitDistributionOverlayOpen",
    :idClinic="idEntity",
    :selectedPatient="selectedPatient",
    @closeAddNewFITKitDistributionOverlay="closeAddNewFITKitDistributionOverlay"
  )

AddNewClinicPatientOverlay(
  :isOpen="isAddNewClinicPatientOverlayOpen",
  @reloadPatients="reloadPatients",
  @closeAddNewClinicPatientOverlayOpen="closeAddNewClinicPatientOverlayOpen",
)

ClinicPatientDetalisOverlay(
  :key="`${componentKey}-1`"
  :showPatient="showPatient",
  :isOpen="isClinicPatientDetalisOverlay",
  @closeClinicPatientDetalisOverlay="closeClinicPatientDetalisOverlay",
)
</template>

<script>
import { defineComponent } from "vue";
import moment from "moment";
import TableTitle from "@/components/TableTitle.vue";
import AddNewFITKitDistributionOverlay from "@/components/fit-kit/overlays/AddNewFITKitDistributionOverlay.vue";
import { fetchClinic } from "@/api/clinicApi";
import { fetchCHSPatients } from "@/api/chsApi";
import { fetchPatients as fetchHospitalPatients } from "@/api/hospitalApi";
import { getDobFormatted } from "@/helpers";
import listPagesMixin from "@/mixins/listPagesMixin";
import { mapGetters } from "vuex";
import AddNewClinicPatientOverlay from "@/components/clinics/scheduling/overlays/AddNewPatientOverlay.vue";
import { denyClinicStaffTraining } from "@/mixins/denyClinicStaffTraining";
import ClinicPatientDetalisOverlay from "@/components/clinics/patient-search/overlays/PatientDetalisOverlay.vue";

export default defineComponent({
  components: {
    TableTitle,
    AddNewFITKitDistributionOverlay,
    AddNewClinicPatientOverlay,
    ClinicPatientDetalisOverlay,
  },
  mixins: [listPagesMixin, denyClinicStaffTraining],
  emits: ["closeSelectPatient"],

  props: {
    context: {
      type: String, // hospital, chs
      required: true,
    },
    idEntity: {
      type: Number, // id depending on context
      required: true,
    },
    searchParams: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      patients: null,
      selectedPatient: null,
      idClinicHealthSystem: null,
      isAddNewFITKitDistributionOverlayOpen: false,
      isAddNewClinicPatientOverlayOpen: false,
      isClinicPatientDetalisOverlay: false,
      showPatient: null,
    };
  },

  computed: {
    title() {
      if (this.patients) {
        const count = this.patients.length;
        return `(${count}) Patients Found`;
      } else {
        return "Patients Found";
      }
    },
  },

  methods: {
    openAddNewClinicPatientOverlayOpen() {
      this.isAddNewClinicPatientOverlayOpen = true;
    },
    closeAddNewClinicPatientOverlayOpen() {
      this.isAddNewClinicPatientOverlayOpen = false;
    },
    openClinicPatientDetalisOverlay(patient) {
      this.componentKey = this.componentKey + 1;
      this.showPatient = patient;
      this.isClinicPatientDetalisOverlay = true;
    },
    closeClinicPatientDetalisOverlay() {
      this.isClinicPatientDetalisOverlay = false;
      this.fetchPatients();
    },
    openAddNewFITKitDistributionOverlay(patient) {
      this.selectedPatient = patient;
      this.isAddNewFITKitDistributionOverlayOpen = true;
    },
    closeAddNewFITKitDistributionOverlay() {
      this.selectedPatient = null;
      this.isAddNewFITKitDistributionOverlayOpen = false;
    },
    searchAgain() {
      this.$emit("closeSelectPatient");
    },
    getDobFormatted,
    getActionTitle(patient) {
      return this.showAvailableToEdit(patient)
        ? ""
        : "Cannot edit a patient that belongs to another Clinic Health System";
    },
    showAvailableToEdit(patient) {
      if (this.context === "chs") {
        var patientCHS = patient?.clinic?.idClinicHealthSystem;
        return patientCHS === this.idClinicHealthSystem;
      } else {
        return true;
      }
    },
    getClinicName(patient) {
      return patient?.clinic?.name;
    },
    getSearchInput(searchParams) {
      let searchInput = {};
      const searchBy = searchParams.searchBy;
      switch (searchBy) {
        case "name":
          if (searchParams.firstName) {
            searchInput.first = searchParams.firstName;
          }
          if (searchParams.lastName) {
            searchInput.last = searchParams.lastName;
          }
          break;
        case "mrn":
          searchInput = searchParams.mrn;
          break;
        case "dob":
          searchInput = moment.utc(searchParams.dob).format("MMDDYYYY");
          break;
        default:
          break;
      }
      return searchInput;
    },
    async reloadPatients(patient) {
      try {
        const resPatient = await this.reloadPatientsQuery(patient);
        this.patients = resPatient.data.patients;
      } catch (err) {
        console.error(err);
      }
    },
    async reloadPatientsQuery(patient) {
      const params = {
        searchBy: "name",
        searchInput: {
          first: patient.first,
          last: patient.last,
        },
      };
      if (this.context === "chs") {
        return fetchCHSPatients({
          params,
        });
      } else if (this.context === "hospital") {
        return fetchHospitalPatients(this.idHospital, {
          params,
        });
      }
    },
    async apiQueryByContext() {
      const params = {
        searchBy: this.searchParams.searchBy,
        searchInput: this.getSearchInput(this.searchParams),
      };
      if (this.context === "chs") {
        return fetchCHSPatients({
          params,
        });
      } else if (this.context === "hospital") {
        return fetchHospitalPatients(this.idHospital, {
          params,
        });
      }
    },
    async fetchPatients() {
      try {
        const resPatient = await this.apiQueryByContext();
        this.patients = resPatient.data.patients;
      } catch (err) {
        console.error(err);
      }
    },
    async fetchChsByClinic(idClinic) {
      try {
        const clinicRes = await fetchClinic(idClinic);
        this.idClinicHealthSystem = clinicRes.data.idClinicHealthSystem;
      } catch (err) {
        console.error(err);
      }
    },
  },

  created() {
    if (this.searchParams === null) return;
    this.fetchPatients();
    if (this.context === "chs" && this.idEntity) {
      this.fetchChsByClinic(this.idEntity);
    }
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/components/actions-below-table-container.scss";
@import "@/scss/components/step-subtitle.scss";
.select-patient {
  .td {
    &:nth-child(1) {
      width: 23.5%;
    }
    &:nth-child(2) {
      width: 23.1%;
    }
    &:nth-child(3) {
      width: 15.4%;
    }
    &:nth-child(4) {
      width: 20.4%;
    }
    &:last-child {
      width: 17.6%;
      // width: 19.5%;
    }
  }
}
.actions-below-table-container {
  .buttons {
    margin-left: 0;
  }
}
</style>
