<template lang="pug">
FullScreenOverlaySlot(
  :isOpen="isOpen",
  headerIcon="primary-care",
  title="Referral Record"
  @closeOverlay="closeOverlay(false)"
)
  template(#breadcrumbs)
    h1.breadcrumbs-title ILLINOIS REFERRAL CARE
  template(#actionSection)
    BaseBtn.x-lg.blue-outline(
      href="#",
      @click.prevent="openProviderReferralNotesOverlay",
      :count="noteSummary.new"
    ) NOTES ({{ noteSummary.total }})
  template(#content)
    .card(style="padding-top: 52px;")
      .patient-info.columns.is-variable
        .column.is-two-thirds
          .columns.is-variable
            .info-pair.column.is-one-third
              .label Referral Date
              .txt.txt--blue {{ getDateFromUnixFormatted(showReferral.createdAt) }} 
            .info-pair.column.is-one-half
              .label Referral Time
              .txt.txt--blue {{ getTimeFromUnixFormatted(showReferral.createdAt) }} 
        .column.is-one-third
          .columns.is-variable
            .info-pair.column.is-one-half
              .label Confirmed
              .txt.txt--blue {{ getDateFromUnixFormatted(showReferral.confirmedDate) || "N/A"}}
            .info-pair.column.is-one-half
              .label Confirmed Date
              .txt.txt--blue {{ getTimeFromUnixFormatted(showReferral.confirmedDate) || "N/A" }} 
     
      .hr.hr--thin
      .columns.is-variable
        .info-pair.column.is-one-third
          .label First Name
          .txt.txt--blue {{ showReferral.patient.first }}
        .info-pair.column.is-one-third
          .label Last Name
          .txt.txt--blue {{ showReferral.patient.last }}
        .info-pair.column.is-one-third
          .label Date of Birth
          .txt.txt--blue {{ getDobFormattedforDateField(showReferral.patient.dob) }}
      .hr.hr--thin
      .info-pair.columns.is-variable
        .column.is-two-thirds.info-pair
          .label Patient Address
          .txt {{ showReferral.patient.address.address}}<br>{{ showReferral.patient.address.placeName }}, {{ showReferral.patient.address.state }} {{ showReferral.patient.address.zipCode }}
        .column.is-one-third.flex.flex--flex-start.flex--align-start
          .info-pair
            .label Patient Phone Number
            .txt {{ getPhoneFormatted(showReferral.patient.contact.cell_phone) }}
          .info-pair
            .label Ext.
            .txt {{ showReferral.patient.contact.cell_phone_extension }}
      .hr.hr.hr--thin
      .columns.is-variable
        .info-pair.column.is-one-third
          .label Community Care Name
          .txt {{ showReferral.communityCareOrganization.name }} 
        .info-pair.column.is-one-third(v-if="showReferral.provider")
          .label Staff Name
          .txt {{ showReferral.provider.first }} {{ showReferral.provider.last }}
        .column.is-one-third.flex.flex--flex-start.flex--align-start
          .info-pair
            .label {{ showReferral.referralModule.referralInfoSectionTitle }} 
            .txt.cursorPointer 
              base-icon(v-if="patientInfoQuestions?.length" name="eye-green", @click.prevent="openReferralInfoOverlay")
              base-icon(v-else name="eye-gray")
        
    .card.card--blue-bg
      .columns
        .info-pair.column.is-one-third
          .label Referral Status
          select(:disabled="true" v-model="showReferral.referralStatus")
            option(value="") Select 
            option(value="RECEIVED") Received 
            option(value="CONTACTED") Contacted
            option(value="UNABLE_TO_CONTACT") Unable to contact
        .info-pair.column.is-one-third(v-if="showReferral.referralModule.providerReport")
          .label Provider Report 
            span(v-if="!showReferral.referralModule.providerReportRequired") (Optional)
          .txt
            FileViewer(:fileId="showReferral?.idProviderReport")
            
      .columns(v-if="showReferral.referralModule.providerNotes")
        .info-pair.column.is-full
          .label Provider Notes 
            span(v-if="!showReferral.referralModule.providerNotesRequired") (Optional)
          textarea(style="height: 250px;",:disabled="true" v-model="showReferral.providerNotes")

ReferralInfoOverlay(
  :isOpen="isReferralInfoOverlayOpen",
  :overlayTitle="showReferral?.referralModule?.referralInfoSectionTitle || 'Patient Info Questions' ",
  :showReferral="showReferral"
  :showReferralDetails="showReferralDetails"
  :patientInfoQuestions="patientInfoQuestions"
  @closeReferralInfoOverlay="closeReferralInfoOverlay"
)
ProviderReferralNotesOverlay(
  :key="`${componentKey}-1`",
  :hasNoNotes="hasNoNotes",
  :isOpen="isProviderReferralNotesOverlayOpen",
  @closeOverlay="closeProviderReferralNotesOverlay"
  @fetchProviderPatientReferralNotifications="fetchProviderPatientReferralNotifications"
  :providerReferral="showReferral"
)
</template>

<script>
import UploadFileButton from "@/components/UploadFileButton";
import { defineComponent } from "vue";

import FileViewer from "@/components/files/FileViewer.vue";
import FullScreenOverlaySlot from "@/components/overlays/FullScreenOverlaySlot.vue";
import ReferralInfoOverlay from "@/components/cco-referrals/patient-referrals/overlays/ReferralInfoOverlay.vue";

import ProviderReferralNotesOverlay from "@/components/overlays/side-overlays/ProviderReferralNotesOverlay.vue";
import listPagesMixin from "@/mixins/listPagesMixin";

import {
  getDateFromUnixFormatted,
  getTimeFromUnixFormatted,
  getDobFormattedforDateField,
  getRelativeTimeFromUnixFormatted,
  getPhoneFormatted,
  getFileSignedUrl,
} from "@/helpers";

import { fetchProviderPatientReferralById } from "@/api/providerPatientReferral";
import { fetchNotification } from "@/api/notifications.ts";

import { apiStatus } from "@/api";
import { fetchFileById } from "@/api/filesApi";
const { IDLE, PENDING, SUCCESS, ERROR } = apiStatus;

import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { maska } from "maska";

export default defineComponent({
  directives: { maska },
  setup() {
    return { v$: useVuelidate() };
  },

  validations() {
    let args = {
      showReferral: {
        referralStatus: { required },
      },
    };
    return args;
  },
  components: {
    UploadFileButton,
    FullScreenOverlaySlot,
    ReferralInfoOverlay,
    ProviderReferralNotesOverlay,
    FileViewer,
  },
  emits: ["closeProviderReferralRecordOverlay"],
  mixins: [listPagesMixin],

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    tabType: {
      type: String,
      default: "",
    },
    showReferral: {
      type: Object,
    },
  },
  data() {
    return {
      date: null,
      hasNoNotes: true,
      patientInfoQuestions: {},
      getDateFromUnixFormatted: getDateFromUnixFormatted,
      getTimeFromUnixFormatted: getTimeFromUnixFormatted,
      getDobFormattedforDateField: getDobFormattedforDateField,
      getRelativeTimeFromUnixFormatted: getRelativeTimeFromUnixFormatted,
      getPhoneFormatted,
      isReferralInfoOverlayOpen: false,
      fetchPatientAppointmentByIdStatus: IDLE,
      fetchProviderPatientReferralNotificationsStatus: IDLE,
      showReferralDetails: null,
      isProviderReferralNotesOverlayOpen: false,
      noteSummary: {
        total: 0,
        new: 0,
      },
      forcedReRenderTimes: 0,
    };
  },
  methods: {
    forceReRender() {
      this.forcedReRenderTimes++;
    },
    async fetchProviderPatientReferralNotifications() {
      this.fetchProviderPatientReferralNotificationsStatus = PENDING;
      try {
        const res = await fetchNotification({
          params: {
            idEntity: this.showReferral.id,
            entityType: "provider-referral",
          },
        });
        let newNotifications = res.data.notifications?.filter(
          (notification) => {
            return notification.status != "Completed";
          }
        );
        this.noteSummary.new = newNotifications?.length || 0;
        this.noteSummary.total = res.data.pagination?.quantity || 0;

        if (this.noteSummary.total > 0) {
          this.hasNoNotes = false;
        }
        this.fetchProviderPatientReferralNotificationsStatus = SUCCESS;
      } catch (err) {
        this.fetchProviderPatientReferralNotificationsStatus = ERROR;
        console.error(err);
      }
    },

    async fetchProviderPatientReferralById() {
      this.fetchPatientAppointmentByIdStatus = PENDING;
      try {
        const res = await fetchProviderPatientReferralById(
          this.showReferral.id,
          {
            params: {
              include: ["patient", "cbo", "providerReport", "referralModule"],
            },
          }
        );
        this.showReferralDetails = res.data.providerPatientReferral;
        this.patientInfoQuestions =
          res.data.providerPatientReferral?.patientInfoQuestions;
        this.checkFetchFiles();
        this.fetchPatientAppointmentByIdStatus = SUCCESS;
      } catch (err) {
        this.fetchPatientAppointmentByIdStatus = ERROR;
        console.error(err);
      }
    },
    closeOverlay(shouldRefresh = true) {
      this.$emit("closeProviderReferralRecordOverlay", shouldRefresh);
    },
    openProviderReferralNotesOverlay() {
      this.componentKey++;
      this.isProviderReferralNotesOverlayOpen = true;
    },
    closeProviderReferralNotesOverlay() {
      this.fetchProviderPatientReferralNotifications();
      this.isProviderReferralNotesOverlayOpen = false;
    },
    openReferralInfoOverlay() {
      this.isReferralInfoOverlayOpen = true;
    },
    closeReferralInfoOverlay() {
      this.isReferralInfoOverlayOpen = false;
    },
    async fetchFileSrc(id) {
      if (!id) {
        return null;
      }

      try {
        const resFile = await fetchFileById(id);
        const file = resFile.data.file;
        file.token = resFile.data.token;
        return getFileSignedUrl(file);
      } catch (err) {
        console.error(err);
      }
    },
    async checkFetchFiles() {
      this.hospitalReportSrc = await this.fetchFileSrc(
        this.showReferralDetails?.idHospitalReport
      );
    },
  },
  created() {
    if (this.showReferral === null) return;

    this.fetchProviderPatientReferralNotifications();
    this.fetchProviderPatientReferralById();
  },
});
</script>

<style scoped lang="scss">
.radio-custom {
  margin-right: 15px;
}
.flex-wrap {
  flex-wrap: wrap;
}
.margin-top {
  margin-top: 30px;
}
.spaceMarginTop {
  margin-top: 50px;
}
.time_spacer {
  margin-bottom: 46px;
}
.date_spacer {
  margin-bottom: 25px;
}
.info-pair .txt.font-medium {
  font-size: 18px;
  line-height: 28px;
  margin-top: 30px;
}
.cursorPointer svg {
  cursor: pointer;
}
.adjust-margin-top {
  margin-top: -8px;
}
.select-m-bot {
  margin-bottom: 25px;
}
.request_tab_confirm {
  margin-right: 0px;
  margin-left: auto;
}
.card {
  padding: 35px $secondaryCardsSidePadding 68px;
}
.columns.is-variable {
  --columnGap: 1.575%;
}
.columns + .hr {
  background-color: $primaryBgDarkGrey;
  margin-top: -21px;
  margin-bottom: 24px;
}
.card > .columns:last-child.info-pair,
.card > .columns:last-child .info-pair {
  margin-bottom: 0;
}
.svg-icon--eye-red,
.svg-icon--eye-gray,
.svg-icon--eye-green,
.svg-icon--yes,
.svg-icon--no {
  width: 50px;
  height: 50px;
  top: 0;
}

.rescheduled {
  .label {
    & > span {
      position: relative;
    }
    .svg-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: calc(100% + 20px);
    }
  }
}
.breadcrumbs-title {
  margin: 0;
  color: $primaryLightBlue;
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.12px;
}

.complete-appointment-popup,
.popup-error-content {
  max-width: 555px;
  margin: 0 auto;
  h1 {
    width: 100%;
    max-width: 549px;
    margin: 39px auto 30px;
    font-size: 28px;
    font-weight: 700;
    line-height: 1.21;
    letter-spacing: 0.14px;
    text-align: center;
  }
  p {
    margin-top: 0;
    margin-bottom: 32px;
    font-size: 16px;
    line-height: 1.19;
    letter-spacing: 0.23px;
    text-align: center;
  }
  .popup-btns {
    margin-bottom: 39px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .btn {
      width: 260px;
    }
  }
  .popup-btns.center {
    justify-content: center;
  }
}
</style>
