<template lang="pug">

TabsCard(variant="lg")
  template(#tabs)
    router-link.tab(:to="{name: 'primary-care-community-organization.patient-referrals.requested'}")
      .tab__inner
        .tab__first-child {{ summary.requested }}
        .tab__second-child Requested
    router-link.tab(:to="{name: 'primary-care-community-organization.patient-referrals.confirmed'}")
      .tab__inner
        .tab__first-child {{ summary.confirmed }}
        .tab__second-child Confirmed
    router-link.tab(:to="{name: 'primary-care-community-organization.patient-referrals.no-show-cancelled'}")
      .tab__inner
        .tab__first-child {{ summary.cancelled }}
        .tab__second-child No Show / Cancelled
    router-link.tab(:to="{name: 'primary-care-community-organization.patient-referrals.archived'}")
      .tab__inner
        .tab__first-child {{ summary.archived }}
        .tab__second-child Archived
  template(#content)
    router-view(
      @refreshAppointmentsSummary="refreshAppointmentsSummary"
    )

</template>

<script>
import { defineComponent } from "vue";
import TabsCard from "@/components/TabsCard.vue";
import { fetchPatientReferrals } from "@/api/patientReferral";

export default defineComponent({
  components: {
    TabsCard,
  },
  emits: ["patientReferralStats"],
  methods: {
    refreshAppointmentsSummary() {
      this.fetchSummaryInfo();
    },
    fetchSummaryInfo() {
      this.fetchPatientReferrals("REQUESTED");
      this.fetchPatientReferrals("CONFIRMED");
      this.fetchPatientReferrals("CANCELLED");
      this.fetchPatientReferrals("ARCHIVES");
    },
    async fetchPatientReferrals(stageParam) {
      try {
        let includeParams = [
          "referringDoctor",
          "patient",
          "cancelled",
          "clinic",
        ];
        let stageParamVal = stageParam;
        if (stageParam == "CANCELLED") {
          stageParamVal = "ARCHIVES";
          includeParams.push("noShowAndCancelled");
        }
        const res = await fetchPatientReferrals({
          params: {
            ...this.reqPagination,
            idCommunity: this.$route.params.idCommunity,
            include: includeParams,
            stage: stageParamVal,
          },
        });

        switch (stageParam) {
          case "REQUESTED":
            this.summary.requested = res.data.pagination.quantity;
            break;
          case "CONFIRMED":
            this.summary.confirmed = res.data.pagination.quantity;
            break;
          case "CANCELLED":
            this.summary.cancelled = res.data.pagination.quantity;
            break;
          case "ARCHIVES":
            this.summary.archived = res.data.pagination.quantity;
            break;
        }

        this.pagination = res.data.pagination;
        this.$emit("patientReferralStats", res.data.patientReferralStats);
      } catch (err) {
        console.error(err);
      }
    },
  },
  data() {
    return {
      summary: {
        requested: 0,
        confirmed: 0,
        cancelled: 0,
        archived: 0,
      },
    };
  },
  created() {
    if (this.$route.params.idCommunity == null) return;
    this.fetchSummaryInfo();
  },
});
</script>
