<template lang="pug">
section.fit-referrals.card
  TableTitle(title="Specialty Care")
    template(#actions)
      BaseBtn.x-lg.blue(
        href="#",
        @click.prevent="openModuleSettingsOverlay()"
      ) ADD NEW
  .table.table--hoverable
    .th
      .td(@click.prevent="changeListOrder('ModuleName', fetchSpecialtyModules)" style="cursor: pointer;") MODULE NAME
          i(style="font-weight: bolder;") {{ ModuleNameColumnSortingIcon }}
      .td Number of CHS USING
      .td ACTIVE
    .tr(
      v-for="item in specialtyModules",
      :key="item.id",
      @click="openModuleSettingsOverlay(item.id)"
    )
      .td.bold {{item.moduleTitle}}
      .td {{item.numberOfChsUsing}}
      .td
        base-icon(:name="item.isModuleEnabled === true ? 'yes' : 'no'")
  Pagination(
    v-if="pagination",
    @changePagination="changePagination"
    :pagination="pagination",
    :reloadFn="fetchSpecialtyModules"
  )
  SpecialtyModulesSettingsOverlay(
    :key="`${componentKey}-1`",
    :moduleId="moduleId",
    :isOpen="isModuleSettingsOverlayOpen",
    @closeModuleSettingsOverlay="closeModuleSettingsOverlay",
    @reloadSpecialtyModules="fetchSpecialtyModules"
  )
</template>

<script>
import { defineComponent } from "vue";
import TableTitle from "@/components/TableTitle.vue";
import listPagesMixin from "@/mixins/listPagesMixin";
import Pagination from "@/components/Pagination.vue";
import SpecialtyModulesSettingsOverlay from "@/components/system-settings/overlays/SpecialtyModulesSettingsOverlay.vue";

import { fetchSpecialtyModules } from "@/api/specialtyModulesApi";
import sortSpecialtyModules from "@/mixins/sortSpecialtyModules";

export default defineComponent({
  components: {
    TableTitle,
    Pagination,
    SpecialtyModulesSettingsOverlay,
  },
  mixins: [listPagesMixin, sortSpecialtyModules],

  data() {
    return {
      isModuleSettingsOverlayOpen: false,
      specialtyModules: null,
      moduleId: null,
    };
  },

  methods: {
    async fetchSpecialtyModules() {
      const order = this.listOrder
        ? {
            by: this.listOrder.by,
            direction: this.listOrder.direction,
          }
        : null;
      try {
        const res = await fetchSpecialtyModules({
          params: {
            ...this.reqPagination,
            order,
          },
        });
        this.specialtyModules = res.data.specialtyModules;
        this.pagination = res.data.pagination;
      } catch (err) {
        console.error(err);
      }
    },
    openModuleSettingsOverlay(moduleId) {
      this.forceRerender();
      this.moduleId = moduleId ?? null;
      this.isModuleSettingsOverlayOpen = true;
    },
    closeModuleSettingsOverlay() {
      this.isModuleSettingsOverlayOpen = false;
    },
  },
  created() {
    this.fetchSpecialtyModules();
  },
});
</script>

<style scoped lang="scss">
.td {
  &:nth-child(1) {
    width: math.percentage(math.div(458, $tabContentCardWidth));
  }
  &:nth-child(2) {
    width: math.percentage(math.div(458, $tabContentCardWidth));
  }
  &:last-child {
    width: math.percentage(math.div(262, $tabContentCardWidth));
    display: flex;
    justify-content: flex-end;
  }
}
</style>
