// @ts-nocheck
import { v1 as uuidv1 } from "uuid";
import {
  createReferralModule,
  updateReferralModule,
} from "@/api/referralModulesApi";
export default {
  emits: ["reloadReferralModules"],

  data() {
    return {};
  },

  methods: {
    setInitialOptions(fieldType) {
      if (fieldType === "DROPDOWN") {
        return [];
      } else if (fieldType === "RADIO") {
        return [
          {
            id: true,
            name: "Yes",
          },
          {
            id: false,
            name: "No",
          },
        ];
      }
      return null;
    },
    addFormField(fieldType, formModel) {
      formModel.push({
        id: uuidv1(),
        name: "",
        type: fieldType,
        required: false, //TODO: ask Damjan for default value
        active: true,
        inReport: fieldType === "NUMERIC" ? false : null,
        hasDependencies: false,
        rules: [],
        options: this.setInitialOptions(fieldType),
      });
    },
    addDropdownOption(dropdownModel) {
      dropdownModel.push({
        id: uuidv1(),
        name: "",
      });
    },
    toReadableType(type) {
      let readableType = type;
      switch (type) {
        case "RADIO":
          readableType = "Radio Y/N";
          break;
        case "SECTION_TITLE":
          readableType = "Section Title";
          break;
      }
      return readableType;
    },
    mutateConditionalRules(currentField, rules) {
      currentField.rules = rules;
    },
    constructModuleData() {
      const moduleData = {
        ...this.general,
        schemaRules: {
          referralInfoQuestions: this.referralInfoQuestions,
        },
      };
      return moduleData;
    },
    async createReferralModule() {
      const moduleData = this.constructModuleData();
      try {
        await createReferralModule(moduleData);
      } catch (err) {
        console.error(err);
      } finally {
        this.$emit("reloadReferralModules");
        this.closeOverlay();
      }
    },
    async updateReferralModule() {
      const moduleData = this.constructModuleData();
      try {
        await updateReferralModule(this.moduleId, {
          referralModule: { updatedAt: this.updatedAt, ...moduleData },
        });
      } catch (err) {
        console.error(err);
      } finally {
        this.$emit("reloadReferralModules");
        this.closeOverlay();
      }
    },
  },
};
