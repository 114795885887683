// @ts-nocheck
import api from "./api";

const patientAppointmentUrl = "/patient-appointment";

export const fetchPatientAppointment = (data = {}) => {
  return api.get(patientAppointmentUrl, data);
};

export const fetchPatientAppointmentNotifications = (data = {}) => {
  return api.get(patientAppointmentUrl + "/notifications", data);
};

export const fetchPatientAppointmentNotificationsCount = (data = {}) => {
  return api.get(
    patientAppointmentUrl +
      `/notifications/new-count?id=${data.id}&side=${data.side}&`
  );
};

export const fetchPatientAppointmentById = (idAppointment, data = {}) => {
  return api.get(patientAppointmentUrl + "/" + idAppointment, data);
};

export const fetchPatientAppointmentNotes = (data = {}) => {
  return api.get(patientAppointmentUrl + "/notes", data);
};

export const putPatientAppointmentNotifications = (data = {}) => {
  return api.put(patientAppointmentUrl + "/notifications", data);
};

export const deletePatientAppointmentById = (idAppointment, data = {}) => {
  return api.delete(
    patientAppointmentUrl + "/available-schedule/" + idAppointment,
    data
  );
};
export const postPatientAppointmentOnGoing = (data = {}) => {
  return api.post(patientAppointmentUrl + "/on-going", data);
};

export const patchPatientAppointmentById = (
  idAppointment,
  idSpecialtyModule,
  data = {}
) => {
  return api.patch(
    patientAppointmentUrl +
      "/" +
      idAppointment +
      "?idSpecialtyModule=" +
      idSpecialtyModule,
    data
  );
};

export const createPatientAppointment = (data = {}) => {
  return api.post(patientAppointmentUrl, data);
};

export const createPatientAppointmentNote = (data = {}) => {
  return api.post(patientAppointmentUrl + "/notes", data);
};

export const fetchPatientAppointmentAvailableSchedule = (data = {}) => {
  return api.get(patientAppointmentUrl + "/available-schedule", data);
};

export const fetchPatientAppointmentAvailableScheduleById = (
  idSchedule,
  data = {}
) => {
  return api.get(
    patientAppointmentUrl + "/available-schedule/" + idSchedule,
    data
  );
};
