<template lang="pug">
SideOverlaySlot(
  :isOpen="isOpen",
  title="COMPLETED COLONOSCOPY",
  @closeOverlay="closeOverlay"
)
  template(#contentTop)
    PatientSection(:fitKit="outsideIccFitKit", :showDetails="false", :showProvider="false")
    .overlay-pairs
      .pairs.columns
        .pair.column.is-half
          .pair-label Test Results
          .pair-txt <strong>{{getTestResults(outsideIccFitKit)}}</strong>
        .pair.column.is-half
          .pair-label Date of Processing
          .pair-txt <strong>{{getProcessingDate(outsideIccFitKit)}}</strong>
      .pairs.columns
        .pair.column.is-half
          .pair-label Report
          .pair-txt
           FileViewer(:fileId="outsideIccFitKit && outsideIccFitKit.colonoscopyOutsideICC && outsideIccFitKit.colonoscopyOutsideICC.idReport")
        .pair.column.is-half
          .pair-label Follow-Up Date
          .pair-txt <strong>{{getFollowUpDate(outsideIccFitKit)}}</strong>
      .pairs.columns
        .pair.column
          .pair-label Notes
          .pair-txt <strong>{{getNotes(outsideIccFitKit)}}</strong>
</template>

<script>
import { defineComponent } from "vue";
import SideOverlaySlot from "@/components/overlays/SideOverlaySlot.vue";
import PatientSection from "@/components/fit-kit/overlays/PatientSection.vue";
import FileViewer from "@/components/files/FileViewer";
import {
  getDateFromUnixFormatted,
  COLONOSCOPY_OUTSIDE_RESULT_OPTIONS as RESULT_OPTIONS,
} from "@/helpers";

export default defineComponent({
  components: {
    FileViewer,
    SideOverlaySlot,
    PatientSection,
  },
  emits: ["closeCompletedColonoscopyOverlay"],

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    outsideIccFitKit: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      RESULT_OPTIONS,
    };
  },

  methods: {
    closeOverlay() {
      this.showSuccess = false;
      this.$emit("closeCompletedColonoscopyOverlay");
    },
    getTestResults(fitKit) {
      const result =
        fitKit &&
        fitKit.colonoscopyOutsideICC &&
        fitKit.colonoscopyOutsideICC.result;
      return RESULT_OPTIONS.find((x) => x.value === result).name || "";
    },
    getProcessingDate(fitKit) {
      return (
        fitKit &&
        fitKit.colonoscopyOutsideICC &&
        getDateFromUnixFormatted(fitKit.colonoscopyOutsideICC.processedAt, "/")
      );
    },
    getFollowUpDate(fitKit) {
      return (
        fitKit &&
        fitKit.colonoscopyOutsideICC &&
        getDateFromUnixFormatted(fitKit.colonoscopyOutsideICC.followUpAt, "/")
      );
    },
    getNotes(fitKit) {
      return (
        fitKit &&
        fitKit.colonoscopyOutsideICC &&
        fitKit.colonoscopyOutsideICC.notes
      );
    },
  },
});
</script>

<style scoped lang="scss"></style>
