<template lang="pug">
ul.small(v-if="getShowHospitalDashboardsMainNav")
  li(v-for="(specialtyWrapper, index) in getHospitalSpecialtyModules" :key="specialtyWrapper.specialtyModule.id")
    a(href="#", title="Notifications", @click.prevent="goToHospitalSpecialtyDashboard(specialtyWrapper)")
      span.icn
      span.txt {{specialtyWrapper.specialtyModule.moduleTitle}}
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    hospital: {
      type: Number,
      default: -1,
    },
  },

  computed: {
    ...mapGetters([
      "getShowHospitalDashboardsMainNav",
      "getHospitalSpecialtyModules",
      "getHospitalId",
    ]),
  },

  methods: {
    goToHospitalSpecialtyDashboard(specialty) {
      this.$router.push({
        name: "hospitals",
        params: {
          idHospital: this.getHospitalId,
          idSpecialty: specialty.specialtyModule.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-main {
  width: 64px + 2 * 30px;
  padding-left: 30px;
  padding-right: 30px;
  overflow: hidden;
  transition: width 0.4s ease-in;
  &--expanded {
    // overflow: visible;
    width: 357px;
    a:hover {
      background-color: $primaryLightBlue;
      &::before,
      &::after {
        opacity: 1;
      }
    }
  }
  ul {
    margin: 0;
    padding: 0;
    position: relative;
    list-style: none;
    & > li {
      padding: 0 13px;
      white-space: nowrap;
      & > a,
      & > .section-title {
        position: relative;
        display: flex;
        align-items: center;
        height: 68px;
        width: 100%;
        font-size: 20px;
        line-height: 1.1;
        font-weight: 400;
        letter-spacing: 0px;
        color: #fff;
        text-decoration: none;
        text-transform: uppercase;
        white-space: nowrap;
        transition: all 0.2s linear;
        .svg-icon,
        .icn {
          flex: 0 0 auto;
          width: 34px;
          height: 34px;
          margin-right: 53px;
        }
        .txt {
          flex: 0 0 auto;
          white-space: nowrap;
        }
        &::before,
        &::after {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          bottom: 0;
          width: 43px;
          background-color: $primaryLightBlue;
          opacity: 0;
          transition: opacity 0.2s linear;
        }
        &::before {
          right: 100%;
        }
        &::after {
          left: 100%;
        }
      }
    }
  }
}

ul:not(:first-of-type) {
  li:first-child {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: -1px;
      right: 0;
      display: block;
      height: 1px;
      background-color: #fff;
      transition: all 0.2s linear;
    }
    &:hover {
      &::before {
        left: -30px;
        right: -30px;
      }
    }
  }
}

ul:not(:last-of-type) {
  li:last-child {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      display: block;
      height: 1px;
      background-color: #fff;
      transition: all 0.2s linear;
    }
    &:hover {
      &::after {
        left: -30px;
        right: -30px;
      }
    }
  }
}

ul.small > li > a {
  text-transform: none;
}
</style>