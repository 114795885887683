import { mapGetters } from "vuex";

// @ts-nocheck
export const denyClinicStaffTraining = {
  computed: {
    ...mapGetters([
      "isClinicHealthSystemManager",
      "isClinicStaff",
      "isClinicDoctor",
    ]),
    clinicStaffInTrainingNotAllowed() {
      return (
        this.isClinicHealthSystemManager ||
        this.isClinicStaff ||
        this.isClinicDoctor
      );
    },
  },
};
