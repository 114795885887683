<template lang="pug">
svg(:class="className", xmlns="http://www.w3.org/2000/svg", v-if="nameString === ''  ")
  title(v-if="title") {{ title }}
  use(:xlink:href="iconPath" xmlns:xlink="http://www.w3.org/1999/xlink")
img.maxWidth(:src='nameString' v-else)
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    name: {
      type: String,
      required: true,
    },
    nameString: {
      type: String,
      required: false,
      default: "",
    },
    title: {
      type: String,
      default: null,
    },
  },

  computed: {
    iconPath() {
      let icon = require(`@/assets/icons/${this.name}.svg`);
      if (Object.prototype.hasOwnProperty.call(icon, "default")) {
        icon = icon.default;
      }
      return icon.url;
    },

    className() {
      // if (!this.name) return null;
      return "svg-icon svg-icon--" + this.name;
    },
  },
});
</script>

<style lang="scss">
img.maxWidth {
  max-width: 100px;
}
.svg-icon {
  // Flow with text content
  display: inline-block;
  // Inherit the parent text color
  fill: currentColor;
  // Use the parent font-size for width and height
  height: 1em;
  width: 1em;
  // Vertically align icon with adjacent text
  vertical-align: middle;
  // Align more nicely with capital letters
  position: relative;
  top: -0.0625em;
  &.fill {
    stroke: none;
  }
  &.stroke {
    fill: none;
    stroke: currentColor;
  }
}
</style>
