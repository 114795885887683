<template lang="pug">
SideOverlaySlot(
  :isOpen="isOpen",
  :title="title"
  @closeOverlay="closeProviderDetailsOverlay(false)"
)
  template(#contentTop)
    ProviderSection(:provider="selectedProvider")  
  template(#scrollbox)
    .overlay-pairs.history-section
      .item(v-for="answer in selectedProvider.user_meta" :key="answer.id")
        .pairs.columns
          .pair.column.is-half
            .pair-label {{ answer.question }}
          .pair.column.is-half
            .pair-txt <strong>{{ answer.value }}</strong>
      .item(v-if="selectedProvider.details")
        .pairs.columns
          .pair.column.is-full 
            .pair-label Details
            .pair-text <strong v-html="selectedProvider.details"></strong>
  template(#footer, v-if="step !== 'completed'")
    .flex.flex--space-between(v-if="step === 'complete'")
      BaseBtn.x-lg.blue-outline(
        href="#",
        @click.prevent="closeProviderDetailsOverlay(false)"
      ) CLOSE
      BaseBtn.x-lg.blue(
        @click.prevent="closeProviderDetailsOverlay(true)"
      ) SELECT PROVIDER
</template>

<script>
import { defineComponent } from "vue";
import SideOverlaySlot from "@/components/overlays/SideOverlaySlot.vue";
import ProviderSection from "@/components/cco-referrals/scheduling/overlays/ProviderSection.vue";

export default defineComponent({
  components: {
    SideOverlaySlot,
    ProviderSection,
  },
  emits: ["closeProviderDetailsOverlay"],

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    selectedProvider: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      step: "complete", //complete, confirm, completed
    };
  },
  methods: {
    closeProviderDetailsOverlay(selectProvider) {
      this.$emit("closeProviderDetailsOverlay", selectProvider);
    },
  },
  computed: {
    title() {
      return "PROVIDER DETAILS";
    },
  },
});
</script>

<style scoped lang="scss">
.item strong {
  white-space: break-spaces;
}
</style>
