<template lang="pug">
PopupCardSlot(
  :isOpen="isOpen",
  :maxWidth="'674px'"
)
  .module-disable-popup
    header
      h1
        span.red Warning! 
        span.message You are about to disable a module.
        
    .popup-content
      p Saving the module settings will disable the module for all organizations.
    footer
      BaseBtn.lg.red-outline(@click="$emit('cancelModuleDisable')") CANCEL
      BaseBtn.lg.green(@click="$emit('confirmModuleDisable')") YES, CONTINUE
</template>

<script>
import { defineComponent } from "vue";
import PopupCardSlot from "@/components/PopupCardSlot.vue";

export default defineComponent({
  components: { PopupCardSlot },
  emits: ["cancelModuleDisable", "confirmModuleDisable"],

  props: {
    isOpen: {
      type: Boolean,
      required: false,
    },
  },
});
</script>

<style scoped lang="scss">
header {
  margin-top: 43px;
  margin-bottom: 29px;
  & > h1 {
    margin: 0;
    font-size: 28px;
    font-weight: 700;
    line-height: 1.21;
    letter-spacing: 0.14px;
    span {
      display: block;
      text-align: center;
    }
  }
}
.popup-content {
  margin-bottom: 30px;
  p {
    margin: 0;
    text-align: center;
  }
}
footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 553px;
  margin: 0 auto 39px;
  .btn {
    width: 259px;
  }
}
</style>
