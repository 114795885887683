<template lang="pug">
PopupCardSlot(
  :isOpen="isInsurancePopupOpen && isInsured !== null",
  maxWidth="675px"
)
  .insurance-popup
    h1 The slot you selected is for 
      span.green(v-show="isInsured") insured 
      span.red(v-show="!isInsured") uninsured 
      | patient only.
    p Please confirm to continue
    .popup-btns
      BaseBtn.x-lg.red-outline(@click="closeInsurancePopup") CANCEL
      BaseBtn.x-lg.green(@click.prevent="nextPage") YES, CONTINUE

section.step-subtitle
  h2 SELECT SCHEDULE 
   span.lighter for 
   span.blue(v-if="patient") {{ getUserFormatted(patient) }}
TableTitle(title="All Schedules")
  template(#actions)
    select.table-action-selector.table-action-selector--hospital(
      v-if="showClinicFilter"
      :title="clinicTitle"
      @change="setFilterValueClinic($event.target.value)"
    )
      option(value="") CLINIC NAME
      option(v-for="clinic in clinics" :key="clinic.id" :value="clinic.id") {{ clinic.name }}

    select.table-action-selector.table-action-selector--insurance(
      :title="insuranceTitle",
      style="margin-right: 24px;"
      @change="setFilterValueInsurance($event.target.value)"
    )
      option INSURANCE
      option(value="true") Insured
      option(value="false") Uninsured
    DateSelector(@filterByDate="filterByDate" :minDate="new Date()")
.table
  .th    
    .td(@click.prevent="changeListOrder('ClinicName', getClinicSchedules)" style="cursor: pointer;") Clinic
      i(style="font-weight: bolder;") {{ ClinicNameColumnSortingIcon }}
    .td(@click.prevent="changeListOrder('DateAndTime', getClinicSchedules)" style="cursor: pointer;") Date and time
      i(style="font-weight: bolder;") {{ DateAndTimeColumnSortingIcon }}
    .td Insurance
    .td
  .tr(v-for="schedule in schedules",
        :key="schedule.id",)
    .td {{ schedule.clinic.name }}
    .td
      div {{ getDateFromUnixFormatted(schedule.availableAt) }}
      .second-row-md-grey {{ getTimeFromUnixFormatted(schedule.availableAt) }}
    .td
      base-icon(name="yes", v-if="schedule.insuranceRequired")
      base-icon(name="no", v-else)
    .td
      BaseBtn.sm.blue(v-if="!schedule.patientAppointment" @click="openInsurancePopup(schedule)") Select Schedule
      BaseBtn.sm.blue-outline.disabled-outline(v-else) Select Schedule

Pagination(
  v-if="pagination  && schedules != null && schedules.length",
  :pagination="pagination",
  :reloadFn="getClinicSchedules"
  @changePagination="changePagination"
)
.no-results(v-if="schedules != null && !schedules.length") 
  base-icon(name="exclamation") 
  span No matching records


.actions-below-table-container
    BaseBtn.lg.green-outline(
      :to="{name: 'primary-care-community-organization.scheduling.select-patient', query: $route.query}",
      style="min-width: 270px",
    ) &lt; PREVIOUS STEP
</template>

<script>
import { defineComponent } from "vue";

import router from "@/router";
import TableTitle from "@/components/TableTitle.vue";
import DateSelector from "@/components/DateSelector.vue";
import Pagination from "@/components/Pagination.vue";
import listPagesMixin from "@/mixins/listPagesMixin";

import PopupCardSlot from "@/components/PopupCardSlot.vue";
import { getClinicSchedules } from "@/api/patientReferral";
import { fetchPatient } from "@/api/patientApi";
import { fetchClinic } from "@/api/clinicApi";
import { apiStatus, apiStatusComputedFactory } from "@/api";
const { IDLE, PENDING, SUCCESS, ERROR } = apiStatus;

import { fetchCommunityCareOrganizationsClinics } from "@/api/communityOrganization";

import {
  getDateFromUnixFormatted,
  getTimeFromUnixFormatted,
  getUserFormatted,
} from "@/helpers";
import sortCCOSchedules from "@/mixins/sortCCOSchedules";

export default defineComponent({
  components: { TableTitle, PopupCardSlot, Pagination, DateSelector },

  data() {
    return {
      isInsurancePopupOpen: false,
      isInsured: null,
      getClinicSchedulesStatus: IDLE,
      fetchClinicsStatus: IDLE,
      fetchPatientStatus: IDLE,
      schedules: null,
      patient: null,
      selectedSchedule: null,
      selectedClinic: null,
      date: null,
      isInsuranceRequired: null,
      idCHS: null,
      insuranceTitle: "INSURANCE",
      clinicTitle: "CLINIC NAME",
      clinics: null,
      filterIdClinic: null,
      idClinic: null,
      showClinicFilter: false,
    };
  },
  mixins: [listPagesMixin, sortCCOSchedules],
  computed: {
    ...apiStatusComputedFactory(
      "getClinicSchedulesStatus",
      "fetchPatientStatus"
    ),
  },
  methods: {
    getUserFormatted,
    getDateFromUnixFormatted,
    getTimeFromUnixFormatted,
    filterByDate(date) {
      this.date = date;
      this.getClinicSchedules();
    },
    setFilterValueClinic(value) {
      if (value != "") {
        this.filterIdClinic = value;
        this.clinics.forEach((clinic) => {
          if (clinic.id == value) {
            this.clinicTitle = clinic.name;
          }
        });
      } else {
        this.filterIdClinic = null;
        this.clinicTitle = "CLINIC NAME";
      }
      this.getClinicSchedules();
    },
    setFilterValueInsurance(value) {
      if (value === "true" || value === "false") {
        if (value === "true") {
          this.insuranceTitle = "INSURED";
        } else {
          this.insuranceTitle = "UNINSURED";
        }
        this.isInsuranceRequired = value;
      } else {
        this.insuranceTitle = "INSURANCE";
        this.isInsuranceRequired = null;
      }
      this.getClinicSchedules();
    },
    async fetchClinic() {
      try {
        const res = await fetchClinic(this.$route.params.idClinic);
        this.idCHS = res.data.idClinicHealthSystem;
      } catch (err) {
        console.error(err);
      }
    },
    async fetchPatient() {
      this.fetchPatientStatus = PENDING;
      try {
        const res = await fetchPatient(this.$route.params.idPatient);

        this.patient = res.data.patient;
        if (this.patient.idClinic === null && this.filterIdClinic === null) {
          this.showClinicFilter = true;
          this.filterIdClinic = null;
        }
        this.fetchPatientStatus = SUCCESS;
      } catch (err) {
        this.fetchPatientStatus = ERROR;
        console.error(err);
      }
    },

    async getClinicSchedules() {
      this.getClinicSchedulesStatus = PENDING;
      const order = this.listOrder
        ? {
            by: this.listOrder.by,
            direction: this.listOrder.direction,
          }
        : null;

      let navigatePage = 1;
      if (this.reqPagination.page) {
        navigatePage = this.reqPagination.page;
      }

      try {
        const res = await getClinicSchedules({
          params: {
            ...this.reqPagination,
            order,
            collapsedDoctor: false,
            idCommunity: this.$route.params.idCommunity,
            idClinic: this.filterIdClinic,
            include: ["patientReferral", "clinic", "doctor", "communityCare"],
            isAvailable: "Available",
            availableAt: this.date ?? null,
            isInsuranceRequired: this.isInsuranceRequired ?? null,
            idSpecialtyModule: this.$route.params.idSpecialty,
            // idClinic: this.filterIdClinic ?? null,
            page: navigatePage,
          },
        });

        this.schedules = res.data.schedules;
        this.pagination = res.data.pagination;
        this.getClinicSchedulesStatus = SUCCESS;
      } catch (err) {
        this.getClinicSchedulesStatus = ERROR;
        console.error(err);
      }
    },
    openInsurancePopup(schedule) {
      this.isInsured = !!schedule.insuranceRequired;
      this.selectedSchedule = schedule.id;
      this.selectedClinic = schedule.clinic.id;

      this.isInsurancePopupOpen = true;
    },
    closeInsurancePopup() {
      this.isInsurancePopupOpen = false;
      this.isInsured = null;
    },
    nextPage() {
      this.isInsurancePopupOpen = false;
      this.isInsured = null;
      let params = {
        idPatient: this.$route.params.idPatient,
        idSchedule: this.selectedSchedule,
        idClinic: this.selectedClinic,
      };
      if (this.$route.params.idFitKit) {
        params.idFitKit = this.$route.params.idFitKit;
      }
      if (this.$route.params.idReferral) {
        params.idReferral = this.$route.params.idReferral;
      }
      router.push({
        name: "primary-care-community-organization.scheduling.appointment-info",
        query: this.$route.query,
        params,
      });
    },
    async getClinics() {
      try {
        const res = await fetchCommunityCareOrganizationsClinics(
          this.$route.params.idCommunity,
          {
            params: {
              idCommunity: this.$route.params.idCommunity,
            },
          }
        );
        this.clinics = res.data.clinics;
      } catch (error) {
        console.error(error);
      }
    },
  },
  created() {
    if (this.$route.params.idCommunity === null) return;

    if (this.$route.params.idClinic) {
      this.filterIdClinic = this.$route.params.idClinic;
    }
    this.fetchPatient().then( () => {
      this.getClinics();
      this.getClinicSchedules();
    });
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/components/step-subtitle.scss";
@import "@/scss/components/actions-below-table-container.scss";
.td {
  &:nth-child(1) {
    width: 29.2%;
  }
  &:nth-child(2) {
    width: 19.3%;
  }
  &:nth-child(3) {
    width: 32%;
  }
  &:nth-child(4) {
    width: 19.5%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    .btn {
      min-width: 190px;
    }
  }
}
.insurance-popup {
  max-width: 555px;
  margin: 0 auto;
  h1 {
    width: 100%;
    max-width: 420px;
    margin: 39px auto 30px;
    font-size: 28px;
    font-weight: 700;
    line-height: 1.21;
    letter-spacing: 0.14px;
    text-align: center;
  }
  p {
    margin-top: 0;
    margin-bottom: 32px;
    font-size: 16px;
    line-height: 1.19;
    letter-spacing: 0.23px;
    text-align: center;
  }
  .popup-btns {
    margin-bottom: 39px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .btn {
      width: 260px;
    }
  }
}
</style>
