<template lang="pug">
BasePageTitle(
  pageTitleIcon="colon",
  title="Illinois Colon Cares: FIT Registry"
)
  template(#breadcrumbs)
    li
      router-link(:to="{name: 'fit-kit-status', params: { idClinic }}") FIT Kit Status
    li
      router-link(:to="{name: 'new-fit-kit', params: { idClinic }}") Add New FIT
    li(v-if="organizationType === 'CBO'")
      router-link(:to="{name: 'fit-referrals', params: { idClinic }}") Referrals
    li
      router-link(:to="{name: 'fit-outside-icc', params: { idClinic }}") Outside ICC
  template(#actionSection, v-if="showAddNewFitBtn")
    BaseBtn.lg.blue(:to="{name: 'new-fit-kit', params: { idClinic }}") ADD NEW FIT
</template>

<script>
import { defineComponent } from "vue";
import { fetchClinic } from "@/api/clinicApi";
import { fetchCHS } from "@/api/chsApi";

export default defineComponent({
  data() {
    return {
      organizationType: null,
    };
  },
  props: {
    showAddNewFitBtn: {
      type: Boolean,
      default: true,
    },
    idClinic: {
      type: Number,
      required: true,
    },
  },
  methods: {
    async getClinicType() {
      try {
        const clinicRes = await fetchClinic(this.idClinic);
        const chsRes = await fetchCHS(clinicRes.data.idClinicHealthSystem);
        this.organizationType = chsRes.data.organizationType;
      } catch (err) {
        console.error(err);
      }
    },
  },
  created() {
    this.getClinicType();
  },
});
</script>
