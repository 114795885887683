<template lang="pug">
section.action-items
  h3.title.title--md ACTION ITEMS
  h4.title-line-right <span>FIT Registry</span>
  .box-links(v-if="dashboard?.fitRegistry?.fitRegistryStatus")
    router-link(:to="{name: 'fit-kit-status.positive-fit', params: {idClinic: clinicId}}")
      BoxLinkMd.red(
        text="Positive FIT Colonoscopy Req.",
        :number="dashboard.fitRegistry.fitRegistryStatus.positiveCompletedNeededActionKits"
      )
    router-link(:to="{name: 'fit-kit-status.follow-up-due', params: {idClinic: clinicId}}")
      BoxLinkMd.yellow(
        text="Kit Follow-Up Due",
        :number="dashboard.fitRegistry.fitRegistryStatus.dueFollowUpKits"
      )
    router-link(:to="{name: 'fit-kit-status.patient-follow-up-due', params: {idClinic: clinicId}}")
      BoxLinkMd.yellow-outline(
        text="Patient Follow-Up Due",
        :number="dashboard.fitRegistry.fitRegistryStatus.oneYearFollowUpKits"
      )
  .box-links(v-else="")
    BoxLinkMd.grey-outline(text="NOT ACTIVATED <br>Contact our Team", number="0")
    BoxLinkMd.grey-outline(text="NOT ACTIVATED <br>Contact our Team", number="0")
    BoxLinkMd.grey-outline(text="NOT ACTIVATED <br>Contact our Team", :number="0")
  .box-links(v-if="dashboard.primaryCare || specialties?.length > 0")
    div(v-if="dashboard.primaryCare")
      h4.title-line-right <span>Primary Care</span>
      router-link.tab(:to="{name: 'primary-care-clinic.patient-referrals.requested'}")
        BoxLinkMd.yellow(text="New Referrals", :number="primaryCareNewReferrals")
    div(v-else="")
      h4.title-line-right <span>Primary Care</span>
      BoxLinkMd.grey-outline(text="NOT ACTIVATED <br>Contact our Team", number="0")
    template(v-if="specialties?.length >= 1")
      div(v-for="(specialty, index) in specialties")
        h4.title-line-right <span>{{specialty.specialtyModule.moduleTitle}}</span>
        router-link(
          v-if="isSpecialtyEnabledAndActive(specialty)",
          :to="{name: 'clinics.appointment-status.pending-clinic-review', params: {idClinic: clinicId, idSpecialty: specialty.specialtyModule.id}}"
        )
          BoxLinkMd.yellow(
            text="Pending Clinic Review",
            :number="specialty?.appointmentStatus?.completedColonoscopyPendingClinicReview ?? 0"
          )
        span(v-else="")
          BoxLinkMd.grey-outline(
            text="NOT ACTIVATED <br>Contact our Team",
            number="0"
          )
</template>

<script>
import { defineComponent } from "vue";
import BoxLinkMd from "@/components/BoxLinkMd.vue";
import { fetchPatientReferrals } from "@/api/patientReferral";

export default defineComponent({
  components: { BoxLinkMd },
  props: {
    dashboard: {
      type: Object,
      default: null,
    },
    clinicId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      primaryCareNewReferrals: 0,
    };
  },
  computed: {
    specialties() {
      // Filter only globally enabled modules which are not linked to CHS
      let globallyEnabledNotLinked;
      if (this.dashboard?.disabledSpecialties) {
        globallyEnabledNotLinked = this.dashboard?.disabledSpecialties.filter(
          (item) => {
            return item.specialtyModule.isModuleEnabled === true;
          }
        );
      }

      // Combine
      let specialties;
      if (
        !this.dashboard?.specialties?.length &&
        !globallyEnabledNotLinked.length
      ) {
        specialties = [];
      } else if (
        this.dashboard?.specialties?.length &&
        !globallyEnabledNotLinked.length
      ) {
        specialties = this.dashboard.specialties;
      } else if (
        !this.dashboard?.specialties?.length &&
        globallyEnabledNotLinked.length
      )
        specialties = globallyEnabledNotLinked;
      else {
        specialties = [
          ...this.dashboard.specialties,
          ...globallyEnabledNotLinked,
        ];
      }
      return specialties.slice(0, 2);
    },
  },
  methods: {
    // isModuleEnabled is general (on the app level)
    isSpecialtyEnabledAndActive(specialty) {
      return (
        specialty.active === undefined &&
        specialty?.specialtyModule?.isModuleEnabled === true
      );
    },
    async fetchPatientReferrals() {
      try {
        let includeParams = [
          "referringDoctor",
          "patient",
          "cancelled",
          "clinic",
        ];

        const res = await fetchPatientReferrals({
          params: {
            ...this.reqPagination,
            idClinic: this.$route.params.idClinic,
            include: includeParams,
            stage: "REQUESTED",
          },
        });
        this.primaryCareNewReferrals = res.data.pagination.quantity;
      } catch (err) {
        console.error(err);
      }
    },
  },
  created() {
    this.fetchPatientReferrals();
  },
});
</script>

<style scoped lang="scss">
.title--md {
  margin-bottom: 60px;
}
.box-links {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 71px;
  & > * {
    flex: 1;
    &:not(:last-child) {
      margin-right: 35px;
    }
  }
}
</style>
