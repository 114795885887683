<template lang="pug">
section.step-subtitle
  h2 REFERRAL INFO
    span.lighter for 
    span.blue(v-if="patient") {{ getUserFormatted(patient) }}
section.appointment-info
  .appt-details-title
    .cols
      h2 PROVIDER: 
        span(v-if="providerGroupProvider") {{ getUserFormatted(providerGroupProvider) }}, {{ providerGroupProvider.referral_module_name }}


  section.gi(v-if="questions")
    .title.title--md-thin REFERRAL INFO QUESTIONS
    .qa-radios
      .qa-radios__row(v-for="question in activeQuestions" :key="question.id")
        .qa-radios__qa(:class="checkNotes(question)")
          .q.title.title--md-thin(v-if="question.type == 'SECTION_TITLE'") {{ question.name }}
          .q(v-else) {{ question.name }} 
            span(v-if="question.required") *
          .a(v-if="question.type == 'RADIO' ")
            inputValidateRadio(
              :key="question.id" 
              :value="question" 
              @emitAnswer="processAnswer" 
              :default="questionsAnsweredLoad[question.id]"
            )

          .a.flex-end(v-if="question.type == 'DROPDOWN' ")
            inputValidateDropdown(
              :key="question.id" 
              :value="question"
              @emitAnswer="processAnswer"  
              :default="questionsAnsweredLoad[question.id]"
            ) 
          .a(v-if="question.type == 'DATE' ")
            inputValidateDate(
              :key="question.id" 
              :value="question" 
              @emitAnswer="processAnswer"  
              :default="questionsAnsweredLoad[question.id]"
            )
          .a(v-if="question.type == 'TEXT' ")
            inputValidateText(
              :key="question.id" 
              :value="question" 
              @emitAnswer="processAnswer"  
              :default="questionsAnsweredLoad[question.id]"
            )
          .a(v-if="question.type == 'NUMERIC' ")
            inputValidateNumeric(
              :key="question.id" 
              :value="question" 
              @emitAnswer="processAnswer"  
              :default="questionsAnsweredLoad[question.id]"
            )
            
          .a(v-if="question.type == 'FILE' ")
            UploadAndPreviewFile(
              :buttonStyleClasses="['btn', 'lg', 'blue', 'btn_gi_match_width']"
              :storageKey="'file_for_question_' + question.id"
              @fileLoaded="forceReRender"
            )
          .a(v-if="question.type == 'NOTES'")
            inputValidateNotes(
              :key="question.id" 
              :value="question" 
              @emitAnswer="processAnswer"  
              :default="questionsAnsweredLoad[question.id]"
            )

  .form-group(:class="{ 'form-group__invalid': v$.communityCareNotes.$errors.length }")
    label Community Care Notes (Optional)
    textarea(style="height: 250px; width: 100%;" v-model="communityCareNotes" @blur="v$.communityCareNotes.$touch()" )
    .form-group__errors(v-if="v$.communityCareNotes.$dirty && v$.communityCareNotes.$errors.length")
      .form-group__error(v-if="v$.communityCareNotes.$invalid") Community care notes are required!


.actions-below-table-container
  .actions-below-table-container
  BaseBtn.lg.green-outline(
    @click.prevent="goToPreviousStep",
    style="min-width: 270px",
  ) &lt; PREVIOUS STEP
  BaseBtn.lg.green(
    @click.prevent="goToConfirm",
    style="min-width: 270px",
    :disabled="v$.$invalid"
  ) CONTINUE

</template>

<script>
import { defineComponent } from "vue";
import moment from "moment";
import {
  getDateFromUnixFormatted,
  getTimeFromUnixFormatted,
  getUserFormatted,
  getDoctorFormatted,
  getPhoneFormatted,
} from "@/helpers";
import { fetchInsurances } from "@/api/insuranceApi";

import { fetchPatient } from "@/api/patientApi";

import { apiStatus, apiStatusComputedFactory } from "@/api";
import { mapGetters } from "vuex";
const { IDLE, PENDING, SUCCESS, ERROR } = apiStatus;

import useVuelidate from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import { maska } from "maska";

import inputValidateRadio from "@/components/clinics/scheduling/inputValidate/inputValidateRadio.vue";
import inputValidateDropdown from "@/components/clinics/scheduling/inputValidate/inputValidateDropdown.vue";
import inputValidateDate from "@/components/clinics/scheduling/inputValidate/inputValidateDate.vue";
import inputValidateText from "@/components/clinics/scheduling/inputValidate/inputValidateText.vue";
import inputValidateNumeric from "@/components/clinics/scheduling/inputValidate/inputValidateNumeric.vue";
import inputValidateNotes from "@/components/clinics/scheduling/inputValidate/inputValidateNotes.vue";
import { fetchPatientReferralById } from "@/api/patientReferral";
import { getProviderGroupProvider } from "@/api/providerGroupApi";
import { fetchReferralModule } from "@/api/referralModulesApi";
import UploadAndPreviewFile from "@/components/UploadAndPreviewFile";

export default defineComponent({
  directives: { maska },
  validations() {
    let args = {
      communityCareNotes: {},
    };
    return args;
  },
  components: {
    UploadAndPreviewFile,
    inputValidateRadio,
    inputValidateDropdown,
    inputValidateDate,
    inputValidateText,
    inputValidateNumeric,
    inputValidateNotes,
  },
  setup() {
    return { v$: useVuelidate() };
  },

  data() {
    return {
      insurancesFound: null,
      fetchPatientReferralByIdStatus: IDLE,
      communityCareNotes: null,
      fetchPatientStatus: IDLE,
      fetchPatientAppointmentAvailableScheduleByIdStatus: IDLE,
      fetchGiStatus: IDLE,
      patient: null,
      providerGroupProvider: null,
      doctors: null,
      questions: [],
      questionsAnswered: [],
      questionsAnsweredLoad: [],
      isClinicReporRequired: false,
      patientInfoQuestions: [],
      forcedReRenderTimes: 0,
      requireCommunityNotes: false,
    };
  },

  computed: {
    ...mapGetters(["getUserRoleContext"]),
    ...apiStatusComputedFactory(
      "fetchPatientStatus",
      "fetchPatientAppointmentAvailableScheduleByIdStatus",
      "fetchClinicStatus",
      "fetchGiStatus"
    ),

    activeQuestions() {
      return this.questions.filter((question) => question.shouldDisplay);
    },
  },

  methods: {
    async fetchPatientReferralById(referralId) {
      this.fetchPatientReferralByIdStatus = PENDING;
      try {
        const res = await fetchPatientReferralById(referralId, {
          params: {
            include: ["communityCare", "patient"],
          },
        });
        let referral = res.data.patientReferral;
        this.communityCareNotes = referral.communityCareNotes;

        referral.patientInfoQuestions.forEach((element) => {
          this.questionsAnsweredLoad[element.id] = element.value;
        });

        this.fetchPatientReferralByIdStatus = SUCCESS;
      } catch (err) {
        this.fetchPatientReferralByIdStatus = ERROR;
        console.error(err);
      }
    },
    forceReRender() {
      this.forcedReRenderTimes++;
    },
    getUserFormatted,
    getDateFromUnixFormatted,
    getTimeFromUnixFormatted,
    getDoctorFormatted,
    insuranceKeyUp() {
      if (this.insuranceInformation.name != "") {
        this.fetchInsurances();
      } else {
        this.insurancesFound = null;
      }
    },
    async fetchInsurances() {
      this.fetchInsuranceStatus = PENDING;
      try {
        const { data } = await fetchInsurances({
          params: {
            filters: {
              searchBy: "insurance-name",
              insuranceName: this.insuranceInformation.name,
              disabled: false,
            },
            order: { by: "insurance-name", direction: "asc" },
          },
        });
        this.insurancesFound = null;
        this.insurancesFound = data.insurances;

        this.insurancesFound.forEach((element) => {
          element.nameHighlight = this.boldString(
            element.name,
            this.insuranceInformation.name
          );
        });
        this.fetchInsuranceStatus = SUCCESS;
      } catch (err) {
        this.fetchInsuranceStatus = ERROR;
        console.error(err);
      }
    },
    insuranceSelect(insurance) {
      this.insuranceInformation.name = insurance.name;
      this.insurancesFound = null;
    },
    boldString(str, find) {
      let reg = new RegExp("(" + find + ")", "gi");
      return str.replace(reg, "<b>$1</b>");
    },
    maxDateFuture: function (value) {
      let unixDistributed = new Date(value).getTime();
      unixDistributed = unixDistributed / 1000;

      let maxYear = moment().add(25, "years").unix();
      if (unixDistributed > maxYear) {
        return false;
      }
      return true;
    },
    maxDateAppointment: function (value) {
      let unixDistributed = new Date(value).getTime();
      if (!unixDistributed) {
        return false;
      }
      unixDistributed = unixDistributed / 1000;
      if (unixDistributed < this.schedule.availableAt) {
        return false;
      }
      return true;
    },
    checkNotes: function (answer) {
      if (answer.type == "NOTES") {
        return "isNotesRow";
      }
    },
    classRadio: function (option) {
      return "radio-custom--" + option.toLowerCase();
    },
    selectAnswer(answer, value) {
      let found = false;
      this.questionsAnswered.forEach((element) => {
        if (element.id == answer.id) {
          element.value = value;
          found = true;
        }
      });
      if (!found) {
        let newAnswerObject = {};
        newAnswerObject.type = answer.type;
        newAnswerObject.value = value;
        newAnswerObject.id = answer.id;
        newAnswerObject.name = answer.name;
        this.questionsAnswered.push(newAnswerObject);
      }

      this.checkDependenciesFor(answer, value);
    },
    checkDependenciesFor(answer, value) {
      this.questions.forEach((element) => {
        if (element.hasDependencies && element.rules) {
          element.rules.forEach((rule) => {
            if (rule.fieldToCheck == answer.id) {
              if (rule.valueToCheck == value) {
                element.shouldDisplay =
                  rule.operatorToUse == "EQUAL" ? true : false;
              } else {
                element.shouldDisplay =
                  rule.operatorToUse == "EQUAL" ? false : true;
              }
              // parsing all existing answers
              this.questionsAnswered.forEach(
                (questionsAnswer, index, object) => {
                  //check if we found an answer that had a dependency
                  if (element.id == questionsAnswer.id) {
                    //check if the dependency is met or not
                    if (!element.shouldDisplay) {
                      element.value = null;
                      if (this.questionsAnsweredLoad) {
                        this.questionsAnsweredLoad[element.id] = null;
                      }
                      this.checkDependenciesFor(element, null);
                      // if its  not met, then remove the answer from existing answers
                      object.splice(index, 1);
                    }
                  }
                }
              );
            }
          });
        }
      });
    },
    goToConfirm() {
      let params = {
        idPatient: this.$route.params.idPatient,
        idProvider: this.$route.params.idProvider,
        patient: JSON.stringify(this.patient),
        provider: JSON.stringify(this.providerGroupProvider),
        idCco: this.$route.params.idCco,
        questionsAnswered: JSON.stringify(this.questionsAnswered),
        questionFiles: JSON.stringify(this.getQuestionFiles()),
      };

      const parseAnswers = JSON.parse(params.questionsAnswered);
      parseAnswers.forEach((element) => {
        if (element.type === "DROPDOWN") {
          this.activeQuestions.forEach((question) => {
            if (question.type === "DROPDOWN") {
              question.options.forEach((option) => {
                if (option.id === element.value) {
                  element.value = option.name;
                }
              });
            }
          });
        }
      });
      params.questionsAnswered = JSON.stringify(parseAnswers);

      if (this.communityCareNotes) {
        params.communityCareNotes = this.communityCareNotes;
      }
      this.$router.push({
        name: "cco-referrals.scheduling.confirm",
        params,
      });
    },
    goToPreviousStep() {
      //navigate
      this.$router.push({
        name: "cco-referrals.scheduling.select-provider",
        query: this.$route.query,
        params: {
          idPatient: this.$route.params.idPatient,
          idProvider: this.$route.params.idProvider,
        },
      });
    },
    async fetchPatient() {
      this.fetchPatientStatus = PENDING;
      try {
        const res = await fetchPatient(this.$route.params.idPatient);
        this.patient = res.data.patient;
        this.fetchPatientStatus = SUCCESS;
      } catch (err) {
        this.fetchPatientStatus = ERROR;
        console.error(err);
      }
    },
    async fetchProviderGroupProvider() {
      try {
        const res = await getProviderGroupProvider(
          this.$route.params.idProvider
        );
        this.providerGroupProvider = res.data.provider[0];
      } catch (error) {
        console.error(error);
      }
    },

    async fetchGi() {
      this.fetchGiStatus = PENDING;
      try {
        const res = await fetchReferralModule(
          this.providerGroupProvider.referral_module_id
        );
        let tempQuestions =
          res.data.referralModule?.schemaRules?.referralInfoQuestions?.fields;
        this.referralInfoQuestions = tempQuestions;

        this.questions = [];
        tempQuestions.forEach((element) => {
          if (element.active) {
            element.shouldDisplay = true;
            if (element.hasDependencies && element.rules) {
              element.shouldDisplay = false;
            }
            this.questions.push(element);
          }
        });

        this.fetchGiStatus = SUCCESS;
      } catch (err) {
        this.fetchGiStatus = ERROR;
        console.error(err);
      }
    },
    getQuestionFiles() {
      return this.activeQuestions
        .filter((question) => {
          const questionIsOfTypeFile = question.type === "FILE";
          const questionHasAFileInNavigatorStorage = sessionStorage.getItem(
            "file_for_question_" + question.id
          );

          return questionIsOfTypeFile && questionHasAFileInNavigatorStorage;
        })
        .map((question) => ({
          id: question.id,
          type: question.type,
          storageKey: "file_for_question_" + question.id,
        }));
    },
    processAnswer(value, eventTarget) {
      this.selectAnswer(value, eventTarget);
      this.questionsAnsweredLoad[value.id] = eventTarget;

      // if (value.type === "DROPDOWN") {
      //   let dropdownAnswer = value.options.find(
      //     (option) => option.id === eventTarget
      //   ).name;
      //   // this.questionsAnsweredLoad[value.id] = dropdownAnswer;
      // }
    },
  },
  created() {
    if (this.$route.params.idPatient === null) return;
    this.fetchPatient();
    this.fetchProviderGroupProvider().then(() => {
      this.fetchGi().then(() => {
        if (this.$route.params.questionsAnswered) {
          let questionsAnsweredLoadTemp = JSON.parse(
            this.$route.params.questionsAnswered
          );
          questionsAnsweredLoadTemp.forEach((element) => {
            this.questionsAnsweredLoad[element.id] = element.value;
            this.selectAnswer(element, element.value);
          });
        }
      });
    });
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/components/qa-radios.scss";
@import "@/scss/components/actions-below-table-container.scss";
@import "@/scss/components/step-subtitle.scss";

.appointment-info {
  width: 100%;
  padding: 0 $secondaryCardsSidePadding;
}

.appt-details-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px $primaryBgDarkGrey;
  margin-top: 56px;
  margin-bottom: 26px;
  padding-bottom: 21px;

  h1 {
    margin: 0;
    font-size: 36px;
    font-weight: 700;
    line-height: 1.22;
    letter-spacing: 0.18px;
  }

  .time-place {
    font-size: 18px;
    line-height: 1.22;
    letter-spacing: 0.1px;

    .place {
      color: $primaryDarkGrey;
    }
  }
}

.cols {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .col {
    width: math.percentage(math.div(345, 1108));
    margin-right: 36px;
    flex-grow: 0;
    flex-shrink: 0;

    &:last-child {
      margin-right: auto;
    }
  }
}

.title--md-thin {
  margin-bottom: 26px;
}

.eci {
  margin-bottom: 50px;

  .col {
    &:nth-child(3) {
      width: math.percentage(math.div(227, 1108));
    }

    &:nth-child(4) {
      width: math.percentage(math.div(81, 1108));
      margin-right: 0;
    }
  }
}

.insurance {
  position: relative;
  padding: 59px 10px 46px;
  margin-bottom: 62px;

  &:before {
    content: "";
    position: absolute;
    left: -$secondaryCardsSidePadding;
    right: -$secondaryCardsSidePadding;
    top: 0;
    bottom: 0;
    background-color: $primaryBgBlue;
  }

  &__content {
    position: relative;
  }

  .insurance-status-wrapper {
    padding-bottom: 24px;
    border-bottom: solid 1px $primaryBgDarkGrey;
    margin-bottom: 21px;
  }

  .insurance-status {
    width: math.percentage(math.div(345, 1108));
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.29;
      letter-spacing: 0.07px;
    }

    .svg-icon {
      font-size: 50px;
      top: 0;
    }
  }

  .cols-2 {
    .col {
      &:last-child {
        width: 168px;

        .btn {
          width: 168px;
        }
      }
    }
  }
}

.gi {
  .qa-radios {
    margin-bottom: 35px;

    .q {
      max-width: 798px;
    }

    .a {
      width: 200px;
    }
  }
}

.gi {
  .qa-radios {
    margin-bottom: 35px;

    .q {
      max-width: 798px;
    }

    .a {
      width: 200px;
    }

    .a.flex-end {
      justify-content: flex-end;
    }
  }
}

.dropdown_input_wrapper {
  position: relative;
  width: 100%;
  height: 0px;
  margin: 0px;

  ul {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: block;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
    box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
    max-height: 300px;
    overflow: scroll;

    li {
      display: block;
      padding: 3px 20px;
      line-height: 1.42857143;
      white-space: nowrap;
      cursor: pointer;
    }

    li.active,
    li:hover {
      color: #fff;
      text-decoration: none;
      background-color: $primaryLightBlue;
      outline: 0;
    }
  }
}
</style>
