// @ts-nocheck
import api from "./api";
const URLS = {
  PROVIDER_GROUP: `/provider-group`,
};
export const fetchProviderGroups = (data = {}) => {
  return api.get(URLS.PROVIDER_GROUP, data);
};
export const fetchProviderGroup = (id, data = {}) => {
  return api.get(URLS.PROVIDER_GROUP + "/" + id, data);
};
export const createProviderGroup = (data = {}) => {
  return api.post(URLS.PROVIDER_GROUP, data);
};
export const updateProviderGroup = (idProviderGroup, data = {}) => {
  return api.put(URLS.PROVIDER_GROUP + "/" + idProviderGroup, data);
};

export const getProviderGroupReferral = (idProviderGroup, data = {}) => {
  return api.get(
    URLS.PROVIDER_GROUP + "/" + idProviderGroup + "/referral-module",
    data
  );
};

export const getProviderGroupProviders = (data = {}) => {
  return api.get(URLS.PROVIDER_GROUP + "/providers", data);
};

export const getProviderGroupProvider = (idProvider, data = {}) => {
  return api.get(URLS.PROVIDER_GROUP + "/providers/" + idProvider);
};

export const getProviderGroupIDforManager = () => {
  return api.get(URLS.PROVIDER_GROUP + "/id-for-manager");
};
