<template lang="pug">
section.clinic-health-systems
  TableTitle(title="Clinic Health Systems")
    template(#actions)
      BaseBtn.x-lg.blue(
        href="#",
        @click.prevent="openClinicHealthSystemsOverlay()"
      ) ADD NEW
  .table(v-if="clinicHealthSystems?.length")
    .th
      .td(@click.prevent="changeListOrder('CHSName', fetchCHSs)" style="cursor: pointer;")  CLINIC HEALTH SYSTEM NAME
        i(style="font-weight: bolder;") {{ CHSNameColumnSortingIcon }}
      .td
    .tr(v-for="chs in clinicHealthSystems", :key="chs.id")
      .td.bold {{chs.name}}
      .td
        BaseBtn.sm.blue-outline(
          @click.prevent="selectCHS(chs)"
          style="margin-right: 53px;"
        ) SELECT
        BaseBtn.sm.blue(
          href="#",
          @click.prevent="openClinicHealthSystemsOverlay(chs.id)"
        ) MANAGE
  Pagination(
    v-if="pagination",
    @changePagination="changePagination",
    :pagination="pagination",
    :reloadFn="fetchCHSs"
  )

  ClinicHealthSystemsOverlay(
    :key="`${componentKey}-1`"
    :chsId="chsId"
    :isOpen="isClinicHealthSystemsOverlayOpen",
    @closeClinicHealthSystemsOverlay="closeClinicHealthSystemsOverlay",
    @reloadCHSs="fetchCHSs"
  )
</template>

<script>
import { defineComponent } from "vue";
import TableTitle from "@/components/TableTitle.vue";
import Pagination from "@/components/Pagination.vue";
import ClinicHealthSystemsOverlay from "@/components/organizations/overlays/ClinicHealthSystemsOverlay.vue";
import listPagesMixin from "@/mixins/listPagesMixin";

import { fetchCHSs } from "@/api/chsApi";
import { apiStatus, apiStatusComputedFactory } from "@/api";
import { mapActions } from "vuex";
const { IDLE, PENDING, SUCCESS, ERROR } = apiStatus;
import sortCHS from "@/mixins/sortCHS";

export default defineComponent({
  components: {
    TableTitle,
    Pagination,
    ClinicHealthSystemsOverlay,
  },
  mixins: [listPagesMixin, sortCHS],

  data() {
    return {
      fetchCHSsStatus: IDLE,
      clinicHealthSystems: null,
      chsId: null,
      isClinicHealthSystemsOverlayOpen: false,
    };
  },
  computed: {
    ...apiStatusComputedFactory("fetchCHSsStatus"),
  },

  methods: {
    ...mapActions(["impersonateClinicHealthSystemManager"]),
    async fetchCHSs() {
      this.fetchCHSsStatus = PENDING;

      const order = this.listOrder
        ? {
            by: this.listOrder.by,
            direction: this.listOrder.direction,
          }
        : null;

      try {
        const res = await fetchCHSs({
          params: {
            ...this.reqPagination,
            order,
          },
        });
        this.clinicHealthSystems = res.data.clinicHealthSystems;
        this.pagination = res.data.pagination;
        this.fetchCHSsStatus = SUCCESS;
      } catch (err) {
        this.fetchCHSsStatus = ERROR;
        console.error(err);
      }
    },
    openClinicHealthSystemsOverlay(chsId) {
      this.forceRerender();
      this.chsId = chsId ?? null;
      this.isClinicHealthSystemsOverlayOpen = true;
    },
    closeClinicHealthSystemsOverlay() {
      this.isClinicHealthSystemsOverlayOpen = false;
    },
    selectCHS(clinicHealthSystem) {
      this.impersonateClinicHealthSystemManager([clinicHealthSystem]);
      this.$router.push({
        name: "all-clinics",
        params: { chsId: clinicHealthSystem.id },
      });
    },
  },

  created() {
    this.fetchCHSs();
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/components/step-subtitle.scss";
.clinic-health-systems {
  .td {
    &:nth-child(1) {
      width: math.percentage(math.div(397, $tabContentCardWidth));
    }
    &:nth-child(2) {
      width: math.percentage(math.div(781, $tabContentCardWidth));
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .btn {
        flex-grow: none;
        max-width: 190px;
      }
    }
    &:nth-child(3) {
      width: math.percentage(math.div(222, $tabContentCardWidth));
    }
  }
}
.table .tr .td:not(:last-child):not(.bold) {
  padding-right: 10px;
  padding-left: 10px;
}
.table .td .btn {
  width: 100%;
}
.table .tr > .td {
  display: flex;
  align-items: center;
}
</style>
