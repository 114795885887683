<template lang="pug">
.content-wrapper
  BasePageTitle(
    pageTitleIcon="icc-logo",
    title="ORGANIZATIONS"
  )
    template(#breadcrumbs)
      li(v-if="!isProviderManager")
        router-link(:to="{name: 'organizations.community-organizations'}") Community Organizations
      li(v-if="!isProviderManager")
        router-link(:to="{name: 'organizations.clinic-health-systems'}") Clinical Health Systems
      li(v-if="!isProviderManager")
        router-link(:to="{name: 'organizations.hospitals'}") Hospitals
      li
        router-link(:to="{name: 'organizations.provider-groups'}") Provider Groups
  .card.router-view-card
    router-view()
</template>

<script>
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

export default defineComponent({
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["isProviderManager"]),
  },

  methods: {},
});
</script>

<style scoped lang="scss">
.content-wrapper {
  position: relative;
  padding-bottom: 40px;
}
.router-view-card {
  padding-top: 0;
  position: relative;
  width: 100%;
  z-index: 1;
}
</style>
