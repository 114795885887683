<template lang="pug">
section.primary-care.dashboard-standard-section
  h3.title.title--md Primary Care
  .status-line
    h4.title.title--sm Appointment Status
  .box-links-sm
    router-link(:to="{name: 'primary-care-clinic.patient-referrals.requested', params: {idClinic: clinicId}}")
      BoxLinkSm(text="Requested", :number="specialty?.referralStatus?.requested ?? 0")
    router-link(:to="{name: 'primary-care-clinic.patient-referrals.confirmed', params: {idClinic: clinicId}}")
      BoxLinkSm(text="Confirmed", :number="specialty?.referralStatus?.confirmed ?? 0")
    router-link(:to="{name: 'primary-care-clinic.patient-referrals.no-show-cancelled', params: {idClinic: clinicId}}")
      BoxLinkSm(text="No Show / Cancelled", :number="specialty?.referralStatus?.noShowOrCancelled ?? 0")
    router-link(:to="{name: 'primary-care-clinic.patient-referrals.archived', params: {idClinic: clinicId}}")
      BoxLinkSm(text="Archived", :number="specialty?.referralStatus?.archives ?? 0")
  .line-graph-wrapper
    h4.title.title--sm Last 30 Days
    LineStats(:stats="stats")
</template>

<script>
import { defineComponent } from "vue";
import BoxLinkSm from "@/components/BoxLinkSm.vue";
import LineStats from "@/components/LineStats.vue";

export default defineComponent({
  components: { BoxLinkSm, LineStats },

  props: {
    clinicId: {
      type: Number,
      required: true,
    },
    specialty: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      stats: [
        {
          label: "Completed",
          number: this.specialty?.iccStats?.completed ?? 0,
          backgroundColor: "#96bf3c",
        },
        {
          label: "Cancelled",
          number: this.specialty?.iccStats?.cancelled ?? 0,
          backgroundColor: "#d5351d",
        },
        {
          label: "No Show",
          number: this.specialty?.iccStats?.noShow ?? 0,
          backgroundColor: "#6d6262",
        },
      ],
    };
  },
});
</script>
