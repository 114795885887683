<template lang="pug">
section.step-subtitle
  h2 REFERRAL INFO
   span.lighter for 
   span.blue(v-if="patient") {{ getUserFormatted(patient) }}
section.appointment-info
  .appt-details-title
    .cols
      .col(v-if="schedule") {{ getDateFromUnixFormatted(schedule.availableAt) }} {{ getTimeFromUnixFormatted(schedule.availableAt) }} 
      .col(v-if="clinic") {{ clinic.name }}
  section.eci
    .title.title--md-thin EMERGENCY CONTACT INFORMATION
    .cols
      .col
        .form-group(:class="{ 'form-group__invalid': v$.emergencyContactInformation.firstName.$errors.length }")
          label(for="first-name") First Name (Required)
          input#first-name(
            type="text" 
            v-model="emergencyContactInformation.firstName"
            @blur="v$.emergencyContactInformation.firstName.$touch()"
          )
          .form-group__errors(v-if="v$.emergencyContactInformation.firstName.$dirty && v$.emergencyContactInformation.firstName.$errors.length")
            .form-group__error(v-if="v$.emergencyContactInformation.firstName.required.$invalid") First name is required!
      .col
        .form-group(:class="{ 'form-group__invalid': v$.emergencyContactInformation.lastName.$errors.length }")        
          label(for="last-name") Last Name (Required)
          input#last-name(
            type="text" 
            v-model="emergencyContactInformation.lastName"
            @blur="v$.emergencyContactInformation.lastName.$touch()"
          )
          .form-group__errors(v-if="v$.emergencyContactInformation.lastName.$dirty && v$.emergencyContactInformation.lastName.$errors.length")
            .form-group__error(v-if="v$.emergencyContactInformation.lastName.required.$invalid") Last name is required!
      .col
        .form-group(:class="{ 'form-group__invalid': v$.emergencyContactInformation.phoneNumber.$errors.length }")
          label(for="phone-no") Phone Number (Required)
          input#phone-no(
            type="text", 
            placeholder="xxx-xxx-xxxx" 
            v-model="emergencyContactInformation.phoneNumber"
            @blur="v$.emergencyContactInformation.phoneNumber.$touch()"
            v-maska="'###-###-####'"
            @maska="phoneNumberRawValue"
          )
          .form-group__errors(v-if="v$.emergencyContactInformation.phoneNumber.$dirty && v$.emergencyContactInformation.phoneNumber.$errors.length")
            .form-group__error(v-if="v$.emergencyContactInformation.phoneNumber.required.$invalid") Phone number is required!
            .form-group__error(v-if="v$.emergencyContactInformation.phoneNumber.minLength.$invalid") Phone number is required!
      .col
        .form-group
          label(for="ext") Ext.
          input#ext(type="text", placeholder="" v-model="emergencyContactInformation.phoneExtension")

  section.insurance(v-if="schedule && schedule.insuranceRequired")
    .insurance__content
      .insurance-status-wrapper
        .insurance-status
          p Does patient have insurance?
          base-icon(name="yes")
      .title.title--md-thin Insurance Information
      .cols.cols-1
        .col
          .form-group
            label(for="insurance-no") Insurance Name
            input#insurance-no(
              type="text", 
              placeholder="" 
              v-model="insuranceInformation.name"
              @keyup='insuranceKeyUp'
            )
            div.dropdown_input_wrapper(v-if="insurancesFound && insurancesFound.length")
              ul
                li(
                  v-for="(insurance, index) in insurancesFound",
                  :key="insurance.id",  
                  :class="{ 'active': index === 0}"
                  @click="insuranceSelect(insurance)"
                )
                  span(v-html="insurance.nameHighlight")
        .col
          .form-group
            label(for="policy-no") Policy Number
            input#policy-no(
              type="text", 
              placeholder="" 
              v-model="insuranceInformation.policyNumber"
            )
      .cols.cols-2
        .col
          .form-group
            label(for="exp-date") Expiration Date
            input#exp-date(
              type="text" 
              v-model="insuranceInformation.expirationDate"
              placeholder="MM/DD/YYYY"
              v-maska="'##/##/####'"
            )
        .col
          .form-group
            label(for="group-no") Group Number
            input#group-no(
              type="text", 
              placeholder="" 
              v-model="insuranceInformation.groupNumber"
            )
        .col
          .form-group
            label Upload Insurance Card
            UploadAndPreviewFile(
              :buttonStyleClasses="['btn', 'lg', 'blue']"
              :storageKey="insuranceCardStorageKey"
            )
  section.gi(v-if="questions")
    .title.title--md-thin {{ specialtyModuleRes?.schemaRules?.patientInfoConfiguration?.referralInfoTitle || "GI QUESTIONS" }}
    .qa-radios
      .qa-radios__row(v-for="question in activeQuestions" :key="question.id")
        .qa-radios__qa(:class="checkNotes(question)")
          .q.title.title--md-thin(v-if="question.type == 'SECTION_TITLE'") {{ question.name }}
          .q(v-else) {{ question.name }} 
            span(v-if="question.required") *
          .a(v-if="question.type == 'RADIO' ")
            inputValidateRadio(
              :key="question.id" 
              :value="question" 
              @emitAnswer="processAnswer" 
              :default="questionsAnsweredLoad[question.id]"
            )

          .a.flex-end(v-if="question.type == 'DROPDOWN' ")
            inputValidateDropdown(
              :key="question.id" 
              :value="question"
              @emitAnswer="processAnswer"  
              :default="questionsAnsweredLoad[question.id]"
            ) 

          .a(v-if="question.type == 'DATE' ")
            inputValidateDate(
              :key="question.id" 
              :value="question" 
              @emitAnswer="processAnswer"  
              :default="questionsAnsweredLoad[question.id]"
            )
          .a(v-if="question.type == 'TEXT' || question.type == 'DESCRIPTION' ")
            inputValidateText(
              :key="question.id" 
              :value="question" 
              @emitAnswer="processAnswer"  
              :default="questionsAnsweredLoad[question.id]"
            )
          .a(v-if="question.type == 'NUMERIC' ")
            inputValidateNumeric(
              :key="question.id" 
              :value="question" 
              @emitAnswer="processAnswer"  
              :default="questionsAnsweredLoad[question.id]"
            )
            
          .a(v-if="question.type == 'FILE' ")
            UploadAndPreviewFile(
              :buttonStyleClasses="['btn', 'lg', 'blue']"
              :storageKey="'file_for_question_' + question.id"
              @fileLoaded="forceReRender"
            )
          .a(v-if="question.type == 'NOTES'")
            inputValidateNotes(
              :key="question.id" 
              :value="question" 
              @emitAnswer="processAnswer"  
              :default="questionsAnsweredLoad[question.id]"
            )
    .form-group(:class="{ 'form-group__invalid': v$.communityCareNotes.$errors.length }")
      label Community Care Notes
      textarea(style="height: 250px; width: 100%;" v-model="communityCareNotes" @blur="v$.communityCareNotes.$touch()" )
      .form-group__errors(v-if="v$.communityCareNotes.$dirty && v$.communityCareNotes.$errors.length")
        .form-group__error(v-if="v$.communityCareNotes.$invalid") Community care notes are required!
    .form-group
      label Upload Community Report 
        span(v-if=" isClinicReporRequired ") (Required)
      UploadAndPreviewFile(
        :buttonStyleClasses="['btn', 'lg', 'blue']"
        :storageKey="clinicReportStorageKey"
        @fileLoaded="forceReRender"
      )

.actions-below-table-container
    .actions-below-table-container
    BaseBtn.lg.green-outline(
      @click.prevent="goToPreviousStep",
      style="min-width: 270px",
    ) &lt; PREVIOUS STEP
    BaseBtn.lg.green(
      @click.prevent="goToConfirm",
      style="min-width: 270px",
      :disabled="v$.$invalid || !requiredFilesAreUploaded"
    ) CONTINUE

</template>

<script>
import { defineComponent } from "vue";
import moment from "moment";
import {
  getDateFromUnixFormatted,
  getTimeFromUnixFormatted,
  getUserFormatted,
  getDoctorFormatted,
  getPhoneFormatted,
} from "@/helpers";
import { fetchInsurances } from "@/api/insuranceApi";

import { fetchPatient } from "@/api/patientApi";
import { fetchPatientAppointmentAvailableScheduleById } from "@/api/patientAppointmentApi";
import { fetchClinic } from "@/api/clinicApi";

import { fetchCHSDoctors } from "@/api/chsApi";
import UploadAndPreviewFile from "@/components/UploadAndPreviewFile";

import { apiStatus, apiStatusComputedFactory } from "@/api";
import { mapGetters } from "vuex";
const { IDLE, PENDING, SUCCESS, ERROR } = apiStatus;

import useVuelidate from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import { maska } from "maska";

import inputValidateRadio from "@/components/clinics/scheduling/inputValidate/inputValidateRadio.vue";
import inputValidateDropdown from "@/components/clinics/scheduling/inputValidate/inputValidateDropdown.vue";
import inputValidateDate from "@/components/clinics/scheduling/inputValidate/inputValidateDate.vue";
import inputValidateText from "@/components/clinics/scheduling/inputValidate/inputValidateText.vue";
import inputValidateNumeric from "@/components/clinics/scheduling/inputValidate/inputValidateNumeric.vue";
import inputValidateNotes from "@/components/clinics/scheduling/inputValidate/inputValidateNotes.vue";
import { fetchPatientReferralById } from "@/api/patientReferral";

import { fetchPrimaryCareModule } from "@/api/primaryCareModule";

export default defineComponent({
  directives: { maska },
  validations() {
    let args = {
      emergencyContactInformation: {
        firstName: { required },
        lastName: { required },
        phoneNumber: { required, minLength: minLength(12) },
      },
      communityCareNotes: {},
    };
    if (this.requireCommunityNotes) {
      args.communityCareNotes = { required };
    }
    return args;
  },
  components: {
    UploadAndPreviewFile,
    inputValidateRadio,
    inputValidateDropdown,
    inputValidateDate,
    inputValidateText,
    inputValidateNumeric,
    inputValidateNotes,
  },
  setup() {
    return { v$: useVuelidate() };
  },

  data() {
    return {
      insurancesFound: null,
      fetchInsuranceStatus: IDLE,
      fetchPatientReferralByIdStatus: IDLE,
      communityCareNotes: null,
      idClinicHealthSystem: null,
      hidePolypsFoundSelectLabel: false,
      fetchPatientStatus: IDLE,
      fetchPatientAppointmentAvailableScheduleByIdStatus: IDLE,
      fetchClinicStatus: IDLE,
      fetchCHSDoctorsStatus: IDLE,
      fetchGiStatus: IDLE,
      patient: null,
      schedule: null,
      clinic: null,
      doctors: null,
      questions: [],
      emergencyContactInformation: {
        firstName: null,
        lastName: null,
        phoneNumber: null,
        phoneNumberRaw: null,
        phoneExtension: null,
      },
      insuranceInformation: {
        name: null,
        policyNumber: null,
        expirationDate: null,
        groupNumber: null,
      },
      questionsAnswered: [],
      // referringDoctor: "",
      questionsAnsweredLoad: [],
      isClinicReporRequired: false,
      patientInfoQuestions: [],
      insuranceCardStorageKey: "schedule_appt_upload_insurance_card",
      clinicReportStorageKey: "schedule_appt_clinic_report",
      forcedReRenderTimes: 0,
      requireCommunityNotes: false,
      specialtyModuleRes: null,
    };
  },

  computed: {
    ...mapGetters(["getUserRoleContext", "getSpecialtyById"]),
    ...apiStatusComputedFactory(
      "fetchPatientStatus",
      "fetchPatientAppointmentAvailableScheduleByIdStatus",
      "fetchClinicStatus",
      "fetchGiStatus"
    ),

    activeQuestions() {
      return this.questions.filter((question) => question.shouldDisplay);
    },

    requiredFilesAreUploaded() {
      this.forcedReRenderTimes;

      //TODO: if these files are to be seen again on save & exit,
      // we must consider a patientInfoQuestion having src or url prop,
      // As an " this file is already uploaded" sign
      const allQuestionFilesUploaded = this.patientInfoQuestions
        .filter((field) => field.type === "FILE" && field.required)
        .every((field) => {
          return sessionStorage.getItem("file_for_question_" + field.id);
        });

      if (this.isClinicReporRequired) {
        const file = sessionStorage.getItem(this.clinicReportStorageKey);
        return allQuestionFilesUploaded && !!file;
      }

      return allQuestionFilesUploaded;
    },
  },

  methods: {
    async fetchPatientReferralById(referralId) {
      this.fetchPatientReferralByIdStatus = PENDING;
      try {
        const res = await fetchPatientReferralById(referralId, {
          params: {
            include: [
              "communityReport",
              "clinicReport",
              "emergencyContact",
              "insurance",
              "cancelled",
              "clinicDoctor",
              "referringDoctor",
              "clinic",
              "communityCare",
              "patient",
            ],
          },
        });
        let referral = res.data.patientReferral;
        this.emergencyContactInformation = referral.emergencyContact;
        this.emergencyContactInformation.phoneNumber = getPhoneFormatted(
          this.emergencyContactInformation.phoneNumber
        );
        this.communityCareNotes = referral.communityCareNotes;
        this.insuranceInformation = referral.insurance;

        referral.patientInfoQuestions.forEach((element) => {
          this.questionsAnsweredLoad[element.id] = element.value;
        });

        this.fetchPatientReferralByIdStatus = SUCCESS;
      } catch (err) {
        this.fetchPatientReferralByIdStatus = ERROR;
        console.error(err);
      }
    },
    forceReRender() {
      this.forcedReRenderTimes++;
    },
    getUserFormatted,
    getDateFromUnixFormatted,
    getTimeFromUnixFormatted,
    getDoctorFormatted,
    insuranceKeyUp() {
      if (this.insuranceInformation.name != "") {
        this.fetchInsurances();
      } else {
        this.insurancesFound = null;
      }
    },
    async fetchInsurances() {
      this.fetchInsuranceStatus = PENDING;
      try {
        const { data } = await fetchInsurances({
          params: {
            filters: {
              searchBy: "insurance-name",
              insuranceName: this.insuranceInformation.name,
              disabled: false,
            },
            order: { by: "insurance-name", direction: "asc" },
          },
        });
        this.insurancesFound = null;
        this.insurancesFound = data.insurances;

        this.insurancesFound.forEach((element) => {
          element.nameHighlight = this.boldString(
            element.name,
            this.insuranceInformation.name
          );
        });
        this.fetchInsuranceStatus = SUCCESS;
      } catch (err) {
        this.fetchInsuranceStatus = ERROR;
        console.error(err);
      }
    },
    insuranceSelect(insurance) {
      this.insuranceInformation.name = insurance.name;
      this.insurancesFound = null;
    },
    boldString(str, find) {
      let reg = new RegExp("(" + find + ")", "gi");
      return str.replace(reg, "<b>$1</b>");
    },
    maxDateFuture: function (value) {
      let unixDistributed = new Date(value).getTime();
      unixDistributed = unixDistributed / 1000;

      let maxYear = moment().add(25, "years").unix();
      if (unixDistributed > maxYear) {
        return false;
      }
      return true;
    },
    maxDateAppointment: function (value) {
      let unixDistributed = new Date(value).getTime();
      if (!unixDistributed) {
        return false;
      }
      unixDistributed = unixDistributed / 1000;
      if (unixDistributed < this.schedule.availableAt) {
        return false;
      }
      return true;
    },
    phoneNumberRawValue: function (event) {
      this.emergencyContactInformation.phoneNumberRaw =
        event.target.dataset.maskRawValue;
    },
    checkNotes: function (answer) {
      if (answer.type == "NOTES") {
        return "isNotesRow";
      }
    },
    classRadio: function (option) {
      return "radio-custom--" + option.toLowerCase();
    },
    selectAnswer(answer, value) {
      let found = false;
      this.questionsAnswered.forEach((element) => {
        if (element.id == answer.id) {
          element.value = value;
          found = true;
        }
      });
      if (!found) {
        let newAnswerObject = {};
        newAnswerObject.type = answer.type;
        newAnswerObject.value = value;
        newAnswerObject.id = answer.id;
        newAnswerObject.name = answer.name;
        this.questionsAnswered.push(newAnswerObject);
      }

      this.checkDependenciesFor(answer, value);
    },
    checkDependenciesFor(answer, value) {
      this.questions.forEach((element) => {
        if (element.hasDependencies && element.rules) {
          element.rules.forEach((rule) => {
            if (rule.fieldToCheck == answer.id) {
              if (rule.valueToCheck == value) {
                element.shouldDisplay =
                  rule.operatorToUse == "EQUAL" ? true : false;
              } else {
                element.shouldDisplay =
                  rule.operatorToUse == "EQUAL" ? false : true;
              }
              // parsing all existing answers
              this.questionsAnswered.forEach(
                (questionsAnswer, index, object) => {
                  //check if we found an answer that had a dependency
                  if (element.id == questionsAnswer.id) {
                    //check if the dependency is met or not
                    if (!element.shouldDisplay) {
                      element.value = null;
                      if (this.questionsAnsweredLoad) {
                        this.questionsAnsweredLoad[element.id] = null;
                      }
                      this.checkDependenciesFor(element, null);
                      // if its  not met, then remove the answer from existing answers
                      object.splice(index, 1);
                    }
                  }
                }
              );
            }
          });
        }
      });
    },
    goToConfirm() {
      const unixDate = moment(this.insuranceInformation.expirationDate).unix();
      this.insuranceInformation.expirationDate = unixDate;
      let params = {
        idPatient: this.$route.params.idPatient,
        idSchedule: this.$route.params.idSchedule,
        idClinic: this.$route.params.idClinic,
        patient: JSON.stringify(this.patient),
        clinic: JSON.stringify(this.clinic),
        schedule: JSON.stringify(this.schedule),
        emergencyContactInformation: JSON.stringify(
          this.emergencyContactInformation
        ),
        insuranceInformation: JSON.stringify(this.insuranceInformation),
        questionsAnswered: JSON.stringify(this.questionsAnswered),
        questionFiles: JSON.stringify(this.getQuestionFiles()),
      };

      if (this.communityCareNotes) {
        params.communityCareNotes = this.communityCareNotes;
      }
      if (this.$route.params.idFitKit) {
        params.idFitKit = this.$route.params.idFitKit;
      }
      this.$router.push({
        name: "primary-care-community-organization.scheduling.confirm",
        params,
      });
    },
    goToPreviousStep() {
      //clean up possible uploaded files to navigator storage
      sessionStorage.removeItem(this.insuranceCardStorageKey);
      sessionStorage.removeItem(this.clinicReportStorageKey);
      this.getQuestionFiles().map((questionFile) =>
        sessionStorage.removeItem(questionFile.storageKey)
      );

      //navigate
      this.$router.push({
        name: "primary-care-community-organization.scheduling.select-schedule",
        query: this.$route.query,
        params: {
          idPatient: this.$route.params.idPatient,
          idFitKit: this.$route.params.idFitKit,
          idClinic: this.$route.params.idClinic,
        },
      });
    },
    async fetchPatient() {
      this.fetchPatientStatus = PENDING;
      try {
        const res = await fetchPatient(this.$route.params.idPatient);
        this.patient = res.data.patient;
        this.fetchPatientStatus = SUCCESS;
      } catch (err) {
        this.fetchPatientStatus = ERROR;
        console.error(err);
      }
    },
    async fetchPatientAppointmentAvailableScheduleById() {
      this.fetchPatientAppointmentAvailableScheduleByIdStatus = PENDING;
      try {
        const res = await fetchPatientAppointmentAvailableScheduleById(
          this.$route.params.idSchedule
        );
        this.schedule = res.data.schedule;
        this.fetchPatientAppointmentAvailableScheduleByIdStatus = SUCCESS;
      } catch (err) {
        this.fetchPatientAppointmentAvailableScheduleByIdStatus = ERROR;
        console.error(err);
      }
    },
    async fetchClinic() {
      this.fetchClinicStatus = PENDING;
      try {
        const res = await fetchClinic(this.$route.params.idClinic);
        this.clinic = res.data;
        this.idClinicHealthSystem = this.clinic.idClinicHealthSystem;
        this.fetchClinicStatus = SUCCESS;
      } catch (err) {
        this.fetchClinicStatus = ERROR;
        console.error(err);
      }
    },
    async fetchCHSDoctors() {
      this.fetchCHSDoctorsStatus = PENDING;
      try {
        const res = await fetchCHSDoctors(this.idClinicHealthSystem);
        this.doctors = res.data.doctors;
        this.fetchCHSDoctorsStatus = SUCCESS;
      } catch (err) {
        this.fetchCHSDoctorsStatus = ERROR;
        console.error(err);
      }
    },
    async fetchGi() {
      this.fetchGiStatus = PENDING;
      try {
        const res = await fetchPrimaryCareModule();

        let tempQuestions =
          res.data.primaryCareModule?.schemaRules?.patientInfoQuestions?.fields;
        this.specialtyModuleRes = res.data.primaryCareModule;
        this.patientInfoQuestions = tempQuestions;

        this.isClinicReporRequired =
          !!res.data.primaryCareModule?.schemaRules?.patientInfoConfiguration
            ?.requireCommunityReport;

        this.requireCommunityNotes =
          !!res.data.primaryCareModule?.schemaRules?.patientInfoConfiguration
            ?.requireClinicNotes;
        this.questions = [];
        tempQuestions.forEach((element) => {
          if (element.active) {
            element.shouldDisplay = true;
            if (element.hasDependencies && element.rules) {
              element.shouldDisplay = false;
            }
            this.questions.push(element);
          }
        });

        this.fetchGiStatus = SUCCESS;
      } catch (err) {
        this.fetchGiStatus = ERROR;
        console.error(err);
      }
    },
    getQuestionFiles() {
      return this.activeQuestions
        .filter((question) => {
          const questionIsOfTypeFile = question.type === "FILE";
          const questionHasAFileInNavigatorStorage = sessionStorage.getItem(
            "file_for_question_" + question.id
          );

          return questionIsOfTypeFile && questionHasAFileInNavigatorStorage;
        })
        .map((question) => ({
          id: question.id,
          type: question.type,
          storageKey: "file_for_question_" + question.id,
        }));
    },
    processAnswer(value, eventTarget) {
      this.questionsAnsweredLoad[value.id] = eventTarget;
      this.selectAnswer(value, eventTarget);
    },
  },
  created() {
    if (this.$route.params.idPatient === null) return;

    this.fetchPatient();
    this.fetchPatientAppointmentAvailableScheduleById();
    this.fetchClinic().then(() => {
      this.fetchCHSDoctors();
    });
    this.fetchGi().then(() => {
      if (this.$route.params.questionsAnswered) {
        let questionsAnsweredLoadTemp = JSON.parse(
          this.$route.params.questionsAnswered
        );
        questionsAnsweredLoadTemp.forEach((element) => {
          this.questionsAnsweredLoad[element.id] = element.value;
          this.selectAnswer(element, element.value);
        });
      }
      if (this.$route.params.idReferral) {
        this.fetchPatientReferralById(this.$route.params.idReferral);
      }
    });

    if (this.$route.params.emergencyContactInformation) {
      this.emergencyContactInformation = JSON.parse(
        this.$route.params.emergencyContactInformation
      );
    }
    if (this.$route.params.communityCareNotes) {
      this.communityCareNotes = this.$route.params.communityCareNotes;
    }

    if (this.$route.params.insuranceInformation) {
      this.insuranceInformation = JSON.parse(
        this.$route.params.insuranceInformation
      );
      if (this.insuranceInformation.expirationDate) {
        this.insuranceInformation.expirationDate = moment
          .unix(this.insuranceInformation.expirationDate)
          .format("MM/DD/YYYY");
      }
    }
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/components/qa-radios.scss";
@import "@/scss/components/actions-below-table-container.scss";
@import "@/scss/components/step-subtitle.scss";
.appointment-info {
  width: 100%;
  padding: 0 $secondaryCardsSidePadding;
}
.appt-details-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px $primaryBgDarkGrey;
  margin-top: 56px;
  margin-bottom: 26px;
  padding-bottom: 21px;
  h1 {
    margin: 0;
    font-size: 36px;
    font-weight: 700;
    line-height: 1.22;
    letter-spacing: 0.18px;
  }
  .time-place {
    font-size: 18px;
    line-height: 1.22;
    letter-spacing: 0.1px;
    .place {
      color: $primaryDarkGrey;
    }
  }
}
.cols {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .col {
    width: math.percentage(math.div(345, 1108));
    margin-right: 36px;
    flex-grow: 0;
    flex-shrink: 0;

    &:last-child {
      margin-right: auto;
    }
  }
}
.title--md-thin {
  margin-bottom: 26px;
}
.eci {
  margin-bottom: 50px;
  .col {
    &:nth-child(3) {
      width: math.percentage(math.div(227, 1108));
    }
    &:nth-child(4) {
      width: math.percentage(math.div(81, 1108));
      margin-right: 0;
    }
  }
}
.insurance {
  position: relative;
  padding: 59px 10px 46px;
  margin-bottom: 62px;
  &:before {
    content: "";
    position: absolute;
    left: -$secondaryCardsSidePadding;
    right: -$secondaryCardsSidePadding;
    top: 0;
    bottom: 0;
    background-color: $primaryBgBlue;
  }
  &__content {
    position: relative;
  }
  .insurance-status-wrapper {
    padding-bottom: 24px;
    border-bottom: solid 1px $primaryBgDarkGrey;
    margin-bottom: 21px;
  }
  .insurance-status {
    width: math.percentage(math.div(345, 1108));
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.29;
      letter-spacing: 0.07px;
    }
    .svg-icon {
      font-size: 50px;
      top: 0;
    }
  }
  .cols-2 {
    .col {
      &:last-child {
        width: 168px;
        .btn {
          width: 168px;
        }
      }
    }
  }
}

.gi {
  .qa-radios {
    margin-bottom: 35px;
    .q {
      max-width: 798px;
    }
    .a {
      width: 200px;
    }
  }
}

.gi {
  .qa-radios {
    margin-bottom: 35px;
    .q {
      max-width: 798px;
    }
    .a {
      width: 200px;
    }
    .a.flex-end {
      justify-content: flex-end;
    }
  }
}
.dropdown_input_wrapper {
  position: relative;
  width: 100%;
  height: 0px;
  margin: 0px;
  ul {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: block;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
    box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
    max-height: 300px;
    overflow: scroll;
    li {
      display: block;
      padding: 3px 20px;
      line-height: 1.42857143;
      white-space: nowrap;
      cursor: pointer;
    }
    li.active,
    li:hover {
      color: #fff;
      text-decoration: none;
      background-color: $primaryLightBlue;
      outline: 0;
    }
  }
}
</style>
