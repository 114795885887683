<template lang="pug">
p(v-if="fetchDogStatusIdle") Welcome
p(v-if="fetchDogStatusPending") Loading data
p(v-if="fetchDogStatusError") There was a problem.
div(v-if="fetchDogStatusSuccess")
  img(:src="dog" alt="Dog image")
</template>
<script>
import { defineComponent } from "vue";
import { fetchCHSs } from "@/api/chsApi";
import { apiStatus, apiStatusComputedFactory } from "@/api";
import { withAsync } from "@/helpers";
const { IDLE, PENDING, SUCCESS, ERROR } = apiStatus;

export default defineComponent({
  data() {
    return {
      fetchCHSsStatus: IDLE,
      clinicHealthSystems: null,
    };
  },
  computed: {
    ...apiStatusComputedFactory("fetchCHSsStatus"),
  },
  methods: {
    async fetchCHSs() {
      this.fetchCHSsStatus = PENDING;
      const { res, error } = await withAsync(fetchCHSs);
      if (error) {
        this.fetchCHSsStatus = ERROR;
        return;
      }
      this.clinicHealthSystems = res.data.clinicHealthSystems;
      this.fetchCHSsStatus = SUCCESS;
    },
  },
  created() {
    setTimeout(this.fetchDog, 2000);
    // this.fetchDog();
  },
});
</script>
