// @ts-nocheck
export default {
  data() {
    return {
      sortings: {
        DateAndTime: [
          undefined,
          { by: "date-time", icon: "▲", direction: "asc" },
          { by: "date-time", icon: "▼", direction: "desc" },
        ],
        CBO: [
          undefined,
          { by: "cbo-name", icon: "▲", direction: "asc" },
          { by: "cbo-name", icon: "▼", direction: "desc" },
        ],
        PatientFullName: [
          undefined,
          { by: "patient-full-name", icon: "▲", direction: "asc" },
          { by: "patient-full-name", icon: "▼", direction: "desc" },
        ],
        ProviderName: [
          undefined,
          { by: "provider-name", icon: "▲", direction: "asc" },
          { by: "provider-name", icon: "▼", direction: "desc" },
        ],
        ReferralModuleName: [
          undefined,
          { by: "referral-module-name", icon: "▲", direction: "asc" },
          { by: "referral-module-name", icon: "▼", direction: "desc" },
        ],
        Status: [
          undefined,
          { by: "status", icon: "▲", direction: "asc" },
          { by: "status", icon: "▼", direction: "desc" },
        ],
      },
      currentSortKey: "DateAndTime",
      currentSortIndex: 0,
      listOrder: undefined,
    };
  },

  computed: {
    DateAndTimeColumnSortingIcon() {
      if (this.currentSortKey === "DateAndTime") {
        return this.sortings.DateAndTime[this.currentSortIndex]?.icon || "▼▲";
      }
      return "▼▲";
    },
    PatientFullNameColumnSortingIcon() {
      if (this.currentSortKey === "PatientFullName") {
        return (
          this.sortings.PatientFullName[this.currentSortIndex]?.icon || "▼▲"
        );
      }
      return "▼▲";
    },
    CBOColumnSortingIcon() {
      if (this.currentSortKey === "CBO") {
        return this.sortings.CBO[this.currentSortIndex]?.icon || "▼▲";
      }
      return "▼▲";
    },
    ReferralModuleNameColumnSortingIcon() {
      if (this.currentSortKey === "ReferralModuleName") {
        return (
          this.sortings.ReferralModuleName[this.currentSortIndex]?.icon || "▼▲"
        );
      }
      return "▼▲";
    },
    StatusColumnSortingIcon() {
      if (this.currentSortKey === "Status") {
        return this.sortings.Status[this.currentSortIndex]?.icon || "▼▲";
      }
      return "▼▲";
    },
    ProviderNameColumnSortingIcon() {
      if (this.currentSortKey === "ProviderName") {
        return this.sortings.ProviderName[this.currentSortIndex]?.icon || "▼▲";
      }
      return "▼▲";
    },
  },

  methods: {
    changeListOrder(key, callback) {
      if (this.currentSortKey === key) {
        this.updateCurrentSortingIndex(callback);
        return;
      }

      this.currentSortKey = key;
      this.currentSortIndex = 0;
      this.updateCurrentSortingIndex(callback);
    },
    updateCurrentSortingIndex(callback) {
      if (
        this.currentSortIndex ===
        this.sortings[this.currentSortKey].length - 1
      ) {
        this.currentSortIndex = 0;
        this.listOrder = this.sortings[this.currentSortKey][0];
        callback();
        return;
      }

      this.currentSortIndex++;
      this.listOrder =
        this.sortings[this.currentSortKey][this.currentSortIndex];
      return callback();
    },
  },
};
