// @ts-nocheck
import api from "./api";
const settingsUrl = "/settings";

export const fetchSettings = (data = {}) => {
  return api.get(settingsUrl, data);
};

export const fetchLogo = (data = {}) => {
  return api.get(settingsUrl + "/logo", data);
};

export const fetchSettingsFiles = (data = {}) => {
  return api.get(settingsUrl + "/files", data);
};

export const createSettings = (data = {}) => {
  return api.post(settingsUrl, data);
};

export const uploadLogo = async (storageKey: string) => {
  const { data, config } = await buildFormDataConfig(storageKey);

  if (data) {
    const response = await api.post(settingsUrl + "/logo/upload", data, config);
    if (!response.data.success) {
      throw new Error(
        respons.data.message,
        "something went wrong uploading the selected file"
      );
    }
    return response.data;
  }
};

const getFileFromSessionStorage = (key: string): string | null =>
  sessionStorage.getItem(key);

const urltoFile = (url, filename, mimeType) => {
  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    });
};

const buildFormDataConfig = async (storageKey: string) => {
  const { name, url, type } = JSON.parse(
    getFileFromSessionStorage(storageKey) || "{}"
  );
  if (!name || !url || !type) {
    return {};
  }

  const blobFileToUpload = await urltoFile(url, name, type);
  const data = new FormData();
  await data.append("upload", blobFileToUpload);
  return {
    data,
    config: {
      header: {
        "Content-Type": "multipart/form-data",
      },
    },
  };
};

export const uploadFile = async (data = {}): Promise<any> => {
  await api.post(`${settingsUrl}/files`, data);
};

export const updateSettings = (data = {}) => {
  return api.put(settingsUrl, data);
};
