<template lang="pug">
div
  .label {{ value.name }}
  label.radio-custom.radio-custom--yes(v-for="option in value.options" :key="value.id + ' ' + option.id" :class="classRadio(option.name)" v-if=' tabTypeProcessed == "confirmed" ' )
    input(type="radio" :disabled="disabled" :name="value.id"  :value="option.id" @change="sendAnswer(value,option.id)" v-model="inputValue")
    span.radio
    span.lbl {{ option.name }}
  
  label.radio-custom(v-else)
    input(type="radio" :checked="true" :disabled="true" v-model="inputValue")
    span.radio
    span.lbl(v-if="inputValue === true ") Yes
    span.lbl(v-else-if="inputValue === false ") No
    span.lbl(v-else) Null

</template>
<script>
import { defineComponent } from "vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default defineComponent({
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    let args = {
      inputValue: {},
    };
    if (this.value.required) {
      args = {
        inputValue: { required },
      };
    }
    return args;
  },
  data() {
    return {
      inputValue: "",
    };
  },
  methods: {
    sendAnswer(value, eventTarget) {
      this.$emit("emitAnswer", value, eventTarget);
    },
    classRadio: function (option) {
      return "radio-custom--" + option.toLowerCase();
    },
  },
  emits: ["emitAnswer"],

  props: {
    value: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
    tabTypeProcessed: {
      type: String,
      required: false,
    },
    default: {
      type: Boolean,
      required: false,
    },
  },
  created() {
    if (this.value === null) return;
    this.inputValue = this.default;
  },
});
</script>
<style scoped lang="scss">
.radio-custom {
  margin-right: 15px;
}
</style>
