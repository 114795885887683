<template lang="pug">
SideOverlaySlot(
  :isOpen="isOpen",
  :title="overlayTitle",
  @closeOverlay="closeOverlay"
)
  template(#contentTop)
    form#manage-user(
      v-if="!showUserManaged",
      @submit.prevent="saveAndcloseOverlay"
      novalidate
    )  
      .form-group(:class="{ 'form-group__invalid': v$.showDoctor.idUserRole.$errors.length }")
        label(for="user-type") User Type (Required)
        select#user-type(v-model="showDoctor.idUserRole" @blur="v$.showDoctor.idUserRole.$touch()" :disabled='overlayTitle == "ADD NEW USER" ? false : true')
          option(value="") Select Role
          option(value=2) Clinic Health System Manager
          option(value=3) Clinic Staff in Training
          option(value=4) Clinic Staff
          option(value=5) Clinic Doctor
        .form-group__errors(v-if="v$.showDoctor.idUserRole.$dirty && v$.showDoctor.idUserRole.$errors.length")
          .form-group__error(v-if="v$.showDoctor.idUserRole.required.$invalid") User role is required!
      .form-group(v-if="!hideClinicDropdown" :class="{ 'form-group__invalid': v$.showDoctor.idClinic.$errors.length }")
        label(for="clinic") Clinic (Required)
        select#clinic(v-model="showDoctor.idClinic"  @blur="v$.showDoctor.idClinic.$touch()")
          option(value="") Select
          option(
            v-for="clinic in clinics",
            :key="clinic.id",
            :value="clinic.id"
          ) {{ clinic.name }}
        .form-group__errors(v-if="v$.showDoctor.idClinic.$dirty && v$.showDoctor.idClinic.$errors.length")
          .form-group__error(v-if="v$.showDoctor.idClinic.required.$invalid") Clinic is required!
      .columns
        .column.is-half
          .form-group(:class="{ 'form-group__invalid': v$.showDoctor.first.$errors.length }")
            label(for="first-name") First Name (Required)
            input#first-name(type="text" v-model="showDoctor.first"  @blur="v$.showDoctor.first.$touch()")
            .form-group__errors(v-if="v$.showDoctor.first.$dirty && v$.showDoctor.first.$errors.length")
              .form-group__error(v-if="v$.showDoctor.first.required.$invalid") First name is required!
        .column.is-half
          .form-group(:class="{ 'form-group__invalid': v$.showDoctor.last.$errors.length }")
            label(for="last-name") Last Name (Required)
            input#last-name(type="text" v-model="showDoctor.last" @blur="v$.showDoctor.last.$touch()")
            .form-group__errors(v-if="v$.showDoctor.last.$dirty && v$.showDoctor.last.$errors.length")
              .form-group__error(v-if="v$.showDoctor.last.required.$invalid") Last name is required!
      .form-group(:class="{ 'form-group__invalid': v$.showDoctor.password.$errors.length }" v-if=" overlayTitle=='ADD NEW USER' ")
        label(for="password") Password (Required)
        input#password(type="password"  v-model="showDoctor.password" autocomplete="current-password"  @blur="v$.showDoctor.password.$touch()")
        .form-group__errors(v-if="v$.showDoctor.password.$dirty && v$.showDoctor.password.$errors.length")
          .form-group__error(v-if="v$.showDoctor.password.required.$invalid") Password is required!
      .form-group(:class="{ 'form-group__invalid': v$.showDoctor.email.$errors.length }")
        label(for="email") Email (Required)
        input#email(type="email" v-model="showDoctor.email"  @blur="v$.showDoctor.email.$touch()")
        .form-group__errors(v-if="v$.showDoctor.email.$dirty && v$.showDoctor.email.$errors.length")
          .form-group__error(v-if="v$.showDoctor.email.email.$invalid") Invalid email format
          .form-group__error(v-if="v$.showDoctor.email.required.$invalid") User email is required!
      .form-group
        label(for="details") Details
        textarea#details(style="height:250px;" v-model="showDoctor.details")

    .overlay-show-success(v-else="")
      p User successfully added!
  template(#footer)
    .flex.flex--space-between(v-if="!showUserManaged")
      BaseBtn.x-lg.red-outline(
        href="#",
        @click.prevent="closeOverlay"
      ) CANCEL
      BaseBtn.x-lg.green(
        type="submit",
        form="manage-user"
        :disabled="v$.$invalid || isSubmitting"
        :class="{spinner: isSubmitting }"
      ) CONTINUE
    .flex.flex--space-between(v-else="")
      BaseBtn.x-lg.blue-outline(
        :to={name: 'fit-kit-status.all-outstanding'}
      ) FIT KIT STATUS
      BaseBtn.x-lg.blue(
        :to={name: 'new-fit-kit'}
      ) ADD NEW FIT
</template>

<script>
import { defineComponent } from "vue";
import SideOverlaySlot from "@/components/overlays/SideOverlaySlot.vue";

import { register } from "@/api/accountApi";
import { updateUser } from "@/api/userApi";
import { fetchCHSClinics } from "@/api/chsApi";
import { apiStatus, apiStatusComputedFactory } from "@/api";
const { IDLE, PENDING, SUCCESS, ERROR } = apiStatus;
import { createToast } from "mosha-vue-toastify";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";

export default defineComponent({
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    let args = {
      showDoctor: {
        idUserRole: { required },
        first: { required },
        last: { required },
        idClinic: { required },
        password: { required },
        email: { required, email },
      },
    };

    if (this.hideClinicDropdown) {
      args.showDoctor.idClinic.required = false;
    }
    if (this.overlayTitle != "ADD NEW USER") {
      args.showDoctor.password.required = false;
    }
    return args;
  },
  components: { SideOverlaySlot },
  emits: ["closeManageUsersClinicalHealthSystemsOverlay"],

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    overlayTitle: {
      type: String,
      required: true,
    },
    showDoctor: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      showUserManaged: false,
      hideClinicDropdown: false,
      fetchCHSClinicsStatus: IDLE,
      addDoctorStatus: IDLE,
      updateUserStatus: IDLE,
      clinics: null,
      isUpdateServerError: false,
      isSubmitting: false,
    };
  },
  computed: {
    ...apiStatusComputedFactory("fetchCHSClinicsStatus"),
  },
  watch: {
    showDoctor: {
      handler(val) {
        if (val.idUserRole == 2) {
          this.hideClinicDropdown = true;
        } else {
          this.hideClinicDropdown = false;
        }
      },
      deep: true,
    },
  },
  methods: {
    saveAndcloseOverlay() {
      if (this.overlayTitle == "ADD NEW USER") {
        this.register().then(() => {
          this.$emit("closeManageUsersClinicalHealthSystemsOverlay");
        });
      } else {
        this.updateUser().then(() => {
          if (!this.isUpdateServerError) {
            this.$emit("closeManageUsersClinicalHealthSystemsOverlay");
          } else {
            this.isUpdateServerError = false;
          }
        });
      }
    },
    async updateUser() {
      this.isSubmitting = true;
      this.updateUserStatus = PENDING;
      let userID = this.showDoctor.id;
      let params = {};
      if (this.showDoctor.idUserRole == 2) {
        params.idClinicHealthSystem = this.$route.params.idClinicHealthSystem;
      } else {
        params.idClinic = this.showDoctor.idClinic;
      }
      try {
        await updateUser(userID, {
          ...params,
          user: {
            details: this.showDoctor.details,
            email: this.showDoctor.email,
            first: this.showDoctor.first,
            idUserRole: parseInt(this.showDoctor.idUserRole),
            last: this.showDoctor.last,
          },
        });
        this.updateUserStatus = SUCCESS;
      } catch (err) {
        this.updateUserStatus = ERROR;
        createToast(err?.response?.data?.message || "There was a problem", {
          timeout: 4000,
          type: "danger",
          position: "bottom-right",
        });
        this.isUpdateServerError = true;
        console.error(err);
      } finally {
        this.isSubmitting = false;
      }
    },
    async register() {
      this.isSubmitting = true;
      this.addDoctorStatus = PENDING;

      let params = {};
      if (this.showDoctor.idUserRole == 2) {
        params.idClinicHealthSystem = this.$route.params.idClinicHealthSystem;
      } else {
        params.idClinic = this.showDoctor.idClinic;
      }
      try {
        await register({
          ...params,
          user: {
            createdAt: null,
            details: this.showDoctor.details,
            email: this.showDoctor.email,
            first: this.showDoctor.first,
            idUserRole: parseInt(this.showDoctor.idUserRole),
            last: this.showDoctor.last,
            password: this.showDoctor.password,
            signInCount: 0,
            status: null,
            updatedAt: null,
          },
        });

        this.addDoctorStatus = SUCCESS;
      } catch (err) {
        this.addDoctorStatus = ERROR;
        createToast(err?.response?.data?.message || "There was a problem", {
          timeout: 4000,
          type: "danger",
          position: "bottom-right",
        });
        console.error(err);
      } finally {
        this.isSubmitting = false;
      }
    },
    async fetchCHSClinics() {
      this.fetchCHSClinicsStatus = PENDING;
      try {
        const res = await fetchCHSClinics(
          this.$route.params.idClinicHealthSystem
        );
        this.clinics = res.data.clinics;
        this.fetchCHSClinicsStatus = SUCCESS;
      } catch (err) {
        this.fetchCHSClinicsStatus = ERROR;
        console.error(err);
      }
    },
    closeOverlay() {
      this.$emit("closeManageUsersClinicalHealthSystemsOverlay");
    },
  },
  created() {
    this.fetchCHSClinics();
    if (this.showDoctor) {
      if (this.showDoctor.idUserRole == 2) {
        this.hideClinicDropdown = true;
      }
    }
  },
});
</script>

<style scoped lang="scss">
.patient-section {
  margin-bottom: 0;
  padding: 37px 24px 25px;
  background-color: $primaryBgBlue;
  p {
    margin: 0 0 12px;
    font-size: 21px;
    font-weight: 500;
    line-height: 1.1;
    color: $primaryLightBlue;
    overflow: hidden;
    strong {
      font-weight: 700;
    }
  }
}
form#manage-user {
  margin: 12px 0 20px;
  .columns {
    margin-bottom: 0px;
    .column {
      padding-bottom: 0px;
    }
  }
}
.overlay-show-success {
  p {
    margin: 41px 0 60px;
  }
}
</style>
