<template lang="pug">
SettingsOverlay(
  :isOpen="isSettingsOverlayOpen"
  @closeSettingsOverlayOverlay="closeSettingsOverlayOverlay",
)
</template>

<script>
import { defineComponent } from "vue";
import SettingsOverlay from "@/components/system-settings/overlays/SettingsOverlay.vue";

export default defineComponent({
  components: {
    SettingsOverlay,
  },

  data() {
    return {
      isSettingsOverlayOpen: true,
    };
  },

  methods: {
    closeSettingsOverlayOverlay() {
      this.$router.go(-1);
      this.isSettingsOverlayOpen = false;
    },
  },
});
</script>
