<template lang="pug">
.content-wrapper
  StepsCard()
    template(#steps)
      router-link(:to="{name: 'primary-care-community-organization.scheduling.search-patient'}", v-slot="{ href, route, navigate, isActive, isExactActive }", custom)
        li(
          :class="[isActive && 'router-link-exact-active']"
        ) <span>Search Patient</span>
          base-icon(name="arrow-steps")

      router-link(:to="{name: 'primary-care-community-organization.scheduling.select-patient'}", v-slot="{ href, route, navigate, isActive, isExactActive }", custom)
        li(
          :class="[isActive && 'router-link-exact-active']"
        ) <span>Select Patient</span>
          base-icon(name="arrow-steps")

      router-link(:to="{name: 'primary-care-community-organization.scheduling.select-schedule'}", v-slot="{ href, route, navigate, isActive, isExactActive }", custom)
        li(
          :class="[isActive && 'router-link-exact-active']"
        ) <span>Select Schedule</span>
          base-icon(name="arrow-steps")

      router-link(:to="{name: 'primary-care-community-organization.scheduling.appointment-info'}", v-slot="{ href, route, navigate, isActive, isExactActive }", custom)
        li(
          :class="[isActive && 'router-link-exact-active']"
        ) <span>Appointment Info</span>
          base-icon(name="arrow-steps")

      router-link(:to="{name: 'primary-care-community-organization.scheduling.confirm'}", v-slot="{ href, route, navigate, isActive, isExactActive }", custom)
        li(
          :class="[isActive && 'router-link-exact-active']"
        ) <span>Confirm</span>

    template(#content)
      router-view()
</template>

<script>
import { defineComponent } from "vue";
import StepsCard from "@/components/StepsCard.vue";

export default defineComponent({
  components: { StepsCard },

  data() {
    return {};
  },

  methods: {},
});
</script>

<style scoped lang="scss">
.content-wrapper {
  position: relative;
  padding-bottom: 40px;
}
</style>
