<template lang="pug">
section.fit-registry.dashboard-standard-section
  h3.title.title--md FIT Registry
  .status-line
    h4.title.title--sm Registry Status
    BaseBtn.x-lg.blue(:to="{name: 'new-fit-kit', params: {idClinic: clinicId}}") ADD NEW FIT
  .box-links-sm
    router-link(:to="{name: 'fit-kit-status.all-outstanding', params: {idClinic: clinicId}}")
      BoxLinkSm(text="All Outstanding", :number="fitRegistry.fitRegistryStatus.openKits")
    router-link(:to="{name: 'fit-kit-status.follow-up-due', params: {idClinic: clinicId}}")
      BoxLinkSm(text="Kit Follow-Up Due", :number="fitRegistry.fitRegistryStatus.dueFollowUpKits")
    router-link(:to="{name: 'fit-kit-status.completed', params: {idClinic: clinicId}}")
      BoxLinkSm(text="Completed", :number="fitRegistry.fitRegistryStatus.completedArchivedKits")
    router-link(:to="{name: 'fit-kit-status.positive-fit', params: {idClinic: clinicId}}")
      BoxLinkSm(text="Positive FIT / Colonoscopy Req.", :number="fitRegistry.fitRegistryStatus.positiveCompletedNeededActionKits")
    router-link(:to="{name: 'fit-kit-status.patient-follow-up-due', params: {idClinic: clinicId}}")
      BoxLinkSm(text="Patient Follow-Up Due", :number="fitRegistry.fitRegistryStatus.oneYearFollowUpKits")
  .line-graph-wrapper
    h4.title.title--sm Last 30 Days
    DotStats(:stats="stats")

  h4.title.title--sm(
    v-if="fitRegistry?.outsideICCAppointments"
  ) Outside ICC Colonoscopy Appointments
  .box-links-sm(v-if="fitRegistry?.outsideICCAppointments")
    router-link(:to="{name: 'fit-outside-icc.pending', params: {idClinic: clinicId}}")
      BoxLinkSm(text="For Clinic Review", :number="fitRegistry.outsideICCAppointments.pending")
    router-link(:to="{name: 'fit-outside-icc.archived', params: {idClinic: clinicId}}")
      BoxLinkSm(text="Archived", :number="fitRegistry.outsideICCAppointments.completed")
  h4.title.title--sm(v-if="organizationType === 'CBO'") Referrals
  .box-links-sm(v-if="organizationType === 'CBO'")
    router-link(:to="{name: 'fit-referrals', params: {idClinic: clinicId}}")
      BoxLinkSm(text="FIT Positive Referred to Outside Clinic", :number="fitRegistry?.countReferrals")
</template>

<script>
import { defineComponent } from "vue";
import { fetchClinic } from "@/api/clinicApi";
import { fetchCHS } from "@/api/chsApi";
import BoxLinkSm from "@/components/BoxLinkSm.vue";
import DotStats from "@/components/DotStats.vue";

export default defineComponent({
  components: { BoxLinkSm, DotStats },

  data() {
    return {
      organizationType: null,
      stats: [
        {
          label: "Negative",
          number: this.fitRegistry.fitRegistryStats.negativeCompletedKits,
          backgroundColor: "#96bf3c",
        },
        {
          label: "Positive",
          number: this.fitRegistry.fitRegistryStats.positiveCompletedKits,
          backgroundColor: "#d5351d",
        },
        {
          label: "No Results",
          number: this.fitRegistry.fitRegistryStats.noResultCompletedKits,
          backgroundColor: "#6d6262",
        },
      ],
    };
  },

  props: {
    fitRegistry: {
      type: Object,
      required: true,
    },
    clinicId: {
      type: Number,
      required: true,
    },
  },
  methods: {
    async getClinicType() {
      try {
        const clinicRes = await fetchClinic(this.clinicId);
        const chsRes = await fetchCHS(clinicRes.data.idClinicHealthSystem);
        this.organizationType = chsRes.data.organizationType;
      } catch (err) {
        console.error(err);
      }
    },
  },
  created() {
    this.getClinicType();
  },
});
</script>
