<template lang="pug">
.tr__row.row-conditionals
  label.label--margin-sm Show this field if
  .conditionals
    .form-group
      select(
        v-model="fieldToCheck"
        style="width: 345px;"
      )
        option(
          v-for="field in checkableFields", :key="field.id",
          :value="field.id",
          :disabled="field.active !== true",

        ) {{field.name}}
    .form-group(v-show="fieldToCheck")
      select(
        v-model="operatorToUse",
        style="width: 155px;"
      )
        option(
          v-for="operator in operators",
          :key="operator.value"
          :value="operator.value"
          :selected="operator.value === operatorToUse"
        ) {{ operator.name }}
    .form-group(v-show="fieldToCheck && operatorToUse")
      select(
        v-if="selectedFieldOptions",
        v-model="valueToCheck",
        style="width: 155px;"
      )
        option(
          v-for="option in selectedFieldOptions",
          :key="option.id",
          :value="option.id",
          :selected="option.id === valueToCheck"
        ) {{ option.name }}
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["mutateConditionalRules"],

  props: {
    fields: {
      type: Object,
      required: true,
    },
    currentField: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      fieldToCheck: this.currentField?.rules[0]?.fieldToCheck,
      operators: [
        {
          value: "EQUAL",
          name: "is",
        },
        {
          value: "NOT_EQUAL",
          name: "is not",
        },
      ],
      operatorToUse: this.currentField?.rules[0]?.operatorToUse ?? null,
      selectedFieldOptions: null,
      valueToCheck: this.currentField?.rules[0]?.valueToCheck,
    };
  },

  computed: {
    checkableFields() {
      return this.fields.filter((field) => {
        return (
          field.id !== this.currentField.id &&
          (field.type === "DROPDOWN" || field.type === "RADIO")
        );
      });
    },
  },

  watch: {
    fieldToCheck: {
      handler: "selectField",
      // immediate: true,
    },
    operatorToUse: {
      handler: "saveRules",
      // immediate: true,
    },
    valueToCheck: {
      handler: "saveRules",
    },
  },

  methods: {
    selectField(resetRules = true) {
      if (resetRules) this.resetRules();
      this.saveRules();
      this.findSelectedFieldOptions();
    },
    findSelectedFieldOptions() {
      const selectedField = this.fields.find(
        (field) => field.id === this.fieldToCheck
      );
      this.selectedFieldOptions = selectedField?.options;
    },
    saveRules() {
      if (
        this.fieldToCheck == null ||
        this.operatorToUse == null ||
        this.valueToCheck == null
      )
        return;
      const rules = [
        {
          fieldToCheck: this.fieldToCheck,
          operatorToUse: this.operatorToUse,
          valueToCheck: this.valueToCheck,
        },
      ];
      this.$emit("mutateConditionalRules", this.currentField, rules);
    },
    resetRules() {
      this.operatorToUse = null;
      this.selectedFieldOptions = null;
      this.valueToCheck = null;
      this.$emit("mutateConditionalRules", this.currentField, []);
    },
  },
  mounted() {
    this.selectField(false);
  },
});
</script>

<style scoped lang="scss">
.row-conditionals,
.table.table--no-side-padding .tr__row.row-conditionals {
  display: block;
  .form-group:not(:last-child) {
    margin-right: 37px;
  }
  .conditionals {
    display: flex;
    margin-top: 11px;
  }
}
</style>
