<template lang="pug">
FullScreenOverlaySlot(
  :isOpen="isOpen",
  headerIcon="icc-logo",
  title="PRIMARY CARE MODULE SETTINGS"
  @closeOverlay="closeOverlay"
)
  template(#breadcrumbs)
    h1.breadcrumbs-title IL CARE CONTINUUM
  template(#content)
    .card(style="padding-top: 52px;")
      .module-base-info.columns.is-variable
        .column.is-one-third
          .form-group(:class="{ 'form-group__invalid': v$.primaryCareModule.moduleTitle.$invalid }")
            label.label--margin-sm Module Title
            input.input.text-lg(
              type="text" 
              v-model="primaryCareModule.moduleTitle"
              @blur="v$.primaryCareModule.moduleTitle.$touch()"
            )
        .column.is-one-third
          .form-group(:class="{ 'form-group__invalid': v$.primaryCareModule.marketingTitle.$invalid }")
            label.label--margin-sm Marketing Title
            input.input.text-lg(
              type="text" 
              v-model="primaryCareModule.marketingTitle"
              @blur="v$.primaryCareModule.marketingTitle.$touch()"
            )
        .info-pair.column.is-one-third
          .label--margin-sm Module Icon (Required)
            base-icon(name="paperclip", style="margin-left:8px;")
          .txt.flex
            UploadedImagePreview(
              :src="primaryCareModule.icon",
              labelFor="general-icon"
              style="margin-right: 15px;"
            )
            BaseUpload#general-icon(v-model="primaryCareModule.icon") Upload

    .card
      h2.title.title--md-thin PATIENT INFO CONFIGURATION
      .patient-record-info.columns.is-variable
        .column.is-one-third
          .form-group
            label.label--margin-sm Referral Info Title
            input(type="text" v-model="primaryCareModule.schemaRules.patientInfoConfiguration.referralInfoTitle")
        .column.is-two-thirds
          .flex
            .form-group(style="margin-right: 48px;")
              BaseToggle(label="Require Community Notes" v-model="primaryCareModule.schemaRules.patientInfoConfiguration.requireClinicNotes")
            .form-group
              BaseToggle(label="Require Community Report" v-model="primaryCareModule.schemaRules.patientInfoConfiguration.requireCommunityReport")
    .card
      .title-md-thin-with-action
        h2.title.title--md-thin(style="margin-bottom: 27px;") PATIENT INFO QUESTIONS
        BaseBtn.btn.lg.blue(
          :isDropdown="true",
          txt="ADD NEW FIELD"
        )
          template(#dropdown-menu)
            li
              a(href="#", @click.prevent="addInfoQuestion('NUMERIC')") Numeric Field
            li
              a(href="#", @click.prevent="addInfoQuestion('TEXT')") Text Field
            li
              a(href="#", @click.prevent="addInfoQuestion('DATE')") Date Field
            li
              a(href="#", @click.prevent="addInfoQuestion('DROPDOWN')") Dropdown
            li
              a(href="#", @click.prevent="addInfoQuestion('FILE')") File Upload
            li
              a(href="#", @click.prevent="addInfoQuestion('RADIO')") Radio Yes/No
            li
              a(href="#", @click.prevent="addInfoQuestion('SECTION_TITLE')") Section Title
            li
              a(href="#", @click.prevent="addInfoQuestion('DESCRIPTION')") Description
      
      .table.table--no-side-padding
        .th
          .td Field Title
          .td Field Type
          .td Active
          .td
        draggable.list-group(:list="primaryCareModule.schemaRules.patientInfoQuestions.fields", handle=".drag-bars", @change="log")
          .tr.list-group-item(v-for="element in primaryCareModule.schemaRules.patientInfoQuestions.fields", :key="element.id")
            .tr__row
              .td
                input(type="text" v-model="element.name")
              .td {{element.type}}
              .td 
                BaseToggle(v-model="element.active")
              .td
                .drag-bars
                  base-icon(name="drag-big")
            .tr__row.row-main-options
              BaseCheckbox(label="Required" v-model="element.required")
              BaseCheckbox(label="Conditional", v-model="element.hasDependencies")
            .tr__row.dropdown-options
              draggable(:list="element.options || []", handle=".drag-bars--sm", @change="log" v-if="element.type === 'DROPDOWN'")
                transition-group(type="transition", name="flip-list")
                  .draggable-input(v-for="option in element.options || []", :key="option.id")
                    .draggable-input__inner
                      span.drag-bars--sm
                        base-icon(name="drag-small")
                      input(type="text", v-model="option.name")
              a.add-new(href="#", @click.prevent="addOption(element.id)" v-if="element.type === 'DROPDOWN'")
                base-icon(name="plus") 
                span Add New
            .tr__row.row-conditionals(v-show="element.hasDependencies")
            ModuleFieldConditionals(
              :key="element.id",
              v-if="element.hasDependencies",
              :currentField="element",
              :fields="primaryCareModule.schemaRules.patientInfoQuestions.fields",
              @mutateConditionalRules="mutateConditionalRules"
            )


  template(#footer)
    BaseBtn.btn.lg.red-outline(href="#") CANCEL CHANGES
    BaseBtn.btn.lg.green(
      @click.prevent="updateModule"
      :disabled="v$.$invalid"
    ) SAVE AND EXIT

</template>

<script>
import { defineComponent } from "vue";
import FullScreenOverlaySlot from "@/components/overlays/FullScreenOverlaySlot.vue";
import { VueDraggableNext } from "vue-draggable-next";
import { v1 as uuidv1 } from "uuid";
import PopupCardSlot from "@/components/PopupCardSlot.vue";
import formBuilderMixin from "@/components/system-settings/mixins/formBuilderMixin.ts";
import ModuleFieldConditionals from "@/components/system-settings/ModuleFieldConditionals.vue";
import UploadedImagePreview from "@/components/UploadedImagePreview.vue";

import {
  fetchPrimaryCareModule,
  updatePrimaryCareModule,
} from "@/api/primaryCareModule";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default defineComponent({
  setup() {
    return { v$: useVuelidate() };
  },

  components: {
    FullScreenOverlaySlot,
    UploadedImagePreview,
    draggable: VueDraggableNext,
    PopupCardSlot,
    ModuleFieldConditionals,
  },

  mixins: [formBuilderMixin],

  emits: ["closeModuleSettingsOverlay"],

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },

  validations() {
    return {
      primaryCareModule: {
        moduleTitle: { required },
        marketingTitle: { required },
        icon: { required },
      },
    };
  },

  data() {
    return {
      primaryCareModule: {
        moduleTitle: null,
        marketingTitle: null,
        schemaRules: {
          patientInfoConfiguration: {},
          patientInfoQuestions: {
            fields: [],
          },
        },
        icon: null,
      },
      disabledbutton: true,
      questionTypes: {
        NUMERIC: "NUMERIC",
        TEXT: "TEXT",
        DATE: "DATE",
        RADIO: "RADIO",
        FILE: "FILE",
        DROPDOWN: "DROPDOWN",
        SECTION_TITLE: "SECTION_TITLE",
        DESCRIPTION: "DESCRIPTION",
      },
    };
  },

  computed: {
    getConditionalFields() {
      return this.primaryCareModule.schemaRules.patientInfoQuestions.fields.filter(
        (field) => field.type === "RADIO" || field.type === "DROPDOWN"
      );
    },
  },

  methods: {
    closeOverlay() {
      this.$emit("closeModuleSettingsOverlay");
    },
    log(event) {
      console.log(event);
    },
    addOption(fieldId) {
      const newDropdownOption = {
        name: "",
        id: uuidv1(),
      };
      const index =
        this.primaryCareModule.schemaRules.patientInfoQuestions.fields.findIndex(
          (field) => field.id === fieldId
        );

      this.primaryCareModule.schemaRules.patientInfoQuestions.fields[index]
        .options
        ? this.primaryCareModule.schemaRules.patientInfoQuestions.fields[
            index
          ].options.push(newDropdownOption)
        : (this.primaryCareModule.schemaRules.patientInfoQuestions.fields[
            index
          ].options = [newDropdownOption]);
    },
    addInfoQuestion(type) {
      const field = {
        name: "",
        type,
        id: uuidv1(),
        rules: [],
        options: null,
        hasDependencies: false,
      };

      if (type === "RADIO") {
        field.options = [
          {
            name: "Yes",
            id: true,
          },
          {
            name: "No",
            id: false,
          },
        ];
      }

      this.primaryCareModule.schemaRules.patientInfoQuestions.fields.push(
        field
      );
    },
    async fetchPrimaryCareModule() {
      const {
        data: { primaryCareModule },
      } = await fetchPrimaryCareModule();
      this.primaryCareModule = primaryCareModule;
    },
    async updateModule() {
      const {
        data: { primaryCareModule },
      } = await updatePrimaryCareModule(this.primaryCareModule.id, {
        primaryCareModule: {
          ...this.primaryCareModule,
          id: undefined,
          deletedAt: undefined,
          createdAt: undefined,
        },
      });

      this.primaryCareModule = primaryCareModule;
      this.$emit("closeModuleSettingsOverlay");
    },
    getConditionalFieldsOptions(conditionalField) {
      const field =
        this.primaryCareModule.schemaRules.patientInfoQuestions.fields.find(
          (field) => field.id === conditionalField.id
        );
      return field.options;
    },
  },

  created() {
    this.fetchPrimaryCareModule();
  },
});
</script>

<style scoped lang="scss">
.card {
  padding: 35px $secondaryCardsSidePadding 20px $secondaryCardsSidePadding;
}
.columns.is-variable {
  --columnGap: 1.575%;
}
.columns + .hr {
  background-color: $primaryBgDarkGrey;
  margin-top: -21px;
  margin-bottom: 24px;
}
.card > .columns:last-child.info-pair,
.card > .columns:last-child .info-pair {
  margin-bottom: 0;
}
.svg-icon--eye-red,
.svg-icon--eye-green,
.svg-icon--yes,
.svg-icon--no {
  width: 50px;
  height: 50px;
  top: 0;
}

// Page specific styles----------------------------------------
.breadcrumbs-title {
  margin: 0;
  color: $primaryLightBlue;
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.12px;
}
.table {
  .td {
    &:nth-child(1) {
      width: math.percentage(math.div(800, $tabContentCardWidth));
    }
    &:nth-child(2) {
      width: math.percentage(math.div(204, $tabContentCardWidth));
    }
    &:nth-child(3) {
      width: math.percentage(math.div(134, $tabContentCardWidth));
    }
    &:last-child {
      width: math.percentage(math.div(36, $tabContentCardWidth));
      display: flex;
      justify-content: flex-end;
    }
  }
  .svg-icon--drag-big {
    font-size: 34px;
    color: $primaryBgDarkGrey;
  }
  .row-main-options {
    padding: 35px 0;
    .base-checkbox:not(:last-child) {
      margin-right: 84px;
    }
  }
  .dropdown-options {
    display: block;
    padding: 0 0 34px;
    width: calc(#{math.percentage(math.div(800, $tabContentCardWidth))} - 42px);
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }
    li {
      position: relative;
      display: flex;
      align-items: flex-end;
      height: 37px;
      padding-bottom: 6px;
      &::after {
        position: absolute;
        content: "";
        display: block;
        height: 1px;
        background-color: $primaryBgDarkGrey;
        bottom: 0;
        left: 26px;
        right: 0;
      }
    }
    .add-new {
      display: flex;
      align-items: center;
      margin-top: 13px;
      font-size: 13px;
      line-height: 1.23;
      color: #000;
      text-transform: uppercase;
      text-decoration: none;
      .svg-icon {
        font-size: 17px;
        top: 0;
        margin-right: 16px;
      }
    }
  }
  .row-conditionals {
    display: block;
    .form-group:not(:last-child) {
      margin-right: 37px;
    }
    .conditionals {
      display: flex;
      margin-top: 11px;
    }
  }
  // Drag and drop ---------------------------------
  .flip-list-move {
    transition: transform 0.5s;
  }
  .no-move {
    transition: transform 0s;
  }
  .ghost {
    opacity: 0.5;
    background: #c8ebfb;
  }
  .list-group {
    min-height: 20px;
  }
  .list-group-item .drag-bars {
    cursor: move;
    padding-right: 1px;
  }
  .drag-bars--sm {
    display: block;
    width: 26px;
    cursor: move;
    .svg-icon {
      width: 9px;
      height: 12px;
      color: #c5c7cd;
    }
  }
}
</style>
