<template lang="pug">
SideOverlaySlot(
  :isOpen="isOpen",
  title="CLINIC REFERRAL"
  @closeOverlay="closeOverlay"
)
  template(#contentTop)
    PatientSection(:fitKit="selectedFitKit")
    form(
      v-if="!isCreated"
      style="display:flex; flex-direction:column; flex:1;"
      @submit.prevent=""
    )
      .columns
        .column.is-half
          .form-group(style="margin-bottom:0;")
            label(for="referred-to") Clinic referred to
            input#referred-to(type="text", v-model="clinicReferredTo")
        .column.is-half
          .form-group(style="margin-bottom:0;")
            label(for="date-of-referral") Date of Referral
            DatePicker(
              v-model="date" 
              :masks="masks"
              :max-date="new Date()"
            )
              template(v-slot="{ inputValue, inputEvents }")
                input(
                  :value="inputValue"  
                  v-on="inputEvents"
                  placeholder="MM/DD/YYYY"
                )
              

      .form-group(
        style="margin-bottom:0; display:flex; flex-direction:column; flex:1;"
      )
        label(for="notes") Notes
        textarea#notes(style="flex:1;", v-model="newNotes")
    .overlay-show-success(v-if="isCreated")
      p Clinic referral successfully created!
  template(#footer, v-if="!isCreated")
    .flex.flex--space-between
      BaseBtn.x-lg.red-outline(
        href="#",
        @click.prevent="closeOverlay"
      ) CANCEL
      BaseBtn.x-lg.green(
        href="#",
        :class="{disabled: !isValidEntry()}"
        @click.prevent="createReferral"
      ) CONTINUE
</template>

<script>
import { defineComponent } from "vue";
import SideOverlaySlot from "@/components/overlays/SideOverlaySlot.vue";
import PatientSection from "@/components/fit-kit/overlays/PatientSection.vue";
import { createFitKitReferral } from "@/api/fitKitApi";
import { getInputFormatDate } from "@/helpers";
import { createToast } from "mosha-vue-toastify";
import { DatePicker } from "v-calendar";
import moment from "moment";

export default defineComponent({
  components: {
    SideOverlaySlot,
    PatientSection,
    DatePicker,
  },
  emits: ["closeFitReferralOverlay"],

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    selectedFitKit: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      date: null,
      masks: {
        input: "MM/DD/YYYY",
      },
      isCreated: false,
      clinicReferredTo: null,
      dateOfReferral: null,
      newNotes: null,
      maxDate: getInputFormatDate(new Date()),
    };
  },
  watch: {
    date: {
      handler: "setDate",
    },
  },

  methods: {
    setDate(newVal) {
      if (newVal === null) {
        this.dateOfReferral = "";
      } else {
        let selectionDisplay = moment(this.date).format("MM/DD/YYYY");
        this.dateOfReferral = selectionDisplay;
      }
    },
    isValidEntry() {
      const isValidClinicReferred =
        this.clinicReferredTo && this.clinicReferredTo !== "";
      const isValidNotes = this.newNotes && this.newNotes !== "";
      return this.dateOfReferral && isValidClinicReferred && isValidNotes;
    },
    closeOverlay() {
      this.clinicReferredTo = null;
      this.dateOfReferral = null;
      this.newNotes = null;
      this.$emit("closeFitReferralOverlay", {
        afterAction: this.isCreated,
      });
      this.isCreated = false;
    },
    //TODO Remove console and add toasts
    async createReferral() {
      if (!this.clinicReferredTo || this.clinicReferredTo === "") {
        console.error("Clinic Referred is required");
        return false;
      }
      const unixReferral = new Date(this.dateOfReferral).getTime();
      if (!unixReferral) {
        console.error("Date of Referral is required");
        return false;
      }
      if (unixReferral > Date.now()) {
        console.error("Date of Referral cannot be set in the future");
        return false;
      }
      if (!this.newNotes || this.newNotes === "") {
        console.error("Notes are required");
        return false;
      }
      const idFitKit = this.selectedFitKit.id;
      const data = {
        clinicReferredTo: this.clinicReferredTo,
        referralAt: unixReferral / 1000,
        notes: this.newNotes,
      };
      try {
        const resFitKit = await createFitKitReferral(idFitKit, data);
        if (resFitKit.data.success) {
          this.isCreated = true;
        }
      } catch (err) {
        createToast(err?.response?.data?.message || "There was a problem", {
          timeout: 4000,
          type: "danger",
          position: "bottom-right",
        });
        console.error(err);
      }
    },
  },
});
</script>

<style scoped lang="scss">
.overlay-show-success {
  margin-bottom: 45px;
}
</style>
