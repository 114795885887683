// @ts-nocheck
export const withAsync = async (fn, ...args) => {
  try {
    const res = await fn(...args);
    return {
      res,
      err: null,
    };
  } catch (err) {
    return {
      res: null,
      err,
    };
  }
};
