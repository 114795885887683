<template lang="pug">
FullScreenOverlaySlot(
  :isOpen="isOpen",
  headerIcon="icc-logo",
  title="REFERRAL MODULE SETTINGS",
  @closeOverlay="closeOverlay"
)
  template(#breadcrumbs)
    h1.breadcrumbs-title IL CARE CONTINUUM
  template(#content)
    .card.general-settings
      h2.title.title--md-thin GENERAL SETTINGS
      .module-base-info.columns.is-variable
        .column.is-one-third
          .form-group
            label.label--margin-sm(for="module-title") Referral Module Title
            input#module-title.text-lg(
              type="text",
              v-model="general.moduleTitle"
            )  
        .column.is-one-third
          .form-group(style="display: flex")
            BaseToggle(
              label="Referral Module Status",
              v-model="general.isModuleEnabled"
              @change="isModuleEnabledChanged"
              style="margin-right: 88px;"
            )

    .card
      h2.title.title--md-thin PATIENT INFO CONFIGURATION
      .patient-record-info.columns.is-variable
        .column.is-one-third
          .form-group
            label.label--margin-sm Referral Info Section Title
            input(
              type="text",
              v-model="general.referralInfoSectionTitle"
            )
        .column.is-one-third
          .form-group
            label.label--margin-sm Provider Required Response Time
      
            select(
              style="width: 345px;"
              v-model="general.providerRequiredResponseTime"
            )
              option(
                v-for="field in providerResponseTimes", :key="field",
                :value="field.value",

              ) {{field.name}}
      .columns 
        .column
          .top_border
      .columns 
        .column.is-one-third
          .flex
            .form-group.margin-right
              BaseToggle(
                label="Provider Report?"
                v-model="general.providerReport"
                @change="toggleProviderReport"
              )
            .form-group
              BaseToggle(
                label="Required?"
                v-model="general.providerReportRequired"
                :disabled="!general.providerReport"
                @change="toggleProviderReportRequired"
              )
        .column.is-one-third
          .flex
            .form-group.margin-right
              BaseToggle(
                label="Provider Notes?"
                @change="toggleProviderNotes"
                v-model="general.providerNotes"
              )
            .form-group
              BaseToggle(
                label="Required?"
                :disabled="!general.providerNotes"
                v-model="general.providerNotesRequired"
                @change="toggleProviderNotesRequired"
              )
            
    //- ----------------------------------
    .card
      .title-md-thin-with-action
        h2.title.title--md-thin(style="margin-bottom: 27px;") REFERRAL INFO QUESTIONS
        AddFormFieldDropdown(
          @addFormField="addFormField"
          :formModel="referralInfoQuestions.fields"
        )
      .table.table--no-side-padding
        .th
          .td Field Title
          .td Field Type
          .td Active
          .td
        draggable.list-group(
          :list="referralInfoQuestions.fields",
          handle=".drag-bars"
        )
          .tr.list-group-item(
            v-for="field in referralInfoQuestions.fields",
            :key="field.id"
          )
            .tr__row
              .td
                input(type="text", v-model="field.name")
              .td.field-type(
                :class="['field-type--' + field.type.toLowerCase()]"
              ) {{toReadableType(field.type)}}
              .td 
                BaseToggle(v-model="field.active")
              .td
                .drag-bars
                  base-icon(name="drag-big")
            .tr__row.row-main-options
              BaseCheckbox(
                v-if="field.type !== 'DESCRIPTION' && field.type !== 'SECTION_TITLE'"
                label="Required",
                v-model="field.required"
              )
              BaseCheckbox(
                label="Conditional",
                v-model="field.hasDependencies"
              )
            .tr__row.list-options(v-if="field.type === 'DROPDOWN'")
              draggable(
                :list="field.options",
                handle=".drag-bars--sm"
              )
                transition-group(type="transition", name="flip-list")
                  .draggable-input(
                    v-for="option in field.options",
                    :key="option.id"
                  )
                    .draggable-input__inner
                      span.drag-bars--sm
                        base-icon(name="drag-small")
                      input(type="text", v-model="option.name")
              a.add-new(href="#", @click.prevent="addDropdownOption(field.options)")
                base-icon(name="plus") 
                span Add dropdown option
            ModuleFieldConditionals(
              v-if="field.hasDependencies",
              :currentField="field",
              :fields="referralInfoQuestions.fields",
              @mutateConditionalRules="mutateConditionalRules"
            )
    .specialty-spacer
  template(#footer)
    .footer-inner(v-if="moduleId")
      BaseBtn.btn.lg.red-outline(
        href="#",
        @click.prevent="closeOverlay"
      ) CANCEL CHANGES
      BaseBtn.btn.lg.green(
        href="#",
        @click.prevent="updateReferralModule"
      ) SAVE CHANGES AND EXIT
    .footer-inner(v-else="")
      BaseBtn.btn.lg.red-outline(
        href="#",
        @click.prevent="closeOverlay"
      ) CANCEL
      BaseBtn.btn.lg.green(
        href="#",
        @click.prevent="createReferralModule"
      ) SAVE AND EXIT
teleport(to="body")
  DisableModulePopup(
    :isOpen="isDisableModulePopupOpen",
    @cancelModuleDisable="cancelModuleDisable"
    @confirmModuleDisable="confirmModuleDisable"
  )
</template>

<script>
import { defineComponent } from "vue";
import FullScreenOverlaySlot from "@/components/overlays/FullScreenOverlaySlot.vue";
import AddFormFieldDropdown from "@/components/system-settings/AddFormFieldDropdown.vue";
import ModuleFieldConditionals from "@/components/system-settings/ModuleFieldConditionals.vue";
import { VueDraggableNext } from "vue-draggable-next";
import { v1 as uuidv1 } from "uuid";
import DisableModulePopup from "@/components/system-settings/popups/DisableModulePopup.vue";
import formBuilderMixinReferrals from "@/components/system-settings/mixins/formBuilderMixinReferrals.ts";
import UploadedImagePreview from "@/components/UploadedImagePreview.vue";
import { fetchReferralModule } from "@/api/referralModulesApi";

export default defineComponent({
  components: {
    FullScreenOverlaySlot,
    AddFormFieldDropdown,
    ModuleFieldConditionals,
    draggable: VueDraggableNext,
    DisableModulePopup,
    UploadedImagePreview,
  },
  mixins: [formBuilderMixinReferrals],
  emits: ["closeModuleSettingsOverlay"],

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    moduleId: {
      required: false,
    },
  },

  data() {
    return {
      isDisableModulePopupOpen: false,
      providerResponseTimes: this.populateProviderResponseTimes(),
      updatedAt: null,
      general: {
        moduleTitle: "",
        referralInfoSectionTitle: "",
        providerRequiredResponseTime: "1 hr",
        isModuleEnabled: true,
        providerReport: false,
        providerReportRequired: false,
        providerNotes: false,
        providerNotesRequired: false,
      },
      referralInfoQuestions: {
        fields: [],
      },
    };
  },

  methods: {
    populateProviderResponseTimes() {
      const providerResponseTimes = [
        {
          name: "12 hrs",
          value: 720,
        },
        {
          name: "24 hrs",
          value: 1440,
        },
        {
          name: "36 hrs",
          value: 2160,
        },
        {
          name: "48 hrs",
          value: 2880,
        },
      ];
      return providerResponseTimes;
    },
    toggleProviderNotes() {
      if (this.general.providerNotes === false) {
        this.general.providerNotesRequired = false;
      }
    },
    toggleProviderReport() {
      if (this.general.providerReport === false) {
        this.general.providerReportRequired = false;
      }
    },

    toggleProviderReportRequired() {
      if (this.general.providerReport === false) {
        this.general.providerReportRequired = false;
      }
    },
    toggleProviderNotesRequired() {
      if (this.general.providerNotes === false) {
        this.general.providerNotesRequired = false;
      }
    },
    isModuleEnabledChanged() {
      this.$nextTick(() => {
        if (this.general.isModuleEnabled === false) {
          this.isDisableModulePopupOpen = true;
        }
      });
    },
    cancelModuleDisable() {
      this.general.isModuleEnabled = true;
      this.isDisableModulePopupOpen = false;
    },
    confirmModuleDisable() {
      this.isDisableModulePopupOpen = false;
    },
    closeOverlay() {
      this.$emit("closeModuleSettingsOverlay");
    },
    addAdditionalDropdownOptions(model) {
      model.push({
        id: uuidv1(),
        name: "",
        value: "",
      });
    },

    async fetchReferralModule() {
      try {
        const res = await fetchReferralModule(this.moduleId);
        this.assignFetchData(res?.data?.referralModule);
      } catch (err) {
        console.error(err);
      }
    },
    assignFetchData(referralModule) {
      this.general = {
        moduleTitle: referralModule.moduleTitle,
        referralInfoSectionTitle: referralModule.referralInfoSectionTitle,
        providerRequiredResponseTime:
          referralModule.providerRequiredResponseTime,
        isModuleEnabled: referralModule.isModuleEnabled,
        providerReport: referralModule.providerReport,
        providerReportRequired: referralModule.providerReportRequired,
        providerNotes: referralModule.providerNotes,
        providerNotesRequired: referralModule.providerNotesRequired,
      };
      this.updatedAt = referralModule?.updatedAt;
      this.referralInfoQuestions =
        referralModule?.schemaRules?.referralInfoQuestions;
    },
  },
  created() {
    if (this.moduleId === null) return;
    this.fetchReferralModule();
  },
});
</script>

<style scoped lang="scss">
.completed-popup {
  position: relative;
  .close {
    display: block;
    position: absolute;
    top: -11px;
    right: 1px;
    z-index: 2;
    .svg-icon {
      height: 20px;
      width: 20px;
      top: 0;
    }
  }
}
.card {
  padding: 35px $secondaryCardsSidePadding 20px $secondaryCardsSidePadding;
}
.columns.is-variable {
  --columnGap: 1.575%;
}
.columns + .hr {
  background-color: $primaryBgDarkGrey;
  margin-top: -21px;
  margin-bottom: 24px;
}
.card > .columns:last-child.info-pair,
.card > .columns:last-child .info-pair {
  margin-bottom: 0;
}
.svg-icon--eye-red,
.svg-icon--eye-green,
.svg-icon--yes,
.svg-icon--no {
  width: 50px;
  height: 50px;
  top: 0;
}

// Page specific styles----------------------------------------
.field-type:not(.field-type--radio) {
  text-transform: lowercase;
  &:first-letter {
    text-transform: capitalize;
  }
}
.breadcrumbs-title {
  margin: 0;
  color: $primaryLightBlue;
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.12px;
}
.procedure-list-instructions {
  margin-top: -20px;
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 1.29;
}
.table {
  .td {
    &:nth-child(1) {
      width: math.percentage(math.div(800, $tabContentCardWidth));
    }
    &:nth-child(2) {
      width: math.percentage(math.div(204, $tabContentCardWidth));
    }
    &:nth-child(3) {
      width: math.percentage(math.div(134, $tabContentCardWidth));
    }
    &:last-child {
      width: math.percentage(math.div(36, $tabContentCardWidth));
      display: flex;
      justify-content: flex-end;
    }
  }
  .svg-icon--drag-big {
    font-size: 34px;
    color: $primaryBgDarkGrey;
  }
  .row-main-options {
    padding: 35px 0;
    .base-checkbox:not(:last-child) {
      margin-right: 84px;
    }
  }
}
.footer-inner {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.specialty-spacer {
  width: 100px;
  height: 150px;
}
// Drag and drop ---------------------------------
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.list-group-item .drag-bars {
  cursor: move;
  padding-right: 1px;
}
.drag-bars--sm {
  display: block;
  width: 26px;
  cursor: move;
  .svg-icon {
    width: 9px;
    height: 12px;
    color: #c5c7cd;
  }
}
.list-options,
.tr__row.list-options {
  display: block;
  padding: 0 0 34px;
  width: calc(#{math.percentage(math.div(800, $tabContentCardWidth))} - 42px);
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  li {
    position: relative;
    display: flex;
    align-items: flex-end;
    height: 37px;
    padding-bottom: 6px;
    &::after {
      position: absolute;
      content: "";
      display: block;
      height: 1px;
      background-color: $primaryBgDarkGrey;
      bottom: 0;
      left: 26px;
      right: 0;
    }
  }
  .add-new {
    display: flex;
    align-items: center;
    margin-top: 13px;
    font-size: 13px;
    line-height: 1.23;
    color: #000;
    text-transform: uppercase;
    text-decoration: none;
    .svg-icon {
      font-size: 17px;
      top: 0;
      margin-right: 16px;
    }
  }
}
.form-group.margin-right {
  margin-right: 25px;
}
.top_border {
  border-top: 1px solid #d8d8d8;
  margin-top: -15px;
}
</style>
