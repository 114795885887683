<template lang="pug">
PopupCardSlot(
  :isOpen="isDeleteSchedulePopupOpen",
  maxWidth="675px"
)
  .delete-schedule-popup
    h1 Are you sure you want to delete this slot?
    p Please confirm to continue
    .popup-btns
      BaseBtn.x-lg.red-outline(@click="closeDeleteSchedulePopup") CANCEL
      BaseBtn.x-lg.green(@click.prevent="closeDeleteSchedulePopup") YES, CONTINUE


.content-wrapper
  .card.router-view-card
    section.all-clinics
    h2.big_title AVAILABLE APPOINTMENT SLOTS
    TableTitle(title="All Schedules")
      template(#actions)
        select.table-action-selector.table-action-selector--hospital(
          :title="clinicTitle"
          @change="setFilterValueClinic($event.target.value)"
          style="margin-right: 24px;"
        )
          option(value="") CLINIC NAME
          option(v-for="clinic in clinics" :key="clinic.id" :value="clinic.id") {{ clinic.name }}
        select.table-action-selector.table-action-selector--insurance(
          style="margin-right: 24px;"
          :title="insuranceTitle",
          @change="setFilterValueInsurance($event.target.value)"
        )
          option INSURANCE
          option(value="true") Insured
          option(value="false") Uninsured
        DateSelector(@filterByDate="filterByDate" :minDate="new Date()")
    .table
      .th
        
        .td(@click.prevent="changeListOrder('ClinicName', getClinicSchedules)" style="cursor: pointer;") Clinic
          i(style="font-weight: bolder;") {{ ClinicNameColumnSortingIcon }}
        .td(@click.prevent="changeListOrder('DateAndTime', getClinicSchedules)" style="cursor: pointer;") Date and time
          i(style="font-weight: bolder;") {{ DateAndTimeColumnSortingIcon }}
        .td Insurance
      .tr(v-for="schedule in schedules",
        :key="schedule.id",)
        .td.bold {{ schedule.clinic.name }}
        .td
          div {{ getDateFromUnixFormatted(schedule.availableAt) }}
          .second-row-md-grey {{ getTimeFromUnixFormatted(schedule.availableAt) }}
        .td
          base-icon(name="yes", v-if="schedule.insuranceRequired")
          base-icon(name="no", v-else)
        
    Pagination(
      v-if="pagination  && schedules != null && schedules.length",
      :pagination="pagination",
      :reloadFn="getClinicSchedules"
      @changePagination="changePagination"
    )
    .no-results(v-if="schedules != null && !schedules.length") 
      base-icon(name="exclamation") 
      span No matching records

</template>

<script>
import { defineComponent } from "vue";
import moment from "moment";

import { getDateFromUnixFormatted, getTimeFromUnixFormatted } from "@/helpers";

import TableTitle from "@/components/TableTitle.vue";
import DateSelector from "@/components/DateSelector.vue";
import Pagination from "@/components/Pagination.vue";
import listPagesMixin from "@/mixins/listPagesMixin";
import PopupCardSlot from "@/components/PopupCardSlot.vue";

import {
  fetchCommunityCareOrganizations,
  fetchCommunityCareOrganizationsClinics,
} from "@/api/communityOrganization";

import { apiStatus, apiStatusComputedFactory } from "@/api";
const { IDLE, PENDING, SUCCESS, ERROR } = apiStatus;
import { getClinicSchedules } from "@/api/patientReferral";
import sortCCOSchedules from "@/mixins/sortCCOSchedules";

export default defineComponent({
  components: {
    TableTitle,
    DateSelector,
    Pagination,
    PopupCardSlot,
  },
  computed: {
    ...apiStatusComputedFactory("getClinicSchedulesStatus"),
  },
  mixins: [listPagesMixin, sortCCOSchedules],

  data() {
    return {
      isAppointmentRecordOverlayOpen: false,
      isAppointmentNotesOverlayOpen: false,
      isAddScheduleOverlayOpen: false,
      isDeleteSchedulePopupOpen: false,

      getClinicSchedulesStatus: IDLE,
      schedules: null,
      insuranceTitle: "INSURANCE",

      linkedCommunities: [],
      isInsuranceRequired: null,
      clinicTitle: "CLINIC NAME",
      filterIdClinic: null,
      clinics: null,
    };
  },

  methods: {
    filterByDate(date) {
      this.date = date;
      this.getClinicSchedules();
    },
    setFilterValueClinic(value) {
      if (value != "") {
        this.filterIdClinic = value;
        this.clinics.forEach((clinic) => {
          if (clinic.id == value) {
            this.clinicTitle = clinic.name;
          }
        });
      } else {
        this.filterIdClinic = null;
        this.clinicTitle = "CLINIC NAME";
      }
      this.getClinicSchedules();
    },
    setFilterValueInsurance(value) {
      if (value === "true" || value === "false") {
        if (value === "true") {
          this.insuranceTitle = "INSURED";
        } else {
          this.insuranceTitle = "UNINSURED";
        }
        this.isInsuranceRequired = value;
      } else {
        this.insuranceTitle = "INSURANCE";
        this.isInsuranceRequired = null;
      }
      this.getClinicSchedules();
    },
    getDateFromUnixFormatted,
    getTimeFromUnixFormatted,
    openDeleteSchedulePopup() {
      this.isDeleteSchedulePopupOpen = true;
    },
    closeDeleteSchedulePopup() {
      this.isDeleteSchedulePopupOpen = false;
    },
    async getClinicSchedules() {
      this.getClinicSchedulesStatus = PENDING;

      let navigatePage = 1;
      if (this.reqPagination.page) {
        navigatePage = this.reqPagination.page;
      }
      const order = this.listOrder
        ? {
            by: this.listOrder.by,
            direction: this.listOrder.direction,
          }
        : null;

      try {
        const res = await getClinicSchedules({
          params: {
            ...this.reqPagination,
            order,
            collapsedDoctor: false,
            idCommunity: this.$route.params.idCommunity,
            include: ["patientReferral", "clinic", "doctor", "communityCare"],
            isAvailable: "Available",
            availableAt: this.date ?? null,
            isInsuranceRequired: this.isInsuranceRequired ?? null,
            idSpecialtyModule: this.$route.params.idSpecialty,
            idClinic: this.filterIdClinic ?? null,
            page: navigatePage,
          },
        });

        this.schedules = res.data.schedules;
        this.pagination = res.data.pagination;
        this.getClinicSchedulesStatus = SUCCESS;
      } catch (err) {
        this.getClinicSchedulesStatus = ERROR;
        console.error(err);
      }
    },
    async getLinkedCommunities() {
      try {
        const {
          data: { communityCareOrganizations },
        } = await fetchCommunityCareOrganizations({
          params: {
            include: "idClinics",
          },
        });
        this.linkedCommunities = communityCareOrganizations.filter(
          (cco) => cco.id == this.$route.params.idCommunity
        );
      } catch (error) {
        console.error(error);
      }
    },
    async getClinics() {
      try {
        const res = await fetchCommunityCareOrganizationsClinics(
          this.$route.params.idCommunity,
          {
            params: {
              idCommunity: this.$route.params.idCommunity,
            },
          }
        );
        this.clinics = res.data.clinics;
      } catch (error) {
        console.error(error);
      }
    },
  },
  created() {
    this.getLinkedCommunities().then(() => {
      this.getClinics();
      this.getClinicSchedules();
    });
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/components/actions-below-table-container.scss";
.card {
  position: relative;
}
.actions-below-table-container {
  top: calc(100% + 44px);
}
.td {
  &:nth-child(1) {
    width: math.percentage(math.div(451, $tabContentCardWidth));
  }
  &:nth-child(2) {
    width: math.percentage(math.div(356, $tabContentCardWidth));
  }
  &:nth-child(3) {
    width: math.percentage(math.div(373, $tabContentCardWidth));
  }
}
.delete-schedule-popup {
  max-width: 555px;
  margin: 0 auto;
  h1 {
    width: 100%;
    max-width: 549px;
    margin: 39px auto 30px;
    font-size: 28px;
    font-weight: 700;
    line-height: 1.21;
    letter-spacing: 0.14px;
    text-align: center;
  }
  p {
    margin-top: 0;
    margin-bottom: 32px;
    font-size: 16px;
    line-height: 1.19;
    letter-spacing: 0.23px;
    text-align: center;
  }
  .popup-btns {
    margin-bottom: 39px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .btn {
      width: 260px;
    }
  }
}
</style>
<style>
.main-container {
  padding-bottom: 100px;
}
</style>
