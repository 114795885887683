<template lang="pug">
SideOverlaySlot(
  :isOpen="isOpen",
  title="APPOINTMENT NOTES"
  @closeOverlay="closeOverlay"
)
  template(#contentTop)
    section.patient-section
      p <strong>Patient: {{ getUserFormatted(patientAppointment.patient) }} </strong>
  template(#scrollbox)
    .overlay-pairs.history-section
      .item(v-for="note in notes" :key="note.id")
        .new {{ note.status }}
        .date-user
          .date-user__line
          .date-user__txt {{ getNoteTitle(note) }}
        .pairs.columns
          .pair.column.is-half
            .pair-label Type
            .pair-txt <strong>{{ note.type}}</strong>
          .pair.column.is-half
            .pair-label Name of Staff
            .pair-txt <strong>{{ getUserFormatted(note.createdBy) }}</strong>
        .pairs.columns
          .pair.column
            .pair-label Notes
            .pair-txt
              p {{ note.content }}
        .pairs.columns(v-if="note.file")
          .pair.column
            .pair-label File Download
            .pair-txt
              a(href="#" @click.prevent="preview(note.file.signedUrl)")
                |  {{ note.file.name }}
                base-icon(name="external-link")
        div(v-if="notificationCanBeMarked(note)")
          BaseBtn.md.green.mark-done(
            href="#", 
            @click.prevent="openMarkAsDone(note.id)"
            v-if=("!note.markOpen")
          ) MARK AS DONE
          div.mark-done-confirmations(v-else)
            BaseBtn.md.blue.margin-right(@click.prevent="markNoteAsCompleted(note.id)") Yes, Complete
            BaseBtn.md.redFill(@click.prevent="resetMarkedAsDone(note.id)") NO, CANCEL

  template(#footer)
    .flex.flex--space-between
      BaseBtn.x-lg.blue-outline(v-if="showAppointmentsFor == 'clinics' " @click="viewAppointment") VIEW APPOINTMENT
      BaseBtn.x-lg.blue-outline(v-else-if="showAppointmentsFor == 'hospitals' " @click="viewAppointment") VIEW APPOINTMENT
      BaseBtn.x-lg.blue-outline(v-else @click="closeOverlay") VIEW APPOINTMENT
      BaseBtn.x-lg.blue(
        href="#",
        @click.prevent="openAddNewAppointmentNoteOverlay"
      ) ADD NEW NOTE

AddNewAppointmentNoteOverlay(
  :isOpen="isAddNewAppointmentNoteOverlayOpen",
  :patientAppointment="patientAppointment"
  @closeAddNewAppointmentNoteOverlay="closeAddNewAppointmentNoteOverlay"
)
</template>

<script>
import { defineComponent } from "vue";

import SideOverlaySlot from "@/components/overlays/SideOverlaySlot";
import AddNewAppointmentNoteOverlay from "@/components/overlays/side-overlays/AddNewAppointmentNoteOverlay.vue";
import listPagesMixin from "@/mixins/listPagesMixin";

import { fetchNotification, putNotification } from "@/api/notifications.ts";

import { getUserFormatted, getNoteTitle, getFileSignedUrl } from "@/helpers";
import { preview } from "@/helpers/preview";

import { mapGetters } from "vuex";

import { apiStatus, apiStatusComputedFactory } from "@/api";
import { fetchFileById } from "@/api/filesApi";
const { IDLE, PENDING, SUCCESS, ERROR } = apiStatus;

export default defineComponent({
  components: {
    AddNewAppointmentNoteOverlay,
    SideOverlaySlot,
  },
  mixins: [listPagesMixin],

  emits: [
    "closeAppointmentNotesOverlay",
    "fetchPatientAppointmentNotifications",
    "openViewAppointment",
  ],

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    patientAppointment: {
      required: false,
      type: Object,
    },
    showAppointment: {
      required: false,
    },
    hasNoNotes: {
      required: false,
    },
    showAppointmentsFor: {
      required: false,
      default: "",
      type: String,
    },
  },

  data() {
    return {
      getUserFormatted,
      getNoteTitle,
      isAddNewAppointmentNoteOverlayOpen: false,
      fetchPatientAppointmentNotificationsStatus: IDLE,
      putPatientAppointmentNotificationsStatus: IDLE,
      markNoteAsCompletedStatus: IDLE,
      notes: null,
      runFirstTime: true,
    };
  },
  computed: {
    ...mapGetters(["getUserId", "getUserFullName", "getUserRoleContext"]),
  },
  methods: {
    viewAppointment() {
      this.$emit("openViewAppointment", this.patientAppointment.id);
    },

    notificationCanBeMarked(notification) {
      let canBeMarked = false;
      if (notification.status === "New") {
        if (this.getUserRoleContext.hospital) {
          canBeMarked = notification.origin === "Clinic";
        } else if (this.getUserRoleContext.clinicHealthSystem) {
          canBeMarked = notification.origin === "Hospital";
        }
      }
      return canBeMarked;
    },

    async markNoteAsCompleted(notificationID) {
      this.markNoteAsCompleted = PENDING;
      try {
        const res = await putNotification(notificationID, {
          completedBy: this.getUserId,
          lastViewedBy: this.getUserId,
          status: "Completed",
        });
        this.markNoteAsCompleted = SUCCESS;
        this.$emit("fetchPatientAppointmentNotifications");
        this.$emit("closeAppointmentNotesOverlay");
        this.$store.dispatch("fetchNotificationCount");
      } catch (err) {
        this.markNoteAsCompleted = ERROR;
        console.error(err);
      }
    },

    async fetchPatientAppointmentNotifications() {
      this.fetchPatientAppointmentNotificationsStatus = PENDING;
      try {
        const res = await fetchNotification({
          params: {
            idEntity: this.patientAppointment.id,
            entityType: "appointment",
            include: [
              "patientAppointment",
              "patient",
              "completedBy",
              "lastViewedBy",
              "file",
              "createdBy",
            ],
          },
        });

        this.notes = await Promise.all(
          res.data.notifications.map(this.fetchFile)
        );
        if (!this.notes.length && this.runFirstTime) {
          this.isAddNewAppointmentNoteOverlayOpen = true;
          this.runFirstTime = false;
        }
        this.fetchPatientAppointmentNotificationsStatus = SUCCESS;
      } catch (err) {
        this.fetchPatientAppointmentNotificationsStatus = ERROR;
        console.error(err);
      }
    },

    async fetchFile(note) {
      if (note.file) {
        const { data } = await fetchFileById(note.file.id);
        const file = data.file;
        file.token = data.token;
        note.file.signedUrl = getFileSignedUrl(file);
      }

      return note;
    },

    preview(src) {
      preview(src);
    },

    closeOverlay() {
      this.$emit("closeAppointmentNotesOverlay");
    },
    openMarkAsDone(noteId) {
      this.notes.forEach((element) => {
        if (element.id == noteId) {
          element.markOpen = true;
        }
      });
    },
    resetMarkedAsDone(noteId) {
      this.notes.forEach((element) => {
        if (element.id == noteId) {
          element.markOpen = false;
        }
      });
    },
    openAddNewAppointmentNoteOverlay() {
      this.isAddNewAppointmentNoteOverlayOpen = true;
    },
    closeAddNewAppointmentNoteOverlay() {
      this.isAddNewAppointmentNoteOverlayOpen = false;
      this.$emit("fetchPatientAppointmentNotifications");
      this.fetchPatientAppointmentNotifications();
      if (!this.notes.length) {
        this.closeOverlay();
      }
    },
  },
  created() {
    if (!this.patientAppointment?.id || !this.isOpen) return;
    this.fetchPatientAppointmentNotifications();
    if (this.hasNoNotes) {
      this.isAddNewAppointmentNoteOverlayOpen = true;
    }
  },
});
</script>

<style scoped lang="scss">
.mark-done-confirmations {
  margin-top: 9px;
  display: inline-block;
}
.margin-right {
  margin-right: 15px;
}
.patient-section {
  margin-bottom: 31px;
  padding: 37px 24px 25px;
  background-color: $primaryBgBlue;
  p {
    margin: 0 0 12px;
    font-size: 21px;
    font-weight: 500;
    line-height: 1.1;
    color: $primaryLightBlue;
    overflow: hidden;
    strong {
      font-weight: 700;
    }
  }
}
</style>
