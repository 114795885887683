<template lang="pug">
FullScreenOverlaySlot(
  :isOpen="isOpen",
  headerIcon="icc-logo",
  :title="overlayTitle"
  @closeOverlay="closeOverlay"
)
  template(#breadcrumbs)
    h1.breadcrumbs-title IL CARE CONTINUUM
  template(#content)
  
    .card.hospital_details
      .columns.is-variable
        .column.is-two-thirds
          .form-group(:class="{ 'form-group__invalid': v$.ProviderGroup.name.$errors.length }")
            label.label--margin-sm Provider Group Name (Required)
            input.input.text-lg(type="text", v-model="ProviderGroup.name" @blur="v$.ProviderGroup.name.$touch()")
            .form-group__errors(v-if="v$.ProviderGroup.name.$dirty && v$.ProviderGroup.name.$errors.length")
              .form-group__error(v-if="v$.ProviderGroup.name.required.$invalid") Provider Group Name is required!
        .column.is-one-fifth
          .form-group(:class="{ 'form-group__invalid': v$.ProviderGroup.contact.businessPhone.$errors.length }")
            label.label--margin-sm Phone Number (Required)
            input.input.text-lg(
              type="text", 
              v-model="ProviderGroup.contact.businessPhone" 
              @blur="v$.ProviderGroup.contact.businessPhone.$touch()"
              placeholder="xxx-xxx-xxxx" 
              v-maska="'###-###-####'"
              @maska="businessPhoneRawValue"
            )
            .form-group__errors(v-if="v$.ProviderGroup.contact.businessPhone.$dirty && v$.ProviderGroup.contact.businessPhone.$errors.length")
              .form-group__error(v-if="v$.ProviderGroup.contact.businessPhone.required.$invalid") Phone Number is required!
              .form-group__error(v-if="v$.ProviderGroup.contact.businessPhone.minLength.$invalid") Phone Number is required!
        .column.is-one-fifth.phone-ext
          .form-group
            label.label--margin-sm Ext.
            input.input.text-lg(type="text", v-model="ProviderGroup.contact.businessPhoneExtension")
      .columns.is-variable
        .column.is-full
          .form-group(:class="{ 'form-group__invalid': v$.ProviderGroup.address.address.$errors.length }")
            label.label--margin-sm Address (Required)
            input.input.text-lg(type="text", v-model="ProviderGroup.address.address" @blur="v$.ProviderGroup.address.address.$touch()")
            .form-group__errors(v-if="v$.ProviderGroup.address.address.$dirty && v$.ProviderGroup.address.address.$errors.length")
              .form-group__error(v-if="v$.ProviderGroup.address.address.required.$invalid") Address is required!
      Address(
        @addressResults="addressResults"
        :institution="ProviderGroup"
        textSize="text-lg"
      )

    .card.modules
      .title-md-thin-with-action
        h2.title.title--md-thin(style="margin-bottom: 27px;") MODULES
      .table.table--no-side-padding(v-if="activereferralModules")
        .th.specialty_modules
          .td Referral Modules
          .td Active
        .tr.tr__row(v-for="element in activereferralModules", :key="element.id")
          .td
            h2 {{ element.moduleTitle }}
          .td
            BaseToggle(
              :key="element.id"
              :modelValue="element.toggleOn"
              @change="changeActiveReferrals(element.id, $event.target.checked)"
            )
    .card.help_resources
      h2.title.title--md-thin HELP & RESOURCES
      .columns.is-variable
        .column.is-one-third
          .form-group
            label(for="user-support-text") User Support Text
            textarea#user-support-text(style="height:250px;", v-model="ProviderGroup.userSupportText")
        .column.is-one-third
          .form-group
            label(for="resources-text") Resources Text
            textarea#resources-text(style="height:250px;", v-model="ProviderGroup.resourcesText")

  template(#footer)
    BaseBtn.btn.lg.red-outline(href="#", @click.prevent="closeOverlay") CANCEL CHANGES
    BaseBtn.btn.lg.green(
      @click.prevent="saveProviderGroup"
      :disabled="v$.$invalid || isSubmitting"
      :class="{spinner: isSubmitting }"
    ) SAVE AND EXIT

</template>

<script>
import { defineComponent } from "vue";
import FullScreenOverlaySlot from "@/components/overlays/FullScreenOverlaySlot.vue";
import UploadAndPreviewFile from "@/components/UploadAndPreviewFile.vue";

import { fetchReferralModules } from "@/api/referralModulesApi";

import {
  createProviderGroup,
  fetchProviderGroup,
  updateProviderGroup,
  getProviderGroupReferral,
} from "@/api/providerGroupApi";
import { apiStatus, apiStatusComputedFactory } from "@/api";
const { IDLE, PENDING, SUCCESS, ERROR } = apiStatus;
import remove from "lodash/remove";
import Address from "@/components/Address.vue";
import { fetchFileById, uploadFile } from "@/api/filesApi";
import { getFileSignedUrl } from "@/helpers";

import useVuelidate from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import { maska, mask } from "maska";

export default defineComponent({
  directives: { maska },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    let args = {
      ProviderGroup: {
        name: { required },
        address: {
          address: {
            required,
          },
        },
        contact: {
          businessPhone: {
            required,
            minLength: minLength(12),
          },
        },
      },
    };

    return args;
  },
  components: {
    FullScreenOverlaySlot,
    Address,
    UploadAndPreviewFile,
  },
  emits: ["closeProviderGroupOverlay", "reloadProviderGroups"],

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    providerGroupId: {
      required: false,
    },
  },

  data() {
    return {
      isSubmitting: false,

      fetchProviderGroupStatus: IDLE,
      updateProviderGroupStatus: IDLE,
      getProviderGroupReferralStatus: IDLE,
      ProviderGroup: {
        name: null,
        contact: {
          businessPhone: null,
          businessPhoneRaw: null,
          businessPhoneExtension: null,
        },
        address: {
          address: null,
          place: null,
          state: null,
          zipCode: null,
          idPlace: null,
          idState: null,
          idZipCode: null,
        },
      },
      referralsStatusChanged: [],
      referralsStatusAdd: [],
      isModuleEnabled: true,
      overlayTitle: this.providerGroupId
        ? "MANAGE PROVIDER GROUP"
        : "ADD NEW PROVIDER GROUP",
      referralModules: null,
      activeReferrals: [],
    };
  },

  computed: {
    ...apiStatusComputedFactory([
      "createProviderGroupStatus",
      "fetchProviderGroupStatus",
      "updateProviderGroupStatus",
    ]),
    activereferralModules: function () {
      let activeReferrals;
      if (!this.activeReferrals) {
        activeReferrals = this.referralModules;
      } else {
        activeReferrals = this.activeReferrals;
      }
      if (this.referralModules) {
        return this.referralModules.filter(function (u) {
          if (activeReferrals) {
            u.toggleOn = false;
            activeReferrals.forEach((element) => {
              if (u.id == element.referralModule.id) {
                u.toggleOn = true;
              }
            });
          }
          return u.isModuleEnabled;
        });
      } else {
        return false;
      }
    },
  },

  methods: {
    businessPhoneRawValue: function (event) {
      this.ProviderGroup.contact.businessPhoneRaw =
        event.target.dataset.maskRawValue;
    },
    addressResults(city, state, zip, idPlace, idState, idZipCode) {
      this.ProviderGroup.address.place = city;
      this.ProviderGroup.address.state = state;
      this.ProviderGroup.address.zipCode = zip;
      this.ProviderGroup.address.idPlace = parseInt(idPlace);
      this.ProviderGroup.address.idState = parseInt(idState);
      this.ProviderGroup.address.idZipCode = parseInt(idZipCode);
    },
    closeOverlay() {
      this.$emit("closeProviderGroupOverlay");
    },

    changeActiveReferrals(id, checked) {
      if (this.providerGroupId === null) {
        if (checked === true) {
          if (this.referralsStatusAdd.includes(id)) {
            return;
          } else {
            this.referralsStatusAdd.push(id);
          }
        } else {
          this.referralsStatusAdd = remove(
            this.referralsStatusAdd,
            function (n) {
              return n !== id;
            }
          );
        }
      } else {
        let found = false;
        this.referralsStatusChanged.forEach((element) => {
          if (element.idReferralModule == id) {
            found = true;
            element.active = checked;
          }
        });
        if (!found) {
          this.referralsStatusChanged.push({
            active: checked,
            idReferralModule: id,
          });
        }
      }
    },

    async saveProviderGroup() {
      if (this.providerGroupId === null) {
        await this.createProviderGroup();
      } else {
        await this.updateProviderGroup();
      }
      this.$emit("reloadProviderGroups");
      this.closeOverlay();
    },
    async createProviderGroup() {
      this.isSubmitting = true;

      this.createProviderGroupStatus = PENDING;

      try {
        this.ProviderGroup.contact.businessPhone =
          this.ProviderGroup.contact.businessPhoneRaw;
        delete this.ProviderGroup.contact.businessPhoneRaw;

        await createProviderGroup({
          providerGroup: {
            ...this.ProviderGroup,
          },
          activeReferrals: this.referralsStatusAdd,
        });
        this.createProviderGroupStatus = SUCCESS;
      } catch (err) {
        console.log(err);
        this.createProviderGroupStatus = ERROR;
      } finally {
        this.isSubmitting = false;
      }
    },
    async updateProviderGroup() {
      this.isSubmitting = true;
      this.updateProviderGroupStatus = PENDING;
      try {
        this.ProviderGroup.contact.cellPhone =
          this.ProviderGroup.contact.businessPhoneRaw;
        delete this.ProviderGroup.contact.businessPhoneRaw;
        await updateProviderGroup(`${this.providerGroupId}`, {
          providerGroup: { ...this.ProviderGroup,},
          referralsStatusChanged: this.referralsStatusChanged,
        });
        this.updateProviderGroupStatus = SUCCESS;
      } catch (err) {
        console.log(err);
        this.updateProviderGroupStatus = ERROR;
      } finally {
        this.isSubmitting = false;
      }
    },

    async getProviderGroupReferral() {
      this.getProviderGroupReferralStatus = PENDING;
      try {
        const res = await getProviderGroupReferral(this.providerGroupId);
        this.activeReferrals = res.data.providerGroupReferrals;
        this.getProviderGroupReferralStatus = SUCCESS;
      } catch (err) {
        console.log(err);
        this.getProviderGroupReferralStatus = ERROR;
      }
    },
    async fetchProviderGroup() {
      this.fetchProviderGroupStatus = PENDING;
      try {
        const res = await fetchProviderGroup(this.providerGroupId);
        this.ProviderGroup = res.data;
        this.ProviderGroup.contact.businessPhone = mask(
          this.ProviderGroup.contact.businessPhone,
          "###-###-####"
        );
        this.getProviderGroupReferral();

        this.fetchProviderGroupStatus = SUCCESS;
      } catch (err) {
        console.log(err);
        this.fetchProviderGroupStatus = ERROR;
      }
    },
    async fetchReferralModules() {
      try {
        const res = await fetchReferralModules({
          params: {
            size: 100,
          },
        });
        this.referralModules = res.data.referralModules;
        this.pagination = res.data.pagination;
      } catch (err) {
        console.error(err);
      }
    },
  },
  created() {
    this.fetchReferralModules();
    if (this.providerGroupId === null) return;
    this.fetchProviderGroup();
  },
});
</script>

<style scoped lang="scss">
.phone-ext {
  max-width: 13.4%;
}
.help_resources h2.title--md-thin {
  margin-bottom: 38px;
}
.table .th.hr_only {
  padding-bottom: 11px;
  margin-bottom: 53px;
}
.table .tr.tr_child {
  padding-top: 0px;
  padding-bottom: 33px;
}
.tr.tr__row {
  padding-top: 33px;
  padding-bottom: 33px;
}
.no_bottom_border {
  border-bottom: 0px;
}
.card {
  padding: 35px $secondaryCardsSidePadding 20px $secondaryCardsSidePadding;
  &.hospital_details {
    padding-top: 55px;
    padding-bottom: 45px;
    .form-group {
      margin-bottom: 18px;
    }
  }
  &.help_resources {
    padding-top: 45px;
  }
  &.modules {
    padding-top: 45px;
    padding-bottom: 0px;
  }
}
.columns.is-variable {
  --columnGap: 1.575%;
}
.columns + .hr {
  background-color: $primaryBgDarkGrey;
  margin-top: -21px;
  margin-bottom: 24px;
}
.card > .columns:last-child.info-pair,
.card > .columns:last-child .info-pair {
  margin-bottom: 0;
}
.svg-icon--eye-red,
.svg-icon--eye-green,
.svg-icon--yes,
.svg-icon--no {
  width: 50px;
  height: 50px;
  top: 0;
}

// Page specific styles----------------------------------------
.breadcrumbs-title {
  margin: 0;
  color: $primaryLightBlue;
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.12px;
}
.procedure-dropdown-instructions {
  margin-top: -20px;
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 1.29;
}
.table {
  .td {
    &:nth-child(1) {
      width: math.percentage(math.div(1106, $tabContentCardWidth));
    }
    &:nth-child(2) {
      width: math.percentage(math.div(74, $tabContentCardWidth));
    }
  }
  .row-main-options {
    padding: 35px 0;
    .base-checkbox:not(:last-child) {
      margin-right: 84px;
    }
  }
  .th {
    padding-bottom: 25px;
  }
}
</style>
