<template lang="pug">

TabsCard(variant="lg", v-if= " $route.name != 'provider-groups.referral-status.reschedule' " )
  template(#tabs)
    router-link.tab(:to="{name: 'provider-groups.referral-status.requested'}")
      .tab__inner
        .tab__first-child {{ summary.requested }}
        .tab__second-child Requested
    router-link.tab(:to="{name: 'provider-groups.referral-status.unable-to-contact'}")
      .tab__inner
        .tab__first-child {{ summary.unable_to_contact }}
        .tab__second-child Unable to Contact
    router-link.tab(:to="{name: 'provider-groups.referral-status.contacted'}")
      .tab__inner
        .tab__first-child {{ summary.contacted }}
        .tab__second-child Contacted
  template(#content)
    router-view(
      @refreshAppointmentsSummary="refreshAppointmentsSummary"
    )
router-view(v-else)

</template>

<script>
import { defineComponent } from "vue";
import TabsCard from "@/components/TabsCard.vue";
import { fetchProviderPatientReferral } from "@/api/providerPatientReferral";

export default defineComponent({
  components: {
    TabsCard,
  },

  emits: ["patientReferralStats"],

  props: {
    referralModule: {
      required: false,
    },
  },
  data() {
    return {
      summary: {
        requested: 0,
        unable_to_contact: 0,
        contacted: 0,
      },
    };
  },
  methods: {
    refreshAppointmentsSummary() {
      this.fetchSummaryInfo();
    },
    fetchSummaryInfo() {
      this.fetchProviderPatientReferral("REQUESTED");
      this.fetchProviderPatientReferral("UNABLE_TO_CONTACT");
      this.fetchProviderPatientReferral("CONTACTED");
    },
    async fetchProviderPatientReferral(stageParam) {
      try {
        const res = await fetchProviderPatientReferral({
          params: {
            ...this.reqPagination,
            include: ["patient", "cbo", "referralModule", "provider"],
            stage: stageParam,
            idProvider: this.$route.params.idProvider,
          },
        });
        switch (stageParam) {
          case "REQUESTED":
            this.summary.requested = res.data.pagination.quantity;
            break;
          case "UNABLE_TO_CONTACT":
            this.summary.unable_to_contact = res.data.pagination.quantity;
            break;
          case "CONTACTED":
            this.summary.contacted = res.data.pagination.quantity;
            break;
        }

        this.pagination = res.data.pagination;

        const emitValues = {
          contacted: this.summary.contacted,
          unableToContact: this.summary.unable_to_contact,
        };

        this.$emit("patientReferralStats", emitValues);
      } catch (err) {
        console.error(err);
      }
    },
  },
  created() {
    this.fetchSummaryInfo();
  },
});
</script>
