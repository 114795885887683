<template lang="pug">
ul
  WithAccessLevel(:allow="[]")
    template(v-slot:default="accessLevel")
      li(v-if="accessLevel.authorized")
        router-link(:to="{name: 'log-in-page'}") Log In
  li
    router-link(:to="{name: 'style-guide'}") Style Guide
  li
    router-link(:to="{name: 'appointment-status.requested'}") Appointment Status
  li
    router-link(:to="{name: 'notifications'}") Notifications
  li
    router-link(:to="{name: 'dashboard', params: {idClinic: 7}}") Dashboard
  li
    router-link(:to="{name: 'manage-care'}") Ongoing Care
  li
    router-link(:to="{name: 'api-demo'}") API Demo
  li
    router-link(:to="{name: 'new-fit-kit', params: { idClinic: 18 }}") New FIT Kit
  li
    router-link(:to="{name: 'fit-kit-status', params: { idClinic: 18 }}") FIT Kit Status
  li
    router-link(:to="{name: 'fit-outside-icc', params: { idClinic: 18 }}") FIT Outside ICC Appointments
  li
    router-link(:to="{name: 'fit-referrals', params: { idClinic: 18 }}") FIT Referrals  
  li
    router-link(:to="{name: 'system-settings'}") SYSTEM SETTINGS
  li
    router-link(:to="{name: 'organizations'}") Organizations
  li
    //- TODO: rectify param
    router-link(:to="{name: 'all-clinics', params: {chsId: 9}}") All Clinics
  li
    router-link(:to="{name: 'all-actions'}") All Actions
  li
    router-link(:to="{name: 'users-super-admin'}") List of super admin users
  li
    router-link(:to="{name: 'users-clinical-health-systems', params: { idClinicHealthSystem: 10} }") List of clinical health systems users
  li
    router-link(:to="{name: 'users-hospitals', params: { idHospital: 8} }") List of hospitals users  
  li
    router-link(:to="{name: 'hospitals.appointment-status', params: { idHospital: 8, idSpecialty: 1} }") COLON: Hospitals
  li
    router-link(:to="{name: 'clinics.appointment-status', params: { idClinic: 12, idSpecialty: 1} }") COLON: Clinics
  li
    router-link(:to="{name: 'hospitals.appointment-status', params: { idHospital: 8, idSpecialty: 6} }") HEPB: Hospitals
  li
    router-link(:to="{name: 'clinics.appointment-status', params: { idClinic: 12, idSpecialty: 6} }") HEPB: Clinics 
  li
    router-link(:to="{name: 'resources-and-help'}") Resources & Help
  li
    router-link(:to="{name: 'reports'}") Reports
  //- TODO: enable
  //- li
  //-   router-link(:to="{name: 'primary-care-clinic'}") Primary Care Clinic
  //- TODO: enable
  //- li
  //-   router-link(:to="{name: 'primary-care-community-organization'}") Primary Care Community Organization
  

</template>

<script>
import { defineComponent } from "vue";
import WithAccessLevel from "@/components/WithAccessLevel";

export default defineComponent({
  components: {
    WithAccessLevel,
  },
});
</script>

<style scoped lang="scss"></style>
