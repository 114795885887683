<template lang="pug">
section.hepatitis-b.dashboard-standard-section
  h3.title.title--md {{specialty?.specialtyModule?.moduleTitle}}
  .status-line
    h4.title.title--sm Appointment Status
    BaseBtn.x-lg.blue(
      v-if="clinicStaffInTrainingNotAllowed"
      :to="{name: 'clinics.scheduling.search-patient', params: {idClinic: clinicId, idSpecialty: specialty.specialtyModule.id}}"
    ) SCHEDULE PATIENT
    router-link.availibility(:to="{name: 'clinics.schedules', params: {idClinic: clinicId, idSpecialty: specialty.specialtyModule.id}}") {{specialty.countAvailableSchedule}} Slots Available
  .box-links-sm
    router-link(:to="{name: 'clinics.appointment-status.requested', params: {idClinic: clinicId, idSpecialty: specialty.specialtyModule.id}}")
      BoxLinkSm(text="Requested", :number="specialty?.appointmentStatus?.requested ?? 0")
    router-link(:to="{name: 'clinics.appointment-status.confirmed', params: {idClinic: clinicId, idSpecialty: specialty.specialtyModule.id}}")
      BoxLinkSm(text="Confirmed", :number="specialty?.appointmentStatus?.confirmed ?? 0")
    router-link(:to="{name: 'clinics.appointment-status.pending-clinic-review', params: {idClinic: clinicId, idSpecialty: specialty.specialtyModule.id}}")
      BoxLinkSm(text="For Clinic Review", :number="specialty?.appointmentStatus?.completedColonoscopyPendingClinicReview ?? 0")
    router-link(:to="{name: 'clinics.appointment-status.archived', params: {idClinic: clinicId, idSpecialty: specialty.specialtyModule.id}}")
      BoxLinkSm(text="Archived", :number="specialty?.appointmentStatus?.archives ?? 0")
  .line-graph-wrapper(v-if="specialty.iccStats")
    h4.title.title--sm Last 30 Days
    LineStats(:stats="stats")
  h4.title.title--sm(v-if="specialty?.specialtyModule?.isManageCare") Ongoing Care
  .box-links-sm(v-if="specialty?.specialtyModule?.isManageCare && ongoingCarePatientsCounts")
    router-link(:to="{name: 'clinics.ongoing-care.active-patients', params: {idClinic: clinicId, idSpecialty: specialty.specialtyModule.id}}")
      BoxLinkSm(text="Active Patients", :number="ongoingCarePatientsCounts?.active ?? 0")
    router-link(:to="{name: 'clinics.ongoing-care.archived', params: {idClinic: clinicId, idSpecialty: specialty.specialtyModule.id}}")
      BoxLinkSm(text="Archived", :number="ongoingCarePatientsCounts?.archived ?? 0")
</template>

<script>
import { defineComponent } from "vue";
import BoxLinkSm from "@/components/BoxLinkSm.vue";
import LineStats from "@/components/LineStats.vue";
import { fetchOnGoingCarePatient } from "@/api/onGoingCareApi";
import { denyClinicStaffTraining } from "@/mixins/denyClinicStaffTraining";
import listPagesMixin from "@/mixins/listPagesMixin";

export default defineComponent({
  components: { BoxLinkSm, LineStats },

  props: {
    clinicId: {
      type: Number,
      required: true,
    },
    specialty: {
      type: Object,
      required: true,
    },
  },

  mixins: [listPagesMixin, denyClinicStaffTraining],

  data() {
    return {
      stats: [
        {
          label: "Completed",
          number: this?.specialty?.iccStats?.completed ?? 0,
          backgroundColor: "#96bf3c",
        },
        {
          label: "Not Completed",
          number: this?.specialty?.iccStats?.notCompleted ?? 0,
          backgroundColor: "#d5351d",
        },
      ],
      ongoingCarePatientsCounts: null,
    };
  },

  methods: {
    async fetchOnGoingCarePatient() {
      delete this.reqPagination.quantity;
      try {
        const res = await fetchOnGoingCarePatient({
          params: {
            size: 99999999999999,
            idSpecialtyModule: this.specialty.specialtyModule.id,
            filters: {
              idClinic: this.clinicId,
              status: "active",
            },
          },
        });
        this.ongoingCarePatientsCounts = {
          active: res.data.activeCount ?? 0,
          archived: res.data.archivedCount ?? 0,
        };
      } catch (err) {
        console.error(err);
      }
    },
  },
  created() {
    this.fetchOnGoingCarePatient();
  },
});
</script>
