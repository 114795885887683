// @ts-nocheck
import api from "./api";

const patientUrl = "/patient";

export const fetchPatient = (id, data = {}) => {
  return api.get(patientUrl + "/" + id, data);
};

export const putPatient = (id, data = {}) => {
  return api.put(patientUrl + "/" + id, data);
};

export const fetchPatientFitKit = (id, data = {}) => {
  return api.get(patientUrl + "/" + id + "/fit-kit", data);
};
