<template lang="pug">
.content-wrapper
  .card.router-view-card
    section.all-clinics
      h2.big_title RESCHEDULE APPOINTMENT 
        span for 
          span.bold(v-if="oldSchedule?.patient") {{ getUserFormatted(oldSchedule.patient) }}
    TableTitle(title="Available Dates"
    )
      template(#actions)
        select.table-action-selector.table-action-selector--hospital(
          :title="hospitalTitle"
          @change="setFilterValueHospital($event.target.value)"
          style="margin-right: 24px;"
        )
          option(value="") HOSPITAL NAME
          option(v-for="hospital in hospitals" :key="hospital.id" :value="hospital.id") {{ hospital.name }}
        DateSelector(
          @filterByDate="filterByDate"
          :min-date="startDateOffset"
        )
    .table
      .th
        .td Doctor Name
        .td Insurance
        .td Hospital
        .td Date and Time
        .td
      .tr(v-for="schedule in schedules", :key="schedule.id")
        .td {{ getDoctorFormatted(schedule.doctor) }}
        .td
          base-icon(name="yes", v-if="schedule.insuranceRequired")
          base-icon(name="no", v-else)
        .td {{ schedule.hospital.name }}
        .td
          div {{ getDateFromUnixFormatted(schedule.availableAt) }}
          .second-row-md-grey {{ getTimeFromUnixFormatted(schedule.availableAt) }}
        .td.alignButtonRight
          BaseBtn.sm.blue(
            @click.prevent="openConfirmScheduleOverlay(schedule)"
          ) SELECT SCHEDULE
    Pagination(
      v-if="pagination && schedules != null && schedules.length",
      :pagination="pagination",
      :reloadFn="fetchPatientAppointmentAvailableSchedule"
      @changePagination="changePagination"
    )
    .no-results(v-if="schedules != null && !schedules.length") 
      base-icon(name="exclamation") 
      span No matching records

    .actions-below-table-container

ConfirmScheduleOverlay(
  :isOpen="isConfirmScheduleOverlayOpen",
  :newSchedule="newSchedule"
  :oldSchedule="oldSchedule"
  @closeConfirmScheduleOverlay="closeConfirmScheduleOverlay"
)
</template>

<script>
import { defineComponent } from "vue";
import moment from "moment";

import TableTitle from "@/components/TableTitle.vue";
import DateSelector from "@/components/DateSelector.vue";
import Pagination from "@/components/Pagination.vue";
import listPagesMixin from "@/mixins/listPagesMixin";

import { fetchPatientAppointmentAvailableSchedule } from "@/api/patientAppointmentApi";
import { fetchClinic } from "@/api/clinicApi";
import { fetchPatientAppointmentById } from "@/api/patientAppointmentApi";
import { apiStatus, apiStatusComputedFactory } from "@/api";
const { IDLE, PENDING, SUCCESS, ERROR } = apiStatus;
import { fetchCHSHospitals } from "@/api/chsApi";
import { mapGetters } from "vuex";

import ConfirmScheduleOverlay from "@/components/clinics/appointment-status/reschedule/overlays/ConfirmScheduleOverlay";

import {
  getDateFromUnixFormatted,
  getTimeFromUnixFormatted,
  getUserFormatted,
  getDoctorFormatted,
} from "@/helpers";

export default defineComponent({
  components: {
    TableTitle,
    DateSelector,
    Pagination,
    ConfirmScheduleOverlay,
  },
  mixins: [listPagesMixin],
  props: {
    specialtyModule: {
      required: false,
    },
  },
  data() {
    return {
      isAppointmentRecordOverlayOpen: false,
      isAppointmentNotesOverlayOpen: false,
      isConfirmScheduleOverlayOpen: false,
      fetchPatientAppointmentAvailableScheduleStatus: IDLE,
      fetchPatientAppointmentByIdStatus: IDLE,
      schedules: null,
      idCHS: null,
      patient: null,
      newSchedule: null,
      oldSchedule: null,
      hospitalTitle: "HOSPITAL NAME",
      filterIdHospital: null,
      hospitals: null,
      date: null,
      startDateOffset: new Date(Date.now() + 15 * 24 * 60 * 60 * 1000),
    };
  },
  computed: {
    ...mapGetters(["getUserRoleContext"]),
    ...apiStatusComputedFactory(
      "fetchPatientAppointmentAvailableScheduleStatus",
      "fetchPatientStatus"
    ),
  },
  methods: {
    getUserFormatted,
    getDateFromUnixFormatted,
    getTimeFromUnixFormatted,
    getDoctorFormatted,
    async fetchPatientAppointmentById(idSchedule) {
      this.fetchPatientAppointmentByIdStatus = PENDING;
      try {
        const res = await fetchPatientAppointmentById(idSchedule, {
          params: {
            include: [
              "clinic",
              "clinicReport",
              "emergencyContact",
              "hospital",
              "hospitalDoctor",
              "hospitalReport",
              "insurance",
              "pathologyReport",
              "patient",
              "referringDoctor",
            ],
          },
        });

        // this.patient = res.data.patientAppointment.patient;
        this.oldSchedule = res.data.patientAppointment;
        this.fetchPatientAppointmentByIdStatus = SUCCESS;
      } catch (err) {
        this.fetchPatientAppointmentByIdStatus = ERROR;
        console.error(err);
      }
    },
    filterByDate(date) {
      this.date = date;
      this.fetchPatientAppointmentAvailableSchedule();
    },
    setFilterValueHospital(value) {
      if (value != "") {
        this.filterIdHospital = value;
        this.hospitals.forEach((hospital) => {
          if (hospital.id == value) {
            this.hospitalTitle = hospital.name;
          }
        });
      } else {
        this.filterIdHospital = null;
        this.hospitalTitle = "HOSPITAL NAME";
      }
      this.fetchPatientAppointmentAvailableSchedule();
    },
    async fetchClinic() {
      try {
        const res = await fetchClinic(this.$route.params.idClinic);
        this.idCHS = res.data.idClinicHealthSystem;
      } catch (err) {
        console.error(err);
      }
    },
    openConfirmScheduleOverlay(newSchedule) {
      this.newSchedule = newSchedule;
      this.isConfirmScheduleOverlayOpen = true;
    },
    closeConfirmScheduleOverlay() {
      this.isConfirmScheduleOverlayOpen = false;
    },
    async fetchPatientAppointmentAvailableSchedule() {
      this.fetchPatientAppointmentAvailableScheduleStatus = PENDING;
      const startDateOffsetNoMiliseconds = Math.floor(
        this.startDateOffset / 1000
      );

      let navigatePage = 1;
      if (this.reqPagination.page) {
        navigatePage = this.reqPagination.page;
      }
      try {
        const res = await fetchPatientAppointmentAvailableSchedule({
          params: {
            ...this.reqPagination,
            collapsedDoctor: false,
            idClinicHealthSystem: this.idCHS,
            include: ["hospital", "patientAppointment", "doctor"],
            isAvailable: "Available",
            startDate: startDateOffsetNoMiliseconds,
            idSpecialtyModule: this.$route.params.idSpecialty,
            page: navigatePage,
            idHospital: this.filterIdHospital ?? null,
            availableAt: this.date ?? null,
            isInsuranceRequired: this.oldSchedule.insurance.name ? true : false,
          },
        });

        this.schedules = res.data.schedules;
        this.pagination = res.data.pagination;
        this.fetchPatientAppointmentAvailableScheduleStatus = SUCCESS;
      } catch (err) {
        this.fetchPatientAppointmentAvailableScheduleStatus = ERROR;
        console.error(err);
      }
    },
    async fetchCHSHospitals() {
      this.fetchCHSHospitalsStatus = PENDING;
      try {
        const res = await fetchCHSHospitals(
          this.getUserRoleContext.clinicHealthSystem.id
        );
        this.hospitals = res.data.hospitals;
        this.fetchCHSHospitalsStatus = SUCCESS;
      } catch (err) {
        this.fetchCHSHospitalsStatus = ERROR;
        console.error(err);
      }
    },
  },
  created() {
    if (this.$route.params.idClinic === null) return;
    this.fetchClinic().then(() => {
      this.fetchPatientAppointmentById(this.$route.params.idAppointment).then(()=>{
        this.fetchPatientAppointmentAvailableSchedule();
      });
    });
    this.fetchCHSHospitals();
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/components/actions-below-table-container.scss";
select.table-action-selector--insurance {
  width: 175px;
}
.big_title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  span {
    font-weight: normal;
    color: $primaryDarkBlue;
    span {
      font-weight: 700;
    }
  }
}
.card {
  position: relative;
}
.actions-below-table-container {
  top: calc(100% + 44px);
}
.td {
  &:nth-child(1) {
    width: math.percentage(math.div(290, $tabContentCardWidth));
  }
  &:nth-child(2) {
    width: math.percentage(math.div(175, $tabContentCardWidth));
  }
  &:nth-child(3) {
    width: math.percentage(math.div(289, $tabContentCardWidth));
  }
  &:nth-child(4) {
    width: math.percentage(math.div(190, $tabContentCardWidth));
  }
  &:nth-child(5) {
    width: math.percentage(math.div(236, $tabContentCardWidth));
  }
  &.alignButtonRight {
    text-align: right;
  }
  .btn {
    min-width: 196px;
  }
}
</style>
<style>
.main-container {
  padding-bottom: 100px;
}
</style>
