<template lang="pug">
.view-appointment-status.content-wrapper
  BasePageTitle(
    pageTitleIcon="hep-b",
    :pageTitleIconString="hospitalIcon",
    :title="hospitalTitle"
    v-if= " $route.name != 'hospitals.patient-search' && $route.name !='hospitals.patient-search.results' " 
  )
    template(#breadcrumbs)
      li
        router-link(:to="{name: 'hospitals.appointment-status'}") Appointment Status
      li(v-if="showOnGoingCare")
        router-link(:to="{name: 'hospitals.ongoing-care'}") Ongoing Care
      li
        router-link(:to="{name: 'hospitals.schedules'}") Schedules
  router-view(:specialtyModule="specialtyModule")

  .content-wrapper.on-going-care-wrapper(v-if="showOnGoingCareOnTabs")
    .card.router-view-card
      section.on-going-care
        h3 Ongoing Care
        .columns.is-variable
          .column.is-one-half 
            router-link(:to="{name: 'hospitals.ongoing-care.active-patients'}")
              BoxLinkSm(text="Active Patients", :number="summaryOngoingCare.active")
          .column.is-one-half
            router-link(:to="{name: 'hospitals.ongoing-care.archived'}")
              BoxLinkSm(text="Archived", :number="summaryOngoingCare.archived")

</template>

<script>
import { defineComponent } from "vue";
import BoxLinkSm from "@/components/BoxLinkSm.vue";
import { fetchSpecialtyModule } from "@/api/specialtyModulesApi";
import { getHospitalSpecialty } from "@/api/hospitalApi";
import { fetchOnGoingCarePatient } from "@/api/onGoingCareApi";

import { apiStatus, apiStatusComputedFactory } from "@/api";
const { IDLE, PENDING, SUCCESS, ERROR } = apiStatus;

export default defineComponent({
  components: {
    BoxLinkSm,
  },

  data() {
    return {
      fetchSpecialtyModuleStatus: IDLE,
      getHospitalSpecialtyStatus: IDLE,
      fetchOnGoingCarePatientStatus: IDLE,

      hospitalTitle: "",
      hospitalIcon: "",
      specialty: {
        marketingTitle: "",
        moduleTitle: "",
      },
      specialtyModule: null,
      currentSpecialty: null,

      summaryOngoingCare: {
        active: 0,
        archived: 0,
      },
    };
  },

  computed: {
    ...apiStatusComputedFactory("fetchHospitalStatus","fetchOnGoingCarePatientStatus"),
    showOnGoingCare() {
      return (
        this.specialtyModule?.isManageCare &&
        this.currentSpecialty?.[0].isOngoingActive
      );
    },
    showOnGoingCareOnTabs() {
      if (this.showOnGoingCare) {
        if (
          this.$route.name == "hospitals.appointment-status.requested" ||
          this.$route.name == "hospitals.appointment-status.confirmed" ||
          this.$route.name == "hospitals.appointment-status.pending-clinic-review" ||
          this.$route.name == "hospitals.appointment-status.archived"
        ) {
          return true;
        }
      }
      return false;
    },
  },

  methods: {
    async fetchOnGoingCarePatient() {
      this.fetchOnGoingCarePatientStatus = PENDING;
      try {
        const res = await fetchOnGoingCarePatient({
          params: {
            idSpecialtyModule: this.$route.params.idSpecialty,
            filters: {
              idHospital: this.$route.params.idHospital,
              status: "archived",
            },
          },
        });
        this.summaryOngoingCare = {
          active: res.data.activeCount || 0,
          archived: res.data.archivedCount || 0,
        };

        this.fetchOnGoingCarePatientStatus = SUCCESS;
      } catch (err) {
        this.fetchOnGoingCarePatientStatus = ERROR;
        console.error(err);
      }
    },

    async getHospitalSpecialty() {
      this.getHospitalSpecialtyStatus = PENDING;
      try {
        const res = await getHospitalSpecialty(this.$route.params.idHospital);
        this.activeSpecialties = res.data.hospitalSpecialties;

        this.currentSpecialty = this.activeSpecialties.filter((specialty) => {
          return (
            specialty.specialtyModule?.id == this.$route.params.idSpecialty
          );
        });

        this.getHospitalSpecialtyStatus = SUCCESS;
      } catch (err) {
        console.log(err);
        this.getHospitalSpecialtyStatus = ERROR;
      }
    },
    async fetchSpecialtyModule() {
      this.fetchSpecialtyModuleStatus = PENDING;
      try {
        const res = await fetchSpecialtyModule(this.$route.params.idSpecialty);
        this.hospitalIcon = res.data.specialtyModule?.icon;
        this.hospitalTitle =
          res.data.specialtyModule?.marketingTitle +
          ": " +
          res.data.specialtyModule?.moduleTitle;

        this.specialtyModule = res.data.specialtyModule;
        this.fetchSpecialtyModuleStatus = SUCCESS;
      } catch (err) {
        this.fetchSpecialtyModuleStatus = ERROR;
        console.error(err);
      }
    },
  },
  created() {
    if (this.$route.params.idSpecialty === null) return;
    this.fetchSpecialtyModule();
    this.getHospitalSpecialty();
    this.fetchOnGoingCarePatient();
  },
});
</script>

<style scoped lang="scss">
.on-going-care-wrapper {
  max-width: 572px;
  margin-right: 0px;
  margin-left: auto;
  .card {
    border-top-left-radius: 0px;
  }
  .on-going-care {
    padding-left: 30px;
    padding-right: 30px;
    h3 {
      font-size: 21px;
      font-weight: normal;
      margin-bottom: 0px;
      margin-top: 30px;
    }
    .columns.is-variable {
      padding-bottom: 78px;
      margin-left: 0px;
      margin-right: 0px;
    }
    .box-link-sm {
      margin-left: -24px;
    }
  }
}
</style>
