<template lang="pug">
TableTitle(
  v-model="searchKeyword",
  @doSearch="fetchPositiveFitKits",
  @blur="fetchPositiveFitKits"
  title="Positive FIT",
  searchPlaceholder="Search...",
)
  template(#actions)
    DateSelector(@filterByDate="filterByDate")
.table
  .th
    .td(@click.prevent="changeListOrder('patientName', fetchPositiveFitKits)" style="cursor: pointer;") Patient Name
      i(style="font-weight: bolder;") {{ patientNameColumnSortingIcon }}
    .td(@click.prevent="changeListOrder('fluVaccine', fetchPositiveFitKits)" style="cursor: pointer;") FLU VACCINE
      i(style="font-weight: bolder;") {{ fluVaccineColumnSortingIcon }}
    .td(@click.prevent="changeListOrder('kitNumber', fetchPositiveFitKits)" style="cursor: pointer;") KIT #
      i(style="font-weight: bolder;") {{ kitNumberColumnSortingIcon }}
    .td(@click.prevent="changeListOrder('distributedAt', fetchPositiveFitKits)" style="cursor: pointer;") DATE SENT
      i(style="font-weight: bolder;") {{ distributedAtColumnSortingIcon }}
    .td(@click.prevent="changeListOrder('providerName', fetchPositiveFitKits)" style="cursor: pointer;") PROVIDER NAME
      i(style="font-weight: bolder;") {{ providerNameColumnSortingIcon }}
    .td(v-if="organizationType === 'Clinic'") SCHEDULE COLONOSCOPY
    .td(v-if="organizationType === 'CBO'") CLINIC REFERRAL
  .tr(v-for="fitKit in positiveFitKits" :key="fitKit.id")
    .td.bold {{`${fitKit.patient.first} ${fitKit.patient.last}`}} 
    .td
      div(v-if="fitKit.fluVaccine === null") {{'N/A'}}
      base-icon(v-else-if="fitKit.fluVaccine", name="yes")
      base-icon(v-else="", name="no")
    .td {{fitKit.kitNumber}}
    .td {{getDateFromUnixFormatted(fitKit.distributedAt)}}
    .td {{getDoctorFormatted(fitKit.providerUser)}}
    .td(v-if="organizationType === 'Clinic'")
      BaseBtn.sm.yellow(
        href="#",
        @click.prevent="openScheduleColonoscopyOutsideIccOverlay(fitKit)"
      ) OUTSIDE ICC
      BaseBtn.sm.yellow(
        v-if="enabledColonoscopySpecialty && clinicStaffInTrainingNotAllowed"
        href="#",
        :to="{name: 'clinics.scheduling.select-schedule', params: { idFitKit: fitKit.id, idPatient: fitKit.idPatient, idSpecialty: idColonoscopySpecialty }}"
      ) WITHIN ICC
    .td(v-if="organizationType === 'CBO'")
      BaseBtn.sm.yellow--outline(
        href="#",
        @click.prevent="openFitReferralOverlay(fitKit)"
      ) REFERRAL
Pagination(
  v-if="pagination && positiveFitKits != null && positiveFitKits.length",
  @changePagination="changePagination",
  :pagination="pagination",
  :reloadFn="fetchPositiveFitKits"
)
.no-results(v-if="positiveFitKits != null && !positiveFitKits.length") 
  base-icon(name="exclamation") 
  span No matching records

ScheduleColonoscopyOutsideIccOverlay(
  :isOpen="isScheduleColonoscopyOutsideIccOverlayOpen",
  :selectedFitKit="selectedFitKit"
  @closeScheduleColonoscopyOutsideIccOverlay="closeScheduleColonoscopyOutsideIccOverlay"
)
FitReferralOverlay(
  :isOpen="isFitReferralOverlayOpen",
  :selectedFitKit="selectedFitKit"
  @closeFitReferralOverlay="closeFitReferralOverlay"
)
</template>

<script>
import { defineComponent } from "vue";
import TableTitle from "@/components/TableTitle.vue";
import DateSelector from "@/components/DateSelector.vue";
import Pagination from "@/components/Pagination.vue";
import ScheduleColonoscopyOutsideIccOverlay from "@/components/fit-kit/overlays/ScheduleColonoscopyOutsideIccOverlay.vue";
import FitReferralOverlay from "@/components/fit-kit/overlays/FitReferralOverlay.vue";
import { fetchCHS, getSpecialtyModulesOfCHS } from "@/api/chsApi";
import { fetchClinic } from "@/api/clinicApi";
import { fetchFitKits } from "@/api/fitKitApi";
import {
  reformatPagination,
  getDoctorFormatted,
  getDateFromUnixFormatted,
} from "@/helpers";
import listPagesMixin from "@/mixins/listPagesMixin";
import { denyClinicStaffTraining } from "@/mixins/denyClinicStaffTraining";
import sortFitKits from "@/mixins/sortFitKits";

export default defineComponent({
  components: {
    TableTitle,
    DateSelector,
    Pagination,
    ScheduleColonoscopyOutsideIccOverlay,
    FitReferralOverlay,
  },

  mixins: [listPagesMixin, denyClinicStaffTraining, sortFitKits],

  data() {
    return {
      selectedFitKit: null,
      positiveFitKits: null,
      isScheduleColonoscopyOutsideIccOverlayOpen: false,
      isFitReferralOverlayOpen: false,
      enabledColonoscopySpecialty: false,
      idColonoscopySpecialty: null,
      organizationType: null,
      date: null,
      searchKeyword: "",
    };
  },

  methods: {
    filterByDate(date) {
      this.date = date;
      this.fetchPositiveFitKits();
    },
    openScheduleColonoscopyOutsideIccOverlay(selectedFitKit) {
      this.selectedFitKit = selectedFitKit;
      this.isScheduleColonoscopyOutsideIccOverlayOpen = true;
    },
    closeScheduleColonoscopyOutsideIccOverlay({ afterAction }) {
      this.isScheduleColonoscopyOutsideIccOverlayOpen = false;
      this.selectedFitKit = null;
      if (afterAction) {
        this.$router.go(0);
      }
    },
    openFitReferralOverlay(selectedFitKit) {
      this.selectedFitKit = selectedFitKit;
      this.isFitReferralOverlayOpen = true;
    },
    closeFitReferralOverlay({ afterAction }) {
      this.isFitReferralOverlayOpen = false;
      this.selectedFitKit = null;
      if (afterAction) {
        this.$router.go(0);
      }
    },
    getDoctorFormatted: getDoctorFormatted,
    getDateFromUnixFormatted: getDateFromUnixFormatted,
    async fetchClinicTypeAndIfColonoscopyAvailable() {
      try {
        const clinicRes = await fetchClinic(this.$route.params.idClinic);
        const chsRes = await fetchCHS(clinicRes.data.idClinicHealthSystem);
        this.organizationType = chsRes.data.organizationType;
        const specialtiesRes = await getSpecialtyModulesOfCHS(
          clinicRes.data.idClinicHealthSystem,
          {
            params: {
              page: 1,
              size: 1000,
            },
          }
        );
        if (specialtiesRes.data && specialtiesRes.data.success) {
          const chsSpecialties = specialtiesRes.data.chsSpecialties;
          if (chsSpecialties.length > 0) {
            const colonoscopy = chsSpecialties.find(
              (x) => x.specialtyModule.isColonoscopy
            );
            this.idColonoscopySpecialty = colonoscopy
              ? colonoscopy.specialtyModule.id
              : null;
            this.enabledColonoscopySpecialty = !!this.idColonoscopySpecialty;
          } else {
            this.enabledColonoscopySpecialty = false;
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
    async fetchPositiveFitKits() {
      const order = this.listOrder
        ? {
            by: this.listOrder.by,
            direction: this.listOrder.direction,
          }
        : null;
      const params = {
        order,
        ...this.reqPagination,
        idClinic: this.$route.params.idClinic,
        include: ["patient", "providerUser"],
        pickOnly: "neededAction",
        result: "positive",
        status: "completed",
        searchKeyword: this.searchKeyword,
        distributedAt: this.date,
      };
      if (this.date === null) delete params.distributedAt;
      if (order === null) delete params.order;
      if (!!this.searchKeyword !== true) delete params.searchKeyword;
      try {
        const resFitKit = await fetchFitKits({
          params: {
            ...params,
          },
        });
        this.positiveFitKits = resFitKit.data.fitKits;
        this.pagination = reformatPagination(resFitKit.data.pagination);
      } catch (err) {
        console.error(err);
      }
    },
  },
  created() {
    if (this.$route.params.idClinic === null) return;
    this.fetchClinicTypeAndIfColonoscopyAvailable();
    this.fetchPositiveFitKits();
  },
});
</script>

<style scoped lang="scss">
.td {
  &:nth-child(1) {
    width: math.percentage(math.div(234, $tabContentCardWidth));
  }
  &:nth-child(2) {
    width: math.percentage(math.div(118, $tabContentCardWidth));
  }
  &:nth-child(3) {
    width: math.percentage(math.div(140, $tabContentCardWidth));
  }
  &:nth-child(4) {
    width: math.percentage(math.div(140, $tabContentCardWidth));
  }
  &:nth-child(5) {
    width: math.percentage(math.div(202, $tabContentCardWidth));
  }
  &:nth-child(6) {
    width: math.percentage(math.div(359, $tabContentCardWidth));
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .btn {
      display: block;
      width: 155px;
    }
  }
}
</style>
