<template lang="pug">
.view-appointment-status.content-wrapper
  BasePageTitle(
    pageTitleIcon="primary-care",
    title="ILLINOIS REFERRAL CARE"
    v-if= " $route.name != 'provider-groups.patient-search' && $route.name !='provider-groups.patient-search.results' && $route.name != 'provider.notifications'  " 
  )
    template(#boldText) REFERRAL DASHBOARD

  router-view(@patientReferralStats="patientReferralStats")

  .content-wrapper.patient-referral-stats(v-if="showPatientReferralStats")
    .card.router-view-card
      section.section_padding
        h3 Patient Referral Stats 
          span.h3-small-font Last 30 Days
        .columns.is-variable.circles-center
          .column.is-one-fifth
            .circle
              <vue3-chart-js :id="doughnutChart.id" :type="doughnutChart.type" :data="doughnutChart.data" :options="doughnutChart.options" ref="chartRef" ></vue3-chart-js>
          .column.is-2.circle-wrapper
            .circle-status.green
            .circle-numbers
              .circle-big-font {{ referralStats.contacted }}
              .circle-small-font Contacted
          .column.is-3.circle-wrapper
            .circle-status.orange
            .circle-numbers
              .circle-big-font {{ referralStats.unableToContact }}
              .circle-small-font Unable to Contact

</template>

<script>
import { defineComponent } from "vue";
import BoxLinkSm from "@/components/BoxLinkSm.vue";
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";
import { ref } from "vue";
import { apiStatus } from "@/api";
const { IDLE, PENDING, SUCCESS, ERROR } = apiStatus;

export default defineComponent({
  components: {
    BoxLinkSm,
    Vue3ChartJs,
  },

  data() {
    return {
      referralStats: {
        contacted: 0,
        unableToContact: 0,
      },

      referral: {
        marketingTitle: "",
      },
    };
  },
  computed: {
    showPatientReferralStats() {
      return this.$route.name.includes("provider-groups.referral-status");
    },
  },
  methods: {
    patientReferralStats(values) {
      this.doughnutChartUpdate(values);
      this.referralStats = values;
    },
  },
  setup() {
    const counter = ref(10);
    const chartRef = ref(null);

    const doughnutChart = {
      id: "doughnut",
      type: "doughnut",
      data: {
        datasets: [
          {
            backgroundColor: ["#96bf3c", "#f5a623"],
            data: [0, 0],
          },
        ],
      },
      options: {
        offset: 0,
        cutout: "70%",
        scales: {
          x: {
            display: false,
          },
          y: {
            display: false,
          },
        },
      },
    };
    const beforeUpdate = () => {
      counter.value++;
    };
    const doughnutChartUpdate = (values) => {
      doughnutChart.data.datasets[0].data = [
        values.contacted,
        values.unableToContact,
      ];
      chartRef.value.update(750);
    };
    return {
      doughnutChart,
      beforeUpdate,
      doughnutChartUpdate,
      chartRef,
    };
  },
  created() {
    if (this.$route.params.idReferral === null) return;
  },
});
</script>

<style scoped lang="scss">
.patient-referral-stats {
  margin-right: 0px;
  margin-left: auto;

  .section_padding {
    padding-left: 30px;
    padding-right: 30px;

    h3 {
      font-size: 21px;
      font-weight: normal;
      margin-bottom: 42px;
      margin-top: 30px;
      color: #7d7c7d;

      .h3-small-font {
        font-size: 16px;
        margin-left: 17px;
        color: #9b9b9b;
      }
    }

    .circles-center {
      align-items: center;
    }

    .columns.is-variable {
      padding-bottom: 57px;
      margin-left: 0px;
      margin-right: 0px;
    }

    .box-link-sm {
      margin-left: -24px;
    }

    .circle {
      width: 130px;
      height: 130px;
    }

    .circle-numbers > * {
      display: block;
    }

    .circle-status {
      width: 18px;
      height: 18px;
      border-radius: 100%;
      display: inline-block;
      margin-top: 10px;
      margin-right: 10px;

      &.green {
        background-color: $secondaryGreen;
      }

      &.orange {
        background-color: $secondaryOrange;
      }

      &.grey {
        background-color: $primaryBgDarkGrey;
      }
    }

    .circle-big-font {
      color: #343434;
      font-size: 26px;
    }

    .circle-small-font {
      color: $primaryDarkGrey;
      font-size: 14px;
      margin-top: -3px;
    }

    .circle-wrapper {
      display: flex;
    }
  }
}
</style>
