export const BULK_FIT_KIT_UPLOAD_FIELDS = {
  MRN: {
    jsonField: "patient.mrn",
    type: "string",
  },
  "Patient Name": {
    jsonField: "patient.first",
    type: "string",
  },
  "Patient last name": {
    jsonField: "patient.last",
    type: "string",
  },
  "Date of birth": {
    jsonField: "patient.dob",
    type: "date",
    isUTC: false,
    format: "MMDDYYYY",
  },
  "Home Phone Number": {
    jsonField: "patient.contact.homePhone",
    type: "number",
    length: 10,
  },
  "Home Phone Number Ext": {
    jsonField: "patient.contact.homePhoneExtension",
    type: "number",
    required: false,
  },
  "Cell Phone Number": {
    jsonField: "patient.contact.cellPhone",
    type: "number",
    length: 10,
  },
  "Cell Phone Number Ext": {
    jsonField: "patient.contact.cellPhoneExtension",
    type: "number",
    required: false,
  },
  "Home Address": {
    jsonField: "patient.address.address",
    type: "string",
  },
  "Zip Code": {
    jsonField: "patient.address.zipCode",
    type: "number",
    length: 5,
  },
  "Patient Notes": {
    jsonField: "patient.notes",
    type: "string",
  },
  "FIT Kit number": {
    jsonField: "kitNumber",
    type: "string",
  },
  "FIT Status": {
    jsonField: "status",
    type: "enum",
  },
  "FIT Result": {
    jsonField: "result",
    type: "enum",
  },
  "Date sent": {
    jsonField: "distributedAt",
    type: "date",
    isUTC: true,
  },
  "Provider email": {
    jsonField: "emailProviderUser",
    type: "string",
  },
  "Date of Processing": {
    jsonField: "processedAt",
    type: "date",
    isUTC: true,
  },
  "Completion Notes": {
    jsonField: "completionNotes",
    type: "string",
  },
};

export const ERROR_MESSAGE = "Unexpected error, Bulk Upload not executed";
export const AUTH_ERROR_STATUS = 401;
export const AUTH_ERROR_MESSAGE = "Not authorized to do this action";
