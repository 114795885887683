<template lang="pug">

</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  components: {},

  props: {
    // project: {
    //   type: Object,
    //   required: true
    // }
  },

  data() {
    return {};
  },

  computed: {},

  watch: {
    // keywords: {
    //   handler: "getRows",
    //   immediate: true
    // }
  },

  methods: {}
});
</script>

<style scoped lang="scss"></style>
