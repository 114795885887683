<template lang="pug">
.date-selector-wrapper
  .date-selector(
    @click.prevent="isCalendarExpanded = !isCalendarExpanded",
    :class="{ expanded: isCalendarExpanded }",
  )
    base-icon(name="calendar")
    span.txt {{selectionDisplay}}
    base-icon(name="chevron-md")
  .datepicker-container(v-show="isCalendarExpanded")
    DatePicker(v-model="date", click.stop="" :min-date="minDate" :max-date="maxDate" )
    .datepicker-reset-btn-container
      BaseBtn.sm.grey-outline(@click.prevent="resetDate") RESET
</template>

<script>
import { defineComponent } from "vue";
import { DatePicker } from "v-calendar";
import moment from "moment";

export default defineComponent({
  components: { DatePicker },
  emits: ["filterByDate"],

  data() {
    return {
      isCalendarExpanded: false,
      selectionDisplay: "All dates",
      date: null,
    };
  },
  props: {
    minDate: {
      type: Date,
      required: false,
      default: null,
    },
    maxDate: {
      type: Date,
      required: false,
      default: null,
    },
  },
  watch: {
    date: {
      handler: "setDate",
    },
  },

  methods: {
    filterByDate() {
      this.isCalendarExpanded = false;
      let dateWithoutTime;
      if (this.date === null) {
        dateWithoutTime = null;
      } else {
        dateWithoutTime = Math.round(this.date.getTime() / 1000).toString();
      }
      this.$emit("filterByDate", dateWithoutTime);
    },
    setDate(newVal) {
      if (newVal === null) return;
      let selectionDisplay = moment(this.date);
      this.selectionDisplay = selectionDisplay.format("MM-DD-YYYY");
      this.filterByDate();
    },
    resetDate() {
      this.date = null;
      this.selectionDisplay = "All dates";
      this.filterByDate();
    },
  },
});
</script>

<style scoped lang="scss">
.date-selector-wrapper {
  position: relative;
}
.date-selector {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 191px;
  margin-right: 8px;
  border-radius: 30px;
  transition: background-color 0.3s linear;
  background: rgba(#f0eeee, 0) 0% 0% no-repeat;
  text-transform: uppercase;
  .svg-icon--calendar {
    font-size: 24px;
  }
  .txt {
    margin: 0 14px;
    text-transform: uppercase;
    font-size: 14px;
  }
  .svg-icon--chevron-md {
    font-size: 16px;
    transition: transform 0.3s linear;
    transform: rotate(0deg);
  }
  &:hover {
    background-color: rgba($primaryLightBlue, 0.1);
  }
  &.expanded {
    background-color: $primaryLightBlue;
    background-color: rgba(#f0eeee, 1);
    .svg-icon--chevron-md {
      transform: rotate(-180deg);
    }
  }
}
.picker {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: transparent !important;
  border: 0 !important;
  opacity: 0 !important;
}
.numInput cur-year {
  position: relative !important;
  top: -20px !important;
}

// Date picker---------------------------------------
.datepicker-container {
  padding-bottom: 20px;
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  background-color: #fff;
  border: 1px $primaryDarkGrey;
  box-shadow: 2px 5px 20px 0 rgba(0, 0, 0, 0.16);
  z-index: 100;
  .vc-container {
    --blue-600: #{$primaryLightBlue};
    border: 0;
    border-radius: 0;
    background-color: transparent;
  }
  .vc-day-content:hover {
    background-color: $primaryBgDarkGrey;
  }
}
.datepicker-reset-btn-container {
  display: flex;
  justify-content: center;
}
</style>
