<template lang="pug">
SideOverlaySlot(
  :isOpen="isOpen",
  title="LINKED CLINIC HEALTH SYSTEMS"
  @closeOverlay="closeOverlay"
)
  template(#contentTop)
    .side-overlay-blue-box
      p.strong-blue Changes are saved automatically.
      
    .links-container
      .not-linked
        .label Not Linked
        BaseBtn.sm.blue-outline(v-for="unlinkedSystem in unlinkedSystems", :key="unlinkedSystem.id" @click.prevent="createWorkWith(hospId,unlinkedSystem.id)" ) {{ unlinkedSystem.name }}
        
      .linked
        .label Linked
        BaseBtn.sm.blue(v-for="linkedSystem in linkedSystems", :key="linkedSystem.id" @click.prevent="deleteWorkWith(hospId,linkedSystem.id)" ) {{ linkedSystem.name }}
</template>

<script>
import { defineComponent } from "vue";
import SideOverlaySlot from "@/components/overlays/SideOverlaySlot.vue";

import {
  createWorkWith,
  deleteWorkWith,
  getLinkedCHS,
} from "@/api/hospitalApi";

import { apiStatus, apiStatusComputedFactory } from "@/api";
const { IDLE, PENDING, SUCCESS, ERROR } = apiStatus;

export default defineComponent({
  components: { SideOverlaySlot },
  emits: ["closeLinkedClinicHealthSystemsOverlay"],

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    hospId: {
      required: false,
    },
  },

  data() {
    return {
      createWorkWithStatus: IDLE,
      deleteWorkWithStatus: IDLE,
      getLinkedCHSStatus: IDLE,
      unlinkedSystems: [],
      linkedSystems: [],
    };
  },
  computed: {
    ...apiStatusComputedFactory(
      "createWorkWithStatus",
      "deleteWorkWithStatus",
      "getLinkedCHSStatus"
    ),
  },
  methods: {
    closeOverlay() {
      this.$emit("closeLinkedClinicHealthSystemsOverlay");
    },

    async createWorkWith(hospID, chsID) {
      this.createWorkWithStatus = PENDING;
      try {
        await createWorkWith(hospID, chsID);
        this.getLinkedCHS();
        this.createWorkWithStatus = SUCCESS;
      } catch (err) {
        console.log(err);
        this.createWorkWithStatus = ERROR;
      }
    },
    async deleteWorkWith(hospID, chsID) {
      this.deleteWorkWithStatus = PENDING;
      try {
        await deleteWorkWith(hospID, chsID);
        this.getLinkedCHS();
        this.deleteWorkWithStatus = SUCCESS;
      } catch (err) {
        console.log(err);
        this.deleteWorkWithStatus = ERROR;
      }
    },
    async getLinkedCHS() {
      this.getLinkedCHSStatus = PENDING;
      try {
        const res = await getLinkedCHS({
          params: {
            // idHospital: this.hospId,
            include: "idHospitals",
          },
        });

        this.linkedSystems = [];
        this.unlinkedSystems = [];

        res.data.clinicHealthSystems.forEach((element) => {
          let newCHS = [];
          newCHS = element;
          if (element.idHospitals.includes(this.hospId)) {
            this.linkedSystems.push(newCHS);
          } else {
            this.unlinkedSystems.push(newCHS);
          }
        });

        this.getLinkedCHSStatus = SUCCESS;
      } catch (err) {
        this.getLinkedCHSStatus = ERROR;
        console.error(err);
      }
    },
  },
  async mounted() {
    this.getLinkedCHS();
  },
});
</script>

<style scoped lang="scss">
.links-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.not-linked,
.linked {
  flex-grow: 0;
  flex-shrink: 0;
  width: 48.1%;
  .label {
    display: block;
    margin-bottom: 31px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
  }
  .btn {
    display: block;
    margin-bottom: 25px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
