<template lang="pug">
PopupCardSlot(
  :isOpen="isAppointmentScheduled",
)
  .completed-popup
    a.close(href="#", @click.prevent="goToAppointmentStatus")
      base-icon(name="x")
    header
      h1
        |COMPLETED
        base-icon.fill(name="checkmark")
        
    .popup-content(:id="'success-print-area'")
      h2 Appointment scheduled!
      ConfirmationInfo()
    footer
      p
        a(href="#", @click.prevent="print")
          |Please <strong>print</strong> this confirmation for record keeping
          base-icon(name="printer")
      BaseBtn.lg.green(@click.prevent="goToAppointmentStatus") RETURN TO DASHBOARD

section.step-subtitle
  h2 CONFIRM
section.schedule-confirm
  TableTitle(
    title="You are about to schedule the following appointment",
    :padded="false"
  )
  ConfirmationInfo()
  .actions-below-table-container
    BaseBtn.lg.green-outline(
      :to="{name: 'primary-care-community-organization.scheduling.appointment-info', query: $route.query, params: $route.params}",
      style="min-width: 270px", 
    ) &lt; PREVIOUS STEP
    BaseBtn.lg.green(
      href="#",
      style="min-width: 270px",
      @click.prevent="scheduleAppointment"
    ) SCHEDULE APPOINTMENT
</template>

<script>
import { defineComponent } from "vue";
import router from "@/router";
import TableTitle from "@/components/TableTitle.vue";
import ConfirmationInfo from "@/components/primary-care-community-organization/scheduling/ConfirmationInfo.vue";
import PopupCardSlot from "@/components/PopupCardSlot.vue";
import { createPatientReferral } from "@/api/patientReferral";
import moment from "moment";
import { createToast } from "mosha-vue-toastify";
import { apiStatus, apiStatusComputedFactory } from "@/api";
import { uploadFile } from "@/api/filesApi";
const { IDLE, PENDING, SUCCESS, ERROR } = apiStatus;

export default defineComponent({
  components: { TableTitle, ConfirmationInfo, PopupCardSlot },

  data() {
    return {
      isAppointmentScheduled: false,
      createPatientReferralStatus: IDLE,
      giQuestionnaire: [],
      insuranceCardStorageKey: "schedule_appt_upload_insurance_card",
      clinicReportStorageKey: "schedule_appt_clinic_report",
      idInsuranceCard: null,
      idCommunityReport: null,
    };
  },
  computed: {
    ...apiStatusComputedFactory("createPatientReferralStatus"),
  },
  methods: {
    async print() {
      const printContents =
        document.getElementById("success-print-area").innerHTML;
      const style =
        ".confirmation-info {border: 1px solid #ECF7FC; background-color: #ECF7FC; box-sizing: border-box;color: rgb(0, 0, 0);display: inline-flex;font-family: Montserrat, sans-serif;font-size: 20px;height: auto;line-height: 28px;margin-bottom: 27px;padding-bottom: 17px;padding-left: 37px;padding-right: 37px;padding-top: 37px;width: auto } .info-pair { box-sizing: border-box; color: rgb(0, 0, 0); display: inline-flex; font-family: Montserrat, sans-serif; font-size: 20px; height: auto; line-height: 28px margin-bottom: 30px; width: auto } .label { padding: 15px; box-sizing: border-box; color: rgb(0, 0, 0); display: inline-flex; font-family: Montserrat, sans-serif; font-size: 14px; font-weight: 400; height: auto; line-height: 15.4px; margin-bottom: 21px; width: auto } .txt { padding: 15px; box-sizing: border-box; color: rgb(0, 0, 0); display: inline-flex; font-family: Montserrat, sans-serif; font-size: 21px; font-weight: 700; height: auto; line-height: 23.1px; width: auto }";
      let popupWin = window.open("", "_blank", "width=940,height=600");
      popupWin.document.open();
      popupWin.document.write(
        "<html><head><style>" +
          style +
          '</style></head><body onload="window.print()" style="font-family:Helvetica, Arial, sans-serif;"><div style="margin:50px;">' +
          printContents +
          "</div></body></html>"
      );
      popupWin.document.close();
    },
    async createPatientReferral() {
      const routeParams = {
        ...this.$route.params,
      };

      await this.uploadFiles();
      const uploadedQuestionFiles = await this.uploadQuestionFiles(
        JSON.parse(routeParams.questionFiles)
      );

      this.createPatientReferralStatus = PENDING;
      try {
        let scheduleParsed = JSON.parse(routeParams.schedule);

        this.giQuestionnaire.forEach((element) => {
          delete element.name;
          if (element.type == "DATE") {
            element.value = moment(element.value).unix();
          }
        });
        let sendCommunityReport = {
          idCommunityReport: this.idCommunityReport,
        };
        if (this.idCommunityReport === null) {
          sendCommunityReport = {};
        }

        let sendParameters = {
          idPatient: routeParams.idPatient,
          idClinic: routeParams.idClinic,
          idCommunity: routeParams.idCommunity,
          communityCareNotes: routeParams.communityCareNotes,
          idReferringDoctor: routeParams.referringDoctor,
          idSchedule: scheduleParsed.id,
          emergencyContact: JSON.parse(routeParams.emergencyContactInformation),
          insurance: JSON.parse(routeParams.insuranceInformation),
          patientInfoQuestions: [
            ...this.giQuestionnaire,
            ...uploadedQuestionFiles,
          ],
          ...sendCommunityReport,
        };

        if (routeParams.idFitKit && routeParams.idFitKit !== "undefined") {
          sendParameters.idFitKit = routeParams.idFitKit;
        }
        if (this.idInsuranceCard) {
          sendParameters.insurance.idInsuranceCard = this.idInsuranceCard;
        }
        if (!sendParameters.emergencyContact.phoneExtension) {
          delete sendParameters.emergencyContact.phoneExtension;
        }
        if (!scheduleParsed.insuranceRequired) {
          delete sendParameters.insurance;
        }

        sendParameters.emergencyContact.phoneNumber =
          sendParameters.emergencyContact.phoneNumberRaw;
        delete sendParameters.emergencyContact.phoneNumberRaw;

        const res = await createPatientReferral(sendParameters);
        this.isAppointmentScheduled = true;
        this.createPatientReferralStatus = SUCCESS;
      } catch (err) {
        this.createPatientReferralStatus = ERROR;
        createToast(err?.response?.data?.message || "There was a problem", {
          timeout: 4000,
          type: "danger",
          position: "bottom-right",
        });
        console.error(err);
      }
    },
    goToAppointmentStatus() {
      this.isAppointmentScheduled = false;
      router.push({
        name: "primary-care-community-organization",
        params: {
          // idClinic: this.$route.params.idClinic,
          idCommunity: this.$route.params.idCommunity,
        },
      });
    },
    scheduleAppointment() {
      this.createPatientReferral();
    },
    async uploadFiles() {
      const insuranceCard = sessionStorage.getItem(
        this.insuranceCardStorageKey
      );
      if (insuranceCard) {
        try {
          const { file } = await uploadFile(this.insuranceCardStorageKey);
          this.idInsuranceCard = file.id;
        } catch (error) {
          console.log(error);
        }
      }

      const clinicReport = sessionStorage.getItem(this.clinicReportStorageKey);
      if (clinicReport) {
        try {
          const { file } = await uploadFile(this.clinicReportStorageKey);
          this.idCommunityReport = file.id;
        } catch (error) {
          console.log(error);
        }
      }

      sessionStorage.removeItem(this.insuranceCardStorageKey);
      sessionStorage.removeItem(this.clinicReportStorageKey);
    },
    async uploadQuestionFiles(questionFiles) {
      return Promise.all(
        questionFiles.map((questionFile) => {
          return uploadFile(questionFile.storageKey).then((data) => {
            //remove item, it's already up.
            sessionStorage.removeItem(questionFile.storageKey);

            return {
              id: questionFile.id,
              type: questionFile.type,
              value: data.file.id,
            };
          });
        })
      );
    },
  },
  created() {
    this.giQuestionnaire = JSON.parse(this.$route.params.questionsAnswered);
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/components/actions-below-table-container.scss";
@import "@/scss/components/step-subtitle.scss";
.schedule-confirm {
  padding-left: 30px;
  padding-right: 30px;
}
.completed-popup {
  position: relative;
  .close {
    display: block;
    position: absolute;
    top: -11px;
    right: 1px;
    z-index: 2;
    .svg-icon {
      height: 20px;
      width: 20px;
      top: 0;
    }
  }
  header {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 36px;
    padding-bottom: 28px;
    &::after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      left: -35px;
      right: -35px;
      height: 1px;
      background-color: $primaryBgDarkGrey;
    }
    h1 {
      margin: 0;
      font-size: 36px;
      font-weight: 700;
      line-height: 1.22;
      letter-spacing: 0.2px;
      color: $secondaryGreen;
      .svg-icon--checkmark {
        margin-left: 20px;
        font-size: 44px;
      }
    }
  }
  .popup-content {
    & > h2 {
      margin-top: 0;
      margin-bottom: 47px;
      font-size: 28px;
      font-weight: 700;
      line-height: 1.21;
      letter-spacing: 0.14px;
    }
    .confirmation-info {
      margin-bottom: 27px;
    }
  }
  footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      margin: 0;
      font-size: 21px;
      font-weight: 700;
      line-height: 1.19;
      letter-spacing: 0.11px;
      color: $secondaryGreen;
      strong {
        color: #000000;
        text-decoration: underline;
      }
      .svg-icon {
        top: 0;
        margin-left: 22px;
        font-size: 33px;
      }
    }
  }
}
@media print {
  .confirmation-info {
    background-color: #ecf7fc !important;
    -webkit-print-color-adjust: exact;
  }
}
</style>
