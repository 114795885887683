<template lang="pug">
section.step-subtitle
  h2 REFERRAL INFO
   span.lighter for 
   span.blue(v-if="patient") {{ getUserFormatted(patient) }}
section.appointment-info
  .appt-details-title
    .cols
      .col(v-if="schedule") {{ getDateFromUnixFormatted(schedule.availableAt) }} {{ getTimeFromUnixFormatted(schedule.availableAt) }} 
      .col(v-if="hospital") {{ hospital.name }}
  section.eci
    .title.title--md-thin EMERGENCY CONTACT INFORMATION
    .cols
      .col
        .form-group(:class="{ 'form-group__invalid': v$.emergencyContactInformation.firstName.$errors.length }")
          label(for="first-name") First Name (Required)
          input#first-name(
            type="text" 
            v-model="emergencyContactInformation.firstName"
            @blur="v$.emergencyContactInformation.firstName.$touch()"
          )
          .form-group__errors(v-if="v$.emergencyContactInformation.firstName.$dirty && v$.emergencyContactInformation.firstName.$errors.length")
            .form-group__error(v-if="v$.emergencyContactInformation.firstName.required.$invalid") First name is required!
      .col
        .form-group(:class="{ 'form-group__invalid': v$.emergencyContactInformation.lastName.$errors.length }")        
          label(for="last-name") Last Name (Required)
          input#last-name(
            type="text" 
            v-model="emergencyContactInformation.lastName"
            @blur="v$.emergencyContactInformation.lastName.$touch()"
          )
          .form-group__errors(v-if="v$.emergencyContactInformation.lastName.$dirty && v$.emergencyContactInformation.lastName.$errors.length")
            .form-group__error(v-if="v$.emergencyContactInformation.lastName.required.$invalid") Last name is required!
      .col
        .form-group(:class="{ 'form-group__invalid': v$.emergencyContactInformation.phoneNumber.$errors.length }")
          label(for="phone-no") Phone Number (Required)
          input#phone-no(
            type="text", 
            placeholder="xxx-xxx-xxxx" 
            v-model="emergencyContactInformation.phoneNumber"
            @blur="v$.emergencyContactInformation.phoneNumber.$touch()"
            v-maska="'###-###-####'"
            @maska="phoneNumberRawValue"
          )
          .form-group__errors(v-if="v$.emergencyContactInformation.phoneNumber.$dirty && v$.emergencyContactInformation.phoneNumber.$errors.length")
            .form-group__error(v-if="v$.emergencyContactInformation.phoneNumber.required.$invalid") Phone number is required!
            .form-group__error(v-if="v$.emergencyContactInformation.phoneNumber.minLength.$invalid") Phone number is required!
      .col
        .form-group
          label(for="ext") Ext.
          input#ext(type="text", placeholder="" v-model="emergencyContactInformation.phoneExtension")
      .col
        .form-group(:class="{ 'form-group__invalid': v$.referringDoctor.$errors.length }")
          label Referring Doctor (Required)
          select(
            v-if="doctors" 
            v-model="referringDoctor"
            @blur="v$.referringDoctor.$touch()"
          )
            option(value='') Select
            option(
              v-for="doctor in doctors", 
              :key="doctor.id",
              :value="doctor.id") {{ getDoctorFormatted(doctor) }}
          .form-group__errors(v-if="v$.referringDoctor.$dirty && v$.referringDoctor.$errors.length")
            .form-group__error(v-if="v$.referringDoctor.required.$invalid") Referring Doctor is required!

  section.insurance(v-if="schedule && schedule.insuranceRequired")
    .insurance__content
      .insurance-status-wrapper
        .insurance-status
          p Does patient have insurance?
          base-icon(name="yes")
      .title.title--md-thin Insurance Information
      .cols.cols-1
        .col
          .form-group(:class="{ 'form-group__invalid': v$.insuranceInformation.name.$errors.length }")
            label(for="insurance-no") Insurance Name (Required)
            input#insurance-no(
              type="text", 
              placeholder="" 
              v-model="insuranceInformation.name"
              @keyup='insuranceKeyUp'
              @blur="v$.insuranceInformation.name.$touch()"
            )
            div.dropdown_input_wrapper(v-if="insurancesFound && insurancesFound.length")
              ul
                li(
                  v-for="(insurance, index) in insurancesFound",
                  :key="insurance.id",  
                  :class="{ 'active': index === 0}"
                  @click="insuranceSelect(insurance)"
                )
                  span(v-html="insurance.nameHighlight")

            .form-group__errors(v-if="v$.insuranceInformation.name.$dirty && v$.insuranceInformation.name.$errors.length")
              .form-group__error(v-if="v$.insuranceInformation.name.$invalid") Insurance name is required!
            
        .col
          .form-group(:class="{ 'form-group__invalid': v$.insuranceInformation.policyNumber.$errors.length }")
            label(for="policy-no") Policy Number (Required)
            input#policy-no(
              type="text", 
              placeholder="" 
              v-model="insuranceInformation.policyNumber"
              @blur="v$.insuranceInformation.policyNumber.$touch()"
            )
            .form-group__errors(v-if="v$.insuranceInformation.policyNumber.$dirty && v$.insuranceInformation.policyNumber.$errors.length")
              .form-group__error(v-if="v$.insuranceInformation.policyNumber.$invalid") Policy number is required!
      .cols.cols-2
        .col
          .form-group(:class="{ 'form-group__invalid': v$.insuranceInformation.expirationDate.$errors.length }")
            label(for="exp-date") Expiration Date (Required)
            input#exp-date(
              type="text" 
              v-model="insuranceInformation.expirationDate"
              @blur="v$.insuranceInformation.expirationDate.$touch()"
              placeholder="MM/DD/YYYY"
              v-maska="'##/##/####'"
            )
            .form-group__errors(v-if="v$.insuranceInformation.expirationDate.$dirty && v$.insuranceInformation.expirationDate.$errors.length")
              .form-group__error(v-if="v$.insuranceInformation.expirationDate.$invalid && v$.insuranceInformation.expirationDate.minLength.$invalid") Expiration date is required!
              .form-group__error(v-if="v$.insuranceInformation.expirationDate.maxDateAppointment.$invalid && !v$.insuranceInformation.expirationDate.minLength.$invalid") The insurance expiration date must be greater than the appointment date.
              .form-group__error(v-if="v$.insuranceInformation.expirationDate.maxDateFuture.$invalid & !v$.insuranceInformation.expirationDate.minLength.$invalid") Invalid expiration date!
        .col
          .form-group(:class="{ 'form-group__invalid': v$.insuranceInformation.groupNumber.$errors.length }")
            label(for="group-no") Group Number (Required)
            input#group-no(
              type="text", 
              placeholder="" 
              v-model="insuranceInformation.groupNumber"
              @blur="v$.insuranceInformation.groupNumber.$touch()"
            )
            .form-group__errors(v-if="v$.insuranceInformation.groupNumber.$dirty && v$.insuranceInformation.groupNumber.$errors.length")
              .form-group__error(v-if="v$.insuranceInformation.groupNumber.$invalid") Group number is required!
        .col
          .form-group
            label Upload Insurance Card
            UploadAndPreviewFile(
              :buttonStyleClasses="['btn', 'lg', 'blue']"
              :storageKey="insuranceCardStorageKey"
            )
  section.gi(v-if="questions")
    .title.title--md-thin {{ specialtyModuleRes?.schemaRules?.patientInfoConfiguration?.referralInfoTitle || "GI QUESTIONS" }}
    .qa-radios
      .qa-radios__row(v-for="question in activeQuestions" :key="question.id")
        .qa-radios__qa(:class="checkNotes(question)")
          .q.title.title--md-thin(v-if="question.type == 'SECTION_TITLE'") {{ question.name }}
          .q(v-else) {{ question.name }} 
            span(v-if="question.required") *
          .a(v-if="question.type == 'RADIO' ")
            inputValidateRadio(
              :key="question.id" 
              :value="question" 
              @emitAnswer="processAnswer" 
              :default="questionsAnsweredLoad[question.id]"
            )

          .a.flex-end(v-if="question.type == 'DROPDOWN' ")
            inputValidateDropdown(
              :key="question.id" 
              :value="question"
              @emitAnswer="processAnswer"  
              :default="questionsAnsweredLoad[question.id]"
            ) 
          .a(v-if="question.type == 'DATE' ")
            inputValidateDate(
              :key="question.id" 
              :value="question" 
              @emitAnswer="processAnswer"  
              :default="questionsAnsweredLoad[question.id]"
            )
          .a(v-if="question.type == 'TEXT' ")
            inputValidateText(
              :key="question.id" 
              :value="question" 
              @emitAnswer="processAnswer"  
              :default="questionsAnsweredLoad[question.id]"
            )
          .a(v-if="question.type == 'NUMERIC' ")
            inputValidateNumeric(
              :key="question.id" 
              :value="question" 
              @emitAnswer="processAnswer"  
              :default="questionsAnsweredLoad[question.id]"
            )
            
          .a(v-if="question.type == 'FILE' ")
            UploadAndPreviewFile(
              :buttonStyleClasses="['btn', 'lg', 'blue', 'btn_gi_match_width']"
              :storageKey="'file_for_question_' + question.id"
              @fileLoaded="forceReRender"
            )
          .a(v-if="question.type == 'NOTES'")
            inputValidateNotes(
              :key="question.id" 
              :value="question" 
              @emitAnswer="processAnswer"  
              :default="questionsAnsweredLoad[question.id]"
            )

    .form-group
      label Upload Clinic Report 
        span(v-if=" specialtyModuleRes?.schemaRules?.patientInfoConfiguration?.requireClinicReport") (Required)
      UploadAndPreviewFile(
        :buttonStyleClasses="['btn', 'lg', 'blue']"
        :storageKey="clinicReportStorageKey"
        @fileLoaded="forceReRender"
      )
.actions-below-table-container
    .actions-below-table-container
    BaseBtn.lg.green-outline(
      @click.prevent="goToPreviousStep",
      style="min-width: 270px",
    ) &lt; PREVIOUS STEP
    BaseBtn.x-lg.green(
      @click.prevent="goToConfirm",
      style="min-width: 270px",
      :disabled="v$.$invalid || !requiredFilesAreUploaded"
    ) CONTINUE
</template>

<script>
import { defineComponent } from "vue";
import moment from "moment";
import {
  getDateFromUnixFormatted,
  getTimeFromUnixFormatted,
  getUserFormatted,
  getDoctorFormatted,
} from "@/helpers";
import { fetchInsurances } from "@/api/insuranceApi";

import { fetchPatient } from "@/api/patientApi";
import { fetchPatientAppointmentAvailableScheduleById } from "@/api/patientAppointmentApi";
import { fetchHospital } from "@/api/hospitalApi";
import { fetchCHSDoctors } from "@/api/chsApi";
import { fetchSpecialtyModule } from "@/api/specialtyModulesApi";
import UploadAndPreviewFile from "@/components/UploadAndPreviewFile";

import { apiStatus, apiStatusComputedFactory } from "@/api";
import { mapGetters } from "vuex";
const { IDLE, PENDING, SUCCESS, ERROR } = apiStatus;

import useVuelidate from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import { maska } from "maska";

import inputValidateRadio from "@/components/clinics/scheduling/inputValidate/inputValidateRadio.vue";
import inputValidateDropdown from "@/components/clinics/scheduling/inputValidate/inputValidateDropdown.vue";
import inputValidateDate from "@/components/clinics/scheduling/inputValidate/inputValidateDate.vue";
import inputValidateText from "@/components/clinics/scheduling/inputValidate/inputValidateText.vue";
import inputValidateNumeric from "@/components/clinics/scheduling/inputValidate/inputValidateNumeric.vue";
import inputValidateNotes from "@/components/clinics/scheduling/inputValidate/inputValidateNotes.vue";

export default defineComponent({
  directives: { maska },
  validations() {
    let args = {
      emergencyContactInformation: {
        firstName: { required },
        lastName: { required },
        phoneNumber: { required, minLength: minLength(12) },
      },
      referringDoctor: { required },
      insuranceInformation: {},
    };
    if (this.schedule && this.schedule.insuranceRequired) {
      args.insuranceInformation = {
        name: { required },
        policyNumber: { required },
        expirationDate: {
          required,
          minLength: minLength(10),
          maxDateAppointment: this.maxDateAppointment,
          maxDateFuture: this.maxDateFuture,
        },
        groupNumber: { required },
      };
    }

    return args;
  },
  components: {
    UploadAndPreviewFile,
    inputValidateRadio,
    inputValidateDropdown,
    inputValidateDate,
    inputValidateText,
    inputValidateNumeric,
    inputValidateNotes,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      insurancesFound: null,
      fetchInsuranceStatus: IDLE,

      hidePolypsFoundSelectLabel: false,
      fetchPatientStatus: IDLE,
      fetchPatientAppointmentAvailableScheduleByIdStatus: IDLE,
      fetchHospitalStatus: IDLE,
      fetchCHSDoctorsStatus: IDLE,
      fetchGiStatus: IDLE,
      patient: null,
      schedule: null,
      hospital: null,
      doctors: null,
      questions: [],
      emergencyContactInformation: {
        firstName: null,
        lastName: null,
        phoneNumber: null,
        phoneNumberRaw: null,
        phoneExtension: null,
      },
      insuranceInformation: {
        name: null,
        policyNumber: null,
        expirationDate: null,
        groupNumber: null,
      },
      questionsAnswered: [],
      referringDoctor: "",
      // clinicFinalNotes: null,
      questionsAnsweredLoad: [],
      specialtyModuleRes: null,
      insuranceCardStorageKey: "schedule_appt_upload_insurance_card",
      clinicReportStorageKey: "schedule_appt_clinic_report",
      forcedReRenderTimes: 0,
    };
  },

  computed: {
    ...mapGetters(["getUserRoleContext", "getSpecialtyById"]),
    ...apiStatusComputedFactory(
      "fetchPatientStatus",
      "fetchPatientAppointmentAvailableScheduleByIdStatus",
      "fetchHospitalStatus",
      "fetchGiStatus"
    ),

    activeQuestions() {
      return this.questions.filter((question) => question.shouldDisplay);
    },

    requiredFilesAreUploaded() {
      this.forcedReRenderTimes;

      const {
        specialtyModule: {
          schemaRules: { patientInfoQuestions, patientInfoConfiguration },
        },
      } = this.getSpecialtyById(+this.$route.params.idSpecialty);

      const { fields } = patientInfoQuestions;

      const allQuestionFilesUploaded = fields
        .filter((field) => field.type === "FILE" && field.required)
        .every((field) => {
          if (field.hasDependencies) {
            const parentField = fields.find(
              (_field) => _field.id === field.rules[0].fieldToCheck
            );
            return parentField.value
              ? sessionStorage.getItem("file_for_question_" + field.id)
              : true;
          } else {
            return sessionStorage.getItem("file_for_question_" + field.id);
          }
        });

      const { requireClinicReport } = patientInfoConfiguration;
      if (requireClinicReport) {
        const file = sessionStorage.getItem(this.clinicReportStorageKey);
        return allQuestionFilesUploaded && !!file;
      }
      return allQuestionFilesUploaded;
    },
  },

  props: {
    specialtyModule: {
      required: false,
    },
  },
  methods: {
    forceReRender() {
      this.forcedReRenderTimes++;
    },
    getUserFormatted,
    getDateFromUnixFormatted,
    getTimeFromUnixFormatted,
    getDoctorFormatted,
    insuranceKeyUp() {
      if (this.insuranceInformation.name != "") {
        this.fetchInsurances();
      } else {
        this.insurancesFound = null;
      }
    },
    async fetchInsurances() {
      this.fetchInsuranceStatus = PENDING;
      try {
        const { data } = await fetchInsurances({
          params: {
            filters: {
              searchBy: "insurance-name",
              insuranceName: this.insuranceInformation.name,
              disabled: false,
            },
            order: { by: "insurance-name", direction: "asc" },
          },
        });
        this.insurancesFound = null;
        this.insurancesFound = data.insurances;

        this.insurancesFound.forEach((element) => {
          element.nameHighlight = this.boldString(
            element.name,
            this.insuranceInformation.name
          );
        });
        this.fetchInsuranceStatus = SUCCESS;
      } catch (err) {
        this.fetchInsuranceStatus = ERROR;
        console.error(err);
      }
    },
    insuranceSelect(insurance) {
      this.insuranceInformation.name = insurance.name;
      this.insurancesFound = null;
    },
    boldString(str, find) {
      let reg = new RegExp("(" + find + ")", "gi");
      return str.replace(reg, "<b>$1</b>");
    },
    maxDateFuture: function (value) {
      let unixDistributed = new Date(value).getTime();
      unixDistributed = unixDistributed / 1000;

      let maxYear = moment().add(25, "years").unix();
      if (unixDistributed > maxYear) {
        return false;
      }
      return true;
    },
    maxDateAppointment: function (value) {
      let unixDistributed = new Date(value).getTime();
      if (!unixDistributed) {
        return false;
      }
      unixDistributed = unixDistributed / 1000;
      if (unixDistributed < this.schedule.availableAt) {
        return false;
      }
      return true;
    },
    phoneNumberRawValue: function (event) {
      this.emergencyContactInformation.phoneNumberRaw =
        event.target.dataset.maskRawValue;
    },
    checkNotes: function (answer) {
      if (answer.type == "NOTES") {
        return "isNotesRow";
      }
    },
    classRadio: function (option) {
      return "radio-custom--" + option.toLowerCase();
    },
    selectAnswer(answer, value) {
      let found = false;
      this.questionsAnswered.forEach((element) => {
        if (element.id == answer.id) {
          element.value = value;
          found = true;
        }
      });
      if (!found) {
        let newAnswerObject = {};
        newAnswerObject.type = answer.type;
        newAnswerObject.value = value;
        newAnswerObject.id = answer.id;
        newAnswerObject.name = answer.name;
        this.questionsAnswered.push(newAnswerObject);
      }

      this.checkDependenciesFor(answer, value);
    },
    checkDependenciesFor(answer, value) {
      this.questions.forEach((element) => {
        if (element.hasDependencies && element.rules) {
          element.rules.forEach((rule) => {
            if (rule.fieldToCheck == answer.id) {
              if (rule.valueToCheck == value) {
                element.shouldDisplay =
                  rule.operatorToUse == "EQUAL" ? true : false;
              } else {
                element.shouldDisplay =
                  rule.operatorToUse == "EQUAL" ? false : true;
              }
              // parsing all existing answers
              this.questionsAnswered.forEach(
                (questionsAnswer, index, object) => {
                  //check if we found an answer that had a dependency
                  if (element.id == questionsAnswer.id) {
                    //check if the dependency is met or not
                    if (!element.shouldDisplay) {
                      element.value = null;
                      if (this.questionsAnsweredLoad) {
                        this.questionsAnsweredLoad[element.id] = null;
                      }
                      this.checkDependenciesFor(element, null);
                      // if its  not met, then remove the answer from existing answers
                      object.splice(index, 1);
                    }
                  }
                }
              );
            }
          });
        }
      });
    },
    goToConfirm() {
      const unixDate = moment(this.insuranceInformation.expirationDate).unix();
      this.insuranceInformation.expirationDate = unixDate;
      let params = {
        idPatient: this.$route.params.idPatient,
        idSchedule: this.$route.params.idSchedule,
        idHospital: this.$route.params.idHospital,
        patient: JSON.stringify(this.patient),
        hospital: JSON.stringify(this.hospital),
        schedule: JSON.stringify(this.schedule),
        referringDoctor: this.referringDoctor,
        emergencyContactInformation: JSON.stringify(
          this.emergencyContactInformation
        ),
        insuranceInformation: JSON.stringify(this.insuranceInformation),
        questionsAnswered: JSON.stringify(this.questionsAnswered),
        questionFiles: JSON.stringify(this.getQuestionFiles()),
        // clinicFinalNotes: this.clinicFinalNotes,
      };
      if (this.$route.params.idFitKit) {
        params.idFitKit = this.$route.params.idFitKit;
      }
      this.$router.push({
        name: "clinics.scheduling.confirm",
        params,
      });
    },
    goToPreviousStep() {
      //clean up possible uploaded files to navigator storage
      sessionStorage.removeItem(this.insuranceCardStorageKey);
      sessionStorage.removeItem(this.clinicReportStorageKey);
      this.getQuestionFiles().map((questionFile) =>
        sessionStorage.removeItem(questionFile.storageKey)
      );

      //navigate
      this.$router.push({
        name: "clinics.scheduling.select-schedule",
        query: this.$route.query,
        params: {
          idPatient: this.$route.params.idPatient,
          idFitKit: this.$route.params.idFitKit,
        },
      });
    },
    async fetchPatient() {
      this.fetchPatientStatus = PENDING;
      try {
        const res = await fetchPatient(this.$route.params.idPatient);
        this.patient = res.data.patient;
        this.fetchPatientStatus = SUCCESS;
      } catch (err) {
        this.fetchPatientStatus = ERROR;
        console.error(err);
      }
    },
    async fetchPatientAppointmentAvailableScheduleById() {
      this.fetchPatientAppointmentAvailableScheduleByIdStatus = PENDING;
      try {
        const res = await fetchPatientAppointmentAvailableScheduleById(
          this.$route.params.idSchedule
        );
        this.schedule = res.data.schedule;
        this.fetchPatientAppointmentAvailableScheduleByIdStatus = SUCCESS;
      } catch (err) {
        this.fetchPatientAppointmentAvailableScheduleByIdStatus = ERROR;
        console.error(err);
      }
    },
    async fetchHospital() {
      this.fetchHospitalStatus = PENDING;
      try {
        const res = await fetchHospital(this.$route.params.idHospital);
        this.hospital = res.data;
        this.fetchHospitalStatus = SUCCESS;
      } catch (err) {
        this.fetchHospitalStatus = ERROR;
        console.error(err);
      }
    },
    async fetchCHSDoctors() {
      this.fetchCHSDoctorsStatus = PENDING;
      try {
        const res = await fetchCHSDoctors(
          this.getUserRoleContext.clinicHealthSystem.id
        );
        this.doctors = res.data.doctors;
        this.fetchCHSDoctorsStatus = SUCCESS;
      } catch (err) {
        this.fetchCHSDoctorsStatus = ERROR;
        console.error(err);
      }
    },
    async fetchGi() {
      this.fetchGiStatus = PENDING;
      try {
        const res = await fetchSpecialtyModule(this.$route.params.idSpecialty);
        let tempQuestions =
          res.data.specialtyModule?.schemaRules?.patientInfoQuestions?.fields;
        this.specialtyModuleRes = res.data.specialtyModule;
        this.questions = [];
        tempQuestions.forEach((element) => {
          if (element.active) {
            element.shouldDisplay = true;
            if (element.hasDependencies && element.rules) {
              element.shouldDisplay = false;
            }
            this.questions.push(element);
          }
        });

        this.fetchGiStatus = SUCCESS;
      } catch (err) {
        this.fetchGiStatus = ERROR;
        console.error(err);
      }
    },
    getQuestionFiles() {
      return this.activeQuestions
        .filter((question) => {
          const questionIsOfTypeFile = question.type === "FILE";
          const questionHasAFileInNavigatorStorage = sessionStorage.getItem(
            "file_for_question_" + question.id
          );

          return questionIsOfTypeFile && questionHasAFileInNavigatorStorage;
        })
        .map((question) => ({
          id: question.id,
          type: question.type,
          storageKey: "file_for_question_" + question.id,
        }));
    },
    processAnswer(value, eventTarget) {
      this.questionsAnsweredLoad[value.id] = eventTarget;
      this.selectAnswer(value, eventTarget);
    },
  },
  created() {
    if (this.$route.params.idPatient === null) return;
    this.fetchPatient();
    this.fetchPatientAppointmentAvailableScheduleById();
    this.fetchHospital();
    this.fetchCHSDoctors();
    this.fetchGi().then(() => {
      if (this.$route.params.questionsAnswered) {
        let questionsAnsweredLoadTemp = JSON.parse(
          this.$route.params.questionsAnswered
        );
        questionsAnsweredLoadTemp.forEach((element) => {
          this.questionsAnsweredLoad[element.id] = element.value;
          this.selectAnswer(element, element.value);
        });
      }
    });

    if (this.$route.params.emergencyContactInformation) {
      this.emergencyContactInformation = JSON.parse(
        this.$route.params.emergencyContactInformation
      );
    }
    if (this.$route.params.referringDoctor) {
      this.referringDoctor = this.$route.params.referringDoctor;
    }

    if (this.$route.params.insuranceInformation) {
      this.insuranceInformation = JSON.parse(
        this.$route.params.insuranceInformation
      );
      this.insuranceInformation.expirationDate = moment
        .unix(this.insuranceInformation.expirationDate)
        .format("MM/DD/YYYY");
    }
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/components/qa-radios.scss";
@import "@/scss/components/actions-below-table-container.scss";
@import "@/scss/components/step-subtitle.scss";
.appointment-info {
  width: 100%;
  padding: 0 $secondaryCardsSidePadding;
}
.gi .qa-radios__qa.isNotesRow {
  flex-direction: column;
  align-items: start;
  .a {
    width: 100%;
    margin-top: 15px;
    height: 250px;
  }
}
.appt-details-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px $primaryBgDarkGrey;
  margin-top: 34px;
  padding-bottom: 34px;
  margin-bottom: 24px;
  font-size: 18px;
  font-weight: bold;
}
.cols {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .col {
    width: math.percentage(math.div(345, 1108));
    margin-right: 36px;
    flex-grow: 0;
    flex-shrink: 0;

    &:last-child {
      margin-right: auto;
    }
  }
}
.q.title--md-thin {
  font-size: 28px;
  font-weight: 300;
  line-height: 1.1;
  letter-spacing: 0.16px;
  color: #019bdf;
  text-transform: uppercase;
  margin-top: 20px;
}
.title--md-thin {
  margin-bottom: 26px;
}
.eci {
  margin-bottom: 50px;
  .col {
    &:nth-child(3) {
      width: math.percentage(math.div(227, 1108));
    }
    &:nth-child(4) {
      width: math.percentage(math.div(81, 1108));
      margin-right: 0;
    }
  }
}
.insurance {
  position: relative;
  padding: 59px 10px 46px;
  margin-bottom: 62px;
  &:before {
    content: "";
    position: absolute;
    left: -$secondaryCardsSidePadding;
    right: -$secondaryCardsSidePadding;
    top: 0;
    bottom: 0;
    background-color: $primaryBgBlue;
  }
  &__content {
    position: relative;
  }
  .insurance-status-wrapper {
    padding-bottom: 24px;
    border-bottom: solid 1px $primaryBgDarkGrey;
    margin-bottom: 21px;
  }
  .insurance-status {
    width: math.percentage(math.div(345, 1108));
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      margin: 0;
      font-size: 14px;
      font-weight: 500;
      line-height: 1.29;
      letter-spacing: 0.07px;
    }
    .svg-icon {
      font-size: 50px;
      top: 0;
    }
  }
  .cols-2 {
    .col {
      &:last-child {
        width: 168px;
        .btn {
          width: 168px;
        }
      }
    }
  }
}

.gi {
  .qa-radios {
    margin-bottom: 35px;
    .q {
      max-width: 798px;
    }
    .a {
      width: 200px;
      height: 50px;
      align-items: center;
    }
    .a.flex-end {
      justify-content: flex-end;
    }
  }
}
.dropdown_input_wrapper {
  position: relative;
  width: 100%;
  height: 0px;
  margin: 0px;
  ul {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: block;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
    box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
    max-height: 300px;
    overflow: scroll;
    li {
      display: block;
      padding: 3px 20px;
      line-height: 1.42857143;
      white-space: nowrap;
      cursor: pointer;
    }
    li.active,
    li:hover {
      color: #fff;
      text-decoration: none;
      background-color: $primaryLightBlue;
      outline: 0;
    }
  }
}
</style>
<style>
.btn_gi_match_width {
  width: 192px;
}
</style>
