<template lang="pug">
.table-title(:class="{'table-title--padded': padded}")
  h2.table-title__title {{title}}
  .table-title__actions
    slot(name="actions")
    form.table-title__searchbox(
      v-if="searchPlaceholder",
      v-bind="$attrs.disabled",
      @submit.prevent="doSearch"
    )
      input(
        :value="modelValue",
        @change="$emit('update:modelValue', $event.target.value)",
        type="search",
        :placeholder="searchPlaceholder"
      )
      base-icon(v-show="!modelValue", name="search-loupe", @click="doSearch")
      base-icon(v-show="modelValue", name="x", @click="clearSearchField")
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  components: {},
  emits: ["update:modelValue", "doSearch"],

  props: {
    modelValue: {
      type: String,
      default: "",
      required: false,
    },
    padded: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      required: true,
    },
    searchPlaceholder: {
      type: String,
      required: false,
    },
    disabled: {
      required: false,
    },
  },

  data() {
    return {
      newModelValue: "",
    };
  },

  methods: {
    doSearch() {
      this.$emit("doSearch");
    },

    clearSearchField() {
      this.$emit("update:modelValue", "");
      this.$nextTick(() => {
        this.doSearch();
      });
    },
  },
});
</script>

<style scoped lang="scss">
.table-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 54px 0 55px;
  &__title {
    margin: 0 20px 0 0;
    font-size: 28px;
    font-weight: 700;
    line-height: 50px;
    letter-spacing: 0.14px;
  }
  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
    flex-wrap: wrap;
  }
  &__searchbox {
    display: flex;
    align-items: center;
    position: relative;
    flex-basis: auto;
    flex-grow: 1;
    min-width: 390px;
    max-width: 464px;
    input[type="search"] {
      // flex-basis: auto;
      // flex-grow: 1;
      // min-width: 390px;
      // max-width: 464px;
      height: 50px;
      padding: 12.5px 62px 12.7px 32.5px;
      border-radius: 30px;
      border: solid 2px $primaryLightBlue;
      font-weight: 400;
    }
    .svg-icon {
      position: absolute;
      font-size: 25px;
      color: $primaryMidGrey;
      top: 50%;
      right: 28px;
      transform: translateY(-50%);
      color: #9b9b9b;
      pointer-events: auto;
    }
  }
  &--padded {
    padding-left: 30px;
    padding-right: 30px;
  }
}
</style>
