// @ts-nocheck
import api from "./api";
const URLS = {
  CLINIC: "/clinic",
};

export const createClinic = (data = {}) => {
  return api.post(URLS.CLINIC, data);
};
export const fetchClinic = (idClinic, data = {}) => {
  return api.get(`${URLS.CLINIC}/${idClinic}`, data);
};
export const updateClinic = (idClinic, data = {}) => {
  return api.put(`${URLS.CLINIC}/${idClinic}`, data);
};

export const fetchClinicSummary = (idClinic, data = {}) => {
  return api.get(URLS.CLINIC + "/" + idClinic + "/dashboard/summary", data);
};

// TODO: delete commented fetchClinicDashboard method?
// export const fetchClinicDashboard = (
//   idClinic: number,
//   idSpecialtyModule: number
// ): Promise<any> => {
//   return api.get(`${URLS.CLINIC}/${idClinic}/dashboard/summary`, {
//     params: {
//       idSpecialtyModule,
//     },
//   });
// };

export const fetchClinicDashboard = (idClinic: number): Promise<any> => {
  return api.get(`${URLS.CLINIC}/${idClinic}/full/dashboard/summary`);
};
export const fetchFitKitDashboard = (idClinic: number): Promise<any> => {
  return api.get(`${URLS.CLINIC}/${idClinic}/fit-kits/dashboard/summary`);
};
export const fetchFitKitProviders = (idClinic, data = {}) => {
  return api.get(`${URLS.CLINIC}/${idClinic}/fit-kits/providers`, data);
};
export const createPatientOnClinic = (idClinic, data = {}) => {
  return api.post(`${URLS.CLINIC}/${idClinic}/patient`, data);
};

export const fetchFullClinicDashboard = (idClinic: number): Promise<any> => {
  return api.get(`${URLS.CLINIC}/${idClinic}/full/dashboard/summary`);
};

export const createWorkWithCommunity = (idClinic, ccoId, data = {}) => {
  return api.post(URLS.CLINIC + "/" + idClinic + "/work-with/" + ccoId, data);
};

export const deleteWorkWithCommunity = (idClinic, ccoId, data = {}) => {
  return api.delete(URLS.CLINIC + "/" + idClinic + "/work-with/" + ccoId, data);
};

export const getClinicDoctors = (idClinic) => {
  return api.get(URLS.CLINIC + "/" + idClinic + "/doctor");
};

export const createScheduleClinicDoctor = (idClinic, idDoctor, data = {}) => {
  return api.post(
    URLS.CLINIC + "/" + idClinic + "/doctor/" + idDoctor + "/schedule",
    data
  );
};
