<template lang="pug">
SideOverlaySlot(
  :isOpen="isOpen",
  title="ADD NEW INSURANCE"
  @closeOverlay="closeOverlay"
)
  template(#contentTop)
    .form-group
      label(for="insurance-name") Insurance Name (Required)
      input#insurance-name(type="text" v-model="name")
  template(#footer)
    .flex.flex--space-between
      BaseBtn.x-lg.red-outline(
        @click.prevent="closeOverlay"
      ) CANCEL
      BaseBtn.x-lg.green(type="button" @click="createInsurance" :disabled="!name") CONTINUE
</template>

<script>
import { defineComponent } from "vue";
import SideOverlaySlot from "@/components/overlays/SideOverlaySlot.vue";
import { createInsurance } from "@/api/insuranceApi";
import { apiStatus, apiStatusComputedFactory } from "@/api";
const { IDLE, PENDING, SUCCESS, ERROR } = apiStatus;

export default defineComponent({
  components: { SideOverlaySlot },
  emits: ["closeAddInsuranceOverlay"],

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      name: "",
      createInsuranceStatus: IDLE,
    };
  },

  computed: {
    ...apiStatusComputedFactory(["createInsuranceStatus"]),
  },

  methods: {
    closeOverlay() {
      this.$emit("closeAddInsuranceOverlay");
    },
    async createInsurance() {
      if (this.name) {
        this.createInsuranceStatus = PENDING;
        try {
          createInsurance({
            insurance: { name: this.name },
          });
          this.createInsuranceStatus = SUCCESS;
          this.closeOverlay();
        } catch (error) {
          console.log(error);
          this.createInsuranceStatus = ERROR;
        }
      }
    },
  },
});
</script>

<style scoped lang="scss">
.patient-section {
  margin-bottom: 31px;
  padding: 37px 24px 25px;
  background-color: $primaryBgBlue;
  p {
    margin: 0 0 12px;
    font-size: 21px;
    font-weight: 500;
    line-height: 1.1;
    color: $primaryLightBlue;
    overflow: hidden;
    strong {
      font-weight: 700;
    }
  }
}
</style>
