//@ts-nocheck
import api from "./api";
const URL = "/community-care-organization";

export const fetchCommunityCareOrganizationById = (id) => {
  return api.get(URL + "/" + id);
};

export const fetchCommunityCareOrganizationPatients = (id, data = {}) => {
  return api.get(URL + "/" + id + "/patient", data);
};

export const fetchCommunityCareOrganizationPatientByID = (
  id,
  idPatient,
  data = {}
) => {
  return api.get(URL + "/" + id + "/patient/" + idPatient, data);
};

export const fetchCommunityCareOrganizationFilesById = (id) => {
  return api.get(URL + "/" + id + "/files");
};

export const fetchCommunityCareOrganizations = (data = {}) => {
  return api.get(URL, data);
};

export const fetchCommunityCareOrganizationsClinics = (ccoId, data = {}) => {
  return api.get(URL + "/" + ccoId + "/clinic", data);
};

export const createCCO = (data = {}) => {
  return api.post(URL, data);
};

export const updateCCO = (ccoId, data = {}) => {
  return api.put(URL + "/" + ccoId, data);
};

export const updateCCOPatient = (ccoId, idPatient, data = {}) => {
  return api.put(URL + "/" + ccoId + "/patient/" + idPatient, data);
};

export const addCCOFiles = (ccoId, data = {}) => {
  return api.post(URL + "/" + ccoId + "/files", data);
};

export const createPatientOnCommunityCareOrganization = (id, data = {}) => {
  return api.post(URL + "/" + id + "/patient", data);
};

export const fetchFitKitDashboard = (idCommunity: number): Promise<any> => {
  return api.get(`${URL}/${idCommunity}/fit-kits/dashboard/summary`);
};

export const fetchFitKitProviders = (idCommunity, data = {}) => {
  return api.get(`${URL}/${idCommunity}/fit-kits/providers`, data);
};
