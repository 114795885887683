// @ts-nocheck
export default {
  data() {
    return {
      sortings: {
        DoctorName: [
          undefined,
          { by: "doctor-name", icon: "▲", direction: "asc" },
          { by: "doctor-name", icon: "▼", direction: "desc" },
        ],
        DateAndTime: [
          undefined,
          { by: "date-time", icon: "▲", direction: "asc" },
          { by: "date-time", icon: "▼", direction: "desc" },
        ],
        ClinicName: [
          undefined,
          { by: "clinic-name", icon: "▲", direction: "asc" },
          { by: "clinic-name", icon: "▼", direction: "desc" },
        ],
        Insurance: [
          undefined,
          { by: "insurance-required", icon: "▲", direction: "asc" },
          { by: "insurance-required", icon: "▼", direction: "desc" },
        ],
        ApptScheduled: [
          undefined,
          { by: "is-scheduled", icon: "▲", direction: "asc" },
          { by: "is-scheduled", icon: "▼", direction: "desc" },
        ],
      },
      currentSortKey: "DoctorName",
      currentSortIndex: 0,
      listOrder: undefined,
    };
  },

  computed: {
    DoctorNameColumnSortingIcon() {
      if (this.currentSortKey === "DoctorName") {
        return this.sortings.DoctorName[this.currentSortIndex]?.icon || "▼▲";
      }
      return "▼▲";
    },
    DateAndTimeColumnSortingIcon() {
      if (this.currentSortKey === "DateAndTime") {
        return this.sortings.DateAndTime[this.currentSortIndex]?.icon || "▼▲";
      }
      return "▼▲";
    },
    ClinicNameColumnSortingIcon() {
      if (this.currentSortKey === "ClinicName") {
        return this.sortings.ClinicName[this.currentSortIndex]?.icon || "▼▲";
      }
      return "▼▲";
    },
    InsuranceColumnSortingIcon() {
      if (this.currentSortKey === "Insurance") {
        return this.sortings.Insurance[this.currentSortIndex]?.icon || "▼▲";
      }
      return "▼▲";
    },
    ApptScheduledColumnSortingIcon() {
      if (this.currentSortKey === "ApptScheduled") {
        return this.sortings.ApptScheduled[this.currentSortIndex]?.icon || "▼▲";
      }
      return "▼▲";
    },
  },

  methods: {
    changeListOrder(key, callback) {
      if (this.currentSortKey === key) {
        this.updateCurrentSortingIndex(callback);
        return;
      }

      this.currentSortKey = key;
      this.currentSortIndex = 0;
      this.updateCurrentSortingIndex(callback);
    },
    updateCurrentSortingIndex(callback) {
      if (
        this.currentSortIndex ===
        this.sortings[this.currentSortKey].length - 1
      ) {
        this.currentSortIndex = 0;
        this.listOrder = this.sortings[this.currentSortKey][0];
        callback();
        return;
      }

      this.currentSortIndex++;
      this.listOrder =
        this.sortings[this.currentSortKey][this.currentSortIndex];
      return callback();
    },
  },
};
