<template lang="pug">
SideOverlaySlot(
  :isOpen="isOpen",
  title="Ongoing Care"
  @closeOverlay="closeOverlay"
)
  template(#contentTop)
    section.patient-section
      p(v-if="onGoingHistory") <strong>Patient: {{ getUserFormatted(onGoingHistory.patient) }}</strong>
  template(#scrollbox)
    .title.title--md Appointment History
    .table
      .th
        .td Appt. Time
        .td COMPLETED
        .td
      .tr(
        v-for="history in onGoingHistory?.appointmentHistory",
        :key="history.id"
      )
        .td
          div {{ getDateFromUnixFormatted(history.scheduleAt) }}
          .second-row-md-grey {{ getTimeFromUnixFormatted(history.scheduleAt) }}
        .td
          base-icon(name="yes" v-if="history.isCompleted")
          base-icon(name="no" v-else)
        .td
          BaseBtn.sm.blue(
            href="#",
            @click.prevent="openAppointmentRecordOverlay(history.id)"
          ) VIEW APPT
    .title.title--md(v-if="onGoingHistory?.nextAppointments.length") Next Appointments
    .table(v-if="onGoingHistory?.nextAppointments.length")
      .tr(v-for="nextAppointment in onGoingHistory?.nextAppointments")
        .td
          div {{ getDateFromUnixFormatted(nextAppointment.scheduleAt) }}
          .second-row-md-grey {{ getTimeFromUnixFormatted(nextAppointment.scheduleAt) }}
        .td
        .td
          BaseBtn.sm.blue(
            href="#",
            @click.prevent="openAppointmentRecordOverlay(nextAppointment.id)"
          ) VIEW APPT

ClinicAppointmentRecordOverlay(
  :key="`${componentKey}-1`",
  :isOpen="isClinicAppointmentRecordOverlayOpen",
  tabType="displayOnly"
  :showAppointment="showAppointment"
  @closeClinicAppointmentRecordOverlay="closeClinicAppointmentRecordOverlay",
  :specialtyModule="specialtyModule"
)

</template>

<script>
import { defineComponent } from "vue";
import SideOverlaySlot from "@/components/overlays/SideOverlaySlot.vue";
import ClinicAppointmentRecordOverlay from "@/components/clinics/appointment-status/overlays/ClinicAppointmentRecordOverlay.vue";
import listPagesMixin from "@/mixins/listPagesMixin";

import { fetchOnGoingCarePatientHistory } from "@/api/onGoingCareApi";
import { apiStatus, apiStatusComputedFactory } from "@/api";
const { IDLE, PENDING, SUCCESS, ERROR } = apiStatus;

import { fetchPatientAppointmentById } from "@/api/patientAppointmentApi";

import {
  getDateFromUnixFormatted,
  getTimeFromUnixFormatted,
  getUserFormatted,
} from "@/helpers";

export default defineComponent({
  components: { SideOverlaySlot, ClinicAppointmentRecordOverlay },
  emits: ["closeAppointmentHistoryOverlay", "openAppointmentRecordOverlay"],
  data() {
    return {
      isClinicAppointmentRecordOverlayOpen: false,
      fetchOnGoingCarePatientHistoryStatus: IDLE,
      fetchPatientAppointmentByIdStatus: IDLE,
      onGoingHistory: null,
      showAppointment: null,
    };
  },
  mixins: [listPagesMixin],

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    patientId: {
      required: true,
      type: Number,
    },
    specialtyModule: {
      required: false,
    },
  },
  methods: {
    getDateFromUnixFormatted,
    getTimeFromUnixFormatted,
    getUserFormatted,
    async fetchOnGoingCarePatientHistory() {
      this.fetchOnGoingCarePatientHistoryStatus = PENDING;
      let patientId = this.patientId;
      try {
        const res = await fetchOnGoingCarePatientHistory(patientId, {
          params: {
            filters: {
              idClinic: parseInt(this.$route.params.idClinic),
            },
            idSpecialtyModule: parseInt(this.$route.params.idSpecialty),
          },
        });
        this.onGoingHistory = res.data.onGoingHistory;

        this.fetchOnGoingCarePatientHistoryStatus = SUCCESS;
      } catch (err) {
        this.fetchOnGoingCarePatientHistoryStatus = ERROR;
        console.error(err);
      }
    },
    closeOverlay() {
      this.$emit("closeAppointmentHistoryOverlay");
    },
    openAppointmentRecordOverlay(idSchedule) {
      this.fetchPatientAppointmentById(idSchedule);
    },
    closeClinicAppointmentRecordOverlay() {
      this.isClinicAppointmentRecordOverlayOpen = false;
    },

    async fetchPatientAppointmentById(idSchedule) {
      this.fetchPatientAppointmentByIdStatus = PENDING;
      try {
        const res = await fetchPatientAppointmentById(idSchedule, {
          params: {
            include: [
              "clinic",
              "hospitalDoctor",
              "patient",
              "referringDoctor",
              "hospital",
            ],
          },
        });
        this.showAppointment = res.data.patientAppointment;
        this.isClinicAppointmentRecordOverlayOpen = true;
        this.componentKey = this.componentKey + 1;

        this.fetchPatientAppointmentByIdStatus = SUCCESS;
      } catch (err) {
        this.fetchPatientAppointmentByIdStatus = ERROR;
        console.error(err);
      }
    },
  },
  created() {
    if (this.patientId !== null && this.patientId != 0) {
      this.fetchOnGoingCarePatientHistory();
    }
  },
});
</script>

<style scoped lang="scss">
.patient-section {
  margin-bottom: 31px;
  padding: 37px 24px 25px;
  background-color: $primaryBgBlue;
  p {
    margin: 0 0 12px;
    font-size: 21px;
    font-weight: 500;
    line-height: 1.1;
    color: $primaryLightBlue;
    overflow: hidden;
    strong {
      font-weight: 700;
    }
  }
}
.btn.sm.blue {
  min-width: 120px;
  width: 120px;
  padding-left: 2px;
  padding-right: 2px;
}
.title.title--md {
  margin-bottom: 25px;
}

.table {
  margin-bottom: 25px;
  .tr {
    &:hover {
      background-color: transparent;
      cursor: default;
    }
  }
  .td {
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 2px;
    }
    &:nth-child(1) {
      width: math.percentage(math.div(154, 438));
    }
    &:nth-child(2) {
      width: math.percentage(math.div(160, 438));
    }
    &:nth-child(3) {
      width: math.percentage(math.div(126, 438));
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
    }
  }
}
.next-apt {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -7px;
  margin-bottom: 14px;
}
</style>
