<template lang="pug">
.card
  BaseBtn.x-lg.blue(href="#") NOTES (265)
  BaseBtn.x-lg.blue.disabled(href="#") NOTES (265)
  br
  br
  BaseBtn.x-lg.blue-outline(href="#", :count="2") NOTES (265)
  BaseBtn.x-lg.blue-outline.disabled(href="#", :count="2657") NOTES (265)
  br
  br
  BaseBtn.lg.blue(href="#") Login
  BaseBtn.lg.blue.disabled(href="#") Login
  BaseBtn.lg.breen.disabled(href="#") Login
  br
  br
  BaseBtn.lg.green(href="#") SAVE AND RETURN TO DASHBOARD
  BaseBtn.lg.green.disabled(href="#") SAVE AND RETURN TO DASHBOARD
  br
  br
  BaseBtn.md.green(href="#") SAVE AND RETURN TO DASHBOARD
  BaseBtn.md.green.disabled(href="#") SAVE AND RETURN TO DASHBOARD
  br
  br
  BaseBtn.sm.grey-outline(href="#") Reset
  BaseBtn.sm.grey-outline.disabled(href="#") SAVE AND RETURN TO DASHBOARD
  br
  br
  BaseBtn.sm.green(href="#") Reset
  BaseBtn.sm.green.disabled(href="#") SAVE AND RETURN TO DASHBOARD
  br
  br
  BaseBtn.sm.black-outline(href="#") Reset
  BaseBtn.sm.black-outline.disabled(href="#") SAVE AND RETURN TO DASHBOARD
  br
  br
  BaseBtn.sm.red-outline(href="#") Reset
  BaseBtn.sm.red-outline.disabled(href="#") SAVE AND RETURN TO DASHBOARD
.card
  a(href="#", @click.prevent="")
    BoxLinkSm(text="Positive FIT / Colonoscopy Req.", number="3377")
  br
  br
  a(href="#", @click.prevent="")
    BoxLinkMd.red(text="Positive FIT Colonoscopy Req.", number="2")
  br
  br
  a(href="#", @click.prevent="")
    BoxLinkMd.yellow(text="Positive FIT Colonoscopy Req.", number="2")
  br
  br
  a(href="#", @click.prevent="")
    BoxLinkMd.yellow-outline(text="Positive FIT Colonoscopy Req.", number="2")
  br
  br
  a(href="#", @click.prevent="")
    BoxLinkMd.grey-outline(text="Positive FIT Colonoscopy Req.", number="2")
  br
  br
  LineStats(:stats="stats")
  br
  br
  //- DotStats(:stats="stats")

.card
  h3 Custom checkbox
  BaseCheckbox(label="Referral Info Title")
  h3 Custom toggle
  BaseToggle(label="Referral Info Title")

.card
  h3 Custom radios
  label.radio-custom.radio-custom--yes
    input(type="radio", name="test")
    span.radio
    span.lbl Yes
  label.radio-custom.radio-custom--no
    input(type="radio", name="test")
    span.radio
    span.lbl No

.card
  .card-inner
    h3 Form with errors
    form(novalidate)
      .form-group.form-group__invalid
        label(for="email") Email
        input#email(type="email")
        .form-group__errors
          .form-group__error Invalid email format
      .form-group
        label(for="password") Password
        input#password(type="password")
      .form-actions
        BaseBtn.x-lg.blue.disabled(href="#") Login
      .form-error-main 101 - The server is unable to connect.


</template>

<script>
import { defineComponent } from "vue";
import BoxLinkSm from "@/components/BoxLinkSm.vue";
import BoxLinkMd from "@/components/BoxLinkMd.vue";
import LineStats from "@/components/LineStats.vue";
import DotStats from "@/components/DotStats.vue";

export default defineComponent({
  components: { BoxLinkSm, BoxLinkMd, LineStats, DotStats },

  props: {
    // project: {
    //   type: Object,
    //   required: true
    // }
  },

  data() {
    return {
      stats: [
        {
          label: "Completed",
          number: 48,
          backgroundColor: "#96bf3c",
        },
        {
          label: "Cancelled",
          number: 9,
          backgroundColor: "#d5351d",
        },
        {
          label: "No Show",
          number: 24,
          backgroundColor: "#6d6262",
        },
      ],
    };
  },

  computed: {},

  watch: {
    // keywords: {
    //   handler: "getRows",
    //   immediate: true
    // }
  },

  methods: {},
});
</script>

<style scoped lang="scss">
.btn {
  margin-right: 20px;
}
.card {
  padding: 20px;
}
</style>
