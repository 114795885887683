<template lang="pug">
.confirmation-info
  .columns
    .column.is-one-third
      .info-pair
        .label Name
        .txt(v-if="patient") {{ getUserFormatted(patient) }}
    .column.is-one-third
      .info-pair
        .label Date
        .txt(v-if="schedule") {{ getDateFromUnixFormatted(schedule.availableAt) }}
    .column.is-one-third
      .info-pair
        .label Time
        .txt(v-if="schedule") {{ getTimeFromUnixFormatted(schedule.availableAt) }} 
  .columns
    .column.is-one-third
      .info-pair
        .label Hospital 
        .txt(v-if="hospital") {{ hospital.name }}<br/> {{ hospital.address.address}}<br/> {{ hospital.address.place }}, {{ hospital.address.abbreviation}} {{ hospital.address.zipCode }}<br />{{ getPhoneFormatted(hospital.contact.businessPhone) }}
    .column.is-two-third(v-if="withReminder")
      .info-pair.reminder-area
         .txt What to bring to your first visit: 
         p · Picture ID <br/> · A medical card or insurance card, if you have one <br/> · If no insurance, bring proof of income
</template>

<script>
import { defineComponent } from "vue";
import {
  getDateFromUnixFormatted,
  getTimeFromUnixFormatted,
  getUserFormatted,
  getPhoneFormatted,
} from "@/helpers";
import { fetchPatient } from "@/api/patientApi";
import { fetchPatientAppointmentAvailableScheduleById } from "@/api/patientAppointmentApi";
import { fetchHospital } from "@/api/hospitalApi";

import { apiStatus, apiStatusComputedFactory } from "@/api";
const { IDLE, PENDING, SUCCESS, ERROR } = apiStatus;

export default defineComponent({
  components: {},

  props: {
    withReminder: {
      type: Boolean,
      required: false,
    },
  },

  data() {
    return {
      fetchPatientStatus: IDLE,
      fetchPatientAppointmentAvailableScheduleByIdStatus: IDLE,
      fetchHospitalStatus: IDLE,
      patient: null,
      schedule: null,
      hospital: null,
    };
  },

  computed: {
    ...apiStatusComputedFactory(
      "fetchPatientStatus",
      "fetchPatientAppointmentAvailableScheduleByIdStatus",
      "fetchHospitalStatus"
    ),
  },

  methods: {
    getDateFromUnixFormatted,
    getTimeFromUnixFormatted,
    getUserFormatted,
    getPhoneFormatted,
    async fetchPatient() {
      this.fetchPatientStatus = PENDING;
      try {
        const res = await fetchPatient(this.$route.params.idPatient);
        this.patient = res.data.patient;
        this.fetchPatientStatus = SUCCESS;
      } catch (err) {
        this.fetchPatientStatus = ERROR;
        console.error(err);
      }
    },
    async fetchPatientAppointmentAvailableScheduleById() {
      this.fetchPatientAppointmentAvailableScheduleByIdStatus = PENDING;
      try {
        const res = await fetchPatientAppointmentAvailableScheduleById(
          this.$route.params.idSchedule
        );
        this.schedule = res.data.schedule;
        this.fetchPatientAppointmentAvailableScheduleByIdStatus = SUCCESS;
      } catch (err) {
        this.fetchPatientAppointmentAvailableScheduleByIdStatus = ERROR;
        console.error(err);
      }
    },
    async fetchHospital() {
      this.fetchHospitalStatus = PENDING;
      try {
        const res = await fetchHospital(this.$route.params.idHospital);
        this.hospital = res.data;
        this.fetchHospitalStatus = SUCCESS;
      } catch (err) {
        this.fetchHospitalStatus = ERROR;
        console.error(err);
      }
    },
  },
  created() {
    if (this.$route.params.idPatient === null) return;
    this.fetchPatient();
    this.fetchPatientAppointmentAvailableScheduleById();
    this.fetchHospital();
  },
});
</script>

<style scoped lang="scss">
.confirmation-info {
  background-color: $primaryBgBlue;
  padding: 37px 37px 17px;
}
.reminder-area {
  line-height: normal;
  margin-top: 1.5em;
  p {
    margin-top: 0;
  }
}
</style>
