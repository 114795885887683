<template lang="pug">
label.upload-label(v-if="labelFor", :for="labelFor")
  .uploaded-image-preview(
    :style="{backgroundImage: 'url(' + srcWithFallback +')'}"
  )
.uploaded-image-preview(
  v-else="",
  :style="{backgroundImage: 'url(' + srcWithFallback +')'}"
)
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    src: {
      type: String,
    },
    labelFor: {
      type: String,
    },
  },

  computed: {
    srcWithFallback() {
      if (!this.src)
        return "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI0LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkNhcGFfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiCgkgdmlld0JveD0iMCAwIDU1MC44IDQyOC40IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1NTAuOCA0MjguNDsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8c3R5bGUgdHlwZT0idGV4dC9jc3MiPgoJLnN0MHtmaWxsOiMwMTlCREY7fQo8L3N0eWxlPgo8Zz4KCTxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik01MTUuOCwwSDM1QzE1LjcsMCwwLDE1LjcsMCwzNXYzNTguNWMwLDE5LjMsMTUuNywzNSwzNSwzNWg0ODAuOWMxOS4zLDAsMzUtMTUuNywzNS0zNVYzNQoJCUM1NTAuOCwxNS43LDUzNS4xLDAsNTE1LjgsMHogTTUxNS44LDM1djI1NC4zbC02OC45LTYyLjdjLTEwLjQtOS40LTI2LjMtOS0zNi4yLDAuOUwzNDEsMjk3LjNMMjAzLjQsMTMzCgkJYy0xMC40LTEyLjQtMjkuNC0xMi41LTQwLTAuM0wzNSwyODJWMzVINTE1Ljh6IE0zNjcuMiwxMjYuOGMwLTI2LjYsMjEuNS00OC4xLDQ4LjEtNDguMWMyNi42LDAsNDguMSwyMS41LDQ4LjEsNDguMQoJCXMtMjEuNSw0OC4xLTQ4LjEsNDguMUMzODguNywxNzQuOSwzNjcuMiwxNTMuMywzNjcuMiwxMjYuOHoiLz4KPC9nPgo8L3N2Zz4K";
      return this.src;
    },
  },
});
</script>

<style scoped lang="scss">
.upload-label {
  cursor: pointer;
  display: block;
  width: 50px;
  height: 50px;
  margin-bottom: 0 !important;
}
.uploaded-image-preview {
  display: block;
  width: 50px;
  height: 50px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
</style>
