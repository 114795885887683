<template lang="pug">
SideOverlaySlot(
  :isOpen="isOpen",
  title="SCHEDULE COLONOSCOPY<br> OUTSIDE ICC"
  @closeOverlay="closeOverlay"
)
  template(#contentTop)
    PatientSection(:fitKit="selectedFitKit", :showDetails="false", :showProvider="false")
    section.warning-box(v-if="!isCompleted")
      p
        strong WARNING!
      p You are about to make/schedule the colonoscopy appointment outside the ICC system.
      p Appointment will be tracked under OUTSIDE ICC APPOINTMENTS until marked completed.
    section.overlay-show-success(v-else="")
      p Colonoscopy outside ICC successfully created!
  template(#footer)
    .flex.flex--space-between(v-if="!isCompleted")
      BaseBtn.x-lg.red-outline(
        href="#",
        @click.prevent="closeOverlay"
      ) CANCEL
      BaseBtn.x-lg.green(
        href="#",
        @click.prevent="scheduleOutsideIcc"
      ) CONTINUE
    .flex(v-else="")
      BaseBtn.x-lg.blue-outline(
        style="width: 100%"
        @click.prevent="goToOutsideIcc"
      ) SEE ALL OUTSIDE ICC APPOINTMENTS
</template>

<script>
import { defineComponent } from "vue";
import SideOverlaySlot from "@/components/overlays/SideOverlaySlot.vue";
import PatientSection from "@/components/fit-kit/overlays/PatientSection.vue";
import { createColonoscopyOutsideICC } from "@/api/fitKitApi";
import { createToast } from "mosha-vue-toastify";

export default defineComponent({
  components: {
    SideOverlaySlot,
    PatientSection,
  },
  emits: ["closeScheduleColonoscopyOutsideIccOverlay"],

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    selectedFitKit: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      isCompleted: false,
    };
  },
  methods: {
    closeOverlay() {
      this.$emit("closeScheduleColonoscopyOutsideIccOverlay", {
        afterAction: this.isCompleted,
      });
      this.isCompleted = false;
    },
    goToOutsideIcc() {
      const idClinic = this.selectedFitKit.idClinic;
      this.$router.push({ path: `/fit-outside-icc/${idClinic}/pending` });
    },
    async scheduleOutsideIcc() {
      const idFitKit = this.selectedFitKit.id;
      try {
        const resFitKit = await createColonoscopyOutsideICC(idFitKit);
        if (resFitKit.data.success) {
          this.isCompleted = true;
        }
      } catch (err) {
        createToast(err?.response?.data?.message || "There was a problem", {
          timeout: 4000,
          type: "danger",
          position: "bottom-right",
        });
        console.error(err);
      }
    },
  },
});
</script>

<style scoped lang="scss">
.warning-box {
  overflow: hidden;
  margin-top: 7px;
  max-width: 429px;
  font-size: 21px;
  font-weight: 500;
  line-height: 1.19;
  color: $secondaryRed;
  strong {
    font-weight: 700;
  }
}
.overlay-show-success {
  max-width: 90%;
}
</style>
