<template lang="pug">
SideOverlaySlot(
  :isOpen="isOpen",
  title="CLINIC REFERRAL"
  @closeOverlay="closeOverlay"
)
  template(#contentTop)
    PatientSection(:fitKit="selectedFitKit", :showProvider="false")
    .overlay-pairs
      .pairs.columns
        .pair.column
          .pair-label Clinic Referred To
          .pair-txt <strong>{{getClinicReferredTo(selectedFitKit)}}</strong>
      .pairs.columns
        .pair.column.is-half
          .pair-label User Referring
          .pair-txt <strong>{{getReferringUser(selectedFitKit)}}</strong>
        .pair.column.is-half
          .pair-label Date of Referral
          .pair-txt <strong>{{getReferralDate(selectedFitKit)}}</strong>
      .pairs.columns
        .pair.column
          .pair-label Notes
          .pair-txt <strong>{{getReferralNotes(selectedFitKit)}}</strong>
</template>

<script>
import { defineComponent } from "vue";
import SideOverlaySlot from "@/components/overlays/SideOverlaySlot.vue";
import PatientSection from "@/components/fit-kit/overlays/PatientSection.vue";
import { getUserFormatted, getDateFromUnixFormatted } from "@/helpers";

export default defineComponent({
  components: {
    SideOverlaySlot,
    PatientSection,
  },
  emits: ["closeClinicReferralOverlay"],

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    selectedFitKit: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {};
  },
  methods: {
    closeOverlay() {
      this.$emit("closeClinicReferralOverlay");
    },
    getUserFormatted: getUserFormatted,
    getDateFromUnixFormatted: getDateFromUnixFormatted,
    getClinicReferredTo(fitKit) {
      return fitKit && fitKit.referral && fitKit.referral.clinicReferredTo;
    },
    getReferringUser(fitKit) {
      return (
        fitKit &&
        fitKit.referral &&
        getUserFormatted(fitKit.referral.referringUser)
      );
    },
    getReferralDate(fitKit) {
      return (
        fitKit &&
        fitKit.referral &&
        getDateFromUnixFormatted(fitKit.referral.referralAt, "/")
      );
    },
    getReferralNotes(fitKit) {
      return fitKit && fitKit.referral && fitKit.referral.notes;
    },
  },
});
</script>

<style scoped lang="scss"></style>
