<template lang="pug">
SideOverlaySlot(
  :isOpen="isOpen",
  :isTour="isOpenTour",
  title="ADD NEW SCHEDULE",
  @closeOverlay="closeOverlay"
)
  template(#contentTop)
    form#add-new-schedule(
      v-if="!showScheduleAdded",
      @submit.prevent="addNewSchedule",
      novalidate
    ) 
      .form-group(:class="{ 'form-group__invalid': v$.scheduleDoctor.$errors.length }")
        label(for="user-type") Doctor's Name (Required)
        select#user-type(v-model="scheduleDoctor" @blur="v$.scheduleDoctor.$touch()")
          option(value="") Select
          option(
            v-for="doctor in activeDoctors", :key="doctor.id",
            :value="{id: doctor.id, first: doctor.first, last: doctor.last}"
          ) {{ getDoctorFormatted(doctor) }} 
        .form-group__errors(v-if="v$.scheduleDoctor.$dirty && v$.scheduleDoctor.$errors.length")
          .form-group__error(v-if="v$.scheduleDoctor.required.$invalid") Doctor is required!
      .columns
        .column.is-half
          .form-group(:class="{ 'form-group__invalid': v$.scheduleDate.$errors.length }")
            label(for="date") Date (Required)
            DatePicker(
              v-model="date" 
              :masks="masks"
            )
              template(v-slot="{ inputValue, inputEvents }")
                input(
                  :value="inputValue"  
                  v-on="inputEvents"
                  placeholder="MM/DD/YYYY"
                  @blur="v$.scheduleDate.$touch()"
                )
            .form-group__errors(v-if="v$.scheduleDate.$dirty && v$.scheduleDate.$errors.length")
              .form-group__error Date is required!

        .column.is-half
          TimeDropdown(
            isRequired=true 
            @timeResults="timeResults"
          )

      .columns
        .column.is-half
          .form-group(:class="{ 'form-group__invalid': v$.scheduleRecurring.$errors.length }")
            label(for="recurring") Recurring * (Required)
            #v-step-1
              select#recurring(v-model="scheduleRecurring" @blur="v$.scheduleRecurring.$touch()")
                option(value="") Select
                option(value="once") Once
                option(value="weekly") Weekly
                option(value="monthly") Monthly
              .form-group__errors(v-if="v$.scheduleRecurring.$dirty && v$.scheduleRecurring.$errors.length")
                .form-group__error(v-if="v$.scheduleRecurring.required.$invalid") Field is required!
        .column.is-half
          .form-group(:class="{ 'form-group__invalid': v$.scheduleInsurance.$errors.length }")
            label(for="insurance") Insurance (Required)
            #v-step-2
              select#insurance(v-model="scheduleInsurance" @blur="v$.scheduleInsurance.$touch()")
                option(value="") Select
                option(value="true") Insured
                option(value="false") Uninsured
              .form-group__errors(v-if="v$.scheduleInsurance.$dirty && v$.scheduleInsurance.$errors.length")
                .form-group__error(v-if="v$.scheduleInsurance.required.$invalid") Field is required!
      .columns
        .column.is-full.recurring-reminder
          p * Selecting the “Weekly” or “Monthly” recurring option will create 12 consecutive (weekly or monthly) appointment slots to the system.

    .overlay-show-success(v-else="")
      section.patient-section
        p <strong>Doctor’s Name: {{ getDoctorFormatted(scheduleDoctor) }}</strong>
        p <strong>Date: {{ scheduleDate }}</strong>
        p <strong>Time: {{ scheduleTime }}</strong>
        p <strong>Recurring: {{ scheduleRecurring.toUpperCase() }}</strong>
        p <strong>Insurance: {{ scheduleInsurance === "true" ? "INSURED" : "UNINSURED" }}</strong>
        
      p Schedule successfully added
  template(#footer)
    .flex.flex--space-between(v-if="!showScheduleAdded")
      BaseBtn.x-lg.red-outline(
        href="#",
        @click.prevent="closeOverlay"
      ) CANCEL
      BaseBtn.x-lg.green(
        type="submit",
        form="add-new-schedule"
        :disabled="v$.$invalid || isSubmitting"
        :class="{spinner: isSubmitting }"
      ) CONFIRM
    .flex.flex--flex-end(v-else="")
      BaseBtn.x-lg.blue(@click.prevent="resetAddNewSchedule") CREATE NEW SCHEDULE
PopupCardSlot(
  :isOpen="isOnboardingPopupOpen",
  maxWidth="674px"
)
  .onboarding-popup(v-show="onboardingPopupStep === 'initial'")
    h1 Hi {{getUserFirstName}}!
    .content
      p This section allows you to create doctor schedules.
      p <strong>Let us show you around in a few quick steps!</strong>
    .popup-btns
      BaseBtn.x-lg.grey-outline(@click="abortOnboarding") NOT RIGHT NOW
      BaseBtn.x-lg.green(@click.prevent="showOnboardingTour") YES, SHOW ME
  .onboarding-popup(v-show="onboardingPopupStep === 'close'")
    h1 How to access training
    .content
      p You can restart the training by clicking the light bulb icon on this screen.<br/><br/><br/>
    .popup-btns.popup-btn-center
      BaseBtn.x-lg.green(@click.prevent="closeOnboardingPopup") GOT IT, CLOSE
  .onboarding-popup(v-show="onboardingPopupStep === 'finished'")
    h1 Well done!
    .content
      p You can restart the training by clicking the light bulb icon on this screen.<br/><br/><br/>
    .popup-btns
      BaseBtn.x-lg.grey-outline(@click="showOnboardingTour") START OVER
      BaseBtn.x-lg.green(@click.prevent="closeOnboardingPopup") GOT IT, CLOSE
.onboarding-toggle(
  :class="{ 'onboarding-toggle--active': isOpen && !isOnboardingPopupOpen }",
  @click="redoOnBoarding"
)
  base-icon(name="lightbulb")
teleport(to="body")
  v-tour(
    name="onboardingTour",
    :steps="onboardingTourSteps",
    :options="{ highlight: true }"
    :callbacks="onboardingTourCallbacks"
  )
</template>

<script>
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import SideOverlaySlot from "@/components/overlays/SideOverlaySlot.vue";
import useVuelidate from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import { createToast } from "mosha-vue-toastify";
import { DatePicker } from "v-calendar";
import TimeDropdown from "@/components/TimeDropdown.vue";
import PopupCardSlot from "@/components/PopupCardSlot.vue";

import moment from "moment";

import {
  fetchDoctorsSpecialty,
  scheduleHospitalDoctor,
} from "@/api/hospitalApi";
import { fetchUserTourStatus, updateUserTourStatus } from "@/api/userApi";
import { apiStatus, apiStatusComputedFactory } from "@/api";
const { IDLE, PENDING, SUCCESS, ERROR } = apiStatus;

import { getDoctorFormatted } from "@/helpers";

export default defineComponent({
  setup() {
    return { v$: useVuelidate() };
  },
  watch: {
    date: {
      handler: "setDate",
    },
    isOpen: {
      handler: "showInitialOnboardingPopup",
      immediate: true,
    },
  },
  components: { SideOverlaySlot, DatePicker, TimeDropdown, PopupCardSlot },
  emits: [
    "closeAddScheduleOverlay",
    "fetchPatientAppointmentAvailableSchedule",
  ],

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      date: null,
      timeModel: new Date(),
      masks: {
        input: "MM/DD/YYYY",
      },
      showScheduleAdded: false,
      fetchDoctorsStatus: IDLE,
      scheduleHospitalDoctorStatus: IDLE,
      doctors: null,
      scheduleDoctor: "",
      scheduleTime: null,
      scheduleDate: null,
      scheduleRecurring: "",
      scheduleInsurance: "",
      isSubmitting: false,
      isOpenTour: false,
      userTourStatus: null,
      isOnboardingPopupOpen: false,
      onboardingPopupStep: null,
      isRedoOnBoarding: false,
      onboardingTourSteps: [
        {
          target: "#v-step-1",
          header: {
            title: "1 of 2",
          },
          content: `
          <strong>Choose whether this is a recurring appointment</strong> 
          that repeats weekly or monthly or is a one-time appointment. 
          Recurring will automatically create a set of 12 following appointments that can be individually managed, starting with the date selected.`,
        },
        {
          target: "#v-step-2",
          header: {
            title: "2 of 2",
          },
          content: `
            <strong>Choose whether the appointment is for insured or uninsured patients.</strong> 
            If it is for both, please select the uninsured option as insured schedules require insurance information."`,
        },
      ],
      onboardingTourCallbacks: {
        // onPreviousStep: this.onboardingTourPreviousStepCallback,
        // onNextStep: this.onboardingTourNextStepCallback,
        onSkip: this.onboardingSkipCallback,
        onFinish: this.onboardingFinishedCallback,
      },
    };
  },
  validations() {
    return {
      scheduleDoctor: { required },
      scheduleDate: {
        required,
        minLength: minLength(10),
        function() {
          let unixDistributed = new Date(this.scheduleDate).getTime();
          if (!unixDistributed) {
            return false;
          }

          return true;
        },
      },
      scheduleRecurring: { required },
      scheduleInsurance: { required },
    };
  },

  computed: {
    ...apiStatusComputedFactory([
      "fetchDoctorsStatus",
      "scheduleHospitalDoctorStatus",
    ]),
    ...mapGetters(["getUserFirstName", "getUserId"]),
    activeDoctors() {
      return this.doctors.filter((doctor) => !doctor.disabled);
    },
  },
  methods: {
    timeResults(val) {
      this.scheduleTime = val;
    },
    getDoctorFormatted,
    setDate(newVal) {
      if (newVal === null) {
        this.scheduleDate = "";
      } else {
        let selectionDisplay = moment(this.date).format("MM/DD/YYYY");
        this.scheduleDate = selectionDisplay;
      }
    },
    async scheduleHospitalDoctor() {
      this.isSubmitting = true;
      this.scheduleHospitalDoctorStatus = PENDING;
      try {
        const scheduleFinalDate =
          new Date(this.scheduleDate + " " + this.scheduleTime).getTime() /
          1000;
        const res = await scheduleHospitalDoctor(
          this.$route.params.idHospital,
          this.scheduleDoctor.id,
          this.$route.params.idSpecialty,
          {
            ...this.reqPagination,
            date: scheduleFinalDate,
            recurringType: this.scheduleRecurring,
            insuranceRequired: this.scheduleInsurance,
          }
        );
        this.$emit("fetchPatientAppointmentAvailableSchedule");
        this.showScheduleAdded = true;
        this.scheduleHospitalDoctorStatus = SUCCESS;
      } catch (err) {
        this.scheduleHospitalDoctorStatus = ERROR;
        createToast(err?.response?.data?.message || "There was a problem", {
          timeout: 4000,
          type: "danger",
          position: "bottom-right",
        });
        console.error(err);
      } finally {
        this.isSubmitting = false;
      }
    },

    async fetchDoctorsSpecialty() {
      this.fetchDoctorsSpecialtyStatus = PENDING;
      try {
        const res = await fetchDoctorsSpecialty(
          this.$route.params.idHospital,
          this.$route.params.idSpecialty
        );
        this.doctors = res.data.doctors;
        this.fetchDoctorsSpecialtyStatus = SUCCESS;
      } catch (err) {
        this.fetchDoctorsSpecialtyStatus = ERROR;
        console.error(err);
      }
    },
    closeOverlay() {
      this.showScheduleAdded = false;
      this.$emit("closeAddScheduleOverlay");
    },
    resetAddNewSchedule() {
      this.showScheduleAdded = false;
      this.clearForm();
    },
    clearForm() {
      this.scheduleDoctor = null;
      this.scheduleTime = null;
      this.scheduleDate = null;
      this.date = null;
      this.scheduleRecurring = null;
      this.scheduleInsurance = null;
      this.$nextTick(() => {
        this.v$.$reset();
      });
    },
    addNewSchedule() {
      this.scheduleHospitalDoctor();
    },
    closeOnboardingPopup() {
      this.isOnboardingPopupOpen = false;
      this.onboardingPopupStep = "initial";
      this.isOpenTour = false;
      this.isRedoOnBoarding = false;
    },
    abortOnboarding() {
      this.updateUserTourStatus();
      this.onboardingPopupStep = "close";
      this.isOpenTour = false;
      this.isRedoOnBoarding = false;
    },
    showInitialOnboardingPopup(newVal) {
      if (newVal === false) return;
      if (
        this.userTourStatus["hospital.createSchedule"] &&
        !this.isRedoOnBoarding
      ) {
        this.onboardingPopupStep = null;
      } else {
        this.onboardingPopupStep = "initial";
        this.isOnboardingPopupOpen = true;
      }
    },
    showOnboardingTour() {
      this.closeOnboardingPopup();
      this.$tours["onboardingTour"].start();
      this.isOpenTour = true;
    },
    redoOnBoarding() {
      this.isOnboardingPopupOpen = !this.isOnboardingPopupOpen;
      this.isRedoOnBoarding = true;
      this.onboardingPopupStep = "initial";
    },
    // onboardingTourPreviousStepCallback() {
    //   console.log("prev");
    // },
    // onboardingTourNextStepCallback() {
    //   if(this.$tours["onboardingTour"].isLast.value);
    //   console.log("next");
    // },
    onboardingSkipCallback() {
      this.isOpenTour = false;
      this.isRedoOnBoarding = false;
      this.onboardingPopupStep = "close";
      this.isOnboardingPopupOpen = true;
      this.updateUserTourStatus();
    },
    onboardingFinishedCallback() {
      this.isOpenTour = false;
      this.isRedoOnBoarding = false;
      this.onboardingPopupStep = "finished";
      this.isOnboardingPopupOpen = true;
      this.updateUserTourStatus();
    },
    async fetchUserTourStatus() {
      try {
        const res = await fetchUserTourStatus(this.getUserId);
        this.userTourStatus = res.data.tourStatus;
      } catch (err) {
        console.error(err);
      }
    },
    async updateUserTourStatus() {
      try {
        await updateUserTourStatus(this.getUserId, {
          routeName: "hospital.createSchedule",
          action: null,
        });
        this.userTourStatus["hospital.createSchedule"] = true;
      } catch (err) {
        console.error(err);
      }
    },
  },
  created() {
    this.fetchDoctorsSpecialty();
    this.fetchUserTourStatus();
  },
});
</script>
<style lang="scss">
@import "@/scss/plugins/vue3-tour.scss";
</style>
<style scoped lang="scss">
.recurring-reminder {
  font-size: 16px;
  line-height: normal;
}
.patient-section {
  margin-bottom: 0;
  padding: 37px 24px 25px;
  background-color: $primaryBgBlue;
  margin-bottom: 53px;
  margin-top: 26px;
  p {
    margin: 0 0 12px;
    font-size: 21px;
    font-weight: 500;
    line-height: 1.1;
    color: $primaryLightBlue;
    overflow: hidden;
    strong {
      font-weight: 700;
    }
  }
}
form#add-new-schedule {
  margin: 12px 0 20px;
  .columns {
    margin-bottom: 0px;
    .column {
      padding-bottom: 0px;
    }
  }
}
.overlay-show-success > p {
  max-width: 400px;
}

.onboarding-popup {
  max-width: 618px;
  margin: 0 auto;
  h1 {
    width: 100%;
    max-width: 549px;
    margin: 39px auto 30px;
    font-size: 28px;
    font-weight: 700;
    line-height: 1.21;
    letter-spacing: 0.14px;
    text-align: center;
  }
  .content {
    margin-bottom: 32px;
    font-size: 16px;
    line-height: 1.19;
    letter-spacing: 0.23px;
    text-align: center;
    p {
      margin: 0;
    }
  }
  .popup-btns {
    margin-bottom: 39px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    .btn {
      width: 260px;
    }
  }
  .popup-btn-center {
    display: flex;
    justify-content: center;
  }
}
</style>
