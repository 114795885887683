<template lang="pug">
TableTitle(
  title="Patient Follow-Up Due",
  searchPlaceholder="Search..."
  v-model="searchKeyword",
  @doSearch="fetchAnnualDueFitKits",
  @blur="fetchAnnualDueFitKits"
)
  template(#actions)
    select.table-action-selector.table-action-selector--follow-up(
      v-model="followUpReason"
      @change="filterByReason($event.target.value)"
    )
      option(value="all") FOLLOW-UP REASON
      option(value="annual_due") ANNUAL DUE
      option(value="lost") LOST
      option(value="throw_out") THROW OUT
      option(value="not_returned") NOT RETURNED
.table
  .th
    .td(@click.prevent="changeListOrder('patientName', fetchAnnualDueFitKits)" style="cursor: pointer;") Patient Name
      i(style="font-weight: bolder;") {{ patientNameColumnSortingIcon }}
    .td(@click.prevent="changeListOrder('processedAt', fetchAnnualDueFitKits)" style="cursor: pointer;") DATE OF PROCESSING
      i(style="font-weight: bolder;") {{ processedAtColumnSortingIcon }}
    .td FOLLOW-UP REASON
    .td
    .td
    .td
  .tr(v-for="fitKit in annualDueFitKits" :key="fitKit.id")
    .td.bold {{`${fitKit.patient.first} ${fitKit.patient.last}`}} 
    .td {{getDateFromUnixFormatted(fitKit.processedAt)}}
    .td 
      div {{getFollowUpReason(fitKit)}}
      .second-row-md-grey {{getMonthsAgo(fitKit.processedAt)}}
    .td
      BaseBtn.sm.blue-outline(
           href="#",
          @click.prevent="openClinicPatientDetailsOverlay(fitKit.patient)"
        ) PATIENT DETAILS
    .td
      BaseBtn.sm.blue-outline(
        href="#",
        @click.prevent="openCompletedFitOverlay(getFitKitCompletion(fitKit))"
      ) FIT DETAILS
    .td
      BaseBtn.sm.blue(:to="{name: 'new-fit-kit.search-patient', params: {firstName: fitKit.patient.first, lastName: fitKit.patient.last} }") ADD NEW FIT
Pagination(
  v-if="pagination && annualDueFitKits != null && annualDueFitKits.length",
  @changePagination="changePagination",
  :pagination="pagination",
  :reloadFn="fetchAnnualDueFitKits"
)
.no-results(v-if="annualDueFitKits != null && !annualDueFitKits.length") 
  base-icon(name="exclamation") 
  span No matching records

CompletedFitOverlay(
  :isOpen="isCompletedFitOverlayOpen",
  :selectedFitKit="selectedFitKit",
  @closeCompletedFitOverlay="closeCompletedFitOverlay",
  @openCompleteFitOverlay="openCompleteFitOverlay"
)
CompleteFitOverlay(
  :isOpen="isCompleteFitOverlayOpen",
  :isEdit="true",
  :selectedFitKit="selectedFitKit",
  @closeCompleteFitOverlay="closeCompleteFitOverlay",
)
ClinicPatientDetailsOverlay(
  :key="`${componentKey}-1`"
  :showPatient="showPatient",
  :isOpen="isClinicPatientDetailsOverlay",
  @closeClinicPatientDetalisOverlay="closeClinicPatientDetailsOverlay"
)
</template>

<script>
import { defineComponent } from "vue";
import TableTitle from "@/components/TableTitle.vue";
import Pagination from "@/components/Pagination.vue";
import CompletedFitOverlay from "@/components/fit-kit/overlays/CompletedFitOverlay.vue";
import CompleteFitOverlay from "@/components/fit-kit/overlays/CompleteFitOverlay.vue";
import ClinicPatientDetailsOverlay from "@/components/clinics/patient-search/overlays/PatientDetalisOverlay.vue";
import { fetchFitKits } from "@/api/fitKitApi";
import {
  reformatPagination,
  getMonthsAgo,
  checkEditable,
  getDoctorFormatted,
  getDateFromUnixFormatted,
} from "@/helpers";
import listPagesMixin from "@/mixins/listPagesMixin";
import sortFitKits from "@/mixins/sortFitKits";

export default defineComponent({
  components: {
    TableTitle,
    Pagination,
    CompletedFitOverlay,
    CompleteFitOverlay,
    ClinicPatientDetailsOverlay,
  },

  mixins: [listPagesMixin, sortFitKits],

  data() {
    return {
      annualDueFitKits: null,
      selectedFitKit: null,
      isCompletedFitOverlayOpen: false,
      isCompleteFitOverlayOpen: false,
      followUpReason: "all",
      searchKeyword: "",
      showPatient: null,
      isClinicPatientDetailsOverlay: false,
    };
  },

  methods: {
    openClinicPatientDetailsOverlay(patient) {
      this.componentKey = this.componentKey + 1;
      this.showPatient = patient;
      this.isClinicPatientDetailsOverlay = true;
    },
    closeClinicPatientDetailsOverlay() {
      this.isClinicPatientDetailsOverlay = false;
    },
    filterByReason(reason) {
      this.followUpReason = reason;
      this.fetchAnnualDueFitKits();
    },
    openCompletedFitOverlay(selectedFitKit) {
      this.selectedFitKit = selectedFitKit;
      this.isCompletedFitOverlayOpen = true;
    },
    closeCompletedFitOverlay() {
      this.isCompletedFitOverlayOpen = false;
    },
    openCompleteFitOverlay(fitKit) {
      this.selectedFitKit = fitKit;
      this.isCompleteFitOverlayOpen = true;
    },
    closeCompleteFitOverlay({ afterAction }) {
      this.isCompleteFitOverlayOpen = false;
      if (afterAction) {
        this.$router.go(0);
      }
    },
    getFollowUpReason(fitKit) {
      if (fitKit.result === "no_result" && fitKit.details) {
        return fitKit.details
          .split("_")
          .map((x) => x.charAt(0).toUpperCase() + x.slice(1))
          .join(" ");
      } else {
        return "Annual Due";
      }
    },
    checkEditable: checkEditable,
    getDoctorFormatted: getDoctorFormatted,
    getMonthsAgo: getMonthsAgo,
    getDateFromUnixFormatted: getDateFromUnixFormatted,
    getFitKitCompletion(fitKit) {
      const {
        id,
        idClinic,
        patient,
        kitNumber,
        providerUser,
        result,
        details,
        fluVaccine,
        covidVaccine,
        location,
        insurance,
        createdAt,
        updatedAt,
        processedAt,
        completionUser,
        completionNotes,
        referral,
        colonoscopyInsideICC,
        colonoscopyOutsideICC,
      } = fitKit;
      const isEditable = this.checkEditable(fitKit);
      const initialCompletion = {
        result,
        details,
        fluVaccine,
        covidVaccine,
        location,
        insurance,
        createdAt,
        processedAt,
        completionUser,
        completionNotes,
        isEditable,
      };
      return {
        id,
        idClinic,
        updatedAt,
        patient,
        kitNumber,
        providerUser,
        initialCompletion,
        referral,
        colonoscopyInsideICC,
        colonoscopyOutsideICC,
      };
    },
    async fetchAnnualDueFitKits() {
      const order = this.listOrder
        ? {
            by: this.listOrder.by,
            direction: this.listOrder.direction,
          }
        : null;
      const params = {
        order,
        ...this.reqPagination,
        idClinic: this.$route.params.idClinic,
        include: [
          "patient",
          "providerUser",
          "hasColonoscopyOrReferral",
          "completionUser",
        ],
        pickOnly: "oneYearFollowUp",
        status: "completed",
        searchKeyword: this.searchKeyword,
        followUpReason: this.followUpReason,
      };
      if (this.date === null) delete params.processedAt;
      if (!!this.searchKeyword !== true) delete params.searchKeyword;
      if (order === null) delete params.order;
      if (this.followUpReason === "all") delete params.followUpReason;
      try {
        const resFitKit = await fetchFitKits({
          params: {
            ...params,
          },
        });
        this.annualDueFitKits = resFitKit.data.fitKits;
        this.pagination = reformatPagination(resFitKit.data.pagination);
      } catch (err) {
        console.error(err);
      }
    },
  },
  created() {
    if (this.$route.params.idClinic === null) return;
    this.fetchAnnualDueFitKits();
  },
});
</script>

<style scoped lang="scss">
.tr {
  cursor: pointer;
}
.td {
  &:nth-child(1) {
    width: math.percentage(math.div(258, $tabContentCardWidth));
  }
  &:nth-child(2) {
    width: math.percentage(math.div(200, $tabContentCardWidth));
  }
  &:nth-child(3) {
    width: math.percentage(math.div(205, $tabContentCardWidth));
  }
  &:nth-child(4) {
    width: math.percentage(math.div(175, $tabContentCardWidth));
  }
  &:nth-child(5) {
    width: math.percentage(math.div(175, $tabContentCardWidth));
  }
  &:nth-child(6) {
    width: math.percentage(math.div(175, $tabContentCardWidth));
  }
  .btn {
    display: block;
    min-width: 0;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}
</style>
