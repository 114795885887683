<template lang="pug">
.tabs-card(:class="`tabs-card--${variant}`")
  .tabs
    slot(name="tabs")
  .tab-content.card
    slot(name="content")
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  components: {},

  props: {
    variant: {
      type: String,
      default: "lg",
    },
  },

  data() {
    return {};
  },

  computed: {},

  methods: {},
});
</script>

<style lang="scss">
.tabs-card {
  position: relative;
  width: 100%;
  box-shadow: none;
  .tabs {
    margin-bottom: -1px;
    display: flex;
    justify-items: space-between;
  }
  .tab {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    margin-right: 9px;
    background-color: $primaryBgDarkGrey;
    color: #000;
    text-decoration: none;
    border-left: solid 1px $primaryBgDarkGrey;
    border-right: solid 1px $primaryBgDarkGrey;
    transition: all 0.3s ease-in-out;
    &:first-child {
      border-radius: 30px 0 0 0;
    }
    &:last-child {
      border-radius: 0 30px 0 0;
      margin-right: 0;
    }
    &:hover {
      background-color: #9b9b9b;
    }
    &.router-link-active {
      position: relative;
      z-index: 2;
      background-color: #fff;
      .tab__first-child {
        font-weight: 700;
        color: #000;
        &::after {
          background-color: $primaryBgDarkGrey;
        }
      }
      .tab__second-child {
        color: #000;
        font-weight: 700;
      }
    }
  }
  .tab-content {
    position: relative;
    width: 100%;
    padding-bottom: 38px;
    border-radius: 0 0 30px 30px;
    border: solid 1px $primaryBgDarkGrey;
    filter: drop-shadow(1px 5px 20px rgba(0, 0, 0, 0.16));
  }
}
.tabs-card--lg {
  .tab {
    height: $tabLgHeight;
    padding-left: 45px;
    padding-right: 45px;
    &.router-link-active {
      height: $tabLgHeight + $tabLgBorderTopHeight;
      border-top: $tabLgBorderTopHeight solid $secondaryYellow;
      margin-top: -$tabLgBorderTopHeight;
    }
    &__inner {
      width: 100%;
    }
  }
  .tab__first-child {
    font-size: 48px;
    font-weight: 300;
    line-height: 1.21;
    letter-spacing: 0.27px;
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 2px;
      background-color: #fff;
      margin-bottom: 5px;
      margin-top: 1px;
    }
  }
  .tab__second-child {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.29;
    letter-spacing: 0.08px;
  }
}
.tabs-card--sm {
  .tab {
    height: $tabSmHeight;
    margin-right: 8px;
    padding-left: 24px;
    padding-right: 16px;
    &:first-child {
      padding-left: 29px;
    }
    &:last-child {
      margin-right: 0;
    }
    &.router-link-active {
      height: $tabSmHeight + $tabSmBorderTopHeight;
      border-top: $tabSmBorderTopHeight solid $secondaryYellow;
      margin-top: -$tabSmBorderTopHeight;
    }
  }
  .tab__inner {
    position: relative;
    top: 1px;
    display: flex;
    align-items: center;
  }
  .tab__first-child {
    margin-right: 14px;
    font-size: 48px;
    font-weight: 300;
    line-height: 1.21;
    letter-spacing: 0.27px;
    color: #343434;
  }
  .tab__second-child {
    font-size: 24px;
    font-weight: 300;
    line-height: 1.21;
    letter-spacing: 0.12px;
    color: #343434;
  }
}
</style>
