<template lang="pug">
FullScreenOverlaySlot(
  :isOpen="isOpen",
  headerIcon="hep-b",
  title="Appointment Record"
  @closeOverlay="closeOverlay"
)
  template(#breadcrumbs)
    h1.breadcrumbs-title Illinois Hep B Cares > HEPATITIS B
  template(#actionSection)
    BaseBtn.x-lg.blue-outline(
      href="#",
      @click.prevent="openAppointmentNotesOverlay",
      :count="2"
    ) NOTES (265)
  template(#content)
    .card(style="padding-top: 52px;")
      .patient-info.columns.is-variable
        .column.is-one-third
          .columns.is-variable
            .info-pair.column.is-one-half
              .label Scheduled Date
              .txt.txt--blue 9/27/17
            .info-pair.column.is-one-half
              .label Scheduled Time
              .txt.txt--blue 12:00PM
        .column.is-one-third.rescheduled
          .info-pair
            .label
              span Rescheduled
                base-icon(name="yes")
        .column.is-one-third
          .flex.flex--space-between
            BaseBtn.sm.black-outline(href="#") RESCHEDULE
            BaseBtn.sm.red-outline(href="#") CANCEL

      .hr.hr--thin
      .columns.is-variable
        .info-pair.column.is-one-third
          .label First Name
          .txt.txt--blue Johnny
        .info-pair.column.is-one-third
          .label Last Name
          .txt.txt--blue Doelcic
        .info-pair.column.is-one-third
          .label Date of Birth
          .txt.txt--blue 9/27/17
      .hr.hr--thin
      .info-pair.columns.is-variable
        .column.is-two-thirds
          .label Patient Address
          .txt 55555 Northern Oak Drive Chicago <br>Illinois 55555 <br>USA
        .column.is-one-third
          .info-pair
            .label Patient Phone Number
            .txt 555-555-5555
          .info-pair
            .label Ext.
            .txt 000
      .hr.hr.hr--thin
      .columns.is-variable
        .info-pair.column.is-one-third
          .label Hospital Name
          .txt Cook Country Memorial 
        .info-pair.column.is-one-third
          .label Doctor Name
          .txt Doctor Paul Blakely
        .column.is-one-third.flex.flex--flex-start
          .info-pair
            .label Hospital Phone Number
            .txt 555-555-5555
          .info-pair
            .label Ext.
            .txt 000
    .card.card--blue-bg
      h2.title.title--md-thin PATIENT RECORD INFORMATION
      .patient-record-info.columns.is-variable
        .info-pair.column.is-one-third
          .label Referring Physician
          .txt Doctor John Doe
        .info-pair.column.is-one-third
          .label Clinic MRN
          .txt 123456789012353401
        .column.is-one-third
          .form-group
            label.label--margin-sm Hospital MRN
            input.text-lg(type="text")
    .card
      h2.title.title--md-thin Emergency Contact Information
      .emergency-contact-info.columns
        .info-pair.column.is-two-thirds
          .label
          .txt Ariette Doelcic Xandureux
        .column.is-one-third.flex.flex--flex-start
          .info-pair
            .label Phone Number
            .txt 555-555-5555
          .info-pair
            .label Ext.
            .txt 000
    .card.card--blue-bg
      .columns.is-variable
        .column.insurance-status.is-one-third.flex.flex--space-between
          p Does patient have insurance?
          base-icon(name="yes")
      .hr.hr.hr--thin
      h2.title.title--md-thin Insurance Information
      .insurance-info.columns.is-variable
        .info-pair.column.is-one-third
          .label Insurance Name
          .txt xxx-xxx-xxx
        .info-pair.column.is-one-third
          .label Policy Number
          .txt xxx-xxx-xxx
      .columns.is-variable
        .info-pair.column.is-one-third
          .label Expiration Date
          .txt 9/27/17
        .info-pair.column.is-one-third
          .label Group Number
          .txt xxx-xxx-xxx
        .info-pair.column.is-one-third
          .label Upload Insurance Card
          .flex.flex--flex-start
            base-icon(name="eye-red", style="margin-right: 13px;")
            BaseBtn.lg.blue(href="#") UPLOAD
    .card
      .columns.is-variable
        .column.is-2
          .info-pair
            .label GI Referral Info
        .column.is-2
          .info-pair
            .label.label--margin-md Clinic Report
            base-icon(name="eye-green")
        .column.is-4
          .form-group
            label Procedure Completed
            input.text-lg(type="text")
        .column.is-4
          .info-pair
            .label.label--margin-md
              | GI Report
              base-icon(
                name="paperclip",
                style="font-size: 16px; top:-2px; margin-left: 5px; opacity: 0.6;"
              )
            .flex.flex--flex-start
              base-icon(name="eye-red", style="margin-right: 13px;")
              BaseBtn.lg.blue(href="#") REUPLOAD
      .columns.is-variable
        .column.is-2
          .form-group
            label Polyps Found
            select(@click.prevent="hidePolypsFoundSelectLabel = true")
              option(
                value="",
                disabled,
                selected,
                :invalid="hidePolypsFoundSelectLabel" Select
              ) Select
              option option 1
              option option 2
              option option 3
        .column.is-2
          .form-group
            label Polyps Removed
            select(@click.prevent="hidePolypsRemovedSelectLabel = true")
              option(
                value="",
                disabled,
                selected,
                :invalid="hidePolypsRemovedSelectLabel" Select
              ) Select
              option option 1
              option option 2
              option option 3
        .column.is-4
          .form-group
            label Pathology
            select
              option No
              option Yes
              option Yes
              option Yes
              option Yes
              option Yes
              option Yes
              option Yes
              option Yes
              option Yes
              option Yes
              option Yes
              option Yes
              option Yes
              option Yes
        .column.is-4
          .info-pair
            .label.label--margin-md Pathology Report
            BaseBtn.lg.blue(href="#") REUPLOAD
      
      .columns.is-variable
        .column.is-4
          .form-group(style="margin-bottom: 28px;")
            label Next Colonoscopy (Required)
            select
              option Other
          .form-group
            input(type="text", placeholder="MM/DD/YYYY")
        .column.is-4
          .form-group
            label Clinic Final Notes
            textarea(style="height: 250px;")
        .column.is-4
          .form-group
            label Hospital Notes (Required)
            textarea(style="height: 250px")

  template(#footer)
    BaseBtn.btn.lg.green-outline(href="#") Save &amp; Return to Dashboard
    BaseBtn.btn.lg.green(href="#") Complete this Appointment

</template>

<script>
import { defineComponent } from "vue";
import FullScreenOverlaySlot from "@/components/overlays/FullScreenOverlaySlot.vue";

export default defineComponent({
  components: { FullScreenOverlaySlot },
  emits: ["closeAppointmentRecordOverlay", "openAppointmentNotesOverlay"],

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      hidePolypsFoundSelectLabel: false,
      hidePolypsRemovedSelectLabel: false,
    };
  },

  methods: {
    closeOverlay() {
      this.$emit("closeAppointmentRecordOverlay");
    },
    openAppointmentNotesOverlay() {
      this.$emit("openAppointmentNotesOverlay");
    },
  },
});
</script>

<style scoped lang="scss">
.card {
  padding: 35px $secondaryCardsSidePadding 68px;
}
.columns.is-variable {
  --columnGap: 1.575%;
}
.columns + .hr {
  background-color: $primaryBgDarkGrey;
  margin-top: -21px;
  margin-bottom: 24px;
}
.card > .columns:last-child.info-pair,
.card > .columns:last-child .info-pair {
  margin-bottom: 0;
}
.svg-icon--eye-red,
.svg-icon--eye-green,
.svg-icon--yes,
.svg-icon--no {
  width: 50px;
  height: 50px;
  top: 0;
}

// Page specific styles----------------------------------------
.insurance-status {
  margin-bottom: 21px;
  p {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.29;
    letter-spacing: 0.07px;
    color: #000;
  }
}
.rescheduled {
  .label {
    & > span {
      position: relative;
    }
    .svg-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: calc(100% + 20px);
    }
  }
}
.breadcrumbs-title {
  margin: 0;
  color: $primaryLightBlue;
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.12px;
}
</style>
