<template lang="pug">
.notes-added
  p Follow-up notes added!
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({});
</script>

<style scoped lang="scss">
.notes-added {
  margin-top: -38px;
  p {
    font-size: 48px;
    font-weight: 300;
    line-height: 1.21;
  }
}
</style>
