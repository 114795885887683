<template lang="pug">
.content-wrapper
  .card.router-view-card
    section.users
      TableTitle(title="Users")
        template(#actions)
          BaseBtn.x-lg.blue(@click.prevent="openManageUsersSuperAdminOverlay('ADD NEW USER')") ADD NEW
      .table
        .th
          .td(@click.prevent="changeListOrder('name', fetchUser)" style="cursor: pointer;") USER NAME
            i(style="font-weight: bolder;") {{ nameColumnSortingIcon }}
          .td(@click.prevent="changeListOrder('role', fetchUser)" style="cursor: pointer;") ROLE
            i(style="font-weight: bolder;") {{ roleColumnSortingIcon }}
          .td ACTIVE
          .td
        .tr(v-for="user in users", :key="user.id")
          .td.bold {{ user.first}} {{ user.last }}
          .td.bold {{ user.role }}
          .td
            BaseToggle(label="" @update:modelValue="toggleUserAccess(user)" :modelValue="!user.disabled")
          .td
            BaseBtn.sm.blue(
               href="#",
              @click.prevent="openManageUsersSuperAdminOverlay('MANAGE USER', user)"
            ) MANAGE
      Pagination(
        v-if="pagination",
        @changePagination="changePagination",
        :pagination="pagination",
        :reloadFn="fetchUser"
      )
      .actions-below-table-container
      ManageUsersSuperAdminOverlay(
        :isOpen="isManageUsersSuperAdminOverlayOpen",
        :overlayTitle="overlayTitle",
        :showAdmin="showAdmin",
        :key="showAdmin"
        @closeManageUsersSuperAdminOverlay="closeManageUsersSuperAdminOverlay"
      )
</template>

<script>
import { defineComponent } from "vue";
import TableTitle from "@/components/TableTitle.vue";
import ManageUsersSuperAdminOverlay from "@/components/users/overlays/ManageUsersSuperAdminOverlay.vue";
import Pagination from "@/components/Pagination.vue";
import listPagesMixin from "@/mixins/listPagesMixin";
import sortUsers from "@/mixins/sortUsers";

import { deleteUser, fetchUser, patchUser } from "@/api/userApi";
import { apiStatus, apiStatusComputedFactory } from "@/api";
import { userRoles } from "@/store/account";
const { IDLE, PENDING, SUCCESS, ERROR } = apiStatus;

export default defineComponent({
  components: {
    TableTitle,
    ManageUsersSuperAdminOverlay,
    Pagination,
  },
  mixins: [listPagesMixin, sortUsers],

  data() {
    return {
      fetchUserStatus: IDLE,
      patchUserStatus: IDLE,
      isManageUsersSuperAdminOverlayOpen: false,
      overlayTitle: "MANAGE USER",
      users: [],
      showAdmin: null,
    };
  },

  computed: {
    ...apiStatusComputedFactory(["fetchUserStatus", "patchUserStatus"]),
  },

  methods: {
    openManageUsersSuperAdminOverlay(overlayTitle, user) {
      this.showAdmin = user || {
        first: "",
        last: "",
        email: "",
        details: "",
      };
      this.isManageUsersSuperAdminOverlayOpen = true;
      this.overlayTitle = overlayTitle;
    },
    closeManageUsersSuperAdminOverlay() {
      this.showAdmin = null;
      this.isManageUsersSuperAdminOverlayOpen = false;
    },
    async fetchUser() {
      this.fetchUserStatus = PENDING;
      const order = this.listOrder
        ? {
            by: this.listOrder.by,
            direction: this.listOrder.direction,
          }
        : undefined;
      try {
        const res = await fetchUser({
          params: {
            order,
            ...this.reqPagination,
          },
        });

        this.users = res.data.users.map((user) => {
          user.role = userRoles.all.find(
            (role) => role.id === user.idUserRole
          )?.name;

          if (!user.role) {
            user.role = user.idUserRole;
          }

          return user;
        });
        this.pagination = res.data.pagination;
        this.fetchUserStatus = SUCCESS;
      } catch (err) {
        this.fetchUserStatus = ERROR;
        console.error(err);
      }
    },
    async toggleUserAccess(user) {
      this.patchUserStatus = PENDING;
      try {
        if (user.disabled) {
          await patchUser(user.id);
        } else {
          await deleteUser(user.id);
        }
        this.patchUserStatus = SUCCESS;
      } catch (err) {
        this.patchUserStatus = ERROR;
        console.error(err);
      }

      this.fetchUser();
    },
  },

  created() {
    this.fetchUser();
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/components/step-subtitle.scss";
.users {
  .td {
    &:nth-child(1) {
      width: math.percentage(math.div(402, $tabContentCardWidth));
    }
    &:nth-child(2) {
      width: math.percentage(math.div(344, $tabContentCardWidth));
    }
    &:nth-child(3) {
      width: math.percentage(math.div(200, $tabContentCardWidth));
    }
    &:nth-child(4) {
      width: math.percentage(math.div(234, $tabContentCardWidth));
    }
  }
}
.content-wrapper {
  position: relative;
  padding-bottom: 40px;
}
.router-view-card {
  padding-top: 0;
  position: relative;
  width: 100%;
  z-index: 1;
}
.table .tr > .td {
  display: flex;
  align-items: center;
}
.table .td .btn {
  width: 100%;
}
</style>
