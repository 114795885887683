// @ts-nocheck
import { fetchNotificationNewCount } from "@/api/notifications.ts";

export default {
  state() {
    return {
      notificationCount: 0,
    };
  },
  getters: {
    getNotificationCount(state) {
      return state.notificationCount;
    },
  },
  mutations: {
    COMMIT_NOTIFICATION_COUNT(state, payload) {
      state.notificationCount = payload;
    },
  },
  actions: {
    async fetchNotificationCount({ commit, getters }) {
      let id, destination;

      if (getters.aClinicIsSelected) {
        id = getters.getClinicId;
        destination = "Clinic";
      }

      if (getters.aHospitalIsSelected) {
        id = getters.getHospitalId;
        destination = "Hospital";
      }

      if (getters.aCCOIsSelected) {
        id = getters.getCCOId;
        destination = "Community";
      }

      if (getters.isProvider) {
        id = getters.getUserId;

        if (getters.getUserRoleContext.impersonateProviderID) {
          id = getters.getUserRoleContext.impersonateProviderID;
        }
        destination = "Provider";
      }

      if (id && destination) {
        try {
          const {
            data: { newNotificationsCount },
          } = await fetchNotificationNewCount({
            params: {
              idDestination: id,
              destination: destination,
            },
          });

          commit("COMMIT_NOTIFICATION_COUNT", newNotificationsCount.count);
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
};
