<template lang="pug">
.view-appointment-status.content-wrapper
  BasePageTitle(
    pageTitleIcon="colon",
    title="Illinois Colon Cares: Colonoscopy"
  )
    template(#breadcrumbs)
      li.active Appointment Status
      li Ongoing Care
      li Schedules
      li Schedule Patient
    template(#actionSection)
      BaseBtn.lg.blue(:to="{name: 'home'}") Schedule patient
      .annotation 24 Slots Available
  TabsCard(variant="lg")
    template(#tabs)
      router-link.tab(:to="{name: 'appointment-status.requested'}")
        .tab__inner
          .tab__first-child 5
          .tab__second-child Requested
      router-link.tab(:to="{name: 'appointment-status.confirmed'}")
        .tab__inner
          .tab__first-child 11
          .tab__second-child Confirmed
      .tab
        .tab__inner
          .tab__first-child 6
          .tab__second-child Pending Clinic Review
      .tab
        .tab__inner
          .tab__first-child 213
          .tab__second-child Archived
    template(#content)
      router-view
</template>

<script>
import { defineComponent } from "vue";
import TabsCard from "@/components/TabsCard.vue";

export default defineComponent({
  components: { TabsCard },

  data() {
    return {};
  },
});
</script>

<style scoped lang="scss"></style>
