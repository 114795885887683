<template lang="pug">
h1.title.title--md Create your password
form(v-if="!isSuccess", @submit.prevent="register", novalidate)
  .form-group(:class="{ 'form-group__invalid': v$.newPassword.$errors.length }")
    label(for="new-password") New Password
    input#new-password(
      placeholder="Minimum 8 characters"
      v-model="newPassword",
      type="password",
      @blur="v$.newPassword.$touch()"
    )
    .form-group__errors(v-if="v$.newPassword.$errors.length")
      .form-group__error(v-if="v$.newPassword.required.$invalid") Password is required
      .form-group__error(v-if="v$.newPassword.minLengthValue.$invalid") Password should have 8 or more characters
      .form-group__error(v-if="v$.newPassword.alphaNumeric.$invalid") Password must contain at least 1 Uppercase and 1 Lowercase Alphabet and 1 Number!

  .form-group(:class="{ 'form-group__invalid': v$.confirmPassword.$errors.length }")
    label(for="confirm-password") Password
    input#confirm-password(
      v-model="confirmPassword",
      type="password",
      @blur="v$.confirmPassword.$touch()"
    )
    .form-group__errors(v-if="v$.confirmPassword.$errors.length")
      .form-group__error(v-if="v$.confirmPassword.required.$invalid") Password is required
      .form-group__error(v-if="v$.confirmPassword.sameAsPassword.$invalid") Passwords do not match
  .form-actions
    BaseBtn.x-lg.blue(
      type="submit",
      :disabled="v$.$invalid || isSubmitting"
      :class="{spinner: isSubmitting}"
    ) CREATE PASSWORD
  .form-error-main(v-show="formErr") {{formErr}}
.success(v-else)
  p Password successfully updated.
  BaseBtn.x-lg.green(:to="{name: 'log-in-page'}") RETURN TO LOGIN
  
</template>

<script>
import { defineComponent } from "vue";
import useVuelidate from "@vuelidate/core";
import { required, minLength, sameAs } from "@vuelidate/validators";
import { updateForgetPassword } from "@/api/accountApi";
import { mapGetters } from "vuex";

const alphaNumeric = (value) =>
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/.test(value);

export default defineComponent({
  components: {},

  setup() {
    return { v$: useVuelidate() };
  },

  data() {
    return {
      isSuccess: false,
      newPassword: "",
      confirmPassword: "",
      isSubmitting: false,
      formErr: null,
    };
  },

  validations() {
    return {
      newPassword: {
        required,
        minLengthValue: minLength(8),
        alphaNumeric,
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs(this.newPassword),
      },
    };
  },

  computed: {
    ...mapGetters(["isHospitalManager", "isClinicHealthSystemManager"]),
  },

  methods: {
    async register() {
      this.isSubmitting = true;
      try {
        const { data } = await updateForgetPassword(
          this.$route.params.code,
          this.newPassword,
          this.confirmPassword
        );

        if (data.success) {
          if (localStorage.getItem("userSigninCount") === "0") {
            localStorage.setItem("userSigninCount", 1);
          }
        }

        this.isSuccess = true;
      } catch (err) {
        this.formErr =
          err?.response?.data?.message ||
          "Could not register, please try again.";
      } finally {
        this.isSubmitting = false;
        this.clearForm();
      }
    },

    clearForm() {
      this.newPassword = "";
      this.confirmPassword = "";
      this.$nextTick(() => {
        this.v$.$reset();
      });
    },
  },
});
</script>

<style scoped lang="scss">
.title--md {
  text-align: center;
  margin-top: -16px;
  margin-bottom: 23px;
}
.form-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.form-error-main {
  margin-top: 35px;
}
.success {
  text-align: center;
  .btn {
    margin-top: 79px;
  }
}
</style>
