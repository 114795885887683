<template lang="pug">
h1.title.title--md Forgot your password?
form(v-if="!isSuccess", @submit.prevent="resetPassword", novalidate)
  p(style="text-align: center; margin-bottom: 40px;") Enter your email address and<br> we will send you an email to reset your password
  .form-group(:class="{ 'form-group__invalid': v$.email.$errors.length }")
    label(for="email") Email Address
    input#email(
      v-model="email",
      type="email",
      @blur="v$.email.$touch()"
    )
    .form-group__errors(v-if="v$.email.$errors.length")
      .form-group__error(v-if="v$.email.required.$invalid") Email address is required
      .form-group__error(v-if="v$.email.email.$invalid") Invalid email address format

  .form-actions
    BaseBtn.x-lg.blue(
      type="submit",
      :disabled="v$.$invalid || isSubmitting"
      :class="{spinner: isSubmitting}"
    ) RESET PASSWORD
  .form-error-main(v-show="formErr") {{formErr}}
.success(v-else)
  p We have sent you an email to reset your password, 
  BaseBtn.x-lg.green(:to="{name: 'log-in-page'}") RETURN TO LOGIN
  
</template>

<script>
import { defineComponent } from "vue";
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { requestForgetPassword } from "@/api/accountApi";

export default defineComponent({
  components: {},

  setup() {
    return { v$: useVuelidate() };
  },

  data() {
    return {
      isSuccess: false,
      email: "",
      isSubmitting: false,
      formErr: null,
    };
  },

  validations() {
    return {
      email: {
        required,
        email,
      },
    };
  },

  methods: {
    async resetPassword() {
      this.isSubmitting = true;
      try {
        const {
          data: { success },
        } = await requestForgetPassword(this.email);
        this.isSuccess = success;
      } catch (err) {
        this.formErr = err?.response?.data?.includes("Email address not found")
          ? "Email address not found"
          : "Something went wrong, please try again.";
      } finally {
        this.isSubmitting = false;
        this.clearForm();
      }
    },

    clearForm() {
      this.email = "";
      this.$nextTick(() => {
        this.v$.$reset();
      });
    },
  },
});
</script>

<style scoped lang="scss">
.title--md {
  text-align: center;
  margin-top: -16px;
  margin-bottom: 23px;
}
.form-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.form-error-main {
  margin-top: 35px;
}
.success {
  text-align: center;
  .btn {
    margin-top: 79px;
  }
}
</style>
