<template lang="pug">
SideOverlaySlot(
  :isOpen="isOpen",
  :title="overlayTitle",
  @closeOverlay="closeOverlay"
)
  template(#contentTop)
    form#manage-user(
      @submit.prevent="saveAndCloseOverlay",
      novalidate
    )  
      .form-group(:class="{ 'form-group__invalid': v$.showDoctor.idUserRole.$errors.length }")
        label(for="user-type") User Type (Required)
        select#user-type(v-model="showDoctor.idUserRole" @change="onChange($event)" :disabled='overlayTitle == "ADD NEW USER" ? false : true' @blur="v$.showDoctor.idUserRole.$touch()")
          option(value=0) Select
          option(value=6) Hospital Manager 
          option(value=7) Hospital Staff
          option(value=8) Hospital Doctor
        .form-group__errors(v-if="v$.showDoctor.idUserRole.$dirty && v$.showDoctor.idUserRole.$errors.length")
          .form-group__error User role is required!
      .form-group(v-if="showSpecialties" :class="{ 'form-group__invalid': v$.finalSpecialtiesValues.$errors.length }")
        label(for="specialties") Specialties (Required)
        Multiselect#specialties( v-model="multiSelect.value" v-bind="multiSelect" placeholder="Select")   
        .form-group__errors(v-if="v$.finalSpecialtiesValues.$dirty && v$.finalSpecialtiesValues.$errors.length")
          .form-group__error(v-if="v$.finalSpecialtiesValues.required.$invalid") Specialtiy is required!

      .form-group(v-if="showDefaultSpecialtyView" :class="{ 'form-group__invalid': v$.activeSpecialtiesDropdown.$errors.length }")
        label(for="default-specialty") Default Specialty View (Required)
        select#default-specialty(v-model="activeSpecialtiesDropdown" @blur="v$.activeSpecialtiesDropdown.$touch()")
          option(value="") Select
          option(
            v-for="specialtyOption in specialtyOptions", :key="specialtyOption.id",
            :value="specialtyOption.specialtyModule.id"
          ) {{ specialtyOption.specialtyModule.moduleTitle }}
        .form-group__errors(v-if="v$.activeSpecialtiesDropdown.$dirty && v$.activeSpecialtiesDropdown.$errors.length")
          .form-group__error(v-if="v$.activeSpecialtiesDropdown.required.$invalid") Default Specialty View is required!
      .columns
        .column.is-half
          .form-group(:class="{ 'form-group__invalid': v$.showDoctor.first.$errors.length }")
            label(for="first-name") First Name (Required)
            input#first-name(type="text" v-model="showDoctor.first" @blur="v$.showDoctor.first.$touch()")
            .form-group__errors(v-if="v$.showDoctor.first.$dirty && v$.showDoctor.first.$errors.length")
              .form-group__error(v-if="v$.showDoctor.first.required.$invalid") First name is required!
        .column.is-half
          .form-group(:class="{ 'form-group__invalid': v$.showDoctor.last.$errors.length }")
            label(for="last-name") Last Name (Required)
            input#last-name(type="text" v-model="showDoctor.last" @blur="v$.showDoctor.last.$touch()")
            .form-group__errors(v-if="v$.showDoctor.last.$dirty && v$.showDoctor.last.$errors.length")
              .form-group__error(v-if="v$.showDoctor.last.required.$invalid") Last name is required!
      .form-group(:class="{ 'form-group__invalid': v$.showDoctor.password.$errors.length }" v-if=" overlayTitle=='ADD NEW USER' ")
        label(for="password") Password (Required)
        input#password(type="password" v-model="showDoctor.password" autocomplete="current-password"  @blur="v$.showDoctor.password.$touch()")
        .form-group__errors(v-if="v$.showDoctor.password.$dirty && v$.showDoctor.password.$errors.length")
          .form-group__error(v-if="v$.showDoctor.password.required.$invalid") Password is required!
          .form-group__error(v-if="v$.showDoctor.password.minLength.$invalid") Password must be at least 8 characters long!
          .form-group__error(v-if="v$.showDoctor.password.specialReq.$invalid && !v$.showDoctor.password.minLength.$invalid && !v$.showDoctor.password.required.$invalid") Password must contain at least 1 Uppercase and 1 Lowercase Alphabet and 1 Number!

      .form-group(:class="{ 'form-group__invalid': v$.showDoctor.email.$errors.length }")
        label(for="email") Email (Required)
        input#email(type="email" v-model="showDoctor.email" @blur="v$.showDoctor.email.$touch()")
        .form-group__errors(v-if="v$.showDoctor.email.$dirty && v$.showDoctor.email.$errors.length")
          .form-group__error(v-if="v$.showDoctor.email.email.$invalid") Invalid email format
          .form-group__error(v-if="v$.showDoctor.email.required.$invalid") User email is required!
      .form-group
        label(for="details") Details
        textarea#details(style="height:250px;" v-model="showDoctor.details")

  template(#footer)
    .flex.flex--space-between
      BaseBtn.x-lg.red-outline(
        href="#",
        @click.prevent="closeOverlay"
      ) CANCEL
      BaseBtn.x-lg.green(
        type="submit",
        form="manage-user"
        :disabled="v$.$invalid || isSubmitting"
        :class="{spinner: isSubmitting }"
      ) CONTINUE
    
</template>

<script>
import { defineComponent } from "vue";
import SideOverlaySlot from "@/components/overlays/SideOverlaySlot.vue";

import { register } from "@/api/accountApi";
import { fetchUser, updateUser } from "@/api/userApi";
import { getHospitalSpecialty, fetchDoctors } from "@/api/hospitalApi";

import { apiStatus, apiStatusComputedFactory } from "@/api";
const { IDLE, PENDING, SUCCESS, ERROR } = apiStatus;

import useVuelidate from "@vuelidate/core";
import { required, email, minLength } from "@vuelidate/validators";

import Multiselect from "@vueform/multiselect";
import { createToast } from "mosha-vue-toastify";

export default defineComponent({
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    let args = {
      finalSpecialtiesValues: {},
      activeSpecialtiesDropdown: {},
      showDoctor: {
        idUserRole: {
          required,
          function(value) {
            if (value == 0) return false;
            return true;
          },
        },
        first: { required },
        last: { required },
        password: {},
        email: { required, email },
      },
    };
    if (this.showDefaultSpecialtyView) {
      args.activeSpecialtiesDropdown = {
        required,
        function(val) {
          if (val == "0" || val == "") return false;
          return true;
        },
      };
    }
    if (this.showSpecialties) {
      args.finalSpecialtiesValues = {
        required,
        function(val) {
          if (val.length) return true;
          return false;
        },
      };
    }

    if (this.overlayTitle == "ADD NEW USER") {
      args.showDoctor.password = {
        required,
        minLength: minLength(8),
        specialReq: function (value) {
          const containsUppercase = /[A-Z]/.test(value);
          const containsLowercase = /[a-z]/.test(value);
          const containsNumber = /[0-9]/.test(value);
          return containsUppercase && containsLowercase && containsNumber;
        },
      };
    }

    return args;
  },
  components: { SideOverlaySlot, Multiselect },
  emits: ["closeManageUsersHospitalsOverlay"],

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    overlayTitle: {
      type: String,
      required: true,
    },
    showDoctor: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      showSpecialties: false,
      showDefaultSpecialtyView: false,

      addDoctorStatus: IDLE,
      getHospitalSpecialtyStatus: IDLE,
      fetchDoctorsStatus: IDLE,
      updateUserStatus: IDLE,
      fetchUserStatus: IDLE,

      specialtyOptions: null,
      activeSpecialties: [],
      activeSpecialtiesDropdown: 0,

      isSubmitting: false,
      isUpdateServerError: false,

      multiSelect: {
        mode: "tags",
        value: [],
        options: [],
        createTag: true,
      },
    };
  },
  computed: {
    ...apiStatusComputedFactory(
      "addDoctorStatus",
      "getHospitalSpecialtyStatus",
      "fetchUserStatus"
    ),
    finalSpecialtiesValues() {
      return this.multiSelect.value;
    },
  },
  watch: {
    // showDoctor: {
    //   handler(val) {
    //     // this.checkUserRole(val);
    //   },
    //   deep: true,
    // },
    // activeSpecialtiesDropdown: {
    //   handler(val) {
    //     this.activeSpecialties = [];
    //     this.activeSpecialties.push(val);
    //   },
    // },
  },
  methods: {
    async fetchUser() {
      this.fetchUserStatus = PENDING;
      try {
        const res = await fetchUser({
          params: {
            ...this.reqPagination,
            filters: {
              idUser: this.showDoctor.id,
              idHospital: this.$route.params.idHospital,
            },
            include: ["clinic"],
          },
        });
        this.activeSpecialtiesDropdown = res.data.users[0].idDefaultSpecialty;
        this.fetchUserStatus = SUCCESS;
      } catch (err) {
        this.fetchUserStatus = ERROR;
        console.error(err);
      }
    },
    checkUserRole(val) {
      this.showSpecialties = false;
      this.showDefaultSpecialtyView = false;
      if (val.idUserRole == 6 || val.idUserRole == 7) {
        this.showDefaultSpecialtyView = true;
        this.fetchUser();
      } else if (val.idUserRole == 8) {
        this.showSpecialties = true;
        this.fetchDoctors();
      }
    },
    onChange(event) {
      this.showSpecialties = false;
      this.showDefaultSpecialtyView = false;

      if (event.target.value == 6 || event.target.value == 7) {
        this.showDefaultSpecialtyView = true;
      } else if (event.target.value == 8) {
        this.showSpecialties = true;
      }
    },
    saveAndCloseOverlay() {
      if (this.overlayTitle == "ADD NEW USER") {
        this.register().then(() => {
          this.$emit("closeManageUsersHospitalsOverlay");
        });
      } else {
        this.updateUser().then(() => {
          if (!this.isUpdateServerError) {
            this.$emit("closeManageUsersHospitalsOverlay");
          } else {
            this.isUpdateServerError = false;
          }
        });
      }
    },
    closeOverlay() {
      this.$emit("closeManageUsersHospitalsOverlay");
    },
    async register() {
      this.isSubmitting = true;
      this.addDoctorStatus = PENDING;
      let registerSpecialty = [];
      let sendSpecialtyParams = {};

      if (this.showSpecialties) {
        this.multiSelect.value.forEach((element) => {
          registerSpecialty.push(element);
        });
        sendSpecialtyParams = {
          activeSpecialties: registerSpecialty,
        };
      } else {
        sendSpecialtyParams = {
          idDefaultSpecialty: this.activeSpecialtiesDropdown,
        };
      }

      try {
        await register({
          idHospital: this.$route.params.idHospital,
          user: {
            createdAt: null,
            details: this.showDoctor.details,
            email: this.showDoctor.email,
            first: this.showDoctor.first,
            idUserRole: this.showDoctor.idUserRole,
            last: this.showDoctor.last,
            password: this.showDoctor.password,
            signInCount: 0,
            status: null,
            updatedAt: null,
          },
          ...sendSpecialtyParams,
        });

        this.addDoctorStatus = SUCCESS;
      } catch (err) {
        this.createPatientAppointmentStatus = ERROR;
        createToast(err?.response?.data?.message || "There was a problem", {
          timeout: 4000,
          type: "danger",
          position: "bottom-right",
        });
        this.addDoctorStatus = ERROR;
        console.error(err);
      } finally {
        this.isSubmitting = false;
      }
    },
    async updateUser() {
      this.isSubmitting = true;
      this.updateUserStatus = PENDING;
      let userID = this.showDoctor.id;
      let specialtiesStatusChanged = [];
      let sendSpecialtyParams = {};

      if (this.showSpecialties) {
        let updateSpecialties = this.specialtyOptions.map((element) => {
          return {
            active: false,
            idHospital: this.$route.params.idHospital,
            idSpecialtyModule: element.specialtyModule.id,
          };
        });

        this.multiSelect.value.forEach((element) => {
          updateSpecialties.forEach((updateSpecialty, index) => {
            if (element == updateSpecialty.idSpecialtyModule) {
              updateSpecialties[index].active = true;
            }
          });
        });
        specialtiesStatusChanged = updateSpecialties;
        sendSpecialtyParams = {
          specialtiesStatusChanged: specialtiesStatusChanged,
        };
      } else {
        sendSpecialtyParams = {
          idDefaultSpecialty: this.activeSpecialtiesDropdown,
        };
      }

      try {
        await updateUser(userID, {
          idHospital: this.$route.params.idHospital,
          user: {
            details: this.showDoctor.details,
            email: this.showDoctor.email,
            first: this.showDoctor.first,
            idUserRole: this.showDoctor.idUserRole,
            last: this.showDoctor.last,
            password: this.showDoctor.password,
          },
          ...sendSpecialtyParams,
        });
        this.updateUserStatus = SUCCESS;
      } catch (err) {
        this.updateUserStatus = ERROR;
        createToast(err?.response?.data?.message || "There was a problem", {
          timeout: 4000,
          type: "danger",
          position: "bottom-right",
        });
        this.isUpdateServerError = true;
        console.error(err);
      } finally {
        this.isSubmitting = false;
      }
    },
    async fetchDoctors() {
      this.fetchDoctorsStatus = PENDING;
      try {
        const res = await fetchDoctors(this.$route.params.idHospital);
        let doctors = res.data.doctors;
        doctors.forEach((item) => {
          if (item.id == this.showDoctor.id) {
            let specialtiesActive = [];

            item.doctorSpecialties.forEach((specialty) => {
              if (specialty.active) {
                specialtiesActive.push(specialty.id_specialty_module);
              }
            });
            this.activeSpecialtiesDropdown = specialtiesActive;
            this.multiSelect.value = specialtiesActive;
          }
        });
        this.fetchDoctorsStatus = SUCCESS;
      } catch (err) {
        console.error(err);
        this.fetchDoctorsStatus = ERROR;
      }
    },
    async getHospitalSpecialty() {
      this.getHospitalSpecialtyStatus = PENDING;
      try {
        const res = await getHospitalSpecialty(this.$route.params.idHospital);
        this.specialtyOptions = res.data.hospitalSpecialties;
        this.getHospitalSpecialtyStatus = SUCCESS;

        let resOptions = this.specialtyOptions?.map(({ specialtyModule }) => {
          return {
            value: specialtyModule.id,
            label: specialtyModule.moduleTitle,
          };
        });
        this.multiSelect.options = resOptions;
      } catch (err) {
        console.error(err);
        this.getHospitalSpecialtyStatus = ERROR;
      }
    },
  },
  created() {
    this.getHospitalSpecialty();
    if (this.showDoctor !== null) {
      this.checkUserRole(this.showDoctor);
    }
  },
});
</script>

<style scoped lang="scss">
.patient-section {
  margin-bottom: 0;
  padding: 37px 24px 25px;
  background-color: $primaryBgBlue;
  p {
    margin: 0 0 12px;
    font-size: 21px;
    font-weight: 500;
    line-height: 1.1;
    color: $primaryLightBlue;
    overflow: hidden;
    strong {
      font-weight: 700;
    }
  }
}
form#manage-user {
  margin: 12px 0 20px;
  .columns {
    margin-bottom: 0px;
    .column {
      padding-bottom: 0px;
    }
  }
}
.overlay-show-success {
  p {
    margin: 41px 0 60px;
  }
}
</style>
<style src="@vueform/multiselect/themes/default.css"></style>
