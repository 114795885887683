<template lang="pug">
SideOverlaySlot(
  :isOpen="isOpen",
  :title="overlayTitle",
  @closeOverlay="closeOverlay"
)
  template(#contentTop)
    section.patient-section
        .patient-section__top
          p.bold Patient: {{ getUserFormatted(patientAppointment.patient) }}
          p
            span.bold {{ getDateFromUnixFormatted(patientAppointment.scheduledAt) }}&nbsp;
            span  {{ getTimeFromUnixFormatted(patientAppointment.scheduledAt) }} 
          p with {{ getDoctorFormatted(patientAppointment.schedule.doctor) }}
          
    .info-pair
      .label Notes (Required)
      textarea(style="height: 383px;", v-model="noteText")
    
  template(#footer)
    .flex.flex--space-between
      BaseBtn.x-lg.red-outline(
        @click.prevent="closeOverlay"
      ) GO BACK
      BaseBtn.x-lg.green(
        type="submit",
        form="refferal-info"
        :disabled = "!noteText"
        @click.prevent="cancelAppointment"
      ) YES, CONTINUE
</template>

<script>
import { defineComponent } from "vue";
import SideOverlaySlot from "@/components/overlays/SideOverlaySlot.vue";
import { mapGetters } from "vuex";

import { patchPatientAppointmentById } from "@/api/patientAppointmentApi";
import { createPatientAppointmentNote } from "@/api/notifications.ts";

import { apiStatus, apiStatusComputedFactory } from "@/api";
const { IDLE, PENDING, SUCCESS, ERROR } = apiStatus;
import { createToast } from "mosha-vue-toastify";

import {
  getUserFormatted,
  getDateFromUnixFormatted,
  getTimeFromUnixFormatted,
  getDoctorFormatted,
} from "@/helpers";

export default defineComponent({
  components: { SideOverlaySlot },
  emits: ["closeCancelAppointmentOverlay", "closeCancelWithAppointmentOverlay"],

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    overlayTitle: {
      type: String,
      required: true,
    },
    patientAppointment: {
      required: false,
      type: Object,
    },
  },
  computed: {
    ...mapGetters(["getUserId"]),
  },
  data() {
    return {
      noteText: null,
      createPatientAppointmentNoteStatus: IDLE,
      patchPatientAppointmentByIdStatus: IDLE,
    };
  },
  methods: {
    getUserFormatted,
    getDateFromUnixFormatted,
    getTimeFromUnixFormatted,
    getDoctorFormatted,
    async createPatientAppointmentNote() {
      this.createPatientAppointmentNoteStatus = PENDING;
      try {
        let origin = "Hospital";
        let destination = "Clinic";
        if (this.$route.params.idClinic) {
          origin = "Clinic";
          destination = "Hospital";
        }
        const res = await createPatientAppointmentNote({
          idEntity: this.patientAppointment.id,
          entityType: "appointment",
          createdBy: this.getUserId,
          note: {
            content: this.noteText,
            stage: this.patientAppointment.stage,
            type: "Cancelled",
            origin: origin,
            destination: destination,
          },
        });
        this.noteText = "";
        this.createPatientAppointmentNoteStatus = SUCCESS;
      } catch (err) {
        this.createPatientAppointmentNoteStatus = ERROR;
        console.error(err);
      }
    },
    async patchPatientAppointmentById() {
      this.patchPatientAppointmentByIdStatus = PENDING;
      let idSpecialty = this.$route.params.idSpecialty;
      try {
        const res = await patchPatientAppointmentById(
          this.patientAppointment.id,
          idSpecialty,
          {
            // clinicNotes: this.patientAppointment.clinicNotes,
            id: this.patientAppointment.id,
            idSchedule: this.patientAppointment.idSchedule,
            scheduledAt: this.patientAppointment.scheduledAt,
            stage: "ARCHIVES",
            status: "Cancelled",
          }
        );
        this.patchPatientAppointmentByIdStatus = SUCCESS;
        createToast("Appointment was cancelled.", {
          timeout: 4000,
          type: "success",
          position: "bottom-right",
        });
      } catch (err) {
        this.patchPatientAppointmentByIdStatus = ERROR;
        console.error(err);
      }
    },
    cancelAppointment() {
      this.patchPatientAppointmentById().then(() => {
        this.createPatientAppointmentNote();
        this.$emit("closeCancelWithAppointmentOverlay");
      });
    },
    closeOverlay() {
      this.$emit("closeCancelAppointmentOverlay");
    },
  },
});
</script>

<style scoped lang="scss">
.info-pair {
  margin-top: 10px;
  .label {
    margin-bottom: 11px;
  }
}
body .patient-section {
  margin-top: 32px;
}
.small {
  font-size: 14px;
}
.patient-section {
  position: relative;
  z-index: 1;
  margin-bottom: 31px;
  padding: 15px 24px 15px;
  background-color: $primaryBgBlue;
  color: $primaryLightBlue;
  &__top {
    margin-bottom: 8px;
    p {
      margin: 0;
      font-size: 21px;
      line-height: 1.67;
      color: #019bdf;
    }
  }
}
form#refferal-info {
  margin: 12px 0 20px;
  .columns {
    margin-bottom: 0px;
    .column {
      padding-bottom: 0px;
    }
  }
}
.overlay-show-success {
  p {
    margin: 41px 0 60px;
  }
}
</style>
