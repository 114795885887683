<template lang="pug">
.three_dots_wrap(:class="{ active: isActive }" @click="showPopUp")
  .dot
  .dot
  .dot
  .dot_popup
    slot(name="entries")
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
  data() {
    return {
      isActive: false,
    };
  },
  methods:{
    showPopUp(){
      this.isActive = !this.isActive;
    }
  }
});
</script>
<style lang="scss">
.three_dots_wrap{
  width:20px;
  height:20px;
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  align-items:center;
  margin-top:5px;
  position:relative;
  .dot{
    width:5px;
    height:5px;
    background-color:#c5c7cd;
    border-radius:100%;
  }
  &.active{
    .dot_popup{
      display:inline;
    }
  }
  .dot_popup{
    display:none;
    position:absolute;
    top:22px;
    right:8px;
    background-color:white;
    width:192px;
    border:2px solid #bbbabb;
    white-space:nowrap;
    z-index:2;
    .dot_popup_entry{
      border-bottom:1px solid #bbbabb;
      padding: 10px 15px;
      display:block;
      color:#000;
      &:last-child{
        border-bottom:0px;
      }
      &:hover{
        text-decoration:underline;
        background-color:transparent;
      }
    }
  }
}
</style>