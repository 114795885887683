<template lang="pug">
SideOverlaySlot(
  :isOpen="isOpen",
  title="FOLLOW-UP NOTES"
  @closeOverlay="closeOverlay"
)
  template(#contentTop)
    PatientSection(:fitKit="selectedFitKit")
  template(#scrollbox)
    .overlay-pairs.history-section
      .item(v-for="followUp in followUpEditions" :key="followUp.id")
        .date-user
          .date-user__line
          .date-user__txt {{getNoteTitle(followUp)}}
        .pairs.columns
          .pair.column.is-half
            .pair-label Type of Follow-Up
            .pair-txt <strong>{{getFollowUpEditionType(followUp)}}</strong>
          .pair.column.is-half
            .pair-label Name of Staff
            .pair-txt <strong>{{followUp.staffName}}</strong>
        .pairs.columns
          .pair.column
            .pair-label Notes
            .pair-txt
              p {{followUp.notes}}
  template(#footer)
    .flex.flex--space-between
      BaseBtn.x-lg.blue-outline(
        href="#",
        @click.prevent="nextOverlayIs('FollowUpNotesUpdateOverlay')"
      ) EDIT
      BaseBtn.x-lg.blue(
        href="#",
        @click.prevent="closeOverlay"
      ) CLOSE
</template>

<script>
import { defineComponent } from "vue";
import SideOverlaySlot from "@/components/overlays/SideOverlaySlot.vue";
import PatientSection from "@/components/fit-kit/overlays/PatientSection.vue";
import { getFitKitFollowUpEditions } from "@/api/fitKitApi";
import { getNoteTitle, FOLLOWUP_TYPE_SIMPLE_OPTIONS } from "@/helpers";

export default defineComponent({
  components: {
    SideOverlaySlot,
    PatientSection,
  },
  emits: ["closeOverlay", "nextOverlayIs"],

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    selectedFitKit: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      followUpEditions: null,
    };
  },
  methods: {
    nextOverlayIs(nextOverlayName) {
      this.$emit("nextOverlayIs", nextOverlayName);
    },
    closeOverlay() {
      this.$emit("closeOverlay", {
        afterAction: false,
      });
    },
    getFollowUpEditionType(followUp) {
      return followUp.followUpType
        ? FOLLOWUP_TYPE_SIMPLE_OPTIONS[followUp.followUpType]
        : "";
    },
    getNoteTitle,
    async fetchFollowUpEditions() {
      const { id: idFitKit, initialFollowUp } = this.selectedFitKit;
      const initialFollowUpEdition = {
        id: 0,
        idFollowUp: initialFollowUp.id,
        notes: initialFollowUp.notes,
        staffName: initialFollowUp.staffName,
        followUp: initialFollowUp.followUp,
        followUpType: initialFollowUp.followUpType,
        createdAt: initialFollowUp.createdAt,
        createdByFirst: initialFollowUp.createdBy.first,
        createdByLast: initialFollowUp.createdBy.last,
      };
      try {
        const resFollowUps = await getFitKitFollowUpEditions(
          idFitKit,
          initialFollowUp.id
        );
        this.followUpEditions = [
          initialFollowUpEdition,
          ...resFollowUps.data.editions,
        ].sort((x, y) => y.createdAt - x.createdAt);
      } catch (err) {
        console.error(err);
        this.followUpEditions = [initialFollowUpEdition];
      }
    },
  },

  created() {
    if (this.selectedFitKit.id === null) return;
    this.fetchFollowUpEditions();
  },
});
</script>

<style scoped lang="scss"></style>
