<template lang="pug">
section.patient-section
  .patient-section__top
    h2 Patient: {{getPatientName()}}
    h2 Kit #: {{fitKit && fitKit.kitNumber}}
  table.patient-section__data(v-if="showDetails")
    tr
      td Phone:
      td {{getPatientPhone()}}
    tr
      td Address:
      td {{getPatientStreet()}},<br> {{getPatientStateAndArea()}}
  .patient-section__provider(v-if="showProvider")
    p Provider: {{getProviderName()}}
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  components: {},

  props: {
    showDetails: {
      type: Boolean,
      default: true,
    },
    showProvider: {
      type: Boolean,
      default: true,
    },
    fitKit: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {};
  },

  computed: {},

  watch: {
    // keywords: {
    //   handler: "getRows",
    //   immediate: true
    // }
  },

  methods: {
    getPatientName() {
      return this.fitKit && this.fitKit.patient
        ? `${this.fitKit.patient.first} ${this.fitKit.patient.last}`
        : "";
    },
    getPatientPhone() {
      return this.fitKit && this.fitKit.patient && this.fitKit.patient.contact
        ? this.fitKit.patient.contact.cellPhone
        : "";
    },
    getPatientStreet() {
      return this.fitKit && this.fitKit.patient && this.fitKit.patient.address
        ? this.fitKit.patient.address.address
        : "";
    },
    getPatientStateAndArea() {
      return this.fitKit && this.fitKit.patient && this.fitKit.patient.address
        ? `${this.fitKit.patient.address.state} ${this.fitKit.patient.address.zipCode}`
        : "";
    },
    getProviderName() {
      return this.fitKit && this.fitKit.providerUser
        ? `${this.fitKit.providerUser.first} ${this.fitKit.providerUser.last}`
        : "";
    },
  },
});
</script>

<style scoped lang="scss">
.patient-section {
  position: relative;
  z-index: 1;
  margin-bottom: 31px;
  padding: 30px 24px 35px;
  background-color: $primaryBgBlue;
  color: $primaryLightBlue;
  &__top {
    margin-bottom: 8px;
    h2 {
      margin: 0;
      font-size: 21px;
      font-weight: bold;
      line-height: 1.67;
      color: #019bdf;
    }
  }
  &__data {
    td {
      padding-right: 14px;
      vertical-align: top;
    }
  }
  &__provider {
    margin-top: 31px;
    p {
      margin: 0;
      font-size: 16px;
      font-weight: 700;
      line-height: 1.31;
    }
  }
}
</style>
