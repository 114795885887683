<template lang="pug">
SideOverlaySlot(
  :isOpen="isOpen",
  title="COMPLETED FIT"
  @closeOverlay="closeOverlay"
)
  template(#contentTop)
    PatientSection(:fitKit="selectedFitKit")
  template(#scrollbox)
    .overlay-pairs.history-section
      .item(v-for="edition in completionEditions" :key="edition.id")
        .date-user
          .date-user__line
          .date-user__txt {{getNoteTitle(edition)}}
        .pairs.columns
          .pair.column.is-half
            .pair-label FIT Test Results
            .pair-txt <strong>{{getFitResult(edition)}}</strong>
          .pair.column.is-half
            .pair-label Date of Processing
            .pair-txt <strong>{{getDateOfProcessing(edition)}}</strong>
        .pairs.columns
          .pair.column.is-half(v-if="areFitNoResults(edition)")
            .pair-label Details
            .pair-txt {{getFitDetails(edition)}}
          .pair.column.is-half
            .pair-label Location
            .pair-txt {{getLocation(edition)}}
        .pairs.columns
          .pair.column.is-half
            .pair-label Flu vaccine
            .pair-txt {{getFluVaccine(edition)}}
          .pair.column.is-half
            .pair-label COVID vaccine
            .pair-txt {{getCovidVaccine(edition)}}
        .pairs.columns
          .pair.column
            .pair-label Insurance Options
            .pair-txt {{getInsurance(edition)}}
        .pairs.columns
          .pair.column
            .pair-label Notes
            .pair-txt
              p {{edition.completionNotes}}



  template(#footer)
    .flex.flex--space-between(:class="{ notEditableFooter: !isEditable }")
      BaseBtn.x-lg.blue-outline(
        v-if="isEditable"
        href="#",
        @click.prevent="openCompleteFitOverlay"
      ) EDIT
      BaseBtn.x-lg.blue(
        href="#",
        @click.prevent="closeOverlay"
      ) CLOSE
</template>

<script>
import { defineComponent } from "vue";
import SideOverlaySlot from "@/components/overlays/SideOverlaySlot.vue";
import PatientSection from "@/components/fit-kit/overlays/PatientSection.vue";
import { getFitKitEditions } from "@/api/fitKitApi";
import {
  getNoteTitle,
  getDateFromUnixFormatted,
  FIT_DETAIL_OPTIONS,
  COMPLETED_FIT_SIMPLE_OPTIONS,
} from "@/helpers";

export default defineComponent({
  components: {
    SideOverlaySlot,
    PatientSection,
  },
  emits: ["closeCompletedFitOverlay", "openCompleteFitOverlay"],

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    selectedFitKit: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      completionEditions: null,
      isEditable: true,
    };
  },

  watch: {
    selectedFitKit: function (newVal) {
      if (newVal && newVal.id) {
        this.fetchEditions();
      }
    },
  },

  methods: {
    closeOverlay() {
      this.$emit("closeCompletedFitOverlay");
    },
    openCompleteFitOverlay() {
      this.closeOverlay();
      this.$emit("openCompleteFitOverlay", this.selectedFitKit);
    },
    getNoteTitle,
    getFitResult(edition) {
      return edition.result ? COMPLETED_FIT_SIMPLE_OPTIONS[edition.result] : "";
    },
    areFitNoResults(edition) {
      return (
        edition.result && edition.result === "no_result" && edition.details
      );
    },
    getDateOfProcessing(edition) {
      return (
        edition.processedAt && getDateFromUnixFormatted(edition.processedAt)
      );
    },
    getFitDetails(edition) {
      return edition.details
        ? FIT_DETAIL_OPTIONS.find((x) => x.value === edition.details).name
        : "";
    },
    getFluVaccine(edition) {
      return edition.fluVaccine === null
        ? "N/A"
        : edition.fluVaccine
        ? "Yes"
        : "No";
    },
    getCovidVaccine(edition) {
      return edition.covidVaccine === null
        ? "N/A"
        : edition.covidVaccine
        ? "Yes"
        : "No";
    },
    getLocation(edition) {
      return edition.location === null ? "N/A" : edition.location;
    },
    getInsurance(edition) {
      return edition.insurance === null ? "N/A" : edition.insurance;
    },
    async fetchEditions() {
      const { id: idFitKit, initialCompletion } = this.selectedFitKit;
      const initialCompletionEdition = {
        id: 0,
        completionNotes: initialCompletion.completionNotes,
        result: initialCompletion.result,
        details: initialCompletion.details,
        createdAt: initialCompletion.createdAt,
        processedAt: initialCompletion.processedAt,
        createdByFirst: initialCompletion.completionUser.first,
        createdByLast: initialCompletion.completionUser.last,
      };
      try {
        const resEditions = await getFitKitEditions(idFitKit);
        this.completionEditions = [
          initialCompletionEdition,
          ...resEditions.data.editions,
        ].sort((x, y) => y.createdAt - x.createdAt);
      } catch (err) {
        console.error(err);
        this.completionEditions = [initialCompletionEdition];
      } finally {
        const { initialCompletion } = this.selectedFitKit;
        this.completionEditions = this.completionEditions.map((x) => {
          return {
            ...x,
            fluVaccine: initialCompletion.fluVaccine,
            covidVaccine: initialCompletion.covidVaccine,
            location: initialCompletion.location,
            insurance: initialCompletion.insurance,
          };
        });
      }
      if (initialCompletion.isEditable !== null) {
        this.isEditable = initialCompletion.isEditable;
      }
    },

    created() {
      if (this.selectedFitKit.id === null) return;
      this.fetchEditions();
    },
  },
});
</script>

<style scoped lang="scss">
.notEditableFooter {
  justify-content: center !important;
}
</style>
