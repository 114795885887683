import api from "./api";
const URLS = {
  INSURANCE: `/insurance`,
};
export const fetchInsurances = (data = {}) => {
  return api.get(URLS.INSURANCE, data);
};
export const fetchInsurance = (id: string, data = {}) => {
  return api.get(URLS.INSURANCE + "/" + id, data);
};
export const createInsurance = (data = {}) => {
  return api.post(URLS.INSURANCE, data);
};
export const updateInsurance = (id: string, data = {}) => {
  return api.put(URLS.INSURANCE + "/" + id, data);
};
export const activateInsurance = (id: string, data = {}) => {
  return api.put(URLS.INSURANCE + "/" + id + "/activate", data);
};
export const deactivateInsurance = (id: string, data = {}) => {
  return api.put(URLS.INSURANCE + "/" + id + "/deactivate", data);
};
