<template lang="pug">
section.step-subtitle
  h2 SELECT PATIENT
  BaseBtn.x-lg.blue(@click.prevent="openAddNewClinicPatientOverlayOpen") ADD NEW PATIENT
section.select-patient
  TableTitle(:title="getTitle")
  .table
    .th
      .td Patient Name
      .td Clinic
      .td DOB
      .td
      .td
    .tr(v-for="patient in patients" :key="patient.id")
      .td.bold {{ getUserFormatted(patient) }}
      .td {{getClinicName(patient)}}
      .td {{getDobFormatted(patient.dob)}}
      .td
        BaseBtn.sm.blue-outline(
          @click="openPatientDetailsOverlay(patient)"
        ) PATIENT RECORDS

      .td
        BaseBtn.sm.blue(
          @click="selectPatient(patient)"
        ) SELECT PATIENT
  Pagination(
    v-if="pagination",
    :pagination="pagination",
    :reloadFn="fetchPatients"
    @changePagination="changePagination"
  )
  .no-results(v-if="patients != null && !patients.length") 
    base-icon(name="exclamation") 
    span No matching records
  .actions-below-table-container
    BaseBtn.lg.green-outline(
      :to="{name: 'cco-referrals.scheduling.search-patient'}",
      style="min-width: 270px",
    ) &lt; PREVIOUS STEP

AddNewPatientOverlay(
  :isOpen="isAddNewClinicPatientOverlayOpen",
  @closeAddNewClinicPatientOverlayOpen="closeAddNewClinicPatientOverlayOpen",
  @reloadPatients="reloadPatients"
)
PatientDetailsOverlay(
  :key="`${componentKey}-1`"
  :showPatient="showPatient",
  :isOpen="isClinicPatientDetalisOverlay",
  @closePatientDetailsOverlay="closePatientDetailsOverlay",
)
</template>

<script>
import { defineComponent } from "vue";
import moment from "moment";
import TableTitle from "@/components/TableTitle.vue";
import Pagination from "@/components/Pagination.vue";
import listPagesMixin from "@/mixins/listPagesMixin";
import { getDobFormatted, getUserFormatted } from "@/helpers";
import {
  fetchCommunityCareOrganizationById,
  fetchCommunityCareOrganizationPatients,
} from "@/api/communityOrganization";
import AddNewPatientOverlay from "@/components/cco-referrals/scheduling/overlays/AddNewPatientOverlay.vue";
import PatientDetailsOverlay from "@/components/cco-referrals/scheduling/overlays/PatientDetailsOverlay.vue"; 

export default defineComponent({
  components: {
    TableTitle,
    Pagination,
    AddNewPatientOverlay,
    PatientDetailsOverlay,
  },
  mixins: [listPagesMixin],
  data() {
    return {
      isClinicPatientDetalisOverlay: false,
      patients: null,
      isAddNewClinicPatientOverlayOpen: false,
      getTitle: "",
      showPatient: null,
      accessToPatients: false,
      searchParams: null,
    };
  },

  methods: {
    async reloadPatients(patient) {
      this.searchParams = {
        searchBy: "name",
        firstName: patient.first,
        lastName: patient.last,
      };
      this.$router.push({
        name: "cco-referrals.scheduling.select-patient",
        query: this.searchParams,
      });
      this.fetchPatients();
    },
    shouldDisableRecords(patient) {
      if (!patient.clinic) {
        return false;
      }
      return !this.accessToPatients;
    },
    getDobFormatted,
    getUserFormatted,
    getClinicName(patient) {
      return patient && patient.clinic && patient.clinic.name;
    },
    async fetchCommunityCareOrganizationById() {
      try {
        const res = await fetchCommunityCareOrganizationById(
          this.$route.params.idCco
        );
        this.accessToPatients = res.data.hasPatientAccess;
      } catch (err) {
        console.error(err);
      }
    },
    async fetchPatients() {
      this.getTitle = "(0) Patients Found";
      try {
        let query_params = {
          searchBy: this.$route.query.searchBy,
          searchInput: this.getSearchInput(this.$route.query),
        };
        if (this.searchParams) {
          query_params = {
            searchBy: this.searchParams.searchBy,
            searchInput: this.getSearchInput(this.searchParams),
          };
        }
        const resPatient = await fetchCommunityCareOrganizationPatients(
          this.$route.params.idCco,
          {
            params: query_params,
            ...this.reqPagination,
          }
        );
        this.patients = resPatient.data.patients.map((element) => {
          element.isDisabled = true;
          if (element.idClinic == this.$route.params.idClinic) {
            element.isDisabled = false;
          }
          return element;
        });

        if (this.patients != null && this.patients.length) {
          this.getTitle = "(" + this.patients.length + ") Patients Found";
        }
      } catch (err) {
        console.error(err);
      }
    },
    getSearchInput(searchParams) {
      let searchInput = {};
      const searchBy = searchParams.searchBy;
      switch (searchBy) {
        case "name":
          if (searchParams.firstName) {
            searchInput.first = searchParams.firstName;
          }
          if (searchParams.lastName) {
            searchInput.last = searchParams.lastName;
          }
          break;
        case "mrn":
          searchInput = searchParams.mrn;
          break;
        case "dob":
          searchInput = moment.utc(searchParams.dob).format("MMDDYYYY");
          break;
        default:
          break;
      }
      return searchInput;
    },
    selectPatient(patient) {
      this.$router.push({
        name: "cco-referrals.scheduling.select-provider",
        query: this.$route.query,
        params: {
          idPatient: patient.id,
        },
      });
    },
    openAddNewClinicPatientOverlayOpen() {
      this.isAddNewClinicPatientOverlayOpen = true;
    },
    closeAddNewClinicPatientOverlayOpen() {
      this.isAddNewClinicPatientOverlayOpen = false;
    },
    openPatientDetailsOverlay(patient) {
      this.componentKey = this.componentKey + 1;
      this.showPatient = patient;
      this.isClinicPatientDetalisOverlay = true;
    },
    closePatientDetailsOverlay() {
      this.isClinicPatientDetalisOverlay = false;
      this.fetchPatients();
    },
  },
  created() {
    if (this.$route.query === null) return;
    this.fetchPatients();
    this.fetchCommunityCareOrganizationById();
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/components/actions-below-table-container.scss";
@import "@/scss/components/step-subtitle.scss";

.select-patient {
  .td {
    &:nth-child(1) {
      width: 23.5%;
    }

    &:nth-child(2) {
      width: 23.1%;
    }

    &:nth-child(3) {
      width: 15.4%;
    }

    &:nth-child(4) {
      width: 20.4%;
    }

    &:last-child {
      width: 17.6%;
      // width: 19.5%;
    }
  }
}

.actions-below-table-container {
  .buttons {
    margin-left: 0;
  }
}
</style>
