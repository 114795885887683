<template lang="pug">
TableTitle(title="Outside ICC Colonoscopy Appointments",
  searchPlaceholder="Search..."
  v-model="searchKeyword",
  @doSearch="fetchPendingOutsideIccFitKit",
  @blur="fetchPendingOutsideIccFitKit"
)
  template(#actions)
    DateSelector(@filterByDate="filterByDate")
.table
  .th
    .td(@click.prevent="changeListOrder('patientName', fetchPendingOutsideIccFitKit)" style="cursor: pointer;") Patient Name
      i(style="font-weight: bolder;") {{ patientNameColumnSortingIcon }}
    .td(@click.prevent="changeListOrder('kitNumber', fetchPendingOutsideIccFitKit)" style="cursor: pointer;") KIT #
      i(style="font-weight: bolder;") {{ kitNumberColumnSortingIcon }}
    .td(@click.prevent="changeListOrder('distributedAt', fetchPendingOutsideIccFitKit)" style="cursor: pointer;") DATE SENT
      i(style="font-weight: bolder;") {{ distributedAtColumnSortingIcon }}
    .td(@click.prevent="changeListOrder('providerName', fetchPendingOutsideIccFitKit)" style="cursor: pointer;") PROVIDER NAME
      i(style="font-weight: bolder;") {{ providerNameColumnSortingIcon }}
    .td
  .tr(v-for="fitKit in pendingOutsideIccFitKit" :key="fitKit.id")
    .td.bold {{`${fitKit.patient.first} ${fitKit.patient.last}`}} 
    .td {{fitKit.kitNumber}}
    .td {{getDateFromUnixFormatted(fitKit.distributedAt)}}
    .td {{getDoctorFormatted(fitKit.providerUser)}}
    .td
      BaseBtn.sm.blue(
        href="#",
        @click.prevent="openCompleteColonoscopyOverlay(fitKit)"
      ) ADD RESULTS
Pagination(
  v-if="pagination && pendingOutsideIccFitKit != null && pendingOutsideIccFitKit.length",
  @changePagination="changePagination",
  :pagination="pagination",
  :reloadFn="fetchPendingOutsideIccFitKit"
)
.no-results(v-if="pendingOutsideIccFitKit != null && !pendingOutsideIccFitKit.length") 
  base-icon(name="exclamation") 
  span No matching records

CompleteColonoscopyOverlay(
  :isOpen="isCompleteColonoscopyOverlayOpen",
  :outsideIccFitKit="outsideIccFitKit"
  @closeCompleteColonoscopyOverlay="closeCompleteColonoscopyOverlay"
)
</template>

<script>
import { defineComponent } from "vue";
import TableTitle from "@/components/TableTitle.vue";
import DateSelector from "@/components/DateSelector.vue";
import Pagination from "@/components/Pagination.vue";
import CompleteColonoscopyOverlay from "@/components/fit-kit/overlays/CompleteColonoscopyOverlay.vue";
import { fetchFitKits } from "@/api/fitKitApi";
import {
  reformatPagination,
  getDoctorFormatted,
  getDateFromUnixFormatted,
} from "@/helpers";
import listPagesMixin from "@/mixins/listPagesMixin";
import sortFitKits from "@/mixins/sortFitKits";

export default defineComponent({
  components: {
    TableTitle,
    DateSelector,
    Pagination,
    CompleteColonoscopyOverlay,
  },

  mixins: [listPagesMixin, sortFitKits],
  emits: ["refreshAppointments"],

  data() {
    return {
      date: null,
      searchKeyword: "",
      outsideIccFitKit: null,
      pendingOutsideIccFitKit: null,
      isCompleteColonoscopyOverlayOpen: false,
    };
  },

  methods: {
    filterByDate(date) {
      this.date = date;
      this.fetchPendingOutsideIccFitKit();
    },
    openCompleteColonoscopyOverlay(fitKit) {
      this.outsideIccFitKit = fitKit;
      this.isCompleteColonoscopyOverlayOpen = true;
    },
    closeCompleteColonoscopyOverlay({ afterAction }) {
      this.isCompleteColonoscopyOverlayOpen = false;
      this.outsideIccFitKit = null;
      if (afterAction) {
        this.$router.go(0);
      }
      this.$emit("refreshAppointments");
    },
    getDoctorFormatted: getDoctorFormatted,
    getDateFromUnixFormatted: getDateFromUnixFormatted,
    async fetchPendingOutsideIccFitKit() {
      const order = this.listOrder
        ? {
            by: this.listOrder.by,
            direction: this.listOrder.direction,
          }
        : null;
      try {
        let params = {
          order,
          ...this.reqPagination,
          idClinic: this.$route.params.idClinic,
          include: ["patient", "providerUser", "colonoscopyOutsideICC"],
          pickOnly: "notCompletedOutsideICC",
        };
        if (this.date) {
          params.distributedAt = this.date;
        }
        if (!!this.searchKeyword === true) {
          params.searchKeyword = this.searchKeyword;
        }
        if (order === null) delete params.order;
        const resFitKit = await fetchFitKits({
          params,
        });
        this.pendingOutsideIccFitKit = resFitKit.data.fitKits;
        this.pagination = reformatPagination(resFitKit.data.pagination);
      } catch (err) {
        console.error(err);
      }
    },
  },
  created() {
    if (this.$route.params.idClinic === null) return;
    this.fetchPendingOutsideIccFitKit();
  },
});
</script>

<style scoped lang="scss">
.tr {
  cursor: pointer;
}
.td {
  &:nth-child(1) {
    width: math.percentage(math.div(260, $tabContentCardWidth));
  }
  &:nth-child(2) {
    width: math.percentage(math.div(156, $tabContentCardWidth));
  }
  &:nth-child(3) {
    width: math.percentage(math.div(145, $tabContentCardWidth));
  }
  &:nth-child(4) {
    width: math.percentage(math.div(240, $tabContentCardWidth));
  }
  &:nth-child(5) {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    width: math.percentage(math.div(377, $tabContentCardWidth));
  }
}
</style>
