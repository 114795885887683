<template lang="pug">
.content-wrapper
  .card.router-view-card
    section.users
      TableTitle(title="Users")
        template(#actions)
          BaseBtn.x-lg.blue(@click.prevent="openManageUsersHospitalsOverlay('ADD NEW USER', null)") ADD NEW
      .table(v-if="users?.length")
        .th
          .td(@click.prevent="changeListOrder('name', fetchUser)" style="cursor: pointer;") USER NAME
            i(style="font-weight: bolder;") {{ nameColumnSortingIcon }}
          .td(@click.prevent="changeListOrder('role', fetchUser)" style="cursor: pointer;") ROLE
            i(style="font-weight: bolder;") {{ roleColumnSortingIcon }}
          .td ACTIVE
          .td
        .tr(v-for="user in users", :key="user.id")
          .td.bold {{ user.first}} {{ user.last }}
          .td.bold {{ user.role }}
          .td
            BaseToggle(label="" :modelValue=" !user.disabled " :key="user.id" @change="toggleUserActive(user.id, $event.target.checked)" )
          .td
            BaseBtn.sm.blue(
               href="#",
              @click.prevent="openManageUsersHospitalsOverlay('MANAGE USER', user)"
            ) MANAGE
      Pagination(
        v-if="pagination",
        @changePagination="changePagination",
        :pagination="pagination",
        :reloadFn="fetchUser"
      )
      .actions-below-table-container
      ManageUsersHospitalsOverlay(
        :key="`${componentKey}-1`",
        :isOpen="isManageUsersHospitalsOverlayOpen",
        :overlayTitle="overlayTitle",
        :showDoctor="showDoctor",
        @closeManageUsersHospitalsOverlay="closeManageUsersHospitalsOverlay"
      )
</template>

<script>
import { defineComponent } from "vue";
import TableTitle from "@/components/TableTitle.vue";
import ManageUsersHospitalsOverlay from "@/components/users/overlays/ManageUsersHospitalsOverlay.vue";
import Pagination from "@/components/Pagination.vue";
import listPagesMixin from "@/mixins/listPagesMixin";
import sortUsers from "@/mixins/sortUsers";

import { fetchUser, deleteUser, patchUser } from "@/api/userApi";
import { apiStatus, apiStatusComputedFactory } from "@/api";
const { IDLE, PENDING, SUCCESS, ERROR } = apiStatus;

import { USER_ROLES } from "@/helpers/constants";

export default defineComponent({
  components: {
    TableTitle,
    ManageUsersHospitalsOverlay,
    Pagination,
  },
  mixins: [listPagesMixin, sortUsers],

  data() {
    return {
      isManageUsersHospitalsOverlayOpen: false,
      overlayTitle: "MANAGE USER",
      fetchUserStatus: IDLE,
      patchUserStatus: IDLE,
      deleteUserStatus: IDLE,
      users: null,
      USER_ROLES,
      showDoctor: null,
    };
  },
  computed: {
    ...apiStatusComputedFactory("fetchUserStatus","patchUserStatus","deleteUserStatus"),
  },
  methods: {
    toggleUserActive(id, checked) {
      if (checked === true) {
        this.patchUser(id);
      } else {
        this.deleteUser(id);
      }
    },

    async patchUser(userId) {
      this.patchUserStatus = PENDING;
      try {
        const res = await patchUser(userId);
        this.patchUserStatus = SUCCESS;
      } catch (err) {
        this.patchUserStatus = ERROR;
        console.error(err);
      }
    },
    async deleteUser(userId) {
      this.deleteUserStatus = PENDING;
      try {
        const res = await deleteUser(userId);
        this.deleteUserStatus = SUCCESS;
      } catch (err) {
        this.deleteUserStatus = ERROR;
        console.error(err);
      }
    },
    async fetchUser() {
      this.fetchUserStatus = PENDING;
      const order = this.listOrder
        ? {
            by: this.listOrder.by,
            direction: this.listOrder.direction,
          }
        : undefined;
      try {
        const res = await fetchUser({
          params: {
            order,
            ...this.reqPagination,
            filters: {
              idHospital: +this.$route.params.idHospital,
            },
          },
        });

        this.users = res.data.users;
        this.pagination = res.data.pagination;

        this.users.forEach((element) => {
          this.USER_ROLES.forEach((userRole) => {
            if (element.idUserRole == userRole.id) {
              element.role = userRole.name;
            }
          });
          if (!element.role) {
            element.role = "null";
          }
        });
        this.fetchUserStatus = SUCCESS;
      } catch (err) {
        this.fetchUserStatus = ERROR;
        console.error(err);
      }
    },
    openManageUsersHospitalsOverlay(overlayTitle, user) {
      if (user == null) {
        this.showDoctor = {
          first: "",
          last: "",
          email: "",
          details: "",
          idUserRole: 0,
        };
      } else {
        this.showDoctor = user;
      }
      this.componentKey = this.componentKey + 1;
      this.isManageUsersHospitalsOverlayOpen = true;
      this.overlayTitle = overlayTitle;
    },
    closeManageUsersHospitalsOverlay() {
      this.fetchUser();
      this.isManageUsersHospitalsOverlayOpen = false;
    },
  },
  created() {
    this.fetchUser();
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/components/step-subtitle.scss";
.users {
  .td {
    &:nth-child(1) {
      width: math.percentage(math.div(402, $tabContentCardWidth));
    }
    &:nth-child(2) {
      width: math.percentage(math.div(344, $tabContentCardWidth));
    }
    &:nth-child(3) {
      width: math.percentage(math.div(200, $tabContentCardWidth));
    }
    &:nth-child(4) {
      width: math.percentage(math.div(234, $tabContentCardWidth));
    }
  }
}
.content-wrapper {
  position: relative;
  padding-bottom: 40px;
}
.router-view-card {
  padding-top: 0;
  position: relative;
  width: 100%;
  z-index: 1;
}
.table .tr > .td {
  display: flex;
  align-items: center;
}
.table .td .btn {
  width: 100%;
}
</style>
