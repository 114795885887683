<template lang="pug">
TableTitle(
  title="Kit Follow-Up Due",
  searchPlaceholder="Search..."
  v-model="searchKeyword",
  @doSearch="fetchFollowUpFitKits",
  @blur="fetchFollowUpFitKits"
)
  template(#actions)
    DateSelector(@filterByDate="filterByDate")
.table
  .th
    .td(@click.prevent="changeListOrder('patientName', fetchFollowUpFitKits)" style="cursor: pointer;") Patient Name
      i(style="font-weight: bolder;") {{ patientNameColumnSortingIcon }}
    .td(@click.prevent="changeListOrder('fluVaccine', fetchFollowUpFitKits)" style="cursor: pointer;") FLU VACCINE
      i(style="font-weight: bolder;") {{ fluVaccineColumnSortingIcon }}
    .td(@click.prevent="changeListOrder('kitNumber', fetchFollowUpFitKits)" style="cursor: pointer;") KIT #
      i(style="font-weight: bolder;") {{ kitNumberColumnSortingIcon }}
    .td(@click.prevent="changeListOrder('distributedAt', fetchFollowUpFitKits)" style="cursor: pointer;") DATE SENT
      i(style="font-weight: bolder;") {{ distributedAtColumnSortingIcon }}
    .td(@click.prevent="changeListOrder('providerName', fetchFollowUpFitKits)" style="cursor: pointer;") PROVIDER NAME
      i(style="font-weight: bolder;") {{ providerNameColumnSortingIcon }}
    .td 2-Week
    .td 4-Week
    .td
  .tr(v-for="fitKit in followUpFitKits" :key="fitKit.id")
    .td.bold {{`${fitKit.patient.first} ${fitKit.patient.last}`}} 
    .td
      div(v-if="fitKit.fluVaccine === null") {{'N/A'}}
      base-icon(v-else-if="fitKit.fluVaccine", name="yes")
      base-icon(v-else="", name="no")
    .td {{fitKit.kitNumber}}
    .td {{getDateFromUnixFormatted(fitKit.distributedAt)}}
    .td {{getDoctorFormatted(fitKit.providerUser)}}
    .td(v-if="isFollowUpPresent(fitKit.followUpNotes.first)")
      BaseBtn.sm.blue-outline(
        @click.prevent="openFollowUpNotesOverlay('FollowUpNotesReadOverlay', getFollowUpByType(fitKit, 'first'))"
      ) VIEW
    .td(v-else)
      BaseBtn.sm.yellow(
        @click.prevent="openFollowUpNotesOverlay('FollowUpNotesCreateOverlay', fitKit, 1)"
      ) ADD
    .td(v-if="isFollowUpPresent(fitKit.followUpNotes.second)")
      BaseBtn.sm.blue-outline(
        @click.prevent="openFollowUpNotesOverlay('FollowUpNotesReadOverlay', getFollowUpByType(fitKit, 'second'))"
      ) VIEW
    .td(v-else)
      BaseBtn.sm.yellow(
        href="#",
        :class="{disabled: !isFollowUpPresent(fitKit.followUpNotes.first)}"
        @click.prevent="openFollowUpNotesOverlay('FollowUpNotesCreateOverlay', fitKit, 2)"
      ) ADD
    .td
      BaseBtn.sm.blue(
        href="#",
        @click.prevent="openCompleteFitOverlay(fitKit)"
      ) COMPLETE FIT
Pagination(
  v-if="pagination && followUpFitKits != null && followUpFitKits.length",
  @changePagination="changePagination",
  :pagination="pagination",
  :reloadFn="fetchFollowUpFitKits"
)
.no-results(v-if="followUpFitKits != null && !followUpFitKits.length") 
  base-icon(name="exclamation") 
  span No matching records

component(
  :is="overlayComponentIs",
  :isOpen="isOverlayOpen",
  :selectedFitKit="selectedFitKit"
  @closeOverlay="closeOverlay",
  @nextOverlayIs="showNextOverlay"
)
CompleteFitOverlay(
  :isOpen="isCompleteFitOverlayOpen",
  :selectedFitKit="selectedFitKit",
  @closeCompleteFitOverlay="closeCompleteFitOverlay"
)
</template>

<script>
import { defineComponent } from "vue";
import TableTitle from "@/components/TableTitle.vue";
import DateSelector from "@/components/DateSelector.vue";
import Pagination from "@/components/Pagination.vue";
import FollowUpNotesReadOverlay from "@/components/fit-kit/overlays/FollowUpNotesReadOverlay.vue";
import FollowUpNotesUpdateOverlay from "@/components/fit-kit/overlays/FollowUpNotesUpdateOverlay.vue";
import FollowUpNotesCreateOverlay from "@/components/fit-kit/overlays/FollowUpNotesCreateOverlay.vue";
import CompleteFitOverlay from "@/components/fit-kit-community-organization/overlays/CompleteFitOverlay.vue";
import { fetchFitKits } from "@/api/fitKitApi";
import {
  reformatPagination,
  getDoctorFormatted,
  getDateFromUnixFormatted,
} from "@/helpers";
import listPagesMixin from "@/mixins/listPagesMixin";
import sortFitKits from "@/mixins/sortFitKits";

export default defineComponent({
  components: {
    TableTitle,
    DateSelector,
    Pagination,
    FollowUpNotesReadOverlay,
    FollowUpNotesUpdateOverlay,
    FollowUpNotesCreateOverlay,
    CompleteFitOverlay,
  },

  mixins: [listPagesMixin, sortFitKits],

  data() {
    return {
      selectedFitKit: null,
      followUpFitKits: null,
      overlayComponentIs: null,
      isOverlayOpen: false,
      isCompleteFitOverlayOpen: false,
      date: null,
      searchKeyword: "",
    };
  },

  methods: {
    filterByDate(date) {
      this.date = date;
      this.fetchFollowUpFitKits();
    },
    openFollowUpNotesOverlay(overlayComponentIs, fitKit, followUp) {
      this.overlayComponentIs = overlayComponentIs;
      const newFollowUp = { ...fitKit, followUp };
      this.selectedFitKit = followUp ? newFollowUp : fitKit;
      this.isOverlayOpen = true;
    },
    closeOverlay({ afterAction }) {
      this.isOverlayOpen = false;
      if (afterAction) {
        this.$router.go(0);
      }
    },
    showNextOverlay(nextOverlayName) {
      if (!nextOverlayName) return this.closeOverlay;
      this.overlayComponentIs = nextOverlayName;
    },
    openCompleteFitOverlay(fitKit) {
      this.selectedFitKit = fitKit;
      this.isCompleteFitOverlayOpen = true;
    },
    closeCompleteFitOverlay({ afterAction }) {
      this.isCompleteFitOverlayOpen = false;
      if (afterAction) {
        this.$router.go(0);
      }
    },
    isFollowUpPresent(followUp) {
      return followUp.id !== null;
    },
    getDoctorFormatted: getDoctorFormatted,
    getDateFromUnixFormatted: getDateFromUnixFormatted,
    getFollowUpByType(fitKit, type) {
      const { id, idCommunity, patient, kitNumber, providerUser } = fitKit;
      const initialFollowUp =
        type === "first"
          ? fitKit.followUpNotes.first
          : fitKit.followUpNotes.second;
      return {
        id,
        idCommunity,
        patient,
        kitNumber,
        providerUser,
        initialFollowUp,
      };
    },
    async fetchFollowUpFitKits() {
      const order = this.listOrder
        ? {
            by: this.listOrder.by,
            direction: this.listOrder.direction,
          }
        : null;
      const params = {
        order,
        ...this.reqPagination,
        idCommunity: this.$route.params.idCommunity,
        include: ["patient", "providerUser", "followUpNotes"],
        pickOnly: "dueFollowUp",
        status: "open",
        searchKeyword: this.searchKeyword,
        distributedAt: this.date,
      };
      if (this.date === null) delete params.distributedAt;
      if (!!this.searchKeyword !== true) delete params.searchKeyword;
      if (order === null) delete params.order;
      try {
        const resFitKit = await fetchFitKits({
          params: {
            ...params,
          },
        });
        this.followUpFitKits = resFitKit.data.fitKits;
        this.pagination = reformatPagination(resFitKit.data.pagination);
      } catch (err) {
        console.error(err);
      }
    },
  },
  created() {
    if (this.$route.params.idCommunity === null) return;
    this.fetchFollowUpFitKits();
  },
});
</script>

<style scoped lang="scss">
.tr {
  cursor: pointer;
}
.td {
  &:nth-child(1) {
    width: math.percentage(math.div(256, $tabContentCardWidth));
  }
  &:nth-child(2) {
    width: math.percentage(math.div(118, $tabContentCardWidth));
  }
  &:nth-child(3) {
    width: math.percentage(math.div(118, $tabContentCardWidth));
  }
  &:nth-child(4) {
    width: math.percentage(math.div(118, $tabContentCardWidth));
  }
  &:nth-child(5) {
    width: math.percentage(math.div(118, $tabContentCardWidth));
  }
  &:nth-child(6) {
    width: math.percentage(math.div(118, $tabContentCardWidth));
  }
  &:nth-child(7) {
    width: math.percentage(math.div(118, $tabContentCardWidth));
  }
  &:nth-child(8) {
    width: math.percentage(math.div(200, $tabContentCardWidth));
  }
  .btn {
    display: block;
    min-width: 0;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}
</style>
