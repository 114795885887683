<template lang="pug">
.content-wrapper
  .card.router-view-card
    section.all-clinics
      TableTitle(title="All Clinics")
        template(#actions)
          BaseBtn.x-lg.blue(
            v-if="showManageCapabilities()"
            href="#",
            @click.prevent="openClinicOverlay()"
          ) ADD NEW
      .table
        .th
          .td(@click.prevent="changeListOrder('ClinicName', fetchCHSClinics)" style="cursor: pointer;") CLINIC NAME
            i(style="font-weight: bolder;") {{ ClinicNameColumnSortingIcon }}
          .td(@click.prevent="changeListOrder('Address', fetchCHSClinics)" style="cursor: pointer;") ADDRESS
            i(style="font-weight: bolder;") {{ AddressColumnSortingIcon }}
          .td(@click.prevent="changeListOrder('City', fetchCHSClinics)" style="cursor: pointer;") CITY
            i(style="font-weight: bolder;") {{ CityColumnSortingIcon }}

          .td
          .td
        .tr(v-for="clinic in clinics", :key="clinic.id")
          .td.bold {{clinic?.name}}
          .td {{clinic?.address?.address}}
          .td {{clinic?.address?.place}}{{clinic?.address?.abbreviation ? ', ' + clinic?.address?.abbreviation : "" }}
          .td.no-padding
            BaseBtn.sm.blue-outline(@click.preventDefault="goToClinic(clinic)") SELECT
          .td
            BaseBtn.sm.blue(
              v-if="showManageCapabilities()"
              href="#",
              @click.prevent="openClinicOverlay(clinic.id)"
            ) MANAGE
    Pagination(
      v-if="pagination",
      @changePagination="changePagination",
      :pagination="pagination",
      :reloadFn="fetchCHSClinics"
    )
ClinicOverlay(
  :key="`${componentKey}-1`",
  :clinicId="clinicId",
  :isOpen="isClinicOverlayOpen",
  @closeClinicOverlay="closeClinicOverlay",
  @reloadClinics="fetchCHSClinics"
)
</template>

<script>
import { defineComponent } from "vue";

import { fetchCHS, fetchCHSClinics } from "@/api/chsApi";

import TableTitle from "@/components/TableTitle.vue";
import Pagination from "@/components/Pagination.vue";
import ClinicOverlay from "@/components/all-clinics/overlays/ClinicOverlay.vue";
import listPagesMixin from "@/mixins/listPagesMixin";
import { mapActions } from "vuex";
import sortClinics from "@/mixins/sortClinics";
import { mapGetters } from "vuex";

export default defineComponent({
  components: {
    TableTitle,
    Pagination,
    ClinicOverlay,
  },
  mixins: [listPagesMixin, sortClinics],

  data() {
    return {
      isClinicOverlayOpen: false,
      chsId: this?.$route?.params?.chsId ?? null,
      clinics: null,
      clinicId: null,
    };
  },
  computed: {
    ...mapGetters(["isSuperAdmin", "isClinicHealthSystemManager"]),
  },
  methods: {
    ...mapActions(["goToClinic"]),
    showManageCapabilities() {
      if (this.isSuperAdmin || this.isClinicHealthSystemManager) {
        return true;
      }
      return false;
    },
    async fetchCHSClinics() {
      const order = this.listOrder
        ? {
            by: this.listOrder.by,
            direction: this.listOrder.direction,
          }
        : null;
      try {
        const res = await fetchCHSClinics(this.chsId, {
          params: {
            ...this.reqPagination,
            order,
          },
        });
        this.clinics = res.data.clinics;
        return (this.pagination = res.data.pagination);
      } catch (err) {
        return console.error(err);
      }
    },
    async fetchCHSData() {
      try {
        const { data } = await fetchCHS(this?.$route?.params?.chsId);
        this.$store.commit("COMMIT_PRIMARY_CARE", data.primaryCare);
      } catch (error) {
        console.error(error);
      }
    },
    openClinicOverlay(clinicId) {
      this.clinicId = clinicId ?? null;
      this.componentKey = this.componentKey + 1;
      this.forceRerender();
      this.isClinicOverlayOpen = true;
    },
    closeClinicOverlay() {
      this.isClinicOverlayOpen = false;
    },
  },
  created() {
    if (!this?.$route?.params?.chsId) return;
    this.fetchCHSClinics();
    this.fetchCHSData();
    this.$store.dispatch("clearCurrentClinic");
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/components/step-subtitle.scss";
.all-clinics {
  .td {
    &:nth-child(1) {
      width: math.percentage(math.div(355, $tabContentCardWidth));
    }
    &:nth-child(2) {
      width: math.percentage(math.div(200, $tabContentCardWidth));
    }
    &:nth-child(3) {
      width: math.percentage(math.div(187, $tabContentCardWidth));
    }
    &:nth-child(4) {
      width: math.percentage(math.div(213, $tabContentCardWidth));
    }
    &:nth-child(5) {
      width: math.percentage(math.div(223, $tabContentCardWidth));
    }
  }
}
.content-wrapper {
  position: relative;
  padding-bottom: 40px;
}
.router-view-card {
  padding-top: 0;
  position: relative;
  width: 100%;
  z-index: 1;
}
.table .tr .td.no-padding {
  padding-right: 10px;
  padding-left: 10px;
}
.table .tr > .td {
  display: flex;
  align-items: flex-start;
}
.table .td .btn {
  width: 100%;
}
</style>
