// @ts-nocheck
export default {
  data() {
    return {
      sortings: {
        NoteDate: [
          undefined,
          { by: "date-time", icon: "▲", direction: "asc" },
          { by: "date-time", icon: "▼", direction: "desc" },
        ],
        Status: [
          undefined,
          { by: "status", icon: "▲", direction: "asc" },
          { by: "status", icon: "▼", direction: "desc" },
        ],
      },
      currentSortKey: "NoteDate",
      currentSortIndex: 0,
      listOrder: undefined,
    };
  },

  computed: {
    NoteDateColumnSortingIcon() {
      if (this.currentSortKey === "NoteDate") {
        return this.sortings.NoteDate[this.currentSortIndex]?.icon || "▼▲";
      }
      return "▼▲";
    },
    StatusColumnSortingIcon() {
      if (this.currentSortKey === "Status") {
        return this.sortings.Status[this.currentSortIndex]?.icon || "▼▲";
      }
      return "▼▲";
    },
  },

  methods: {
    changeListOrder(key, callback) {
      if (this.currentSortKey === key) {
        this.updateCurrentSortingIndex(callback);
        return;
      }

      this.currentSortKey = key;
      this.currentSortIndex = 0;
      this.updateCurrentSortingIndex(callback);
    },
    updateCurrentSortingIndex(callback) {
      if (
        this.currentSortIndex ===
        this.sortings[this.currentSortKey].length - 1
      ) {
        this.currentSortIndex = 0;
        this.listOrder = this.sortings[this.currentSortKey][0];
        callback();
        return;
      }

      this.currentSortIndex++;
      this.listOrder =
        this.sortings[this.currentSortKey][this.currentSortIndex];
      return callback();
    },
  },
};
