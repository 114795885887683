// @ts-nocheck
import api from "./api";

const providerPatientReferralUrl = "/provider-patient-referral";
const providerPatientReportUrl = "/report/provider-patient-referral";

export const fetchProviderPatientReferral = (data = {}) => {
  return api.get(providerPatientReferralUrl, data);
};

export const fetchProviderPatientReferralNotifications = (data = {}) => {
  return api.get(providerPatientReferralUrl + "/notifications", data);
};

export const fetchProviderPatientReferralNotificationsCount = (data = {}) => {
  return api.get(
    providerPatientReferralUrl +
      `/notifications/new-count?id=${data.id}&side=${data.side}&`
  );
};

export const fetchProviderPatientReferralById = (
  idProviderPatientReferral,
  data = {}
) => {
  return api.get(
    providerPatientReferralUrl + "/" + idProviderPatientReferral,
    data
  );
};

export const fetchProviderPatientReferralNotes = (data = {}) => {
  return api.get(providerPatientReferralUrl + "/notes", data);
};

export const putPatientAppointmentNotifications = (data = {}) => {
  return api.put(providerPatientReferralUrl + "/notifications", data);
};

export const patchProviderPatientReferralById = (
  idProviderPatientReferral,
  data = {}
) => {
  return api.patch(
    providerPatientReferralUrl + "/" + idProviderPatientReferral,
    data
  );
};

export const createPatientReferral = (data = {}) => {
  return api.post(providerPatientReferralUrl, data);
};

export const createPatientReferraltNote = (data = {}) => {
  return api.post(providerPatientReferralUrl + "/notes", data);
};

export const getOverallPatientReferralReport = (params: {
  timezone: string;
  idProvider?: number;
  idCommunity?: number;
  startDate?: number;
  endDate?: number;
}): Promise<any> => {
  Object.keys(params).forEach(
    (key) => params[key] === undefined && delete params[key]
  );
  return api.get(providerPatientReportUrl + "/overall", { params });
};

export const getFullPatientReferralReport = (params: {
  idProvider?: number;
  idCommunity?: number;
  startDate?: number;
  endDate?: number;
}): Promise<any> => {
  Object.keys(params).forEach(
    (key) => params[key] === undefined && delete params[key]
  );
  return api.get(providerPatientReportUrl + "/full", { params });
};
