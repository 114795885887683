<template lang="pug">
div
  .form-group(:class="{ 'form-group__invalid': v$.inputValue.$errors.length }")
    input(
      type="text" 
      v-model="inputValue" 
      @blur="v$.inputValue.$touch()" 
      @change="sendAnswer(value, $event.target.value)"
    )
    .form-group__errors(v-if="v$.inputValue.$dirty && v$.inputValue.$errors.length")
      .form-group__error(v-if="v$.inputValue.required.$invalid") Field is required!

</template>
<script>
import { defineComponent } from "vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default defineComponent({
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    let args = {
      inputValue: {},
    };
    if (this.value.required) {
      args = {
        inputValue: { required },
      };
    }
    return args;
  },
  data() {
    return {
      inputValue: "",
    };
  },
  methods: {
    sendAnswer(value, eventTarget) {
      this.$emit("emitAnswer", value, eventTarget);
    },
  },
  emits: ["emitAnswer"],

  props: {
    value: {
      type: Object,
      required: true,
    },
    default: {
      type: String,
      required: false,
    },
  },
  watch: {
    default: function (newVal) {
      this.inputValue = newVal;
    },
  },
  created() {
    if (this.value === null) return;
    this.inputValue = this.default;
  },
});
</script>
<style scoped>
.form-group input {
  width: 192px;
}
</style>