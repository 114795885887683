<template lang="pug">
.pagination
  .paginator
    .arrow_left
      a(
        v-if="pagination.page !== 1"
        href="#",
        @click.prevent="previousPage"
      )
        base-icon.mirror(name="chevron-next")
    .paginator__count {{displayCurrentPage}} of {{displayLastPage}}
    .arrow_right
      a(
        v-if="pagination.page !== pagination.lastPage"
        href="#",
        @click.prevent="nextPage"
      )
        base-icon(name="chevron-next")
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  emits: ["changePagination"],

  props: {
    pagination: {
      type: Object,
      required: true,
    },
    reloadFn: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      size: this.pagination.size,
      page: this.pagination.page,
      displayCurrentPage: this.pagination.page,
      displayLastPage: this.pagination.lastPage,
    };
  },
  watch: {
    pagination(val) {
      if (val.lastPage) {
        this.displayLastPage = val.lastPage;
      }
    },
  },
  methods: {
    changePagination() {
      const newPagination = {
        size: this.size,
        page: this.page,
      };
      this.$emit("changePagination", newPagination, this.reloadFn);
    },
    nextPage() {
      this.page = this.page + 1;
      this.displayCurrentPage = this.page;
      this.changePagination();
    },
    previousPage() {
      this.page = this.page - 1;
      this.displayCurrentPage = this.page;
      this.changePagination();
    },
  },
});
</script>
<style scoped lang="scss">
.pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 40px 30px;
  .paginator {
    margin-left: 23px;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.29;
    .arrow_left,
    .arrow_right {
      width: 31px;
    }
    &__count {
      margin: 0 20px;
    }
    .svg-icon--chevron-next {
      top: 0;
      font-size: 31px;
      &.mirror {
        transform: rotate(180deg);
      }
    }
  }
}
</style>
