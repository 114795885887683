import { cloneDeep } from "lodash";
import { fetchClinic, fetchFullClinicDashboard } from "@/api/clinicApi";
import router from "@/router";

interface SpecialtyModule {
  createdAt: number;
  deletedAt: number | null;
  icon: string | null;
  id: number;
  isColonoscopy: boolean;
  isManageCare: boolean;
  isModuleEnabled: boolean;
  marketingTitle: string;
  moduleTitle: string;
  schemaRules: any;
  updatedAt: number | null;
}

interface SpecialtyModuleWrapper {
  appointmentStatus: {
    archives: string;
    completedColonoscopyPendingClinicReview: string;
    confirmed: string;
    requested: string;
  };
  countAvailableSchedule: string;
  iccStats: {
    cancelled: string;
    completed: string;
    notCompleted: string;
  };
  specialtyModule: SpecialtyModule;
}

interface FitRegistry {
  countReferrals: string;
  fitRegistryStats: {
    dueFollowUpKits: string;
    negativeCompletedKits: string;
    newKits: number;
    noResultCompletedKits: string;
    positiveCompletedKits: string;
  };
  fitRegistryStatus: {
    completedArchivedKits: string;
    dueFollowUpKits: string;
    oneYearFollowUpKits: string;
    openKits: string;
    positiveCompletedNeededActionKits: string;
  };
  iccOutsideStats: {
    completed: string;
    pending: string;
  };
  outsideICCAppointments: {
    completed: string;
    pending: string;
  };
}

interface PrimaryCare {
  iccStats: {
    cancelled: string;
    completed: string;
    notCompleted: string;
  };
  referralStatus: {
    requested: string;
    confirmed: string;
    noShowOrCancelled: string;
    archives: string;
  };
}

interface CurrentClinicSpecialtiesState {
  currentClinidId: number;
  showClinicSpecialtiesNavigation: boolean;
  fitRegistry: FitRegistry | null;
  primaryCare: PrimaryCare | null;
  specialties: SpecialtyModuleWrapper[];
  name: string;
}

export default {
  state(): CurrentClinicSpecialtiesState {
    return {
      currentClinidId: -1,
      showClinicSpecialtiesNavigation: false,
      fitRegistry: null,
      primaryCare: null,
      specialties: [],
      name: "",
    };
  },
  getters: {
    showClinicFitRegistry(state: CurrentClinicSpecialtiesState) {
      return state.fitRegistry !== null;
    },
    showClinicPrimaryCare(state: CurrentClinicSpecialtiesState) {
      return state.primaryCare !== null;
    },
    showClinicSpecialties(state: CurrentClinicSpecialtiesState) {
      return state.specialties.length > 0 || state.primaryCare !== null || state.fitRegistry !== null;
    },
    aClinicIsSelected(state: CurrentClinicSpecialtiesState) {
      return state.currentClinidId > 0;
    },
    getClinicSpecialtyModules(state: CurrentClinicSpecialtiesState) {
      return state.specialties;
    },
    getShowClinicDashboardsMainNav(state: CurrentClinicSpecialtiesState) {
      return state.showClinicSpecialtiesNavigation;
    },
    getClinicId(state: CurrentClinicSpecialtiesState) {
      return state.currentClinidId;
    },
    getClinicName(state: CurrentClinicSpecialtiesState) {
      return state.name;
    },
    getSpecialtyById:
      (state: CurrentClinicSpecialtiesState) => (id: number) => {
        return state.specialties.find(
          (specialty) => specialty.specialtyModule.id === id
        );
      },
  },
  mutations: {
    COMMIT_CURRENT_CLINIC(
      state: CurrentClinicSpecialtiesState,
      payload: number
    ) {
      state.currentClinidId = payload;
    },
    COMMIT_FIT_REGISTRY(
      state: CurrentClinicSpecialtiesState,
      payload: FitRegistry
    ) {
      state.fitRegistry = payload;
      state.showClinicSpecialtiesNavigation = true;
    },
    COMMIT_PRIMARY_CARE(
      state: CurrentClinicSpecialtiesState,
      payload: PrimaryCare
    ) {
      state.primaryCare = payload;
      state.showClinicSpecialtiesNavigation = true;
    },
    COMMIT_SPECIALTY_MODULES(
      state: CurrentClinicSpecialtiesState,
      payload: SpecialtyModuleWrapper[]
    ) {
      state.specialties = cloneDeep(payload);
      state.showClinicSpecialtiesNavigation = payload.length > 0;
    },
    COMMIT_CLINIC_NAME(state: CurrentClinicSpecialtiesState, payload: string) {
      state.name = payload;
    },
  },
  actions: {
    async getFullClinicDashboard(
      { commit }: { commit: any },
      clinicId: number
    ) {
      if (clinicId > 0) {
        const clinicinfo = await fetchClinic(clinicId);
        const res = await fetchFullClinicDashboard(clinicId);
        commit("COMMIT_CURRENT_CLINIC", clinicId);
        commit("COMMIT_SPECIALTY_MODULES", res.data.specialties);
        commit("COMMIT_FIT_REGISTRY", res.data.fitRegistry);
        commit("COMMIT_PRIMARY_CARE", res.data.primaryCare);
        //update just in case
        commit("COMMIT_CLINIC_NAME", clinicinfo.data.name);
        localStorage.setItem("currentClinicId", clinicId.toString());
      }
    },
    goToAllClinicsView({ getters }: { getters: any }) {
      router.push({
        name: "all-clinics",
        params: {
          chsId: getters.getUserRoleContext.clinicHealthSystem.id,
        },
      });
    },
    goToLastClinicVisited({
      getters,
      dispatch,
    }: {
      getters: any;
      dispatch: any;
    }) {
      if (getters.getClinicId > 0) {
        router.push({
          name: "dashboard",
          params: { idClinic: getters.getClinicId },
        });
      } else {
        dispatch("goToAllClinicsView");
      }
    },
    goToClinic(
      {
        getters,
        dispatch,
        commit,
      }: { getters: any; dispatch: any; commit: any },
      clinic: any
    ) {
      if (getters.isClinicHealthSystemManager) {
        commit("COMMIT_CURRENT_CLINIC", clinic.id);
        dispatch("impersonateClinicStaff");
      }

      router.push({ name: "dashboard", params: { idClinic: clinic.id } });
    },
    goToUsersClinicHealthSystem({ getters }: { getters: any }) {
      router.push({
        name: "users-clinical-health-systems",
        params: {
          idClinicHealthSystem:
            getters.getUserRoleContext.clinicHealthSystem.id,
        },
      });
    },
    goToClinicSearchPatient({ getters }: { getters: any }) {
      router.push({
        name: "clinics.patient-search",
        params: {
          idClinic: getters.getClinicId,
        },
      });
    },
    clearCurrentClinic({ commit }: { commit: any }) {
      commit("COMMIT_CURRENT_CLINIC", -1);
      commit("COMMIT_SPECIALTY_MODULES", []);
      commit("COMMIT_FIT_REGISTRY", null);
      commit("COMMIT_PRIMARY_CARE", null);
      commit("COMMIT_CLINIC_NAME", "");
      localStorage.removeItem("currentClinicId");
    },
  },
};
