<template lang="pug">
section.sidebar-main
  NavMain()
</template>

<script>
import { defineComponent } from "vue";
import NavMain from "@/components/NavMain";

export default defineComponent({
  components: { NavMain },

  data() {
    return {};
  },
  watch: {
    $route(to, from) {
      this.$store.dispatch("fetchNotificationCount");

      setTimeout(() => {
        this.$store.dispatch("fetchNotificationCount");
      }, 500);

      setTimeout(() => {
        this.$store.dispatch("fetchNotificationCount");
      }, 1000);
    },
  },
});
</script>

<style scoped lang="scss">
.sidebar-main {
  position: fixed;
  backface-visibility: hidden;
  padding: 48px 0;
  top: $headerHeight;
  bottom: 0;
  left: 0;
  background-color: $primaryDarkBlue;
  color: #fff;
  z-index: 99;
  background-image: url("../assets/images/icc-logo-white.svg");
  background-repeat: no-repeat;
  background-size: 277.22px 445.03px;
  background-position: -87px calc(100% + 93px);
  // background-size: contain;
  &::after {
    content: "";
    display: block;
    height: $headerHeight;
    width: 60px;
    position: absolute;
    background-image: url("../assets/images/sidebar-corner.svg");
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: right top;
    background-size: contain;
    top: 0;
    left: 100%;
  }
}
</style>
