<template lang="pug">
TableTitle(
  title="Ongoing Care",
  searchPlaceholder="Search by patient name or hospital"
)
.table
  .th
    .td Patient Name
    .td Hospital
    .td Doctor
    .td Last Appt
    .td Next Appt
    .td On Track
  .tr(
    v-for="index in 10",
    :key="index",
    @click="openManageCareOverlay"
  )
    .td.bold John Doe
    .td Michigan Hospital <br>Surgery Center
    .td Dr. Joseph Bepolinguan
    .td
      div 07-30-2020
      .second-row-md-grey 6:30 PM
    .td
      div 07-30-2020
      .second-row-md-grey 6:30 PM
    .td
      base-icon(name="yes")
Pagination(v-if="pagination")

ManageCareOverlay(
  :isOpen="isManageCareOverlayOpen",
  @closeManageCareOverlay="closeManageCareOverlay",
  @openAppointmentRecordOverlay="openAppointmentRecordOverlay"
)
AppointmentRecordOverlay(
  :isOpen="isAppointmentRecordOverlayOpen",
  @closeAppointmentRecordOverlay="closeAppointmentRecordOverlay",
  @openAppointmentNotesOverlay="openAppointmentNotesOverlay"
)
AppointmentNotesOverlay(
  :isOpen="isAppointmentNotesOverlayOpen",
  @closeAppointmentNotesOverlay="closeAppointmentNotesOverlay"
)
</template>

<script>
import { defineComponent } from "vue";
import TableTitle from "@/components/TableTitle.vue";
import DateSelector from "@/components/DateSelector.vue";
import Pagination from "@/components/Pagination.vue";
import ManageCareOverlay from "@/components/overlays/side-overlays/ManageCareOverlay.vue";
import AppointmentRecordOverlay from "@/components/overlays/full-screen-overlays/AppointmentRecordOverlay.vue";
import AppointmentNotesOverlay from "@/components/overlays/side-overlays/AppointmentNotesOverlay.vue";

export default defineComponent({
  components: {
    TableTitle,
    DateSelector,
    Pagination,
    ManageCareOverlay,
    AppointmentRecordOverlay,
    AppointmentNotesOverlay,
  },

  data() {
    return {
      isManageCareOverlayOpen: false,
      isAppointmentRecordOverlayOpen: false,
      isAppointmentNotesOverlayOpen: false,
    };
  },

  methods: {
    openManageCareOverlay() {
      this.isManageCareOverlayOpen = true;
    },
    closeManageCareOverlay() {
      this.isManageCareOverlayOpen = false;
    },

    openAppointmentRecordOverlay() {
      this.isAppointmentRecordOverlayOpen = true;
    },
    closeAppointmentRecordOverlay() {
      this.isAppointmentRecordOverlayOpen = false;
    },

    openAppointmentNotesOverlay() {
      this.isAppointmentNotesOverlayOpen = true;
    },
    closeAppointmentNotesOverlay() {
      this.isAppointmentNotesOverlayOpen = false;
    },
  },
});
</script>

<style scoped lang="scss">
.tr {
  cursor: pointer;
}
.td {
  &:nth-child(1) {
    width: math.percentage(math.div(249, $tabContentCardWidth));
  }
  &:nth-child(2) {
    width: math.percentage(math.div(249, $tabContentCardWidth));
  }
  &:nth-child(3) {
    width: math.percentage(math.div(250, $tabContentCardWidth));
  }
  &:nth-child(4) {
    width: math.percentage(math.div(160, $tabContentCardWidth));
  }
  &:nth-child(5) {
    width: math.percentage(math.div(160, $tabContentCardWidth));
  }
  &:nth-child(6) {
    width: math.percentage(math.div(110, $tabContentCardWidth));
  }
}
</style>
