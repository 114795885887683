<template lang="pug">
PopupCardSlot(
  :isOpen="isOpen",
  maxWidth="675px"
)
  h1.title.title--md For your security, your session is about to expire due to inactivity.
  .countdown-popup
    .counter {{minutesRemainingString}}:{{secondsRemainingString}}
    BaseBtn.x-lg.blue(href="#", @click.prevent="renewSession") RESUME SESSION
</template>

<script>
import { defineComponent } from "vue";
import PopupCardSlot from "@/components/PopupCardSlot.vue";
import { mapActions, mapGetters } from "vuex";
import { renewSession } from "@/api/accountApi";

export default defineComponent({
  components: { PopupCardSlot },

  computed: {
    ...mapGetters(["getMinutesRemaining", "getSecondsRemaining"]),
    minutesRemainingString() {
      if (this.getMinutesRemaining < 10) {
        return "0" + this.getMinutesRemaining;
      }

      return this.getMinutesRemaining.toString();
    },
    secondsRemainingString() {
      if (this.getSecondsRemaining < 10) {
        return "0" + this.getSecondsRemaining;
      }

      return this.getSecondsRemaining.toString();
    },
  },

  data() {
    return {
      isOpen: true,
      interval: null,
    };
  },

  methods: {
    ...mapActions([
      "logOut",
      "showSessionCountdownModal",
      "showSessionExpiredModal",
    ]),
    async renewSession() {
      clearInterval(this.interval);
      try {
        await renewSession();
        this.$store.commit("COMMIT_LAST_GONE_TIMESTAMP", 0);
        this.$store.commit("COMMIT_LAST_IDLE_TIMESTAMP", 0);
        this.$store.commit("COMMIT_MINUTES_REMAINING", 3);
        this.$store.commit("COMMIT_SECONDS_REMAINING", 0);
        this.showSessionCountdownModal(false);
      } catch (error) {
        console.error(error);
        this.logOut();
        this.showSessionExpiredModal(true);
        this.showSessionCountdownModal(false);
      }
    },
    beginTimer() {
      this.interval = setInterval(() => {
        if (this.getSecondsRemaining === 0 && this.getMinutesRemaining > 0) {
          this.$store.commit("COMMIT_SECONDS_REMAINING", 59);
          this.$store.commit(
            "COMMIT_MINUTES_REMAINING",
            this.getMinutesRemaining - 1
          );
        } else if (
          this.getMinutesRemaining === 0 &&
          this.getSecondsRemaining === 0
        ) {
          clearInterval(this.interval);
          this.logOut();
          this.showSessionExpiredModal(true);
          this.showSessionCountdownModal(false);
        } else {
          this.$store.commit(
            "COMMIT_SECONDS_REMAINING",
            this.getSecondsRemaining - 1
          );
        }
      }, 1000);
    },
  },

  created() {
    this.beginTimer();
  },

  unmounted() {
    clearInterval(this.interval);
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/components/actions-below-table-container.scss";
@import "@/scss/components/step-subtitle.scss";
.schedule-confirm {
  padding-left: 30px;
  padding-right: 30px;
}

.title--md {
  text-align: center;
  margin-bottom: 23px;
}
.countdown-popup {
  text-align: center;
  position: relative;

  .counter {
    font-size: 1.7rem;
    margin-bottom: 1rem;
  }

  header {
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 36px;
    padding-bottom: 28px;
    &::after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0;
      left: -35px;
      right: -35px;
      height: 1px;
      background-color: $primaryBgDarkGrey;
    }
    h1 {
      margin: 0;
      font-size: 36px;
      font-weight: 700;
      line-height: 1.22;
      letter-spacing: 0.2px;
      color: $secondaryGreen;
      .svg-icon--checkmark {
        margin-left: 20px;
        font-size: 44px;
      }
    }
  }
  .popup-content {
    & > h2 {
      margin-top: 0;
      margin-bottom: 47px;
      font-size: 28px;
      font-weight: 700;
      line-height: 1.21;
      letter-spacing: 0.14px;
    }
    .confirmation-info {
      margin-bottom: 27px;
    }
  }
  footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      margin: 0;
      font-size: 21px;
      font-weight: 700;
      line-height: 1.19;
      letter-spacing: 0.11px;
      color: $secondaryGreen;
      strong {
        color: #000000;
        text-decoration: underline;
      }
      .svg-icon {
        top: 0;
        margin-left: 22px;
        font-size: 33px;
      }
    }
  }
}
</style>
