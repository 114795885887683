export const BULK_PATIENT_UPLOAD_FIELDS = {
  "Id Clinic": {
    jsonField: "idClinic",
    type: "number",
    required: true,
  },
  MRN: {
    jsonField: "mrn",
    type: "string",
  },
  "Patient Name": {
    jsonField: "first",
    type: "string",
  },
  "Patient last name": {
    jsonField: "last",
    type: "string",
  },
  "Date of birth": {
    jsonField: "dob",
    type: "date",
    isUTC: false,
    format: "MMDDYYYY",
  },
  "Home Phone Number": {
    jsonField: "contact.homePhone",
    type: "number",
    length: 10,
  },
  "Home Phone Number Ext": {
    jsonField: "contact.homePhoneExtension",
    type: "number",
    required: false,
  },
  "Cell Phone Number": {
    jsonField: "contact.cellPhone",
    type: "number",
    length: 10,
  },
  "Cell Phone Number Ext": {
    jsonField: "contact.cellPhoneExtension",
    type: "number",
    required: false,
  },
  "Home Address": {
    jsonField: "address.address",
    type: "string",
  },
  "Zip Code": {
    jsonField: "address.zipCode",
    type: "number",
    length: 5,
  },
  "Patient Notes": {
    jsonField: "notes",
    type: "string",
  },
};

export const BULK_PATIENT_UPLOAD_TEMPLATE_DATA = [
  {
    "Id Clinic": 36,
    MRN: 18603,
    "Patient Name": "Craig",
    "Patient last name": "Farmer",
    "Date of birth": "4/13/1953",
    "Home Phone Number": "4626269119",
    "Home Phone Number Ext": 830,
    "Cell Phone Number": "6654748535",
    "Cell Phone Number Ext": 922,
    "Home Address": "972 Najo Pike",
    "Zip Code": "69103",
    "Patient Notes":
      "Alhuasi ecsuzu vo di olosude mab jacperob fa odumu eheheuka emro matmo veg ca.",
  },
  {
    "Id Clinic": 36,
    MRN: 19119,
    "Patient Name": "Nell",
    "Patient last name": "Ray",
    "Date of birth": "11/12/1954",
    "Home Phone Number": "2234375756",
    "Home Phone Number Ext": 387,
    "Cell Phone Number": "6564747332",
    "Cell Phone Number Ext": 550,
    "Home Address": "257 Ijci Lane",
    "Zip Code": "10304",
    "Patient Notes":
      "Lu likdekvud ifocoz fawgokem amgokjaz wef ere jicvu hu nonu tighub nos va waoliezo.",
  },
  {
    "Id Clinic": 36,
    MRN: 18416,
    "Patient Name": "Charlotte",
    "Patient last name": "Obrien",
    "Date of birth": "11/18/1946",
    "Home Phone Number": "8288597344",
    "Home Phone Number Ext": 578,
    "Cell Phone Number": "4747363136",
    "Cell Phone Number Ext": 622,
    "Home Address": "1468 Ijilor Place",
    "Zip Code": "55576",
    "Patient Notes":
      "Winkifar oweno wetunjoj mucfed ikva mowlan rucu rudolo vup vuvrez seh sifpe du pa pa.",
  },
  {
    "Id Clinic": 36,
    MRN: 10573,
    "Patient Name": "Howard",
    "Patient last name": "Clayton",
    "Date of birth": "5/19/1964",
    "Home Phone Number": "7594497327",
    "Home Phone Number Ext": 388,
    "Cell Phone Number": "3539802441",
    "Cell Phone Number Ext": 696,
    "Home Address": "591 Liltuc Avenue",
    "Zip Code": "94995",
    "Patient Notes":
      "Hibhevag no jur guzuhzig ca ju oreuwuwus tirvo terwir dukzosnur nakawle pakohu ine witi kek.",
  },
  {
    "Id Clinic": 36,
    MRN: 19969,
    "Patient Name": "Jeffrey",
    "Patient last name": "Spencer",
    "Date of birth": "7/30/1972",
    "Home Phone Number": "9468884455",
    "Home Phone Number Ext": 487,
    "Cell Phone Number": "6812814101",
    "Cell Phone Number Ext": 305,
    "Home Address": "1000 Hiwib Square",
    "Zip Code": "52132",
    "Patient Notes":
      "Oraedoh vewaaro kib pobwuf fopbaob bara hiczitjop oferam befrifi diflor ruswu jewot ijvonced abahu om ecdohi.",
  },
  {
    "Id Clinic": 36,
    MRN: 16195,
    "Patient Name": "Beatrice",
    "Patient last name": "Lindsey",
    "Date of birth": "5/22/1969",
    "Home Phone Number": "2258963790",
    "Home Phone Number Ext": 155,
    "Cell Phone Number": "4397952141",
    "Cell Phone Number Ext": 85,
    "Home Address": "1760 Puso View",
    "Zip Code": "79525",
    "Patient Notes":
      "Jino fuoworuw owemoj conwauko janpor ruhut fubuza fesiv infedmeb ofuga da ruf odcungeh nuzwac teopawo tizifoh eci kibowam.",
  },
  {
    "Id Clinic": 36,
    MRN: 19635,
    "Patient Name": "Melvin",
    "Patient last name": "Knight",
    "Date of birth": "4/15/1974",
    "Home Phone Number": "5728163486",
    "Home Phone Number Ext": 403,
    "Cell Phone Number": "2076136749",
    "Cell Phone Number Ext": 566,
    "Home Address": "1221 Luoj View",
    "Zip Code": "75070",
    "Patient Notes":
      "Haj unfaeh kohesu givev koadril ida ikma hohodudu ako di beoze jurse noc.",
  },
  {
    "Id Clinic": 36,
    MRN: 13520,
    "Patient Name": "Theodore",
    "Patient last name": "Maldonado",
    "Date of birth": "9/9/1967",
    "Home Phone Number": "8159943057",
    "Home Phone Number Ext": 200,
    "Cell Phone Number": "5449593474",
    "Cell Phone Number Ext": 606,
    "Home Address": "403 Imvu Avenue",
    "Zip Code": "26511",
    "Patient Notes":
      "Numono gaot bonwi hov udview cok co vuclelo he bi omafanfud wanecfu ja.",
  },
  {
    "Id Clinic": 36,
    MRN: 18240,
    "Patient Name": "Luella",
    "Patient last name": "Sherman",
    "Date of birth": "3/23/1974",
    "Home Phone Number": "6055701881",
    "Home Phone Number Ext": 636,
    "Cell Phone Number": "4862157917",
    "Cell Phone Number Ext": 834,
    "Home Address": "1242 Morlu Manor",
    "Zip Code": "78964",
    "Patient Notes":
      "Seji sel bo rewifpon wo pihocim loefo wokabhus paufe waw jifgada keta vo fela rif ja rojmucgor.",
  },
  {
    "Id Clinic": 36,
    MRN: 10722,
    "Patient Name": "Evan",
    "Patient last name": "Wheeler",
    "Date of birth": "12/29/1975",
    "Home Phone Number": "5183561572",
    "Home Phone Number Ext": 602,
    "Cell Phone Number": "3874983521",
    "Cell Phone Number Ext": 446,
    "Home Address": "243 Vihi Pike",
    "Zip Code": "93969",
    "Patient Notes":
      "Upa koze fodava je ro sod povlun nozuvu tetur woc ivruh lumvawu dijcuzu lanatopa esnusri.",
  },
];

export const ERROR_MESSAGE = "Unexpected error, Bulk Upload not executed";
export const AUTH_ERROR_STATUS = 401;
export const AUTH_ERROR_MESSAGE = "Not authorized to do this action";
