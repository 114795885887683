<template lang="pug">
.card(v-if="notifications", style="padding-bottom: 40px;")
  TableTitle(title="Notifications",
    searchPlaceholder="Search by patient name"
    v-model="searchKeyword",
    @doSearch="fetchNotification",
    @blur="fetchNotification"
  )
    template(#actions)
      select.table-action-selector.table-action-selector--bell(
        v-model="selectedFilter"
        @change="setFilterValue($event.target.value)"
      )
        option(value="New") NEW ONLY
        option(value="Completed") COMPLETED
        option(value="Clinic") CLINIC
        option(value="Hospital") HOSPITAL
        option(value="Community") COMMUNITY
        option(value="All") VIEW ALL
      DateSelector(@filterByDate="filterByDate")

  .table.table--hoverable
    .th
      .td(@click.prevent="changeListOrder('NoteDate', fetchNotification)" style="cursor: pointer;") NOTE DATE
        i(style="font-weight: bolder;") {{ NoteDateColumnSortingIcon }}
      .td Patient Name
      .td TYPE
      .td(@click.prevent="changeListOrder('Status', fetchNotification)" style="cursor: pointer;") LAST ACTIVITY
        i(style="font-weight: bolder;") {{ StatusColumnSortingIcon }}
      .td DONE
    .tr(v-for="notification in notifications", :key="notification.id", @click="openAppointmentNotesOverlay(notification)")
      .td
        div {{unixToUTCDate(notification)}} 
        .second-row-md-grey {{unixToUTCTime(notification)}}
      .td.bold  {{ getUserFormatted(notification.patient)}}
      .td
        div(:class="{'red': notification.type === 'Cancelled'}") {{notification.type}}
        .second-row-md-grey {{ notification.specialtyModule?.moduleTitle }}
      .td
        div(
          :class="isCompleted(notification) ? 'green':'red'"
        ) {{isCompleted(notification) ? `Completed by ${notification.completedBy.first} ${notification.completedBy.last}` : `Not Completed` }}
        .second-row-sm-grey {{isViewed(notification) ? `Last viewed by ${notification?.lastViewedBy?.first} ${notification?.lastViewedBy?.last}` : `Not viewed`}}
      .td
        base-icon(:name="isCompleted(notification) ? 'yes': 'no'")
  Pagination(
    v-if="pagination && notifications != null && notifications.length",
    @changePagination="changePagination",
    :pagination="pagination",
    :reloadFn="fetchNotification"
  )
  .no-results(v-if="notifications != null && !notifications.length") 
    base-icon(name="exclamation") 
    span No matching records

AppointmentNotesOverlay(
  :key="`${componentKeyForAppointment}-1`",
  :isOpen="isAppointmentNotesOverlayOpen",
  @closeAppointmentNotesOverlay="closeAppointmentNotesOverlay"
  @fetchPatientAppointmentNotifications="fetchNotification"
  @openViewAppointment="fetchPatientAppointmentById"
  :patientAppointment="sendNotification"
  showAppointmentsFor="clinics"
)
ReferralNotesOverlay(
  :key="`${componentKeyForReferral}-1`",
  :isOpen="isReferralNotesOverlayOpen",
  @closeReferralNotesOverlay="closeReferralNotesOverlay"
  @fetchNotifications="fetchNotification"
  @openViewAppointment="fetchPatientReferralById"
  :patientAppointment="sendNotification"
  showAppointmentsFor="clinics"
)

ClinicAppointmentRecordOverlay(
  :key="`${componentKey}-1`",
  :isOpen="isClinicAppointmentRecordOverlayOpen",
  tabType="displayOnly"
  :showAppointment="showAppointmentClinic"
  @closeClinicAppointmentRecordOverlay="closeClinicAppointmentRecordOverlay",
)
ReferralRecordOverlay(
  :key="`${componentKey}-1`",
  :isOpen="isReferralRecordOverlayOpen",
  tabType="displayOnly"
  :showReferral="showReferral",
  @closeReferralRecordOverlay="closeReferralRecordOverlay",
)
</template>

<script>
import { defineComponent } from "vue";
import moment from "moment";
import { getUserFormatted } from "@/helpers";
import TableTitle from "@/components/TableTitle.vue";
import DateSelector from "@/components/DateSelector.vue";
import Pagination from "@/components/Pagination.vue";
import listPagesMixin from "@/mixins/listPagesMixin";
import AppointmentNotesOverlay from "@/components/overlays/side-overlays/AppointmentNotesOverlay.vue";
import ReferralNotesOverlay from "@/components/overlays/side-overlays/ReferralNotesOverlay.vue";
import ReferralRecordOverlay from "@/components/primary-care-clinic/patient-referrals/overlays/ReferralRecordOverlay";

import { fetchPatientAppointmentById } from "@/api/patientAppointmentApi";
import { fetchPatientReferralById } from "@/api/patientReferral";

import { fetchNotification } from "@/api/notifications.ts";

import ClinicAppointmentRecordOverlay from "@/components/clinics/appointment-status/overlays/ClinicAppointmentRecordOverlay.vue";
import { apiStatus, apiStatusComputedFactory } from "@/api";
const { IDLE, PENDING, SUCCESS, ERROR } = apiStatus;
import { mapGetters } from "vuex";
import sortNotifications from "@/mixins/sortNotifications";

export default defineComponent({
  components: {
    TableTitle,
    DateSelector,
    ReferralRecordOverlay,
    Pagination,
    AppointmentNotesOverlay,
    ReferralNotesOverlay,
    ClinicAppointmentRecordOverlay,
  },
  mixins: [listPagesMixin, sortNotifications],
  computed: {
    // ...mapGetters(["userRoleContext"]),
  },
  data() {
    return {
      componentKeyForAppointment: 0,
      componentKeyForReferral: 0,
      isReferralNotesOverlayOpen: false,
      isAppointmentNotesOverlayOpen: false,
      notifications: null,
      searchKeyword: "",
      pagination: null,
      status: "New",
      date: null,
      sendNotification: null,
      isClinicAppointmentRecordOverlayOpen: false,
      isReferralRecordOverlayOpen: false,
      fetchPatientAppointmentByIdStatus: IDLE,
      fetchPatientReferralByIdStatus: IDLE,
      showAppointmentClinic: null,
      showReferral: null,
      origin: null,
      isClinicFilter: false,
      selectedFilter: null,
    };
  },

  methods: {
    getUserFormatted,
    async fetchPatientReferralById(referralId) {
      this.fetchPatientReferralByIdStatus = PENDING;
      try {
        const res = await fetchPatientReferralById(referralId, {
          params: {
            include: [
              "communityCare",
              "clinicDoctor",
              "communityReport",
              "cancelled",
              "clinic",
              "clinicReport",
              "emergencyContact",
              "insurance",
              "patient",
              "referringDoctor",
            ],
          },
        });
        let referral = res.data.patientReferral;
        this.openClinicReferralRecordOverlay(referral);
        this.fetchPatientReferralByIdStatus = SUCCESS;
      } catch (err) {
        this.fetchPatientReferralByIdStatus = ERROR;
        console.error(err);
      }
    },
    openClinicReferralRecordOverlay(referral) {
      this.componentKey = this.componentKey + 1;
      this.showReferral = referral;
      this.isReferralRecordOverlayOpen = true;
    },
    async fetchPatientAppointmentById(appointmentId) {
      this.fetchPatientAppointmentByIdStatus = PENDING;
      try {
        const res = await fetchPatientAppointmentById(appointmentId, {
          params: {
            include: [
              "clinic",
              "clinicReport",
              "emergencyContact",
              "hospital",
              "hospitalDoctor",
              "hospitalReport",
              "insurance",
              "pathologyReport",
              "patient",
              "referringDoctor",
            ],
          },
        });
        let appointment = res.data.patientAppointment;
        this.openClinicAppointmentRecordOverlay(appointment);

        this.fetchPatientAppointmentByIdStatus = SUCCESS;
      } catch (err) {
        this.fetchPatientAppointmentByIdStatus = ERROR;
        console.error(err);
      }
    },
    openClinicAppointmentRecordOverlay(appointment) {
      this.componentKey = this.componentKey + 1;
      this.showAppointmentClinic = appointment;
      this.isClinicAppointmentRecordOverlayOpen = true;
    },
    closeReferralRecordOverlay() {
      this.isReferralRecordOverlayOpen = false;
    },
    closeClinicAppointmentRecordOverlay() {
      this.isClinicAppointmentRecordOverlayOpen = false;
    },
    openAppointmentNotesOverlay(sendNotification) {
      let sendPatientInfo = {
        patient: sendNotification.patient,
        id: sendNotification.idEntity,
      };
      this.sendNotification = sendPatientInfo;

      if (sendNotification.patientReferral) {
        this.componentKeyForReferral++;
        this.isReferralNotesOverlayOpen = true;
      } else {
        this.componentKeyForAppointment++;
        this.isAppointmentNotesOverlayOpen = true;
      }
    },
    closeAppointmentNotesOverlay() {
      this.isAppointmentNotesOverlayOpen = false;
      this.fetchNotification();
    },
    closeReferralNotesOverlay() {
      this.isReferralNotesOverlayOpen = false;
    },

    setFilterValue(value) {
      this.status = null;
      this.origin = null;
      this.isClinicFilter = false;
      if (value === "New" || value === "Completed") {
        this.status = value;
      } else {
        if (value === "Hospital") {
          this.origin = "Hospital";
        } else if (value === "Community") {
          this.origin = "Community";
        } else if (value === "Clinic") {
          this.isClinicFilter = true;
        }
      }
      this.fetchNotification();
    },
    filterByDate(date) {
      this.date = date;
      this.fetchNotification();
    },

    async fetchNotification() {
      const order = this.listOrder
        ? {
            by: this.listOrder.by,
            direction: this.listOrder.direction,
          }
        : null;
      try {
        if (!this.isClinicFilter) {
          let res = await fetchNotification({
            params: {
              ...this.reqPagination,
              order,
              status: this.status ?? null,
              destination: "Clinic",
              destinationId: localStorage.getItem("currentClinicId"),
              origin: this.origin,
              searchKeyword: this.searchKeyword || null,
              createdAt: this.date ?? null,
              include: ["completedBy", "lastViewedBy", "patient"],
            },
          });
          this.notifications = res.data.notifications;
          this.pagination = res.data.pagination;
        } else {
          let res = await fetchNotification({
            params: {
              ...this.reqPagination,
              order,
              status: this.status ?? null,
              origin: "Clinic",
              originId: localStorage.getItem("currentClinicId"),
              searchKeyword: this.searchKeyword || null,
              createdAt: this.date ?? null,
              include: ["completedBy", "lastViewedBy", "patient"],
            },
          });
          this.notifications = res.data.notifications;
          this.pagination = res.data.pagination;
        }
      } catch (error) {
        console.error(error);
      }
    },

    unixToUTCDate(notification) {
      return moment.unix(notification.createdAt).format("MM-DD-YYYY");
    },
    unixToUTCTime(notification) {
      return moment.unix(notification.createdAt).format("hh:mm A");
    },
    isCompleted(notification) {
      if (notification?.completedAt) return true;
      return false;
    },
    isViewed(notification) {
      if (notification?.lastViewedBy?.email) return true;
      return false;
    },
  },
  created() {
    if (this.$route.params?.isViewAll) {
      this.selectedFilter = "All";
      this.status = null;
      this.origin = null;
      this.isClinicFilter = false;
      this.fetchNotification();
    } else {
      this.selectedFilter = "New";
      this.fetchNotification();
    }
  },
});
</script>

<style scoped lang="scss">
.tr {
  cursor: pointer;
}
.td {
  &:nth-child(1) {
    width: 15%;
  }
  &:nth-child(2) {
    width: 18%;
  }
  &:nth-child(3) {
    width: 20%;
  }
  &:nth-child(4) {
    width: 40%;
  }
  &:nth-child(5) {
    width: 7%;
  }
}
</style>
