<template lang="pug">
section.community-organizations
  TableTitle(title="Community Organizations")
    template(#actions)
      BaseBtn.x-lg.blue(
        href="#",
        @click.prevent="openCommunityOrganizationsOverlay()"
      ) ADD NEW
  .table
    .th
      .td(@click.prevent="changeListOrder('CCOName', fetchCommunityCareOrganizations)" style="cursor: pointer;") Community organization name
        i(style="font-weight: bolder;") {{ CCONameColumnSortingIcon }}
      .td
      .td
    .tr(v-for="communityOrganization in communityCareOrganizations", :key="communityOrganization.id")
      .td.bold {{communityOrganization.name}}
      .td
        BaseBtn.sm.blue-outline(
          @click.prevent="selectCommunityCareOrganization(communityOrganization)"
        ) SELECT
      .td
        BaseBtn.sm.blue(
          @click.prevent="openCommunityOrganizationsOverlay(communityOrganization.id)"
        ) MANAGE
  Pagination(
    v-if="pagination",
    :pagination="pagination",
    @changePagination="changePagination"
    :reloadFn="fetchCommunityCareOrganizations"
  )

  CommunityOrganizationsOverlay(
    :isOpen="isCommunityOrganizationsOverlayOpen",
    :ccoId="ccoId"
    :CommunityOrganizationsOverlayType="CommunityOrganizationsOverlayType",
    @closeCommunityOrganizationsOverlay="closeCommunityOrganizationsOverlay",
  )
</template>

<script>
import { defineComponent } from "vue";
import TableTitle from "@/components/TableTitle.vue";
import Pagination from "@/components/Pagination.vue";
import listPagesMixin from "@/mixins/listPagesMixin";
import CommunityOrganizationsOverlay from "@/components/organizations/overlays/CommunityOrganizationsOverlay.vue";
import { apiStatus, apiStatusComputedFactory } from "@/api";
const { IDLE, PENDING, SUCCESS, ERROR } = apiStatus;
import sortCCO from "@/mixins/sortCCO";

import { fetchCommunityCareOrganizations } from "@/api/communityOrganization";
import { mapActions } from "vuex";
import { userRoles } from "@/store/account";

export default defineComponent({
  components: {
    TableTitle,
    Pagination,
    CommunityOrganizationsOverlay,
  },

  mixins: [listPagesMixin, sortCCO],

  data() {
    return {
      fetchCommunityCareOrganizationStatus: IDLE,
      isCommunityOrganizationsOverlayOpen: false,
      CommunityOrganizationsOverlayType: "manage",
      ccoId: null,
      communityCareOrganizations: [],
    };
  },

  computed: {
    ...apiStatusComputedFactory("fetchCommunityCareOrganizationStatus"),
  },

  methods: {
    ...mapActions(["getCCOinfo", "impersonate"]),
    openCommunityOrganizationsOverlay(ccoId) {
      if (ccoId) {
        this.CommunityOrganizationsOverlayType = "manage";
        this.ccoId = ccoId;
      } else {
        this.CommunityOrganizationsOverlayType = "new";
      }
      this.isCommunityOrganizationsOverlayOpen = true;
    },
    closeCommunityOrganizationsOverlay() {
      this.isCommunityOrganizationsOverlayOpen = false;
      this.fetchCommunityCareOrganizations();
      this.ccoId = null;
    },
    async fetchCommunityCareOrganizations() {
      this.fetchCommunityCareOrganizationStatus = PENDING;

      const order = this.listOrder
        ? {
            by: this.listOrder.by,
            direction: this.listOrder.direction,
          }
        : null;

      try {
        const { data } = await fetchCommunityCareOrganizations({
          params: {
            ...this.reqPagination,
            order,
          },
        });
        this.communityCareOrganizations = data.communityCareOrganizations;
        this.pagination = data.pagination;
        this.fetchCommunityCareOrganizationStatus = SUCCESS;
      } catch (err) {
        this.fetchCommunityCareOrganizationStatus = ERROR;
        console.error(err);
      }
    },
    async selectCommunityCareOrganization(communityCareOrganization) {
      await this.getCCOinfo(communityCareOrganization);
      this.impersonate({
        role: userRoles.communitycareorganization.find(
          (role) => role.name === "Community Care Organization Manager"
        ),
        communityCareOrganization,
      });
    },
  },

  created() {
    this.fetchCommunityCareOrganizations();
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/components/step-subtitle.scss";
.community-organizations {
  .td {
    &:nth-child(1) {
      width: math.percentage(math.div(744, $tabContentCardWidth));
    }
    &:nth-child(2) {
      width: math.percentage(math.div(212, $tabContentCardWidth));
    }
    &:nth-child(3) {
      width: math.percentage(math.div(222, $tabContentCardWidth));
    }
  }
}
.table .tr .td:not(:last-child):not(.bold) {
  padding-right: 10px;
  padding-left: 10px;
}
.table .td .btn {
  width: 100%;
}
.table .tr > .td {
  display: flex;
  align-items: center;
}
</style>
