<template lang="pug">
.content-wrapper
  .card.router-view-card
    section.all-clinics
      TableTitle(title="Reports")
    
    .columns.is-variable
      .column.is-one-quarter(v-if="!isProviderManager")
        .form-group
          label(for="specialty") Module
          select#specialty(v-model="selectedSpecialty" :disabled="aClinicIsSelected")
            option(
              v-if="!(aClinicIsSelected || aCCOIsSelected)"
              v-for="specialty in specialtyModules"
              :key="specialty.id"
              :value="specialty.id"
            ) {{specialty.moduleTitle}}
            option(value="fit" v-if="!aHospitalIsSelected") FIT
            option(value="primary-care" v-if="!aHospitalIsSelected && !aClinicIsSelected") Primary Care
            option(value="patient-referrals") Patient Referrals
      .column.is-one-quarter(v-if="showFitSideDropdown")
        .form-group
          label(for="specialty") Side
          select#specialty(v-model="selectedFitSide")
            option(value="chs") Clinic Health Systems
            option(value="community") Community Care Organizations
      .column.is-one-quarter(v-if="showHospitalsDropdown")
        .form-group
          label(for="hospitals") Hospitals
          select#hospitals(v-model="selectedHospital")
            option(:value="null") All Hospitals
            option(
              v-for="hospital in hospitals"
              :key="hospital.id"
              :value="hospital.id"
            ) {{hospital.name}}
      .column.is-one-quarter(v-if="showAllCommunitiesDropdown")
        .form-group
          label(for="communities") Communities
          select#clinics(v-model="selectedCommunity")
            option(:value="null") All Communities
            option(
              v-for="community in communities"
              :key="community.id"
              :value="community.id"
            ) {{community.name}}
      .column.is-one-quarter(v-if="showProvidersDropdown")
        .form-group
          label(for="providers") Providers
          select#providers(v-model="selectedProvider")
            option(:value="null") All Providers
            option(
              v-for="provider in showProviders"
              :key="provider.id_provider"
              :value="provider.id_provider"
            ) {{provider.first}} {{provider.last}}
      .column.is-one-quarter(v-if="showClinicHealthSystemsDropwdown")
        .form-group
          label(for="clinic-health-systems") Clinic Health Systems
          select#clinics(v-model="selectedClinicHealthSystem")
            option(:value="null") All Clinic Health Systems
            option(
              v-for="clinicHealthSystem in clinicHealthSystems"
              :key="clinicHealthSystem.id"
              :value="clinicHealthSystem.id"
            ) {{clinicHealthSystem.name}}
      .column.is-one-quarter(v-if="selectedClinicHealthSystem && !aClinicIsSelected")
        .form-group
          label(for="clinic-health-systems") All Clinics
          select#clinics(v-model="selectedClinic")
            option(:value="null") All Clinics
            option(
              v-for="clinic in clinics"
              :key="clinic.id"
              :value="clinic.id"
            ) {{clinic.name}}
        
    .flex.flex--space-between
      .hr.hr--thin
      RangeSelector(
        @filterByDate="filterByDate" 
        :selectedStart="selectedStart"
        :selectedEnd="selectedEnd"
      )
    .print-area
      .chart_wrapper
        .title {{chartTitle}}
        <vue3-chart-js :id="chart.id" ref="chartRef" :type="chart.type" :data="chart.data" :options="chart.options" ></vue3-chart-js>
      SpecialtyDataTable(
        v-if="selectedSpecialty !== 'fit' && selectedSpecialty !== 'primary-care' && selectedSpecialty !== 'patient-referrals' && !isProviderManager "
        :reportData="reportData"
      )
      FitDataTable(
        v-if="selectedSpecialty === 'fit'"
        :reportData="reportData"
      )
      PrimaryCareDataTable(
        v-if="selectedSpecialty === 'primary-care'"
        :reportData="reportData"
      )
      
      ProviderDataTable(
        v-if="selectedSpecialty === 'patient-referrals'"
        :reportData="reportData"
      )

      

    .flex.flex--flex-end
      BaseBtn.x-lg.blue-outline(href="#" @click.prevent="print") PRINT
      BaseBtn.btn-margin-left.x-lg.blue(href="#" @click.prevent="downloadTableCsv") DOWNLOAD DATA
      BaseBtn.btn-margin-left.x-lg.blue(href="#" @click.prevent="downloadRawData") DOWNLOAD RAW DATA
</template>

<script>
import { defineComponent, ref } from "vue";
import TableTitle from "@/components/TableTitle.vue";
import RangeSelector from "@/components/RangeSelector.vue";
import FitDataTable from "@/components/reports/FitDataTable.vue";
import SpecialtyDataTable from "@/components/reports/SpecialtyDataTable.vue";
import PrimaryCareDataTable from "@/components/reports/PrimaryCareDataTable.vue";
import ProviderDataTable from "@/components/reports/ProviderDataTable.vue";
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";
import { fetchSpecialtyModules } from "@/api/specialtyModulesApi";
import { fetchReferralModules } from "@/api/referralModulesApi";
import { fetchUser } from "@/api/userApi";
import listPagesMixin from "@/mixins/listPagesMixin";

import {
  fetchHospitals,
  getHospitalSpecialty,
  getLinkedCHS,
} from "@/api/hospitalApi";
import {
  fetchCHS,
  fetchCHSClinics,
  fetchCHSHospitals,
  fetchCHSs,
  getSpecialtyModulesOfCHS,
} from "@/api/chsApi";
import { endOfYear, startOfYear } from "@/helpers";
import { downloadCsv } from "@/helpers/downloadCsv";
import {
  fetchFitReportData,
  fetchFullFitReportData,
  fetchFullReportsData,
  fetchReportsData,
} from "@/api/reports";
import moment from "moment-timezone";
import { mapGetters } from "vuex";
import {
  fetchCommunityCareOrganizations,
  fetchCommunityCareOrganizationsClinics,
} from "@/api/communityOrganization";
import {
  getFullPrimaryCareReport,
  getOverallPrimaryCareReport,
} from "@/api/patientReferral";

import {
  getFullPatientReferralReport,
  getOverallPatientReferralReport,
} from "@/api/providerPatientReferral";

export default defineComponent({
  components: {
    TableTitle,
    Vue3ChartJs,
    RangeSelector,
    FitDataTable,
    SpecialtyDataTable,
    PrimaryCareDataTable,
    ProviderDataTable,
  },
  mixins: [listPagesMixin],
  data() {
    return {
      selectedSpecialty: null,
      specialtyModules: [],
      referrralModules: [],
      selectedHospital: null,
      hospitals: [],
      selectedClinicHealthSystem: null,
      clinicHealthSystems: [],
      selectedClinic: null,
      clinics: [],
      selectedStart: startOfYear,
      selectedEnd: endOfYear,
      reportData: [],
      selectedCommunity: null,
      communities: [],
      selectedFitSide: null,
      lastQueryId: null,
      selectedProvider: null,
      showProviders: null,
      filteredProvidersByCommunity: null,
    };
  },

  setup() {
    const chartRef = ref(null);

    const chart = {
      id: "chart",
      type: "line",
      options: {
        scales: {
          x: {
            display: true,
            title: {
              display: true,
              text: "Month",
              padding: { top: 5, left: 0, right: 0, bottom: 0 },
            },
          },
          y: {
            display: true,
            title: {
              display: true,
              text: "Value",
              padding: { top: 0, left: 0, right: 0, bottom: 0 },
            },
          },
        },
      },
      data: {
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
        ],
        datasets: [
          {
            label: "Scheduled",
            tension: 0.4,
            borderColor: "#ff6383",
            backgroundColor: ["#ff6383"],
            data: [],
          },
          {
            label: "Completed",
            tension: 0.4,
            borderColor: "#007dc5",
            backgroundColor: ["#007dc5"],
            data: [],
          },
        ],
      },
    };

    const updateChartData = (data) => {
      chart.data.datasets = data.datasets;
      chart.data.labels = data.labels;
      chartRef.value.update(300);
    };

    return {
      chartRef,
      chart,
      updateChartData,
    };
  },

  computed: {
    ...mapGetters([
      "aHospitalIsSelected",
      "getHospitalId",
      "aClinicIsSelected",
      "getClinicId",
      "aChsIsSelected",
      "getChsId",
      "aCCOIsSelected",
      "getCCOId",
      "isProviderManager",
      "getManagerOfProviderID",
    ]),
    scheduled() {
      return this.reportData.map((monthData) => monthData.totalScheduled);
    },
    totalReferred() {
      return this.reportData.map((monthData) => monthData.totalReferred);
    },
    totalContacted() {
      return this.reportData.map((monthData) => monthData.totalContacted);
    },
    completed() {
      return this.reportData.map((monthData) => monthData.totalCompleted);
    },
    cumulativeRateOfReturn() {
      return this.reportData.map(
        (monthData) => monthData.cumulativeRateOfReturnPercentage
      );
    },
    labels() {
      return this.reportData.map((monthData, index, arr) =>
        moment(
          monthData.distributedAtMonth || monthData.scheduledAtMonth
        ).format(arr.length > 12 ? "MMMM YY" : "MMMM")
      );
    },
    providerLabels() {
      return this.reportData.map((monthData, index, arr) =>
        moment(monthData.referredAtMonth).format(
          arr.length > 12 ? "MMMM YY" : "MMMM"
        )
      );
    },
    chartTitle() {
      const startDate = moment(this.selectedStart).format("MM-DD-YYYY");
      const endDate = moment(this.selectedEnd).format("MM-DD-YYYY");
      let title = `${startDate} to ${endDate}`;

      let hospitalName, clinicHealthSystemName, clinicName, communityName;
      if (this.selectedHospital) {
        hospitalName = this.hospitals.find(
          (hospital) => hospital.id === this.selectedHospital
        )?.name;
      }

      if (this.selectedClinicHealthSystem) {
        clinicHealthSystemName = this.clinicHealthSystems.find(
          (chs) => chs.id === this.selectedClinicHealthSystem
        )?.name;
      }

      if (this.selectedClinic) {
        clinicName = this.clinics.find(
          (clinic) => clinic.id === this.selectedClinic
        )?.name;
      }

      if (this.selectedCommunity) {
        communityName = this.communities.find(
          (community) => community.id === this.selectedCommunity
        )?.name;
      }

      if (hospitalName || clinicHealthSystemName || communityName) {
        title += " | ";
      }

      if (hospitalName) {
        title += hospitalName;
      }

      if (clinicHealthSystemName) {
        if (hospitalName) {
          title += `, (${clinicHealthSystemName})`;
        } else {
          title += `(${clinicHealthSystemName})`;
        }
      }

      if (clinicName) {
        title += ", " + clinicName;
      }

      if (communityName) {
        if (clinicHealthSystemName || hospitalName) {
          title += ", " + communityName;
        } else {
          title += communityName;
        }
      }

      return title;
    },
    noOrganizationIsSelected() {
      return !(
        this.aHospitalIsSelected ||
        this.aChsIsSelected ||
        this.aClinicIsSelected ||
        this.aCCOIsSelected
      );
    },
    showClinicHealthSystemsDropwdown() {
      //there is no need to show dropdown if:
      //user is in a CHS reports view
      //user is in a Clinic reports view
      //user has selected fit under a CCO reports view

      const noChsNorClinicSelectedAsRoleContext = !(
        this.aChsIsSelected || this.aClinicIsSelected
      );
      const specialtyFitUnderCCO = !(
        this.aCCOIsSelected && this.selectedSpecialty === "fit"
      );
      const fitSideIsCCO = this.selectedFitSide === "community";

      return (
        noChsNorClinicSelectedAsRoleContext &&
        specialtyFitUnderCCO &&
        !this.isProviderManager &&
        !fitSideIsCCO &&
        this.selectedSpecialty !== "patient-referrals"
      );
    },
    showFitSideDropdown() {
      //selected specialty must be fit in order to show this dropdown.
      //if user is under context of a chs or a cco, then there is no need to show this dropdown.
      return (
        !this.isProviderManager &&
        this.selectedSpecialty === "fit" &&
        !this.isProviderManager &&
        !(this.aChsIsSelected || this.aCCOIsSelected)
      );
    },
    showHospitalsDropdown() {
      //selected specialty must not be fit nor primary care
      //if a hospital is selected, there's no need to show a dropdown
      //clinic reports should not filter by hospital
      //CCO reports should not filter by hospital
      return (
        !this.isProviderManager &&
        !(
          this.selectedSpecialty === "fit" ||
          this.selectedSpecialty === "primary-care" ||
          this.selectedSpecialty === "patient-referrals"
        ) &&
        !(
          this.aHospitalIsSelected ||
          this.aClinicIsSelected ||
          this.aCCOIsSelected
        )
      );
    },
    showProvidersDropdown() {
      return this.selectedSpecialty === "patient-referrals" || this.isProviderManager;
    },
    showAllCommunitiesDropdown() {
      //selected specialty must be primary-care or fit
      //user under context of hospital or clinic should not filter by CCO
      //if specialty is fit, side must be cco.

      const ifSpecialtyFitSideMustBeCCO =
        this.selectedSpecialty === "fit"
          ? this.selectedFitSide === "community"
          : true;

      return (
        ((this.selectedSpecialty === "primary-care" ||
          this.selectedSpecialty === "fit") &&
          !(
            this.aHospitalIsSelected ||
            this.aClinicIsSelected ||
            this.aCCOIsSelected
          ) &&
          ifSpecialtyFitSideMustBeCCO) ||
        (this.selectedSpecialty === "patient-referrals" && !this.aCCOIsSelected && !this.isProviderManager)
      );
    },
  },

  methods: {
    async fetchUser() {
      try {
        const res = await fetchUser({
          params: {
            filters: {
              // idProviderGroup: +this.$route.params.idProviderGroup,
              idUserRole: 12,
            },
          },
        });
      } catch (err) {
        console.error(err);
      }
    },
    async fetchReport(updateProviders = true) {
      const lastQueryId = new Date().getTime();
      this.lastQueryId = lastQueryId;
      switch (this.selectedSpecialty) {
        case "fit":
          this.fetchFitReport(lastQueryId);
          break;
        case "primary-care":
          this.fetchPrimaryCareReport(lastQueryId);
          break;
        case "patient-referrals":
          this.fetchProviderReferralReport(lastQueryId, updateProviders);
          break;
        default:
          if (this.selectedSpecialty) {
            this.fetchReportOverall(lastQueryId);
          }
          break;
      }
    },
    checkAndSetReportData(reportData, queryId) {
      if (this.lastQueryId === queryId) {
        this.reportData = reportData;
        return true;
      }

      return false;
    },
    async fetchReportOverall(queryId) {
      try {
        const { data } = await fetchReportsData({
          params: {
            idSpecialtyModule: this.selectedSpecialty,
            idClinic: this.selectedClinic,
            idClinicHealthSystem: this.selectedClinicHealthSystem,
            idHospital: this.selectedHospital,
            startDate: new Date(this.selectedStart).getTime() / 1000,
            endDate: new Date(this.selectedEnd).getTime() / 1000,
            timezone: moment.tz.guess(),
          },
        });

        const shouldUpdateChart = this.checkAndSetReportData(
          data.overallPatientAppointmentReport,
          queryId
        );
        if (shouldUpdateChart) {
          const chartData = {
            ...this.chart.data,
            labels: this.labels,
            datasets: [
              {
                label: "Scheduled",
                tension: 0.4,
                borderColor: "#ff6383",
                backgroundColor: ["#ff6383"],
                data: this.scheduled,
              },
              {
                label: "Completed",
                tension: 0.4,
                borderColor: "#007dc5",
                backgroundColor: ["#007dc5"],
                data: this.completed,
              },
            ],
          };

          this.updateChartData(chartData);
        }
      } catch (error) {
        console.error(error);
      }
    },
    async fetchFitReport(queryId) {
      try {
        const { data } = await fetchFitReportData({
          params: {
            idClinic: this.selectedClinic,
            idClinicHealthSystem: this.selectedClinicHealthSystem,
            idCommunity: this.selectedCommunity,
            startDate: new Date(this.selectedStart).getTime() / 1000,
            endDate: new Date(this.selectedEnd).getTime() / 1000,
            timezone: moment.tz.guess(),
            fitType: this.selectedFitSide,
          },
        });

        const shouldUpdateChart = this.checkAndSetReportData(
          data.overallFitKitReport,
          queryId
        );
        if (shouldUpdateChart) {
          const chartData = {
            ...this.chart.data,
            labels: this.labels,
            datasets: [
              {
                label: "Cumulative Rate of Return",
                tension: 0.4,
                borderColor: "#ff6383",
                backgroundColor: ["#ff6383"],
                data: this.cumulativeRateOfReturn,
              },
            ],
          };

          this.updateChartData(chartData);
        }
      } catch (error) {
        console.error(error);
      }
    },
    async fetchProviderReferralReport(queryId, updateProviders = true) {
      try {
        const params = {
          idProvider: this.selectedProvider,
          idCommunity: this.selectedCommunity,
          startDate: new Date(this.selectedStart).getTime() / 1000,
          endDate: new Date(this.selectedEnd).getTime() / 1000,
          timezone: moment.tz.guess(),
        };

        if(this.isProviderManager){
          delete params.idCommunity;
          params.idProviderGroup = this.getManagerOfProviderID;
        } 

        const { data } = await getOverallPatientReferralReport(params);
        const shouldUpdateChart = this.checkAndSetReportData(
          data.overallPatientReferralReport,
          queryId
        );

        if (updateProviders) {
          this.showProviders = data.providers;
        }
        
        if (shouldUpdateChart) {
          const chartData = {
            ...this.chart.data,
            labels: this.providerLabels,
            datasets: [
              {
                label: "Referred",
                tension: 0.4,
                borderColor: "#ff6383",
                backgroundColor: ["#ff6383"],
                data: this.totalReferred,
              },
              {
                label: "Contacted",
                tension: 0.4,
                borderColor: "#007dc5",
                backgroundColor: ["#007dc5"],
                data: this.totalContacted,
              },
            ],
          };
          this.updateChartData(chartData);
        }
      } catch (error) {
        console.error(error);
      }
    },
    async fetchPrimaryCareReport(queryId) {
      try {
        const { data } = await getOverallPrimaryCareReport({
          timezone: moment.tz.guess(),
          idClinic: this.selectedClinic,
          idClinicHealthSystem: this.selectedClinicHealthSystem,
          idCommunity: this.selectedCommunity,
          startDate: new Date(this.selectedStart).getTime() / 1000,
          endDate: new Date(this.selectedEnd).getTime() / 1000,
        });
        const shouldUpdateChart = this.checkAndSetReportData(
          data.overallPatientReferralReport,
          queryId
        );

        if (shouldUpdateChart) {
          const chartData = {
            ...this.chart.data,
            labels: this.labels,
            datasets: [
              {
                label: "Scheduled",
                tension: 0.4,
                borderColor: "#ff6383",
                backgroundColor: ["#ff6383"],
                data: this.scheduled,
              },
              {
                label: "Completed",
                tension: 0.4,
                borderColor: "#007dc5",
                backgroundColor: ["#007dc5"],
                data: this.completed,
              },
            ],
          };

          this.updateChartData(chartData);
        }
      } catch (error) {
        console.error(error);
      }
    },
    async fetchReferralModules() {
      try {
        const { data } = await fetchReferralModules();
        this.referralModules = data.referralModules;
      } catch (error) {
        console.error(error);
      }
    },
    async fetchSpecialties() {
      try {
        const { data } = await fetchSpecialtyModules();
        this.specialtyModules = data.specialtyModules;
      } catch (error) {
        console.error(error);
      }
    },
    async fetchHospitals() {
      try {
        const { data } = await fetchHospitals();
        this.hospitals = data.hospitals;
      } catch (error) {
        console.error(error);
      }
    },
    async fetchClinicHealthSystems() {
      try {
        const { data } = await fetchCHSs();
        this.clinicHealthSystems = data.clinicHealthSystems;
      } catch (error) {
        console.error(error);
      }
    },
    async fetchCCOLinkedCHS() {
      try {
        const { data } = await fetchCommunityCareOrganizationsClinics(
          this.selectedCommunity,
          {
            params: {
              idCommunity: this.selectedCommunity,
            },
          }
        );

        const clinicHealthSystemIds = data.clinics
          .map((clinic) => clinic.idClinicHealthSystem)
          .filter((chs, index, arr) => arr.indexOf(chs) === index);
        const resultingChss = await Promise.all(
          clinicHealthSystemIds.map(async (chsId) => {
            const { data } = await fetchCHS(chsId);
            return data;
          })
        );

        this.clinicHealthSystems = resultingChss;
      } catch (error) {
        console.error(error);
      }
    },
    async fetchClinics() {
      if (this.selectedClinicHealthSystem) {
        try {
          const { data } = await fetchCHSClinics(
            this.selectedClinicHealthSystem
          );
          this.clinics = data.clinics;
        } catch (error) {
          console.error(error);
        }
      }
    },
    async downloadRawData() {
      switch (this.selectedSpecialty) {
        case "fit":
          this.downloadRawFitData();
          break;
        case "primary-care":
          this.downloadRawPrimaryCareData();
          break;
        case "patient-referrals":
          this.downloadRawProviderData();
          break;
        default:
          this.downloadRawAppointmentData();
          break;
      }
    },
    async downloadRawAppointmentData() {
      try {
        const { data } = await fetchFullReportsData({
          params: {
            idSpecialtyModule: this.selectedSpecialty,
            idClinic: this.selectedClinic,
            idClinicHealthSystem: this.selectedClinicHealthSystem,
            idHospital: this.selectedHospital,
            startDate: new Date(this.selectedStart).getTime() / 1000,
            endDate: new Date(this.selectedEnd).getTime() / 1000,
          },
        });

        downloadCsv(this.chartTitle + " Appointments", data);
      } catch (error) {
        console.error(error);
      }
    },
    async downloadRawFitData() {
      try {
        const { data } = await fetchFullFitReportData({
          params: {
            idCommunity: this.selectedCommunity,
            idClinic: this.selectedClinic,
            idClinicHealthSystem: this.selectedClinicHealthSystem,
            startDate: new Date(this.selectedStart).getTime() / 1000,
            endDate: new Date(this.selectedEnd).getTime() / 1000,
            fitType: this.selectedFitSide,
          },
        });
        downloadCsv(this.chartTitle + " Fit Kits", data);
      } catch (error) {
        console.error(error);
      }
    },
    async downloadRawProviderData() {
      const params = {
        idProvider: this.selectedProvider,
        idCommunity: this.selectedCommunity,
        startDate: new Date(this.selectedStart).getTime() / 1000,
        endDate: new Date(this.selectedEnd).getTime() / 1000,
      };

      if(this.isProviderManager){
        delete params.idCommunity;
        params.idProviderGroup = this.getManagerOfProviderID;
      } 


      try {
        const { data } = await getFullPatientReferralReport(params);
        downloadCsv(this.chartTitle + " Patient Referrals", data);
      } catch (error) {
        console.error(error);
      }
    },
    async downloadRawPrimaryCareData() {
      try {
        const { data } = await getFullPrimaryCareReport({
          idClinic: this.selectedClinic,
          idClinicHealthSystem: this.selectedClinicHealthSystem,
          idCommunity: this.selectedCommunity,
          startDate: new Date(this.selectedStart).getTime() / 1000,
          endDate: new Date(this.selectedEnd).getTime() / 1000,
        });
        downloadCsv(this.chartTitle + " Primary Care", data);
      } catch (error) {
        console.error(error);
      }
    },
    async fetchHospitalSpecialties() {
      try {
        const { data } = await getHospitalSpecialty(this.getHospitalId);
        this.specialtyModules = data.hospitalSpecialties.map(
          (specialtyWrapper) => specialtyWrapper.specialtyModule
        );
        if (data.hospitalSpecialties.length) {
          this.selectedSpecialty =
            data.hospitalSpecialties[0].specialtyModule.id;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getHospitalLinkedCHS() {
      try {
        const res = await getLinkedCHS({
          params: {
            include: "idHospitals",
          },
        });
        const clinicHealthSystems = [];
        res.data.clinicHealthSystems.forEach((element) => {
          let newCHS = [];
          newCHS = element;
          if (element.idHospitals.includes(+this.getHospitalId)) {
            clinicHealthSystems.push(newCHS);
          }
        });
        this.clinicHealthSystems = clinicHealthSystems;
      } catch (err) {
        console.error(err);
      }
    },
    async getCHSlinkedHospitals() {
      try {
        const { data } = await fetchCHSHospitals(
          this.selectedClinicHealthSystem
        );
        this.hospitals = data.hospitals;
      } catch (error) {
        console.error(error);
      }
    },
    async getCHSSpecialties() {
      try {
        const { data } = await getSpecialtyModulesOfCHS(
          this.selectedClinicHealthSystem
        );
        this.specialtyModules = data.chsSpecialties
          .filter((specialty) => specialty.active)
          .map((specialtyWrapper) => specialtyWrapper.specialtyModule);

        if (!this.aClinicIsSelected) {
          this.selectedSpecialty = this.specialtyModules[0].id;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async fetchCommunities() {
      try {
        const { data } = await fetchCommunityCareOrganizations();
        this.communities = data.communityCareOrganizations;
      } catch (error) {
        console.error(error);
      }
    },
    initDropdownsForHospital() {
      this.fetchHospitalSpecialties();
      this.fetchHospitals();
      this.getHospitalLinkedCHS();
      this.selectedHospital = this.getHospitalId;
    },
    filterByDate(rangeValue) {
      this.selectedStart = rangeValue.start;
      this.selectedEnd = rangeValue.end;
      this.fetchReport(false);
    },
    print() {
      window.print();
    },
    downloadTableCsv() {
      const separator = ",";
      const table_id = "data-table";
      // Select rows from table_id
      const rows = document.querySelectorAll("table#" + table_id + " tr");
      // Construct csv
      const csv = [];
      for (var i = 0; i < rows.length; i++) {
        const row = [],
          cols = rows[i].querySelectorAll("td, th");
        for (let j = 0; j < cols.length; j++) {
          // Clean innertext to remove multiple spaces and jumpline (break csv)
          let data = cols[j].innerText
            .replace(/(\r\n|\n|\r)/gm, "")
            .replace(/(\s\s)/gm, " ");
          // Escape double-quote with double-double-quote (see https://stackoverflow.com/questions/17808511/properly-escape-a-double-quote-in-csv)
          data = data.replace(/"/g, '""');
          // Push escaped string
          row.push('"' + data + '"');
        }
        csv.push(row.join(separator));
      }
      const csv_string = csv.join("\n");

      // Download it
      downloadCsv(this.chartTitle, csv_string);
    },
  },
  created() {
    if (this.noOrganizationIsSelected && !this.isProviderManager) {
      this.fetchSpecialties().then(() => {
        if (this.noOrganizationIsSelected && this.specialtyModules.length) {
          this.selectedSpecialty = this.specialtyModules[0].id;
        }
      });
      this.fetchReferralModules();
      this.fetchHospitals();
      this.fetchClinicHealthSystems();
      this.fetchCommunities();
      // this.fetchUser();
    } else if (this.aHospitalIsSelected) {
      this.initDropdownsForHospital();
    } else if (this.aChsIsSelected) {
      this.fetchClinicHealthSystems();
      this.selectedClinicHealthSystem = this.getChsId;
      this.selectedFitSide = "chs";
      this.getCHSlinkedHospitals();
      this.getCHSSpecialties();
      if (this.aClinicIsSelected) {
        this.selectedSpecialty = "fit";
        this.selectedClinic = this.getClinicId;
      }
    } else if (this.aCCOIsSelected) {
      this.fetchCommunities();
      this.selectedSpecialty = "fit";
      this.selectedFitSide = "community";
      this.selectedCommunity = this.getCCOId;
    } else if (this.isProviderManager){
      this.selectedCommunity = this.getManagerOfProviderID;
      this.selectedSpecialty = "patient-referrals";

    }
  },

  watch: {
    aHospitalIsSelected(value) {
      if (value) {
        this.initDropdownsForHospital();
      }
    },
    aClinicIsSelected(value) {
      if (value) {
        this.selectedSpecialty = "fit";
        this.selectedClinic = this.getClinicId;
      }
    },
    aCCOIsSelected(value) {
      if (value) {
        this.selectedCommunity = this.getCCOId;
        this.selectedSpecialty = "fit";
      }
    },
    selectedClinicHealthSystem() {
      this.selectedClinic = null;
      this.fetchClinics().then(async () => {
        if (
          this.selectedClinicHealthSystem &&
          this.aCCOIsSelected &&
          this.selectedSpecialty === "primary-care"
        ) {
          try {
            const { data } = await fetchCommunityCareOrganizationsClinics(
              this.selectedCommunity,
              {
                params: {
                  idCommunity: this.selectedCommunity,
                },
              }
            );

            this.clinics = data.clinics.filter(
              (clinic) =>
                clinic.idClinicHealthSystem === this.selectedClinicHealthSystem
            );
          } catch (error) {
            console.error(error);
          }
        }
      });
      this.fetchReport();
    },
    selectedSpecialty(value) {
      if (value) {
        switch (value) {
          case "fit":
            this.selectedHospital = null;
            if (this.aCCOIsSelected) {
              this.selectedFitSide = "community";
              break;
            }

            this.selectedFitSide = "chs";
            break;
          case "primary-care":
            this.selectedFitSide = null;

            if (!this.communities.length && !this.aCCOIsSelected) {
              this.fetchCommunities();
            }

            if (this.aCCOIsSelected) {
              this.selectedCommunity = this.getCCOId;
              this.fetchCCOLinkedCHS();
            }
            break;
          case "patient-referrals":
            this.selectedClinicHealthSystem = null;
            break;
          default:
            this.selectedFitSide = null;
            this.selectedCommunity = null;
            break;
        }
        this.fetchReport();
      }
    },
    selectedClinic() {
      this.fetchReport();
    },
    selectedHospital() {
      this.fetchReport();
    },
    selectedCommunity() {
      this.fetchReport();
      this.selectedProvider = null;
    },
    selectedProvider(){
      this.fetchReport(false);
    },
    filteredProviderPatientReferrals() {
      this.showProviders = {};
    },
    selectedFitSide(value) {
      switch (value) {
        case "chs":
          this.selectedCommunity = null;
          break;
        case "community":
          this.selectedClinic = null;
          this.selectedClinicHealthSystem = null;
          break;
        default:
          break;
      }
      this.fetchReport();
    },
  },
});
</script>

<style scoped lang="scss">
.debugColumn {
  flex-direction: column;
  span {
    display: block;
  }
}
.title {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
  font-family: inherit;
  line-height: 1.1;
  color: inherit;
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 28px;
  letter-spacing: 0.4px;
}
.card {
  padding: 64px 35px 50px 35px;
}
.table-title {
  padding-left: 0px;
  padding-right: 0px;
  margin-top: 0px;
}
.table_wrapper {
  width: 100%;
  margin-bottom: 80px;
}
.chart_wrapper {
  width: 100%;
  margin-top: 100px;
  margin-bottom: 75px;
}
.hr {
  margin-right: 30px;
}
.btn-margin-left {
  margin-left: 28px;
}
.table_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.table {
  width: 100%;
  color: #000;

  .th {
    padding-bottom: 14px;
    padding-top: 14px;
    height: auto;
    border-bottom: 0px;
    .td {
      font-weight: 700;
      text-transform: none;
    }
    background-color: $primaryBgDarkGrey;
  }

  .tr {
    padding-bottom: 14px;
    padding-top: 14px;
    height: auto;
    .td {
      height: auto;
      display: flex;
    }
    &:hover {
      background-color: transparent;
    }
    &:last-child {
      border-bottom: 0px;
    }
  }

  .td {
    font-size: 14px;
    display: flex;
    flex: 1;
  }
}
</style>
<style lang="scss">
@media print {
  body * {
    visibility: hidden;
  }
  .print-area,
  .print-area * {
    visibility: visible;
  }
  .print-area {
    position: absolute;
    left: 0;
    top: 0;

    .chart-wrapper {
      margin-top: 0;
    }
  }
}
</style>
