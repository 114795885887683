<template lang="pug">
.content-wrapper
  .card.router-view-card
    section.all-clinics
      h2.big_title RESCHEDULE APPOINTMENT 
        span for 
          span.bold(v-if="oldSchedule?.patient") {{ getUserFormatted(oldSchedule.patient) }}
    TableTitle(title="Available Dates"
    )
      template(#actions)
        select.table-action-selector.table-action-selector--doctor(
          :title="doctorTitle",
          style="margin-right: 24px;"
          @change="setFilterValueDoctor($event.target.value)"
        )
          option(value="") DOCTOR'S NAME
          option(
            v-for="doctor in activeDoctors", :key="doctor.id",
            :value="doctor.id"
          ) {{ getDoctorFormatted(doctor) }} 
        DateSelector(
          @filterByDate="filterByDate"
          :min-date="new Date()"
        )
    .table
      .th
        .td(@click.prevent="changeListOrder('DoctorName', getClinicSchedules)" style="cursor: pointer;") Doctor Name
          i(style="font-weight: bolder;") {{ DoctorNameColumnSortingIcon }}
        .td Insurance
        .td Clinic
        .td(@click.prevent="changeListOrder('DateAndTime', getClinicSchedules)" style="cursor: pointer;") Date and time
          i(style="font-weight: bolder;") {{ DateAndTimeColumnSortingIcon }}
        .td
      .tr(v-for="schedule in schedules", :key="schedule.id")
        .td {{ getDoctorFormatted(schedule.doctor) }}
        .td
          base-icon(name="yes", v-if="schedule.insuranceRequired")
          base-icon(name="no", v-else)
        .td {{ schedule.clinic.name }}
        .td
          div {{ getDateFromUnixFormatted(schedule.availableAt) }}
          .second-row-md-grey {{ getTimeFromUnixFormatted(schedule.availableAt) }}
        .td.alignButtonRight
          BaseBtn.sm.blue(
            @click.prevent="openConfirmScheduleOverlay(schedule)"
          ) SELECT SCHEDULE
    Pagination(
      v-if="pagination && schedules != null && schedules.length",
      :pagination="pagination",
      :reloadFn="getClinicSchedules"
      @changePagination="changePagination"
    )
    .no-results(v-if="schedules != null && !schedules.length") 
      base-icon(name="exclamation") 
      span No matching records

ConfirmScheduleOverlay(
  :isOpen="isConfirmScheduleOverlayOpen",
  :newSchedule="newSchedule"
  :oldSchedule="oldSchedule"
  @closeConfirmScheduleOverlay="closeConfirmScheduleOverlay"
)
</template>

<script>
import { defineComponent } from "vue";
import TableTitle from "@/components/TableTitle.vue";
import DateSelector from "@/components/DateSelector.vue";
import Pagination from "@/components/Pagination.vue";
import listPagesMixin from "@/mixins/listPagesMixin";
import {
  getClinicSchedules,
  fetchPatientReferralById,
} from "@/api/patientReferral";

import { apiStatus, apiStatusComputedFactory } from "@/api";
const { IDLE, PENDING, SUCCESS, ERROR } = apiStatus;

import ConfirmScheduleOverlay from "@/components/primary-care-clinic/patient-referrals/reschedule/overlays/ConfirmScheduleOverlay.vue";

import {
  getDateFromUnixFormatted,
  getTimeFromUnixFormatted,
  getUserFormatted,
  getDoctorFormatted,
} from "@/helpers";
import { getClinicDoctors } from "@/api/clinicApi";
import sortCCOSchedules from "@/mixins/sortCCOSchedules";

export default defineComponent({
  components: {
    TableTitle,
    DateSelector,
    Pagination,
    ConfirmScheduleOverlay,
  },
  mixins: [listPagesMixin, sortCCOSchedules],

  data() {
    return {
      isAppointmentRecordOverlayOpen: false,
      isAppointmentNotesOverlayOpen: false,
      isConfirmScheduleOverlayOpen: false,
      getClinicSchedulesStatus: IDLE,
      fetchPatientReferralByIdStatus: IDLE,
      schedules: null,
      idCHS: null,
      patient: null,
      newSchedule: null,
      oldSchedule: null,
      doctors: null,
      idDoctor: null,
      doctorTitle: "",
      idClinic: null,
      date: null,
    };
  },
  computed: {
    ...apiStatusComputedFactory(
      "getClinicSchedulesStatus",
      "fetchPatientReferralByIdStatus"
    ),
    activeDoctors() {
      return this.doctors?.filter((doctor) => !doctor.disabled);
    },
  },
  methods: {
    async getDoctors() {
      try {
        const {
          data: { doctors },
        } = await getClinicDoctors(this.idClinic);
        this.doctors = doctors;
      } catch (error) {
        console.error(error);
      }
    },
    filterByDate(date) {
      this.date = date;
      this.getClinicSchedules();
    },
    getUserFormatted,
    getDateFromUnixFormatted,
    getTimeFromUnixFormatted,
    getDoctorFormatted,
    setFilterValueDoctor(value) {
      if (value) {
        this.idDoctor = value;
        this.doctors.forEach((doctor) => {
          if (doctor.id == value) {
            this.doctorTitle = this.getDoctorFormatted(doctor);
          }
        });
      } else {
        this.idDoctor = null;
      }
      this.getClinicSchedules();
    },
    async fetchPatientReferralById() {
      this.fetchPatientReferralByIdStatus = PENDING;
      try {
        const res = await fetchPatientReferralById(
          this.$route.params.idAppointment,
          {
            params: {
              include: [
                "communityCare",
                "clinicDoctor",
                "communityReport",
                "cancelled",
                "clinic",
                "clinicReport",
                "emergencyContact",
                "insurance",
                "patient",
                "referringDoctor",
              ],
            },
          }
        );
        this.oldSchedule = res.data.patientReferral;
        this.idClinic = this.oldSchedule.idClinic;
        this.fetchPatientReferralByIdStatus = SUCCESS;
      } catch (err) {
        this.fetchPatientReferralByIdStatus = ERROR;
        console.error(err);
      }
    },

    openConfirmScheduleOverlay(newSchedule) {
      this.newSchedule = newSchedule;
      this.isConfirmScheduleOverlayOpen = true;
    },
    closeConfirmScheduleOverlay() {
      this.isConfirmScheduleOverlayOpen = false;
    },
    async getClinicSchedules() {
      this.getClinicSchedulesStatus = PENDING;
      let navigatePage = 1;
      if (this.reqPagination.page) {
        navigatePage = this.reqPagination.page;
      }
      const order = this.listOrder
        ? {
            by: this.listOrder.by,
            direction: this.listOrder.direction,
          }
        : null;

      try {
        const res = await getClinicSchedules({
          params: {
            ...this.reqPagination,
            collapsedDoctor: false,
            idCommunity: this.$route.params.idCommunity,
            idClinic: this.$route.params.idClinic,
            include: ["patientReferral", "clinic", "doctor", "communityCare"],
            isAvailable: "Available",
            availableAt: this.date ?? null,
            isInsuranceRequired: this.oldSchedule.schedule.insuranceRequired ? true : false,
            idDoctor: this.idDoctor,
            idSpecialtyModule: this.$route.params.idSpecialty,
            idHospital: this.filterIdHospital ?? null,
            page: navigatePage,
            order,
          },
        });

        this.schedules = res.data.schedules;
        this.pagination = res.data.pagination;
        this.getClinicSchedulesStatus = SUCCESS;
      } catch (err) {
        this.getClinicSchedulesStatus = ERROR;
        console.error(err);
      }
    },
  },
  created() {
    if (this.$route.params.idCommunity === null) return;
    this.fetchPatientReferralById().then(() => {
      this.getClinicSchedules();
      this.getDoctors();
    });
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/components/actions-below-table-container.scss";
select.table-action-selector--insurance {
  width: 175px;
}
.big_title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  span {
    font-weight: normal;
    color: $primaryDarkBlue;
    span {
      font-weight: 700;
    }
  }
}
.card {
  position: relative;
}
.actions-below-table-container {
  top: calc(100% + 44px);
}
.td {
  &:nth-child(1) {
    width: math.percentage(math.div(290, $tabContentCardWidth));
  }
  &:nth-child(2) {
    width: math.percentage(math.div(175, $tabContentCardWidth));
  }
  &:nth-child(3) {
    width: math.percentage(math.div(289, $tabContentCardWidth));
  }
  &:nth-child(4) {
    width: math.percentage(math.div(190, $tabContentCardWidth));
  }
  &:nth-child(5) {
    width: math.percentage(math.div(236, $tabContentCardWidth));
  }
  &.alignButtonRight {
    text-align: right;
  }
  .btn {
    min-width: 196px;
  }
}
</style>
<style>
.main-container {
  padding-bottom: 100px;
}
</style>
