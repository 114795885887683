// @ts-nocheck
export default {
  data() {
    return {
      sortings: {
        ClinicName: [
          undefined,
          { by: "clinic-name", icon: "▲", direction: "asc" },
          { by: "clinic-name", icon: "▼", direction: "desc" },
        ],
        Address: [
          undefined,
          { by: "address", icon: "▲", direction: "asc" },
          { by: "address", icon: "▼", direction: "desc" },
        ],
        City: [
          undefined,
          { by: "city", icon: "▲", direction: "asc" },
          { by: "city", icon: "▼", direction: "desc" },
        ],
      },
      currentSortKey: "ClinicName",
      currentSortIndex: 0,
      listOrder: undefined,
    };
  },

  computed: {
    ClinicNameColumnSortingIcon() {
      if (this.currentSortKey === "ClinicName") {
        return this.sortings.ClinicName[this.currentSortIndex]?.icon || "▼▲";
      }
      return "▼▲";
    },
    AddressColumnSortingIcon() {
      if (this.currentSortKey === "Address") {
        return this.sortings.Address[this.currentSortIndex]?.icon || "▼▲";
      }
      return "▼▲";
    },
    CityColumnSortingIcon() {
      if (this.currentSortKey === "City") {
        return this.sortings.City[this.currentSortIndex]?.icon || "▼▲";
      }
      return "▼▲";
    },
  },

  methods: {
    changeListOrder(key, callback) {
      if (this.currentSortKey === key) {
        this.updateCurrentSortingIndex(callback);
        return;
      }

      this.currentSortKey = key;
      this.currentSortIndex = 0;
      this.updateCurrentSortingIndex(callback);
    },
    updateCurrentSortingIndex(callback) {
      if (
        this.currentSortIndex ===
        this.sortings[this.currentSortKey].length - 1
      ) {
        this.currentSortIndex = 0;
        this.listOrder = this.sortings[this.currentSortKey][0];
        callback();
        return;
      }

      this.currentSortIndex++;
      this.listOrder =
        this.sortings[this.currentSortKey][this.currentSortIndex];
      return callback();
    },
  },
};
