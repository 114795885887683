<template lang="pug">
label.base-toggle.label--margin-sm(:for="labelKebabCase" :class="{'disabled': disabled}" ) {{label ? label : ''}}
  label.toggle
    input.toggle-input(
      type="checkbox",
      :checked="modelValue",
      @change="$emit('update:modelValue', $event.target.checked)",
      :id="labelKebabCase"
    )
    .toggle-switch
</template>

<script>
import { defineComponent } from "vue";
import kebabCase from "lodash/kebabCase";
import { v1 as uuidv1 } from "uuid";

export default defineComponent({
  props: {
    label: {
      type: String,
      default: "",
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    disabled: {
      required: false,
    },
  },

  computed: {
    labelKebabCase() {
      if (!this.label) return "";
      return `${kebabCase(this.label)}-${uuidv1()}`;
    },
  },
});
</script>

<style scoped lang="scss">
// TOGGLE -----------------------------------------
$toggleHeight: 28px;
$toggleWidth: 66px;
$toggleSwitchPadding: 3px;
$toggleCircleWidth: $toggleHeight - 2 * $toggleSwitchPadding;

// Disabled ==================================================================
@mixin btn-disabled() {
  cursor: not-allowed;
  max-width: 70px;
}

.disabled {
  @include btn-disabled;
  label {
    pointer-events: none;
  }
}

.toggle {
  cursor: pointer;
  display: block;
  width: $toggleWidth;
  height: $toggleHeight;
  margin-top: math.div((50px - $toggleHeight), 2);
  margin-bottom: math.div((50px - $toggleHeight), 2);
  overflow: hidden;
}
.toggle-switch {
  display: flex;
  width: inherit;
  height: inherit;
  align-items: center;
  border-radius: 100px;
  background-color: $primaryBgDarkGrey;
  position: relative;
  transition: background 0.25s;
  &::before {
    content: "";
    display: block;
    border-radius: 50%;
    width: $toggleCircleWidth;
    height: $toggleCircleWidth;
    position: absolute;
    left: $toggleSwitchPadding;
    background: #fff;
    transition: left 0.25s;
    z-index: 2;
  }
  &::after {
    content: "OFF";
    display: block;
    position: absolute;
    right: 8px;
    font-size: 13px;
    font-weight: 700;
    line-height: 1;
    letter-spacing: 0.33px;
    color: #9b9b9b;
    transition: all 0.25s;
    z-index: 1;
  }
  .toggle-input:checked + & {
    background: $primaryLightBlue;
    &:before {
      left: calc(100% - #{$toggleCircleWidth} - #{$toggleSwitchPadding});
    }
    &::after {
      content: "ON";
      left: 11px;
      right: auto;
      color: #fff;
    }
  }
}
.toggle-input {
  position: absolute;
  visibility: hidden;
}
.toggle-label {
  margin-left: 5px;
  position: relative;
  top: 2px;
}
</style>
