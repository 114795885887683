<template lang="pug">
.view-appointment-status.content-wrapper
  BasePageTitle(
    pageTitleIcon="primary-care",
    :pageTitleIconString="careIcon"
    :title="careTitle"
  )
    template(#breadcrumbs)
      li
        router-link(:to="{name: 'primary-care-community-organization.patient-referrals'}") Patient Referrals
      li
        router-link(:to="{name: 'primary-care-community-organization.schedules'}") Schedules
      li
        router-link(:to="{name: 'primary-care-community-organization.scheduling'}") Schedule Patient

  router-view(@patientReferralStats="patientReferralStats" v-if="showPatientReferralStats")
  router-view(v-else)

  .content-wrapper.patient-referral-stats(v-if="showPatientReferralStats")
    .card.router-view-card
      section.section_padding
        h3 Patient Referral Stats 
         span.h3-small-font Last 30 Days
        .columns.is-variable.circles-center
          .column.is-one-fifth
            .circle
              <vue3-chart-js :id="doughnutChart.id" :type="doughnutChart.type" :data="doughnutChart.data" :options="doughnutChart.options" ref="chartRef" ></vue3-chart-js>
          .column.is-2.circle-wrapper
            .circle-status.green
            .circle-numbers
              .circle-big-font {{ referralStats.completed }}
              .circle-small-font Completed
          .column.is-2.circle-wrapper
            .circle-status.orange
            .circle-numbers
              .circle-big-font {{ referralStats.cancelled }}
              .circle-small-font Cancelled
          .column.is-3.circle-wrapper
            .circle-status.grey
            .circle-numbers
              .circle-big-font {{ referralStats.noShow }}
              .circle-small-font No Show

</template>
<script>
import { defineComponent } from "vue";
import BoxLinkSm from "@/components/BoxLinkSm.vue";
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";
import { fetchPrimaryCareModule } from "@/api/primaryCareModule";

import { ref } from "vue";
export default defineComponent({
  components: {
    BoxLinkSm,
    Vue3ChartJs,
  },

  data() {
    return {
      careTitle: "",
      careIcon: "",
      referralStats: {
        completed: 0,
        cancelled: 0,
        noShow: 0,
      },
    };
  },
  methods: {
    patientReferralStats(values) {
      this.doughnutChartUpdate(values);
      this.referralStats = values;
    },
    async fetchPrimaryCareModule() {
      try {
        const res = await fetchPrimaryCareModule();
        this.careIcon = res.data.primaryCareModule?.icon;
        this.careTitle =
          res.data.primaryCareModule?.marketingTitle +
          ": " +
          res.data.primaryCareModule?.moduleTitle;
      } catch (err) {
        console.error(err);
      }
    },
  },
  computed: {
    showPatientReferralStats() {
      return this.$route.name.includes(
        "primary-care-community-organization.patient-referrals"
      );
    },
  },
  setup() {
    const counter = ref(0);
    const chartRef = ref(null);

    const doughnutChart = {
      id: "doughnut",
      type: "doughnut",
      data: {
        datasets: [
          {
            backgroundColor: ["#96bf3c", "#f5a623", "#d8d8d8"],
            data: [0, 0, 0],
          },
        ],
      },
      options: {
        offset: 0,
        cutout: "70%",
        scales: {
          x: {
            display: false,
          },
          y: {
            display: false,
          },
        },
      },
    };
    const beforeUpdate = (e) => {
      counter.value++;
    };
    const doughnutChartUpdate = (values) => {
      doughnutChart.data.datasets[0].data = [
        values.completed,
        values.cancelled,
        values.noShow,
      ];
      chartRef.value.update(750);
    };
    return {
      doughnutChart,
      beforeUpdate,
      doughnutChartUpdate,
      chartRef,
    };
  },
  created() {
    if (this.$route.params.idCommunity === null) return;
    this.fetchPrimaryCareModule();
  },
});
</script>

<style scoped lang="scss">
.patient-referral-stats {
  margin-right: 0px;
  margin-left: auto;

  .section_padding {
    padding-left: 30px;
    padding-right: 30px;
    h3 {
      font-size: 21px;
      font-weight: normal;
      margin-bottom: 42px;
      margin-top: 30px;
      color: #7d7c7d;
      .h3-small-font {
        font-size: 16px;
        margin-left: 17px;
        color: #9b9b9b;
      }
    }
    .circles-center {
      align-items: center;
    }
    .columns.is-variable {
      padding-bottom: 57px;
      margin-left: 0px;
      margin-right: 0px;
    }
    .box-link-sm {
      margin-left: -24px;
    }
    .circle {
      width: 130px;
      height: 130px;
    }
    .circle-numbers > * {
      display: block;
    }
    .circle-status {
      width: 18px;
      height: 18px;
      min-width: 18px;
      border-radius: 100%;
      display: inline-block;
      margin-top: 10px;
      margin-right: 10px;
      &.green {
        background-color: $secondaryGreen;
      }
      &.orange {
        background-color: $secondaryOrange;
      }
      &.grey {
        background-color: $primaryBgDarkGrey;
      }
    }
    .circle-big-font {
      color: #343434;
      font-size: 26px;
    }
    .circle-small-font {
      color: $primaryDarkGrey;
      font-size: 14px;
      margin-top: -3px;
    }
    .circle-wrapper {
      display: flex;
    }
  }
}
</style>
