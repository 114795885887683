// @ts-nocheck

import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";

import Home from "@/views/Home.vue";
import HomeNavigator from "@/views/HomeNavigator.vue";
import Account from "@/views/Account.vue";
import LogIn from "@/views/LogIn.vue";
import ResetPassword from "@/views/ResetPassword.vue";
import ForgotPassword from "@/views/ForgotPassword.vue";

import AppointmentStatus from "@/views/AppointmentStatus.vue";
import Requested from "@/components/appointment-status/Requested.vue";
import Confirmed from "@/components/appointment-status/Confirmed.vue";

import ManageCare from "@/views/ManageCare.vue";
import ActivePatients from "@/components/manage-care/ActivePatients.vue";
import Archived from "@/components/manage-care/Archived.vue";

import StyleGuide from "@/views/StyleGuide.vue";
import Dashboard from "@/views/Dashboard.vue";

import NewFitKit from "@/views/fit-kit/NewFitKit.vue";
import FitKitSearchPatient from "@/components/fit-kit/new-fit-kit/SearchPatient.vue";
import FitKitSelectPatient from "@/components/fit-kit/new-fit-kit/SelectPatient.vue";

import FitKitStatus from "@/views/fit-kit/FitKitStatus.vue";
import PositiveFit from "@/components/fit-kit/fit-kit-status/PositiveFit.vue";
import FitFollowUpDue from "@/components/fit-kit/fit-kit-status/FitFollowUpDue.vue";
import FitAllOutstanding from "@/components/fit-kit/fit-kit-status/FitAllOutstanding.vue";
import FitAnnualDue from "@/components/fit-kit/fit-kit-status/FitAnnualDue.vue";
import FitCompleted from "@/components/fit-kit/fit-kit-status/FitCompleted.vue";

import NewFitKitCommunityOrganization from "@/views/fit-kit-community-organization/NewFitKit.vue";
import FitKitSearchPatientCommunityOrganization from "@/components/fit-kit-community-organization/new-fit-kit/SearchPatient.vue";
import FitKitSelectPatientCommunityOrganization from "@/components/fit-kit-community-organization/new-fit-kit/SelectPatient.vue";

import FitKitStatusCommunityOrganization from "@/views/fit-kit-community-organization/FitKitStatus.vue";
import PositiveFitCommunityOrganization from "@/components/fit-kit-community-organization/fit-kit-status/PositiveFit.vue";
import FitFollowUpDueCommunityOrganization from "@/components/fit-kit-community-organization/fit-kit-status/FitFollowUpDue.vue";
import FitAllOutstandingCommunityOrganization from "@/components/fit-kit-community-organization/fit-kit-status/FitAllOutstanding.vue";
import FitAnnualDueCommunityOrganization from "@/components/fit-kit-community-organization/fit-kit-status/FitAnnualDue.vue";
import FitCompletedCommunityOrganization from "@/components/fit-kit-community-organization/fit-kit-status/FitCompleted.vue";

import FitOutsideIcc from "@/views/fit-kit/FitOutsideIcc.vue";
import OutsideIccPending from "@/components/fit-kit/fit-outside-icc/OutsideIccPending.vue";
import OutsideIccArchived from "@/components/fit-kit/fit-outside-icc/OutsideIccArchived.vue";

import FitReferrals from "@/views/fit-kit/FitReferrals.vue";

import Organizations from "@/views/Organizations";
import ClinicHealthSystems from "@/components/organizations/ClinicHealthSystems";
import CommunityOrganizations from "@/components/organizations/CommunityOrganizations";
import OrganizationsHospitals from "@/components/organizations/Hospitals";
import OrganizationsProviderGroups from "@/components/organizations/ProviderGroups";

import UsersSuperAdmin from "@/views/UsersSuperAdmin";
import UsersProviders from "@/views/UsersProviders";
import UsersClinicalHealthSystems from "@/views/UsersClinicalHealthSystems";
import UsersHospitals from "@/views/UsersHospitals";
import UsersCommunity from "@/views/UsersCommunity";

import AllClinics from "@/views/AllClinics";
import AllActions from "@/views/AllActions";
import Reports from "@/views/Reports";

import ProviderGroups from "@/views/ProviderGroups";
import ProviderGroupsReferralStatus from "@/components/provider-groups/referral-status/ReferralStatus";
import ProviderGroupsReferralStatusRequested from "@/components/provider-groups/referral-status/Requested";
import ProviderGroupsReferralStatusUnableToContact from "@/components/provider-groups/referral-status/UnableToContact";
import ProviderGroupsReferralStatusContacted from "@/components/provider-groups/referral-status/Contacted";
import ProviderNotifications from "@/components/provider-groups/Notifications";

import CCOReferrals from "@/views/CCOReferrals";
import CCOPatientReferrals from "@/components/cco-referrals/patient-referrals/PatientReferrals";
import CCOPatientReferralsRequested from "@/components/cco-referrals/patient-referrals/Requested";
import CCOPatientReferralsUnableToContact from "@/components/cco-referrals/patient-referrals/UnableToContact";
import CCOPatientReferralsContacted from "@/components/cco-referrals/patient-referrals/Contacted";

import CCOReferralsScheduling from "@/views/CCOReferralsScheduling";
import CCOReferralsSchedulingSearchPatient from "@/components/cco-referrals/scheduling/SearchPatient";
import CCOReferralsSchedulingSelectPatient from "@/components/cco-referrals/scheduling/SelectPatient";
import CCOReferralsSchedulingSelectProvider from "@/components/cco-referrals/scheduling/SelectProvider";
import CCOReferralsSchedulingReferralInfo from "@/components/cco-referrals/scheduling/ReferralInfo";
import CCOReferralsSchedulingConfirm from "@/components/cco-referrals/scheduling/Confirm";

import Hospitals from "@/views/Hospitals";
import HospitalsAppointmentStatus from "@/components/hospitals/appointment-status/AppointmentStatus";
import HospitalsAppointmentStatusPendingClinicReview from "@/components/hospitals/appointment-status/PendingClinicReview";
import HospitalsAppointmentStatusArchived from "@/components/hospitals/appointment-status/Archived";
import HospitalsAppointmentStatusCofirmed from "@/components/hospitals/appointment-status/Confirmed";
import HospitalsAppointmentStatusRequested from "@/components/hospitals/appointment-status/Requested";
import HospitalsSchedules from "@/components/hospitals/schedules/Schedules";
import HospitalsPatientSearch from "@/views/HospitalsPatientSearch";
import HospitalsPatientSearchResults from "@/components/hospitals/patient-search/PatientSearchResults";
import HospitalsReschedule from "@/components/hospitals/appointment-status/reschedule/Reschedule";
import HospitalsOnGoingCare from "@/components/hospitals/ongoing-care/OnGoingCare";
import HospitalsOnGoingCareActivePatients from "@/components/hospitals/ongoing-care/ActivePatients";
import HospitalsOnGoingCareArchived from "@/components/hospitals/ongoing-care/Archived";
import HospitalsNotifications from "@/components/hospitals/Notifications.vue";

import PrimaryCareClinic from "@/views/PrimaryCareClinic";
import PrimaryCareClinicPatientReferrals from "@/components/primary-care-clinic/patient-referrals/PatientReferrals";
import PrimaryCareClinicPatientReferralsRequested from "@/components/primary-care-clinic/patient-referrals/Requested";
import PrimaryCareClinicPatientReferralsConfirmed from "@/components/primary-care-clinic/patient-referrals/Confirmed";
import PrimaryCareClinicPatientReferralsNoShowCancelled from "@/components/primary-care-clinic/patient-referrals/NoShowCancelled";
import PrimaryCareClinicPatientReferralsArchived from "@/components/primary-care-clinic/patient-referrals/Archived";
import PrimaryCareClinicSchedules from "@/components/primary-care-clinic/schedules/Schedules";
import PrimaryCareClinicReschedule from "@/components/primary-care-clinic/patient-referrals/reschedule/Reschedule";

import PrimaryCareCommunityOrganization from "@/views/PrimaryCareCommunityOrganization";
import PrimaryCareCommunityOrganizationReschedule from "@/components/primary-care-community-organization/patient-referrals/reschedule/Reschedule";
import PrimaryCareCommunityOrganizationPatientReferrals from "@/components/primary-care-community-organization/patient-referrals/PatientReferrals";
import PrimaryCareCommunityOrganizationPatientReferralsRequested from "@/components/primary-care-community-organization/patient-referrals/Requested";
import PrimaryCareCommunityOrganizationPatientReferralsConfirmed from "@/components/primary-care-community-organization/patient-referrals/Confirmed";
import PrimaryCareCommunityOrganizationPatientReferralsNoShowCancelled from "@/components/primary-care-community-organization/patient-referrals/NoShowCancelled";
import PrimaryCareCommunityOrganizationPatientReferralsArchived from "@/components/primary-care-community-organization/patient-referrals/Archived";
import PrimaryCareCommunityOrganizationSchedules from "@/components/primary-care-community-organization/schedules/Schedules";
import PrimaryCareCommunityOrganizationScheduling from "@/views/PrimaryCareCommunityOrganizationScheduling";
import PrimaryCareCommunityOrganizationSchedulingSearchPatient from "@/components/primary-care-community-organization/scheduling/SearchPatient";
import PrimaryCareCommunityOrganizationSchedulingSelectPatient from "@/components/primary-care-community-organization/scheduling/SelectPatient";
import PrimaryCareCommunityOrganizationSchedulingSelectSchedule from "@/components/primary-care-community-organization/scheduling/SelectSchedule";
import PrimaryCareCommunityOrganizationSchedulingAppointmentInfo from "@/components/primary-care-community-organization/scheduling/AppointmentInfo";
import PrimaryCareCommunityOrganizationSchedulingConfirm from "@/components/primary-care-community-organization/scheduling/Confirm";
import PrimaryCareCommunityOrganizationNotifications from "@/components/primary-care-community-organization/Notifications.vue";
import PrimaryCareCommunityOrganizationPatientSearch from "@/views/PrimaryCareCommunityOrganizationPatientSearch.vue";
import PrimaryCareCommunityOrganizationPatientSearchResults from "@/components/primary-care-community-organization/patient-search/PatientSearchResults.vue";

import Clinics from "@/views/Clinics";
import ClinicsAppointmentStatus from "@/components/clinics/appointment-status/AppointmentStatus";
import ClinicsAppointmentStatusPendingClinicReview from "@/components/clinics/appointment-status/PendingClinicReview";
import ClinicsAppointmentStatusArchived from "@/components/clinics/appointment-status/Archived";
import ClinicsAppointmentStatusCofirmed from "@/components/clinics/appointment-status/Confirmed";
import ClinicsAppointmentStatusRequested from "@/components/clinics/appointment-status/Requested";
import ClinicsSchedules from "@/components/clinics/schedules/Schedules";
import ClinicsPatientSearch from "@/views/ClinicsPatientSearch";
import ClinicsPatientSearchResults from "@/components/clinics/patient-search/PatientSearchResults";
import ClinicsReschedule from "@/components/clinics/appointment-status/reschedule/Reschedule";
import ClinicScheduling from "@/views/ClinicScheduling.vue";
import ClinicSearchPatient from "@/components/clinics/scheduling/SearchPatient.vue";
import ClinicSelectPatient from "@/components/clinics/scheduling/SelectPatient.vue";
import ClinicSelectSchedule from "@/components/clinics/scheduling/SelectSchedule.vue";
import ClinicReferralInfo from "@/components/clinics/scheduling/ReferralInfo.vue";
import ClinicConfirm from "@/components/clinics/scheduling/Confirm.vue";
import ClinicsOnGoingCare from "@/components/clinics/ongoing-care/OnGoingCare";
import ClinicsOnGoingCareActivePatients from "@/components/clinics/ongoing-care/ActivePatients";
import ClinicssOnGoingCareArchived from "@/components/clinics/ongoing-care/Archived";
import ClinicsNotifications from "@/components/clinics/Notifications.vue";

import SystemSettings from "@/views/system-settings/SystemSettings.vue";
import SpecialtyModules from "@/views/system-settings/SpecialtyModules.vue";
import Referrals from "@/views/system-settings/ReferralModules.vue";
import PrimaryCareModules from "@/views/system-settings/PrimaryCareModules.vue";
import Insurances from "@/views/system-settings/Insurances.vue";
import Settings from "@/views/system-settings/Settings.vue";

import ResourcesHelp from "@/views/ResourcesHelp";

import APIDemo from "@/views/APIDemo";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeNavigator,
    meta: {
      title: "Home",
    },
  },
  {
    path: "/quick-access",
    name: "quick.access",
    component: Home,
    meta: {
      title: "Home",
    },
  },

  {
    path: "/account",
    name: "account",
    component: Account,
    redirect: {
      name: "log-in-page",
    },
    children: [
      {
        path: "/login",
        name: "log-in-page",
        component: LogIn,
        meta: {
          title: "Log in",
        },
      },
      {
        path: "reset-password/:code",
        name: "reset-password",
        component: ResetPassword,
        meta: {
          title: "Set password",
        },
      },
      {
        path: "/forgot-password",
        name: "forgot-password-page",
        component: ForgotPassword,
        meta: {
          title: "Forgot Password",
        },
      },
    ],
  },
  {
    path: "/primary-care-clinic/:idClinic",
    name: "primary-care-clinic",
    component: PrimaryCareClinic,
    redirect: { name: "primary-care-clinic.patient-referrals" },
    meta: {
      title: "Clinic Primary Care",
    },
    children: [
      {
        path: "reschedule/:idAppointment?",
        name: "primary-care-clinic.reschedule",
        component: PrimaryCareClinicReschedule,
        meta: {
          title: "Clinic - Primary Care - Reschedule",
        },
      },
      {
        path: "schedules",
        name: "primary-care-clinic.schedules",
        component: PrimaryCareClinicSchedules,
        meta: {
          title: "Clinic - Primary Care - Schedules",
        },
      },
      {
        path: "patient-referrals",
        name: "primary-care-clinic.patient-referrals",
        component: PrimaryCareClinicPatientReferrals,
        redirect: { name: "primary-care-clinic.patient-referrals.requested" },
        meta: {
          title: "Clinic - Primary Care - Patient Referrals",
        },
        children: [
          {
            path: "requested",
            name: "primary-care-clinic.patient-referrals.requested",
            component: PrimaryCareClinicPatientReferralsRequested,
            meta: {
              title: "Clinic - Primary Care - Patient Referrals - Requested",
            },
          },
          {
            path: "confirmed",
            name: "primary-care-clinic.patient-referrals.confirmed",
            component: PrimaryCareClinicPatientReferralsConfirmed,
            meta: {
              title: "Clinic - Primary Care - Patient Referrals - Confirmed",
            },
          },
          {
            path: "no-show-cancelled",
            name: "primary-care-clinic.patient-referrals.no-show-cancelled",
            component: PrimaryCareClinicPatientReferralsNoShowCancelled,
            meta: {
              title:
                "Clinic - Primary Care - Patient Referrals - No Show / Cancelled",
            },
          },
          {
            path: "archived",
            name: "primary-care-clinic.patient-referrals.archived",
            component: PrimaryCareClinicPatientReferralsArchived,
            meta: {
              title: "Clinic - Primary Care - Patient Referrals - Archived",
            },
          },
        ],
      },
    ],
  },
  {
    path: "/primary-care-community-organization/:idCommunity/patient-search",
    name: "primary-care-community-organization.patient-search",
    component: PrimaryCareCommunityOrganizationPatientSearch,
    meta: {
      title: "Community Organization - Patient Search",
    },
    children: [
      {
        path: "results",
        name: "primary-care-community-organization.patient-search.results",
        component: PrimaryCareCommunityOrganizationPatientSearchResults,
        meta: {
          title: "Community Organization - Patient Search Results",
        },
      },
    ],
  },
  {
    path: "/primary-care-community-organization/:idCommunity",
    name: "primary-care-community-organization",
    component: PrimaryCareCommunityOrganization,
    redirect: { name: "primary-care-community-organization.patient-referrals" },
    meta: {
      title: "Community Organization Primary Care",
    },
    children: [
      {
        path: "reschedule/:idAppointment?/clinic/:idClinic?",
        name: "primary-care-community-organization.reschedule",
        component: PrimaryCareCommunityOrganizationReschedule,
        meta: {
          title: "Community Organization - Patient Referral - Reschedule",
        },
      },
      {
        path: "schedules",
        name: "primary-care-community-organization.schedules",
        component: PrimaryCareCommunityOrganizationSchedules,
        meta: {
          title: "Community Organization - Primary Care - Schedules",
        },
      },
      {
        path: "scheduling/:idReferral?",
        name: "primary-care-community-organization.scheduling",
        redirect: {
          name: "primary-care-community-organization.scheduling.search-patient",
        },
        component: PrimaryCareCommunityOrganizationScheduling,
        meta: {
          title: "Community Organization - Primary Care - Scheduling",
        },
        children: [
          {
            path: "search-patient",
            name: "primary-care-community-organization.scheduling.search-patient",
            component: PrimaryCareCommunityOrganizationSchedulingSearchPatient,
            meta: {
              title: "Community Organization - Scheduling - Patient Search",
            },
          },
          {
            path: "select-patient",
            name: "primary-care-community-organization.scheduling.select-patient",
            component: PrimaryCareCommunityOrganizationSchedulingSelectPatient,
            meta: {
              title: "Community Organization - Scheduling - Select Patient",
            },
          },
          {
            path: "select-schedule/patient/:idPatient?/clinic/:idClinic?/",
            name: "primary-care-community-organization.scheduling.select-schedule",
            component: PrimaryCareCommunityOrganizationSchedulingSelectSchedule,
            meta: {
              title: "Community Organization - Scheduling - Select Schedule",
            },
          },
          {
            path: "appointment-info/patient/:idPatient?/schedule/:idSchedule?/clinic/:idClinic?/",
            name: "primary-care-community-organization.scheduling.appointment-info",
            component:
              PrimaryCareCommunityOrganizationSchedulingAppointmentInfo,
            meta: {
              title:
                "Community Organization - Scheduling - Appointment Details",
            },
          },
          {
            path: "confirm/patient/:idPatient?/schedule/:idSchedule?/clinic/:idClinic?/",
            name: "primary-care-community-organization.scheduling.confirm",
            component: PrimaryCareCommunityOrganizationSchedulingConfirm,
            meta: {
              title: "Community Organization - Scheduling - Confirm",
            },
          },
        ],
      },
      {
        path: "patient-referrals",
        name: "primary-care-community-organization.patient-referrals",
        component: PrimaryCareCommunityOrganizationPatientReferrals,
        redirect: {
          name: "primary-care-community-organization.patient-referrals.requested",
        },
        meta: {
          title: "Community Organization - Primary Care - Patient Referrals",
        },
        children: [
          {
            path: "requested",
            name: "primary-care-community-organization.patient-referrals.requested",
            component:
              PrimaryCareCommunityOrganizationPatientReferralsRequested,
            meta: {
              title:
                "Community Organization - Primary Care - Patient Referrals - Requested",
            },
          },
          {
            path: "confirmed",
            name: "primary-care-community-organization.patient-referrals.confirmed",
            component:
              PrimaryCareCommunityOrganizationPatientReferralsConfirmed,
            meta: {
              title:
                "Community Organization - Primary Care - Patient Referrals - Confirmed",
            },
          },
          {
            path: "no-show-cancelled",
            name: "primary-care-community-organization.patient-referrals.no-show-cancelled",
            component:
              PrimaryCareCommunityOrganizationPatientReferralsNoShowCancelled,
            meta: {
              title:
                "Clinic - Primary Care - Patient Referrals - No Show / Cancelled",
            },
          },
          {
            path: "archived",
            name: "primary-care-community-organization.patient-referrals.archived",
            component: PrimaryCareCommunityOrganizationPatientReferralsArchived,
            meta: {
              title:
                "Community Organization - Primary Care - Patient Referrals - Archived",
            },
          },
        ],
      },
    ],
  },

  {
    path: "/primary-care-community-organization/notifications",
    name: "primary-care-community-organization.notifications",
    component: PrimaryCareCommunityOrganizationNotifications,
    meta: {
      title: "Primary Care Community Organization Notifications",
    },
  },
  {
    path: "/hospitals/notifications",
    name: "hospitals.notifications",
    component: HospitalsNotifications,
    meta: {
      title: "Hospital Notifications",
    },
  },
  {
    path: "/provider-groups/:idProvider",
    name: "provider-groups",
    component: ProviderGroups,
    redirect: { name: "provider-groups.referral-status" },
    meta: {
      title: "Provider Groups",
    },
    props: ({ params }) => ({
      idProvider: params.idProvider,
      key: params.idReferral,
    }),
    children: [
      {
        path: "notifications",
        name: "provider.notifications",
        component: ProviderNotifications,
        meta: {
          title: "Provider Notifications",
        },
      },
      {
        path: "appointment-status",
        name: "provider-groups.referral-status",
        component: ProviderGroupsReferralStatus,
        redirect: {
          name: "provider-groups.referral-status.requested",
        },
        meta: {
          title: "Provider Group - Referral Status",
        },
        children: [
          {
            path: "unable-to-contact",
            name: "provider-groups.referral-status.unable-to-contact",
            component: ProviderGroupsReferralStatusUnableToContact,
            meta: {
              title: "Provider Group - Appointment Status - Unable to Contact",
            },
          },
          {
            path: "contacted",
            name: "provider-groups.referral-status.contacted",
            component: ProviderGroupsReferralStatusContacted,
            meta: {
              title: "Provider Group - Referral Status - Contacted",
            },
          },
          {
            path: "requested",
            name: "provider-groups.referral-status.requested",
            component: ProviderGroupsReferralStatusRequested,
            meta: {
              title: "Provider Group - Referral Status - Requested",
            },
          },
        ],
      },
    ],
  },
  {
    path: "/hospitals/:idHospital/specialty/:idSpecialty",
    name: "hospitals",
    component: Hospitals,
    redirect: { name: "hospitals.appointment-status" },
    meta: {
      title: "Hospital ",
    },
    props: ({ params }) => ({
      idHospital: params.idHospital,
      idSpecialty: params.idSpecialty,
      key: params.idSpecialty,
    }),
    children: [
      {
        path: "appointment-status",
        name: "hospitals.appointment-status",
        component: HospitalsAppointmentStatus,
        redirect: {
          name: "hospitals.appointment-status.requested",
        },
        meta: {
          title: "Hospital - Appointment Status",
        },
        children: [
          {
            path: "pending-clinic-review",
            name: "hospitals.appointment-status.pending-clinic-review",
            component: HospitalsAppointmentStatusPendingClinicReview,
            meta: {
              title: "Hospital - Appointment Status - Pending Clinic Review",
            },
          },
          {
            path: "archived",
            name: "hospitals.appointment-status.archived",
            component: HospitalsAppointmentStatusArchived,
            meta: {
              title: "Hospital - Appointment Status - Archived",
            },
          },
          {
            path: "confirmed",
            name: "hospitals.appointment-status.confirmed",
            component: HospitalsAppointmentStatusCofirmed,
            meta: {
              title: "Hospital - Appointment Status - Confirmed",
            },
          },
          {
            path: "requested",
            name: "hospitals.appointment-status.requested",
            component: HospitalsAppointmentStatusRequested,
            meta: {
              title: "Hospital - Appointment Status - Requested",
            },
          },
        ],
      },
      {
        path: "reschedule/:idAppointment?",
        name: "hospitals.appointment-status.reschedule",
        component: HospitalsReschedule,
        meta: {
          title: "Hospital - Appointment Status - Reschedule",
        },
      },
      {
        path: "schedules",
        name: "hospitals.schedules",
        component: HospitalsSchedules,
        meta: {
          title: "Hospital - Schedules",
        },
      },
      {
        path: "patient-search",
        name: "hospitals.patient-search",
        component: HospitalsPatientSearch,
        meta: {
          title: "Patient Search",
        },
        children: [
          {
            path: "results",
            name: "hospitals.patient-search.results",
            component: HospitalsPatientSearchResults,
            meta: {
              title: "Patient Search Results",
            },
          },
        ],
      },
      {
        path: "ongoing-care",
        name: "hospitals.ongoing-care",
        component: HospitalsOnGoingCare,
        redirect: { name: "hospitals.ongoing-care.active-patients" },
        meta: {
          title: "Hospital Ongoing Care",
        },
        children: [
          {
            path: "active-patients",
            name: "hospitals.ongoing-care.active-patients",
            component: HospitalsOnGoingCareActivePatients,
            meta: {
              title: "Hospital Ongoing Care - Active Patients",
            },
          },
          {
            path: "archived",
            name: "hospitals.ongoing-care.archived",
            component: HospitalsOnGoingCareArchived,
            meta: {
              title: "Hospital Ongoing Care - Archived",
            },
          },
        ],
      },
    ],
  },

  {
    path: "/clinics/:idClinic/patient-search",
    name: "clinics.patient-search",
    component: ClinicsPatientSearch,
    meta: {
      title: "Clinic Patient Search",
    },
    children: [
      {
        path: "results",
        name: "clinics.patient-search.results",
        component: ClinicsPatientSearchResults,
        meta: {
          title: "Patient Search Results",
        },
      },
    ],
  },
  {
    path: "/clinics/notifications",
    name: "clinics.notifications",
    component: ClinicsNotifications,
    meta: {
      title: "Clinic Notifications",
    },
  },
  {
    path: "/clinics/:idClinic/specialty/:idSpecialty",
    name: "clinics",
    component: Clinics,
    redirect: { name: "clinics.appointment-status" },
    meta: {
      title: "Clinic",
    },
    props: ({ params }) => ({
      idClinic: params.idClinic,
      idSpecialty: params.idSpecialty,
      key: params.idSpecialty,
    }),
    children: [
      {
        path: "appointment-status",
        name: "clinics.appointment-status",
        component: ClinicsAppointmentStatus,
        redirect: { name: "clinics.appointment-status.requested" },
        meta: {
          title: "Clinic - Appointment Status",
        },
        children: [
          {
            path: "pending-clinic-review",
            name: "clinics.appointment-status.pending-clinic-review",
            component: ClinicsAppointmentStatusPendingClinicReview,
            meta: {
              title: "Clinic - Appointment Status - Pending Clinic Review",
            },
          },
          {
            path: "archived",
            name: "clinics.appointment-status.archived",
            component: ClinicsAppointmentStatusArchived,
            meta: {
              title: "Clinic - Appointment Status - Archived",
            },
          },
          {
            path: "confirmed",
            name: "clinics.appointment-status.confirmed",
            component: ClinicsAppointmentStatusCofirmed,
            meta: {
              title: "Clinic - Appointment Status - Confirmed",
            },
          },
          {
            path: "requested",
            name: "clinics.appointment-status.requested",
            component: ClinicsAppointmentStatusRequested,
            meta: {
              title: "Clinic - Appointment Status - Requested",
            },
          },
        ],
      },
      {
        path: "reschedule/:idAppointment?",
        name: "clinics.appointment-status.reschedule",
        component: ClinicsReschedule,
        meta: {
          title: "Clinics - Appointment Status - Reschedule",
        },
      },
      {
        path: "schedules",
        name: "clinics.schedules",
        component: ClinicsSchedules,
        meta: {
          title: "Clinic - Schedules",
        },
      },
      {
        path: "scheduling",
        name: "clinics.scheduling",
        redirect: { name: "clinics.scheduling.search-patient" },
        component: ClinicScheduling,
        meta: {
          title: "Clinic Scheduling",
        },
        children: [
          {
            path: "search-patient",
            name: "clinics.scheduling.search-patient",
            component: ClinicSearchPatient,
            meta: {
              title: "Clinic Scheduling - Patient Search",
            },
          },
          {
            path: "select-patient",
            name: "clinics.scheduling.select-patient",
            component: ClinicSelectPatient,
            meta: {
              title: "Clinic Scheduling - Select Patient",
            },
          },
          {
            path: "select-schedule/patient/:idPatient?",
            name: "clinics.scheduling.select-schedule",
            component: ClinicSelectSchedule,
            meta: {
              title: "Clinic Scheduling - Select Schedule",
            },
          },
          {
            path: "referral-info/patient/:idPatient?/schedule/:idSchedule?/hospital/:idHospital?",
            name: "clinics.scheduling.referral-info",
            component: ClinicReferralInfo,
            meta: {
              title: "Clinic Scheduling - Referral Info",
            },
          },
          {
            path: "confirm/patient/:idPatient?/schedule/:idSchedule?/hospital/:idHospital?",
            name: "clinics.scheduling.confirm",
            component: ClinicConfirm,
            meta: {
              title: "Clinic Scheduling - Confirm",
            },
          },
        ],
      },
      {
        path: "ongoing-care",
        name: "clinics.ongoing-care",
        component: ClinicsOnGoingCare,
        redirect: { name: "clinics.ongoing-care.active-patients" },
        meta: {
          title: "Clinic Ongoing Care",
        },
        children: [
          {
            path: "active-patients",
            name: "clinics.ongoing-care.active-patients",
            component: ClinicsOnGoingCareActivePatients,
            meta: {
              title: "Clinic Ongoing Care - Active Patients",
            },
          },
          {
            path: "archived",
            name: "clinics.ongoing-care.archived",
            component: ClinicssOnGoingCareArchived,
            meta: {
              title: "Clinic Ongoing Care - Archived",
            },
          },
        ],
      },
    ],
  },
  {
    path: "/appointment-status",
    name: "appointment-status",
    component: AppointmentStatus,
    redirect: { name: "appointment-status.requested" },
    meta: {
      title: "Appointment Status",
    },
    children: [
      {
        path: "requested",
        name: "appointment-status.requested",
        component: Requested,
        meta: {
          title: "Appointment Status - Requested",
        },
      },
      {
        path: "confirmed",
        name: "appointment-status.confirmed",
        component: Confirmed,
        meta: {
          title: "Appointment Status - Confirmed",
        },
      },
    ],
  },
  {
    path: "/manage-care",
    name: "manage-care",
    component: ManageCare,
    redirect: { name: "manage-care.active-patients" },
    meta: {
      title: "Ongoing Care",
    },
    children: [
      {
        path: "active-patients",
        name: "manage-care.active-patients",
        component: ActivePatients,
        meta: {
          title: "Ongoing Care - Active Patients",
        },
      },
      {
        path: "archived",
        name: "manage-care.archived",
        component: Archived,
        meta: {
          title: "Ongoing Care - Archived",
        },
      },
    ],
  },
  {
    path: "/new-fit-kit/:idClinic",
    name: "new-fit-kit",
    redirect: { name: "new-fit-kit.search-patient" },
    component: NewFitKit,
    meta: {
      title: "New Fit Kit",
    },
    children: [
      {
        path: "search-patient",
        name: "new-fit-kit.search-patient",
        component: FitKitSearchPatient,
        meta: {
          title: "New Fit Kit - Patient Search",
        },
      },
      {
        path: "select-patient",
        name: "new-fit-kit.select-patient",
        component: FitKitSelectPatient,
        meta: {
          title: "New Fit Kit - Select Patient",
        },
      },
    ],
  },
  {
    path: "/fit-kit-status/:idClinic",
    name: "fit-kit-status",
    redirect: { name: "fit-kit-status.positive-fit" },
    component: FitKitStatus,
    meta: {
      title: "Fit Kit Status",
    },
    children: [
      {
        path: "positive-fit",
        name: "fit-kit-status.positive-fit",
        component: PositiveFit,
        meta: {
          title: "Fit Kit Status - Positive FIT",
        },
      },
      {
        path: "follow-up-due",
        name: "fit-kit-status.follow-up-due",
        component: FitFollowUpDue,
        meta: {
          title: "Fit Kit Status - Positive FIT",
        },
      },
      {
        path: "all-outstanding",
        name: "fit-kit-status.all-outstanding",
        component: FitAllOutstanding,
        meta: {
          title: "Fit Kit Status - All Outstanding",
        },
      },
      {
        path: "patient-follow-up-due",
        name: "fit-kit-status.patient-follow-up-due",
        component: FitAnnualDue,
        meta: {
          title: "Fit Kit Status - Patient Follow-Up Due",
        },
      },
      {
        path: "completed",
        name: "fit-kit-status.completed",
        component: FitCompleted,
        meta: {
          title: "Fit Kit Status - Completed",
        },
      },
    ],
  },
  {
    path: "/fit-outside-icc/:idClinic",
    name: "fit-outside-icc",
    redirect: { name: "fit-outside-icc.pending" },
    component: FitOutsideIcc,
    meta: {
      title: "Outside ICC",
    },
    children: [
      {
        path: "pending",
        name: "fit-outside-icc.pending",
        component: OutsideIccPending,
        meta: {
          title: "Outside ICC - Pending",
        },
      },
      {
        path: "archived",
        name: "fit-outside-icc.archived",
        component: OutsideIccArchived,
        meta: {
          title: "Outside ICC - Archived",
        },
      },
    ],
  },
  {
    path: "/fit-referrals/:idClinic",
    name: "fit-referrals",
    component: FitReferrals,
    meta: {
      title: "Referrals",
    },
  },
  //cco fit kit routes
  {
    path: "/cco-new-fit-kit/:idCommunity",
    name: "community-care-organization-new-fit-kit",
    redirect: {
      name: "community-care-organization-new-fit-kit.search-patient",
    },
    component: NewFitKitCommunityOrganization,
    meta: {
      title: "New Fit Kit",
    },
    children: [
      {
        path: "search-patient",
        name: "community-care-organization-new-fit-kit.search-patient",
        component: FitKitSearchPatientCommunityOrganization,
        meta: {
          title: "New Fit Kit - Patient Search",
        },
      },
      {
        path: "select-patient",
        name: "community-care-organization-new-fit-kit.select-patient",
        component: FitKitSelectPatientCommunityOrganization,
        meta: {
          title: "New Fit Kit - Select Patient",
        },
      },
    ],
  },

  {
    path: "/cco-referrals/:idCco/",
    name: "cco-referrals",
    redirect: {
      name: "cco-referrals.patient-referrals.requested",
    },
    component: CCOReferrals,
    meta: {
      title: "Community Care Organization ",
    },
    children: [
      {
        path: "patient-referrals",
        name: "cco-referrals.patient-referrals",
        component: CCOPatientReferrals,
        redirect: {
          name: "cco-referrals.patient-referrals.requested",
        },
        meta: {
          title: "Community Care Organization - Patient Referrals",
        },
        children: [
          {
            path: "unable-to-contact",
            name: "cco-referrals.patient-referrals.unable-to-contact",
            component: CCOPatientReferralsUnableToContact,
            meta: {
              title:
                "Community Care Organization - Patient Referrals - Unable to Contact",
            },
          },
          {
            path: "contacted",
            name: "cco-referrals.patient-referrals.contacted",
            component: CCOPatientReferralsContacted,
            meta: {
              title:
                "Community Care Organization - Patient Referrals - Contacted",
            },
          },
          {
            path: "requested",
            name: "cco-referrals.patient-referrals.requested",
            component: CCOPatientReferralsRequested,
            meta: {
              title:
                "Community Care Organization - Patient Referrals - Requested",
            },
          },
        ],
      },
      {
        path: "scheduling/:idReferral?",
        name: "cco-referrals.scheduling",
        redirect: {
          name: "cco-referrals.scheduling.search-patient",
        },
        component: CCOReferralsScheduling,
        meta: {
          title: "Referral Process - Scheduling",
        },
        children: [
          {
            path: "search-patient",
            name: "cco-referrals.scheduling.search-patient",
            component: CCOReferralsSchedulingSearchPatient,
            meta: {
              title: "Referral Process - Patient Search",
            },
          },
          {
            path: "select-patient",
            name: "cco-referrals.scheduling.select-patient",
            component: CCOReferralsSchedulingSelectPatient,
            meta: {
              title: "Referral Process - Select Patient",
            },
          },
          {
            path: "select-provider/patient/:idPatient?/",
            name: "cco-referrals.scheduling.select-provider",
            component: CCOReferralsSchedulingSelectProvider,
            meta: {
              title: "Referral Process - Select Provider",
            },
          },
          {
            path: "referral-info/patient/:idPatient?/provider/:idProvider?/",
            name: "cco-referrals.scheduling.referral-info",
            component: CCOReferralsSchedulingReferralInfo,
            meta: {
              title: "Referral Process - Referral Details",
            },
          },
          {
            path: "confirmation-info/patient/:idPatient?/provider/:idProvider?/",
            name: "cco-referrals.scheduling.confirm",
            component: CCOReferralsSchedulingConfirm,
            meta: {
              title: "Referral Process - Confirm",
            },
          },
        ],
      },
    ],
  },

  {
    path: "/cco-fit-kit-status/:idCommunity",
    name: "community-care-organization-fit-kit-status",
    redirect: {
      name: "community-care-organization-fit-kit-status.positive-fit",
    },
    component: FitKitStatusCommunityOrganization,
    meta: {
      title: "Fit Kit Status",
    },
    children: [
      {
        path: "positive-fit",
        name: "community-care-organization-fit-kit-status.positive-fit",
        component: PositiveFitCommunityOrganization,
        meta: {
          title: "Fit Kit Status - Positive FIT",
        },
      },
      {
        path: "follow-up-due",
        name: "community-care-organization-fit-kit-status.follow-up-due",
        component: FitFollowUpDueCommunityOrganization,
        meta: {
          title: "Fit Kit Status - Positive FIT",
        },
      },
      {
        path: "all-outstanding",
        name: "community-care-organization-fit-kit-status.all-outstanding",
        component: FitAllOutstandingCommunityOrganization,
        meta: {
          title: "Fit Kit Status - All Outstanding",
        },
      },
      {
        path: "patient-follow-up-due",
        name: "community-care-organization-fit-kit-status.patient-follow-up-due",
        component: FitAnnualDueCommunityOrganization,
        meta: {
          title: "Fit Kit Status - Patient Follow-Up Due",
        },
      },
      {
        path: "completed",
        name: "community-care-organization-fit-kit-status.completed",
        component: FitCompletedCommunityOrganization,
        meta: {
          title: "Fit Kit Status - Completed",
        },
      },
    ],
  },
  {
    path: "/cco-fit-kit-status/:idCommunity",
    name: "community-care-organization-fit-kit-status",
    redirect: {
      name: "community-care-organization-fit-kit-status.positive-fit",
    },
    component: FitKitStatusCommunityOrganization,
    meta: {
      title: "Fit Kit Status",
    },
    children: [
      {
        path: "positive-fit",
        name: "community-care-organization-fit-kit-status.positive-fit",
        component: PositiveFitCommunityOrganization,
        meta: {
          title: "Fit Kit Status - Positive FIT",
        },
      },
      {
        path: "follow-up-due",
        name: "community-care-organization-fit-kit-status.follow-up-due",
        component: FitFollowUpDueCommunityOrganization,
        meta: {
          title: "Fit Kit Status - Positive FIT",
        },
      },
      {
        path: "all-outstanding",
        name: "community-care-organization-fit-kit-status.all-outstanding",
        component: FitAllOutstandingCommunityOrganization,
        meta: {
          title: "Fit Kit Status - All Outstanding",
        },
      },
      {
        path: "patient-follow-up-due",
        name: "community-care-organization-fit-kit-status.patient-follow-up-due",
        component: FitAnnualDueCommunityOrganization,
        meta: {
          title: "Fit Kit Status - Patient Follow-Up Due",
        },
      },
      {
        path: "completed",
        name: "community-care-organization-fit-kit-status.completed",
        component: FitCompletedCommunityOrganization,
        meta: {
          title: "Fit Kit Status - Completed",
        },
      },
    ],
  },
  {
    path: "/organizations",
    name: "organizations",
    redirect: { name: "organizations.clinic-health-systems" },
    component: Organizations,
    meta: {
      title: "Organizations",
    },
    children: [
      {
        path: "clinic-health-systems",
        name: "organizations.clinic-health-systems",
        component: ClinicHealthSystems,
        meta: {
          title: "Clinic Health Systems",
        },
      },
      {
        path: "community-organizations",
        name: "organizations.community-organizations",
        component: CommunityOrganizations,
        meta: {
          title: "Community Organizations",
        },
      },
      {
        path: "hospitals",
        name: "organizations.hospitals",
        component: OrganizationsHospitals,
        meta: {
          title: "Hospitals",
        },
      },
      {
        path: "provider-groups",
        name: "organizations.provider-groups",
        component: OrganizationsProviderGroups,
        meta: {
          title: "Provider Groups",
        },
      },
    ],
  },
  {
    path: "/users-super-admin",
    name: "users-super-admin",
    component: UsersSuperAdmin,
    meta: {
      title: "Users Super Admin",
    },
    beforeEnter() {
      return store.getters.isSuperAdmin;
    },
  },
  {
    path: "/users-providers/:idProviderGroup",
    name: "users-providers",
    component: UsersProviders,
    meta: {
      title: "Users Providers",
    },
  },
  {
    path: "/users-clinical-health-systems/:idClinicHealthSystem",
    name: "users-clinical-health-systems",
    component: UsersClinicalHealthSystems,
    meta: {
      title: "Users Clinical Health Systems",
    },
  },
  {
    path: "/users-hospitals/:idHospital",
    name: "users-hospitals",
    component: UsersHospitals,
    meta: {
      title: "Users Hospitals",
    },
  },
  {
    path: "/users-community-organizations/:idCommunity",
    name: "users-community-organizations",
    component: UsersCommunity,
    meta: {
      title: "Users Community",
    },
  },
  {
    path: "/chs/:chsId/all-clinics",
    name: "all-clinics",
    component: AllClinics,
    meta: {
      title: "All Clinics",
    },
  },
  {
    path: "/all-actions/:idEntity?",
    name: "all-actions",
    component: AllActions,
    meta: {
      title: "All Actions",
    },
  },
  {
    path: "/reports",
    name: "reports",
    component: Reports,
    meta: {
      title: "Reports",
    },
  },
  {
    path: "/system-settings",
    name: "system-settings",
    redirect: { name: "system-settings.specialty-modules" },
    component: SystemSettings,
    meta: {
      title: "System Settings",
    },
    beforeEnter() {
      if (store.getters.isSuperAdmin) {
        return true;
      } else {
        store.dispatch("logOut");
        return false;
      }
    },
    children: [
      {
        path: "specialty-modules",
        name: "system-settings.specialty-modules",
        component: SpecialtyModules,
        meta: {
          title: "Specialty Care",
        },
      },
      {
        path: "primary-care-modules",
        name: "system-settings.primary-care-modules",
        component: PrimaryCareModules,
        meta: {
          title: "Primary Care Modules",
        },
      },
      {
        path: "referrals",
        name: "system-settings.referrals",
        component: Referrals,
        meta: {
          title: "Referrals",
        },
      },
      {
        path: "insurances",
        name: "system-settings.insurances",
        component: Insurances,
        meta: {
          title: "Insurances",
        },
      },
      {
        path: "settings",
        name: "system-settings.settings",
        component: Settings,
        meta: {
          title: "Settings",
        },
      },
    ],
  },
  {
    path: "/clinic/:idClinic/dashboard/",
    name: "dashboard",
    component: Dashboard,
    meta: {
      title: "Dashboard",
    },
  },
  {
    path: "/style-guide",
    name: "style-guide",
    component: StyleGuide,
    meta: {
      title: "Style Guide",
    },
  },
  {
    path: "/api-demo",
    name: "api-demo",
    component: APIDemo,
    meta: {
      title: "API Demo",
    },
  },
  {
    path: "/resources-and-help",
    name: "resources-and-help",
    component: ResourcesHelp,
    meta: {
      title: "Resources & Help",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Updating Page Title
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);
  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) document.title = "ICC " + nearestWithTitle.meta.title;
  next();
});

router.beforeEach((to: Route, from, next) => {
  if (
    to.name === "reset-password" ||
    to.name === "log-in-page" ||
    to.name === "forgot-password-page"
  ) {
    store.dispatch("logOut", false);
  }
  next();
});

//logged out won't allow user to see system views, only authentication.
router.beforeEach((to: Route, from, next) => {
  const authRoutes = routes
    .find((route) => route.name === "account")
    ?.children?.map((routeChild) => routeChild.name);
  if (!authRoutes?.includes(to.name) && !store.getters.isAuthenticated) {
    router.push({ name: "log-in-page" });
  }

  next();
});

export default router;
