<template lang="pug">
.column.is-one-third
  .form-group(:class="{ 'form-group__invalid': v$.inputValue.$errors.length }")
    label(:for="labelKebabCase") {{label}}
    select(
      :id="labelKebabCase",
      v-model="inputValue"
      @change="$emit('update:modelValue', $event.target.value)",
      @blur="v$.inputValue.$touch()"
    )
      option(
        :value="null",
        :selected="modelValue == null",
        disabled="true",
      ) Select {{label}}
      option(
        v-for="(item, index) in weekNumbersArray",
        :key="index",
        :value="item",
        :selected="item === modelValue",
      ) {{getName(item)}}
    .form-group__errors(v-if="v$.inputValue.$dirty && v$.inputValue.$errors.length")
      .form-group__error(v-if="v$.inputValue.required.$invalid") {{ label }} is required!
      .form-group__error(v-if="v$.inputValue.secondWeekBigger.$invalid && !v$.inputValue.required.$invalid") Second Follow-Up time period must be greater than First Follow-Up
</template>

<script>
import { defineComponent } from "vue";
import kebabCase from "lodash/kebabCase";
import { v1 as uuidv1 } from "uuid";

import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default defineComponent({
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    let args = {
      inputValue: {
        required,
        secondWeekBigger: true,
      },
    };
    if (this.firstWeekNumber) {
      args.inputValue = {
        required,
        secondWeekBigger: function (val) {
          if (val <= this.firstWeekNumber) {
            return false;
          }
          return true;
        },
      };
    }
    return args;
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    weekNumbersArray: {
      type: Array,
      required: true,
    },
    modelValue: {
      required: true,
    },
    firstWeekNumber: {
      required: false,
    },
  },
  watch: {
    firstWeekNumber() {
      const defaultValue = this.v$.inputValue.$model;
      this.v$.inputValue.$model = null;
      this.v$.inputValue.$model = defaultValue;
    },
  },
  data() {
    return {
      inputValue: this.modelValue,
    };
  },
  computed: {
    labelKebabCase() {
      if (!this.label) return "";
      return `${kebabCase(this.label)}-${uuidv1()}`;
    },
  },

  methods: {
    getName(numberOfWeeks) {
      return `${numberOfWeeks} ${numberOfWeeks == 1 ? "Week" : "Weeks"}`;
    },
  },
});
</script>

<style scoped lang="scss"></style>
