// @ts-nocheck
import { requestForgetPassword } from "@/api/accountApi";
import api from "@/api/api";
import { getHospitalSpecialty } from "@/api/hospitalApi";
import {
  getUserDefaultSpecialty,
  getUserDoctorSpecialties,
} from "@/api/userApi";
import router from "@/router";
import IdleJs from "idle-js";
import { cloneDeep } from "lodash";
import router from "./router";
// import { getProviderGroupIDforManager } from "@/api/providerGroupApi";

export const userRoles = {
  all: [
    {
      id: 1,
      subSystem: "admin",
      name: "CARES TEAM",
    },
    {
      id: 2,
      subSystem: "clinichealthsystem",
      name: "Clinic Health System Manager",
    },
    {
      id: 3,
      subSystem: "clinichealthsystem",
      name: "Clinic Staff in Training",
    },
    {
      id: 4,
      subSystem: "clinichealthsystem",
      name: "Clinic Staff",
    },
    {
      id: 5,
      subSystem: "clinichealthsystem",
      name: "Clinic Doctor",
    },
    {
      id: 6,
      subSystem: "hospital",
      name: "Hospital Manager",
    },
    {
      id: 7,
      subSystem: "hospital",
      name: "Hospital Staff",
    },
    {
      id: 8,
      subSystem: "hospital",
      name: "Hospital Doctor",
    },
    {
      id: 9,
      subSystem: "communitycareorganization",
      name: "Community Care Organization Manager",
    },
    {
      id: 10,
      subSystem: "communitycareorganization",
      name: "Community Care Organization Staff",
    },
    {
      id: 11,
      subSystem: "provider",
      name: "Provider Group Manager",
    },
    {
      id: 12,
      subSystem: "provider",
      name: "Provider",
    },
  ],
  admin: [
    {
      id: 1,
      subSystem: "admin",
      name: "CARES TEAM",
    },
  ],
  provider: [
    {
      id: 11,
      subSystem: "provider",
      name: "Provider Group Manager",
    },
    {
      id: 12,
      subSystem: "provider",
      name: "Provider",
    },
  ],
  clinichealthsystem: [
    {
      id: 2,
      subSystem: "clinichealthsystem",
      name: "Clinic Health System Manager",
    },
    {
      id: 3,
      subSystem: "clinichealthsystem",
      name: "Clinic Staff in Training",
    },
    {
      id: 4,
      subSystem: "clinichealthsystem",
      name: "Clinic Staff",
    },
    {
      id: 5,
      subSystem: "clinichealthsystem",
      name: "Clinic Doctor",
    },
  ],
  hospital: [
    {
      id: 6,
      subSystem: "hospital",
      name: "Hospital Manager",
    },
    {
      id: 7,
      subSystem: "hospital",
      name: "Hospital Staff",
    },
    {
      id: 8,
      subSystem: "hospital",
      name: "Hospital Doctor",
    },
  ],
  communitycareorganization: [
    {
      id: 9,
      subSystem: "communitycareorganization",
      name: "Community Care Organization Manager",
    },
    {
      id: 10,
      subSystem: "communitycareorganization",
      name: "Community Care Organization Staff",
    },
  ],
};

export default {
  state() {
    return {
      JWT: null,
      userId: null,
      userEmail: null,
      userFirstName: null,
      userLastName: null,
      userRole: null,
      userRoleContext: null,
      impersonateStack: [],
      countDownModalOpen: false,
      sessionExpiredModalOpen: false,
      lastIdleTimestamp: 0,
      lastGoneTimestamp: 0,
      minutesRemaining: 3,
      secondsRemaining: 0,
      managerOfProviderID: null,
      managerOfProviderGroupName: null,
    };
  },
  getters: {
    getProviderGroupName(state) {
      if (state.managerOfProviderGroupName) {
        return state.managerOfProviderGroupName;
      }
      return state.userRoleContext?.providerGroup?.name;
    },
    getProviderGroup(state) {
      return state.userRoleContext?.providerGroup;
    },
    aProviderGroupIsSelected(state, getters) {
      return (
        getters.getUserRoleContext.providerGroup?.id > 0 ||
        state.managerOfProviderID > 0
      );
    },
    getRole(state) {
      return state.impersonateStack[0]?.role || state.userRole;
    },
    getUserId(state) {
      return state.userId;
    },
    getUserFirstName(state) {
      return `${state.userFirstName}`;
    },
    getUserFullName(state) {
      return `${state.userFirstName} ${state.userLastName}`;
    },
    getUserRoleName(state, getters) {
      const role = getters.getRole;
      return role?.name;
    },
    getUserRoleContext(state) {
      return state.userRoleContext;
    },
    getImpersonationStack(state) {
      return state.impersonateStack;
    },
    showLeaveImpersonateHeader(state) {
      const impersonateHead = state.impersonateStack[0];
      return impersonateHead ? !impersonateHead.hiddenImpersonate : false;
    },
    isSuperAdmin(state, getters) {
      return getters.getRole?.subSystem === "admin";
    },
    isProvider(state, getters) {
      return getters.getRole?.subSystem === "provider";
    },
    isProviderManager(state, getters) {
      return getters.getRole?.name === "Provider Group Manager";
    },
    isClinicHealthSystemManager(state, getters) {
      return getters.getRole?.name === "Clinic Health System Manager";
    },
    isClinicStaff(state, getters) {
      return getters.getRole?.name === "Clinic Staff";
    },
    isClinicStaffInTraining(state, getters) {
      return getters.getRole?.name === "Clinic Staff in Training";
    },
    isClinicDoctor(state, getters) {
      return getters.getRole?.name === "Clinic Doctor";
    },
    isHospitalManager(state, getters) {
      return getters.getRole?.name === "Hospital Manager";
    },
    isHospitalStaff(state, getters) {
      return getters.getRole?.name === "Hospital Staff";
    },
    isHospitalDoctor(state, getters) {
      return getters.getRole?.name === "Hospital Doctor";
    },
    isCommunityCareOrganizationManager(state, getters) {
      return getters.getRole?.name === "Community Care Organization Manager";
    },
    isCommunityCareOrganizationStaff(state, getters) {
      return getters.getRole?.name === "Community Care Organization Staff";
    },
    isSubSystemHospital(state, getters) {
      return getters.getRole?.subSystem === "hospital";
    },
    isSubSystemProvider(state, getters) {
      return getters.getRole?.subSystem === "provider";
    },
    isSubSystemClinic(state, getters) {
      return getters.getRole?.subSystem === "clinichealthsystem";
    },
    isSubSystemCommunityOrganization(state, getters) {
      return getters.getRole?.subSystem === "communitycareorganization";
    },
    destinationType(state, getters) {
      if (getters.getRole?.subSystem === "hospital") return "Hospital";
      if (getters.getRole?.subSystem === "clinichealthsystem") return "Clinic";
    },
    showSessionCountdownModal(state) {
      return state.countDownModalOpen;
    },
    showSessionExpiredModal(state) {
      return state.sessionExpiredModalOpen;
    },
    isAuthenticated(state) {
      return !!(state.JWT && state.userId);
    },
    getMinutesRemaining(state) {
      return state.minutesRemaining;
    },
    getSecondsRemaining(state) {
      return state.secondsRemaining;
    },
    aChsIsSelected(state, getters) {
      return getters.getUserRoleContext.clinicHealthSystem;
    },
    getChsId(state, getters) {
      if (getters.aChsIsSelected) {
        return getters.getUserRoleContext.clinicHealthSystem.id;
      }

      return 0;
    },
    getManagerOfProviderID(state) {
      return state.managerOfProviderID;
    },
  },
  mutations: {
    COMMIT_JWT(state, payload) {
      state.JWT = payload;
      localStorage.setItem("JWT", payload);
      api.defaults.headers.common["Authorization"] = `Bearer ${payload}`;
    },
    COMMIT_USER_ID(state, payload) {
      state.userId = payload;
      localStorage.setItem("userId", payload);
    },
    COMMIT_USER_EMAIL(state, payload) {
      state.userEmail = payload;
      localStorage.setItem("userEmail", payload);
    },
    COMMIT_USER_FIRST_NAME(state, payload) {
      state.userFirstName = payload;
      localStorage.setItem("userFirstName", payload);
    },
    COMMIT_USER_LAST_NAME(state, payload) {
      state.userLastName = payload;
      localStorage.setItem("userLastName", payload);
    },
    COMMIT_USER_ROLE(state, payload) {
      const roleObject = userRoles.all.find((role) => role.id === payload);
      if (roleObject) {
        state.userRole = roleObject;
        localStorage.setItem("userRole", JSON.stringify(roleObject));
      }
    },
    COMMIT_USER_ROLE_CONTEXT(state, payload) {
      state.userRoleContext = payload;
      localStorage.setItem("userRoleContext", JSON.stringify(payload));
    },
    COMMIT_IMPERSONATION_STACK(state, payload) {
      state.impersonateStack = payload;
      localStorage.setItem("impersonateStack", JSON.stringify(payload));
    },
    COMMIT_OPEN_COUNTDOWN_MODAL(state, payload) {
      state.countDownModalOpen = payload;
    },
    COMMIT_OPEN_SESSION_EXPIRED_MODAL(state, payload) {
      state.sessionExpiredModalOpen = payload;
    },
    COMMIT_LAST_IDLE_TIMESTAMP(state, payload) {
      state.lastIdleTimestamp = payload;
      localStorage.setItem("lastIdleTimestamp", payload);
    },
    COMMIT_LAST_GONE_TIMESTAMP(state, payload) {
      state.lastGoneTimestamp = payload;
      localStorage.setItem("lastGoneTimestamp", payload);
    },
    COMMIT_SECONDS_REMAINING(state, payload) {
      state.secondsRemaining = payload;
    },
    COMMIT_MINUTES_REMAINING(state, payload) {
      state.minutesRemaining = payload;
    },
    COMMIT_MANAGER_OF_PROVIDER_GROUP_NAME(state, payload) {
      localStorage.setItem("managerOfProviderGroupName", payload);
      state.managerOfProviderGroupName = payload;
    },
    COMMIT_MANAGER_OF_PROVIDER_ID(state, payload) {
      localStorage.setItem("managerOfProviderID", payload);
      state.managerOfProviderID = payload;
      if (state.userRoleContext?.providerGroup) {
        state.userRoleContext.providerGroup.id = payload;
      } else {
        state.userRoleContext = { providerGroup: { id: payload } };
      }
    },
  },
  actions: {
    goToProviderDashboard({ getters }: { getters: any }) {
      let id = getters.getUserId;
      if (getters.getUserRoleContext.impersonateProviderID) {
        id = getters.getUserRoleContext.impersonateProviderID;
      }
      router.push({
        name: "provider-groups",
        params: {
          idProvider: id,
        },
      });
    },
    goToProviderGroupUsers({ getters }: { getters: any }) {
      const id = getters.getManagerOfProviderID;
      router.push({
        name: "users-providers",
        params: {
          idProviderGroup: id,
        },
      });
    },
    logOut({ commit, dispatch }, goToLogin = true) {
      commit("COMMIT_JWT", null);
      commit("COMMIT_USER_ID", null);
      commit("COMMIT_MANAGER_OF_PROVIDER_ID", null);
      commit("COMMIT_MANAGER_OF_PROVIDER_GROUP_NAME", null);
      commit("COMMIT_USER_EMAIL", null);
      commit("COMMIT_USER_FIRST_NAME", null);
      commit("COMMIT_USER_LAST_NAME", null);
      commit("COMMIT_USER_ROLE_CONTEXT", null);
      commit("COMMIT_IMPERSONATION_STACK", []);
      commit("COMMIT_MINUTES_REMAINING", 3);
      commit("COMMIT_LAST_IDLE_TIMESTAMP", 0);
      commit("COMMIT_LAST_GONE_TIMESTAMP", 0);

      dispatch("clearCurrentClinic");
      dispatch("clearCurrentHospital");
      dispatch("clearCurrentCCO");
      localStorage.clear();
      if (goToLogin) {
        router.push({ name: "log-in-page" });
      }
    },
    showSessionCountdownModal({ commit }, state = true) {
      commit("COMMIT_OPEN_COUNTDOWN_MODAL", state);
      if (state) {
        commit("COMMIT_LAST_IDLE_TIMESTAMP", new Date().getTime());
      }
    },
    showSessionExpiredModal({ commit }, state = true) {
      commit("COMMIT_OPEN_SESSION_EXPIRED_MODAL", state);
    },

    checkLastGoneTimestamp({ state, dispatch, commit }) {
      const lastGoneTimestampDiff =
        (new Date().getTime() - state.lastGoneTimestamp) / (1000 * 60); //minutes

      if (lastGoneTimestampDiff >= 30) {
        dispatch("logOut");
        dispatch("showSessionExpiredModal", true);
        dispatch("showSessionCountdownModal", false);
      } else {
        commit("COMMIT_LAST_GONE_TIMESTAMP", 0);
      }
    },

    startIdleTimeout({ state, dispatch, commit }) {
      const checkAndLogout = () => {
        if (state.lastGoneTimestamp) {
          dispatch("checkLastGoneTimestamp");
          //nothing else to do
          return;
        }

        if (state.lastIdleTimestamp) {
          const elapsedTime = new Date().getTime() - state.lastIdleTimestamp;
          const elapsedMinutes = Math.floor(elapsedTime / (1000 * 60));

          if (elapsedMinutes >= 3) {
            dispatch("logOut");
            dispatch("showSessionExpiredModal", true);
            dispatch("showSessionCountdownModal", false);
          } else {
            commit("COMMIT_MINUTES_REMAINING", 2 - elapsedMinutes);
            commit("COMMIT_LAST_IDLE_TIMESTAMP", 0);
          }
        }
      };

      const idle = new IdleJs({
        idle: 1000 * 60 * 12, // idle time in ms, should be 12 min
        events: ["mousemove", "keydown", "mousedown"], // events that will trigger the idle resetter
        onIdle: function () {
          if (state.JWT) {
            dispatch("showSessionCountdownModal");
          }
        },
        onActive: function () {
          checkAndLogout();
        },
        onShow: function () {
          checkAndLogout();
        },
        onHide: function () {
          commit("COMMIT_LAST_GONE_TIMESTAMP", new Date().getTime());
        },
        keepTracking: true, // set it to false if you want to be notified only on the first idleness change
        startAtIdle: false,
      });
      idle.start();
    },
    impersonate({ commit, state, dispatch }, payload) {
      const impersonate = {
        role: payload.role,
        hiddenImpersonate: payload.hiddenImpersonate,
      };

      const context = {};

      if (payload.clinicHealthSystem) {
        impersonate.clinicHealthSystem = payload.clinicHealthSystem;
        context.clinicHealthSystem = payload.clinicHealthSystem;
      } else if (payload.hospital) {
        impersonate.hospital = payload.hospital;
        context.hospital = payload.hospital;
      } else if (payload.clinic) {
        impersonate.clinic = payload.clinic;
        context.clinic = payload.clinic;
      } else if (payload.communityCareOrganization) {
        impersonate.communityCareOrganization =
          payload.communityCareOrganization;
        context.communityCareOrganization = payload.communityCareOrganization;
      } else if (payload.providerGroup) {
        if (typeof payload.providerGroup === "number") {
          const payloadProviderGroup = state.userRoleContext.providerGroup;
          payloadProviderGroup.id = payload.providerGroup;
          context.providerGroup = payloadProviderGroup;
          context.impersonateProviderID = payload.impersonateProviderID;
          impersonate.providerGroup = payloadProviderGroup;
        } else {
          context.providerGroup = payload.providerGroup;
          impersonate.providerGroup = payload.providerGroup;
        }
      }

      const impersonateStack = payload.flush
        ? [impersonate]
        : [impersonate, ...state.impersonateStack];

      commit("COMMIT_IMPERSONATION_STACK", impersonateStack);
      commit("COMMIT_USER_ROLE_CONTEXT", context);
      dispatch("navigateAccordingToRole");
    },
    leaveImpersonate({ commit, state, dispatch, getters }) {
      if (getters.getUserRoleContext.clinicHealthSystem) {
        dispatch("clearCurrentClinic");
        if (state.impersonateStack.length === 1) {
          //user leaving impersonation of CHS MANAGER
          commit("COMMIT_USER_ROLE_CONTEXT", {});
        }
      }

      if (
        getters.getUserRoleContext.providerGroup &&
        state.impersonateStack.length === 1
      ) {
        commit("COMMIT_USER_ROLE_CONTEXT", {});
      }

      if (getters.getUserRoleContext.hospital) {
        dispatch("clearCurrentHospital");
        commit("COMMIT_USER_ROLE_CONTEXT", {});
      }

      if (getters.getUserRoleContext.communityCareOrganization) {
        dispatch("clearCurrentCCO");
        commit("COMMIT_USER_ROLE_CONTEXT", {});
      }

      const impersonateStack = [...cloneDeep(state.impersonateStack)];
      impersonateStack.shift();
      commit("COMMIT_IMPERSONATION_STACK", impersonateStack);
      dispatch("navigateAccordingToRole");
    },
    impersonateClinicHealthSystemManager({ dispatch }, payload) {
      const role = userRoles.clinichealthsystem.find(
        (role) => role.name === "Clinic Health System Manager"
      );

      dispatch("impersonate", {
        role,
        clinicHealthSystem: payload[0],
        hiddenImpersonate: payload[1],
      });
    },
    impersonateHospitalManager({ dispatch, getters }) {
      dispatch("impersonate", {
        role: userRoles.hospital.find(
          (role) => role.name === "Hospital Manager"
        ),
        hiddenImpersonate: true,
        flush: true,
        hospital: getters.getUserRoleContext.hospital,
      });
    },
    impersonateClinicStaff({ dispatch, getters }) {
      dispatch("impersonate", {
        role: userRoles.clinichealthsystem.find(
          (role) => role.name === "Clinic Staff"
        ),
        clinicHealthSystem: getters.getUserRoleContext.clinicHealthSystem,
      });
    },
    navigateAccordingToRole({ getters, dispatch }) {
      const context = getters.getUserRoleContext;
      if (
        getters.isHospitalManager ||
        getters.isHospitalStaff ||
        getters.isHospitalDoctor
      ) {
        dispatch("fetchHospitalSpecialtiesAndNavigate", context.hospital.id);
      } else if (getters.isSuperAdmin) {
        router.push({ name: "organizations.clinic-health-systems" });
      } else if (
        getters.isClinicHealthSystemManager ||
        getters.isClinicStaff ||
        getters.isClinicStaffInTraining ||
        getters.isClinicDoctor
      ) {
        if (context.clinicHealthSystem) {
          router.push({
            name: "all-clinics",
            params: { chsId: context.clinicHealthSystem.id },
          });
        }
      } else if (getters.isSubSystemCommunityOrganization) {
        if (getters.showCCOPrimaryCare) {
          return router.push({
            name: "primary-care-community-organization",
            params: { idCommunity: getters.getCCOId },
          });
        } else if (getters.showCCOReferralModule) {
          return router.push({
            name: "cco-referrals",
            params: { idCco: getters.getCCOId },
          });
        } else if (getters.showCCOFitRegistry) {
          return router.push({
            name: "community-care-organization-fit-kit-status",
            params: { idCommunity: getters.getCCOId },
          });
        }
        return router.push({
          name: "home",
        });
      } else if (getters.isProviderManager) {
        const id = getters.getManagerOfProviderID;
        return router.push({
          name: "users-providers",
          params: {
            idProviderGroup: id,
          },
        });
      } else if (getters.isProvider) {
        const userId = getters.getUserId;
        return router.push({
          name: "provider-groups",
          params: { idProvider: userId },
        });
      } else {
        router.push({ name: "home" });
      }
    },

    async restoreSessionFromStorage({ commit, dispatch, getters }) {
      const storedJWT = localStorage.getItem("JWT");
      if (storedJWT) {
        commit("COMMIT_JWT", storedJWT);
        dispatch("startIdleTimeout");
      } else {
        dispatch("logOut", false);
      }

      const storedManagerOfProviderID = localStorage.getItem(
        "managerOfProviderID"
      );
      if (storedManagerOfProviderID) {
        commit("COMMIT_MANAGER_OF_PROVIDER_ID", storedManagerOfProviderID);
      }
      const storedManagerOfProviderGroupName = localStorage.getItem(
        "managerOfProviderGroupName"
      );
      if (storedManagerOfProviderGroupName) {
        commit(
          "COMMIT_MANAGER_OF_PROVIDER_GROUP_NAME",
          storedManagerOfProviderGroupName
        );
      }

      const storedUserId = localStorage.getItem("userId");
      if (storedUserId) {
        commit("COMMIT_USER_ID", storedUserId);
      }

      const storedUserEmail = localStorage.getItem("userEmail");
      if (storedUserId) {
        commit("COMMIT_USER_EMAIL", storedUserEmail);
      }

      const storedUserFirstName = localStorage.getItem("userFirstName");
      if (storedUserFirstName) {
        commit("COMMIT_USER_FIRST_NAME", storedUserFirstName);
      }
      const storedUserLastName = localStorage.getItem("userLastName");
      if (storedUserLastName) {
        commit("COMMIT_USER_LAST_NAME", storedUserLastName);
      }
      const storedUserRole = localStorage.getItem("userRole");
      if (storedUserRole) {
        commit("COMMIT_USER_ROLE", JSON.parse(storedUserRole).id);
      }

      const storedImpersonateStack = localStorage.getItem("impersonateStack");
      if (storedImpersonateStack) {
        commit(
          "COMMIT_IMPERSONATION_STACK",
          JSON.parse(storedImpersonateStack)
        );
      }

      const storedUserRoleContext = localStorage.getItem("userRoleContext");
      if (storedUserRoleContext) {
        commit("COMMIT_USER_ROLE_CONTEXT", JSON.parse(storedUserRoleContext));
        dispatch("fetchBaseDataForContext", JSON.parse(storedUserRoleContext));
      }

      const lastIdleTimestamp = localStorage.getItem("lastIdleTimestamp");
      if (lastIdleTimestamp) {
        commit("COMMIT_LAST_IDLE_TIMESTAMP", lastIdleTimestamp);
      }

      const lastGoneTimestamp = localStorage.getItem("lastGoneTimestamp");
      if (lastGoneTimestamp && +lastGoneTimestamp > 0) {
        commit("COMMIT_LAST_GONE_TIMESTAMP", lastGoneTimestamp);
        dispatch("checkLastGoneTimestamp");
      } else {
        commit("COMMIT_LAST_GONE_TIMESTAMP", 0);
      }

      if (getters.getUserRoleContext?.clinicHealthSystem) {
        const currentClinic = localStorage.getItem("currentClinicId");
        if (currentClinic) {
          dispatch("getFullClinicDashboard", +currentClinic);
        }
      }

      if (getters.getUserRoleContext?.hospital) {
        const currentHospital = localStorage.getItem("currentHospitalId");
        if (currentHospital) {
          dispatch("fetchHospitalDashboard", currentHospital);
        }
      }

      if (getters.getUserRoleContext?.communityCareOrganization) {
        const currentCCO = localStorage.getItem(
          "currentCommunityOrganizationId"
        );
        dispatch(
          "getCCOinfo",
          getters.getUserRoleContext?.communityCareOrganization
        );
      }

      if (localStorage.getItem("userSigninCount") === "0") {
        const {
          data: { code },
        } = await requestForgetPassword(storedUserEmail, true);
        router.push({ name: "reset-password", params: { code } });
      }
    },
    async fetchProvidersAndNavigate({ commit, getters }, id) {
      commit("COMMIT_MANAGER_OF_PROVIDER_ID", id);
      try {
        router.push({
          name: "users-providers",
          params: {
            idProviderGroup: id,
          },
        });
      } catch (error) {
        console.log(error);
      }
    },
    //TODO: move to custom store
    async fetchHospitalSpecialtiesAndNavigate({ commit, getters }, id) {
      try {
        const response = await getHospitalSpecialty(id);
        const specialties = response.data.hospitalSpecialties;
        const userId = getters.getUserId;
        let defaultSpecialtyId = specialties[0].specialtyModule.id;
        if (!getters.isHospitalDoctor) {
          try {
            const { data } = await getUserDefaultSpecialty(id, userId);
            const user = data.users[0];
            const defaultSpecialty = specialties.find(
              (x) => x.specialtyModule.id === user.idDefaultSpecialty
            );
            defaultSpecialtyId = defaultSpecialty
              ? user.idDefaultSpecialty
              : specialties[0].specialtyModule.id;
          } catch (e) {
            console.error("Error fetching default specialty");
          }
        } else {
          try {
            const { data } = await getUserDoctorSpecialties(id, userId);
            const user = data.users[0];
            const doctorSpecialties = user.doctorSpecialties;
            const anyActiveSpecialty = doctorSpecialties.find((x) => x.active);
            if (
              doctorSpecialties &&
              doctorSpecialties.length > 0 &&
              anyActiveSpecialty
            ) {
              const activeSpecialty = doctorSpecialties.find((x) => x.active);
              const defaultSpecialty = specialties.find(
                (x) =>
                  x.specialtyModule.id === activeSpecialty.id_specialty_module
              );
              defaultSpecialtyId = defaultSpecialty
                ? activeSpecialty.id_specialty_module
                : specialties[0].specialtyModule.id;
            }
          } catch (e) {
            console.error("Error fetching default specialty for doctor");
          }
        }
        commit("COMMIT_CURRENT_HOSPITAL", id);
        commit("COMMIT_HOSPITAL_SPECIALTY_MODULES", specialties);

        router.push({
          name: "hospitals",
          params: {
            idHospital: id,
            idSpecialty: defaultSpecialtyId,
          },
        });
      } catch (error) {
        console.log(error);
      }
    },

    fetchBaseDataForContext({ dispatch }, roleContext) {
      if (roleContext.hospital) {
        dispatch("fetchHospitalDashboard", roleContext.hospital.id);
      }
    },
  },
};
