<template lang="pug">
.view-appointment-status.content-wrapper
  BasePageTitle(
    pageTitleIcon="colon",
    :pageTitleIconString="clinicIcon",
    :title="clinicTitle"
    v-if="$route.name != 'clinics.patient-search' && $route.name !='clinics.patient-search.results'" 
  )
    template(#breadcrumbs)
      li
        router-link(:to="{name: 'clinics.appointment-status'}") Appointment Status
      li(v-if="specialtyModule?.isManageCare")
        router-link(:to="{name: 'clinics.ongoing-care'}") Ongoing Care
      li
        router-link(:to="{name: 'clinics.schedules'}") Schedules
      li(v-if="!isClinicStaffInTraining")
        router-link(:to="{name: 'clinics.scheduling'}") Schedule Patient

    template(#actionSection v-if="showSchedulePatientButton && !isClinicStaffInTraining")
      BaseBtn.lg.blue(:to="{name: 'clinics.scheduling'}") SCHEDULE PATIENT
      .annotation {{ availableSlots }} Slots Available
  router-view(:specialtyModule="specialtyModule")  

  .content-wrapper.on-going-care-wrapper(v-if="showOnGoingCareOnTabs")
    .card.router-view-card
      section.on-going-care
        h3 Ongoing Care
        .columns.is-variable
          .column.is-one-half 
            router-link(:to="{name: 'clinics.ongoing-care.active-patients'}")
              BoxLinkSm(text="Active Patients", :number="summaryOngoingCare.active")
          .column.is-one-half
            router-link(:to="{name: 'clinics.ongoing-care.archived'}")
              BoxLinkSm(text="Archived", :number="summaryOngoingCare.archived")

</template>

<script>
import { defineComponent } from "vue";
import moment from "moment";

import BoxLinkSm from "@/components/BoxLinkSm.vue";
import { fetchSpecialtyModule } from "@/api/specialtyModulesApi";
import { fetchPatientAppointmentAvailableSchedule } from "@/api/patientAppointmentApi";
import { fetchOnGoingCarePatient } from "@/api/onGoingCareApi";

import { apiStatus, apiStatusComputedFactory } from "@/api";
const { IDLE, PENDING, SUCCESS, ERROR } = apiStatus;
import { mapGetters } from "vuex";

export default defineComponent({
  components: {
    BoxLinkSm,
  },

  data() {
    return {
      clinicIcon: "",
      clinicTitle: "",
      fetchSpecialtyModuleStatus: IDLE,
      fetchOnGoingCarePatientStatus: IDLE,
      specialtyModule: null,
      fetchPatientAppointmentAvailableScheduleStatus: IDLE,
      availableSlots: 0,
      summaryOngoingCare: {
        active: 0,
        archived: 0,
      },
    };
  },

  computed: {
    ...mapGetters(["getUserRoleContext", "isClinicStaffInTraining"]),
    showSchedulePatientButton() {
      return !this.$route.name.includes("clinics.scheduling");
    },
    showOnGoingCare() {
      return this.specialtyModule?.isManageCare;
    },
    showOnGoingCareOnTabs() {
      if (this.showOnGoingCare) {
        if (
          this.$route.name == "clinics.appointment-status.requested" ||
          this.$route.name == "clinics.appointment-status.confirmed" ||
          this.$route.name ==
            "clinics.appointment-status.pending-clinic-review" ||
          this.$route.name == "clinics.appointment-status.archived"
        ) {
          return true;
        }
      }
      return false;
    },
    ...apiStatusComputedFactory(
      "fetchPatientAppointmentAvailableScheduleStatus",
      "fetchOnGoingCarePatientStatus"
    ),
  },

  props: {
    idClinic: {
      required: true,
    },
    idSpecialty: {
      required: true,
    },
  },

  methods: {
    async fetchOnGoingCarePatient() {
      this.fetchOnGoingCarePatientStatus = PENDING;
      try {
        const res = await fetchOnGoingCarePatient({
          params: {
            idSpecialtyModule: this.$route.params.idSpecialty,
            filters: {
              idClinic: this.$route.params.idClinic,
              status: "archived",
            },
          },
        });
        this.summaryOngoingCare = {
          active: res.data.activeCount || 0,
          archived: res.data.archivedCount || 0,
        };

        this.fetchOnGoingCarePatientStatus = SUCCESS;
      } catch (err) {
        this.fetchOnGoingCarePatientStatus = ERROR;
        console.error(err);
      }
    },
    async fetchPatientAppointmentAvailableSchedule() {
      this.fetchPatientAppointmentAvailableScheduleStatus = PENDING;
      const startDateOffset = moment().add(15, "days").unix();
      try {
        const res = await fetchPatientAppointmentAvailableSchedule({
          params: {
            ...this.reqPagination,
            collapsedDoctor: false,
            idClinicHealthSystem: this.getUserRoleContext.clinicHealthSystem.id,
            idSpecialtyModule: this.$route.params.idSpecialty,
            include: ["hospital", "patientAppointment"],
            isAvailable: "Available",
            page: 1,
            size: 10,
            startDate: startDateOffset,
          },
        });
        this.availableSlots = res.data.pagination.quantity;
        this.fetchPatientAppointmentAvailableScheduleStatus = SUCCESS;
      } catch (err) {
        this.fetchPatientAppointmentAvailableScheduleStatus = ERROR;
        console.error(err);
      }
    },
    async fetchSpecialtyModule() {
      this.fetchSpecialtyModuleStatus = PENDING;
      try {
        const res = await fetchSpecialtyModule(this.idSpecialty);
        this.clinicIcon = res.data.specialtyModule?.icon;
        this.clinicTitle =
          res.data.specialtyModule?.marketingTitle +
          ": " +
          res.data.specialtyModule?.moduleTitle;
        this.specialtyModule = res.data.specialtyModule;
        this.fetchSpecialtyModuleStatus = SUCCESS;
      } catch (err) {
        this.fetchSpecialtyModuleStatus = ERROR;
        console.error(err);
      }
    },
  },

  created() {
    if (this.$route.params.idClinic === null) return;
    this.fetchSpecialtyModule();
    this.fetchPatientAppointmentAvailableSchedule();
    this.fetchOnGoingCarePatient();
  },
  mounted() {
    this.emitter.on("reloadPatientAppointmentAvailableSchedule", () => {
      this.fetchPatientAppointmentAvailableSchedule();
    });
  },
});
</script>

<style scoped lang="scss">
.on-going-care-wrapper {
  max-width: 572px;
  margin-right: 0px;
  margin-left: auto;
  .card {
    border-top-left-radius: 0px;
  }
  .on-going-care {
    padding-left: 30px;
    padding-right: 30px;
    h3 {
      font-size: 21px;
      font-weight: normal;
      margin-bottom: 0px;
      margin-top: 30px;
    }
    .columns.is-variable {
      padding-bottom: 78px;
    }
    .box-link-sm {
      margin-left: -24px;
    }
  }
}
</style>
