<template lang="pug">
section.step-subtitle
  h2 SEARCH PATIENT
section.search-patient
  .cols
    .col
      .form-group
        label(for="search-by") Search By
        select#search-by(v-model="searchBy")
          option(
            v-for="type in PATIENT_SEARCH_OPTIONS",
            :key="type.id"
            :value="type.value"
            :selected="type.value === searchBy"
          ) {{ type.name }}
    .col(v-if="searchBy==='name'")
      .form-group
        label(for="first-name") First Name
        input#first-name(type="text", v-model="firstName")
    .col(v-if="searchBy==='name'")
      .form-group
        label(for="last-name") Last Name
        input#last-name(type="text", v-model="lastName")
    .col(v-if="searchBy==='mrn'")
      .form-group(:class="{ 'form-group__invalid': v$.mrn.$errors.length }")
        label(for="mrn") MRN
        input#mrn(
          type="text", 
          v-model="mrn"
          @blur="v$.mrn.$touch()"
        )
        .form-group__errors(v-if="v$.mrn.$dirty && v$.mrn.$errors.length")
          .form-group__error(v-if="v$.mrn.required.$invalid") MRN is required!
    .col(v-if="searchBy==='dob'")
      .form-group(:class="{ 'form-group__invalid': v$.dob.$errors.length }")
        label(for="dob") Date of Birth
        input#dob(
          type="text", 
          v-model="dob"
          v-maska="'##/##/####'" 
          placeholder="MM/DD/YYYY" 
          @blur="v$.dob.$touch()"
        )
        .form-group__errors(v-if="v$.dob.$dirty && v$.dob.$errors.length")
          .form-group__error Date of Birth is required!
  .actions-below-table-container
    span
    BaseBtn.lg.green(
      @click.prevent="startSearch()",
      :disabled="checkFormValidations()"
    ) SEARCH
</template>

<script>
import { defineComponent } from "vue";
import { PATIENT_SEARCH_OPTIONS } from "@/helpers";
import useVuelidate from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import { maska } from "maska";

export default defineComponent({
  directives: { maska },
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    let args = {};
    if (this.searchBy == "dob") {
      args = {
        dob: {
          required,
          minLength: minLength(10),
          function() {
            let unixDistributed = new Date(this.dob).getTime();
            if (!unixDistributed) {
              return false;
            }
            return true;
          },
        },
      };
    } else if (this.searchBy == "mrn") {
      args = {
        mrn: { required },
      };
    }

    return args;
  },
  data() {
    return {
      mrn: null,
      dob: null,
      isSearch: false,
      searchBy: "name",
      firstName: "",
      lastName: "",
      searchParams: null,
      PATIENT_SEARCH_OPTIONS,
    };
  },

  props: {
    specialtyModule: {
      required: false,
    },
  },
  methods: {
    checkFormValidations() {
      if (this.searchBy === "name") {
        return this.lastName == "" && this.firstName == "";
      } else {
        return this.v$.$invalid;
      }
    },
    startSearch() {
      this.searchParams = {
        searchBy: this.searchBy,
        firstName: this.firstName,
        lastName: this.lastName,
        mrn: this.mrn,
        dob: this.dob,
      };
      this.isSearch = true;
      this.$router.push({
        name: "clinics.scheduling.select-patient",
        query: this.searchParams,
      });
    },
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/components/actions-below-table-container.scss";
@import "@/scss/components/step-subtitle.scss";
.search-patient {
  padding: 50px 30px 9px;
  .cols {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .col {
      width: math.percentage(math.div(345, 1108));
      margin-right: 36px;
      flex-grow: 0;
      flex-shrink: 0;
      &:last-child {
        margin-right: auto;
      }
    }
  }
}
</style>
