<template lang="pug">
FullScreenOverlaySlot(
  :isOpen="isOpen",
  headerIcon="colon",
  :headerIconString="clinicIcon",
  title="Appointment Record"
  @closeOverlay="closeOverlay(false)"
)
  template(#breadcrumbs)
    h1.breadcrumbs-title {{ clinicTitle }}
  template(#actionSection)
    BaseBtn.x-lg.blue-outline(
      href="#",
      @click.prevent="openAppointmentNotesOverlay",
      :count="noteSummary.new"
    ) NOTES ({{ noteSummary.total }})
  template(#content)
    .card(style="padding-top: 52px;")
      .patient-info.columns.is-variable
        .column.is-one-third
          .columns.is-variable
            .info-pair.column.is-one-half
              .label Scheduled Date
              .txt.txt--blue {{ getDateFromUnixFormatted(showAppointment.scheduledAt) }} 
            .info-pair.column.is-one-half
              .label Scheduled Time
              .txt.txt--blue {{ getTimeFromUnixFormatted(showAppointment.scheduledAt) }} 
        .column.is-one-third.rescheduled
          .columns.is-variable
            .column.is-one-half
            .column.is-one-half
              .info-pair
                .label
                  span Rescheduled
                    base-icon(name="yes" v-if="showAppointment.status == 'Rescheduled'")
                    base-icon(name="no" v-else)
        .column.is-one-third.adjust-margin-top(v-if=" tabTypeProcessed == 'confirmed' ")
          .flex.flex--space-between
            BaseBtn.sm.black-outline(:to="{name: 'hospitals.appointment-status.reschedule',params: { idAppointment: showAppointment?.id, idHospital: hospitalId, idSpecialty: specialtyId },}") RESCHEDULE
            BaseBtn.sm.red-outline(href="#",@click.prevent="openCancelAppointmentOverlay") CANCEL

      .hr.hr--thin
      .columns.is-variable
        .info-pair.column.is-one-third
          .label First Name
          .txt.txt--blue {{ showAppointment.patient.first }}
        .info-pair.column.is-one-third
          .label Last Name
          .txt.txt--blue {{ showAppointment.patient.last }}
        .info-pair.column.is-one-third
          .label Date of Birth
          .txt.txt--blue {{ getDobFormattedforDateField(showAppointment.patient.dob) }}
      .hr.hr--thin
      .info-pair.columns.is-variable
        .column.is-two-thirds.info-pair
          .label Patient Address
          .txt {{ showAppointment.patient.address.address}}<br>{{ showAppointment.patient.address.place }}, {{ showAppointment.patient.address.state }} {{ showAppointment.patient.address.zipCode }}
        .column.is-one-third.flex.flex--flex-start.flex--align-start
          .info-pair
            .label Patient Phone Number
            .txt {{ getPhoneFormatted(showAppointment.patient.contact.cellPhone) }}
          .info-pair
            .label Ext.
            .txt {{ showAppointment.patient.contact.cellPhoneExtension }}
      .hr.hr.hr--thin
      .columns.is-variable
        .info-pair.column.is-one-third
          .label Clinic Name
          .txt {{ showAppointment.clinic.name }} 
        .info-pair.column.is-one-third(v-if="showAppointment.schedule.doctor")
          .label Doctor Name
          .txt {{ getDoctorFormatted(showAppointment.schedule.doctor) }}
        .column.is-one-third.flex.flex--flex-start.flex--align-start
          .info-pair
            .label Clinic Phone Number
            .txt {{ getPhoneFormatted(showAppointment.clinic.contact.businessPhone) }}
          .info-pair
            .label Ext.
            .txt {{ showAppointment.clinic.contact.businessPhoneExtension }}
    .card.card--blue-bg
      h2.title.title--md-thin PATIENT RECORD INFORMATION
      .patient-record-info.columns.is-variable
        .info-pair.column.is-one-third(v-if="showAppointment.referringDoctor")
          .label Referring Physician
          .txt {{ getDoctorFormatted(showAppointment.referringDoctor) }}
        .info-pair.column.is-one-third
          .label Clinic MRN
          .txt {{ showAppointment.patient.mrn }}
        .column.is-one-third
          .form-group(:class="{ 'form-group__invalid': v$.showAppointment.hospitalMrn.$errors.length }")
            label.label--margin-sm Hospital MRN
            input.text-lg(type="text" v-model="showAppointment.hospitalMrn" :disabled="shouldShowHospitalMRN" @blur="v$.showAppointment.hospitalMrn.$touch()")
            .form-group__errors(v-if="v$.showAppointment.hospitalMrn.$dirty && v$.showAppointment.hospitalMrn.$errors.length")
              .form-group__error(v-if="v$.showAppointment.hospitalMrn.required.$invalid") Hospital MRN is required!
    .card
      h2.title.title--md-thin Emergency Contact Information
      .emergency-contact-info.columns
        .info-pair.column.is-two-thirds
          .label Name
          .txt {{ showAppointmentDetails?.emergencyContact.firstName }} {{ showAppointmentDetails?.emergencyContact.lastName }}
        .column.is-one-third.flex.flex--flex-start.flex--align-start
          .info-pair
            .label Phone Number
            .txt {{ getPhoneFormatted(showAppointmentDetails?.emergencyContact.phoneNumber) }}
          .info-pair(v-if="showAppointmentDetails?.emergencyContact.phoneExtension")
            .label Ext.
            .txt {{ showAppointmentDetails?.emergencyContact.phoneExtension }}
    .card
      .columns.is-variable
        .column.insurance-status.is-one-third.flex.flex--space-between
          p Does patient have insurance?
          base-icon(name="yes", v-if="showAppointmentDetails?.insurance.name")
          base-icon(name="no", v-else)
      .hr.hr.hr--thin(v-if="showAppointmentDetails?.insurance.name")
      h2.title.title--md-thin(v-if="showAppointmentDetails?.insurance.name") Insurance Information
      .insurance-info.columns.is-variable(v-if="showAppointmentDetails?.insurance.name")
        .info-pair.column.is-one-third
          .label Insurance Name
          .txt {{ showAppointmentDetails?.insurance.name }}
        .info-pair.column.is-one-third
          .label Policy Number
          .txt {{ showAppointmentDetails?.insurance.policyNumber }}
      .columns.is-variable(v-if="showAppointmentDetails?.insurance.name")
        .info-pair.column.is-one-third
          .label Expiration Date
          .txt {{ getDateFromUnixFormatted(showAppointmentDetails?.insurance.expirationDate) }}
        .info-pair.column.is-one-third
          .label Group Number
          .txt {{ showAppointmentDetails?.insurance.groupNumber }}
        .info-pair.column.is-one-third
          .label Insurance Card
          .flex.flex--flex-start
            FileViewer(
              :fileId="showAppointmentDetails?.insuranceCard.id"
            )
    .card
      h2.title.title--md-thin CLINIC APPOINTMENT DETAILS
      .columns
        .info-pair.column.is-one-third
          .label {{ specialtyModule?.schemaRules?.patientInfoConfiguration?.referralInfoTitle || "GI Referral Info" }}
          .txt.cursorPointer 
            base-icon(name="eye-green", @click.prevent="openReferralInfoOverlay")
        .info-pair.column.is-one-third
          .label Clinic Report
          .txt
            FileViewer(:fileId="showAppointment.idClinicReport")

    .card.card--blue-bg(v-if=" tabTypeProcessed != 'requested' ")
      h2.title.title--md-thin PROCEDURE COMPLETION
      .columns
        .form-group.info-pair.column.is-one-third(:class="{ 'form-group__invalid': v$.showAppointment.procedureStatus.$errors.length }")
          .label Procedure Completed (Required)
          select(:disabled="isPendingOrArchived" @change="selectAnswerProcedureCompleted($event.target.value)" v-model="showAppointment.procedureStatus" @blur="v$.showAppointment.procedureStatus.$touch()")
            option(value="") Select
            option(value="COMPLETED") YES - COMPLETED
            option(value="CANCELED") NO - CANCELLED
            option(value="NO_SHOW") NO - NO SHOW
            option(
              v-for="additionalDropdownOption in additionalDropdownOptions" 
              :key="additionalDropdownOption.id" 
              :value="additionalDropdownOption.value"
            ) NO - {{ additionalDropdownOption.name.toUpperCase() }}
          .form-group__errors(v-if="v$.showAppointment.procedureStatus.$dirty && v$.showAppointment.procedureStatus.$errors.length")
            .form-group__error(v-if="v$.showAppointment.procedureStatus.required.$invalid") Procedure completed is required!
        .info-pair.column.is-one-third
          .label {{ procedureReportTitle }} (Required)
          FileViewer(
            :fileId="showAppointmentDetails?.idHospitalReport"
            v-if="isPendingOrArchived"
          )
          UploadAndPreviewFile(
            :buttonStyleClasses="['btn', 'lg', 'blue']"
            :storageKey="procedureReportStorageKey"
            :src="hospitalReportSrc"
            v-if="!isPendingOrArchived"
            @fileLoaded="forceReRender"
          )

    .card.card--blue-bg(v-if=" tabTypeProcessed != 'requested' ")
      h2.title.title--md-thin PROCEDURE RESULTS
      .columns.flex-wrap(v-if="activeSpecialtyFields")
        template(v-for="(specialtyField, index) in activeSpecialtyFields" :key="specialtyField.id" :class="specialtyField.class" )
          .info-pair.column.is-full(v-if="specialtyField.inNewRow !== undefined && specialtyField.inNewRow")
          .info-pair.column(:class="specialtyField.class" )
            div(v-if="specialtyField.type == 'NUMERIC' ")
              inputValidateNumeric(
                :key="specialtyField.id" 
                :value="specialtyField" 
                :disabled="isPendingOrArchived" @emitAnswer="processAnswer" 
                :default="getDefaultNumeric(procedureResults[specialtyField.id])"
              ) 

            div(v-else-if="specialtyField.type == 'TEXT' ")
              inputValidateText(
                :key="specialtyField.id" 
                :value="specialtyField" 
                :disabled="isPendingOrArchived" @emitAnswer="processAnswer" 
                :default="procedureResults[specialtyField.id]"
              )
            
            div(v-else-if="specialtyField.type == 'RADIO' ")
              inputValidateRadio(
                :key="specialtyField.id" 
                :value="specialtyField" 
                :disabled="isPendingOrArchived" :tabTypeProcessed="tabTypeProcessed" @emitAnswer="processAnswer"  
                :default="procedureResults[specialtyField.id]"
              ) 

            div(v-else-if="specialtyField.type == 'FILE' ")
              .label(v-if="specialtyField.required") {{ specialtyField.name }} (Required)
              .label(v-else) {{ specialtyField.name }} 
              UploadAndPreviewFile(
                :buttonStyleClasses="['btn', 'lg', 'blue']"
                :storageKey="'file_for_specialty_field_' + specialtyField.id"
                :src="specialtyField.src"
                v-if="!isPendingOrArchived"
                @fileLoaded="forceReRender"
              )
              FileViewer(
                :fileId="specialtyField.fileId"
                v-if="isPendingOrArchived"
              )

            div(v-else-if="specialtyField.type == 'DROPDOWN' ")
              inputValidateDropdown(
                :key="specialtyField.id" 
                :value="specialtyField" 
                :disabled="isPendingOrArchived" :tabTypeProcessed="tabTypeProcessed" 
                @emitAnswer="processAnswer"  
                :default="procedureResults[specialtyField.id]"
              ) 

            div(v-else-if="specialtyField.type == 'SECTION_TITLE' ")
              h2.title.title--md-thin.margin-top {{ specialtyField.name }}

            div(v-else-if="specialtyField.type == 'DESCRIPTION' ")
              p {{ specialtyField.name }}

            div(v-else) {{ specialtyField.name }} || {{ specialtyField.type }} 
    .card.card--blue-bg(v-if=" tabTypeProcessed != 'requested' ")
      h2.title.title--md-thin FINAL NOTES
      .columns
        .form-group.info-pair.column.is-one-third(:class="{ 'form-group__invalid': v$.showAppointment.hospitalNotes.$errors.length }")
          .label Hospital Notes (Required)
          textarea(style="height: 250px;" :disabled="isPendingOrArchived" v-model="showAppointment.hospitalNotes" @blur="v$.showAppointment.hospitalNotes.$touch()")
          .form-group__errors(v-if="v$.showAppointment.hospitalNotes.$dirty && v$.showAppointment.hospitalNotes.$errors.length")
            .form-group__error(v-if="v$.showAppointment.hospitalNotes.required.$invalid") Hospital notes are required!
        .info-pair.column.is-one-third(v-if="tabTypeProcessed == 'confirmed' ")
          div(v-if="ongoingCareActive")
            .form-group.select-m-bot(:class="{ 'form-group__invalid': v$.continueOnGoingCareSelectValueRaw.$errors.length }")
              .label Continuing Ongoing Care? (Required)
              select(:disabled="isPendingOrArchived" @change="onChangeContinueOngoingCare($event)" 
              @blur="v$.continueOnGoingCareSelectValueRaw.$touch()"
              v-model="continueOnGoingCareSelectValueRaw" )
                option(value="") Select
                option(value="yes") YES
                option(value="no") NO
              .form-group__errors(v-if="v$.continueOnGoingCareSelectValueRaw.$dirty && v$.continueOnGoingCareSelectValueRaw.$errors.length")
                .form-group__error(v-if="v$.continueOnGoingCareSelectValueRaw.required.$invalid") Field is required!
          div(v-if="continueOngoingCare")
            .form-group.date-spacer(:class="{ 'form-group__invalid': v$.onGoingCareData.date.$errors.length }")
              DatePicker(
                v-model="onGoingDate" 
                :masks="masks"
                @blur="v$.onGoingCareData.date.$touch()"
              )
                template(v-slot="{ inputValue, inputEvents }")
                  input(
                    :value="inputValue"  
                    v-on="inputEvents"
                    placeholder="MM/DD/YYYY"
                    @blur="v$.onGoingCareData.date.$touch()"
                    readonly
                  )
              .form-group__errors(v-if="v$.onGoingCareData.date.$dirty && v$.onGoingCareData.date.$errors.length")
                .form-group__error(v-if="v$.onGoingCareData.date.required.$invalid") Field is required!
                .form-group__error(v-if="!v$.onGoingCareData.date.required.$invalid && v$.onGoingCareData.date.$dirty") Next appointment must be greater than the appointment date.

            TimeDropdown(
              isRequired=true 
              @timeResults="timeResults"
              class="time-spacer"
            )

            .form-group.select-m-bot(:class="{ 'form-group__invalid': v$.onGoingCareData.doctor.$errors.length }")
              label(for="doctorName") Doctor Name (Required)
              select#doctorName(@change="onChangeDoctorName($event)" v-model="onGoingCareData.doctor" @blur="v$.onGoingCareData.doctor.$touch()")
                option(value="") Please Select
                option(
                  v-for="doctor in activeDoctors", :key="doctor.id",
                  :value="doctor.id"
                ) {{ getDoctorFormatted(doctor) }}
              .form-group__errors(v-if="v$.onGoingCareData.doctor.$dirty && v$.onGoingCareData.doctor.$errors.length")
                .form-group__error(v-if="v$.onGoingCareData.doctor.required.$invalid") Field is required!

          div(v-if="dontContinueOngoingCare")
            .label Recommended Follow-Up (Required)
            select.select-m-bot(
              :disabled="isPendingOrArchived" 
              @change="onChangeNextAppointment($event)"
              v-model="relativeNextAppointment"
            )
                option(value="1 month") 1 month
                option(value="3 months") 3 months
                option(value="6 months") 6 months
                option(value="1 year") 1 year
                option(value="5 years") 5 years
                option(value="10 years") 10 years
                option(value="Other") Other
            .form-group.select-m-bot(:class="{ 'form-group__invalid': v$.nextAppointmentOther.$errors.length }")
              DatePicker(
                v-model="date" 
                :masks="masks"
                v-if="showDatePicker_Other" 
                @change="onChangeNextAppointmentDate($event)"
              )
                template(v-slot="{ inputValue, inputEvents }")
                  input(
                    :value="inputValue"  
                    v-on="inputEvents"
                    placeholder="MM/DD/YYYY"
                    @blur="v$.nextAppointmentOther.$touch()"
                  )
              .form-group__errors(v-if="v$.nextAppointmentOther.$dirty && v$.nextAppointmentOther.$errors.length")
                .form-group__error(v-if="v$.nextAppointmentOther.required.$invalid") Field is required!
                .form-group__error(v-if="!v$.nextAppointmentOther.required.$invalid && v$.nextAppointmentOther.$dirty") Next appointment must be set in the future.
        .info-pair.column.is-one-third(v-else)
          div(v-if="showAppointment.followUpDate")
            .label Recommended Follow-Up
            input(type="text" :value="getDateFromUnixFormatted(showAppointment.followUpDate)" disabled)
        .info-pair.column.is-one-third(v-if="continueOngoingCare")
          .txt.txt--blue.font-medium NOTE:<br />Completing this appointment will automatically create a new appointment for this patient. 
    .card(v-if="showClinicNotesForHospital()")
      h2.title.title--md-thin FINAL NOTES
      .columns
        .info-pair.column
          .label Clinic Final Notes (Required)
          textarea(style="height: 250px;" :disabled="true" v-model="showAppointment.clinicNotes")
        
  template(#footer, v-if=" tabTypeProcessed == 'confirmed' || tabTypeProcessed == 'requested' ")
    BaseBtn.btn.lg.green-outline(
      @click.prevent="preparePatchPatientAppointmentById()", 
      v-if=" tabTypeProcessed == 'confirmed' "
      :disabled="v$.nextAppointmentOther.$invalid"
    ) Save &amp; Return to Dashboard
    BaseBtn.btn.lg.green( 
      @click.prevent="openCompleteAppointmentPopup()", 
      v-if=" tabTypeProcessed == 'confirmed' "
      :disabled="v$.$invalid || !requiredFilesAreUploaded || isSubmitting"
      :class="{spinner: isSubmitting }"
    ) Complete this Appointment
    BaseBtn.btn.lg.green.request_tab_confirm(
      @click.prevent="preparePatchPatientAppointmentById()", 
      v-if=" tabTypeProcessed == 'requested' ", 
      :disabled="v$.$invalid || isSubmitting"
      :class="{spinner: isSubmitting }"
    )  CONFIRM THIS APPOINTMENT


PopupCardSlot(
    :isOpen="isCompleteAppointmentPopupOpen",
    maxWidth="675px"
  )
    .complete-appointment-popup
      h1 Are you sure you want to complete this appointment?
      p Please confirm to continue
      .popup-btns
        BaseBtn.x-lg.red-outline(@click.prevent="closeCompleteAppointmentPopup") CANCEL
        BaseBtn.x-lg.green(
          @click.prevent="prepareCompleteAppointmentPopup"
          :disabled="isSubmitting"
          :class="{spinner: isSubmitting }"
        ) YES, COMPLETE
PopupCardSlot(
    :isOpen="errorPopupOpen",
    maxWidth="675px"
  )
    .popup-error-content 
      h1 {{errorPopupMessage}}
      .popup-btns.center
        BaseBtn.x-lg.red-outline(@click.prevent="closeErrorPopup") CLOSE
    

ReferralInfoOverlay(
  :isOpen="isReferralInfoOverlayOpen",
  :overlayTitle="specialtyModule?.schemaRules?.patientInfoConfiguration?.referralInfoTitle || 'GI Referral Info' ",
  :showAppointment="showAppointment"
  :showAppointmentDetails="showAppointmentDetails"
  :patientInfoQuestions="patientInfoQuestions"
  @closeReferralInfoOverlay="closeReferralInfoOverlay"
)
CancelAppointmentOverlay(
  :isOpen="isCancelAppointmentOverlayOpen",
  :patientAppointment="showAppointment"
  overlayTitle="ARE YOU SURE YOU WANT TO CANCEL THIS APPOINTMENT?",
  @closeCancelAppointmentOverlay="closeCancelAppointmentOverlay"
  @closeCancelWithAppointmentOverlay="closeCancelWithAppointmentOverlay"
)

AppointmentNotesOverlay(
  :key="`${componentKey}-1`",
  :hasNoNotes="hasNoNotes",
  :isOpen="isAppointmentNotesOverlayOpen",
  @closeAppointmentNotesOverlay="closeAppointmentNotesOverlay"
  @fetchPatientAppointmentNotifications="fetchPatientAppointmentNotifications"
  :patientAppointment="showAppointment"
)
</template>

<script>
import { defineComponent } from "vue";
import moment from "moment";
import { mapGetters } from "vuex";
import TimeDropdown from "@/components/TimeDropdown.vue";

import FileViewer from "@/components/files/FileViewer.vue";
import FullScreenOverlaySlot from "@/components/overlays/FullScreenOverlaySlot.vue";
import PopupCardSlot from "@/components/PopupCardSlot.vue";
import ReferralInfoOverlay from "@/components/hospitals/appointment-status/overlays/ReferralInfoOverlay.vue";
import CancelAppointmentOverlay from "@/components/hospitals/appointment-status/overlays/CancelAppointmentOverlay.vue";
import UploadAndPreviewFile from "@/components/UploadAndPreviewFile";

import AppointmentNotesOverlay from "@/components/overlays/side-overlays/AppointmentNotesOverlay.vue";
import listPagesMixin from "@/mixins/listPagesMixin";
import inputValidateNumeric from "@/components/hospitals/appointment-status/overlays/inputValidate/inputValidateNumeric.vue";
import inputValidateRadio from "@/components/hospitals/appointment-status/overlays/inputValidate/inputValidateRadio.vue";
import inputValidateText from "@/components/hospitals/appointment-status/overlays/inputValidate/inputValidateText.vue";
import inputValidateDropdown from "@/components/hospitals/appointment-status/overlays/inputValidate/inputValidateDropdown.vue";
import { DatePicker } from "v-calendar";

import {
  getDateFromUnixFormatted,
  getTimeFromUnixFormatted,
  getDobFormattedforDateField,
  getRelativeTimeFromUnixFormatted,
  getDoctorFormatted,
  getPhoneFormatted,
  getFileSignedUrl,
} from "@/helpers";

import { getHospitalSpecialty, fetchDoctorsSpecialty } from "@/api/hospitalApi";
import {
  fetchPatientAppointmentById,
  patchPatientAppointmentById,
  postPatientAppointmentOnGoing,
} from "@/api/patientAppointmentApi";
import { fetchNotification } from "@/api/notifications.ts";

import { apiStatus } from "@/api";
import { fetchFileById, uploadFile } from "@/api/filesApi";
const { IDLE, PENDING, SUCCESS, ERROR } = apiStatus;

import useVuelidate from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import { maska } from "maska";

export default defineComponent({
  directives: { maska },
  setup() {
    return { v$: useVuelidate() };
  },

  validations() {
    let args = {};
    if (this.tabTypeProcessed == "requested") {
      args = {
        showAppointment: {
          hospitalMrn: { required },
        },
      };
    }
    if (this.tabTypeProcessed != "requested") {
      args = {
        showAppointment: {
          hospitalMrn: {},
          hospitalNotes: { required },
          procedureStatus: { required },
        },
        nextAppointmentOther: {},
        continueOnGoingCareSelectValueRaw: { required },
      };
      if (!this.ongoingCareActive) {
        args.continueOnGoingCareSelectValueRaw.required = false;
      }
      if (this.showDatePicker_Other) {
        // validate "Other" date field, to be larger than the current scheduledAt date
        args.nextAppointmentOther = {
          required,
          function(value) {
            let unixDistributed = new Date(value).getTime();
            if (!unixDistributed) {
              return false;
            }
            unixDistributed = unixDistributed / 1000;
            if (unixDistributed <= this.showAppointment.scheduledAt) {
              return false;
            }

            return true;
          },
        };
      }
      if (this.continueOngoingCare) {
        args.onGoingCareData = {
          date: {
            required,
            minLength: minLength(10),
            function() {
              let unixDistributed = new Date(
                this.onGoingCareData.date
              ).getTime();
              if (!unixDistributed) {
                return false;
              }
              unixDistributed = unixDistributed / 1000;
              if (unixDistributed <= this.showAppointment.scheduledAt) {
                return false;
              }
              return true;
            },
          },
          doctor: { required },
        };
      }
    }

    return args;
  },
  components: {
    FullScreenOverlaySlot,
    PopupCardSlot,
    ReferralInfoOverlay,
    CancelAppointmentOverlay,
    AppointmentNotesOverlay,
    FileViewer,
    UploadAndPreviewFile,
    inputValidateNumeric,
    inputValidateRadio,
    inputValidateText,
    inputValidateDropdown,
    DatePicker,
    TimeDropdown,
  },
  emits: ["closeHospitalAppointmentRecordOverlay"],
  mixins: [listPagesMixin],

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    tabType: {
      type: String,
      default: "",
    },
    showAppointment: {
      type: Object,
    },
    specialtyModule: {
      required: false,
    },
  },
  data() {
    return {
      date: null,
      onGoingDate: null,
      hasNoNotes: true,
      patientInfoQuestions: {},

      masks: {
        input: "MM/DD/YYYY",
      },

      errorPopupOpen: false,
      errorPopupMessage: "",
      isSubmitting: false,
      relativeNextAppointment: "1 month",
      nextAppointmentOther: "",

      continueOnGoingCareSelectValueRaw: null,
      getDateFromUnixFormatted: getDateFromUnixFormatted,
      getTimeFromUnixFormatted: getTimeFromUnixFormatted,
      getDobFormattedforDateField: getDobFormattedforDateField,
      getRelativeTimeFromUnixFormatted: getRelativeTimeFromUnixFormatted,
      getDoctorFormatted,
      getPhoneFormatted,

      tabTypeProcessed: "",
      specialtyModuleProcessed: null,
      hospitalId: null,
      specialtyId: null,

      hidePolypsFoundSelectLabel: false,
      hidePolypsRemovedSelectLabel: false,
      isCompleteAppointmentPopupOpen: false,
      isReferralInfoOverlayOpen: false,
      isCancelAppointmentOverlayOpen: false,

      fetchPatientAppointmentByIdStatus: IDLE,
      patchPatientAppointmentByIdStatus: IDLE,
      getHospitalSpecialtyStatus: IDLE,
      fetchDoctorsSpecialtyStatus: IDLE,
      postPatientAppointmentOnGoingStatus: IDLE,
      fetchPatientAppointmentNotificationsStatus: IDLE,
      showAppointmentDetails: null,

      continueOngoingCareSelectValue: true,
      ongoingCareActive: false,
      showDatePicker_Other: false,
      continueOngoingCare: false,
      dontContinueOngoingCare: false,
      doctors: null,
      followUpDate: null,

      additionalDropdownOptions: [],
      specialtyFields: [],

      specialtyFieldsAnswers: [],
      showAppointmentProcedureStatus: null,
      procedureResults: [],

      hospitalReportSrc: null,
      procedureReportTitle: "GI Report",
      procedureReportStorageKey: "appt_record_procedure_report",
      isAppointmentNotesOverlayOpen: false,

      onGoingCareData: {
        date: null,
        time: null,
        doctor: null,
      },
      noteSummary: {
        total: 0,
        new: 0,
      },
      forcedReRenderTimes: 0,
    };
  },
  computed: {
    ...mapGetters(["getHospitalId", "getHospitalSpecialtyById"]),
    clinicIcon() {
      return this.specialtyModuleProcessed?.icon;
    },
    clinicTitle() {
      return (
        this.specialtyModuleProcessed?.marketingTitle +
        " > " +
        this.specialtyModuleProcessed?.moduleTitle
      );
    },
    activeDoctors() {
      return this.doctors.filter((doctor) => !doctor.disabled);
    },
    activeSpecialtyFields() {
      return this.specialtyFields
        .filter((specialty) => {
          return specialty.active && specialty.shouldDisplay;
        })
        .map((specialty) => {
          switch (specialty.type) {
            case "NUMERIC":
              specialty.class = "is-one-third";
              break;
            case "DROPDOWN":
              specialty.class = "is-one-third";
              break;
            case "SECTION_TITLE":
              specialty.class = "is-full";
              break;
            case "DESCRIPTION":
              specialty.class = "is-full";
              break;
            default:
              specialty.class = "is-one-third";
          }
          return specialty;
        });
    },
    shouldShowHospitalMRN() {
      return this.tabTypeProcessed != "requested";
    },
    isPendingOrArchived() {
      return (
        this.tabTypeProcessed == "pending" ||
        this.tabTypeProcessed == "archived" ||
        this.tabTypeProcessed == "displayOnly"
      );
    },
    requiredFilesAreUploaded() {
      this.forcedReRenderTimes;
      const {
        specialtyModule: {
          schemaRules: {
            appointmentRecordSpecialtyFields: { fields: specialtyFields },
          },
        },
      } = this.getHospitalSpecialtyById(+this.$route.params.idSpecialty);

      const allSpecFieldsFilesUploaded = this.activeSpecialtyFields
        .filter((field) => field.type === "FILE" && field.required)
        .every((field) => {
          return (
            sessionStorage.getItem("file_for_specialty_field_" + field.id) ||
            field.src
          );
        });

      const procedureReportIsUp =
        !!sessionStorage.getItem(this.procedureReportStorageKey) ||
        !!this.hospitalReportSrc;

      return procedureReportIsUp && allSpecFieldsFilesUploaded;
    },
  },
  watch: {
    date: {
      handler: "setDate",
    },
    onGoingDate: {
      handler: "setDateOngoing",
    },
  },
  methods: {
    getDefaultNumeric(numericValue) {
      if (numericValue !== null && numericValue !== undefined) {
        return parseInt(numericValue);
      }
      return numericValue;
    },
    showClinicNotesForHospital() {
      if (this.tabTypeProcessed == "pending") {
        return true;
      }

      if (
        this.tabTypeProcessed == "archived" &&
        this.showAppointment.clinicNotes
      ) {
        return true;
      }

      return false;
    },
    timeResults(val) {
      this.onGoingCareData.time = val;
    },
    setDateOngoing(newVal) {
      if (newVal === null) {
        this.onGoingCareData.date = "";
      } else {
        this.onGoingCareData.date = moment(newVal).format("MM/DD/YYYY");
        const defaultValue = this.v$.onGoingCareData.date.$model;
        this.v$.onGoingCareData.date.$model = null;
        this.v$.onGoingCareData.date.$model = defaultValue;
      }
    },
    setDate(newVal) {
      if (newVal === null) {
        this.nextAppointmentOther = "";
      } else {
        let selectionDisplay = moment(this.date).format("MM/DD/YYYY");
        this.nextAppointmentOther = selectionDisplay;
        this.followUpDate = selectionDisplay;
      }
    },
    closeErrorPopup() {
      this.errorPopupOpen = false;
    },
    forceReRender() {
      this.forcedReRenderTimes++;
    },
    processAnswer(value, eventTarget) {
      this.procedureResults[value.id] = eventTarget;
      this.selectAnswer(value, eventTarget);
    },
    async fetchPatientAppointmentNotifications() {
      this.fetchPatientAppointmentNotificationsStatus = PENDING;
      try {
        const res = await fetchNotification({
          params: {
            idEntity: this.showAppointment.id,
            entityType: "appointment",
          },
        });
        let newNotifications = res.data.notifications?.filter(
          (notification) => {
            return notification.status != "Completed";
          }
        );
        this.noteSummary.new = newNotifications?.length || 0;
        this.noteSummary.total = res.data.pagination?.quantity || 0;

        if (this.noteSummary.total > 0) {
          this.hasNoNotes = false;
        }
        this.fetchPatientAppointmentNotificationsStatus = SUCCESS;
      } catch (err) {
        this.fetchPatientAppointmentNotificationsStatus = ERROR;
        console.error(err);
      }
    },
    selectAnswerProcedureCompleted(value) {
      this.showAppointmentProcedureStatus = value;
    },
    selectAnswer(answer, value) {
      let found = false;
      this.specialtyFieldsAnswers.forEach((element) => {
        if (element.id == answer.id) {
          element.value = value;
          found = true;
        }
      });
      if (!found) {
        let newAnswerObject = {};
        newAnswerObject.type = answer.type;
        newAnswerObject.value = value;
        newAnswerObject.id = answer.id;
        this.specialtyFieldsAnswers.push(newAnswerObject);
      }
      this.checkDependenciesFor(answer, value);
    },
    checkDependenciesFor(answer, value) {
      this.specialtyFields.forEach((element) => {
        if (element.hasDependencies && element.rules) {
          element.rules.forEach((rule) => {
            if (rule.fieldToCheck == answer.id) {
              answer.options?.forEach((answer) => {
                if (answer.name == value) {
                  value = answer.id;
                }
              });

              if (rule.operatorToUse == "EQUAL") {
                if (rule.valueToCheck == value) {
                  element.shouldDisplay =
                    rule.operatorToUse == "EQUAL" ? true : false;
                } else {
                  element.shouldDisplay =
                    rule.operatorToUse == "EQUAL" ? false : true;
                }
              } else if (rule.operatorToUse == "NOT_EQUAL") {
                if (rule.valueToCheck == value) {
                  element.shouldDisplay =
                    rule.operatorToUse == "NOT_EQUAL" ? false : true;
                } else {
                  element.shouldDisplay =
                    rule.operatorToUse == "NOT_EQUAL" ? true : false;
                }
              }
              // parsing all existing answers
              this.specialtyFieldsAnswers.forEach(
                (questionsAnswer, index, object) => {
                  //check if we found an answer that had a dependency
                  if (element.id == questionsAnswer.id) {
                    //check if the dependency is met or not
                    if (!element.shouldDisplay) {
                      element.value = null;
                      if (this.procedureResults) {
                        this.procedureResults[element.id] = null;
                      }
                      this.checkDependenciesFor(element, null);
                      // if its not met, then remove the answer from existing answers
                      object.splice(index, 1);
                    }
                  }
                }
              );
            }
          });
        }
      });
    },
    classRadio: function (option) {
      return "radio-custom--" + option.toLowerCase();
    },
    shouldShowNextAppointment() {
      let shouldShow = false;
      if (!this.ongoingCareActive) {
        shouldShow = true;
      }

      if (!this.continueOngoingCareSelectValue) {
        shouldShow = true;
      }
      this.dontContinueOngoingCare = shouldShow;
    },
    onChangeDoctorName(event) {
      this.onGoingCareData.doctor = event.target.value;
    },
    onChangeContinueOngoingCare(event) {
      this.continueOngoingCare = false;

      if (event.target.value == "no") {
        this.continueOngoingCareSelectValue = false;
        this.followUpDate = "1 month";
      } else if (event.target.value == "yes") {
        this.continueOngoingCareSelectValue = true;
        this.continueOngoingCare = true;
      } else if (event.target.value == "") {
        this.continueOngoingCareSelectValue = true;
      }
      this.shouldShowNextAppointment();
    },
    onChangeNextAppointment(event) {
      this.followUpDate = null;
      if (event.target.value == "Other") {
        this.showDatePicker_Other = true;
      } else {
        this.showDatePicker_Other = false;
        this.followUpDate = event.target.value;
      }
    },
    onChangeNextAppointmentDate(event) {
      this.followUpDate = event.target.value;
    },
    async prepareCompleteAppointmentPopup() {
      this.isSubmitting = true;
      await this.uploadHospitalReport();
      const fileAnswersToUpload = this.getQuestionFiles();
      const uploadedAnswers = await this.uploadQuestionFiles(
        fileAnswersToUpload
      );
      this.specialtyFieldsAnswers = [
        ...this.specialtyFieldsAnswers,
        ...uploadedAnswers,
      ];
      if (this.continueOngoingCare) {
        this.postPatientAppointmentOnGoing();
      } else {
        this.completeAppointmentPopup();
      }
    },
    convertStringToUnix(stringDate) {
      let unixDate = null;
      if (stringDate) {
        if (typeof stringDate === "string" && stringDate.includes("-")) {
          unixDate = moment(stringDate).unix();
        } else {
          switch (stringDate) {
            case "1 month":
              unixDate = moment
                .unix(this.showAppointment.scheduledAt)
                .add(1, "month")
                .unix();
              break;
            case "3 months":
              unixDate = moment
                .unix(this.showAppointment.scheduledAt)
                .add(3, "months")
                .unix();
              break;
            case "6 months":
              unixDate = moment
                .unix(this.showAppointment.scheduledAt)
                .add(6, "months")
                .unix();
              break;
            case "1 year":
              unixDate = moment
                .unix(this.showAppointment.scheduledAt)
                .add(1, "years")
                .unix();
              break;
            case "5 years":
              unixDate = moment
                .unix(this.showAppointment.scheduledAt)
                .add(5, "years")
                .unix();
              break;
            case "10 years":
              unixDate = moment
                .unix(this.showAppointment.scheduledAt)
                .add(10, "years")
                .unix();
              break;
          }
        }
      }
      return unixDate;
    },
    async completeAppointmentPopup() {
      this.patchPatientAppointmentByIdStatus = PENDING;

      let followUpDateUnix;
      if (this.followUpDate != null) {
        if (this.showDatePicker_Other) {
          followUpDateUnix = new Date(this.followUpDate).getTime() / 1000;
        } else {
          followUpDateUnix = this.convertStringToUnix(this.followUpDate);
        }
      } else {
        followUpDateUnix = this.showAppointment.followUpDate;
      }

      try {
        const res = await patchPatientAppointmentById(
          this.showAppointment.id,
          this.specialtyId,
          {
            followUpDate: followUpDateUnix,
            hospitalMrn: this.showAppointment.hospitalMrn,
            hospitalNotes: this.showAppointment.hospitalNotes,
            id: this.showAppointment.id,
            idHospitalReport: this.idHospitalReport,
            idPathologyReport: null,
            pathologyNeeded: false,
            polypsFound: null,
            polypsRemoved: null,
            procedureStatus: this.showAppointmentProcedureStatus,
            scheduledAt: this.showAppointment.scheduledAt,
            stage: "COMPLETED_COLONOSCOPY_PENDING_CLINIC_REVIEW",
            appointmentRecordSpecialtyFields: this.specialtyFieldsAnswers,
            status: "Completed",
          }
        );
        this.isCompleteAppointmentPopupOpen = false;

        this.patchPatientAppointmentByIdStatus = SUCCESS;
        this.closeOverlay();
        this.isCompleteAppointmentPopupOpen = false;
        this.isSubmitting = false;
      } catch (err) {
        this.patchPatientAppointmentByIdStatus = ERROR;
        console.error(err);
        this.errorPopupMessage = err?.response?.data?.message || err.message;
        this.errorPopupOpen = true;
      } finally {
        this.isSubmitting = false;
      }
    },
    preparePatchPatientAppointmentById() {
      this.patchPatientAppointmentById().then(() => {
        this.closeOverlay();
      });
    },
    async fetchDoctorsSpecialty() {
      this.fetchDoctorsSpecialtyStatus = PENDING;

      try {
        const res = await fetchDoctorsSpecialty(
          this.hospitalId,
          this.specialtyId
        );
        this.doctors = res.data.doctors;
        this.fetchDoctorsSpecialtyStatus = SUCCESS;
      } catch (err) {
        this.fetchDoctorsSpecialtyStatus = ERROR;
        console.error(err);
      }
    },
    async getHospitalSpecialty() {
      this.getHospitalSpecialtyStatus = PENDING;

      try {
        let showAppointmentSpecialty;
        const res = await getHospitalSpecialty(this.hospitalId);
        let specialties = res.data.hospitalSpecialties;
        specialties.forEach((specialty) => {
          if (specialty.specialtyModule.id == this.specialtyId) {
            this.patientInfoQuestions =
              specialty.specialtyModule?.schemaRules?.patientInfoQuestions;
            if (this.specialtyModuleProcessed?.isManageCare) {
              this.ongoingCareActive = specialty.isOngoingActive;
            }
            specialty.specialtyModule?.schemaRules?.appointmentRecordSpecialtyFields?.fields?.forEach(
              (specialtyField) => {
                specialtyField.shouldDisplay = true;
                if (specialtyField.hasDependencies && specialtyField.rules) {
                  specialtyField.shouldDisplay = false;
                }
                this.specialtyFields.push(specialtyField);
              }
            );

            specialty.specialtyModule?.schemaRules?.specialtyConfiguration?.additionalDropdownOptions?.forEach(
              (dropdownOption) => {
                this.additionalDropdownOptions.push(dropdownOption);
              }
            );

            this.procedureReportTitle =
              specialty.specialtyModule?.schemaRules?.specialtyConfiguration?.procedureReportTitle;

            showAppointmentSpecialty = specialty;
          }
        });

        if (this.tabTypeProcessed != "confirmed") {
          this.specialtyFields = [];
          this.showAppointment?.appointmentRecordSpecialtyFields?.forEach(
            (specialtyField) => {
              specialtyField.active = true;
              specialtyField.shouldDisplay = true;
              if (
                specialtyField.value !== null ||
                specialtyField.type == "SECTION_TITLE" ||
                specialtyField.type == "DESCRIPTION"
              ) {
                showAppointmentSpecialty.specialtyModule?.schemaRules?.appointmentRecordSpecialtyFields?.fields?.forEach(
                  (element) => {
                    if (element.id == specialtyField.id && element.inNewRow) {
                      specialtyField.inNewRow = true;
                    }
                    if (
                      element.id == specialtyField.id &&
                      element.hasDependencies
                    ) {
                      specialtyField.hasDependencies = true;
                      specialtyField.rules = element.rules;
                    }
                  }
                );

                this.specialtyFields.push(specialtyField);
                this.procedureResults[specialtyField.id] = specialtyField.value;
              }
            }
          );
          this.specialtyFields.forEach((elem) => {
            this.checkDependenciesFor(elem, elem.value);
          });
        } else {
          //on load, check dependecies from two different objects: one that holds the actual question and rules, and one object that has the value assigned to it
          this.showAppointment?.appointmentRecordSpecialtyFields?.forEach(
            (specialtyField, index) => {
              this.checkDependenciesFor(
                this.specialtyFields[index],
                specialtyField.value
              );
            }
          );
        }

        this.getHospitalSpecialtyStatus = SUCCESS;
      } catch (err) {
        this.getHospitalSpecialtyStatus = ERROR;
        console.error(err);
      }
    },
    async postPatientAppointmentOnGoing() {
      this.postPatientAppointmentOnGoingStatus = PENDING;
      const scheduleFinalDate =
        new Date(
          this.onGoingCareData.date + " " + this.onGoingCareData.time
        ).getTime() / 1000;
      this.onGoingCareData.unixDate = scheduleFinalDate;
      this.showAppointment.patientInfoQuestions?.forEach((element) => {
        delete element.name;
      });

      if (
        this.showAppointmentDetails.emergencyContact.phoneExtension === null
      ) {
        delete this.showAppointmentDetails.emergencyContact.phoneExtension;
      }
      try {
        const res = await postPatientAppointmentOnGoing({
          onGoingGeneratedBy: this.showAppointment.id,
          idPatient: this.showAppointment.idPatient,
          idClinic: this.showAppointment.idClinic,
          idReferringDoctor: this.showAppointment.idReferringDoctor,
          idHospital: parseInt(this.hospitalId),
          schedule: {
            availableAt: this.onGoingCareData.unixDate,
            idHospital: this.hospitalId,
            idDoctor: this.onGoingCareData.doctor,
            insuranceRequired: this.showAppointment.schedule.insuranceRequired,
          },
          emergencyContact: this.showAppointmentDetails.emergencyContact,
          insurance: this.showAppointmentDetails.insurance,
          patientNotes: this.showAppointment.patientNotes,
          idClinicReport: this.showAppointment.idClinicReport,
          hospitalMrn: this.showAppointment.hospitalMrn,
          // idPrescription: this.showAppointment.idPrescription,
          idPrescription: 1,
          idSpecialtyModule: this.specialtyId,
          patientInfoQuestions: this.showAppointment.patientInfoQuestions,
          appointmentRecordSpecialtyFields: this.specialtyFieldsAnswers,
          hospitalNotes: this.showAppointment.hospitalNotes,
          procedureStatus: this.showAppointmentProcedureStatus,
          idHospitalReport: this.idHospitalReport,
        });

        this.$emit("closeHospitalAppointmentRecordOverlay");
        this.isCompleteAppointmentPopupOpen = false;
        this.isSubmitting = false;

        this.postPatientAppointmentOnGoingStatus = SUCCESS;
      } catch (err) {
        this.postPatientAppointmentOnGoingStatus = ERROR;
        console.error(err);
        this.errorPopupMessage = err?.response?.data?.message || err.message;
        this.errorPopupOpen = true;
      } finally {
        this.isSubmitting = false;
      }
    },
    async patchPatientAppointmentById() {
      await this.uploadHospitalReport();
      const fileAnswersToUpload = this.getQuestionFiles();
      const uploadedAnswers = await this.uploadQuestionFiles(
        fileAnswersToUpload
      );
      this.specialtyFieldsAnswers = [
        ...this.specialtyFieldsAnswers,
        ...uploadedAnswers,
      ];

      this.patchPatientAppointmentByIdStatus = PENDING;
      let idSpecialty = this.specialtyId;
      this.isSubmitting = true;

      let followUpDateUnix;
      if (this.followUpDate != null) {
        if (this.showDatePicker_Other) {
          followUpDateUnix = new Date(this.followUpDate).getTime() / 1000;
        } else {
          followUpDateUnix = this.convertStringToUnix(this.followUpDate);
        }
      } else {
        followUpDateUnix = this.showAppointment.followUpDate;
      }

      try {
        await patchPatientAppointmentById(
          this.showAppointment.id,
          idSpecialty,
          {
            followUpDate: followUpDateUnix,
            hospitalMrn: this.showAppointment.hospitalMrn,
            hospitalNotes: this.showAppointment.hospitalNotes,
            id: this.showAppointment.id,
            idHospitalReport: this.idHospitalReport,
            idPathologyReport: null,
            pathologyNeeded: false,
            polypsFound: null,
            polypsRemoved: null,
            procedureStatus: this.showAppointmentProcedureStatus,
            scheduledAt: this.showAppointment.scheduledAt,
            stage: "CONFIRMED",
            appointmentRecordSpecialtyFields: this.specialtyFieldsAnswers,
          }
        );

        this.isCompleteAppointmentPopupOpen = false;

        this.patchPatientAppointmentByIdStatus = SUCCESS;
      } catch (err) {
        this.patchPatientAppointmentByIdStatus = ERROR;
        console.error(err);
        this.errorPopupMessage = err?.response?.data?.message || err.message;
        this.errorPopupOpen = true;
      } finally {
        this.isSubmitting = false;
      }
    },
    async fetchPatientAppointmentById() {
      this.fetchPatientAppointmentByIdStatus = PENDING;
      try {
        const res = await fetchPatientAppointmentById(this.showAppointment.id, {
          params: {
            include: [
              "clinic",
              "clinicReport",
              "emergencyContact",
              "hospital",
              "hospitalDoctor",
              "hospitalReport",
              "insurance",
              "pathologyReport",
              "patient",
              "referringDoctor",
            ],
          },
        });
        this.showAppointmentDetails = res.data.patientAppointment;
        this.checkFetchFiles();
        this.fetchPatientAppointmentByIdStatus = SUCCESS;
      } catch (err) {
        this.fetchPatientAppointmentByIdStatus = ERROR;
        console.error(err);
      }
    },
    closeOverlay(shouldRefresh = true) {
      this.clearFilesFromStorage();
      this.$emit("closeHospitalAppointmentRecordOverlay", shouldRefresh);
    },
    openAppointmentNotesOverlay() {
      this.componentKey++;
      this.isAppointmentNotesOverlayOpen = true;
    },
    closeAppointmentNotesOverlay() {
      this.fetchPatientAppointmentNotifications();
      this.isAppointmentNotesOverlayOpen = false;
    },
    openCompleteAppointmentPopup() {
      this.isCompleteAppointmentPopupOpen = true;
    },
    closeCompleteAppointmentPopup() {
      this.isCompleteAppointmentPopupOpen = false;
    },
    openReferralInfoOverlay() {
      this.isReferralInfoOverlayOpen = true;
    },
    closeReferralInfoOverlay() {
      this.isReferralInfoOverlayOpen = false;
    },
    openCancelAppointmentOverlay() {
      this.isCancelAppointmentOverlayOpen = true;
    },
    closeCancelAppointmentOverlay() {
      this.fetchPatientAppointmentNotifications();
      this.isCancelAppointmentOverlayOpen = false;
    },
    closeCancelWithAppointmentOverlay() {
      this.fetchPatientAppointmentNotifications();
      this.isCancelAppointmentOverlayOpen = false;
      this.closeOverlay();
    },
    getQuestionFiles() {
      return this.activeSpecialtyFields
        .filter((specialtyField) => {
          const isOfTypeFile = specialtyField.type === "FILE";
          const hasAFileInNavigatorStorage = sessionStorage.getItem(
            "file_for_specialty_field_" + specialtyField.id
          );

          return isOfTypeFile && hasAFileInNavigatorStorage;
        })
        .map((specialtyField) => ({
          id: specialtyField.id,
          type: specialtyField.type,
          storageKey: "file_for_specialty_field_" + specialtyField.id,
        }));
    },
    clearFilesFromStorage() {
      //clear any question file uploaded to session storage.
      this.getQuestionFiles().forEach((questionFile) =>
        sessionStorage.removeItem(questionFile.storageKey)
      );
      //clear procedure report
      sessionStorage.removeItem(this.procedureReportStorageKey);
    },
    async uploadQuestionFiles(questionFiles) {
      return Promise.all(
        questionFiles.map((questionFile) => {
          return uploadFile(questionFile.storageKey).then((data) => {
            //remove item, it's already up.
            sessionStorage.removeItem(questionFile.storageKey);

            return {
              id: questionFile.id,
              type: questionFile.type,
              value: data.file.id,
            };
          });
        })
      );
    },
    async uploadHospitalReport() {
      const hospitalReport = sessionStorage.getItem(
        this.procedureReportStorageKey
      );
      if (hospitalReport) {
        try {
          const { file } = await uploadFile(this.procedureReportStorageKey);
          this.idHospitalReport = file.id;
        } catch (error) {
          console.error(error);
        }
      }
      sessionStorage.removeItem(this.procedureReportStorageKey);
    },
    async fetchFileSrc(id) {
      if (!id) {
        return null;
      }

      try {
        const resFile = await fetchFileById(id);
        const file = resFile.data.file;
        file.token = resFile.data.token;
        return getFileSignedUrl(file);
      } catch (err) {
        console.error(err);
      }
    },
    async checkFetchFiles() {
      this.hospitalReportSrc = await this.fetchFileSrc(
        this.showAppointmentDetails?.idHospitalReport
      );
      await Promise.all(
        this.showAppointmentDetails.appointmentRecordSpecialtyFields.map(
          async (apptSpecialtyField) => {
            if (apptSpecialtyField.type !== "FILE") {
              return apptSpecialtyField;
            }
            const specialtyFieldIndex = this.specialtyFields.findIndex(
              (specialtyField) => specialtyField.id === apptSpecialtyField.id
            );

            if (this.specialtyFields[specialtyFieldIndex]) {
              this.specialtyFields[specialtyFieldIndex].fileId =
                apptSpecialtyField.value;

              if (!this.isPendingOrArchived) {
                this.specialtyFields[specialtyFieldIndex].src =
                  await this.fetchFileSrc(apptSpecialtyField.value);
              }
            }

            return apptSpecialtyField;
          }
        )
      );
    },
  },
  created() {
    if (this.showAppointment === null) return;
    if (this.showAppointment.scheduledAt && this.showAppointment.followUpDate) {
      var a = moment(this.showAppointment.scheduledAt * 1000);
      var b = moment(this.showAppointment.followUpDate * 1000);
      this.relativeNextAppointment = a.from(b, true);
      if (this.relativeNextAppointment == "a month") {
        this.relativeNextAppointment = "1 month";
      }
      if (
        this.relativeNextAppointment != "1 month" &&
        this.relativeNextAppointment != "3 months" &&
        this.relativeNextAppointment != "6 months" &&
        this.relativeNextAppointment != "1 year" &&
        this.relativeNextAppointment != "5 years" &&
        this.relativeNextAppointment != "10 years"
      ) {
        this.relativeNextAppointment = "Other";
        this.showDatePicker_Other = true;
        this.nextAppointmentOther = moment
          .unix(this.showAppointment.followUpDate)
          .format("MM/DD/YYYY");
      }
    }

    if (this.showAppointment.followUpDate) {
      this.followUpDate = this.showAppointment.followUpDate;
    }

    this.specialtyModuleProcessed = this.specialtyModule;
    if (!this.specialtyModuleProcessed) {
      this.specialtyModuleProcessed = this.showAppointment.specialtyModule;
    }

    this.hospitalId = this.$route.params.idHospital;
    if (!this.hospitalId) {
      this.hospitalId = this.getHospitalId;
    }
    this.specialtyId = this.$route.params.idSpecialty;
    if (!this.specialtyId) {
      this.specialtyId = this.showAppointment?.specialtyModule?.id;
    }

    this.tabTypeProcessed = this.tabType;
    if (this.tabType == "displayOnly") {
      let convertType = "";
      switch (this.showAppointment.stage) {
        case "REQUESTED":
          convertType = "requested";
          break;
        case "CONFIRMED":
          convertType = "confirmed";
          break;
        case "COMPLETED_COLONOSCOPY_PENDING_CLINIC_REVIEW":
          convertType = "pending";
          break;
        case "ARCHIVES":
          convertType = "archived";
          break;
      }
      this.tabTypeProcessed = convertType;
    }

    this.getHospitalSpecialty().then(() => {
      this.shouldShowNextAppointment();
    });
    this.fetchPatientAppointmentNotifications();
    this.fetchPatientAppointmentById();
    this.fetchDoctorsSpecialty();

    this.showAppointment?.appointmentRecordSpecialtyFields?.forEach(
      (answer) => {
        this.procedureResults[answer.id] = answer.value;
      }
    );
  },
});
</script>

<style scoped lang="scss">
.radio-custom {
  margin-right: 15px;
}
.flex-wrap {
  flex-wrap: wrap;
}
.margin-top {
  margin-top: 30px;
}
.spaceMarginTop {
  margin-top: 50px;
}
.time_spacer {
  margin-bottom: 46px;
}
.date_spacer {
  margin-bottom: 25px;
}
.info-pair .txt.font-medium {
  font-size: 18px;
  line-height: 28px;
  margin-top: 30px;
}
.cursorPointer svg {
  cursor: pointer;
}
.adjust-margin-top {
  margin-top: -8px;
}
.select-m-bot {
  margin-bottom: 25px;
}
.request_tab_confirm {
  margin-right: 0px;
  margin-left: auto;
}
.card {
  padding: 35px $secondaryCardsSidePadding 68px;
}
.columns.is-variable {
  --columnGap: 1.575%;
}
.columns + .hr {
  background-color: $primaryBgDarkGrey;
  margin-top: -21px;
  margin-bottom: 24px;
}
.card > .columns:last-child.info-pair,
.card > .columns:last-child .info-pair {
  margin-bottom: 0;
}
.svg-icon--eye-red,
.svg-icon--eye-green,
.svg-icon--yes,
.svg-icon--no {
  width: 50px;
  height: 50px;
  top: 0;
}

// Page specific styles----------------------------------------
.insurance-status {
  margin-bottom: 21px;
  p {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.29;
    letter-spacing: 0.07px;
    color: #000;
  }
}
.rescheduled {
  .label {
    & > span {
      position: relative;
    }
    .svg-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: calc(100% + 20px);
    }
  }
}
.breadcrumbs-title {
  margin: 0;
  color: $primaryLightBlue;
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.12px;
}

.complete-appointment-popup,
.popup-error-content {
  max-width: 555px;
  margin: 0 auto;
  h1 {
    width: 100%;
    max-width: 549px;
    margin: 39px auto 30px;
    font-size: 28px;
    font-weight: 700;
    line-height: 1.21;
    letter-spacing: 0.14px;
    text-align: center;
  }
  p {
    margin-top: 0;
    margin-bottom: 32px;
    font-size: 16px;
    line-height: 1.19;
    letter-spacing: 0.23px;
    text-align: center;
  }
  .popup-btns {
    margin-bottom: 39px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .btn {
      width: 260px;
    }
  }
  .popup-btns.center {
    justify-content: center;
  }
}
</style>
