<template lang="pug">
PrimaryCareModuleSettingsOverlay(
  :isOpen="isModuleSettingsOverlayOpen"
  @closeModuleSettingsOverlay="closeModuleSettingsOverlay",
)
</template>

<script>
import { defineComponent } from "vue";
import PrimaryCareModuleSettingsOverlay from "@/components/system-settings/overlays/PrimaryCareModuleSettingsOverlay.vue";

export default defineComponent({
  components: {
    PrimaryCareModuleSettingsOverlay,
  },

  data() {
    return {
      isModuleSettingsOverlayOpen: true,
    };
  },

  methods: {
    closeModuleSettingsOverlay() {
      this.$router.go(-1);
      this.isModuleSettingsOverlayOpen = false;
    },
  },
});
</script>
