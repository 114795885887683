<template lang="pug">
PopupCardSlot(
  :isOpen="isInsurancePopupOpen && isInsured !== null",
  maxWidth="675px"
)
  .insurance-popup
    h1 The slot you selected is for 
      span.green(v-show="isInsured") insured 
      span.red(v-show="!isInsured") uninsured 
      | patient only.
    p Please confirm to continue
    .popup-btns
      BaseBtn.x-lg.red-outline(@click="closeInsurancePopup") CANCEL
      BaseBtn.x-lg.green(@click.prevent="nextPage") YES, CONTINUE

section.step-subtitle
  h2 SELECT SCHEDULE
   span.lighter for 
   span.blue(v-if="patient") {{ getUserFormatted(patient) }}
TableTitle(title="All Schedules")
  template(#actions)
    select.table-action-selector.table-action-selector--hospital(
      :title="hospitalTitle"
      @change="setFilterValueHospital($event.target.value)"
    )
      option(value="") HOSPITAL NAME
      option(v-for="hospital in hospitals" :key="hospital.id" :value="hospital.id") {{ hospital.name }}
    select.table-action-selector.table-action-selector--insurance(
      :title="insuranceTitle",
      style="margin-right: 24px;"
      @change="setFilterValueInsurance($event.target.value)"
    )
      option INSURANCE
      option(value="true") Insured
      option(value="false") Uninsured
    DateSelector(
      @filterByDate="filterByDate"
      :min-date="startDateOffset"
    )
.table
  .th
    .td(@click.prevent="changeListOrder('HospitalName', fetchPatientAppointmentAvailableSchedule)" style="cursor: pointer;") HOSPITAL
      i(style="font-weight: bolder;") {{ HospitalNameColumnSortingIcon }}
    .td(@click.prevent="changeListOrder('DateAndTime', fetchPatientAppointmentAvailableSchedule)" style="cursor: pointer;") Appt. Time
      i(style="font-weight: bolder;") {{ DateAndTimeColumnSortingIcon }}
    .td Insurance
    .td
  .tr(v-for="schedule in schedules",
        :key="schedule.id",)
    .td {{ schedule.hospital.name }}
    .td
      div {{ getDateFromUnixFormatted(schedule.availableAt) }}
      .second-row-md-grey {{ getTimeFromUnixFormatted(schedule.availableAt) }}
    .td
      base-icon(name="yes", v-if="schedule.insuranceRequired")
      base-icon(name="no", v-else)
    .td
      BaseBtn.sm.blue(v-if="!schedule.patientAppointment" @click="openInsurancePopup(schedule)") Select Schedule
      BaseBtn.sm.blue-outline.disabled-outline(v-else) Select Schedule
Pagination(
  v-if="pagination && schedules != null && schedules.length",
  :pagination="pagination",
  :reloadFn="fetchPatientAppointmentAvailableSchedule"
  @changePagination="changePagination"
)
.no-results(v-if="schedules != null && !schedules.length") 
  base-icon(name="exclamation") 
  span No matching records

.actions-below-table-container
    BaseBtn.lg.green-outline(
      :to="{name: 'clinics.scheduling.select-patient', query: $route.query}",
      style="min-width: 270px",
    ) &lt; PREVIOUS STEP
</template>

<script>
import { defineComponent } from "vue";
import moment from "moment";

import router from "@/router";
import TableTitle from "@/components/TableTitle.vue";
import DateSelector from "@/components/DateSelector.vue";
import Pagination from "@/components/Pagination.vue";
import listPagesMixin from "@/mixins/listPagesMixin";

import PopupCardSlot from "@/components/PopupCardSlot.vue";

import { fetchCHSHospitals } from "@/api/chsApi";
import { fetchPatientAppointmentAvailableSchedule } from "@/api/patientAppointmentApi";
import { fetchPatient } from "@/api/patientApi";
import { fetchClinic } from "@/api/clinicApi";
import { apiStatus, apiStatusComputedFactory } from "@/api";
const { IDLE, PENDING, SUCCESS, ERROR } = apiStatus;

import {
  getDateFromUnixFormatted,
  getTimeFromUnixFormatted,
  getUserFormatted,
} from "@/helpers";
import sortClinicSchedules from "@/mixins/sortClinicSchedules";

export default defineComponent({
  components: { TableTitle, PopupCardSlot, Pagination, DateSelector },

  data() {
    return {
      isInsurancePopupOpen: false,
      isInsured: null,
      fetchPatientAppointmentAvailableScheduleStatus: IDLE,
      fetchCHSHospitalsStatus: IDLE,
      fetchPatientStatus: IDLE,
      schedules: null,
      patient: null,
      selectedSchedule: null,
      selectedHospital: null,
      date: null,
      isInsuranceRequired: null,
      idCHS: null,
      insuranceTitle: "INSURANCE",
      hospitalTitle: "HOSPITAL NAME",
      hospitals: null,
      filterIdHospital: null,
      startDateOffset: new Date(Date.now() + 15 * 24 * 60 * 60 * 1000),
    };
  },
  mixins: [listPagesMixin, sortClinicSchedules],

  props: {
    specialtyModule: {
      required: false,
    },
  },
  computed: {
    ...apiStatusComputedFactory(
      "fetchPatientAppointmentAvailableScheduleStatus",
      "fetchPatientStatus"
    ),
  },

  methods: {
    getUserFormatted,
    getDateFromUnixFormatted,
    getTimeFromUnixFormatted,
    filterByDate(date) {
      this.date = date;
      this.fetchPatientAppointmentAvailableSchedule();
    },
    setFilterValueHospital(value) {
      if (value != "") {
        this.filterIdHospital = value;
        this.hospitals.forEach((hospital) => {
          if (hospital.id == value) {
            this.hospitalTitle = hospital.name;
          }
        });
      } else {
        this.filterIdHospital = null;
        this.hospitalTitle = "HOSPITAL NAME";
      }
      this.fetchPatientAppointmentAvailableSchedule();
    },
    setFilterValueInsurance(value) {
      if (value === "true" || value === "false") {
        if (value === "true") {
          this.insuranceTitle = "INSURED";
        } else {
          this.insuranceTitle = "UNINSURED";
        }
        this.isInsuranceRequired = value;
      } else {
        this.insuranceTitle = "INSURANCE";
        this.isInsuranceRequired = null;
      }
      this.fetchPatientAppointmentAvailableSchedule();
    },
    async fetchClinic() {
      try {
        const res = await fetchClinic(this.$route.params.idClinic);
        this.idCHS = res.data.idClinicHealthSystem;
      } catch (err) {
        console.error(err);
      }
    },
    async fetchPatient() {
      this.fetchPatientStatus = PENDING;
      try {
        const res = await fetchPatient(this.$route.params.idPatient);
        this.patient = res.data.patient;
        this.fetchPatientStatus = SUCCESS;
      } catch (err) {
        this.fetchPatientStatus = ERROR;
        console.error(err);
      }
    },
    async fetchCHSHospitals() {
      this.fetchCHSHospitalsStatus = PENDING;
      try {
        const res = await fetchCHSHospitals(this.idCHS);
        this.hospitals = res.data.hospitals;
        this.fetchCHSHospitalsStatus = SUCCESS;
      } catch (err) {
        this.fetchCHSHospitalsStatus = ERROR;
        console.error(err);
      }
    },

    async fetchPatientAppointmentAvailableSchedule() {
      this.fetchPatientAppointmentAvailableScheduleStatus = PENDING;
      const startDateOffsetNoMiliseconds = Math.floor(
        this.startDateOffset / 1000
      );
      let navigatePage = 1;
      if (this.reqPagination.page) {
        navigatePage = this.reqPagination.page;
      }
      const order = this.listOrder
        ? {
            by: this.listOrder.by,
            direction: this.listOrder.direction,
          }
        : null;
      try {
        const res = await fetchPatientAppointmentAvailableSchedule({
          params: {
            ...this.reqPagination,
            order,
            collapsedDoctor: false,
            idClinicHealthSystem: this.idCHS,
            include: ["hospital", "patientAppointment"],
            isAvailable: "Available",
            startDate: startDateOffsetNoMiliseconds,
            availableAt: this.date ?? null,
            isInsuranceRequired: this.isInsuranceRequired ?? null,
            idSpecialtyModule: this.$route.params.idSpecialty,
            idHospital: this.filterIdHospital ?? null,
            page: navigatePage,
          },
        });
        this.schedules = res.data.schedules;
        this.pagination = res.data.pagination;
        this.fetchPatientAppointmentAvailableScheduleStatus = SUCCESS;
      } catch (err) {
        this.fetchPatientAppointmentAvailableScheduleStatus = ERROR;
        console.error(err);
      }
    },
    openInsurancePopup(schedule) {
      this.isInsured = !!schedule.insuranceRequired;
      this.selectedSchedule = schedule.id;
      this.selectedHospital = schedule.idEntity;
      this.isInsurancePopupOpen = true;
    },
    closeInsurancePopup() {
      this.isInsurancePopupOpen = false;
      this.isInsured = null;
    },
    nextPage() {
      this.isInsurancePopupOpen = false;
      this.isInsured = null;
      let params = {
        idPatient: this.$route.params.idPatient,
        idSchedule: this.selectedSchedule,
        idHospital: this.selectedHospital,
      };
      if (this.$route.params.idFitKit) {
        params.idFitKit = this.$route.params.idFitKit;
      }
      router.push({
        name: "clinics.scheduling.referral-info",
        query: this.$route.query,
        params,
      });
    },
  },
  created() {
    if (this.$route.params.idClinic === null) return;
    this.fetchClinic().then(() => {
      this.fetchPatientAppointmentAvailableSchedule();
      this.fetchCHSHospitals();
    });
    this.fetchPatient();
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/components/step-subtitle.scss";
@import "@/scss/components/actions-below-table-container.scss";
.td {
  &:nth-child(1) {
    width: 29.2%;
  }
  &:nth-child(2) {
    width: 19.3%;
  }
  &:nth-child(3) {
    width: 32%;
  }
  &:nth-child(4) {
    width: 19.5%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    .btn {
      min-width: 190px;
    }
  }
}
.insurance-popup {
  max-width: 555px;
  margin: 0 auto;
  h1 {
    width: 100%;
    max-width: 420px;
    margin: 39px auto 30px;
    font-size: 28px;
    font-weight: 700;
    line-height: 1.21;
    letter-spacing: 0.14px;
    text-align: center;
  }
  p {
    margin-top: 0;
    margin-bottom: 32px;
    font-size: 16px;
    line-height: 1.19;
    letter-spacing: 0.23px;
    text-align: center;
  }
  .popup-btns {
    margin-bottom: 39px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .btn {
      width: 260px;
    }
  }
}
</style>
