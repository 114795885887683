<template lang="pug">
FullScreenOverlaySlot(
  :isOpen="isOpen",
  headerIcon="icc-logo",
  :title="overlayTitle"
  @closeOverlay="closeOverlay"
)
  template(#breadcrumbs)
    h1.breadcrumbs-title IL CARE CONTINUUM
  template(#content)
    .card.clinic_details
      form#manage-clinic(
      @submit.prevent="saveClinic",
      novalidate
      ) 
        .columns.is-variable
          .column.is-two-thirds
            .form-group(:class="{ 'form-group__invalid': v$.clinic.name.$errors.length }")
              label.label--margin-sm Clinic Name (Required)
              input.input.text-lg(type="text", v-model="clinic.name" @blur="v$.clinic.name.$touch()")
              .form-group__errors(v-if="v$.clinic.name.$dirty && v$.clinic.name.$errors.length")
                .form-group__error(v-if="v$.clinic.name.required.$invalid") Clinic name is required!
          .column.is-one-fifth
            .form-group(:class="{ 'form-group__invalid': v$.clinic.contact.businessPhone.$errors.length }")
              label.label--margin-sm Phone Number (Required)
              input.input.text-lg(
                type="text", 
                v-model="clinic.contact.businessPhone" 
                @blur="v$.clinic.contact.businessPhone.$touch()"
                
                v-maska="'###-###-####'"
                @maska="businessPhoneRawValue"
              )
              .form-group__errors(v-if="v$.clinic.contact.businessPhone.$dirty && v$.clinic.contact.businessPhone.$errors.length")
                .form-group__error(v-if="v$.clinic.contact.businessPhone.$invalid") Phone number is required!
          .column.is-one-fifth.phone-ext
            .form-group
              label.label--margin-sm Ext.
              input.input.text-lg(type="text", v-model="clinic.contact.businessPhoneExtension")
        .columns.is-variable
          .column.is-full
            .form-group(:class="{ 'form-group__invalid': v$.clinic.address.address.$errors.length }")
              label.label--margin-sm Clinic Address (Required)
              input.input.text-lg(type="text", v-model="clinic.address.address" @blur="v$.clinic.address.address.$touch()")
              .form-group__errors(v-if="v$.clinic.address.address.$dirty && v$.clinic.address.address.$errors.length")
                .form-group__error(v-if="v$.clinic.address.address.required.$invalid") Clinic address is required!
        Address(
          @addressResults="addressResults"
          :institution="clinic"
          textSize="text-lg"
          :key="`${componentKey}-1`",
        )
        .columns.is-variable(v-if="clinicId && showLinkedCommunitySystems")
          .column.is-full
            .table.table--no-side-padding
              .th.hr_only
            BaseBtn.btn.lg.blue.extra_m_bot(
              @click.prevent="openManageLinkedCommunitySystemsOverlay"
            ) LINKED COMMUNITY SYSTEMS

  template(#footer)
    BaseBtn.btn.lg.red-outline(@click.prevent="closeOverlay") CANCEL CHANGES
    BaseBtn.btn.lg.green(
      type="submit",
      form="manage-clinic", 
      :disabled="v$.$invalid || isSubmitting"
      :class="{spinner: isSubmitting }"
    ) SAVE AND EXIT
      //- @click.prevent="saveClinic"

ManageLinkedCommunitySystemsOverlay(
  :key="`${componentKey}-1`",
  :isOpen="isManageLinkedCommunitySystemsOverlayOpen",
  @closeManageLinkedCommunitySystemsOverlay="closeManageLinkedCommunitySystemsOverlay"
  :clinicId="clinicId"
)

</template>

<script>
import { defineComponent } from "vue";
import { fetchClinic, createClinic, updateClinic } from "@/api/clinicApi";
import FullScreenOverlaySlot from "@/components/overlays/FullScreenOverlaySlot.vue";
import ManageLinkedCommunitySystemsOverlay from "@/components/all-clinics/overlays/ManageLinkedCommunitySystemsOverlay.vue";
import Address from "@/components/Address.vue";
import listPagesMixin from "@/mixins/listPagesMixin";

import useVuelidate from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import { maska, mask } from "maska";
import { fetchCHS } from "@/api/chsApi";

export default defineComponent({
  directives: { maska },
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    FullScreenOverlaySlot,
    ManageLinkedCommunitySystemsOverlay,
    Address,
  },
  mixins: [listPagesMixin],
  emits: ["closeClinicOverlay", "reloadClinics"],

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    clinicId: {
      required: false,
    },
  },

  data() {
    return {
      isManageLinkedCommunitySystemsOverlayOpen: false,
      isSubmitting: false,
      clinic: {
        id: null,
        idAddress: null,
        idContact: null,
        idClinicHealthSystem: this?.$route?.params?.chsId,
        name: "",
        updatedAt: null,
        address: {
          id: null,
          createdAt: null,
          abbreviation: "",
          address: "",
          idPlace: null,
          idState: null,
          idZipCode: null,
          place: "",
          state: "",
          updatedAt: null,
          zipCode: "",
        },
        contact: {
          id: null,
          businessPhone: null,
          businessPhoneRaw: null,
          businessPhoneExtension: "",
          cellPhone: "",
          cellPhoneExtension: "",
          homePhone: "",
          homePhoneExtension: "",
        },
      },
      showLinkedCommunitySystems: false,
    };
  },
  validations() {
    return {
      clinic: {
        name: { required },
        contact: {
          businessPhone: {
            required,
            minLength: minLength(12),
          },
        },
        address: {
          address: { required },
        },
      },
    };
  },
  computed: {
    overlayTitle() {
      return this.clinicId ? "MANAGE CLINIC" : "ADD NEW CLINIC";
    },
  },

  methods: {
    businessPhoneRawValue: function (event) {
      this.clinic.contact.businessPhoneRaw = event.target.dataset.maskRawValue;
    },
    addressResults(city, state, zip, idPlace, idState, idZipCode) {
      this.clinic.address.place = city;
      this.clinic.address.state = state;
      this.clinic.address.zipCode = zip;
      this.clinic.address.idPlace = parseInt(idPlace, 10);
      this.clinic.address.idState = parseInt(idState, 10);
      this.clinic.address.idZipCode = parseInt(idZipCode, 10);
    },

    closeOverlay() {
      this.$emit("closeClinicOverlay");
    },
    closeManageLinkedCommunitySystemsOverlay() {
      this.isManageLinkedCommunitySystemsOverlayOpen = false;
    },
    openManageLinkedCommunitySystemsOverlay() {
      this.isManageLinkedCommunitySystemsOverlayOpen = true;
    },
    async fetchClinic() {
      try {
        const res = await fetchClinic(this.clinicId);
        this.clinic = res.data;
        if (res.data.idClinicHealthSystem) {
          const {
            data: { primaryCare },
          } = await fetchCHS(res.data.idClinicHealthSystem);
          this.showLinkedCommunitySystems = primaryCare;
        }

        this.clinic.contact.businessPhone = mask(
          this.clinic.contact.businessPhone,
          "###-###-####"
        );
      } catch (err) {
        console.error(err);
      }
    },

    async createClinic() {
      this.isSubmitting = true;
      try {
        let sendParams = JSON.parse(JSON.stringify(this.clinic));

        sendParams.contact.businessPhone = sendParams.contact.businessPhoneRaw;
        delete sendParams.contact.businessPhoneRaw;

        const res = await createClinic({
          ...sendParams,
        });
        console.log(res);
      } catch (err) {
        console.error(err);
      } finally {
        this.isSubmitting = false;
      }
    },

    async updateClinic() {
      this.isSubmitting = true;
      try {
        let sendParams = JSON.parse(JSON.stringify(this.clinic));

        sendParams.contact.businessPhone = sendParams.contact.businessPhoneRaw;
        delete sendParams.contact.businessPhoneRaw;
        await updateClinic(this.clinicId, {
          ...sendParams,
        });
      } catch (err) {
        console.error(err);
      } finally {
        this.isSubmitting = false;
      }
    },

    async saveClinic() {
      if (this.clinicId === null) {
        await this.createClinic();
      } else {
        await this.updateClinic();
      }
      this.$emit("reloadClinics");
      this.closeOverlay();
    },
  },
  created() {
    if (this.clinicId === null) return;
    this.fetchClinic();
  },
});
</script>

<style scoped lang="scss">
.extra_m_bot {
  margin-bottom: 25px;
}
.phone-ext {
  max-width: 13.4%;
}
.table .th.hr_only {
  padding-bottom: 11px;
  margin-bottom: 53px;
}
.table .tr.tr_child {
  padding-top: 0px;
  padding-bottom: 33px;
}
.tr.tr__row {
  padding-top: 33px;
  padding-bottom: 33px;
}
.no_bottom_border {
  border-bottom: 0px;
}
.card {
  padding: 35px $secondaryCardsSidePadding 20px $secondaryCardsSidePadding;
  &.clinic_details {
    padding-top: 55px;
    padding-bottom: 45px;
    .form-group {
      margin-bottom: 18px;
    }
  }
}
.columns.is-variable {
  --columnGap: 1.575%;
}
.columns + .hr {
  background-color: $primaryBgDarkGrey;
  margin-top: -21px;
  margin-bottom: 24px;
}
.card > .columns:last-child.info-pair,
.card > .columns:last-child .info-pair {
  margin-bottom: 0;
}
.svg-icon--eye-red,
.svg-icon--eye-green,
.svg-icon--yes,
.svg-icon--no {
  width: 50px;
  height: 50px;
  top: 0;
}

// Page specific styles----------------------------------------
.breadcrumbs-title {
  margin: 0;
  color: $primaryLightBlue;
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.12px;
}
.procedure-dropdown-instructions {
  margin-top: -20px;
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 1.29;
}
.table {
  .td {
    &:nth-child(1) {
      width: math.percentage(math.div(810, $tabContentCardWidth));
    }
    &:nth-child(2) {
      width: math.percentage(math.div(296, $tabContentCardWidth));
    }
    &:nth-child(3) {
      width: math.percentage(math.div(74, $tabContentCardWidth));
    }
  }
  .row-main-options {
    padding: 35px 0;
    .base-checkbox:not(:last-child) {
      margin-right: 84px;
    }
  }
  .th {
    padding-bottom: 25px;
  }
}
</style>
