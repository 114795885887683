// @ts-nocheck
import api from "./api";

const patientUrl = "/patient";

export const fetchOnGoingCarePatient = (data = {}) => {
  return api.get(patientUrl + "/on-going", data);
};

export const fetchOnGoingCarePatientHistory = (idPatient, data = {}) => {
  return api.get(patientUrl + "/" + idPatient + "/on-going-history", data);
};
