<template lang="pug">
FullScreenOverlaySlot(
  :isOpen="isOpen",
  headerIcon="icc-logo",
  :title="overlayTitle"
  @closeOverlay="closeOverlay"
)
  template(#breadcrumbs)
    h1.breadcrumbs-title IL CARE CONTINUUM
  template(#content)
    .card.clinic-title
      .columns.is-variable
        .column.is-two-thirds
          .form-group
            label.label--margin-sm Community Organization Title
            input.input.text-lg(type="text" v-model="communityOrganization.name")
        .info-pair.column.is-one-third
          .form-group
            label.label--margin-sm(for="organization-type") Organization Type (Required)
            select#organization-type(
              v-model="communityOrganization.type"
            )
              option(:value="null") Select
              option(
                v-for="organizationType in ccoTypes"
                :key="organizationType.value"
                :value="organizationType.value"
              ) {{organizationType.label}}

    .card.modules
      .title-md-thin-with-action
        h2.title.title--md-thin(style="margin-bottom: 27px;") MODULES
      
      .table.table--no-side-padding
        .th
          .td General Modules
          .td Active
        .tr.tr__row.no_bottom_border
          .td
            h2 Fit Registry
          .td 
            BaseToggle(v-model="communityOrganization.fitRegistry")
        .tr.tr_child(v-if="communityOrganization.fitRegistry")
          .columns.is-variable
            FollowUpField(
              v-model="communityOrganization.firstFollowUp",
              label="First Follow-up",
              :weekNumbersArray="[1,2,3,4]"
            )
            FollowUpField(
              v-model="communityOrganization.secondFollowUp",
              label="Second Follow-up",
              :weekNumbersArray="[2,3,4,6]",
              :firstWeekNumber="communityOrganization.firstFollowUp"

            )
        .tr.tr__row.no_bottom_border.p_bot_10
          .td
            h2 Primary Care
          .td 
            BaseToggle(v-model="communityOrganization.primaryCare")
        .tr.has_access
            BaseCheckbox(
              label="Has Access to Patient Records"
              v-model="communityOrganization.hasPatientAccess"
            )

        .tr.tr__row.no_bottom_border.p_bot_10
          .td
            h2 Referral Module
          .td 
            BaseToggle(v-model="communityOrganization.referralModule")
        .tr.has_access
            //- BaseCheckbox(
            //-   label="Has Access to Patient Records"
            //-   v-model="communityOrganization.hasPatientAccess"
            //- )
            
    .card.help_resources
      h2.title.title--md-thin HELP & RESOURCES
      .columns.is-variable
        .column.is-one-third
          .form-group
            label(for="user-support-text") User Support Text
            textarea#user-support-text(
              style="height:250px;"
              v-model="communityOrganization.userSupportText"
            )
        .column.is-one-third
          .form-group
            label(for="resources-text") Resources Text
            textarea#resources-text(
              style="height:250px;"
              v-model="communityOrganization.resourcesText"
            )
      .columns.is-variable
        .column.is-full
          .form-group(v-for="file in loadedFiles" :key="file.id")
            a(href="#" @click.prevent="preview(file.url)") {{file.title}} ( {{file.file.name}} ) 
            base-icon(@click.prevent="markToBeRemoved(file)" name="x")
      .columns.is-variable
        .column.is-full
          .form-group(v-for="confirmedFile in filesConfirmedToUpload" :key="confirmedFile.id")
            a(href="#" @click.prevent="preview(confirmedFile.url)") {{confirmedFile.title}} ( {{confirmedFile.name}} ) 
            base-icon(@click.prevent="removeConfirmed(confirmedFile.key)" name="x")
      .columns.is-variable
        .column.is-two-thirds
          .form-group
            label.label--margin-sm Additional Resource Title
            input.input.text-lg(type="text" v-model="extraResourceTitle")
        .info-pair.column.is-one-third
          .label--margin-sm Upload File
          .txt
            UploadAndPreviewFile(
              :buttonStyleClasses="['btn', 'lg', !extraResourceTitle ? 'grey disabled' : 'blue']"
              :storageKey="additionalResourceStorageKey"
              :key="forcedRerenderTimes"
              @fileLoaded="forceRerender"
              :disabled="!extraResourceTitle"
            )
      BaseBtn.btn.lg.blue(
        :disabled="!extraResourceTitle"
        v-if="anAdditionalResourceFileIsSelected"
        @click.prevent="confirmFileToUpload"
      ) Confirm Upload

  template(#footer)
    BaseBtn.btn.lg.red-outline(href="#", @click.prevent="closeOverlay") CANCEL CHANGES
    BaseBtn.btn.lg.green(
      @click.prevent="save"
      :disabled="v$.$invalid || isSubmitting || !saveIsAllowed"
      :class="{spinner: isSubmitting }"
    ) SAVE AND EXIT

</template>

<script>
import { defineComponent } from "vue";
import FullScreenOverlaySlot from "@/components/overlays/FullScreenOverlaySlot.vue";
import FollowUpField from "@/components/FollowUpField.vue";
import UploadAndPreviewFile from "@/components/UploadAndPreviewFile";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

import { preview } from "@/helpers/preview";
import sanitizeHtml from "sanitize-html";
import {
  addCCOFiles,
  createCCO,
  fetchCommunityCareOrganizationById,
  fetchCommunityCareOrganizationFilesById,
  updateCCO,
} from "@/api/communityOrganization";
import { fetchFileById, uploadFile } from "@/api/filesApi";
import { getFileSignedUrl } from "@/helpers";

const COMMUNITY_TYPES = [
  {
    label: "Community Based Organization",
    value: "CBO",
  },
  {
    label: "Emergency Room",
    value: "ER",
  },
  {
    label: "Health Department",
    value: "Health Department",
  },
];

export default defineComponent({
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    FullScreenOverlaySlot,
    FollowUpField,
    UploadAndPreviewFile,
  },
  emits: ["closeCommunityOrganizationsOverlay"],
  validations() {
    let args = {
      communityOrganization: {
        name: {
          required,
        },
      },
    };

    return args;
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    CommunityOrganizationsOverlayType: {
      type: String,
    },
    ccoId: {
      required: false,
    },
  },

  data() {
    return {
      isSubmitting: false,
      isModuleEnabled: true,
      overlayTitle: "MANAGE COMMUNITY ORGANIZATION",
      specialtyModules: [
        { name: "Colonoscopy" },
        { name: "Hepatitis B" },
        { name: "Breast Cancer" },
      ],
      communityOrganization: {
        name: null,
        type: null,
        fitRegistry: false,
        firstFollowUp: 0,
        secondFollowUp: 0,
        primaryCare: false,
        hasPatientAccess: false,
        userSupportText: null,
        resourcesText: null,
      },
      additionalResourceStorageKey: "cco_resources_additional", //key for current selected but not confirmed file item to upload
      confirmedFilesToUploadStorageKey: "additional_resources_keys", //key for storage item that holds the list of confirmed file keys, as an string array.
      forcedRerenderTimes: 0,
      additionalResourceFiles: [],
      filesToBeRemoved: [],
      extraResourceTitle: "",
      createdCCOId: null,
      timer: null,
    };
  },

  computed: {
    ccoTypes() {
      return COMMUNITY_TYPES;
    },
    communityFitRegistry() {
      return this.communityOrganization.fitRegistry;
    },
    anAdditionalResourceFileIsSelected() {
      //add force re-render times as dependency for this computed;
      this.forcedRerenderTimes;
      return !!sessionStorage.getItem(this.additionalResourceStorageKey);
    },
    filesConfirmedToUpload() {
      this.forcedRerenderTimes;

      const keys =
        JSON.parse(
          sessionStorage.getItem(this.confirmedFilesToUploadStorageKey)
        ) || [];
      return keys.map((key) => ({
        key,
        ...JSON.parse(sessionStorage.getItem(key)),
      }));
    },
    loadedFiles() {
      return this.additionalResourceFiles.filter(
        (file) =>
          !this.filesToBeRemoved.some(
            (fileMarkedToBeRemoved) => fileMarkedToBeRemoved.id === file.id
          )
      );
    },
    saveIsAllowed() {
      const hasName = this.communityOrganization.name;
      const hasType = this.communityOrganization.type;
      const hasFitRegistry = this.communityOrganization.fitRegistry;

      if (hasFitRegistry) {
        return (
          hasName &&
          hasType &&
          this.communityOrganization.firstFollowUp &&
          this.communityOrganization.secondFollowUp
        );
      } else {
        return hasName && hasType;
      }
    },
  },

  methods: {
    closeOverlay() {
      this.communityOrganization = {
        name: null,
        type: null,
        fitRegistry: false,
        firstFollowUp: undefined,
        secondFollowUp: undefined,
        primaryCare: false,
        referralModule: false,
        hasPatientAccess: false,
        userSupportText: null,
        resourcesText: null,
      };
      this.additionalResourceFiles = [];
      this.filesToBeRemoved = [];
      this.extraResourceTitle = "";
      this.$emit("closeCommunityOrganizationsOverlay");
      this.forceRerender();
    },
    async save() {
      if (this.communityOrganization?.userSupportText) {
        this.communityOrganization.userSupportText = this.getSecureHtml(
          this.communityOrganization.userSupportText
        );
      }
      if (this.communityOrganization?.resourcesText) {
        this.communityOrganization.resourcesText = this.getSecureHtml(
          this.communityOrganization.resourcesText
        );
      }
      if (!this.ccoId) {
        await this.createCCO();
      } else {
        await this.updateCCO();
      }
      await this.uploadExtraResources();
      this.closeOverlay();
    },
    getSecureHtml(unsafe) {
      return sanitizeHtml(unsafe);
    },
    async createCCO() {
      this.isSubmitting = true;
      try {
        const {
          data: { communityCareOrganization },
        } = await createCCO({
          communityCareOrganization: this.communityOrganization,
        });
        this.createdCCOId = communityCareOrganization.id;
      } catch (err) {
        console.err(err);
      } finally {
        this.isSubmitting = false;
      }
    },
    async updateCCO() {
      this.isSubmitting = true;
      if (this.ccoId) {
        try {
          const allowedAttributes = {
            ...this.communityOrganization,
          };
          delete allowedAttributes.createdAt;

          if (this.communityOrganization.fitRegistry === false) {
            delete allowedAttributes.firstFollowUp;
            delete allowedAttributes.secondFollowUp;
          }

          await updateCCO(this.ccoId, {
            communityCareOrganization: allowedAttributes,
          });
        } catch (error) {
          console.error(error);
        } finally {
          this.isSubmitting = false;
        }
      }
    },
    async uploadExtraResources() {
      const keys = JSON.parse(
        sessionStorage.getItem(this.confirmedFilesToUploadStorageKey) || "[]"
      );

      if (keys.every((key) => sessionStorage.getItem(key))) {
        const files = await Promise.all(
          keys.map(async (key) => {
            try {
              const { file } = await uploadFile(key);
              const fileInStorage = JSON.parse(sessionStorage.getItem(key));
              sessionStorage.removeItem(key);
              return {
                ...file,
                title: fileInStorage.title,
              };
            } catch (error) {
              console.error(error);
            }
          })
        );

        sessionStorage.removeItem(this.confirmedFilesToUploadStorageKey);

        await addCCOFiles(this.ccoId || this.createdCCOId, {
          filesAdded: [...files],
          filesRemoved: [...this.filesToBeRemoved],
        });
      }
    },
    forceRerender() {
      this.forcedRerenderTimes++;
    },
    confirmFileToUpload() {
      const confirmedFilesToUploadKeys = JSON.parse(
        sessionStorage.getItem(this.confirmedFilesToUploadStorageKey) || "[]"
      );
      const confirmedFileKey =
        this.additionalResourceStorageKey + "_" + new Date().getTime();
      const currentFileToConfirm = {
        ...JSON.parse(
          sessionStorage.getItem(this.additionalResourceStorageKey)
        ),
        title: this.extraResourceTitle,
      };
      //pass current to an indexable key
      sessionStorage.setItem(
        confirmedFileKey,
        JSON.stringify(currentFileToConfirm)
      );
      //update list of confirmed files
      sessionStorage.setItem(
        this.confirmedFilesToUploadStorageKey,
        JSON.stringify([...confirmedFilesToUploadKeys, confirmedFileKey])
      );
      //remove current item because is already in a new key
      sessionStorage.removeItem(this.additionalResourceStorageKey);
      this.extraResourceTitle = "";
      //re render view
      this.forceRerender();
    },
    removeConfirmed(key) {
      sessionStorage.removeItem(key);
      const keyList = JSON.parse(
        sessionStorage.getItem(this.confirmedFilesToUploadStorageKey)
      ).filter((keyFromList) => keyFromList !== key);

      sessionStorage.setItem(
        this.confirmedFilesToUploadStorageKey,
        JSON.stringify(keyList)
      );
      this.forceRerender();
    },
    markToBeRemoved(file) {
      this.filesToBeRemoved = [...this.filesToBeRemoved, file];
    },
    preview(src) {
      preview(src);
    },
    async fetchCommunityCareOrganizationToManage(id) {
      const { data: cco } = await fetchCommunityCareOrganizationById(id);
      this.communityOrganization = cco;
      const {
        data: { files },
      } = await fetchCommunityCareOrganizationFilesById(id);

      this.additionalResourceFiles = await Promise.all(
        files.map(async (file) => {
          const { data } = await fetchFileById(file.idFile);
          const filedata = data.file;
          filedata.token = data.token;
          return {
            ...file,
            url: getFileSignedUrl(filedata),
          };
        })
      );
    },
    async fetchCommunityResources() {
      const {
        data: { files },
      } = await fetchCommunityCareOrganizationFilesById(this.ccoId);

      this.additionalResourceFiles = await Promise.all(
        files.map(async (file) => {
          const { data } = await fetchFileById(file.idFile);
          const filedata = data.file;
          filedata.token = data.token;
          return {
            ...file,
            url: getFileSignedUrl(filedata),
          };
        })
      );
    },
    cancelAutoRefresh() {
      clearInterval(this.timer);
    },
  },
  unmounted() {
    this.cancelAutoRefresh();
  },
  created() {
    this.timer = setInterval(this.fetchCommunityResources, 25000);
  },

  watch: {
    CommunityOrganizationsOverlayType: function (val) {
      if (val === "manage") {
        this.overlayTitle = "MANAGE COMMUNITY ORGANIZATION";
      } else {
        this.overlayTitle = "ADD NEW COMMUNITY ORGANIZATION";
      }
    },
    communityFitRegistry(value) {
      if (!value) {
        this.communityOrganization.firstFollowUp = undefined;
        this.communityOrganization.secondFollowUp = undefined;
      }
    },
    ccoId(value) {
      if (value) {
        this.fetchCommunityCareOrganizationToManage(value);
        return;
      }
    },
  },
});
</script>

<style scoped lang="scss">
.tr.tr__row.p_bot_10 {
  padding-bottom: 10px;
}
.tr.has_access {
  padding-top: 0px;
  padding-bottom: 35px;
}
.help_resources h2.title--md-thin {
  margin-bottom: 38px;
}
.table .tr.tr_child {
  padding-top: 0px;
  padding-bottom: 33px;
}
.tr.tr__row {
  padding-top: 33px;
  padding-bottom: 33px;
}
.specialty_modules {
  margin-top: 102px;
}
.no_bottom_border {
  border-bottom: 0px;
}
.card {
  padding: 35px $secondaryCardsSidePadding 20px $secondaryCardsSidePadding;
  &.clinic-title {
    padding-top: 55px;
  }
  &.help_resources,
  &.modules {
    padding-top: 45px;
  }
}
.columns.is-variable {
  --columnGap: 1.575%;
}
.columns + .hr {
  background-color: $primaryBgDarkGrey;
  margin-top: -21px;
  margin-bottom: 24px;
}
.card > .columns:last-child.info-pair,
.card > .columns:last-child .info-pair {
  margin-bottom: 0;
}
.svg-icon--eye-red,
.svg-icon--eye-green,
.svg-icon--yes,
.svg-icon--no {
  width: 50px;
  height: 50px;
  top: 0;
}

// Page specific styles----------------------------------------
.breadcrumbs-title {
  margin: 0;
  color: $primaryLightBlue;
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.12px;
}
.procedure-dropdown-instructions {
  margin-top: -20px;
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 1.29;
}
.table {
  .td {
    &:nth-child(1) {
      width: math.percentage(math.div(1108, $tabContentCardWidth));
    }
    &:nth-child(2) {
      width: math.percentage(math.div(72, $tabContentCardWidth));
    }
  }
  .row-main-options {
    padding: 35px 0;
    .base-checkbox:not(:last-child) {
      margin-right: 84px;
    }
  }
  .th {
    padding-bottom: 25px;
  }
}
</style>
