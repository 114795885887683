<template lang="pug">
div(v-click-outside="onClickOutside")
  .form-group(:class="{ 'form-group__invalid': v$.scheduleTime.$errors.length }")
    label(for="time") Time (Required)
    input(
      v-model="scheduleTime" 
      placeholder="HH:MM" 
      @focus="openShowPossibleTimes" 
      readonly
    )
    div.dropdown_input_wrapper(v-if="showPossibleTimes")
      ul(ref="possibleDates")
        li(
          v-for="(possibleTime, index) in possibleTimes", 
          :key="index", 
          @click.stop="onChange(possibleTime.hour, index)" ,
          :class="{ 'active': possibleTime.active}",
        )
          span(v-html="possibleTime.hour")
    .form-group__errors(v-if="v$.scheduleTime.$dirty && v$.scheduleTime.$errors.length")
      .form-group__error Time is required!
</template>
<script>
import { defineComponent } from "vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import moment from "moment";

export default defineComponent({
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    let args = {
      scheduleTime: {},
    };
    if (this.isRequired) {
      args.scheduleTime = { required };
    }
    return args;
  },
  emits: ["timeResults"],
  props: {
    isRequired: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      scheduleTime: null,
      hours: [],
      showPossibleTimes: false,
      startTime: null,
    };
  },
  methods: {
    onClickOutside(){
      this.showPossibleTimes = false;
    },
    onChange(val, index) {
      this.possibleTimes.forEach((time, k) => {
        time.active = false;
        if (k == index) {
          time.active = true;
        }
      });
      this.showPossibleTimes = false;
      this.scheduleTime = val;
      this.v$.scheduleTime.$touch();
      this.$emit("timeResults", this.scheduleTime);
    },
    checkIfCurrentTime(hour, minute) {
      let end = moment({ hour, minute });
      let difference = moment.duration(end.diff(this.startTime));
      if (difference.asHours() > 0 && difference.asHours() < 1) {
        if (difference.asMinutes() < 15) {
          return true;
        }
      }
      return false;
    },
    openShowPossibleTimes() {
      this.showPossibleTimes = true;
      this.$nextTick(() => {
        const container = this.$refs.possibleDates;
        container.scrollTop = document.querySelector(".active").offsetTop;
      });
    },
  },
  created() {
    const hours = [];
    this.startTime = moment();

    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 15) {
        let timeEntry = {};
        timeEntry.active = this.checkIfCurrentTime(hour, minute);
        timeEntry.hour = moment({ hour, minute }).format("h:mm A");
        hours.push(timeEntry);
      }
    }
    this.possibleTimes = hours;
  },
});
</script>

<style scoped lang="scss">
.dropdown_input_wrapper {
  position: relative;
  width: 100%;
  height: 0px;
  margin: 0px;
  ul {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: block;
    min-width: 160px;
    padding: 5px 0 0px 0;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
    box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
    max-height: 210px;
    overflow-y: scroll;
    overflow-x: hidden;
    li {
      display: block;
      padding: 3px 20px;
      line-height: 1.42857143;
      white-space: nowrap;
      cursor: pointer;
    }
    li.active,
    li:hover {
      color: #fff;
      text-decoration: none;
      background-color: $primaryLightBlue;
      outline: 0;
    }
  }
}
</style>
