<template lang="pug">
.dashboard(v-if="clinicId && dashboard")
  .equal-heights-card-row
    .card.card--action-items
      DashboardActionItems(:clinicId="clinicId", :dashboard="dashboard")
    .card.card--dashboard-notifications
      DashboardNotifications()

  h2.title-blue-underlined(v-if="dashboard.fitRegistry !== null || !!colonoscopySpecialty")
    base-icon(name="colon")
    | ILLINOIS COLON CARES
  .equal-heights-card-row
    .card.half(v-if="dashboard.fitRegistry !== null")
      DashboardFitRegistry(:clinicId="clinicId", :fitRegistry="dashboard?.fitRegistry")
    .card.half(v-if="colonoscopySpecialty")
      DashboardSpecialty(
        :clinicId="clinicId",
        :specialty="colonoscopySpecialty"
        :key="colonoscopySpecialty.specialtyModule.id"
      )

    .specialties(v-if="specialtiesWithoutColonoscopy?.length || dashboard.primaryCare !== null")
      .specialty(v-if="dashboard.primaryCare !== null")
        h2.title-blue-underlined
          span.title-icon(:style="{'background-image': 'url(' + clinicIcon + ')'}")
          | ILLINOIS PRIMARY CARE
        .card
          DashboardPrimaryCare(
            :clinicId="clinicId", 
            :specialty="dashboard?.primaryCare || null"
          )
      template(v-if="specialtiesWithoutColonoscopy?.length")
        .specialty(v-for="specialty in specialtiesWithoutColonoscopy", :key="specialty.specialtyModule.id")
          h2.title-blue-underlined(style="margin-right: 24px;")
            span.title-icon(:style="{'background-image': 'url(' + specialty.specialtyModule.icon + ')'}")
            | {{specialty.specialtyModule?.moduleTitle}}
          .card
            DashboardSpecialty(:clinicId="clinicId", :specialty="specialty")
</template>

<script>
import { defineComponent } from "vue";
import { fetchClinicDashboard } from "@/api/clinicApi";
import DashboardActionItems from "@/components/DashboardActionItems.vue";
import DashboardNotifications from "@/components/DashboardNotifications.vue";
import DashboardFitRegistry from "@/components/DashboardFitRegistry.vue";
import DashboardPrimaryCare from "@/components/DashboardPrimaryCare.vue";
import DashboardSpecialty from "@/components/DashboardSpecialty.vue";
import { fetchPrimaryCareModule } from "@/api/primaryCareModule";

export default defineComponent({
  components: {
    DashboardActionItems,
    DashboardNotifications,
    DashboardFitRegistry,
    DashboardPrimaryCare,
    DashboardSpecialty,
  },

  data() {
    return {
      clinicId: parseInt(this?.$route?.params?.idClinic, 10) ?? null,
      dashboard: null,
      clinicIcon: "",
    };
  },
  computed: {
    colonoscopySpecialty() {
      if (!this?.dashboard?.specialties?.length) return false;
      const colonoscopySpecialty = this.dashboard.specialties.find((item) => {
        return item?.specialtyModule?.isColonoscopy === true;
      });
      return colonoscopySpecialty;
    },
    specialtiesWithoutColonoscopy() {
      if (!this?.dashboard?.specialties?.length) return false;
      var specialtiesWithoutColonoscopy = this.dashboard.specialties.filter(
        (item) => {
          return item?.specialtyModule?.isColonoscopy !== true;
        }
      );
      return specialtiesWithoutColonoscopy;
    },
  },

  methods: {
    async fetchPrimaryCareModule() {
      try {
        const res = await fetchPrimaryCareModule();
        this.clinicIcon = res.data.primaryCareModule?.icon;
      } catch (err) {
        console.error(err);
      }
    },
    async fetchClinicDashboard() {
      try {
        let res = await fetchClinicDashboard(this.clinicId);
        this.dashboard = res.data;
        if(this.dashboard.primaryCare !== null){
          this.fetchPrimaryCareModule();
        }
      } catch (error) {
        console.error(error);
      }
    },
  },

  created() {
    if (this.clinicId === null) return;
    this.fetchClinicDashboard();
  },
});
</script>

<style scoped lang="scss">
.title-blue-underlined svg,
.title-blue-underlined span.title-icon {
  max-height: 31px;
}
.dashboard {
  position: relative;
  padding-bottom: 44px;
}
.card {
  margin-bottom: 0;
  position: relative;
  padding: 33px $secondaryCardsSidePadding 40px;
  border: solid 1px $primaryBgDarkGrey;
  box-shadow: 1px 5px 20px 0 rgba(0, 0, 0, 0.1);
}
.equal-heights-card-row {
  lost-flex-container: row;
  margin-bottom: 85px;
  .half {
    lost-column: 1/2 2 3%;
    align-self: stretch;
    // TODO: refactor?====
    .card {
      height: calc(100% - 92px);
    }
    //====================
  }
  .card--action-items {
    lost-column: 2/3 2 3%;
    border: solid 10px $primaryBgDarkGrey;
    padding: 30px 26px 0;
  }
  .card--dashboard-notifications {
    lost-column: 1/3 2 3%;
    padding-top: 39px;
    padding-bottom: 30px;
  }
}
.specialties {
  lost-flex-container: row;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  .specialty {
    margin-top: 85px;
    lost-column: 1/2 2 3%;
    align-self: stretch;
    // TODO: refactor?====
    .card {
      height: calc(100% - 92px);
    }
  }
}
.title-icon {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
  margin-right: 13px;
  top: 3px;
  background-repeat: no-repeat;
  background-color: transparent;
  background-position: center;
  background-size: contain;
}
</style>
