<template lang="pug">
slot(:authorized="authorized")
</template>

<script>
import { userRoles } from "@/store/account";
import { mapGetters } from "vuex";
export default {
  props: {
    allow: {
      type: Array,
      required: true,
    },
    allowBy: {
      type: String,
      default: "name",
    },
  },

  computed: {
    ...mapGetters(["getRole"]),
    authorized() {
      //allow by default
      if (!this.allow.length) {
        return true;
      }

      let allowedRoles = [...this.allow];
      const currentUserRole = this.getRole;

      //always add admin role
      allowedRoles.push(userRoles.admin[0][this.allowBy].toUpperCase());

      return allowedRoles.some(
        (allowedRole) => allowedRole === currentUserRole[this.allowBy]
      );
    },
  },
};
</script>
