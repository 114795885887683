<template lang="pug">
FullScreenOverlaySlot(
  :isOpen="isOpen",
  headerIcon="icc-logo",
  title="SPECIALTY CARE SETTINGS",
  @closeOverlay="closeOverlay"
)
  template(#breadcrumbs)
    h1.breadcrumbs-title IL CARE CONTINUUM
  template(#content)
    .card.general-settings
      h2.title.title--md-thin GENERAL SETTINGS
      .module-base-info.columns.is-variable
        .column.is-one-third
          .form-group
            label.label--margin-sm(for="module-title") Module Title
            input#module-title.text-lg(
              type="text",
              v-model="general.moduleTitle"
            )
        .column.is-one-third
          .form-group
            label.label--margin-sm(for="marketing-title") Marketing Title
            input#marketing-title.text-lg(
              type="text",
              v-model="general.marketingTitle"
            )
        .info-pair.column.is-one-third
          .label--margin-sm Module Icon
            base-icon(name="paperclip", style="margin-left:8px;")
          .txt.flex
            UploadedImagePreview(
              :src="general.icon",
              labelFor="general-icon"
              style="margin-right: 15px;"
            )
            BaseUpload#general-icon(v-model="general.icon") Upload

      .form-group(style="margin-top: -18px; display: flex")
        BaseToggle(
          label="Module Status",
          v-model="general.isModuleEnabled"
          @change="isModuleEnabledChanged"
          style="margin-right: 88px;"
        )
        BaseToggle(label="Ongoing Care", v-model="general.isManageCare")

    .card
      h2.title.title--md-thin SPECIALTY CONFIGURATION
      .module-base-info.columns.is-variable
        .column.is-one-third
          .form-group
            label.label--margin-sm Procedure Report Title
            input.input.text-lg(
              type="text",
              v-model="specialtyConfiguration.procedureReportTitle"
            )
      p.procedure-list-instructions Procedure Completed Additional Dropdown Options (All Categorized as “No”)
      .list-options
        draggable(
          :list="specialtyConfiguration.additionalDropdownOptions",
          handle=".drag-bars--sm",
        )
          transition-group(type="transition", name="flip-list")
            .draggable-input(
              v-for="item in specialtyConfiguration.additionalDropdownOptions",
              :key="item.id"
            )
              .draggable-input__inner
                span.drag-bars--sm
                  base-icon(name="drag-small")
                input(type="text", @change="changeAdditionalDropdownOptions(item.id, $event.target.value)", :value="item.name")
        a.add-new(
          href="#",
          @click.prevent="addAdditionalDropdownOptions(specialtyConfiguration.additionalDropdownOptions)"
        )
          base-icon(name="plus") 
          span Add dropdown option

    .card
      .title-md-thin-with-action
        h2.title.title--md-thin(
          style="margin-bottom: 27px;"
        ) APPOINTMENT RECORD SPECIALTY FIELDS
        AddFormFieldDropdown(
          @addFormField="addFormField"
          :formModel="appointmentRecordSpecialtyFields.fields"
        )
      
      .table.table--no-side-padding
        .th
          .td Field Title
          .td Field Type
          .td Active
          .td
        draggable.list-group(
          :list="appointmentRecordSpecialtyFields.fields",
          handle=".drag-bars"
        )
          .tr.list-group-item(
            v-for="field in appointmentRecordSpecialtyFields.fields",
            :key="field.id"
          )
            .tr__row
              .td
                input(type="text", v-model="field.name")
              .td.field-type(:class="['field-type--' + field.type.toLowerCase()]") {{toReadableType(field.type)}}
              .td 
                BaseToggle(v-model="field.active")
              .td
                .drag-bars
                  base-icon(name="drag-big")
            .tr__row.row-main-options
              BaseCheckbox(
                v-if="field.type !== 'DESCRIPTION' && field.type !== 'SECTION_TITLE'"
                label="Required",
                v-model="field.required")
              BaseCheckbox(
                label="Conditional",
                v-model="field.hasDependencies"
              )
              BaseCheckbox(
                v-if="field.inReport !== null",
                label="In Report",
                v-model="field.inReport"
              )
              BaseCheckbox(
                label="In New Row",
                v-model="field.inNewRow"
              )

            .tr__row.list-options(v-if="field.type === 'DROPDOWN'")
              draggable(
                :list="field.options",
                handle=".drag-bars--sm"
              )
                transition-group(type="transition", name="flip-list")
                  .draggable-input(
                    v-for="option in field.options",
                    :key="option.id"
                  )
                    .draggable-input__inner
                      span.drag-bars--sm
                        base-icon(name="drag-small")
                      input(type="text", v-model="option.name")
              a.add-new(href="#", @click.prevent="addDropdownOption(field.options)")
                base-icon(name="plus") 
                span Add dropdown option
            ModuleFieldConditionals(
              v-if="field.hasDependencies",
              :currentField="field",
              :fields="appointmentRecordSpecialtyFields.fields",
              @mutateConditionalRules="mutateConditionalRules"
            )
    .card
      h2.title.title--md-thin PATIENT INFO CONFIGURATION
      .patient-record-info.columns.is-variable
        .column.is-one-third
          .form-group
            label.label--margin-sm Referral Info Title
            input(
              type="text",
              v-model="patientInfoConfiguration.referralInfoTitle"
            )
        .column.is-two-thirds
          .form-group
            BaseToggle(
              label="Require Clinic Report"
              v-model="patientInfoConfiguration.requireClinicReport"
            )
    //- ----------------------------------
    .card
      .title-md-thin-with-action
        h2.title.title--md-thin(style="margin-bottom: 27px;") PATIENT INFO QUESTIONS
        AddFormFieldDropdown(
          @addFormField="addFormField"
          :formModel="patientInfoQuestions.fields"
        )
      .table.table--no-side-padding
        .th
          .td Field Title
          .td Field Type
          .td Active
          .td
        draggable.list-group(
          :list="patientInfoQuestions.fields",
          handle=".drag-bars"
        )
          .tr.list-group-item(
            v-for="field in patientInfoQuestions.fields",
            :key="field.id"
          )
            .tr__row
              .td
                input(type="text", v-model="field.name")
              .td.field-type(
                :class="['field-type--' + field.type.toLowerCase()]"
              ) {{toReadableType(field.type)}}
              .td 
                BaseToggle(v-model="field.active")
              .td
                .drag-bars
                  base-icon(name="drag-big")
            .tr__row.row-main-options
              BaseCheckbox(
                v-if="field.type !== 'DESCRIPTION' && field.type !== 'SECTION_TITLE'"
                label="Required",
                v-model="field.required"
              )
              BaseCheckbox(
                label="Conditional",
                v-model="field.hasDependencies"
              )
            .tr__row.list-options(v-if="field.type === 'DROPDOWN'")
              draggable(
                :list="field.options",
                handle=".drag-bars--sm"
              )
                transition-group(type="transition", name="flip-list")
                  .draggable-input(
                    v-for="option in field.options",
                    :key="option.id"
                  )
                    .draggable-input__inner
                      span.drag-bars--sm
                        base-icon(name="drag-small")
                      input(type="text", v-model="option.name")
              a.add-new(href="#", @click.prevent="addDropdownOption(field.options)")
                base-icon(name="plus") 
                span Add dropdown option
            ModuleFieldConditionals(
              v-if="field.hasDependencies",
              :currentField="field",
              :fields="patientInfoQuestions.fields",
              @mutateConditionalRules="mutateConditionalRules"
            )
    .specialty-spacer
  template(#footer)
    .footer-inner(v-if="moduleId")
      BaseBtn.btn.lg.red-outline(
        href="#",
        @click.prevent="closeOverlay"
      ) CANCEL CHANGES
      BaseBtn.btn.lg.green(
        href="#",
        @click.prevent="updateSpecialtyModule"
      ) SAVE CHANGES AND EXIT
    .footer-inner(v-else="")
      BaseBtn.btn.lg.red-outline(
        href="#",
        @click.prevent="closeOverlay"
      ) CANCEL
      BaseBtn.btn.lg.green(
        href="#",
        @click.prevent="createSpecialtyModule"
      ) SAVE AND EXIT
teleport(to="body")
  DisableModulePopup(
    :isOpen="isDisableModulePopupOpen",
    @cancelModuleDisable="cancelModuleDisable"
    @confirmModuleDisable="confirmModuleDisable"
  )
</template>

<script>
import { defineComponent } from "vue";
import FullScreenOverlaySlot from "@/components/overlays/FullScreenOverlaySlot.vue";
import AddFormFieldDropdown from "@/components/system-settings/AddFormFieldDropdown.vue";
import ModuleFieldConditionals from "@/components/system-settings/ModuleFieldConditionals.vue";
import { VueDraggableNext } from "vue-draggable-next";
import { v1 as uuidv1 } from "uuid";
import DisableModulePopup from "@/components/system-settings/popups/DisableModulePopup.vue";
import formBuilderMixin from "@/components/system-settings/mixins/formBuilderMixin.ts";
import UploadedImagePreview from "@/components/UploadedImagePreview.vue";
import upperCase from "lodash/upperCase";
import { fetchSpecialtyModule } from "@/api/specialtyModulesApi";

export default defineComponent({
  components: {
    FullScreenOverlaySlot,
    AddFormFieldDropdown,
    ModuleFieldConditionals,
    draggable: VueDraggableNext,
    DisableModulePopup,
    UploadedImagePreview,
  },
  mixins: [formBuilderMixin],
  emits: ["closeModuleSettingsOverlay"],

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    moduleId: {
      required: false,
    },
  },

  data() {
    return {
      isDisableModulePopupOpen: false,

      updatedAt: null,
      general: {
        moduleTitle: "",
        marketingTitle: "",
        isModuleEnabled: true,
        isManageCare: false,
        icon: "",
      },
      specialtyConfiguration: {
        procedureReportTitle: "",
        additionalDropdownOptions: [],
      },
      appointmentRecordSpecialtyFields: {
        fields: [],
      },
      patientInfoConfiguration: {
        referralInfoTitle: "",
        requireClinicReport: true,
      },
      patientInfoQuestions: {
        fields: [],
      },
    };
  },

  methods: {
    isModuleEnabledChanged() {
      this.$nextTick(() => {
        if (this.general.isModuleEnabled === false) {
          this.isDisableModulePopupOpen = true;
        }
      });
    },
    cancelModuleDisable() {
      this.general.isModuleEnabled = true;
      this.isDisableModulePopupOpen = false;
    },
    confirmModuleDisable() {
      this.isDisableModulePopupOpen = false;
    },
    closeOverlay() {
      this.$emit("closeModuleSettingsOverlay");
    },
    addAdditionalDropdownOptions(model) {
      model.push({
        id: uuidv1(),
        name: "",
        value: "",
      });
    },
    changeAdditionalDropdownOptions(itemId, name) {
      let entry = {
        id: itemId,
        name: name,
        value: name ? upperCase(name).replace(/ /g, "_") : "",
      };
      const foundIndex =
        this.specialtyConfiguration.additionalDropdownOptions.findIndex(
          (item) => entry.id == item.id
        );
      if (foundIndex === -1) return;
      this.specialtyConfiguration.additionalDropdownOptions[foundIndex].name =
        entry.name;
      this.specialtyConfiguration.additionalDropdownOptions[foundIndex].value =
        entry.value;
    },

    async fetchSpecialtyModule() {
      try {
        const res = await fetchSpecialtyModule(this.moduleId);
        this.assignFetchData(res?.data?.specialtyModule);
      } catch (err) {
        console.error(err);
      }
    },
    assignFetchData(specialtyModule) {
      this.general = {
        moduleTitle: specialtyModule.moduleTitle,
        marketingTitle: specialtyModule.marketingTitle,
        isModuleEnabled: specialtyModule.isModuleEnabled,
        isManageCare: specialtyModule.isManageCare,
        icon: specialtyModule.icon,
      };
      this.updatedAt = specialtyModule?.updatedAt;
      this.specialtyConfiguration =
        specialtyModule?.schemaRules?.specialtyConfiguration;
      this.appointmentRecordSpecialtyFields =
        specialtyModule?.schemaRules?.appointmentRecordSpecialtyFields;
      this.patientInfoConfiguration =
        specialtyModule?.schemaRules?.patientInfoConfiguration;
      this.patientInfoQuestions =
        specialtyModule?.schemaRules?.patientInfoQuestions;
    },
  },
  created() {
    if (this.moduleId === null) return;
    this.fetchSpecialtyModule();
  },
});
</script>

<style scoped lang="scss">
.completed-popup {
  position: relative;
  .close {
    display: block;
    position: absolute;
    top: -11px;
    right: 1px;
    z-index: 2;
    .svg-icon {
      height: 20px;
      width: 20px;
      top: 0;
    }
  }
}
.card {
  padding: 35px $secondaryCardsSidePadding 20px $secondaryCardsSidePadding;
}
.columns.is-variable {
  --columnGap: 1.575%;
}
.columns + .hr {
  background-color: $primaryBgDarkGrey;
  margin-top: -21px;
  margin-bottom: 24px;
}
.card > .columns:last-child.info-pair,
.card > .columns:last-child .info-pair {
  margin-bottom: 0;
}
.svg-icon--eye-red,
.svg-icon--eye-green,
.svg-icon--yes,
.svg-icon--no {
  width: 50px;
  height: 50px;
  top: 0;
}

// Page specific styles----------------------------------------
.field-type:not(.field-type--radio) {
  text-transform: lowercase;
  &:first-letter {
    text-transform: capitalize;
  }
}
.breadcrumbs-title {
  margin: 0;
  color: $primaryLightBlue;
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.12px;
}
.procedure-list-instructions {
  margin-top: -20px;
  margin-bottom: 16px;
  font-size: 14px;
  line-height: 1.29;
}
.table {
  .td {
    &:nth-child(1) {
      width: math.percentage(math.div(800, $tabContentCardWidth));
    }
    &:nth-child(2) {
      width: math.percentage(math.div(204, $tabContentCardWidth));
    }
    &:nth-child(3) {
      width: math.percentage(math.div(134, $tabContentCardWidth));
    }
    &:last-child {
      width: math.percentage(math.div(36, $tabContentCardWidth));
      display: flex;
      justify-content: flex-end;
    }
  }
  .svg-icon--drag-big {
    font-size: 34px;
    color: $primaryBgDarkGrey;
  }
  .row-main-options {
    padding: 35px 0;
    .base-checkbox:not(:last-child) {
      margin-right: 84px;
    }
  }
}
.footer-inner {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.specialty-spacer {
  width: 100px;
  height: 150px;
}
// Drag and drop ---------------------------------
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.list-group-item .drag-bars {
  cursor: move;
  padding-right: 1px;
}
.drag-bars--sm {
  display: block;
  width: 26px;
  cursor: move;
  .svg-icon {
    width: 9px;
    height: 12px;
    color: #c5c7cd;
  }
}
.list-options,
.tr__row.list-options {
  display: block;
  padding: 0 0 34px;
  width: calc(#{math.percentage(math.div(800, $tabContentCardWidth))} - 42px);
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  li {
    position: relative;
    display: flex;
    align-items: flex-end;
    height: 37px;
    padding-bottom: 6px;
    &::after {
      position: absolute;
      content: "";
      display: block;
      height: 1px;
      background-color: $primaryBgDarkGrey;
      bottom: 0;
      left: 26px;
      right: 0;
    }
  }
  .add-new {
    display: flex;
    align-items: center;
    margin-top: 13px;
    font-size: 13px;
    line-height: 1.23;
    color: #000;
    text-transform: uppercase;
    text-decoration: none;
    .svg-icon {
      font-size: 17px;
      top: 0;
      margin-right: 16px;
    }
  }
}
</style>
