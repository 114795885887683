// @ts-nocheck
import api from "./api";

const ChsUrl = "/clinic-health-system";
export const fetchCHSs = (data = {}) => {
  return api.get(ChsUrl, data);
};
export const fetchCHS = (chsId, data = {}) => {
  return api.get(ChsUrl + "/" + chsId, data);
};
export const createCHS = (data = {}) => {
  return api.post(ChsUrl, data);
};
export const updateCHS = (chsId, data = {}) => {
  return api.put(ChsUrl + "/" + chsId, data);
};
export const fetchCHSPatients = (data = {}) => {
  return api.get(`${ChsUrl}/patient`, data);
};
export const fetchCHSClinics = (chsId, data = {}) => {
  return api.get(ChsUrl + "/" + chsId + "/clinic", data);
};
export const fetchCHSDoctors = (chsId, data = {}) => {
  return api.get(ChsUrl + "/" + chsId + "/doctor", data);
};
export const fetchCHSFiles = (chsId, data = {}) => {
  return api.get(ChsUrl + "/" + chsId + "/files", data);
};
export const addCHSFiles = (chsId, data = {}) => {
  return api.post(ChsUrl + "/" + chsId + "/files", data);
};
export const fetchCHSHospitals = (chsId, data = {}) => {
  return api.get(ChsUrl + "/" + chsId + "/hospital", data);
};
export const fetchCHSHFITRegistryFields = (data = {}) => {
  return api.get(ChsUrl + "/fit-registry/fields", data);
};
export const CHSBulkUploadPatients = (chsId, data = {}) => {
  return api.post(ChsUrl + "/" + chsId + "/bulk-upload-patients", data);
};
export const getSpecialtyModulesOfCHS = (chsId, data = {}) => {
  return api.get(ChsUrl + "/" + chsId + "/specialty-module", data);
};
