<template lang="pug">
section.hospitals
  TableTitle(title="Hospitals")
    template(#actions)
      BaseBtn.x-lg.blue(
        href="#",
        @click.prevent="openHospitalsOverlay()"
      ) ADD NEW
  .table(v-if="Hospitals?.length")
    .th
      .td(@click.prevent="changeListOrder('HospitalName', fetchHospitals)" style="cursor: pointer;")  HOSPITAL NAME
        i(style="font-weight: bolder;") {{ HospitalNameColumnSortingIcon }}
      .td(@click.prevent="changeListOrder('City', fetchHospitals)" style="cursor: pointer;")  CITY
        i(style="font-weight: bolder;") {{ CityColumnSortingIcon }}
      .td
      .td
    .tr(v-for="hosp in Hospitals", :key="hosp.id")
      .td.bold {{ hosp.name }}
      .td.bold {{ hosp.address.place }}
      .td
        BaseBtn.sm.blue-outline(href="#", @click.prevent="goToHospitalSpecialtyDashboard(hosp)") SELECT
      .td
        BaseBtn.sm.blue(
           href="#",
          @click.prevent="openHospitalsOverlay(hosp.id)"
        ) MANAGE
  Pagination(
    v-if="pagination",
    :pagination="pagination",
    :reloadFn="fetchHospitals"
    @changePagination="changePagination"
  )

  HospitalsOverlay(
    :key="`${componentKey}-1`"
    :hospId="hospId"
    :isOpen="isHospitalsOverlayOpen",
    @closeHospitalsOverlay="closeHospitalsOverlay",
    @reloadHospitals="fetchHospitals"
  )
</template>

<script>
import { defineComponent } from "vue";
import TableTitle from "@/components/TableTitle.vue";
import Pagination from "@/components/Pagination.vue";
import HospitalsOverlay from "@/components/organizations/overlays/HospitalsOverlay.vue";

import listPagesMixin from "@/mixins/listPagesMixin";
import { fetchHospitals } from "@/api/hospitalApi";
import { apiStatus, apiStatusComputedFactory } from "@/api";
import { userRoles } from "@/store/account";
const { IDLE, PENDING, SUCCESS, ERROR } = apiStatus;
import sortHospitals from "@/mixins/sortHospitals";

export default defineComponent({
  components: {
    TableTitle,
    Pagination,
    HospitalsOverlay,
  },
  mixins: [listPagesMixin, sortHospitals],
  data() {
    return {
      fetchHospitalsStatus: IDLE,
      Hospitals: null,
      hospId: null,
      isHospitalsOverlayOpen: false,
      isNavHospitalManager: false,
    };
  },
  computed: {
    ...apiStatusComputedFactory("fetchHospitalsStatus"),
  },
  methods: {
    async fetchHospitals() {
      this.fetchHospitalsStatus = PENDING;

      const order = this.listOrder
        ? {
            by: this.listOrder.by,
            direction: this.listOrder.direction,
          }
        : null;

      try {
        const res = await fetchHospitals({
          params: {
            ...this.reqPagination,
            order,
          },
        });
        this.Hospitals = res.data.hospitals;
        this.pagination = res.data.pagination;
        this.fetchHospitalsStatus = SUCCESS;
      } catch (err) {
        this.fetchHospitalsStatus = ERROR;
        console.error(err);
      }
    },

    async goToHospitalSpecialtyDashboard(hospital) {
      this.$store.dispatch("impersonate", {
        role: userRoles.hospital.find(
          (role) => role.name === "Hospital Manager"
        ),
        hospital,
      });
      this.$store.dispatch("fetchHospitalSpecialtiesAndNavigate", hospital.id);
    },

    openHospitalsOverlay(hospId) {
      this.componentKey = this.componentKey + 1;
      this.forceRerender();
      this.hospId = hospId ?? null;
      this.isHospitalsOverlayOpen = true;
    },
    closeHospitalsOverlay() {
      this.isHospitalsOverlayOpen = false;
    },
  },

  created() {
    this.fetchHospitals();
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/components/step-subtitle.scss";
.hospitals {
  .td {
    &:nth-child(1) {
      width: math.percentage(math.div(397, $tabContentCardWidth));
    }
    &:nth-child(2) {
      width: math.percentage(math.div(347, $tabContentCardWidth));
    }
    &:nth-child(3) {
      width: math.percentage(math.div(212, $tabContentCardWidth));
    }
    &:nth-child(4) {
      width: math.percentage(math.div(222, $tabContentCardWidth));
    }
  }
}
.table .tr .td:not(:last-child):not(.bold) {
  padding-right: 10px;
  padding-left: 10px;
}
.table .td .btn {
  width: 100%;
}
.table .tr > .td {
  display: flex;
  align-items: center;
}
</style>
