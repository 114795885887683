// @ts-nocheck
import api from "./api";
const URLS = {
  referralModule: `/referral-module`,
};
export const fetchReferralModules = (options = {}) => {
  return api.get(URLS.referralModule, options);
};
export const fetchReferralModule = (id, data = {}) => {
  return api.get(URLS.referralModule + "/" + id, data);
};
export const createReferralModule = (data = {}) => {
  return api.post(URLS.referralModule, data);
};
export const updateReferralModule = (id, data = {}) => {
  return api.put(URLS.referralModule + "/" + id, data);
};
