// @ts-nocheck
export const reformatPagination = (pagination: any): any => {
  if (
    "currentPage" in pagination &&
    "lastPage" in pagination &&
    "size" in pagination
  ) {
    return pagination;
  }
  const { quantity, size, page } = pagination;
  const lastPage = Math.ceil(quantity / size);
  const currentPage = page > lastPage ? lastPage : page;
  return {
    size,
    lastPage,
    currentPage,
  };
};
