<template lang="pug">
.fit-kit-completed
  header.overlay-show-success.fit-kit-completed__head
    p FIT successfully completed!

  section.fit-kit-completed__positive(
    v-if="showPositiveArea()"
  )
    h2.result Result is FIT Positive
    p You indicated that the test results are positive.
    p Would you like to immediately take action?

    p.schedule-colonoscopy(
      v-if="showCreateReferral()"
    ) SCHEDULE REFERRAL:
    .actions.has-icc
      BaseBtn.lg.green(
        :to="{name: 'primary-care-community-organization.scheduling.select-schedule', params: { idFitKit, idPatient }}"
      ) CREATE REFERRAL
</template>

<script>
import { defineComponent } from "vue";
import { fetchCommunityCareOrganizationById } from "@/api/communityOrganization";

export default defineComponent({
  components: {},

  props: {
    idCommunity: {
      type: Number,
      required: true,
    },
    completedFit: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      idFitKit: null,
      idPatient: null,
      isPrimaryCareActive: false,
      isFitReferralOverlayOpen: false,
      isScheduleColonoscopyOutsideIccOverlayOpen: false,
    };
  },

  computed: {},

  watch: {},

  methods: {
    showPositiveArea() {
      return (
        this.isPrimaryCareActive &&
        this.completedFit &&
        this.completedFit.result === "positive" &&
        this.showReferral()
      );
    },
    showCreateReferral() {
      const showReferral = this.showReferral();
      return this.isPrimaryCareActive && showReferral;
    },
    showReferral() {
      const hasReferral =
        this.completedFit &&
        this.completedFit.communityPatientReferral &&
        this.completedFit.communityPatientReferral.id;
      return !hasReferral;
    },
    closeFitReferralOverlay({ afterAction }) {
      this.isFitReferralOverlayOpen = false;
      if (afterAction) {
        this.$router.go(0);
      }
    },
    closeScheduleColonoscopyOutsideIccOverlay({ afterAction }) {
      this.isFitReferralOverlayOpen = false;
      if (afterAction) {
        this.$router.go(0);
      }
    },
    async fetchCommunity(idCommunity) {
      try {
        const communityRes = await fetchCommunityCareOrganizationById(
          idCommunity
        );
        this.isPrimaryCareActive = communityRes.data.primaryCare;
      } catch (err) {
        console.error(err);
      }
    },
  },

  created() {
    this.idFitKit = this.completedFit && this.completedFit.id;
    this.idPatient = this.completedFit && this.completedFit.idPatient;
    if (this.idCommunity === null) return;
    this.fetchCommunity(this.idCommunity);
  },
});
</script>

<style scoped lang="scss">
.side-overlay__footer {
  display: none;
}
.fit-kit-completed {
  &__head {
    margin-bottom: 45px;
  }
  &__positive {
    padding: 10px 30px 40px;
    border: solid 1px $primaryBgDarkGrey;
    .result {
      margin-bottom: 33px;
      text-transform: uppercase;
      font-size: 28px;
      font-weight: 700;
      line-height: 1.14;
      color: $secondaryRed;
    }
  }
  p.schedule-colonoscopy {
    margin-top: 35px;
    text-transform: uppercase;
    font-weight: 700;
  }
  .actions {
    margin-top: 30px;
    display: flex;
    .btn {
      min-width: 206px;
    }
    & > .btn:first-child {
      margin-right: 17px;
    }
  }
}
</style>
