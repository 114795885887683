<template lang="pug">
.content-wrapper
  .card.router-view-card
    section.select-patient
      h2.big_title PATIENT SEARCH RESULTS
      TableTitle(:title="getTitle")
        template(#actions)
          BaseBtn.lg.blue_outline.setMinWidth(:to="{name: 'hospitals.patient-search'}") SEARCH AGAIN
      .table
        .th
          .td Patient Name
          .td DOB
          .td Clinic
          .td
        .tr(v-if="patients" v-for="patient in patients" :key="patient.id")
          .td.bold {{ getUserFormatted(patient) }}
          .td {{ getDobFormatted(patient.dob) }}
          .td.bold {{ patient.clinic?.name }}
          .td
            BaseBtn.sm.blue-outline(
              @click="!clinicStaffInTrainingNotAllowed ? openHospitalPatientDetalisOverlay(patient) : null"
            ) PATIENT DETAILS
            
      Pagination(
        v-if="pagination",
        :pagination="pagination",
        :reloadFn="fetchPatients"
        @changePagination="changePagination"
      )
      .no-results(v-if="patients != null && !patients.length") 
        base-icon(name="exclamation") 
        span No matching records
      .actions-below-table-container
        
  router-view  

HospitalPatientDetalisOverlay(
  :key="`${componentKey}-1`"
  :showPatient="showPatient",
  :isOpen="isHospitalPatientDetalisOverlayOpen",
  @closeHospitalPatientDetalisOverlay="closeHospitalPatientDetalisOverlay",
  :specialtyModule="specialtyModule"
)
</template>

<script>
import moment from "moment";
import { defineComponent } from "vue";
import TableTitle from "@/components/TableTitle.vue";
import Pagination from "@/components/Pagination.vue";
import listPagesMixin from "@/mixins/listPagesMixin";
import { getDobFormatted, getUserFormatted } from "@/helpers";
import { mapGetters } from "vuex";
import { fetchPatients } from "@/api/hospitalApi";
import { denyClinicStaffTraining } from "@/mixins/denyClinicStaffTraining";
import HospitalPatientDetalisOverlay from "@/components/hospitals/patient-search/overlays/PatientDetalisOverlay.vue";

export default defineComponent({
  components: {
    TableTitle,
    Pagination,
    HospitalPatientDetalisOverlay,
  },
  mixins: [listPagesMixin, denyClinicStaffTraining],

  data() {
    return {
      isHospitalPatientDetalisOverlayOpen: false,
      patients: null,
      getTitle: "",
      showPatient: null,
      searchParams: null,
    };
  },
  props: {
    specialtyModule: {
      required: false,
    },
  },

  methods: {
    getDobFormatted,
    getUserFormatted,

    openHospitalPatientDetalisOverlay(patient) {
      this.componentKey = this.componentKey + 1;
      this.showPatient = patient;
      this.isHospitalPatientDetalisOverlayOpen = true;
    },
    closeHospitalPatientDetalisOverlay() {
      this.isHospitalPatientDetalisOverlayOpen = false;
    },
    async fetchPatients() {
      this.getTitle = "(0) Patients Found";
      try {
        const query_params = {
          searchBy: this.$route.query.searchBy,
          searchInput: this.getSearchInput(this.$route.query),
        };
        const resPatient = await fetchPatients(this.$route.params.idHospital, {
          params: query_params,
          ...this.reqPagination,
        });
        this.patients = resPatient.data.patients;
        this.pagination = resPatient.data.pagination;

        // this.patients.forEach((element) => {
        //   element.isDisabled = true;
        //   if (element.idClinic == this.$route.params.idClinic) {
        //     element.isDisabled = false;
        //   }
        // });

        if (this.patients != null && this.patients.length) {
          this.getTitle = "(" + this.patients.length + ") Patients Found";
        }
      } catch (err) {
        console.error(err);
      }
    },
    getSearchInput(searchParams) {
      let searchInput = {};
      const searchBy = searchParams.searchBy;
      switch (searchBy) {
        case "name":
          if (searchParams.firstName) {
            searchInput.first = searchParams.firstName;
          }
          if (searchParams.lastName) {
            searchInput.last = searchParams.lastName;
          }
          break;
        case "mrn":
          searchInput = searchParams.mrn;
          break;
        case "dob":
          searchInput = moment.utc(searchParams.dob).format("MMDDYYYY");
          break;
        default:
          break;
      }
      return searchInput;
    },
  },
  created() {
    if (this.$route.query === null) return;
    this.fetchPatients();
  },
});
</script>

<style scoped lang="scss">
@import "@/scss/components/actions-below-table-container.scss";
.btn.setMinWidth {
  min-width: 228px;
}
.table-title {
  margin-bottom: 41px;
}

.select-patient {
  .td {
    &:nth-child(1) {
      width: math.percentage(math.div(260, $tabContentCardWidth));
    }
    &:nth-child(2) {
      width: math.percentage(math.div(155, $tabContentCardWidth));
    }
    &:nth-child(3) {
      width: math.percentage(math.div(560, $tabContentCardWidth));
    }
    &:nth-child(4) {
      width: math.percentage(math.div(205, $tabContentCardWidth));
      text-align: right;
    }
  }
}
</style>
