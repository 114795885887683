<template lang="pug">
SideOverlaySlot(
  :isOpen="isOpen",
  title="ADD NEW SCHEDULE",
  @closeOverlay="closeOverlay"
)
  template(#contentTop)
    form#add-new-schedule(
      v-if="!showScheduleAdded",
      @submit.prevent="addNewSchedule"
    ) 
      .form-group(:class="{ 'form-group__invalid': v$.scheduleDoctor.$errors.length }")
        label(for="user-type") Doctor's Name (Required)
        select#user-type(v-model="scheduleDoctor" @blur="v$.scheduleDoctor.$touch()")
          option(value="") Select
          option(
            v-for="doctor in activeDoctors", :key="doctor.id",
            :value="{id: doctor.id, first: doctor.first, last: doctor.last}"
          ) {{ getDoctorFormatted(doctor) }} 
        .form-group__errors(v-if="v$.scheduleDoctor.$dirty && v$.scheduleDoctor.$errors.length")
          .form-group__error(v-if="v$.scheduleDoctor.required.$invalid") Doctor is required!
      .columns
        .column.is-half
          .form-group(:class="{ 'form-group__invalid': v$.scheduleDate.$errors.length }")
            label(for="date") Date (Required)
            DatePicker(
              v-model="date" 
              :masks="masks"
            )
              template(v-slot="{ inputValue, inputEvents }")
                input(
                  :value="inputValue"  
                  v-on="inputEvents"
                  placeholder="MM/DD/YYYY"
                  @blur="v$.scheduleDate.$touch()"
                )
            .form-group__errors(v-if="v$.scheduleDate.$dirty && v$.scheduleDate.$errors.length")
              .form-group__error Date is required!
        .column.is-half
          TimeDropdown(
            isRequired=true 
            @timeResults="timeResults"
          )
      .columns
        .column.is-half
          .form-group(:class="{ 'form-group__invalid': v$.scheduleRecurring.$errors.length }")
            label(for="recurring") Recurring * (Required)
            select#recurring(v-model="scheduleRecurring" @blur="v$.scheduleRecurring.$touch()")
              option(value="") Select
              option(value="once") Once
              option(value="weekly") Weekly
              option(value="monthly") Monthly
            .form-group__errors(v-if="v$.scheduleRecurring.$dirty && v$.scheduleRecurring.$errors.length")
              .form-group__error(v-if="v$.scheduleRecurring.required.$invalid") Field is required!
        .column.is-half
          .form-group(:class="{ 'form-group__invalid': v$.scheduleInsurance.$errors.length }")
            label(for="insurance") Insurance (Required)
            select#insurance(v-model="scheduleInsurance" @blur="v$.scheduleInsurance.$touch()")
              option(value="") Select
              option(value="true") Insured
              option(value="false") Uninsured
            .form-group__errors(v-if="v$.scheduleInsurance.$dirty && v$.scheduleInsurance.$errors.length")
              .form-group__error(v-if="v$.scheduleInsurance.required.$invalid") Field is required!
      .columns
        .column.is-full.recurring-reminder
          p * Selecting the “Weekly” or “Monthly” recurring option will create 12 consecutive (weekly or monthly) appointment slots to the system.

    .overlay-show-success(v-else="")
      section.patient-section
        p <strong>Doctor’s Name: {{ getDoctorFormatted(scheduleDoctor) }}</strong>
        p <strong>Date: {{ scheduleDate }}</strong>
        p <strong>Time: {{ scheduleTime }}</strong>
        p <strong>Recurring: {{ scheduleRecurring.toUpperCase() }}</strong>
        p <strong>Insurance: {{ scheduleInsurance === "true" ? "INSURED" : "UNINSURED" }}</strong>
      p Schedule successfully added
  template(#footer)
    .flex.flex--space-between(v-if="!showScheduleAdded")
      BaseBtn.x-lg.red-outline(
        href="#",
        @click.prevent="closeOverlay"
      ) CANCEL
      BaseBtn.x-lg.green(
        type="submit",
        form="add-new-schedule"
        @click.prevent="addNewSchedule"
        :disabled="v$.$invalid || isSubmitting"
        :class="{spinner: isSubmitting }"
      ) CONTINUE
    .flex.flex--flex-end(v-else="")
      BaseBtn.x-lg.blue(@click.prevent="resetAddNewSchedule") CREATE NEW SCHEDULE
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { maska } from "maska";
import { required, minLength } from "@vuelidate/validators";
import { defineComponent } from "vue";
import SideOverlaySlot from "@/components/overlays/SideOverlaySlot.vue";
import { createScheduleClinicDoctor, getClinicDoctors } from "@/api/clinicApi";
import { mapGetters } from "vuex";
import { getDoctorFormatted } from "@/helpers";
import { createToast } from "mosha-vue-toastify";
import { DatePicker } from "v-calendar";
import TimeDropdown from "@/components/TimeDropdown.vue";
import moment from "moment";

export default defineComponent({
  directives: { maska },

  setup() {
    return { v$: useVuelidate() };
  },
  components: { SideOverlaySlot, DatePicker, TimeDropdown },
  emits: ["closeAddScheduleOverlay"],

  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
  },

  validations() {
    return {
      scheduleDoctor: { required },
      scheduleDate: {
        required,
        minLength: minLength(10),
        function() {
          let unixDistributed = new Date(this.scheduleDate).getTime();
          if (!unixDistributed) {
            return false;
          }

          return true;
        },
      },
      scheduleRecurring: { required },
      scheduleInsurance: { required },
    };
  },

  computed: {
    ...mapGetters(["getClinicId"]),
    activeDoctors() {
      return this.doctors.filter((doctor) => !doctor.disabled);
    },
  },

  data() {
    return {
      date: null,
      timeModel: new Date(),
      masks: {
        input: "MM/DD/YYYY",
      },

      showScheduleAdded: false,
      doctors: [],
      scheduleDoctor: null,
      scheduleTime: null,
      scheduleDate: null,
      scheduleRecurring: "",
      scheduleInsurance: "",
      isSubmitting: false,
    };
  },

  methods: {
    timeResults(val) {
      this.scheduleTime = val;
    },
    setDate(newVal) {
      if (newVal === null) {
        this.scheduleDate = "";
      } else {
        this.scheduleDate = moment(this.date).format("MM/DD/YYYY");
        console.log("Line 178");
        console.log("preformatted date:");
        console.log(this.date);
        console.log("formatted date:");
        console.log(this.scheduleDate);
      }
    },
    getDoctorFormatted,
    closeOverlay() {
      this.showScheduleAdded = false;
      this.$emit("closeAddScheduleOverlay");
    },
    resetAddNewSchedule() {
      this.showScheduleAdded = false;
    },
    async addNewSchedule() {
      this.isSubmitting = true;

      const scheduleFinalDate =
        new Date(this.scheduleDate + " " + this.scheduleTime).getTime() / 1000;
      console.log("Line 197");
      console.log("preformatted date:");
      console.log(this.scheduleDate);
      console.log("preformatted time:");
      console.log(this.scheduleTime);
      console.log("formatted dateTime unix:");
      console.log(scheduleFinalDate);
      console.log("formatted dateTime:");
      console.log(moment.unix(scheduleFinalDate).format("MM/DD/YYYY hh:mm:ss"));

      try {
        await createScheduleClinicDoctor(
          this.getClinicId,
          this.scheduleDoctor.id,
          {
            date: scheduleFinalDate,
            recurringType: this.scheduleRecurring,
            insuranceRequired: this.scheduleInsurance,
          }
        );
        this.showScheduleAdded = true;
      } catch (error) {
        console.error(error);
        createToast(error?.response?.data?.message || "There was a problem", {
          timeout: 4000,
          type: "danger",
          position: "bottom-right",
        });
      } finally {
        this.isSubmitting = false;
      }
    },
    async getDoctors() {
      try {
        if (this.getClinicId > 0) {
          const {
            data: { doctors },
          } = await getClinicDoctors(this.getClinicId);
          this.doctors = doctors;
        }
      } catch (error) {
        console.error(error);
      }
    },
  },

  watch: {
    isOpen: function () {
      if (this.isOpen && this.getClinicId > 0) {
        this.getDoctors();
      }
    },
    date: {
      handler: "setDate",
    },
  },
});
</script>

<style scoped lang="scss">
.recurring-reminder {
  font-size: 16px;
  line-height: normal;
}
.patient-section {
  margin-bottom: 0;
  padding: 37px 24px 25px;
  background-color: $primaryBgBlue;
  margin-bottom: 53px;
  margin-top: 26px;
  p {
    margin: 0 0 12px;
    font-size: 21px;
    font-weight: 500;
    line-height: 1.1;
    color: $primaryLightBlue;
    overflow: hidden;
    strong {
      font-weight: 700;
    }
  }
}
form#add-new-schedule {
  margin: 12px 0 20px;
  .columns {
    margin-bottom: 0px;
    .column {
      padding-bottom: 0px;
    }
  }
}
.overlay-show-success > p {
  max-width: 400px;
}
</style>
